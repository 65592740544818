import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { SortFacetProps } from './SortFacet.type'
import Radio from '../Radio'
import Accordion from '../Accordion'
import { replaceStrWithDynamicVal } from '../../utils'
import { sortFacetType } from './Facets.constant'
import ShowMoreLessButton from './ShowMoreLessButton'

const SortFacet: React.FC<SortFacetProps> = props => {
    const {
        selectedFacetType,
        sortings,
        onSortChange,
        sortLabel,
        showMoreThreshold,
        showMoreLabel,
        showLessLabel,
        enableFilterFacetsEnhancement,
    } = props

    const updatedShowMoreLabel = replaceStrWithDynamicVal(showMoreLabel, sortings.length - showMoreThreshold)
    const { current: rowsToShow } = useRef(sortings.slice(0, showMoreThreshold))
    const isSortOpen = selectedFacetType === sortFacetType
    const componentClassName = `${PREFIX}-facet-modal`

    const [showAllRows, setShowAllRows] = useState(false)
    const [listData, setListData] = useState({
        listViewData: rowsToShow,
        icon: 'ct-chevron-down',
        link: updatedShowMoreLabel,
    })
    const [sortFacetOpen, setSortFacetOpen] = useState(isSortOpen)

    // Sets related label, icon and list data based on show less / show more
    useEffect(() => {
        if (showAllRows) {
            setListData({
                listViewData: sortings,
                icon: 'ct-chevron-up',
                link: showLessLabel,
            })
        } else {
            setListData({
                listViewData: rowsToShow,
                icon: 'ct-chevron-down',
                link: updatedShowMoreLabel,
            })
        }
    }, [rowsToShow, showAllRows, showLessLabel, sortings, updatedShowMoreLabel])

    const onSortAccordionChange = (isAccOpen: boolean): void => {
        setSortFacetOpen(isAccOpen)
    }

    // Returns title with selected comma separated values incase of collapsed
    const getTitle = (): React.ReactNode => {
        const selectedSortOption = sortings.filter(sort => sort.selected).map(sortOption => sortOption.title)
        return (
            <>
                <div className={`${PREFIX}-facet-name`}>{sortLabel}</div>
                {!sortFacetOpen && selectedSortOption && (
                    <div className={`${PREFIX}-facet-selection`}>{selectedSortOption.join(',')}</div>
                )}
            </>
        )
    }

    const sortExpandedClass = sortFacetOpen ? `${componentClassName}__sort-facet--expanded` : ''

    return (
        <div className={`${componentClassName}__sort-facet ${sortExpandedClass}`}>
            <Accordion
                title={getTitle()}
                isHeaderOpen={isSortOpen}
                collapseControl={onSortAccordionChange}
                isHeaderHidden={enableFilterFacetsEnhancement && selectedFacetType == 'sort'}>
                <ul>
                    {listData.listViewData.map(sortOption => (
                        <li key={sortOption.title} className={`${componentClassName}__sort-options`}>
                            <Radio
                                id={sortOption.title}
                                label={sortOption.title}
                                name="sortings"
                                checked={sortOption.selected}
                                onChange={onSortChange}
                                value={sortOption.title}
                            />
                        </li>
                    ))}
                    <ShowMoreLessButton
                        totalResults={sortings as unknown as Record<string, unknown>[]}
                        setShowAllRows={setShowAllRows}
                        showAllRows={showAllRows}
                        listData={listData}
                        showMoreThreshold={showMoreThreshold}
                    />
                </ul>
            </Accordion>
        </div>
    )
}

SortFacet.propTypes = {
    selectedFacetType: PropTypes.string,
    sortings: PropTypes.array,
    onSortChange: PropTypes.func,
    sortLabel: PropTypes.string,
    showMoreThreshold: PropTypes.number,
    showMoreLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
}

export default SortFacet
