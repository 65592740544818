import { MediaImages } from '../components/MediaGalleryViewer/MediaGalleryViewer.type'

/**
 * function to check id key present in array
 * @param {MediaImages[]} mediaSet
 * @param {string} key
 * @return {boolean}
 */
export const checkKeyPresenceInArray = (mediaSet: MediaImages[], key: string): boolean =>
    mediaSet?.some(mediaSetObj => Object.keys(mediaSetObj).includes(key))
