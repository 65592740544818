import { Dispatch } from 'redux'

import { FETCH_STICKY_TYPES, IS_STICKY_TOC_IN_VIEW } from '../types/products/stickyToc.actionTypes.constant'
import { fetchStickyAction, setIsStickyTOCInViewAction } from '../actionCreators/stickyToc.actionCreators'

export const fetchStickyData =
    (reuqestPayload: string) =>
    (dispatch: Dispatch<{ type: typeof FETCH_STICKY_TYPES; payload: string }>): void => {
        dispatch(fetchStickyAction(reuqestPayload))
    }

export const setIsStickyTOCInView =
    (isInView: boolean) =>
    (dispatch: Dispatch<{ type: typeof IS_STICKY_TOC_IN_VIEW; payload: boolean }>): void => {
        dispatch(setIsStickyTOCInViewAction(isInView))
    }
