import React, { createElement, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { HeadingType } from './Heading.types'
import { PREFIX } from '../config'
import { EMPTY_STRING } from '../../globalConstants/global.constant'

const Heading: React.FC<HeadingType> = ({ ...props }): JSX.Element => {
    const { variant, size, children, componentClass = EMPTY_STRING } = props
    const [headingRef, setHeadingRef] = useState<HTMLHeadingElement>(null)

    const handleSize = useCallback((): string => {
        switch (size) {
            case '4xl':
                return 'display-1'
            case '3xl':
                return 'h1'
            case '2xl':
                return 'h2'
            case 'xl':
                return 'h3'
            case 'lg':
                return 'display-2'
            case 'md':
                return 'h4'
            case 'sm':
                return 'body-sm'
            case 'xs':
                return 'body-xs'
        }
    }, [size])

    const handleSizeClass = size ? `${PREFIX}-${handleSize()}` : EMPTY_STRING

    /**
     * useEffect to add children to created heading
     */
    useEffect(() => {
        if (headingRef) {
            headingRef.innerHTML = children as string
        }
    }, [headingRef, children])

    return createElement(variant, {
        className: `${componentClass} ${handleSizeClass}`,
        ref: setHeadingRef,
    })
}

Heading.propTypes = {
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl']),
    componentClass: PropTypes.string,
}

export default Heading
