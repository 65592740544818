/**
 * Used to avoid arrow function complexity on React components.
 * @param {unknown} data
 * @return {unknown}
 */
export const enableDestructOnUndefinedData = (data: unknown): unknown => {
    return data || {}
}

/**
 * Used to avoid arrow function complexity on React components.
 * @param {unknown} data
 * @return {unknown}
 */
export const dataOrString = (data: unknown): unknown => {
    return data || ''
}
