/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'
import {
    setGeneratedSharedCartLink,
    setGeneratedSharedCartError,
    setCustomerSharedCart,
    setCustomerSharedCartError,
    setCustomerSharedCartMergingError,
    setStoreInitiatedCartTemplate,
    setStoreInitiatedCartTemplateError,
    setStoreInitiatedCart,
    setStoreInitiatedCartError,
    resetStoreInitiatedCartData,
    setSendLinkFormData,
    setSharedCartLinkSent,
    setSharedCartLinkSendingError,
    setOneTimeCartDeliveryModeChanged,
    setChangeDeliveryModeError,
    setOneTimeCartStore,
    setOneTimeCartStoreErrorAction,
    updateStoreInitiatedCart,
    updateStoreInitiatedCartPostalCode,
    updateStoreInitiatedCartPostalCodeFail,
    clearPostalErrorStateForOneTimeCart,
} from '../actionCreators'
import * as actions from '../actionCreators'
import { ShareCartTemplate, SendLinkFormData } from '../models/sharedCart.interface'
import { FilteredCartData, CartResponseErrorDTO } from '../models/cart.interface'
import { StoreWithAvailability } from '../models/storeDetails.interface'

type Action = ActionType<typeof actions>

export interface SharedCartState {
    cartSharing: CartSharing
    cartConsuming: {
        storeSharedCart: StoreSharedCart
        customerToCustomerSharedCart: CustomerToCustomerSharedCart
    }
}
export interface CartSharing {
    generatedLink: string
    sharedCartLinkError: string
    sendLinkFormData: SendLinkFormData
    isSharedCartLinkSent: boolean
    sharedCartLinkSendingError: string
}
export interface CustomerToCustomerSharedCart {
    customerSharedCart: ShareCartTemplate
    customerSharedCartError: string
    customerSharedCartMergingError: string
}
export interface StoreSharedCart {
    isStoreSharedCart: boolean
    storeInitiatedCartTemplate: ShareCartTemplate
    storeInitiatedCartTemplateError: string
    storeInitiatedCart: FilteredCartData
    isStoreInitiatedCartRetrievingError: boolean
    isDeliveryModeChanged: boolean
    isChangeDeliveryModeError: boolean
    oneTimeCartStore: StoreWithAvailability
    isStoreInitiatedCartReserved: boolean
    areErrorsPresentOnCart: boolean
    postalCodeSuccess: boolean
    postalCodeFailure: boolean
    postalCodePartialSuccess: boolean
    postalCodeError: boolean
    postalCodeDispatchStarted: boolean
    changeShippingFeeSuccess: boolean
    isEtaError: boolean
    cartValidations: CartResponseErrorDTO
}

export const initialState: SharedCartState = {
    cartSharing: {
        generatedLink: '',
        sharedCartLinkError: '',
        sendLinkFormData: {} as SendLinkFormData,
        isSharedCartLinkSent: false,
        sharedCartLinkSendingError: '',
    },
    cartConsuming: {
        storeSharedCart: {
            isStoreSharedCart: false,
            storeInitiatedCartTemplate: {} as ShareCartTemplate,
            storeInitiatedCartTemplateError: '',
            storeInitiatedCart: {} as FilteredCartData,
            isStoreInitiatedCartRetrievingError: false,
            isDeliveryModeChanged: false,
            isChangeDeliveryModeError: false,
            oneTimeCartStore: {} as StoreWithAvailability,
            isStoreInitiatedCartReserved: false,
            areErrorsPresentOnCart: false,
            postalCodeSuccess: false,
            postalCodeFailure: false,
            postalCodePartialSuccess: false,
            postalCodeError: false,
            postalCodeDispatchStarted: false,
            changeShippingFeeSuccess: false,
            isEtaError: false,
            cartValidations: {} as CartResponseErrorDTO,
        },
        customerToCustomerSharedCart: {
            customerSharedCart: {} as ShareCartTemplate,
            customerSharedCartError: '',
            customerSharedCartMergingError: '',
        },
    },
}

export const sharedCartReducer = (state: SharedCartState = initialState, action: Action): SharedCartState => {
    switch (action.type) {
        case getType(setGeneratedSharedCartLink): {
            return { ...state, cartSharing: { ...state.cartSharing, generatedLink: action.payload } }
        }
        case getType(setGeneratedSharedCartError): {
            return { ...state, cartSharing: { ...state.cartSharing, sharedCartLinkError: action.payload } }
        }
        case getType(setCustomerSharedCart): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    customerToCustomerSharedCart: {
                        ...state.cartConsuming.customerToCustomerSharedCart,
                        customerSharedCart: action.payload,
                    },
                },
            }
        }
        case getType(setCustomerSharedCartError): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    customerToCustomerSharedCart: {
                        ...state.cartConsuming.customerToCustomerSharedCart,
                        customerSharedCartError: action.payload,
                    },
                },
            }
        }
        case getType(setCustomerSharedCartMergingError): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    customerToCustomerSharedCart: {
                        ...state.cartConsuming.customerToCustomerSharedCart,
                        customerSharedCartMergingError: action.payload,
                    },
                },
            }
        }
        case getType(setStoreInitiatedCartTemplate): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        storeInitiatedCartTemplate: action.payload,
                    },
                },
            }
        }
        case getType(setStoreInitiatedCartTemplateError): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        storeInitiatedCartTemplateError: action.payload,
                    },
                },
            }
        }
        case getType(setStoreInitiatedCart): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        isStoreSharedCart: true,
                        storeInitiatedCart: action.payload,
                        isStoreInitiatedCartRetrievingError: false,
                        isStoreInitiatedCartReserved: false,
                    },
                },
            }
        }
        case getType(updateStoreInitiatedCart): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        storeInitiatedCart: action.payload,
                        isStoreInitiatedCartReserved: true,
                    },
                },
            }
        }
        case getType(updateStoreInitiatedCartPostalCode): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        storeInitiatedCart: action.payload,
                        isStoreInitiatedCartReserved: true,
                        areErrorsPresentOnCart: false,
                        postalCodeSuccess: true,
                        postalCodeFailure: false,
                        postalCodePartialSuccess: false,
                        postalCodeError: false,
                        postalCodeDispatchStarted: false,
                        changeShippingFeeSuccess:
                            state.cartConsuming.storeSharedCart.storeInitiatedCart.cart?.cartSummary?.shippingFees
                                ?.value !== action.payload.cart.cartSummary?.shippingFees?.value,
                        isEtaError: false,
                    },
                },
            }
        }
        case getType(updateStoreInitiatedCartPostalCodeFail): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        cartValidations: action.payload,
                        postalCodeFailure: true,
                        postalCodeDispatchStarted: false,
                        postalCodePartialSuccess: false,
                    },
                },
            }
        }
        case getType(clearPostalErrorStateForOneTimeCart): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        postalCodeFailure: false,
                    },
                },
            }
        }
        case getType(setStoreInitiatedCartError): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        isStoreSharedCart: true,
                        storeInitiatedCart: {} as FilteredCartData,
                        isStoreInitiatedCartRetrievingError: true,
                    },
                },
            }
        }
        case getType(resetStoreInitiatedCartData): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: { ...initialState.cartConsuming.storeSharedCart },
                },
            }
        }
        case getType(setOneTimeCartDeliveryModeChanged): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        isDeliveryModeChanged: action.payload,
                    },
                },
            }
        }
        case getType(setChangeDeliveryModeError): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        isChangeDeliveryModeError: action.payload,
                    },
                },
            }
        }
        case getType(setOneTimeCartStore): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        oneTimeCartStore: action.payload,
                    },
                },
            }
        }
        case getType(setOneTimeCartStoreErrorAction): {
            return {
                ...state,
                cartConsuming: {
                    ...state.cartConsuming,
                    storeSharedCart: {
                        ...state.cartConsuming.storeSharedCart,
                        oneTimeCartStore: {} as StoreWithAvailability,
                    },
                },
            }
        }
        case getType(setSendLinkFormData): {
            return { ...state, cartSharing: { ...state.cartSharing, sendLinkFormData: action.payload } }
        }
        case getType(setSharedCartLinkSent): {
            return { ...state, cartSharing: { ...state.cartSharing, isSharedCartLinkSent: action.payload } }
        }
        case getType(setSharedCartLinkSendingError): {
            return { ...state, cartSharing: { ...state.cartSharing, sharedCartLinkSendingError: action.payload } }
        }
        default:
            return state
    }
}
