import { ProductResponseData, ProductSku } from './../../../redux/models/product.interface'
import { BreadcrumbLinkData } from './../../../redux/models/productData.interface'
import { AutomotiveVehicle } from '../AutomotiveProductDoesNotFit/AutomotiveProductDoesNotFit.type'
import { CriticalFitmentProduct } from './AutomotiveBuyBox.type'
import {
    TireWheelFitmentNote,
    TireWheelFitmentTitle,
    VehicleFitType,
    productWheelTypes,
} from '../../../globalConstants/global.constant'
import { isArrayNotEmpty, getSetOfFourPriceForStaggered, Vehicle, FitmentNote, CriticalFitment } from '@nl/lib'
import { MagicNumber } from '../../../analytics/analytics.type'
import { TireType } from '../../../redux/models/tireVehicle.interface'
import { getFullTireSize, isAutoPartPDP, isTirePDP } from '../../Vehicles/Vehicle.helper'
import { Price } from '../../../redux/models/cart.interface'
import { areAllParamsValid } from '../../../utils/getFilteredCartItems'
import { pdpURLStringCheck } from '../../Vehicles/Vehicles.constant'
import { isStaggeredFront, isStaggeredRear } from '../../../utils/checkStaggered.utils'
import { ShopByTireSizeInitialState } from '../../AutomotivePLP/ShopByTireSize/ShopByTireSize.types'

/**
 * function to return true if auto-part/tire/wheel does not fit
 * @param {boolean} isFitmentRequired
 * @param {AutomotiveVehicle} vehicle
 * @return {boolean}
 */
export const isAutomotiveProductNotFit = (isFitmentRequired: boolean, vehicle: AutomotiveVehicle): boolean => {
    return isFitmentRequired && vehicle.vehicleFitType === VehicleFitType.NotFit
}

/**
 * function to return vehicle name
 * @param {Vehicle | null} vehicleList
 * @return {string}
 */
export const getVehicleName = (vehicleList?: Vehicle | null): string => {
    return `${String(vehicleList?.autoAttributes?.year)} ${String(vehicleList?.autoAttributes?.make)} ${String(
        vehicleList?.autoAttributes?.model,
    )}`
}

/**
 * function to return product url
 * @param {BreadcrumbLinkData[]} breadcrumbList
 * @param {string} homeUrl
 * @return {string}
 */
export const getProductListUrl = (breadcrumbList: BreadcrumbLinkData[], homeUrl: string): string => {
    return breadcrumbList[breadcrumbList.length - MagicNumber.TWO]?.url || homeUrl
}

/**
 * function to return string for button in not fit condition
 * @param {ProductResponseData} productData
 * @param {string} seeWheelsFitVehicleCTA
 * @param {string} seeProductFitVehicleCTA
 * @param {string} seeTiresFitVehicleCTA
 * @param {string} seeProductFitTireSizeCTA
 * @param {boolean} isTireSizePresent
 * @return {string}
 */
export const seeProductFitVehicleCTAButton = (
    productData: ProductResponseData,
    seeWheelsFitVehicleCTA: string,
    seeProductFitVehicleCTA: string,
    seeTiresFitVehicleCTA: string,
    seeProductFitTireSizeCTA: string,
    isTireSizePresent: boolean,
): string => {
    return productData?.productWheelType?.toUpperCase() === productWheelTypes.Wheel
        ? seeWheelsFitVehicleCTA
        : isTirePDP(productData?.productWheelType)
        ? isTireSizePresent
            ? seeProductFitTireSizeCTA
            : seeTiresFitVehicleCTA
        : seeProductFitVehicleCTA
}

/**
 * function to return vehicle name or tire name
 * @param {Vehicle | null} vehicleList
 * @param {TireType | null} tiresData
 * @return {string}
 */
export const getVehicleORTireName = (vehicleList: Vehicle | null, tiresData: TireType | null): string => {
    return !tiresData
        ? `${String(vehicleList?.autoAttributes?.year)} ${String(vehicleList?.autoAttributes?.make)} ${String(
              vehicleList?.autoAttributes?.model,
          )}`
        : `${tiresData?.selectedDiameterValue} ${getFullTireSize(tiresData)}`
}

/**
 * function  to return regular and staggered fitment array
 * @param {CriticalFitment} criticalFitment
 * @return {CriticalFitmentProduct}
 *
 */
export const getCriticalFitmentProducts = (criticalFitment?: CriticalFitment[] | null): CriticalFitmentProduct => {
    const regularFitment: FitmentNote[] = []
    const staggeredFitment: FitmentNote[] = []
    const rearFitment: FitmentNote[] = []
    const frontFitment: FitmentNote[] = []
    criticalFitment?.forEach((fitments: CriticalFitment) => {
        if (isArrayNotEmpty(fitments.tireWheelFitment)) {
            fitments.tireWheelFitment.forEach((fitmentData: FitmentNote) => {
                if (
                    fitmentData.title === TireWheelFitmentTitle.regular &&
                    (fitmentData?.note === TireWheelFitmentNote.OE ||
                        fitmentData?.note === TireWheelFitmentNote.PlusMinus ||
                        fitmentData?.note === TireWheelFitmentNote.OI)
                ) {
                    fitmentData['sku'] = fitments.sku
                    regularFitment.push(fitmentData)
                } else if (fitmentData.title === TireWheelFitmentTitle.staggered) {
                    if (isStaggeredFront(fitmentData.note)) {
                        fitmentData['sku'] = fitments.sku
                        frontFitment.push(fitmentData)
                    } else if (isStaggeredRear(fitmentData.note)) {
                        fitmentData['sku'] = fitments.sku
                        rearFitment.push(fitmentData)
                    }
                }
            })
        }
    })
    if (isArrayNotEmpty(frontFitment) && isArrayNotEmpty(rearFitment)) {
        staggeredFitment.push(frontFitment[0])
        staggeredFitment.push(rearFitment[0])
    } else if (isArrayNotEmpty(frontFitment)) {
        staggeredFitment.push(frontFitment[0])
    } else if (isArrayNotEmpty(rearFitment)) {
        staggeredFitment.push(rearFitment[0])
    }
    const isStaggered = isArrayNotEmpty(staggeredFitment)
    return { regularFitment, staggeredFitment, isStaggered }
}

// function return RegExpMatchArray when url contains #df
export const checkForExternalLink = (): RegExpMatchArray | null => {
    return RegExp(pdpURLStringCheck.df).exec(window.location.href)
}

/**
 * function to calulate total value for tire and wheel
 * @param { ProductSku[] } productskus
 * @param { boolean } isFeeValue
 * @param { number } frontQuantity
 * @param { number } rearQuantity
 * @return { Price | undefined }
 */
export const pdpStaggeredTotalPrice = (
    productskus: ProductSku[],
    isFeeValue?: boolean,
    frontQuantity?: number,
    rearQuantity?: number,
): Price | undefined | number => {
    if (isArrayNotEmpty(productskus)) {
        const isFullStaggered = productskus.length === 2
        if (areAllParamsValid(!isFeeValue, !frontQuantity, !rearQuantity)) {
            return {
                minPrice: getSetOfFourPriceForStaggered(productskus, true),
                maxPrice: getSetOfFourPriceForStaggered(productskus, true),
                value: getSetOfFourPriceForStaggered(productskus, true),
            }
        } else if (areAllParamsValid(!isFeeValue, frontQuantity, rearQuantity)) {
            return {
                minPrice: getSetOfFourPriceForStaggered(productskus, true, frontQuantity, rearQuantity),
                maxPrice: getSetOfFourPriceForStaggered(productskus, true, frontQuantity, rearQuantity),
                value: getSetOfFourPriceForStaggered(productskus, true, frontQuantity, rearQuantity),
            }
        } else if (isFullStaggered) {
            return productskus[0].feeValue * 2 + productskus[1].feeValue * 2
        } else {
            return productskus[0].feeValue * 2
        }
    }
}

/**
 * function to check if crticalfitment is returning multiplematching sku or single,
 * @param {CriticalFitment[]} criticalFitment
 * @param {string} fitmentTypeCode
 * @param {string} productWheelType
 * @param {any} list
 * @return {boolean}
 */
export const checkIfMultipleSKUFitsFromCriticalfitment = (
    criticalFitment?: CriticalFitment[],
    fitmentTypeCode?: string,
    productWheelType?: string,
    list?: Vehicle[],
): boolean => {
    return (
        isAutoPartPDP(fitmentTypeCode, productWheelType) &&
        ((isArrayNotEmpty(criticalFitment) && criticalFitment?.length > MagicNumber.ONE) ||
            (isArrayNotEmpty(list) && Array.isArray(list?.[0])))
    )
}

/**
 * Checks if single sku product
 * @param {ProductResponseData} productData
 * @return {boolean}
 */
export const isSingleSkuProduct = (productData: ProductResponseData): boolean => {
    return isArrayNotEmpty(productData?.skus) && productData?.skus.length === MagicNumber.ONE
}

/**
 * get PLP url and redirect it
 * @param {BreadcrumbLinkData[]} breadcrumbList
 * @param {string} homeUrl
 */
export const redirectToPLPURL = (breadcrumbList: BreadcrumbLinkData[], homeUrl: string) => {
    const productListLink = getProductListUrl(breadcrumbList, homeUrl)
    window.open(productListLink, '_self', 'noopener')?.focus()
}

/**
 * function to check selected tire details and previous tire details are not same
 * @param {ShopByTireSizeInitialState} selectedTireSize
 * @param {TireType | null} previosulySelectedData
 * @return {boolean}
 */
export const isTireValueChanged = (
    selectedTireSize: ShopByTireSizeInitialState,
    previosulySelectedData: TireType | null,
) => {
    return (
        selectedTireSize?.selectedAspectRatio !== previosulySelectedData?.selectedAspectRatio ||
        selectedTireSize?.selectedDiameterValue !== previosulySelectedData?.selectedDiameterValue ||
        selectedTireSize?.selectedSectionWidth !== previosulySelectedData?.selectedSectionWidth
    )
}

export const wheelSpecsRequireHighlight = ['ps_offset', 'ps_wheelrim_diameter', 'ps_rim_width']
