import { ProductCardType } from '../ProductGridView/ProductGrid.types'

/**
 * Interface for SearchBarProps
 * @interface
 */
export interface SearchBarProps {
    // props required by wrapper from header
    path?: string
    searchIconSize: string
    a11ySearchIconLabel: string
    allyCloseIconLabel: string
    a11yUpdateQueryIconLabel: string
    id?: string
    suggestionPannel?: boolean
    showModal?: boolean
    searchBoxPlaceholder: string
    minChars: number
    searchLabel: string
    categoriesLabel: string
    didYouMeanLabel: string
    noResultsForLabel: string
    noResultsForText: string
    searchHistoryTitle: string
    searchHistoryCountDesktop: number
    searchHistoryCountMobile: number
    searchHistoryClearLabel: string
    // new props required for lib component
    suggestResponse: Suggest
    searchValue: string
    showClearButton: boolean
    isCodeLookup: boolean
    suggestedKeywordList: Suggestion[]
    suggestedCategoryList: Suggestion[]
    searchHistoryList: Suggestion[]
    getSearchHistoryList: () => Suggestion[]
    addSearchHistory: (searchValue: string, data?: Suggestion) => Suggestion[]
    clearSearchHistoryList: () => void
    getSuggestions: (searchTerm: string) => Promise<{ data: Suggestion }>
    handleSuggestionGet: (data: { data: Suggestion }) => void
    handleSuggestionErr: (error: { response: SuggestErrorResponse }) => void
    searchCallBack: (searchTerm: string) => void
    searchPagePath: string
    pushAnalyticsData: (
        searchValue: string,
        searchType: string,
        suggestionType: string,
        selectedSuggestion: Suggestion | null,
        selectedProductIndex: number,
        suggestionProductImpressions: number,
        suggestionEngagementType: string,
        isTypeaheadUsed: boolean,
    ) => void
    closeModal: () => void
    showCloseButton: boolean
    showSuggestionPanel: boolean
    suggestiveQueryClickCallback: () => void
    delay: number
    searchingString: string
    pushQueue: (searchString: string) => void
    topHit: Suggestion[]
    a11yArrowButtonsAriaLabel?: string
    maxNumCats?: number
    searchResultPageUrl?: string
    a11ySuggestionsAvailableLabel?: string
    mobileSearchBarId?: string
}

/**
 ** Interface for Suggest
 * @interface
 */
export interface Suggest {
    isCodeLookup: boolean
    suggestions: Suggestion[]
    categories: Suggestion[]
    topHit: Suggestion[]
    products: ProductCardType[]
}

/**
 ** Interface for SuggestErrorResponse
 * @interface
 */
export interface SuggestErrorResponse {
    data: string
    status: number
}

/**
 ** Interface for Suggestion
 * @interface
 */
export interface Suggestion {
    isRedirect: boolean
    label: string
    searchUrl: string
    type?: string
    index?: number
}
export interface HistorySuggestion {
    index?: number
    label: string
    data?: Suggestion
}

export interface SuggestionsProps {
    handleMobileFillTypeahead: (value: string) => void
    suggestedList: Suggestion[]
    headerLabel: string
    currentSuggestionIndex: number
    indexMargin: number
    type: string
    handleMouseover: (suggestion: Suggestion) => void
    handleSuggestionClick: (suggestion: Suggestion) => void
    handleSuggestionEnter: (event: React.KeyboardEvent<HTMLElement>) => void
    a11yUpdateQueryIconLabel?: string
    searchIconSize?: string
    originalSearchValue?: string
    clearHistory: () => void
    searchHistoryClearLabel?: string
    showTypeahead: boolean
    a11yArrowButtonsAriaLabel?: string
    setShowSuggestionPanel?: React.Dispatch<React.SetStateAction<boolean>>
    currentSuggestionRef?: React.MutableRefObject<HTMLDivElement>
    currentTypeaheadRef?: React.MutableRefObject<HTMLButtonElement>
}

// constants
export const searchConstants: Record<string, string> = Object.freeze({
    // These "raw"  types are the suggestion.type property stored in the redux store
    SUGGESTION_RAW_KEYWORD_TYPE: 'queryText',
    SUGGESTION_RAW_CATEGORY_TYPE: 'category',
    SUGGESTION_RAW_HISTORY_TYPE: 'history',
    // These types are used for GA "type" property
    SUGGESTION_TYPE_KEYWORD: 'keyword',
    SUGGESTION_TYPE_CATEGORY: 'category',
    SUGGESTION_TYPE_HISTORY: 'history',
    // These types are used for GA user journey
    SUGGESTION_TYPE_UJ_KEYWORD: 'selectedKeyword',
    SUGGESTION_TYPE_UJ_CATEGORY: 'selectedCategory',
    SUGGESTION_TYPE_UJ_HISTORY: 'selectedHistory',

    ANALYTICS_EVENT: 'partial-search',
    BLOOMREACH: 'bloomreach',
    USER_INPUTTED: 'user search',
    SEARCH_SUGGESTION: 'suggested search',
    TRUE: 'true',
    INTERACT_CLICK: 'click',
    INTERACT_ENTER: 'enter',
    COMPLETE_SEARCH_EVENT_DATA: 'search_analytics',
    COMPLETE_SEARCH_EVENT_EVENT: 'complete-search',
    SUGGESTION_CACHE_DATA: 'suggestion_cache_data',
    AUTO_UNIVERSAL_PRODUCTS_FILTER: 'auto.isUniversalDisabled',
})

export interface SearchAnalytics {
    event: string
    userInput: string
    engine: string
    type?: string
    suggestionProductImpressions: number
    suggestionEngagementType: string
    suggestionValue: string
    redirect?: boolean
    selectedProduct?: string
    position?: number
    code?: string
    userJourney?: string
    suggestionType: string
}

export interface CacheData {
    headers: Array<string>
    creationTime: string
    timeToLive: number | false | string | undefined
    data: Suggest
}
