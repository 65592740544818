import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { LanguageToggle, getParameterFromURL, queryParameters } from '@nl/lib'
import { LanguageToggleHandlerProps } from './LanguageToggleHandler.type'
import { languagesToggleService } from '../../../../services/languageToggleService'
import { GlobalPropsHelper } from '../../../../analytics/helpers/globalProps/globalProps.helper'
import getPageType from '../../../../utils/getPageType'
import { pageTypes } from '../../../../config'
import { productDataSelector, productSelector } from '../../../../redux/selectors/product.selectors'
import { storeDetailsSelector } from '../../../../redux/selectors/storeDetails.selectors'
import { categoryIdDataSelector } from '../../../../redux/selectors/categoryIdData.selectors'

/**
 * Generate Language Toggle Handler Component To Be Used In Pencil Banner
 * @return {JSX.Element} returns Language Toggle Handler  component
 *
 **/
const LanguageToggleHandler: React.FC<LanguageToggleHandlerProps> = ({ ...props }): JSX.Element => {
    const { languageLabel, alternateLanguageA11y, featureType } = props
    const [languageToggleService] = useState(languagesToggleService())
    const productData = useSelector(productDataSelector) || {}
    const categoryIdData = useSelector(categoryIdDataSelector) || {}
    const storeDetails = useSelector(storeDetailsSelector) || {}
    const isSRPPage = getPageType() === pageTypes.searchPage
    const globalProps = new GlobalPropsHelper()
    const { isDynamic, alternatePageDetails } = globalProps.readDataAlternateLangDetails()
    const { selectedProductCode } = useSelector(productSelector)
    /**
     * Generate function to return language updated on toggle in lang key when page is static
     * @return {string} returns page path
     */
    const getLangUrlParams = (): string => {
        if (isSRPPage) {
            const langParamValue = getParameterFromURL(queryParameters.language)
            const langUpdatedValue = alternatePageDetails.hrefLang.replace('-', '_')
            const currentParams = decodeURI(window.location.search)
            return langParamValue ? currentParams.replace(langParamValue, langUpdatedValue) : currentParams
        }
        return window.location.search
    }
    /**
     * Generate function to return page path on the basis of property isDynamic(true/false),whether page is dynamic or not
     * @return {string} returns page path
     */
    const getAlternateLangUrl = (): string => {
        return isDynamic === 'false'
            ? `${alternatePageDetails?.path ? alternatePageDetails?.path : ''}${getLangUrlParams()}`
            : languageToggleService.getPageTypeLangUrl(
                  languageLabel || '',
                  productData,
                  categoryIdData,
                  storeDetails,
                  selectedProductCode,
              )
    }

    return (
        <LanguageToggle
            languageLabel={languageLabel}
            alternateLanguageA11y={alternateLanguageA11y}
            getAlternateUrl={getAlternateLangUrl}
            featureType={featureType}
        />
    )
}

LanguageToggleHandler.propTypes = {
    languageLabel: PropTypes.string.isRequired,
    alternateLanguageA11y: PropTypes.string.isRequired,
    featureType: PropTypes.string.isRequired,
}

export default LanguageToggleHandler
