import { createAction } from 'typesafe-actions'
import { SuggestErrorResponse } from '../models/search.interface'
import { Suggest } from '@nl/lib'
import {
    FETCH_SUGGESTIONS_REQUEST_SUCCESS,
    FETCH_SUGGESTIONS_REQUEST_FAILURE,
    FETCH_SUGGESTED_PRODUCTS_REQUEST_SUCCESS,
    FETCH_SUGGESTED_PRODUCTS_REQUEST_FAILURE,
} from '../types/suggest/suggest.actionTypes.constant'

export const fetchSuggestionsSuccessAction = createAction(
    FETCH_SUGGESTIONS_REQUEST_SUCCESS,
    (payload: Suggest) => payload,
)<Suggest>()

export const fetchSuggestionsErrorAction = createAction(
    FETCH_SUGGESTIONS_REQUEST_FAILURE,
    (payload: SuggestErrorResponse) => payload,
)<SuggestErrorResponse>()

export const fetchSuggestedProductsSuccessAction = createAction(
    FETCH_SUGGESTED_PRODUCTS_REQUEST_SUCCESS,
    (payload: Suggest) => payload,
)<Suggest>()

export const fetchSuggestedProductsErrorAction = createAction(
    FETCH_SUGGESTED_PRODUCTS_REQUEST_FAILURE,
    (payload: SuggestErrorResponse) => payload,
)<SuggestErrorResponse>()
