import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX } from '../config'
import { propsType } from './ButtonToggle.types'
import { DisplayViewType } from './buttonConstant.type'

const ButtonToggle: React.FC<propsType> = ({ ...props }): JSX.Element => {
    const defaultView = props.defaultView
    const [selectedListView, setSelectedListView] = useState(false)

    /**
     * Function to get current target, toggle value and send updated value to applyActiveClass function
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - Click Event.
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const node = event.currentTarget
        const toggle = node.dataset.toggle
        setSelectedListView(toggle.toLowerCase() === DisplayViewType.list.toLowerCase())
        props.handleDispatchClick(toggle)
    }

    useEffect(() => {
        setSelectedListView(defaultView?.toLowerCase() === DisplayViewType.list.toLowerCase())
    }, [defaultView])

    return (
        <>
            {props.productCardListView ? (
                <div className={`${PREFIX}-buttontoggle`}>
                    {selectedListView ? (
                        <button
                            data-toggle="Grid"
                            onClick={handleClick}
                            name="Grid"
                            aria-label={`${props.a11yViewTypeLabel} ${props.gridViewLabel}`}
                            aria-live="polite">
                            <Icon type="ct-grid" size="lg" path={props.path} />
                            {props.gridViewLabel}
                        </button>
                    ) : (
                        <button
                            data-toggle="List"
                            onClick={handleClick}
                            name="List"
                            aria-label={`${props.a11yViewTypeLabel} ${props.listViewLabel}`}
                            aria-live="polite">
                            <Icon type="ct-list-view" size="lg" path={props.path} />
                            {props.listViewLabel}
                        </button>
                    )}
                </div>
            ) : null}
        </>
    )
}

ButtonToggle.defaultProps = {
    isFitmentRequired: false,
}

ButtonToggle.propTypes = {
    productCardListView: PropTypes.bool,
    handleDispatchClick: PropTypes.func,
    gridViewLabel: PropTypes.string,
    listViewLabel: PropTypes.string,
    a11ySelectedLabel: PropTypes.string,
    allyUnselectedLabel: PropTypes.string,
    path: PropTypes.string,
    isFitmentRequired: PropTypes.bool,
    defaultView: PropTypes.string,
}
export default ButtonToggle
