import { RootState } from '../reducers'
import { OffersState } from '../reducers/offers.reducer'
import { EmailOfferActivationErrorResponse } from '../models/emailOfferActivation.interface'
import { CustomerOffers, OfferActivation, TargetedOfferActivation } from '../models/offers.interface'

export const offersSelector = (state: RootState): OffersState => state.offers
export const targetedOfferActivatedDataSelector = (state: RootState): TargetedOfferActivation[] | null =>
    state.offers.targetedOfferActivatedData
export const offersActivatedDataSelector = (state: RootState): OfferActivation | null =>
    state.offers.offersActivatedData
export const offersActivatedErrorSelector = (state: RootState): EmailOfferActivationErrorResponse | null =>
    state.offers.offersActivatedError
export const offersDataSelector = (state: RootState): CustomerOffers | null => state.offers.offersData
