import { MutableRefObject, useMemo } from 'react'
// Custom hook for check the element dimensions.
const useRefDimensions = (ref: MutableRefObject<HTMLElement>) => {
    const { current } = ref
    const boundingRect = current?.getBoundingClientRect()
    return useMemo(() => {
        if (boundingRect) {
            const { width, height } = boundingRect
            return {
                width: Math.round(width),
                height:
                    Math.round(height) +
                    parseInt(getComputedStyle(current).marginTop) +
                    parseInt(getComputedStyle(current).marginBottom),
            }
        }
        return { width: 0, height: 0 }
    }, [boundingRect, current])
}
export { useRefDimensions }
export default useRefDimensions
