import React from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import Icon from '../Icon'
import Button from '../Button'
import FlyoutModalComponent from '../FlyoutModalComponent'
import { NavigationFlyoutProps } from './NavigationFlyout.types'

/**
 * Flyout Modal
 * @param {NavigationFlyoutProps} param
 * @return {JSX.Element} JSX Element
 */
const NavigationFlyout: React.FC<NavigationFlyoutProps> = ({ ...props }) => {
    const { flyoutOpen = false, closeFlyout, children, profileState, a11yLabel } = props
    const componentClass = `${PREFIX}-navigation-flyout`

    const FlyOutModalComponentProps = {
        isOpen: flyoutOpen,
        closeFlyout,
        title: a11yLabel,
        isStoreSelectorModalUsed: false,
    }

    return (
        <div className={`${componentClass}__wrapper ${componentClass}__${profileState.toLowerCase()}`}>
            <FlyoutModalComponent {...FlyOutModalComponentProps}>
                <Button data-testid="close-button" type="icon_button" onClick={closeFlyout}>
                    <Icon type="ct-close" size="lg" />
                </Button>
                {children}
            </FlyoutModalComponent>
        </div>
    )
}

NavigationFlyout.propTypes = {
    flyoutOpen: PropTypes.bool.isRequired,
    closeFlyout: PropTypes.func,
    children: PropTypes.element,
    profileState: PropTypes.string,
}

export default NavigationFlyout
