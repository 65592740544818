import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX, previousElementName } from '../config'
import { OfferCardsType, BannerImagePath } from './OfferCard.type'
import Button from '../Button'
import { replaceMultipleStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { magicNumber } from '../../utils/magicNumber'
import { offerEndDateRegex } from '../../globalConstants/regexPatterns.constant'
import { applyAkamaiPolicy } from '../../utils/getAkamaiPolicy'
import Accordion from '../Accordion'
import RecommendationsList from '../RecommendationsList'

const OfferCard: React.FC<OfferCardsType> = props => {
    const {
        offer,
        bannerImageList,
        offerEndsLabel,
        isFlyout,
        activateOffer,
        modalLabel,
        classNameSubtitle,
        classNameDates,
        classNameAlign,
        classNameCarousel,
        closeModal,
        offerEndsTodayLabel,
        offerEndsDateLabel,
        columns = {},
        status,
        openModal,
        hasLoyaltyAccount = true,
        updateOfferState,
        component,
        filteredOffers,
        akamaiPolicy,
        moreDetailsLabel,
        isAccordionOpen,
        alidaAppId,
        offersSortBy,
        recomendationList,
    } = props

    const { md, sm, xs } = columns
    const { details, displayBanner, daysLeft, offerEndDate } = offer || {}
    const {
        returnPolicy,
        offersCardImgComponentName,
        bannerLogoImgComponentName,
        fallbackOfferImageUrl,
        fallbackImageForBrandLogo,
        fallbackImageForBrandLogoAltText,
    } = akamaiPolicy || {}
    const colSizes = `${PREFIX}-col-md-${md} ${PREFIX}-col-sm-${sm} ${PREFIX}-col-xs-${xs}`
    const setBannerLogo = (bannerName: string): BannerImagePath | undefined => {
        const getBannerName = bannerName?.toString()

        return (
            bannerImageList &&
            bannerImageList.find((imageList: BannerImagePath) => getBannerName === imageList.bannerId)
        )
    }
    const mutipleBanner = displayBanner?.includes(',')

    const { bannerImagePath, bannerImageAlt } = setBannerLogo(displayBanner) || {}

    const brandImage = mutipleBanner ? fallbackImageForBrandLogo : bannerImagePath
    const brandImageAlt = mutipleBanner ? fallbackImageForBrandLogoAltText : bannerImageAlt

    const componentClassName = `${PREFIX}-offers-card`

    const dateLabel =
        Number(daysLeft) <= magicNumber.ONE
            ? offerEndsTodayLabel
            : replaceMultipleStrWithDynamicVal(offerEndsLabel, [daysLeft])

    const newDate =
        Number(daysLeft) > magicNumber.SEVEN
            ? `${offerEndsDateLabel || 'Offer Ends:'} ${offerEndDate.replace(offerEndDateRegex, '$1/$2/$3')}`
            : dateLabel

    /**
     * Display Modal Header containg desc and close button
     * @return { JSX.Element }
     */
    const modalHeader = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-offers-card__modal--header`}>
                <p className={`${PREFIX}-offers-card__modal--title`}>{modalLabel}</p>
                <Button type="icon_button" size="mini" id="close-modal" onClick={() => closeModal(false)}>
                    <Icon type="ct-close" size="lg" />
                </Button>
            </div>
        )
    }

    /**
     * Display offer title and ends date
     * @return { JSX.Element }
     */
    const renderOfferTitleAndDate = (): JSX.Element => (
        <div className={`${PREFIX}-offers-card__${isFlyout ? 'modal-align' : 'wrapper-align'} ${classNameAlign}`}>
            <div className={`${isFlyout ? '' : `${PREFIX}-offers-card__wrapper--subtitle`}`}>
                <p
                    title={details?.offerShortDesc}
                    className={`${
                        isFlyout
                            ? `${PREFIX}-offers-card__modal--subtitle`
                            : `${PREFIX}-offers-card__wrapper--subtitle-description`
                    } ${classNameSubtitle ? classNameSubtitle : ''}`}>
                    {details?.offerShortDesc}
                </p>
            </div>
            {renderOffersLoyaltyContent()}
        </div>
    )

    /**
     * Display offer description
     * @return { JSX.Element }
     */
    const renderBodyDescription = (): JSX.Element => (
        <div className={`${componentClassName}__details-desc`}>
            {isFlyout && renderOfferTitleAndDate()}
            <h4 className={`${PREFIX}-h4`}>{details?.bonusDescription}</h4>
        </div>
    )

    /**
     * Renders offers expiry date component
     * @return {JSX.Element}
     */
    const renderOffersLoyaltyContent = (): JSX.Element => {
        return (
            !!hasLoyaltyAccount && (
                <div className={`${PREFIX}-offers-card__wrapper-end`}>
                    <span
                        className={`${PREFIX}-offers-card__${isFlyout ? `modal` : `wrapper`}--dates ${
                            classNameDates ? classNameDates : ''
                        }`}>
                        {newDate}
                    </span>
                </div>
            )
        )
    }

    /**
     * Display offer card
     * @return { JSX.Element }
     */
    const renderBody = (): JSX.Element => (
        <div className={`${isFlyout ? `${PREFIX}-offers-card__modal-content` : ''}`}>
            <Button
                type="modal_button"
                aria-haspopup="true"
                id="popup-button"
                disabled={isFlyout}
                onClick={e => {
                    const elem = e.currentTarget as HTMLElement
                    elem.setAttribute(previousElementName, 'true')
                    !isFlyout && openModal(offer)
                }}>
                <div className={`${componentClassName}__banner`}>
                    <img
                        className={`${componentClassName}__logo`}
                        alt={brandImageAlt}
                        {...applyAkamaiPolicy(brandImage, bannerLogoImgComponentName, false, returnPolicy)}
                    />
                    <img
                        className={`${componentClassName}__logo`}
                        alt=""
                        {...applyAkamaiPolicy(details?.badgeImageUrl, bannerLogoImgComponentName, false, returnPolicy)}
                    />
                </div>
                <picture>
                    <source
                        type="image/png"
                        {...applyAkamaiPolicy(details?.thumbnailUrl, offersCardImgComponentName, false, returnPolicy)}
                    />
                    <img
                        className={`${componentClassName}__img`}
                        alt=""
                        {...applyAkamaiPolicy(fallbackOfferImageUrl, offersCardImgComponentName, false, returnPolicy)}
                    />
                </picture>
                {!isFlyout && renderOfferTitleAndDate()}
            </Button>
            {isFlyout && renderBodyDescription()}
        </div>
    )

    /**
     * render offer status and display button
     * @return { JSX.Element }
     */
    const renderFooter = (): JSX.Element =>
        !!hasLoyaltyAccount && (
            <div className={`${componentClassName}__footer`}>
                <Button
                    id="activate-button"
                    type={status?.class as 'activate' | 'activated' | 'redeemed'}
                    size="medium"
                    disabled={status?.class == 'activated' || status?.class == 'redeemed'}
                    onClick={() =>
                        alidaAppId
                            ? activateOffer(offer, updateOfferState, filteredOffers, component, alidaAppId)
                            : activateOffer(offer, updateOfferState, filteredOffers, component, offersSortBy)
                    }>
                    <Icon type={status?.icon} size="lg" />
                    <span className={`${componentClassName}__status`}>{status?.label}</span>
                </Button>
            </div>
        )

    /**
     * Display more details section in offer modald
     * @return { JSX.Element }
     */
    const renderAccordion = (): JSX.Element => (
        <div className={`${componentClassName}__modal--more-details`}>
            <Accordion title={moreDetailsLabel} isHeaderOpen={isAccordionOpen}>
                <p className={`${PREFIX}-body-sm`}>{details?.bonusDetailedDescription}</p>
            </Accordion>
        </div>
    )

    /**
     * render offer recommendation List
     * @return { JSX.Element }
     */
    const renderRecommendationsComponent = (): JSX.Element => (
        <RecommendationsList
            title={recomendationList.title}
            productList={recomendationList?.productList}
            priceProps={recomendationList?.priceProps}
            a11yStrikeOutPrice={recomendationList.a11yStrikeOutPrice}
            a11yStrikeOutPriceRange={recomendationList.a11yStrikeOutPriceRange}
            language={recomendationList.language}
            returnPolicy={returnPolicy}
            selectedSchemaId={recomendationList.selectedSchemaId}
            isYouNeedThis={false}
            badgePriorities={recomendationList.badgePriorities}
            enableBadges={true}
            maximumNumberOfRecommendations={recomendationList.maximumNumberOfRecommendations}
        />
    )
    /**
     * Renders offer descriptions and footer
     * @return { JSX.Element }
     */
    const renderBodyAndFooter = (): JSX.Element => (
        <div
            className={`${
                isFlyout
                    ? `${componentClassName}__details`
                    : `${colSizes} ${classNameCarousel} ${componentClassName}__tile`
            }`}
            data-testid="weekly-offers-card">
            <div className={`${componentClassName}__${isFlyout ? 'modal-wrapper' : 'wrapper'}`}>
                {renderBody()}
                {isFlyout && renderAccordion()}
                {isFlyout && recomendationList?.productList && renderRecommendationsComponent()}
                {renderFooter()}
            </div>
        </div>
    )

    return (
        <>
            {isFlyout && modalHeader()}
            {renderBodyAndFooter()}
        </>
    )
}

OfferCard.propTypes = {
    classNameAlign: PropTypes.string,
    classNameCarousel: PropTypes.string,
}

OfferCard.defaultProps = {
    classNameAlign: '',
    classNameCarousel: '',
}

export default OfferCard
