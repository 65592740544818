import React from 'react'
import PropTypes from 'prop-types'

import { RecommendationsProps, ProductCardType } from './Recommendations.type'
import { ProductCardType as ProductCardGrid } from '../ProductGridView/ProductGrid.types'
import Carousel from '../Carousel'
import ProductCard from '../ProductReusableCard'
import { BREAKPOINTS, PREFIX } from '../config'
import { addDynamicToken, replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { appendQueryParamsToUrl, magicNumber } from '../../utils'
import { ProductDataTypeObj } from '../../../../app/src/redux/models/productData.interface'
import { getAccessibilityId } from '../../utils/getAccessibilityId'
import { getPageType } from '../..'

/**
 * Recommendations component
 * @param {RecommendationsProps} props -  title, productList, path, priceProps, productCardClick, maximumNumberOfRecommendations, a11yCarouselPreviousLabel, a11yCarouselNextLabel, a11yStrikeOutPrice
 * @return {JSX.Element} returns Recommendations component
 */
const Recommendations: React.FC<RecommendationsProps> = props => {
    const {
        title,
        productList,
        path,
        priceProps,
        productCardClick,
        carouselCallbackHandler,
        maximumNumberOfRecommendations,
        a11yCarouselPreviousLabel,
        a11yCarouselNextLabel,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        badgePriorities,
        language,
        recommendationCarouselNumberOfItems,
        imageDataComponentName,
        enableBadges,
        enableSaveStory,
        returnPolicy,
        a11yClickToReadFootnote,
        selectedSchemaId,
        sliderRef,
        a11yCloseIconLabel,
        a11yTooltipIcon,
        enableBoldedBrandName = false,
    } = props

    const {
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    } = priceProps

    const productProps = {
        ratingsAndReview: true,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    /**
     * Product Card Badge
     * @param {string[]}  badges - string array of badges
     * @return {sting[]} returns badge 'CLEARANCE' if 'enableBadges' is true and the item is on 'CLEARANCE'
     */
    const renderClearanceBadge = (badges: string[]): string[] => {
        return enableBadges && badges?.includes('CLEARANCE') ? ['CLEARANCE'] : []
    }

    /**
     * Number of Shown Cards
     * @return {number} returns the desired number of shown items in the recommendation carousel based on the screen size
     */
    const calcRecommendationCarouselNumberOfItems = (): number => {
        const windowInnerWidth = window.innerWidth
        if (windowInnerWidth >= BREAKPOINTS.desktopLarg) {
            return magicNumber.SEVEN
        }
        if (windowInnerWidth >= BREAKPOINTS.desktopStandard) {
            return magicNumber.SIX
        }
        if (windowInnerWidth >= BREAKPOINTS.desktopMin) {
            return magicNumber.FIVE
        }
        return recommendationCarouselNumberOfItems
    }

    /**
     * gets the ProductCarousel List
     *
     * @return {JSX.Element} returns array of JSX.Element
     */
    const getProductCarouselList = (): JSX.Element[] => {
        return productList.slice(0, maximumNumberOfRecommendations).map((product: ProductCardType, index: number) => {
            const {
                type,
                title: productTitle,
                brand,
                images,
                rating,
                ratingsCount,
                timeTestedPrice,
                price,
                priceShortMessage,
                priceLongMessage,
                longDescription,
                featureBullets,
                onlineOnly,
                discount,
                badges,
                currentPrice,
                feeMessages,
                url,
                code,
                isOnSale,
            } = product

            const pageType = getPageType()
            const urlParams = {
                rrecName: title,
                rrecReferrer: pageType,
                rrecProductId: code,
                rrecProductSlot: index + magicNumber.ONE,
                rrecSchemeId: selectedSchemaId,
                rrec: true,
            }

            return (
                <ProductCard
                    product={product as unknown as ProductCardGrid}
                    url={appendQueryParamsToUrl(url, urlParams)}
                    key={index}
                    productProps={productProps}
                    path={path}
                    idx={index}
                    cardType="grid"
                    title={productTitle}
                    type={type}
                    code={null}
                    brand={brand}
                    images={images}
                    rating={rating}
                    ratingsCount={ratingsCount || 0}
                    timeTestedPrice={timeTestedPrice}
                    price={price}
                    discount={discount}
                    priceShortMessage={priceShortMessage}
                    priceLongMessage={priceLongMessage}
                    longDescription={longDescription}
                    featureBullets={featureBullets}
                    onlineOnly={onlineOnly}
                    productCardClick={(event, productdata, idx) =>
                        productCardClick(event, productdata as unknown as ProductDataTypeObj, idx)
                    }
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    showRatingSection={true}
                    badges={renderClearanceBadge(badges)}
                    badgePriorities={badgePriorities}
                    language={language}
                    feeTitle={
                        feeMessages?.[0]?.value
                            ? replaceStrWithDynamicVal(
                                  addDynamicToken(feeMessages?.[0]?.feeTitle, '$x'),
                                  getFormattedPriceValue(language, feeMessages?.[0]?.value),
                              )
                            : ''
                    }
                    feeDisclaimerType={feeMessages?.[0]?.type}
                    feeDisclaimerMessage={feeMessages?.[0]?.feeDisclaimerMessage}
                    feeDisclaimerTitle={feeMessages?.[0]?.feeDisclaimerTitle}
                    currentPrice={currentPrice}
                    nowFromLabel={nowFromLabel}
                    saveFromLabel={saveFromLabel}
                    wasFromLabel={wasFromLabel}
                    fromLabel={fromLabel}
                    imageDataComponentName={imageDataComponentName}
                    returnPolicy={returnPolicy}
                    unitPriceLabel={unitPriceLabel}
                    promotionalPriceLabel={promotionalPriceLabel}
                    clearancePriceLabel={clearancePriceLabel}
                    thresholdValue={threshold}
                    enableSaveStory={enableSaveStory}
                    productDataId={code}
                    a11yClickToReadFootnote={a11yClickToReadFootnote}
                    accessibilityId={getAccessibilityId(
                        selectedSchemaId,
                        product?.skus?.[0]?.code ? product?.skus?.[0]?.code : code,
                    )}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                    a11yTooltipIcon={a11yTooltipIcon}
                    boldBrand={enableBoldedBrandName}
                    isOnSale={isOnSale}
                />
            )
        })
    }

    return (
        <div className={`${PREFIX}-recommendations`}>
            <div className={`${PREFIX}-recommendations__title`} data-testid="recommendations-title">
                <h2>{title}</h2>
            </div>
            {productList && productList.length > 0 && (
                <div className={`${PREFIX}-product__grid-view`}>
                    <Carousel
                        carouselList={getProductCarouselList()}
                        carouselCallbackHandler={carouselCallbackHandler}
                        path={path}
                        sliderRef={sliderRef}
                        a11yCarouselPreviousLabel={a11yCarouselPreviousLabel}
                        a11yCarouselNextLabel={a11yCarouselNextLabel}
                        desktopThreshold={calcRecommendationCarouselNumberOfItems()}
                        title={title}
                    />
                </div>
            )}
        </div>
    )
}

Recommendations.propTypes = {
    title: PropTypes.string,
    productList: PropTypes.array,
    path: PropTypes.string,
    priceProps: PropTypes.object,
    productCardClick: PropTypes.func,
    maximumNumberOfRecommendations: PropTypes.number,
    a11yCarouselPreviousLabel: PropTypes.string,
    a11yCarouselNextLabel: PropTypes.string,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    language: PropTypes.string.isRequired,
    recommendationCarouselNumberOfItems: PropTypes.number,
    imageDataComponentName: PropTypes.string,
    enableBadges: PropTypes.bool,
    enableSaveStory: PropTypes.bool,
    returnPolicy: PropTypes.func,
    a11yClickToReadFootnote: PropTypes.string,
    selectedSchemaId: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
}

export default Recommendations
