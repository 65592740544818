import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { BannerType, VehicleBannerProps, Cta } from './NewVehicleBanner.type'
import Icon from '../Icon'
import Button from '../Button'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { allWhitespace } from '../../globalConstants/regexPatterns.constant'
import VehicleHeader from './VehicleHeader'

export const NewVehicleBanner: React.FC<VehicleBannerProps> = props => {
    const [titleCtasState, setTitleCtasState] = useState({})
    const { isAdditionalInfo, setDefaultSelectedCard } = props
    const prevDefaultCtasStateRef = useRef<Record<number, boolean>>({})
    const prevIsTiresDataAvailable = useRef(props.isTiresDataAvailable)
    const flyOutRef = useRef()
    const newVehicleBannerClass = `${PREFIX}-vehicle-banner`

    // use Effect to set default props in next render
    useEffect(() => {
        if (
            prevDefaultCtasStateRef.current !== props.defaultCtasState ||
            prevIsTiresDataAvailable.current !== props.isTiresDataAvailable
        ) {
            const prevCtaState = props.isTiresDataAvailable ? { 0: false } : titleCtasState
            setTitleCtasState({ ...prevCtaState, ...props.defaultCtasState })
            prevDefaultCtasStateRef.current = props.defaultCtasState as Record<number, boolean>
            prevIsTiresDataAvailable.current = props.isTiresDataAvailable
        }
    }, [props.defaultCtasState, props.isTiresDataAvailable, titleCtasState])

    // useEffect to reset default selected card
    useEffect(() => {
        if (Object.keys(titleCtasState).length && isAdditionalInfo) {
            setDefaultSelectedCard('')
        }
    }, [titleCtasState, isAdditionalInfo, setDefaultSelectedCard])

    /**
     * function to set selected cta title
     * @param {string} ctaIndex
     * @param {boolean} state
     */
    const titleCtaAction = (ctaIndex: string, state: boolean): void => {
        setTitleCtasState({ [0]: !state, [ctaIndex]: state })
        props?.closeClickHandler?.(true)
    }

    // TODO: Need to fix this, its causing to close model when multiple model are present on vehicle banner
    const closeHandler = (): void => {
        setTitleCtasState({})
        props?.closeManageVehiclesDropdown()
    }
    useClickOutsideClose(flyOutRef, closeHandler, !props.showPackageInfoPopup, true)

    return (
        <>
            <div className={!props.hideVehicleSelectorBannerState ? newVehicleBannerClass : `${PREFIX}-xs-none`}>
                {!props.vehicleDetails?.replace(allWhitespace, '') ? (
                    <div className={`${newVehicleBannerClass}__not-fit-label`}>
                        <div>
                            <Icon type="ct-notification-caution" size="lg" />
                        </div>
                        <div className={`${newVehicleBannerClass}__not-fit-label__text`}>{props.mayNotFitLabel}</div>
                    </div>
                ) : (
                    <div className={`${newVehicleBannerClass}__details`}>
                        <div>
                            {props.vehicleImage ? (
                                <img
                                    src={props.vehicleImage}
                                    alt={props.vehicleImageAltText}
                                    className={`${newVehicleBannerClass}__image`}
                                />
                            ) : (
                                <div className={`${newVehicleBannerClass}__default-image`}>
                                    <Icon type={props.defaultImage} size="lg" />
                                </div>
                            )}
                        </div>
                        <VehicleHeader
                            shoppingForTitle={props.shoppingForTitle}
                            vehicleDetails={props.vehicleDetails}
                        />
                    </div>
                )}
                <div className={`${newVehicleBannerClass}__buttons`}>
                    <div
                        className={`${PREFIX}-ctas-container ${
                            props.isAdditionalInfo ? `${PREFIX}-ctas-container__additional-info` : ''
                        }`}>
                        {/* We are not using renderer to improve the readability fo the code. */}
                        {props?.ctas.map((cta: Cta, index) => {
                            const { AfterActionUi } = cta
                            return (
                                <>
                                    <Button
                                        id={cta?.ctaId ? cta?.ctaId : 'title-btn'}
                                        type="secondary"
                                        size="small"
                                        onClick={
                                            cta?.ctaAction
                                                ? cta.ctaAction
                                                : () => {
                                                      titleCtaAction(`${index}`, !titleCtasState[`${index}`])
                                                  }
                                        }>
                                        {cta.text}
                                    </Button>
                                    {titleCtasState[`${index}`] && AfterActionUi ? (
                                        <div
                                            className={`${PREFIX}-ctas-container__action-ui-container`}
                                            ref={flyOutRef}>
                                            <AfterActionUi index={index} {...cta.uiProps} />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )
                        })}
                    </div>
                    <div className={`${newVehicleBannerClass}__buttons__appointemnt`}>
                        {props.renderAutoAppointmentButton()}
                    </div>
                </div>
            </div>
        </>
    )
}

NewVehicleBanner.propTypes = {
    bannerType: PropTypes.oneOf(Object.values(BannerType)),
    title: PropTypes.string,
    subTitle: PropTypes.string,
    showSubtitle: PropTypes.bool,
    ctas: PropTypes.any,
    subCtas: PropTypes.any,
    defaultCtasState: PropTypes.any,
    showVehicleInfo: PropTypes.func,
    isNotShowIcon: PropTypes.bool,
    isAdditionalInfo: PropTypes.bool,
    closeClickHandler: PropTypes.func,
    pageType: PropTypes.string,
    isPackageFlow: PropTypes.bool,
    hideVehicleSelectorBannerState: PropTypes.bool,
    renderAutoAppointmentButton: PropTypes.func.isRequired,
    setDefaultSelectedCard: PropTypes.func,
    isTiresDataAvailable: PropTypes.bool,
    showPackageInfoPopup: PropTypes.bool,
    defaultImage: PropTypes.string,
    vehicleImage: PropTypes.string,
    vehicleImageAltText: PropTypes.string,
    shoppingForTitle: PropTypes.string,
    vehicleDetails: PropTypes.string,
}

export default NewVehicleBanner
