import { createAction } from 'typesafe-actions'

import { CartResponseErrorDTO } from '../models/cart.interface'
import { Order, OrderNotificationSubscriptionResponse } from '../models/orderConfirmation.interface'
import {
    GET_ORDER_CONFIRMATION_DATA_SUCCESS,
    GET_ORDER_CONFIRMATION_DATA_FAILURE,
    TOGGLE_NOTIFICATION_SUBSCRIPTION,
    SET_ORDER_NOTIFICATION_ERROR,
    PLACE_ORDER_CONFIRMATION_DATA_SUCCESS,
    PLACE_ORDER_CONFIRMATION_DATA_FAILURE,
} from '../types/orderConfirmation/orderConfirmation.actionTypes.contant'

export const orderConfirmationSuccess = createAction(
    GET_ORDER_CONFIRMATION_DATA_SUCCESS,
    (payload: Order) => payload,
)<Order>()

export const orderConfirmationFailure = createAction(GET_ORDER_CONFIRMATION_DATA_FAILURE)<CartResponseErrorDTO>()

export const toggleNotificationSubscription = createAction(
    TOGGLE_NOTIFICATION_SUBSCRIPTION,
)<OrderNotificationSubscriptionResponse>()

export const setOrderNotificationError = createAction(SET_ORDER_NOTIFICATION_ERROR)<boolean>()

export const placeOrderConfirmationSuccess = createAction(
    PLACE_ORDER_CONFIRMATION_DATA_SUCCESS,
    (payload: Order) => payload,
)<Order>()

export const placeOrderConfirmationFailure = createAction(PLACE_ORDER_CONFIRMATION_DATA_FAILURE)<CartResponseErrorDTO>()
