import { magicNumber } from './magicNumber'

/**
 *  @param {string} urlSearchParam All the params in the url
 *  @param {string} paramKey Name of the param which u want to find in the url
 *  @param {string} splitKey delimiter between the params
 *  @return {boolean} Returns value of that param
 */
export const getParamValue = (urlSearchParam: string, paramKey: string, splitKey: string): string | undefined => {
    const paramArray = urlSearchParam.replace('?', '').split(splitKey)
    return paramArray.find(el => el.startsWith(`${paramKey}=`))?.split('=')[magicNumber.ONE]
}
