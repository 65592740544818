export const anchorTag = 'A'
export const anchorTagSmall = 'a'
export const promoCtaClass = 'js-promo-cta-name'
export const anchorTargetBlank = '_blank'
export const payPalManualCancelError = 'manual paypal cancellation'

/**
 * Checkout page
 */
export const checkout = {
    contactInfoStep: 2,
    shippingInfoStep: 3,
    paymentInfoStep: 4,
    orderSummaryInfoStep: 5,
    defaultCheckoutStep: 2,
}

export enum AnalyticsOption {
    onlySTH = 'sth',
    onlyPickup = 'pick-up',
    pickupAndSTH = 'sth and pick-up',
    skippedText = 'skipped',
}
