import { libUtils } from '@nl/lib'
import { clp } from '../globalConstants'
import { IGlobalProps } from '../redux/models/commonContent.interface'
import sessionStorageService from '../utils/sessionStorageService'

const language = libUtils.getLanguage()

// SSO condition for clp url param
export const clpURLParam = new URLSearchParams(window.location.search).get(clp) === 'true'

/**
 * Determines SSO condition based on values from enableCLP in global props and clp parameter in page url
 * @return {boolean}
 */
export const enableSingleSignOn = (): boolean => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            console.log(error)
        }
    }
    const enableCLP = hasContent?.featureFlag?.enableCLP as boolean
    return enableCLP && clpURLParam
}

/**
 * Calls gigya login method and passes context info from originating page to CLP (Central Login Page)
 * @param {string} redirectURL originating page from which sign-in was initiated
 * @param {ContextType} additionalContext carried to the CLP and can be used to customize user experience
 * @return {void}
 */
export const ssoLoginHandler = (redirectURL: string, additionalContext?: Record<string, unknown>): void => {
    return window.gigya.sso.login({
        authFlow: 'redirect',
        redirectURL,
        context: {
            lang: language,
            ...additionalContext,
        },
    })
}
