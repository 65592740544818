import { ActionType, getType } from 'typesafe-actions'
import {
    hideVehicleSelectorBannerAction,
    isSuggestiveDropdownAction,
    isSuggestiveDropdownAPIFetchedAction,
    setSuggestedAttributeAction,
} from '../actionCreators/hideVehicleSelectorBanner.actionCreators'

type Action = ActionType<typeof hideVehicleSelectorBannerAction>

export interface HideVehicleSelectorBannerState {
    hideVehicleSelectorBannerState: boolean
    isSuggestiveDropdownState: boolean
    isSuggestiveDropdownAPIFetched: boolean
    suggestiveAttribute: string
}

export const initialState: HideVehicleSelectorBannerState = {
    hideVehicleSelectorBannerState: true,
    isSuggestiveDropdownState: false,
    isSuggestiveDropdownAPIFetched: false,
    suggestiveAttribute: '',
}

export const hideVehicleSelectorBannerReducer = (
    state: HideVehicleSelectorBannerState = initialState,
    action: Action,
): HideVehicleSelectorBannerState => {
    if (action.type === getType(hideVehicleSelectorBannerAction)) {
        return { ...state, hideVehicleSelectorBannerState: action.payload }
    } else if (action.type === getType(isSuggestiveDropdownAction)) {
        return { ...state, isSuggestiveDropdownState: action.payload }
    } else if (action.type === getType(isSuggestiveDropdownAPIFetchedAction)) {
        return { ...state, isSuggestiveDropdownAPIFetched: action.payload }
    } else if (action.type === getType(setSuggestedAttributeAction)) {
        return { ...state, suggestiveAttribute: action.payload }
    } else {
        return state
    }
}
