import { checkDataLength } from '@nl/lib'
import { CategoryBreadCrumb } from '../../redux/models/category.interface'
import getCategoriesFromURL from './getCategoriesFromURL'

/**
 * Function to check that price availability lazy load needed
 * @param {boolean} featureEnabled toggle from AEM configuration for this functionality
 * @param {SingleBreadCrumbType[]} breadcrumbList to check that category id from breadcrumbs presented in categoryIds
 * @param {string[]} categoryIds category ids list from AEM configuration where price availability lazy load functionality should be enabled
 * @return {boolean}
 */
export const checkIsPriceAvailabilityLazyLoadNeeded = (
    featureEnabled: boolean,
    breadcrumbList: CategoryBreadCrumb[],
    categoryIds: string[],
): boolean => {
    return (
        featureEnabled &&
        checkDataLength(breadcrumbList) &&
        checkDataLength(categoryIds) &&
        Boolean(
            breadcrumbList.find(breadcrumb => {
                return categoryIds.find(
                    categoryId => getCategoriesFromURL([]).extractCategoryId(breadcrumb.url) === categoryId,
                )
            }),
        )
    )
}
