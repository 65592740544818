import { createAction } from 'typesafe-actions'

import {
    POST_CONTACT_US_SUCCESS,
    POST_CONTACT_US_FAILURE,
    RESET_CONTACT_FORM_ACTION_VARIABLES,
} from '../types/contactUs/contactUs.actionTypes.constant'
import { ContactUsErrorResponse } from '../models/contactUs.interface'

export const contactUsSuccessAction = createAction(POST_CONTACT_US_SUCCESS)()

export const contactUsErrorAction = createAction(
    POST_CONTACT_US_FAILURE,
    (payload: ContactUsErrorResponse) => payload,
)<ContactUsErrorResponse>()

export const resetContactUsActionVariables = createAction(RESET_CONTACT_FORM_ACTION_VARIABLES)()
