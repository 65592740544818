import {
    FilteredCartData,
    CartOrderEntries,
    StoreDataDTO,
    ServiceDTO,
    CartItemsData,
    DeliveryAddressDTO,
    StockItemAvailability,
    AvailableDeliveryModesType,
    ProductStatus,
} from '../../redux/models/cart.interface'
import { addRemovePayLoad, AddToWishListPayload, WishlistResponse } from '../../redux/models/wishlist.interface'
import { BadgePriority } from '../../redux/models/commonContent.interface'
import { GlobalAccessibilityProps } from '../CartFlyout/CartFlyout.type'
import { ItemAvailability } from '../../redux/models/product.interface'
import { ProductCartDataForAnalytics } from '../../analytics/analytics.type'
import { iPrice } from '../../redux/models/productData.interface'

/**
 * Interface for cartExpressDeliveryLabels
 * @interface
 */
export interface cartDeliveryOptionsLabels {
    selectShippingMethodLabel?: string
    expressDeliveryTooltipHeaderText?: string
    expressDeliveryTooltipBodyText?: string
    standardDeliveryTooltipHeaderText?: string
    standardDeliveryTooltipBodyText?: string
    postalCodeNotEligibleExpressDeliveryLabel: string
    expressDeliveryNotAvailableLabel: string
    standardDeliveryNotAvailableLabel: string
}

/**
 * Interface for ShoppingCartProps
 * @interface
 */
export interface ShoppingCartProps {
    mgMsg?: string
    availableLabel?: string
    allyCloseLabel?: string
    a11yRemoveIconLabel: string
    addItemToWishlist?: (productCode: CartOrderEntries) => void
    removeitemFromWishlist?: (productData: CartOrderEntries) => void
    a11yWishlistIcon?: string
    authenticatedMessage?: string
    a11yServiceInstallation: string
    addonTitle: string
    availableByLabel?: string
    availableBetweenLabel?: string
    addServicesCTALabel?: string
    accessibilityGlobalProps: GlobalAccessibilityProps
    betweenLabel?: string
    bopis?: CartOrderEntries[]
    bopisGrouped?: Record<string, unknown>
    sthGrouped?: Record<string, unknown>
    servicesGrouped?: Record<string, unknown>
    badges?: string[]
    bulkMessage?: string
    badgePriorities?: BadgePriority
    bulkLabel: string
    cancelLabel?: string
    cancelProductLabel?: string
    clearancePriceLabel: string
    changeShipToHomeCTALabel?: string
    changeCTALabel?: string
    inStorePurchaseOnlyMsg: string
    cartItemsData?: FilteredCartData
    countInSfl: string
    curbsidePickupMessage?: string
    curbsidePickupLearnMoreLabel?: string
    checkNearByCTAHandler: (pCode?: string) => void
    curbsideText?: string
    cartItemAnalytics?: (item: CartOrderEntries | ServiceDTO, quantityReduced: number) => void
    coreChargesTooltipTitle?: string
    coreChargesTooltipParagraph?: string
    checkoutButtonClick?: boolean
    continueLabel?: string
    cartData?: CartItemsData
    deliveryOptionsData?: deliveryOptionsDTO
    deliveryOptions?: deliveryOptionsDTO
    discontinuedMsg: string
    discontinuedInStorePickUpMsg: string
    deliveryBOPISError: string
    deliverySTHError: string
    discontinuedInStoreShipFromMsg: string
    eachLabel: string
    exceededMsg?: string
    exceededMsgBar?: string
    emptyCartTitle?: string
    exceededTotalAvailableMsg?: string
    envFeeTooltipParagraph?: string
    errorToastMessage?: string
    emptyPostalCodeMessage?: string
    errorAnalyticsForInlineToast: (errorMessage: string) => void
    envFeeTooltipTitle?: string
    wishlistPageLink?: string
    homePageLink?: string
    isCurbside?: boolean
    itemRemovedMsg: string
    inlineToastWarning?: (value: boolean, index: number) => boolean
    inclCoreChargesLbl?: string
    isStoreEligibleForCurbside?: boolean
    inclEnvFeeLbl?: string
    language: string
    limitOfLabel?: string
    loginPageLink: string
    nearbyAvailabilityCTALabel: string
    numOfItems: number
    onChangeFulfillmentOption?: (option: string, entryNumber: number) => void
    onRemoveCartItem?: (isServiceRemoved?: boolean) => void
    onChangePCMAction?: (postalCode: string) => void
    onChangeAddress?: (value: boolean) => void
    outOfStockMsg: string
    outOfStockInStorePickUpMsg: string
    outOfStockInStoreShipFromMsg: string
    onlySomeInStockMsg: string
    onlySomeInStockShipFromMsg: string
    productCannotBePurchasedOnline: string
    serviceAddOnNotAvailableLabel: string
    pickAtStoreTitle?: string
    orderThreshold?: number
    freePickUpOverLabel?: string
    pickAtStoreCTALabel?: string
    plusCoreChargesLbl?: string
    pcmChangeCTALabel?: string
    pcmPostalCodeTitle?: string
    pcmChangePostalCodeTitle?: string
    pcmPostalCodeLabel?: string
    pcmErrorMsg?: string
    postalCodeOpen?: boolean
    postalCodeRegexVal?: RegExp
    plusEnvFeeLbl?: string
    quantityCoreFeeLabel?: string
    receiveBetweenLabel?: string
    resolveIssuesMsg?: string
    resetToastVariables?: (stateValue: boolean) => void
    removalModalTitle: string
    removalModalDescription: string
    removalModalCTA1Label: string
    removalModalCTA2Label: string
    removalModalServicesDescription: string
    removalModalAssociatedItemsDescription: string
    removalModalMoveToWishlistDescription: string
    shipToHomeTitle?: string
    servicesTitle?: string
    servicesDisclaimer?: string
    storeDetailsCTALabel?: string
    storeData?: StoreDataDTO | DeliveryAddressDTO
    sth?: CartOrderEntries[]
    serviceAvailableMsg: string
    serviceAddToCartHandler: (services: ServiceDTO[], productData?: CartOrderEntries) => void
    selectedServiceList?: CartOrderEntries[]
    saveLabel: string
    services?: CartOrderEntries[]
    successToastMessage?: string
    signInLabel?: string
    title?: string
    tempOutOfStockShipFromMsg: string
    tempDiscontinuedInStorePickUpMsg: string
    tempDiscontinuedInStoreShipFromMsg: string
    tempOutOfStockPickUpMsg: string
    thresholdValue?: number
    onlineOrdersNotAcceptedMsg?: string
    unitPriceLabel: string
    unauthenticatedMessage?: string
    wishlistGlobalPropsObject?: Record<string, string>
    updateCartData?: (quantity: number, isAdd: boolean, entryNumber: number, fromInputUpdate?: boolean) => void
    updatedLabel: string
    warningToastShow?: (show: boolean) => void
    [key: string]: unknown
    maxNumberOfStores?: number
    isDetailPage?: boolean
    dpDeliveryInstructions?: string
    discScheduleDelivery?: string
    discServiceInstallation?: string
    discCannotTrack?: string
    dpTrackOrderLabel?: string
    dpPrintReturnSlipLinkText?: string
    dpStatus1?: string
    dpStatus2?: string
    dpStatus3?: string
    dpStatus4?: string
    dpStatus5?: string
    dpStatus6?: string
    dpStatus7?: string
    dpStatus8?: string
    dpStatus9?: string
    changeBrowserBackUrl?: () => void
    nowFromLabel: string
    saveFromLabel: string
    wasFromLabel: string
    fromLabel: string
    setErrorInfoHandler: (isHardStopError: boolean, isSoftError: boolean) => void
    saveForLaterHandler: (isClicked: boolean) => void
    isSaveForLater: boolean
    addToCartActionHandler: (isClicked: boolean) => void
    addedToCartAction: boolean
    mInHomeDeliveryUnavailable?: string
    setRemoveButtonClicked: React.Dispatch<React.SetStateAction<boolean>>
    saleEndDaySoonMessage?: string
    limitedStockMessage?: string
    showWGIsNotEligibleWarningToastComponent: boolean
    pcmOpenOnCheckoutClick: boolean
    setPcmOpenOnCheckoutClick: React.Dispatch<React.SetStateAction<boolean>>
    setPreviousCartItemsData: React.Dispatch<React.SetStateAction<FilteredCartData>>
    packageIsNotCompleteMsg?: string
    completePackageMsg?: string
    isPostPOSOrderDetails?: boolean
    singleShippingMethodOption: boolean
    chooseMethodTitle: string
    itemsForceSetForSTH: string
    unavailableForSTHCartError: string
    becameUnavailableForSTHCartError: string
    itemsForceSetForBOPIS: string
    unavailableForBOPISCartError: string
    becameUnavailableForBOPISCartError: string
    itemUnavailableForSTH: string
    itemNoLongerAvailableForSTH: string
    itemUnavailableForBOPIS: string
    itemUnavailableAtStoreForBOPIS: string
    itemNoLongerAvailableForBOPIS: string
    unavailableForBOPIS: string
    unavailableAtStoreForBOPIS: string
    unavailableForSTH: string
    initialCartItemsData: FilteredCartData
    sflCtaLabel?: string
    sflAnchorId: string
    showCountInSfl?: boolean
    failureMessageAddingSaveForLater?: string
    orderStatusLabel?: string
    newPromotionsAppliedToastMsg?: string
    popUpTitleId?: string
    selectShippingMethodLabel?: string
    expressDeliveryTooltipHeaderText?: string
    expressDeliveryTooltipBodyText?: string
    standardDeliveryTooltipHeaderText?: string
    standardDeliveryTooltipBodyText?: string
    postalCodeNotEligibleExpressDeliveryLabel: string
    expressDeliveryNotAvailableLabel: string
    standardDeliveryNotAvailableLabel: string
    availableDeliveryModes: AvailableDeliveryModesType[]
    onChangeSTHDeliveryOptions: (option: string, entryNumber: Array<number>) => void
    cartExceedsSizeLimitMsg: string
    cartExceedsWeightLimitMsg: string
    itemsNotEligibleForShippingMethodGeneralMsg: string
    itemNotEligibleForExpressDeliveryMsg: string
    itemNotEligibleForStandardDeliveryMsg: string
    expressDeliveryDoesNotSupportHeavyOversizedMsg: string
    expressDeliveryLabel?: string
    isOrderConfirmationPage?: boolean
    insufficientQuantityOfProductForExpressDeliveryMsg: string
    insufficientQuantityOfProductAtSelectedStoreMsg: string
    postalCodeIsNotEligibleForExpressDeliveryMsg?: string
    valueBagsHeaderLabel?: string
    valueBagsTitleText?: string
    valueBagsTooltipText?: string
    valueBagsOptInLabel?: string
    valueBagsOutInLabel?: string
    valueBagsWarningText?: string
    isOptInOptOutNotClicked: () => boolean
    valueBagsInfoTextForExpressDelivery?: string
    isExpressDeliverySelected?: boolean
    checkExpressDeliveryEligibilityText?: string
    changeLabel?: string
    standardDeliveryNotAvailable?: string
    standardDeliveryUnavailabilityTooltipTitle?: string
    standardDeliveryUnavailabilityTooltipDescription?: string
    cartExceedsExpressThresholdLimitMsg?: string
    plusMinusSymbol?: string
}

/**
 * Interface for deliveryOptionsDTO
 * @interface
 */
export interface deliveryOptionsDTO {
    deliveryOptionCode: string
    deliveryTextWithAdditionalCost: string
    bulkTooltipTitle: string | JSX.Element
    bulkTooltipDesc: string | JSX.Element
    selected?: boolean
}

export interface ShippingMethodSwitcherProps {
    chooseMethodTitle: string
    shipToHomeLabel?: string
    pickAtStoreTitle?: string
    onlineOrdersNotAcceptedMsg?: string
    openBopisModal: () => void
    itemsForceSetForSTH: string
    unavailableForSTHCartError: string
    becameUnavailableForSTHCartError: string
    itemsForceSetForBOPIS: string
    unavailableForBOPISCartError: string
    becameUnavailableForBOPISCartError: string
    availabilityChangedToastMsg: string
    expressDeliveryTooltipHeaderText?: string
    expressDeliveryTooltipBodyText?: string
    accessibilityGlobalProps: GlobalAccessibilityProps
    checkExpressDeliveryEligibilityText?: string
    changeLabel?: string
    expressDeliveryNotAvailableLabel?: string
    postalCodeNotEligibleExpressDeliveryLabel?: string
    postalCodeIsNotEligibleForExpressDeliveryMsg?: string
    standardDeliveryNotAvailable: string
    itemsNotEligibleForShippingMethodGeneralMsg: string
    standardDeliveryUnavailabilityTooltipTitle?: string
    standardDeliveryUnavailabilityTooltipDescription?: string
    cartExceedsWeightLimitMsg?: string
    cartExceedsSizeLimitMsg?: string
    insufficientQuantityOfProductAtSelectedStoreMsg?: string
    setErrorInfoHandler: (isHardStopError: boolean, isSoftError: boolean) => void
    enterPostalCodeLabel?: string
    noPostalCodeMsg?: string
    cartExceedsExpressThresholdLimitMsg?: string
}

/**
 * Interface for cartWishlistProps
 * @interface
 */
export interface cartWishlistProps {
    wishlistItems: WishlistResponse
    setItemExists: React.Dispatch<React.SetStateAction<boolean>>
    analyticsParams: addRemovePayLoad
    guid: string
    dispatch: React.Dispatch<unknown>
    dispatchRemoveCartItem: (productData?: CartOrderEntries, removeAll?: boolean) => void
    analyticsLocation: string
    productData: CartOrderEntries
    mockFileName?: string
    removeAll: boolean
    addToWishlistPayLoad: AddToWishListPayload
}

/**
 * Interface for SingleProductAnalyticsData
 * @interface
 */
export interface SingleProductAnalyticsData<T extends AnalyticsOrderEntryData> {
    cart: AnalyticsCartData<T>
}

/**
 * Interface for AnalyticsCartData
 * @interface
 */
export interface AnalyticsCartData<T extends AnalyticsOrderEntryData> {
    saveForLaterId?: string
    cartId: string
    orderEntries: Array<T>
}

/**
 *  Interface for AnalyticsOrderEntryData
 *  @interface
 */
export interface AnalyticsOrderEntryData extends ProductCartDataForAnalytics {
    packagedItem?: boolean
    isBulk?: boolean
    rating?: number
    price?: number | iPrice
    productStatus?: ProductStatus
    quantity?: number
}
export interface ItemInlineErrorType {
    showInlineError: boolean
    errorMessage: string
    grayOutImage: boolean
    optionsToBeDisplayed: string[]
    fulfillmentDeliverError?: string
    hardStopError?: boolean
    isShowETAToast?: boolean
}

export interface SelectedDeliveryMode {
    isSTH: boolean
    isExpress: boolean
}

export interface GetErrorFunctionPropType {
    itemData: CartOrderEntries
    selectedDeliveryMode: SelectedDeliveryMode
    messageObject: {
        [Key: string]: string
    }
    itemType: FulfillmentMethods
    itemAvailabilityData: StockItemAvailability
    initialItemData: CartOrderEntries
}

export interface GetWishlistErrorPropType {
    extendedWishlistHandling: boolean
    itemData: CartOrderEntries
    messageObject: {
        [Key: string]: string
    }
    itemAvailabilityData: ItemAvailability
}

export enum OptionsOnCartItem {
    WISHLIST = 'WISHLIST',
    CHECK_NEAR_BY = 'CHECK_NEAR_BY',
    UPDATED_TEXT = 'UPDATED_TEXT',
    PRICE_AND_QUANTITY = 'PRICE_AND_QUANTITY',
    AVAILABILITY_INFO = 'AVAILABILITY_INFO',
    ADD_TO_CART = 'ADD_TO_CART',
    IN_STOCK_TAG = 'IN_STOCK_TAG',
    STOCK_INFO = 'STOCK_INFO',
    OUT_OF_STOCK = 'OUT_OF_STOCK',
    COMING_SOON = 'COMING_SOON',
    IN_STOCK_FOR_PICK_UP = 'IN_STOCK_FOR_PICK_UP',
    IN_STOCK_FOR_PURCHASE = 'IN_STOCK_FOR_PURCHASE',
}

export enum CartUpdateType {
    ADD_QUANTITY = 'ADD_QUANTITY',
    REMOVE_QUANTITY = 'REMOVE_QUANTITY',
    FULFILLMENT_CHANGE = 'FULFILLMENT_CHANGE',
    BULK_DELIVERY_OPTION = 'BULK_DELIVERY_OPTION',
    // NO_UPDATE is only for analytics condition checking
    NO_UPDATE = 'NO_UPDATE',
}

export enum FulfillmentErrorType {
    FULFILLMENT_STH_ERROR = 'FULFILLMENT_STH_ERROR',
    FULFILLMENT_BOPIS_ERROR = 'FULFILLMENT_BOPIS_ERROR',
}

export enum FulfillmentType {
    FULFILLED_STH = 'shipToHome',
    FULFILLED_BOPIS = 'storePickUp',
}

export interface PriceEtaType {
    isPriceChanged?: boolean
    isEtaChanged?: boolean
    isShippingFeeChanged?: boolean
}

export enum FulfillmentMethods {
    STH = 'STH',
    BOPIS = 'BOPIS',
    EXPRESS = 'EXPRESS',
}

export const cartPostalCodeError = '000100'
