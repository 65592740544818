import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/ctMoneyRedeemUpsell.actionCreators'

import {
    redeemCTMoneyAction,
    redeemCTMoneyErrorAction,
    resetRedeemCtMoneyAction,
} from '../actionCreators/ctMoneyRedeemUpsell.actionCreators'
import { CtMoneyRedeemResponse } from '../models/ctMoneyRedeem.interface'
import { ErrorResponse } from '../models/upsellingRewards.interface'

type Action = ActionType<typeof actions>

export interface CTMoneyRedeemUpsellState {
    redeemCTMoneySuccess: CtMoneyRedeemResponse
    redeemCTMoneyError: ErrorResponse | null
}

export const initialState: CTMoneyRedeemUpsellState = {
    redeemCTMoneySuccess: {} as CtMoneyRedeemResponse,
    redeemCTMoneyError: {} as ErrorResponse,
}

export const ctMoneyRedeemReducer = (
    state: CTMoneyRedeemUpsellState = initialState,
    action: Action,
): CTMoneyRedeemUpsellState => {
    switch (action.type) {
        case getType(redeemCTMoneyAction): {
            return { ...state, redeemCTMoneySuccess: action.payload }
        }
        case getType(redeemCTMoneyErrorAction): {
            return { ...state, redeemCTMoneyError: action.payload }
        }
        case getType(resetRedeemCtMoneyAction): {
            return { ...state, redeemCTMoneySuccess: {}, redeemCTMoneyError: null }
        }
        default:
            return state
    }
}
