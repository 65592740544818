import { Position } from '../components/Header/Header.type'

const navigatorService = (navigator => {
    /**
     * Get the current location details using navigator.
     * @param {Function} allowCallback
     * @param {Function} disallowCallback
     */
    const _getCurrentPosition = (allowCallback: (position: Position) => void, disallowCallback: () => void): void => {
        navigator.geolocation && navigator.geolocation.getCurrentPosition(allowCallback, disallowCallback)
    }

    return {
        getCurrentPosition: _getCurrentPosition,
    }
})(navigator)

export { navigatorService }
export default navigatorService
