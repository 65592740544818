/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'

import {
    fetchProductCardDataSuccessAction,
    fetchLoadMoreProductDataSuccessAction,
    updateFacetClickAction,
    updateSelectedSortAction,
    clearProductListAction,
    fetchProductCardDataRequestAction,
    fetchWeatherTechProductCardDataSuccessAction,
    fetchWeatherTechProductCardDataRequestAction,
    fetchProductCardDataErrorAction,
    fillPriceAndAvailability,
} from '../actionCreators'
import * as actions from '../actionCreators/productData.actionCreators'
import { ProductDataType, ProductDataTypeObj, SortOption } from '../models/productData.interface'
import mergePriceAvailabilityDataWithProducts from '../utils/mergePriceAvailabilityDataWithProducts'

type Action = ActionType<typeof actions>

export interface ProductDataState {
    productCardData: ProductDataType
    weatherTechProductCardData: ProductDataType
    loadMoreProductData: ProductDataTypeObj[]
    selectedFacetType: string
    selectedSortOption: SortOption
    productCardLoading: boolean
    weatherTechProductCardLoading: boolean
    errorStatusCode: number | null
}

export const initialState: ProductDataState = {
    productCardData: {} as unknown as ProductDataType,
    weatherTechProductCardData: {} as unknown as ProductDataType,
    loadMoreProductData: [] as unknown as ProductDataTypeObj[],
    selectedFacetType: '',
    selectedSortOption: {} as SortOption,
    productCardLoading: false,
    weatherTechProductCardLoading: false,
    errorStatusCode: null,
}

export const PRODUCT_DATA_STATE_KEY = 'productCardData'

export const productDataReducer = (state: ProductDataState = initialState, action: Action): ProductDataState => {
    switch (action.type) {
        case getType(fetchProductCardDataRequestAction): {
            return { ...state, productCardLoading: true }
        }
        case getType(fetchWeatherTechProductCardDataRequestAction): {
            return { ...state, weatherTechProductCardLoading: true }
        }
        case getType(fetchProductCardDataErrorAction): {
            const { status } = action.payload
            return { ...state, errorStatusCode: status, weatherTechProductCardLoading: false }
        }
        case getType(fetchProductCardDataSuccessAction): {
            const copyOfStateToMutate: ProductDataState = JSON.parse(JSON.stringify(state)) as ProductDataState

            return { ...copyOfStateToMutate, productCardData: action.payload, productCardLoading: false }
        }
        case getType(fetchWeatherTechProductCardDataSuccessAction): {
            const copyOfStateToMutate: ProductDataState = JSON.parse(JSON.stringify(state)) as ProductDataState

            return {
                ...copyOfStateToMutate,
                weatherTechProductCardData: action.payload,
                weatherTechProductCardLoading: false,
            }
        }
        case getType(fetchLoadMoreProductDataSuccessAction): {
            return { ...state, loadMoreProductData: action.payload }
        }
        case getType(updateFacetClickAction): {
            return { ...state, selectedFacetType: action.payload }
        }
        case getType(updateSelectedSortAction): {
            return {
                ...state,
                selectedSortOption: action.payload,
            }
        }
        case getType(clearProductListAction): {
            return { ...state, productCardData: {} as ProductDataType, loadMoreProductData: [] }
        }
        case getType(fillPriceAndAvailability): {
            return {
                ...state,
                productCardData: {
                    ...state.productCardData,
                    products: mergePriceAvailabilityDataWithProducts(action.payload, state.productCardData.products),
                },
            }
        }
        default:
            return state
    }
}
