import { ActionType, getType } from 'typesafe-actions'

import { setPencilBannerProps, setSecondaryNavProps } from '../actionCreators/headerProps.actionCreators'
import * as actions from '../actionCreators'
import { AccountPencilBannerProps } from '../models/accountMenu.interface'
import { SecondaryNavigationWrapperProps } from '../../components/SecondaryNavigation/SecondaryNavigationWrapper.type'

type Action = ActionType<typeof actions>

export interface Headers {
    pencilBannerProp: AccountPencilBannerProps
    secondaryNavProps: SecondaryNavigationWrapperProps
}

export const initialState: Headers = {
    pencilBannerProp: {} as AccountPencilBannerProps,
    secondaryNavProps: {} as SecondaryNavigationWrapperProps,
}

export const headerPropsReducer = (state: Headers = initialState, action: Action): Headers => {
    if (action.type === getType(setPencilBannerProps)) {
        return { ...state, pencilBannerProp: action.payload }
    } else if (action.type === getType(setSecondaryNavProps)) {
        return { ...state, secondaryNavProps: action.payload }
    } else {
        return state
    }
}
