import { createAction } from 'typesafe-actions'

import { CartResponseErrorDTO, FilteredCartData } from '../models/cart.interface'
import {
    GET_ORDER_DETAILS_DATA_FAILURE,
    GET_ORDER_DETAILS_DATA_SUCCESS,
    GET_ORDER_DETAILS_EXPIRED,
    GENERATE_RETURN_SLIP_SUCCESS,
    GENERATE_RETURN_SLIP_ERROR,
    RESET_RETURN_SLIP_DATA,
    UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_SUCCESS,
    UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE,
    RESET_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE,
} from '../types/orderDetails/orderDetails.actionTypes.constant'
import { OrderDetaiilsErrorDTO } from '../models/orderDetails.interface'

export const orderDetailsExpired = createAction(GET_ORDER_DETAILS_EXPIRED, (payload: boolean) => payload)<boolean>()

export const orderDetailsConfirmationSuccess = createAction(
    GET_ORDER_DETAILS_DATA_SUCCESS,
    (payload: FilteredCartData) => payload,
)<FilteredCartData>()

export const orderDetailsConfirmationFailure = createAction(GET_ORDER_DETAILS_DATA_FAILURE)<CartResponseErrorDTO>()

export const generateReturnSlipSuccess = createAction(
    GENERATE_RETURN_SLIP_SUCCESS,
    (payload: string) => payload,
)<string>()

export const generateReturnSlipError = createAction(GENERATE_RETURN_SLIP_ERROR, (payload: string) => payload)<string>()

export const resetReturnSlipData = createAction(RESET_RETURN_SLIP_DATA)()

export const updateBillingInformationOrderDetailsSuccess = createAction(
    UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_SUCCESS,
    (payload: boolean) => payload,
)<boolean>()

export const updateBillingInformationOrderDetailsFailure = createAction(
    UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE,
    (payload: OrderDetaiilsErrorDTO) => payload,
)<OrderDetaiilsErrorDTO>()

export const resetBillingInformationOrderDetailsFailure = createAction(
    RESET_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE,
)()
