import { useEffect, useRef, RefObject, useCallback } from 'react'

// this hook is used to add click event to close any
// section based on the reference passed when clicked outside the section

export const useClickOutsideClose = (
    ref: RefObject<HTMLElement>,
    callback: (e?: MouseEvent) => void,
    when: boolean,
    outsideClick: boolean,
): void => {
    const updateClosureOnRender = useRef(callback)

    useEffect(() => {
        updateClosureOnRender.current = callback
    })

    const handleCloseEvent = useCallback(
        (ev: MouseEvent): void | undefined => {
            if (outsideClick) {
                const node = ev.target
                if (ref.current && !ref.current.contains(node as HTMLElement)) {
                    ev.stopPropagation()
                    updateClosureOnRender.current(ev)
                }
            }
        },
        [outsideClick, ref],
    )

    useEffect(() => {
        if (outsideClick) {
            if (when) {
                document.addEventListener('click', handleCloseEvent, true)
                return () => document.removeEventListener('click', handleCloseEvent, true)
            } else return
        } else return
    }, [when, handleCloseEvent, outsideClick])
}
