const sessionStorageService = (sessionStorage => {
    const _getItem = (key: string) => {
        return sessionStorage.getItem(key)
    }

    const _setItem = (key: string, value: string) => {
        sessionStorage.setItem(key, value)
    }

    const _removeItem = (key: string) => {
        sessionStorage.removeItem(key)
    }

    const _clear = () => {
        sessionStorage.clear()
    }

    return {
        getItem: (key: string) => {
            return _getItem(key)
        },
        setItem: (key: string, value: string) => {
            _setItem(key, value)
        },
        removeItem: (key: string) => {
            _removeItem(key)
        },
        clear: () => {
            _clear()
        },
    }
})(sessionStorage)

export default sessionStorageService
