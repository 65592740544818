import { IStorageProvider } from './storage.interface'

/**
 * @class {LocalStorageProvider}
 */
export class LocalStorageProvider implements IStorageProvider {
    /**
     * This function used to get info from local storage by key
     * @param {string} key
     * @return {Object} localStorageInfo
     */
    get(key: string) {
        return new Promise((resolve, reject) => {
            try {
                resolve(JSON.parse(localStorage.getItem(key) as string))
            } catch (error) {
                reject(error)
            }
        })
    }

    /**
     * This function uset to set info to local storage by key
     * @param {string} key
     * @param {any} data
     * @return {Promise<any>} promise
     */
    set(key: string, data: any) {
        return new Promise((resolve, reject) => {
            localStorage.setItem(key, JSON.stringify(data))
            try {
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default LocalStorageProvider
