import React, { useEffect, useRef, useState, useCallback } from 'react'

import Icon from '../Icon'
import { PREFIX } from '../config'
import { TextAreaPropType } from './TextArea.type'
import { onFocusInputHandler, textAreaInputHandler, onFocusOutHandler } from './TextArea.utils'
import { getTextInputClassNames } from '../../utils/getTextInputClassNames'

export const TextArea: React.FC<TextAreaPropType> = ({
    id,
    error,
    errorIcon,
    label,
    hasMaxLimit,
    maxCharacters,
    onInputChange,
    path,
    value,
    isErrorFocus,
}: TextAreaPropType) => {
    // State Variables.
    const [isFocused, setIsFocused] = useState(false)
    const [charCount, setCharCount] = useState('')

    // Create constant to avoid sonar duplicate error.
    const componentClassName = `${PREFIX}-textarea`
    const nonInputSectionClassName = `${componentClassName}-non-input-section`

    // Toggle between classes based on the interaction.
    const errorFocusClass = getTextInputClassNames(error, isFocused, isErrorFocus, componentClassName)
    const noTextClass = charCount === '' ? `${componentClassName}__input-no-text` : ''
    const assessibilityErrorClass = error ? `${PREFIX}-accessibility-field--error` : ''

    // TextArea reference.
    const textAreaRef = useRef<HTMLDivElement>(null)

    const valueRef = useRef(value)
    const onInputChangeRef = useRef(onInputChange)

    /**
     * To capture the text and set the character limit to the contentEditable div
     *
     * @param {KeyboardEvent} event - capture the event to restrict the settings when the max limit is reached.
     * @return {void}
     */
    const inputHandler = useCallback(
        (event: KeyboardEvent): void => {
            textAreaInputHandler(event, setCharCount, textAreaRef, onInputChangeRef.current, hasMaxLimit, maxCharacters)
        },
        [textAreaRef, hasMaxLimit, maxCharacters, onInputChangeRef],
    )

    /**
     * Function to handle the paste.
     * Why this function? -> this function is used to limit the characters to the maxChar.
     * @param {React.ClipboardEvent<HTMLDivElement>} e
     * @return {void}
     */
    const onPasteHandler = (e: React.ClipboardEvent<HTMLDivElement>): void => {
        e.preventDefault()
        const pastedText = e.clipboardData.getData('text').slice(0, Number(maxCharacters))
        textAreaRef.current.innerText = pastedText
        setCharCount(pastedText)
    }

    // UseEffect to add keyup, keydown listeners
    useEffect(() => {
        const textAreaRefValue = textAreaRef.current
        // Below two initialization are used for persistance.
        textAreaRefValue.innerText = valueRef.current // Only used for initializing the value on load.
        setCharCount(valueRef.current) // For setting character count.
        textAreaRefValue.addEventListener('keydown', inputHandler)
        textAreaRefValue.addEventListener('keyup', inputHandler)
        return () => {
            textAreaRefValue.removeEventListener('keydown', inputHandler)
            textAreaRefValue.removeEventListener('keyup', inputHandler)
        }
    }, [inputHandler, valueRef])

    useEffect(() => {
        const elementInput: HTMLElement = document.querySelector(`.${componentClassName}__input`)
        const elementLabel: HTMLElement = document.querySelector(`.${componentClassName}__label`)
        elementInput.style.marginTop = `${elementLabel.offsetHeight}px`
    }, [componentClassName])

    return (
        <div className={`${componentClassName}`}>
            <div className={`${componentClassName}__container ${errorFocusClass}`} data-testid={'textarea-container'}>
                <div
                    ref={textAreaRef}
                    id={id}
                    data-testid={id}
                    className={`${componentClassName}__input ${noTextClass} ${assessibilityErrorClass}`}
                    onFocus={() => onFocusInputHandler(setIsFocused, id)}
                    onBlur={() => onFocusOutHandler(setIsFocused)}
                    onPaste={onPasteHandler}
                    role="textbox"
                    contentEditable="true"
                    aria-label={label}
                />
                <label className={`${componentClassName}__label`} htmlFor={id}>
                    {label}
                </label>
            </div>
            {(hasMaxLimit || error) && (
                <div className={nonInputSectionClassName}>
                    <div className={`${componentClassName}__error`} role="alert">
                        {error && (
                            <>
                                <Icon type={errorIcon} size="sm" path={path} />
                                <span className={`${componentClassName}__error-text`}>{error}</span>
                            </>
                        )}
                    </div>
                    {hasMaxLimit && (
                        <div
                            className={`${nonInputSectionClassName}-char-count`}>{`${charCount.length}/${maxCharacters}`}</div>
                    )}
                </div>
            )}
        </div>
    )
}

TextArea.defaultProps = {
    errorIcon: 'ct-warning',
}
