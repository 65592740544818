import React from 'react'

import { PREFIX } from '../components/config'

const renderQuantity = ({ qtyAbbrLabel, quantity }: { qtyAbbrLabel: string; quantity: number }): JSX.Element => {
    const orderConfirmationClass = `${PREFIX}-order-confirmation`
    return <div className={`${orderConfirmationClass}__quantity`}>{`${qtyAbbrLabel} ${quantity}`}</div>
}

export default renderQuantity
