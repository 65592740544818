import { createAction } from 'typesafe-actions'
import { PartialResponse } from '../models/errorInterceptor.interface'

import {
    ERROR_INTERCEPTED,
    ACTION_PERFORMED,
    PARTIAL_RESPONSE,
} from '../types/interceptors/fetchInterceptorError.actionTypes.constants'
// rename this error toast to http error
export const fetchInterceptorErrorAction = createAction(
    ERROR_INTERCEPTED,
    (payload: Record<string, unknown>) => payload,
)<Record<string, unknown>>()

export const actionPerformedOnScreenAction = createAction(ACTION_PERFORMED, (payload: string) => payload)<string>()

export const fetchInterceptorPartialResponse = createAction(
    PARTIAL_RESPONSE,
    (payload: PartialResponse | null) => payload,
)<PartialResponse | null>()
