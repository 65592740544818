import { ActionType, getType } from 'typesafe-actions'

import {
    contactUsSuccessAction,
    contactUsErrorAction,
    resetContactUsActionVariables,
} from '../actionCreators/contactUs.actionCreators'
import * as actions from '../actionCreators/contactUs.actionCreators'

type Action = ActionType<typeof actions>
export interface ContactUsState {
    contactUsSuccess: boolean
    contactUsFailure: boolean
}

export const initialState: ContactUsState = {
    contactUsSuccess: false,
    contactUsFailure: false,
}

export const contactUsReducer = (state: ContactUsState = initialState, action: Action): ContactUsState => {
    if (action.type === getType(contactUsSuccessAction)) {
        return { ...state, contactUsSuccess: true, contactUsFailure: false }
    } else if (action.type === getType(contactUsErrorAction)) {
        return { ...state, contactUsSuccess: false, contactUsFailure: true }
    } else if (action.type === getType(resetContactUsActionVariables)) {
        return { ...state, contactUsFailure: false, contactUsSuccess: false }
    } else {
        return state
    }
}
