import React, { useMemo, useEffect, useState } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import CarouselNavigationArrow from '../Carousel/CarouselNavigationArrow'
import { DateSlot, DatePickerProps } from './DatePicker.type'
import { getActiveSlideIndex, getDate, getWeekDayFormat, getWeekDayMobileFormat } from './DatePicker.helper'
import { getIsMobile } from '../../utils/getIsMobile'

/**
 * DatePicker component
 * @param {DatePickerProps} props
 * @return {JSX.Element}
 */
const DatePicker: React.FC<DatePickerProps> = ({ ...props }) => {
    const { className, selectedDate, dateList, onChangeDate } = props

    const activeSlideIndex = useMemo(() => {
        return getActiveSlideIndex(dateList, selectedDate)
    }, [dateList, selectedDate])

    // Create state to keep track of window width
    const [isMobile, setIsMobile] = useState(false)

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 7,
        nextArrow: <CarouselNavigationArrow type="ct-chevron-right" size="md" />,
        prevArrow: <CarouselNavigationArrow type="ct-chevron-left" size="md" />,
        initialSlide: activeSlideIndex,
    }

    useEffect(() => {
        // Setting state to keep track of device type
        setIsMobile(getIsMobile())
    }, [])

    const onDateChangeHandler = (date: DateSlot) => {
        if (date.isOpen && date.availableTimeSlotList?.length && date.date !== selectedDate) {
            onChangeDate(date.date)
        }
    }

    return (
        <>
            <Slider className={`date-carousel ${PREFIX}-carousel ${PREFIX}-date-carousel`} {...settings}>
                {dateList.map((date, i) => (
                    <label aria-label={`${date.dayOfWeek} ${date.date}`} key={i}>
                        <input
                            data-testid={date.date}
                            type="radio"
                            name="date"
                            disabled={!(date.isOpen && date.availableTimeSlotList?.length > 0)}
                            checked={date.date === selectedDate}
                        />
                        <button
                            className={`${PREFIX}-date__box ${className ? className : ''}`}
                            onClick={() => onDateChangeHandler(date)}
                            data-testid={`${date.dayOfWeek} ${date.date}__button`}>
                            <div className={`${PREFIX}-week-date`}>
                                {isMobile ? getWeekDayMobileFormat(date.date) : getWeekDayFormat(date.date)}
                            </div>
                            <div className={`${PREFIX}-numeral-date`}>
                                <span>{getDate(date.date)}</span>
                            </div>
                        </button>
                    </label>
                ))}
            </Slider>
        </>
    )
}

export default DatePicker

DatePicker.propTypes = {
    className: PropTypes.string,
    selectedDate: PropTypes.string,
    dateList: PropTypes.array,
    onChangeDate: PropTypes.func,
}
