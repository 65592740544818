import { useState, useEffect } from 'react'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { getFormattedPriceWithLocale } from '../../utils/formatPrice'
import { magicNumber } from '../../utils/magicNumber'
import { SaleCut, Price, SaleMessagesRule } from './Price.types'
import { CUT, LESS, PERCENT, EMPTY_STRING, DOLLAR_SYMBOL } from '../../globalConstants/global.constant'

/**
 * Hook to calculate save message
 * @param {SaleCut} saleCut
 * @param {SaleMessagesRule[]} saleMessagesRules
 * @param {Price} originalPrice
 * @return {string}
 */

export const useGenerateSaveMessage = (
    saleCut?: SaleCut,
    originalPrice?: Price,
    saleMessagesRules?: SaleMessagesRule[],
    currentPrice?: Price,
): string => {
    const [saveMessage, setSaveMessage] = useState('')

    const showSaveMessage = isArrayNotEmpty(saleMessagesRules) && !!saleCut?.value

    useEffect(() => {
        if (showSaveMessage) {
            setSaveMessage(generateSaveMessage(saleCut, saleMessagesRules, originalPrice, currentPrice))
        } else {
            setSaveMessage('')
        }
    }, [showSaveMessage, originalPrice, saleCut, saleMessagesRules, currentPrice])

    return saveMessage
}

const generateSaveMessage = (
    saleCut?: SaleCut,
    saleMessagesRules?: SaleMessagesRule[],
    originalPrice?: Price,
    currentPrice?: Price,
): string => {
    const roundedPercentage = Math.round(Number(saleCut?.percentage) / magicNumber.FIVE) * magicNumber.FIVE
    const saleCutValue = Number(saleCut?.value)
    const roundedSaleCutValue = getFormattedPriceWithLocale(Number(saleCut?.value?.toFixed(magicNumber.TWO))) ?? ''
    const roundedSaleCutValueWithoutDollarSymbol = roundedSaleCutValue?.replace(DOLLAR_SYMBOL, EMPTY_STRING)
    const messageReplacer = (message: string) =>
        message?.replace(PERCENT, `${roundedPercentage}`)?.replace(CUT, `${roundedSaleCutValueWithoutDollarSymbol}`)

    const saleMessagesRule = saleMessagesRules?.find(rule => {
        const percentage = Number(saleCut?.percentage?.toFixed(magicNumber.TWO))
        return (
            percentage <= rule.maxPercent &&
            percentage >= rule.minPercent &&
            (rule.absoluteCutType === LESS
                ? rule.absoluteValueThreshold > saleCutValue
                : rule.absoluteValueThreshold < saleCutValue)
        )
    })

    return !!originalPrice?.minPrice || !!currentPrice?.minPrice
        ? messageReplacer(saleMessagesRule?.upToSaveMessage)
        : messageReplacer(saleMessagesRule?.saveMessage)
}
