/* eslint-disable no-invalid-this */
import React, { RefObject } from 'react'
import { PREFIX, keyCodes, previousElementName } from '../../config'
import { Icon } from '@nl/lib'
import { disableFocusLock, enableFocusLock } from '@nl/lib/src/utils/focusLock'

/**
 * Function for wrap component to popup
 * @param {React.FC<any>} WrappedComponent
 * @param {function} onClosePopupAction
 * @return {React.ReactElement}
 */
function wrapIntoPopup(WrappedComponent: React.FC<any>, onClosePopupAction?: () => any) {
    /**
     * Wrapper class
     */
    class PopupWrapper extends React.Component<any> {
        mainContainerRef: RefObject<HTMLElement | null>
        closeButtonRef: RefObject<HTMLButtonElement | undefined>
        focusLockEvent: EventListener | null = null
        previousElement: HTMLElement | null = null
        /**
         * Constructor
         */
        constructor() {
            super()
            this.mainContainerRef = React.createRef()
            this.state = {
                isVisible: true,
            }
            this.closeButtonRef = React.createRef()
            this.previousElement = document.querySelector(`button[${previousElementName}]`)
        }

        /**
         * Mount component function
         */
        componentDidMount(): void {
            document.body.style.overflow = 'hidden'
            if (!this.props.avoidClosePopupOnOutsideClick) {
                this.mainContainerRef.current?.addEventListener('click', this.closePopupClickingOutside)
            }
            document.body.addEventListener('keydown', this.closePopupEsc)
            this.focusLockEvent = enableFocusLock(this.mainContainerRef)
            this.closeButtonRef.current && this.closeButtonRef.current.focus()
        }
        /**
         * Unmount component function
         */
        componentWillUnmount(): void {
            document.body.style.overflow = 'auto'
            document.body.removeEventListener('keydown', this.closePopupEsc)
            disableFocusLock(this.focusLockEvent)
        }

        /**
         * Close popup by click outside popup
         * @param {React.MouseEvent<Element, MouseEvent>} e - mouse event on click
         */
        closePopupClickingOutside = (e: Event): void => {
            if (e.target === this.mainContainerRef.current) {
                this.closePopup()
            }
        }

        /**
         * Close popup by press esc button function
         * @param {React.KeyboardEvent<HTMLButtonElement>} e - esc button event.
         */
        closePopupEsc = (e: KeyboardEvent): void => {
            if (e.keyCode === keyCodes.escKeyCode) {
                this.closePopup()
            }
        }

        /**
         * Close popup function
         */
        closePopup = (): void => {
            this.setState({ isVisible: false })
            this.componentWillUnmount()
            if (onClosePopupAction) {
                onClosePopupAction()
            }
            if (this.previousElement) {
                this.previousElement.focus() // Highlight the initiated button
                this.previousElement.removeAttribute(previousElementName)
            }
        }

        /**
         * Render wrapped component
         * @return {React.ReactElement}
         */
        render() {
            return this.state.isVisible ? (
                <div className={`${PREFIX}-popup__overlay`} ref={this.mainContainerRef} data-testid="popup-wrapper">
                    <div
                        role="dialog"
                        aria-labelledby={this.props.popUpTitleId as string}
                        aria-modal="true"
                        className={`${PREFIX}-popup__container`}>
                        <span className={`${PREFIX}-popup__close-wrapper`}>
                            <button
                                ref={this.closeButtonRef}
                                className={`${PREFIX}-popup__close-button`}
                                aria-label={this.props.closePopupButtonLabel as string}
                                title={this.props.closePopupButtonLabel as string}
                                onClick={this.closePopup}>
                                <Icon type="ct-close" size="md" />
                            </button>
                        </span>
                        <div className={`${PREFIX}-popup__container-body`}>
                            <WrappedComponent {...this.props} />
                        </div>
                    </div>
                </div>
            ) : null
        }
    }

    return PopupWrapper
}

export default wrapIntoPopup
