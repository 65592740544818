import {
    AddedServiceToCart,
    CartItemsData,
    CartModificationsDTO,
    CartResponseErrorDTO,
    FilteredCartData,
    MiniCartData,
    ServiceDTO,
    sflAddAndMoveCart,
    XhrInfo,
} from '../models/cart.interface'
import { RootState } from '../reducers'
import { CartState } from '../reducers/cart.reducer'

export const cartStateSelector = (state: RootState): CartState => state.cart
export const addedServiceToCartSelector = (state: RootState): AddedServiceToCart => state.cart.addedServiceToCart
export const addToCartErrorDataSelector = (state: RootState): CartResponseErrorDTO => state.cart.addToCartErrorData
export const cartItemsDataForAuthSelector = (state: RootState): FilteredCartData => state.cart.cartItemsDataForAuth
export const cartItemsDataSelector = (state: RootState): FilteredCartData => state.cart.cartItemsData
export const cartItemsDataCartSelector = (state: RootState): CartItemsData => state.cart.cartItemsData.cart
export const cartModificationsSelector = (state: RootState): CartModificationsDTO => state.cart.cartModifications
export const cartServicesSelector = (state: RootState): ServiceDTO[] => state.cart.cartServices
export const mergeCartSuccessSelector = (state: RootState): boolean => state.cart.mergeCartSuccess
export const sflAddAndMoveCartSelector = (state: RootState): sflAddAndMoveCart => state.cart.sflAddAndMoveCart
export const xhrInfoSelector = (state: RootState): XhrInfo | undefined => state.cart.xhrInfo
export const miniCartSelector = (state: RootState): MiniCartData => state.cart.miniCartItemsData
export const cartLimitItemsFailureSelector = (state: RootState) => state.cart.cartLimitItemsFailure
