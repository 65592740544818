import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../../config'
import { JumpListSearchProps } from '../JumpList.type'
import Icon from '../../Icon'
import Button from '../../Button'
import HiddenLabel from '../../HiddenLabel'

/**
 * JumpListSearch component
 * @param {JumpListSearchProps} props
 * @return {JSX.Element} returns JumpListSearch component
 */
const JumpListSearch: React.FC<JumpListSearchProps> = ({ ...props }) => {
    const {
        id,
        dropIcon,
        path,
        dropIconSize,
        searchBoxPlaceholder,
        showClearButton,
        clearLabel,
        searchMaxLength,
        allyClearIconLabel,
        allyCloseIconLabel,
        onChange,
        onClose,
        keyDownEvent,
        searchContainerRef,
    } = props

    const searchInputRef = useRef<HTMLInputElement>(null)

    const [filterValue, setFilterValue] = useState('')

    /**
     * Function to handle the search field change event to update the local state value
     * and call the onChange callback function with updated search value
     * @param {ChangeEvent<HTMLInputElement>} event
     */
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setFilterValue(value)
        onChange(value)
    }

    /**
     * Function to clear the search field value and focus the search field on clear text
     */
    const clearInput = () => {
        setFilterValue('')
        searchInputRef.current.focus()
        onChange('')
    }

    /**
     * Lifecycle method to focus the search field when component is mounted
     */
    useEffect(() => {
        searchInputRef.current.focus()
        // This hack helps to empty editable input if previous event was keyboard arrow
        searchInputRef.current.select()
    }, [])

    return (
        <div ref={searchContainerRef} className={`${PREFIX}-jumplist-search`} data-testid="jumplist-search-panel">
            <HiddenLabel className={`${PREFIX}-row`} labelText={searchBoxPlaceholder}>
                <input
                    id={id}
                    ref={searchInputRef}
                    enterkeyhint="search"
                    placeholder={searchBoxPlaceholder}
                    className={`${PREFIX}-jumplist-search__input`}
                    value={filterValue}
                    maxLength={searchMaxLength}
                    onChange={e => handleChange(e)}
                    onKeyDown={keyDownEvent}
                    data-testid={id}
                    autoComplete="off"
                />
                <div className={`${PREFIX}-jumplist-search__buttons`} aria-hidden="true">
                    {showClearButton && (
                        <div className={`${PREFIX}-jumplist-search__button`}>
                            <Button type="tertiary" ariaLabel={allyClearIconLabel} onClick={clearInput} id="clear-btn">
                                {clearLabel}
                            </Button>
                        </div>
                    )}
                    <div className={`${PREFIX}-jumplist-search__button ${PREFIX}-jumplist-search__button__collapse`}>
                        <Button
                            onClick={onClose}
                            ariaExpanded={true}
                            ariaLabel={allyCloseIconLabel}
                            type="icon_button"
                            id="collapse-btn">
                            <Icon type={dropIcon} path={path} size={dropIconSize} />
                        </Button>
                    </div>
                </div>
            </HiddenLabel>
        </div>
    )
}

JumpListSearch.propTypes = {
    dropIcon: PropTypes.string,
    path: PropTypes.string,
    dropIconSize: PropTypes.string,
    id: PropTypes.string,
    searchBoxPlaceholder: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    keyDownEvent: PropTypes.any,
    showClearButton: PropTypes.bool,
    clearLabel: PropTypes.string,
    allyClearIconLabel: PropTypes.string,
    allyCloseIconLabel: PropTypes.string,
    searchMaxLength: PropTypes.number,
}

export default JumpListSearch
