/**
 * Init Banner component
 * @param {Element} element
 */
const initBanner = (element: Element): void => {
    const isBannerClickable = element.querySelector('.nl-banner--is-clickable')

    if (isBannerClickable) {
        isBannerClickable.addEventListener('click', event => {
            const elemTarget: HTMLElement = event.target as HTMLElement

            if (elemTarget.nodeName !== 'BUTTON' && elemTarget.nodeName !== 'A') {
                const dataUrl = isBannerClickable.getAttribute('data-url')
                const dataTarget = isBannerClickable.getAttribute('data-target')

                if (dataUrl && dataTarget) {
                    window.open(dataUrl, dataTarget, 'noopener')
                }
            }
        })
    }
}

/**
 * Banner component
 * @param {string | Element} element
 */
const Banner = (element?: string | Element): void => {
    // Reference to the element where the data prop is added.
    const elementRef = typeof element === 'string' ? document.querySelectorAll(element) : element ? [element] : null

    if (elementRef) {
        elementRef.forEach((el: Element) => {
            initBanner(el)
        })
    }
}

export { Banner }
