import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import { TabsProps } from './Tabs.type'
import Tab from './Tab'
import { stringKeyCodes, useOnClickOutside } from '../../utils'
import {
    arrowActionIdentifier,
    handleArrowsAccessibility,
    handleClickOrEnterAccessibility,
    outsideActionHandler,
} from '../../helpers/keyboardAccessibility.helper'
import { useOnKeyDownOutside } from '../../utils/useOnKeyDownOutside'

/**
 * Tabs component
 * @return {JSX.Element} returns Tabs component
 */
const Tabs: React.FC<TabsProps> = ({ ...props }): JSX.Element => {
    const children = props.children.filter(child => child.props.label)
    const activeLabel = props.selectedTab || children[0]?.props?.label
    const [activeTab, setActiveTab] = useState(activeLabel)
    const tablistRef = useRef<HTMLUListElement>(null)
    useEffect(() => {
        setActiveTab(activeLabel)
    }, [activeLabel])

    const tabsOutsideActionHandler = useCallback(() => outsideActionHandler(tablistRef.current), [])

    // Handling edge cases with respect to Tabs accessibility
    useOnClickOutside(tablistRef, tabsOutsideActionHandler)
    useOnKeyDownOutside(tablistRef, tabsOutsideActionHandler)
    /**
     * Function to call callback function to set active tab
     * @param {string} tab selected tab label
     */
    const onClickTabItem = (tab: string): void => {
        setActiveTab(tab)
        if (props.tabClickedCallBack) {
            props.tabClickedCallBack(tab)
        }
    }

    /**
     * Function to return Tab header data
     * @return {JSX.Element} returns Overlay component
     */
    const getTabData = (): JSX.Element[] => {
        return children.map((child, index) => {
            return (
                <Tab
                    dataTestId={`testid-${index}`}
                    activeTab={activeTab}
                    key={child.props.label}
                    label={child.props.label}
                    currentIndex={index}
                    handleKeyDown={handleKeyDown}
                    handleClickOrEnter={handleClickOrEnter}
                />
            )
        })
    }

    /**
     * This function handles keydown event for tab
     * @param { React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { string } label
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, currentIndex: number, label: string) => {
        if (event.key === stringKeyCodes.rightArrow) {
            handleArrowsAccessibility(event, arrowActionIdentifier.NEXT, currentIndex, tablistRef.current)
        } else if (event.key === stringKeyCodes.leftArrow) {
            handleArrowsAccessibility(event, arrowActionIdentifier.PREVIOUS, currentIndex, tablistRef.current)
        } else if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.space) {
            handleClickOrEnter(event, currentIndex, label)
        }
    }

    /**
     * This function handles click or enter event for tab
     * @param { React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { string } label
     */
    const handleClickOrEnter = (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        label: string,
    ) => {
        handleClickOrEnterAccessibility(event, currentIndex, tablistRef.current)
        onClickTabItem(label)
    }

    return (
        <div className={`${PREFIX}-tabs`}>
            <ul ref={tablistRef} className={`${PREFIX}-tabs__list`} role="tablist">
                {getTabData()}
            </ul>
            <div className={`${PREFIX}-tabs__content`} role="tabpanel">
                {props.children.map(child => {
                    if (!props.isPrerender && child.props.label !== activeTab) return null
                    return child.props.children
                })}
            </div>
        </div>
    )
}

Tabs.propTypes = {
    children: PropTypes.any,
    tabClickedCallBack: PropTypes.func,
    isPrerender: PropTypes.bool,
}

export default Tabs
