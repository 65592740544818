import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { libUtils, updateUrlHistory } from '@nl/lib'
import { fetchDataUsingCategoryId } from '../../redux/actions'
import { MagicNumber } from '../../analytics/analytics.type'
import { seoHoc } from '../../Seo/components/Hoc/Seo.hoc'
import getPageType from '../../utils/getPageType'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { CategoryUrlType } from '../../redux/models/category.interface'
import { categoryIdDataSelector } from '../../redux/selectors/categoryIdData.selectors'
import BaseService from '../../services/base.service'
import { categoryURLIdentifier } from '../../config'
import { categoryUrlParser } from './categoryUrlParser'
import { SeoHelper } from '../../Seo/helpers/Seo.helper'
import { httpStatusCodes } from '../../globalConstants'
import { secondaryNavPropsSelector } from '../../redux/selectors/headerProps.selectors'

const CategoryInit: React.FC = () => {
    const categoryIdData = useSelector(categoryIdDataSelector)
    const secondaryNavProps = useSelector(secondaryNavPropsSelector)
    const { enablePrerenderMetaTagClp } = secondaryNavProps || {}
    const dispatch = useDispatch()

    /**
     * use Effect used to process init operation once when Redux provides dispatch as a tool
     * During initial operation:
     * - url parsed to extract category id and vehicle preselection attribute if they exist
     * - propagate the data from url by dispatching to REDUX
     */
    useEffect(() => {
        const pathName = window.location.pathname
        const isCategoryURL = pathName.includes(`/${categoryURLIdentifier}/`)
        const { categoryId } = categoryUrlParser(pathName)

        if (isCategoryURL) {
            dispatch(fetchDataUsingCategoryId(categoryId))
        }

        return () => {
            window.prefetchedCategoryPromise = null
        }
    }, [dispatch])

    /**
     * As soon as category data will be ready process redirect if the dynamic url is not the same as API provides
     * Until API has been updated, this POC will prevent re-direction if URL contains automotive attributes and process them accordingly
     */
    useEffect(() => {
        if (!BaseService.isMock() && checkDataLength(categoryIdData)) {
            if (categoryIdData?.categoryErrorResponse?.status === MagicNumber.FOURHUNDREDFOUR) {
                window.location.href = encodeURI(window.ODP?.globalLinks.pageNotFoundPath)
            }
            const { url } = categoryIdData
            if (Array.isArray(url) && url.length > 0) {
                const language = libUtils.getLanguage()
                const currentPath = window.location.pathname
                const linkData = url.find((link: CategoryUrlType) => {
                    return link.hreflang === language
                })

                const { vehicleParameters } = categoryUrlParser(currentPath)

                if (!checkDataLength(vehicleParameters) && checkDataLength(linkData) && linkData?.url !== currentPath) {
                    updateUrlHistory(linkData?.url)
                    const prerenderHeaderURL = window.location.origin + String(linkData?.url)
                    if (enablePrerenderMetaTagClp) {
                        SeoHelper.createMetaTag('name', 'prerender-status-code', String(httpStatusCodes.redirectCode))
                        SeoHelper.createMetaTag('name', 'prerender-header', `Location: ${prerenderHeaderURL}`)
                    }
                }
            }
        }
    }, [categoryIdData, enablePrerenderMetaTagClp])

    return null
}

export default seoHoc(CategoryInit, getPageType())
