import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { ButtonProps } from './Button.types'

/**
 * Renders button spinner
 * @return {JSX.Element}
 */
const renderButtonSpinner = (): JSX.Element => {
    return <div className={`${PREFIX}-button__spinner`}></div>
}

/**
 * Button component
 * @param {ButtonProps} props Button props
 * @param {React.ForwardedRef<HTMLButtonElement>} ref Button ref
 * @return {JSX.Element} returns Button component
 */
const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
    ({ ...props }, ref): JSX.Element => {
        const { quantumMetricAttribute } = props
        const size = props.size ? `${PREFIX}-button--${props.size}` : ''
        const type = `${PREFIX}-button--${props.type}`
        const state = props.state ? `${props.state}` : ''
        const ariaLabel = props.ariaLabel ? props.ariaLabel : ''
        const modifierClass = props.modifierClass ? `${props.modifierClass}` : ''
        const quantumMetricDataAttribute = quantumMetricAttribute?.type
            ? { [`data-qm-${quantumMetricAttribute?.type}`]: quantumMetricAttribute?.value }
            : {}
        return (
            <button
                data-testid={props.id}
                id={props.id}
                {...quantumMetricDataAttribute}
                onClick={props.onClick}
                aria-label={`${ariaLabel}`}
                aria-disabled={props.disabled}
                className={`${PREFIX}-button ${type} ${size} ${state} ${modifierClass}`}
                disabled={props.disabled}
                aria-expanded={props.ariaExpanded}
                ref={ref}
                type={props.buttonType}
                onKeyDown={val => (props.onKeyDown ? props.onKeyDown(val) : null)}
                dap-wac-link-section={props.linkSection}
                dap-wac-value={props.linkValue}
                role={props.role ? props.role : 'button'}
                aria-controls={props.ariaControls}>
                {props.showSpinner ? renderButtonSpinner() : props.label}
                {props.children}
            </button>
        )
    },
)

Button.displayName = 'Button'

Button.defaultProps = {
    type: 'primary',
    buttonType: 'button',
}

Button.propTypes = {
    type: PropTypes.oneOf([
        'primary',
        'primary_reverse',
        'primary_red',
        'secondary',
        'secondary_reverse',
        'tertiary',
        'tertiary_reverse',
        'icon_button',
        'secondary_reverse_light',
        'modal_button',
        'activate',
        'activated',
        'redeemed',
        'all_activated',
        'link',
        'primary_light',
        'call_to_action',
    ]),
    id: PropTypes.string,
    size: PropTypes.oneOf(['mini', 'small', 'medium-small', 'medium', 'large']),
    state: PropTypes.oneOf(['hover', 'active', 'focus', 'disabled']),
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    buttonType: PropTypes.oneOf(['button', 'submit', 'reset']),
    label: PropTypes.string,
    showSpinner: PropTypes.bool,
    onKeyDown: PropTypes.func,
    ariaExpanded: PropTypes.bool,
    modifierClass: PropTypes.string,
    ariaControls: PropTypes.string,
}

export default Button
