import React, { useEffect } from 'react'

/**
 * This hook is created to read keyup outside event.
 * It is recommended to use for filters in PLP. Specially when you want to close last opened dropdown on row change
 * @param {React.MutableRefObject} ref - ref to any element
 * @param {function} handler - handler
 * @param {React.MutableRefObject} parentRef
 */
export const useOnKeyUpOutside = (
    ref: React.MutableRefObject<Element>,
    handler: (event: KeyboardEvent) => void,
    parentRef?: React.MutableRefObject<HTMLElement>,
): void => {
    useEffect(() => {
        const parentRefNode = parentRef?.current || document
        const listener = (event: KeyboardEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref?.current || ref?.current?.contains(event.target as Node)) return
            handler(event)
        }
        parentRefNode?.addEventListener('keyup', listener)

        return () => parentRefNode.removeEventListener('keyup', listener)
    }, [ref, handler, parentRef])
}
