import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { TableRowData, tableProps } from './Table.type'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import { magicNumber, replaceStrWithDynamicVal } from '../../utils'

/**
 * Generate table headings
 * @param {any} tableData array of tableData
 * @return {JSX.Element[]} returns table headings
 */
const getHeader = (tableData: Record<string, unknown>[]): JSX.Element[] => {
    const [firstRow] = tableData
    const keys = Object.keys(firstRow)
    return keys.map(key => {
        return <th key={key}>{key}</th>
    })
}

/**
 * Generate table cell in the table
 * @param {any} rowData array of tableData
 * @return {JSX.Element[]} returns table cells
 */
const getCell = (rowData: Record<string, unknown>): JSX.Element[] => {
    return Object.keys(rowData).map(key => {
        return <td key={rowData[key] as string}>{rowData[key]}</td>
    })
}

/**
 * Generate Table Data in the table
 * @param {any} rowData array of tableData
 * @return {JSX.Element[]} returns table cells
 */
const getArray = (rowData: unknown[]): JSX.Element[] => {
    return rowData.map(key => {
        const data = key as TableRowData
        if (data?.a11Hidden) {
            return (
                <td key={data.label}>
                    <span aria-hidden="true">{data.label}</span>
                    <span className="sr-only">{replaceStrWithDynamicVal(data.a11Label, data.label)}</span>
                </td>
            )
        }
        return <td key={key as string}>{key}</td>
    })
}

/**
 * Generate row in the table
 * @param {any} tableData array of tableData
 * @param {string} headerType type of table
 * @param {string} tableRichText type of table
 * @return {JSX.Element[]} returns Overlay component
 */
const getRowsData = (
    tableData: Record<string, unknown>[],
    headerType: string,
    tableRichText?: boolean,
): JSX.Element[] => {
    return tableData.map((row, index) => {
        if (headerType.toUpperCase() == 'COLUMN') {
            const columnKey = Object.keys(row)
            const columnKeyString = columnKey.toString()
            const tableHeader = tableRichText ? (
                <SanitizeStringContentWrapper stringContent={columnKeyString}>
                    {memoizedStringContent => <th dangerouslySetInnerHTML={{ __html: memoizedStringContent }}></th>}
                </SanitizeStringContentWrapper>
            ) : (
                <th> {columnKey} </th>
            )
            return (
                <tr key={index} className={index === magicNumber.ZERO ? `first-row` : ''}>
                    {tableHeader}
                    {getArray(row[columnKey[0]] as unknown[])}
                </tr>
            )
        } else {
            return <tr key={index}>{getCell(row)}</tr>
        }
    })
}

/**
 * Table component
 * @return {JSX.Element} returns Table component
 */
const Table: React.FC<tableProps> = ({ ...props }): JSX.Element => {
    const { tableType, tableData, headerType, tableRichText, ariaLabel, tableModifierClass = '', tableId } = props

    const tableClass = `${PREFIX}-table`
    const type = tableType && `${PREFIX}-table--${tableType}`

    return (
        <table className={`${tableClass} ${type} ${tableModifierClass}`} aria-label={ariaLabel} id={tableId}>
            <thead>{headerType.toUpperCase() === 'ROW' && <tr>{getHeader(tableData)}</tr>}</thead>
            <tbody>{getRowsData(tableData, headerType, tableRichText)}</tbody>
        </table>
    )
}

Table.defaultProps = {
    tableType: 'bordered',
    headerType: 'row',
}

Table.propTypes = {
    tableType: PropTypes.string,
    tableRichText: PropTypes.bool,
    tableData: PropTypes.any,
    headerType: PropTypes.oneOf(['row', 'column']),
    ariaLabel: PropTypes.string,
    tableModifierClass: PropTypes.string,
    tableId: PropTypes.string,
}

export default Table
