import { Dispatch } from 'redux'

import {
    fetchSuggestionsSuccessAction,
    fetchSuggestionsErrorAction,
    fetchSuggestedProductsSuccessAction,
    fetchSuggestedProductsErrorAction,
} from '../actionCreators'
import { searchService } from '../../services/searchService'
import { Suggest, SuggestErrorResponse } from '../models/search.interface'

/**
 * get suggestion data from 46
 * @param {string} searchTerm
 * @param {string} storeId
 * @return {Promise}
 */
export const fetchSuggestions = (searchTerm: string, storeId?: string) =>
    searchService.getSuggestions(searchTerm, storeId)

/**
 * handle successfull suggestion fetch
 * @param {Object} data
 * @return {Promise}
 */
export const setSuggestions = (data: { data: Suggest }) => (dispatch: Dispatch) => {
    return dispatch(fetchSuggestionsSuccessAction(data.data))
}

/**
 * handle unsuccessful suggestion fetch
 * @param {Object} error
 * @return {Promise}
 */
export const setSuggestionsErr = (error: { response: SuggestErrorResponse }) => (dispatch: Dispatch) => {
    const errorResponse = error.response
    return dispatch(fetchSuggestionsErrorAction(errorResponse))
}

/**
 * get products for suggested term. since there is no dedicated API available to fetch products only, suggestion API needs to be called to get products for the term
 * if fetching most popular products, all contents of suggest state needs to be updated (including suggested keyword and category)
 * if fetching products for one of the highlighted suggestions, only the product field in suggest state is updated
 * @param {string} suggestedTerm
 * @param {string} storeId
 * @return {Promise}
 */
export const fetchProducts = (suggestedTerm: string, storeId: string): Promise<any> =>
    searchService.getSuggestions(suggestedTerm, storeId)

/**
 * handle successfull products fetch
 * @param {Object} data
 * @param {flyerEventsParams} flyerParams
 * @param {boolean} isDigitalFlyer
 * @return {Promise}
 */
export const setProducts = (data: { data: Suggest }) => (dispatch: Dispatch) => {
    dispatch(fetchSuggestedProductsSuccessAction(data.data))
}

/**
 * handle unsuccessful products fetch
 * @param {Object} error
 * @return {Promise}
 */
export const setProductsErr = (error: { response: SuggestErrorResponse }) => (dispatch: Dispatch) => {
    const errorResponse = error.response
    dispatch(fetchSuggestedProductsErrorAction(errorResponse))
}
