import { Dispatch } from 'redux'

import { setAuthouredBreadcrumbDataAction } from '../actionCreators/breadcrumbAuthouredData.actionCreator'
import { BreadcrumbLinkData } from '../models/productData.interface'

/**
 * function to store package breadcrumb list
 * @param { BreadcrumbLinkData[] } value
 * @return {void}
 */
export const setAuthouredBreadcrumbData =
    (value: BreadcrumbLinkData[]) =>
    (dispatch: Dispatch): void => {
        dispatch(setAuthouredBreadcrumbDataAction(value))
    }
