import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/estimateDeliveryDate.actionCreators'
import { EstimateDeliveryDate } from '../models/estimateDeliveryDate.interface'
import {
    fetchEstimateDeliveryDateAction,
    estimateDeliveryDateFailureAction,
    resetEstimateDeliveryDateAction,
} from '../actionCreators/estimateDeliveryDate.actionCreators'

type Action = ActionType<typeof actions>

export interface estimateDeliveryDateState {
    estimateDeliveryDateData: EstimateDeliveryDate | null
    estimateDeliveryDateFailure: boolean
    estimateDeliveryDateSuccess: boolean
}

export const initialState: estimateDeliveryDateState = {
    estimateDeliveryDateData: null,
    estimateDeliveryDateFailure: false,
    estimateDeliveryDateSuccess: false,
}

export const estimateDeliveryDateReducer = (
    state: estimateDeliveryDateState = initialState,
    action: Action,
): estimateDeliveryDateState => {
    switch (action.type) {
        case getType(fetchEstimateDeliveryDateAction): {
            return {
                ...state,
                estimateDeliveryDateData: action.payload,
                estimateDeliveryDateFailure: false,
                estimateDeliveryDateSuccess: true,
            }
        }
        case getType(estimateDeliveryDateFailureAction): {
            return {
                ...state,
                estimateDeliveryDateFailure: true,
                estimateDeliveryDateSuccess: false,
            }
        }
        case getType(resetEstimateDeliveryDateAction): {
            return {
                ...state,
                estimateDeliveryDateData: null,
                estimateDeliveryDateFailure: false,
                estimateDeliveryDateSuccess: true,
            }
        }
        default:
            return state
    }
}
