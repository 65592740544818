import React, { useState } from 'react'
import {
    DynamicVehicleSeoCopyBlockProps,
    HeadlineProps,
    DynamicVehicleSeoCopyBlockButtonProps,
    ButtonIconProps,
} from './DynamicVehicleSeoCopyBlock.type'
import { PREFIX } from '../../config'
import { Button, Icon } from '@nl/lib'
import { useSelector } from 'react-redux'
import {
    getPreselectedVehicleMake,
    getPreselectedVehicleModel,
    getPreselectedVehicleYear,
} from '../../redux/selectors/preselectedVehicle.selectors'
import { productDataSelector } from '../../redux/selectors/product.selectors'
import { isFitmentRequiredSelector } from '../../redux/selectors/categoryIdData.selectors'
import { getDataObject, hasBeenShown } from './DynamicVehicleSeoCopyBlock.helper'

/**
 * Headline - A React component that renders the headline for a dynamic vehicle seo copy block.
 *
 * @param {HeadlineProps} headline
 * @return {React.ReactElement}
 */
const Headline = ({ headline }: HeadlineProps): React.ReactElement => {
    return <h2>{headline}</h2>
}

/**
 * ButtonIcon - A React component that renders the button icon for a dynamic vehicle seo copy block.
 *
 * @param {ButtonIconProps} iconType
 * @return {React.ReactElement}
 */
const ButtonIcon = ({ iconType }: ButtonIconProps): React.ReactElement => {
    return <Icon size="lg" type={iconType} />
}

/**
 * DynamicVehicleSeoCopyBlockButton - A React component that renders the button for a dynamic vehicle seo copy block.
 *
 * @param {Function} onClick
 * @param {string} label
 * @param {string} modifierClass
 * @param {boolean} isExpanded
 * @return {React.ReactElement}
 */
const DynamicVehicleSeoCopyBlockButton = ({
    onClick,
    label,
    modifierClass,
    isExpanded,
}: DynamicVehicleSeoCopyBlockButtonProps): React.ReactElement => {
    return (
        <Button type="tertiary" modifierClass={`${modifierClass}`} onClick={onClick} label={label}>
            <ButtonIcon iconType={isExpanded ? 'ct-chevron-up' : 'ct-chevron-down'} />
        </Button>
    )
}

/**
 * DynamicVehicleSeoCopyBlock component
 * @return {JSX.Element} returns DynamicVehicleSeoCopyBlock component
 */
const DynamicVehicleSeoCopyBlock = ({
    makeHeadline,
    makeText,
    makeModelHeadline,
    makeModelText,
    yearMakeModelHeadline,
    yearMakeModelText,
    viewMoreLabel,
    viewLessLabel,
}: DynamicVehicleSeoCopyBlockProps): JSX.Element => {
    const [isExpanded, setIsExpanded] = useState(false)

    const year = useSelector(getPreselectedVehicleYear)
    const make = useSelector(getPreselectedVehicleMake)
    const model = useSelector(getPreselectedVehicleModel)

    const productData = useSelector(productDataSelector)
    const isFitmentRequired = useSelector(isFitmentRequiredSelector) || false

    const preselectedVehicleValuesMap = [
        { key: 'year', value: year || '' },
        { key: 'make', value: make || '' },
        { key: 'model', value: model || '' },
    ]

    const dataObject = getDataObject(
        yearMakeModelHeadline,
        yearMakeModelText,
        makeModelHeadline,
        makeModelText,
        makeHeadline,
        makeText,
        preselectedVehicleValuesMap,
    )

    const changeState = () => {
        setIsExpanded(previousValue => !previousValue)
    }

    const showSingleLine = isExpanded ? '' : `${PREFIX}-vehicle-seo-copy-block--show-single-line`

    return hasBeenShown(productData, isFitmentRequired, dataObject.headline, dataObject.text) ? (
        <div className={`${PREFIX}-vehicle-seo-copy-block ${PREFIX}-container`}>
            <Headline headline={dataObject.headline} />
            <div className={`${PREFIX}-vehicle-seo-copy-block--text ${showSingleLine}`}>
                <div dangerouslySetInnerHTML={{ __html: dataObject.text }} />
            </div>
            {isExpanded ? (
                <DynamicVehicleSeoCopyBlockButton
                    label={viewLessLabel}
                    onClick={changeState}
                    modifierClass={`${PREFIX}-vehicle-seo-copy-block--view-less-button`}
                    isExpanded={isExpanded}
                />
            ) : (
                <DynamicVehicleSeoCopyBlockButton
                    label={viewMoreLabel}
                    onClick={changeState}
                    modifierClass={`${PREFIX}-vehicle-seo-copy-block--view-more-button`}
                    isExpanded={isExpanded}
                />
            )}
        </div>
    ) : (
        <></>
    )
}

export default DynamicVehicleSeoCopyBlock
