import { ActionType, getType } from 'typesafe-actions'
import { fetchToggleAction } from '../actionCreators/buttonToggle.actionCreators'

type Action = ActionType<typeof fetchToggleAction>

export interface ToggleState {
    toggle: string
}

export const initialState: ToggleState = {
    toggle: '',
}

export const toggleReducer = (state: ToggleState = initialState, action: Action): ToggleState => {
    if (action.type === getType(fetchToggleAction)) {
        return { ...state, toggle: action.payload }
    } else {
        return state
    }
}
