import { BaseService } from '../base.service'
import { getEnvironment } from '../../environments'
import appCacheService from '../../utils/appCacheService'
import { getHttpClient } from '../../httpClient'
import { PriceAvailabilityDataResponse, RequestBodyItem } from './priceAvailabilityService.type'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'

const httpClient = getHttpClient()
const environment = getEnvironment()

/**
 * Service to get price availability data
 */
class PriceAvailabilityService extends BaseService {
    /**
     * Creates the url
     * @method
     * @return {URL} url
     */
    private createUrl(): URL {
        const storeId = appCacheService.preferredStoreId.get()
        const {
            language,
            API_BASE_URL,
            API_ENDPOINTS: { productPriceAvailability },
        } = environment

        const url = new URL(`${API_BASE_URL}${productPriceAvailability}`)
        url.searchParams.set('lang', language)
        url.searchParams.set('storeId', storeId)

        return url
    }

    /**
     * Creates request body item
     * @method
     * @param {string} code
     * @param {string} brand
     * @return {RequestBodyItem} item
     */
    private createRequestBodyItem(code: string, brand: string): RequestBodyItem {
        return {
            code: code,
            brand: brand,
        }
    }

    /**
     * Creates request payload
     * @method
     * @param {ProductDataTypeObj[]} products
     * @return {URL} url
     */
    private createRequestBody(products: ProductDataTypeObj[]): string {
        const requestBodyProducts = products
            .filter(product => !product?.skus?.length)
            .map(product => this.createRequestBodyItem(product.code, product?.brand?.label || ''))

        const requestBodySkus = products
            .flatMap(
                product =>
                    product?.skus?.map(sku => ({
                        brandLabel: product?.brand?.label || '',
                        ...sku,
                    })) || [],
            )
            .map(({ code, brandLabel }) => this.createRequestBodyItem(code, brandLabel))

        return JSON.stringify({
            products: requestBodyProducts,
            skus: requestBodySkus,
        })
    }

    /**
     * This function used to fetch price and availability data for specified products
     * @method
     * @param {ProductDataTypeObj[]} products
     * @return {AxiosPromise<APIProductGroupResponse>} PriceAvailabilityDataResponse
     */
    fetchPriceAvailabilityData(products: ProductDataTypeObj[]): Promise<PriceAvailabilityDataResponse> {
        const url = this.createUrl()
        const requestBody = this.createRequestBody(products)

        return new Promise<PriceAvailabilityDataResponse>((resolve, reject) => {
            httpClient
                .apiPost<PriceAvailabilityDataResponse>(url, requestBody)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export const priceAvailabilityService = new PriceAvailabilityService()
