import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers/index'

/**
 * This Function is used to fire data layer analytics event when general links are clicked
 * @param {string} linkType internal or external link
 * @param {string} linkValue link value
 * @param {string} location page location
 */

export const invokeGenericLinkClickAnalytics = (
    linkType = '',
    linkValue = '',
    location = '',
    featureType = '',
): void => {
    const {
        event: { interaction },
        eventParameters: {
            action: { clickOn, link },
        },
    } = analyticsAttributes

    getProvider().push({
        event: interaction,
        eventParameters: {
            action: `${clickOn} ${linkType} ${link}`,
            category: featureType ? `${featureType} ${interaction}` : `${getPageType()} ${interaction}`,
            label: linkValue,
            value: '',
            location: `${getPageType()} - ${location}`,
        },
    })
}
