import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Button, isArrayNotEmpty, libUtils, Radio, FullPageSpinner, Filters, extractKeyValues } from '@nl/lib'
import { PREFIX, previousElementName, pageTypes, BREAKPOINTS, REF_URL_KEY } from '../../config'
import { RootState } from '../../redux/reducers'
import { FacetHorizontalBarProps } from './FacetHorizontalBar.type'
import { updateFacetClick, updateSelectedSort } from '../../redux/actions/ProductData.action'
import {
    sortFacetType,
    maxFourFactes,
    maxFiveFacets,
    containerMaxWidthMd,
    filterMargins,
    facetsFont,
    labelsBounds,
    windowPaddingBounds,
    containerMaxWidthLg,
} from './Facets.constant'
import { removeParam, updateParam, pushUrlHistory, queryParameters } from '@nl/lib'

import { Facet, FacetRange, FacetValue, SortOption } from '../../redux/models/productData.interface'
import { clearFilterAnalytics, openFilterActionAnalytics } from '../../analytics/components/facetAnalytics'
import { FacetTypes } from './HorizontalPillBar/HorizontalPillBar.types'
import getPageType from '../../utils/getPageType'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { MagicNumber } from '../../analytics/analytics.type'
import CustomDropdown from '../../../../lib/src/components/CustomDropdown'
import PlpService from '../../services/plpService/plpService'
import { useProductPerPage } from '../ProductGridView/ProductCard.hooks'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { categoryIdDataSelector, isFitmentRequiredSelector } from '../../redux/selectors/categoryIdData.selectors'
import { productCardDataSelector, productDataStateSelector } from '../../redux/selectors/productData.selectors'
import sessionStorageService from '../../utils/sessionStorageService'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { useDispatchOfFetchProductData } from './useDispatchOfFetchProductData.hook'

const FacetHorizontalBar: React.FC<FacetHorizontalBarProps> = (props): JSX.Element => {
    const {
        sortTitle,
        allCategoriesLabel,
        moreFiltersLabel,
        sortAndFiltersMobileLabel,
        enableFiveFilterFacets,
        enableFilterFacetsEnhancement,
        maxPriceErrorMessage,
        maxPriceLabel,
        minPriceErrorMessage,
        minPriceLabel,
        showLessLabel,
        showMoreLabel,
        showMoreThreshold,
        clearAllFilterLabel,
        allFiltersMobileLabel,
        filterPanelRef,
    } = props
    const { productCardLoading, weatherTechProductCardLoading } = useSelector(productDataStateSelector)
    const productCardData = useSelector(productCardDataSelector)
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const categoryIdData = useSelector(categoryIdDataSelector)
    const { facets = [] } = productCardData
    const isFitmentRequired = useSelector(isFitmentRequiredSelector)
    const { commonContentAvailable } = useSelector((state: RootState) => state.commonContent)
    const productToFetchCount = useProductPerPage()
    const pageType = getPageType()

    const currentLocale = libUtils.getLanguage()
    const isCategoryPage = pageTypes.categoryPages.includes(pageType)
    const maxNumberFacet = enableFiveFilterFacets ? (maxFiveFacets as number) : (maxFourFactes as number)
    const factetFilterSearchThreshold = MagicNumber.FIFTEEN

    const [[searchInputPlaceholderText, noSearchResults]] = extractKeyValues(commonContentAvailable, [
        {
            general: ['searchInputPlaceholderText', 'noSearchResults'],
        },
    ]) as Array<string[]>

    const filterRow = useRef<HTMLDivElement>(null)

    const [accessibilityContent, setAccessibilityContent] = useState({} as typeof commonContentAvailable.accessibility)
    useEffect(() => {
        commonContentAvailable?.accessibility && setAccessibilityContent(commonContentAvailable.accessibility)
    }, [commonContentAvailable])

    const fetchProducts = useDispatchOfFetchProductData()

    const getCategoryData = useCallback(() => {
        let shouldShowCategoryDropdown = false
        let categoryDataArr: FacetValue[] = []
        if (isCategoryPage && checkDataLength(categoryIdData)) {
            if (isArrayNotEmpty(categoryIdData.subcategories)) {
                categoryDataArr = categoryIdData.subcategories.map(
                    ({ name, url }): FacetValue => ({
                        label: name,
                        url,
                        selected: false,
                    }),
                )
                shouldShowCategoryDropdown = true
            }
        } else {
            if (checkDataLength(facets)) {
                const filteredData = facets.find(item => item.type === FacetTypes.BREADCRUMB)
                categoryDataArr =
                    filteredData?.values.map(
                        ({ label, url, clearUrl, selected }: FacetValue): FacetValue => ({
                            label,
                            url,
                            clearUrl,
                            selected,
                        }),
                    ) || []
                shouldShowCategoryDropdown = !!categoryDataArr
            }
        }
        return {
            shouldShowCategoryDropdown,
            categoryData: categoryDataArr,
        }
    }, [categoryIdData, facets, isCategoryPage])

    const componentClassName = `${PREFIX}-facet-bar--desktop`

    const selectedSortOption = productCardData.sortBy?.find(sort => sort.selected)?.title || ''

    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth)
    libUtils.useGlobalResizeEvent(() => setWindowInnerWidth(window.innerWidth))

    const onSortFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const selectedSortByOption = productCardData.sortBy.find(sort => sort.title === e.target.value) as SortOption
        dispatch(updateSelectedSort(selectedSortByOption))
    }

    /**
     * Dispatches Sort Facet Click
     *
     * @param {Event} e - React.MouseEvent
     * @return {void} Nothing
     */
    const dispatch = useDispatch()
    const sortFacetClicked = (e: React.MouseEvent<Element, MouseEvent>): void => {
        dispatch(updateFacetClick(sortFacetType))
        setAttributeFunction(e)
        openFilterActionAnalytics(sortTitle)
    }

    /**
     * Dispatches Featured Facet Click
     *
     * @param {Event} e - React.MouseEvent
     * @param {string} label - Label of facet
     * @return {void} Nothing
     */
    const featuredFacetClicked = (e: React.MouseEvent<Element, MouseEvent>, label: string): void => {
        dispatch(updateFacetClick(label))
        setAttributeFunction(e)
        openFilterActionAnalytics(label)
    }

    /**
     * Dispatches More Facet Click
     *
     * @param {Event} e - React.MouseEvent
     * @return {void} Nothing
     */
    const moreFacetsClicked = (e: React.MouseEvent<Element, MouseEvent>): void => {
        dispatch(updateFacetClick('all'))
        setAttributeFunction(e)
        openFilterActionAnalytics(moreFiltersLabel)
    }

    /**
     * Set's Previous Element True
     *
     * @param {Event} e - React.MouseEvent
     * @return {void} Nothing
     */
    const setAttributeFunction = (e: React.MouseEvent<Element>): void => {
        const button = e.target as HTMLButtonElement
        button.setAttribute(previousElementName, 'true')
    }

    const categoryDropdown = document?.getElementById('productCategory') as HTMLElement
    const categoryDropdownWidth = (categoryDropdown?.offsetWidth + filterMargins) | MagicNumber.ZERO
    // Get width of the facet
    const getFacetComputedWidth = (text: string): number => {
        const textWidth = libUtils.getTextWidth(text, facetsFont)
        return Math.ceil(textWidth) + filterMargins
    }

    // Get width of the available space on the filters bar
    const filterBarComputedWidth = useMemo((): number => {
        const bounds =
            getFacetComputedWidth(selectedSortOption) +
            getFacetComputedWidth(moreFiltersLabel) +
            libUtils.getTextWidth(sortTitle, facetsFont) +
            labelsBounds +
            windowPaddingBounds

        if (windowInnerWidth >= BREAKPOINTS.desktopLgWidth) return containerMaxWidthLg - bounds

        if (windowInnerWidth > containerMaxWidthMd && windowInnerWidth < BREAKPOINTS.desktopLgWidth)
            return containerMaxWidthMd - bounds

        return windowInnerWidth - bounds
    }, [selectedSortOption, moreFiltersLabel, sortTitle, windowInnerWidth])

    // Filters facets array based on at least one value should have result count and not breadcrumb except srp page
    // Slices filtered Array based on maxFacets limit (applicable only for enableFilterFacetsEnhancement === false)
    const maxFacetToDisplay = useMemo((): Facet[] => {
        const facetsWithResults = facets?.filter((eachFacet: Facet) => {
            const facetVals = eachFacet?.values
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const totalResultCount = facetVals?.reduce((count, val) => (count + val?.count) as number, 0)

            const shouldShowFacetBtn = !(eachFacet?.id === FacetTypes.CATEGORY_ID && isCategoryPage)
            return totalResultCount > 0 && shouldShowFacetBtn
        })

        const facetBarWidth = isCategoryPage ? filterBarComputedWidth - categoryDropdownWidth : filterBarComputedWidth

        if (enableFilterFacetsEnhancement) {
            const facetsToShow: Facet[] = []
            let acc = 0
            for (const facet of facetsWithResults) {
                acc += getFacetComputedWidth(facet.label)
                if (acc <= facetBarWidth) facetsToShow.push(facet)
                else break
            }
            return facetsToShow
        }

        return facetsWithResults.slice(0, maxNumberFacet)
    }, [
        facets,
        enableFilterFacetsEnhancement,
        filterBarComputedWidth,
        maxNumberFacet,
        categoryDropdownWidth,
        isCategoryPage,
    ])

    /* Get (count) the number of filters applied
     * @return {number}
     */
    const countSelectedFacets = (): number => {
        if (facets.length) {
            let selectedFacets = 0
            facets.forEach((facet: Facet | FacetRange) => {
                selectedFacets += (facet.values ?? []).filter(value => value.selected).length
            })
            return selectedFacets
        } else {
            return 0
        }
    }

    /**
     * @method numberfiltersAppliedComp Method used to show number of filters applied.
     * @return { JSX.Element | null}
     */

    const numberfiltersAppliedComp = (): JSX.Element | null => {
        const renderNumberfiltersApplied = countSelectedFacets() > 0
        return renderNumberfiltersApplied ? (
            <span className={`${PREFIX}-number-filters-applied `}>({countSelectedFacets()})</span>
        ) : null
    }

    const renderSortAndFilter = (): JSX.Element => {
        return (
            <Button type="secondary" size="small" onClick={moreFacetsClicked}>
                {enableFilterFacetsEnhancement ? allFiltersMobileLabel : sortAndFiltersMobileLabel}
                {numberfiltersAppliedComp()}
            </Button>
        )
    }

    const renderSortByFilter = () =>
        enableFilterFacetsEnhancement && (
            <div className={`${componentClassName}__sortlabel`}>
                <label htmlFor="SortByFilter" className={`${componentClassName}__sortbylabel`}>
                    {sortTitle}:
                </label>
                <CustomDropdown
                    label={selectedSortOption}
                    filterRow={filterRow}
                    filterPanelRef={filterPanelRef}
                    isMaxHeightRemove={true}
                    id="SortByFilter">
                    <ul className={`${componentClassName}__listfilter`}>
                        {productCardData.sortBy?.map(sortOption => (
                            <li key={sortOption.title} className={`${PREFIX}-facet-modal__sort-options`}>
                                <Radio
                                    id={sortOption.url}
                                    label={sortOption.title}
                                    name="sortings"
                                    checked={sortOption.selected}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSortFilterChange(event)}
                                    value={sortOption.title}
                                />
                            </li>
                        ))}
                    </ul>
                </CustomDropdown>
            </div>
        )

    const renderCategoryDropdown = () =>
        enableFilterFacetsEnhancement && (
            <div id="productCategory">
                <CustomDropdown
                    label={allCategoriesLabel}
                    filterRow={filterRow}
                    filterPanelRef={filterPanelRef}
                    forSEOOnLoad={true}>
                    <ul>
                        {getCategoryData().categoryData?.map(val => (
                            <li key={val.label} className={`${PREFIX}-filter-section__multiselect-item`}>
                                <a
                                    data-testid="categories-facet"
                                    tabIndex={0}
                                    className={`${PREFIX}-filter-section__breadcrumb-item`}
                                    href={val.url}>
                                    {val.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </CustomDropdown>
            </div>
        )

    /**
     * function call on filter Change
     * @param {string} url url to update history
     * @param {string} facet
     * @param {string} facetTitle
     * @param {boolean} facetSelected
     * @param {boolean} priceFacetUpdated
     * @param {number} minPrice
     * @param {number} maxPrice
     * @param {string} customPriceUrl
     */
    const onFilterChange = (
        url: string,
        facet: string,
        facetTitle: string,
        facetSelected: boolean,
        priceFacetUpdated: boolean,
        minPrice?: number,
        maxPrice?: number,
        customPriceUrl?: string,
    ): void => {
        let queryParams = window.location.search
        const isApiCall = !PlpService.isMock()

        if (priceFacetUpdated && isApiCall) {
            // Update min price and max price when price facet changes
            if (maxPrice) {
                queryParams = updateParam(
                    queryParams || customPriceUrl,
                    queryParameters.priceUpperBound,
                    queryParameters.divider,
                    `${maxPrice}`,
                )
            } else {
                queryParams = removeParam(queryParams, queryParameters.priceUpperBound, queryParameters.divider)
            }
            if (minPrice) {
                queryParams = updateParam(
                    queryParams || customPriceUrl,
                    queryParameters.priceLowerBound,
                    queryParameters.divider,
                    `${minPrice}`,
                )
            } else {
                queryParams = removeParam(queryParams, queryParameters.priceLowerBound, queryParameters.divider)
            }
        } else {
            queryParams = url
        }
        sessionStorageService.setItem(REF_URL_KEY, window.location.href)
        pushUrlHistory(queryParams)
        fetchProducts(queryParams, selectedPreferredStoreId, isFitmentRequired, productToFetchCount)
        if (facet === clearAllFilterLabel) {
            clearFilterAnalytics()
        }
    }

    // Please remove the below functions when feature toggle is unnecessary
    const getToggledCategory = () =>
        getCategoryData().shouldShowCategoryDropdown && isCategoryPage && renderCategoryDropdown()

    /**
     * function call for spinning wheel feature for filters
     * @return { JSX.Element | null} return FullPageSpinner component
     */

    const renderSpinningWheel = (): JSX.Element | null => {
        return (
            <FullPageSpinner
                showSpinner={productCardLoading || weatherTechProductCardLoading || false}
                globalSpinnerColor={'black'}
            />
        )
    }

    const getToggledFilters = () =>
        enableFilterFacetsEnhancement ? (
            <>
                {maxFacetToDisplay.map((facet: Facet) => (
                    <CustomDropdown
                        key={facet.label}
                        label={<span>{facet.label || 'Unknown'}</span>}
                        filterPanelRef={filterPanelRef}
                        filterRow={filterRow}>
                        <Filters
                            currentLocale={currentLocale}
                            customPriceFacet={
                                facets.find(oneFacet => oneFacet.type === FacetTypes.CUSTOM_PRICE) as FacetRange
                            }
                            facet={facet}
                            maxPriceErrorMessage={maxPriceErrorMessage}
                            maxPriceLabel={maxPriceLabel}
                            minPriceErrorMessage={minPriceErrorMessage}
                            minPriceLabel={minPriceLabel}
                            onFilterChange={onFilterChange}
                            showLessLabel={showLessLabel}
                            showMoreLabel={showMoreLabel}
                            showMoreThreshold={showMoreThreshold}
                            getFilterOnly={true}
                            closeOnFirstClick={true}
                            showSearch={
                                facet.type !== FacetTypes.RANGE && facet?.values?.length > factetFilterSearchThreshold
                                    ? true
                                    : false
                            }
                            enableFilterFacetsEnhancement={enableFilterFacetsEnhancement}
                            searchInputPlaceholderText={searchInputPlaceholderText}
                            noSearchResults={noSearchResults}
                            closeLabel={accessibilityContent.a11yCloseIconLabel}
                            a11ySuggestionResultAvailable={accessibilityContent.a11ySuggestionResultAvailable}
                        />
                    </CustomDropdown>
                ))}
            </>
        ) : (
            maxFacetToDisplay.map((facet: Facet) => {
                return (
                    <Button
                        type="secondary"
                        size="small"
                        key={facet.label}
                        onClick={e => featuredFacetClicked(e, facet.label)}>
                        {facet.label}
                    </Button>
                )
            })
        )

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        if (!Array.from(searchParams.values()).length) {
            sessionStorageService.removeItem(analyticsAttributes.event.filterInteraction)
        }
    }, [])

    return (
        <div className={`${PREFIX}-facet-bar ${PREFIX}-row ${enableFilterFacetsEnhancement ? 'featured' : ''}`}>
            {renderSpinningWheel()}
            <div ref={filterRow} className={`${PREFIX}-facet-bar--desktop`}>
                {!enableFilterFacetsEnhancement && (
                    <Button type="secondary" size="small" onClick={sortFacetClicked}>
                        {sortTitle}
                    </Button>
                )}
                {getToggledCategory()}
                {getToggledFilters()}
                {/* TODO: Need to take below label from AEM. Will be done in OCCP-5192 */}
                {moreFiltersLabel && (
                    <Button type="secondary" size="small" onClick={moreFacetsClicked}>
                        {moreFiltersLabel}
                        {numberfiltersAppliedComp()}
                    </Button>
                )}
                {renderSortByFilter()}
            </div>
            <div
                className={`${PREFIX}-facet-bar--mobile ${
                    getCategoryData().shouldShowCategoryDropdown ? '' : `${PREFIX}-facet-bar--mobile-no-category`
                } ${
                    !enableFilterFacetsEnhancement
                        ? `${PREFIX}-facet-bar--mobile__sort-open`
                        : `${PREFIX}-facet-bar--mobile-sort-filter`
                }`}>
                {/* TODO: Need to take below label from AEM. Will be done in OCCP-5192 */}
                {enableFilterFacetsEnhancement ? (
                    <>
                        {renderSortAndFilter()}
                        <Button type="secondary" size="small" onClick={sortFacetClicked}>
                            <span className={`${PREFIX}-facet-bar--mobile__sortbyfilters`}>{sortTitle}:</span>{' '}
                            <span className={`${PREFIX}-facet-bar--mobile__sortselectedoption`}>
                                {selectedSortOption}
                            </span>
                        </Button>
                    </>
                ) : (
                    renderSortAndFilter()
                )}
            </div>
        </div>
    )
}

FacetHorizontalBar.propTypes = {
    sortTitle: PropTypes.string.isRequired,
    moreFiltersLabel: PropTypes.string.isRequired,
    sortAndFiltersMobileLabel: PropTypes.string.isRequired,
    allFiltersMobileLabel: PropTypes.string.isRequired,
    allCategoriesLabel: PropTypes.string.isRequired,
    enableFilterFacetsEnhancement: PropTypes.bool,
    maxPriceErrorMessage: PropTypes.string.isRequired,
    maxPriceLabel: PropTypes.string.isRequired,
    minPriceErrorMessage: PropTypes.string.isRequired,
    minPriceLabel: PropTypes.string.isRequired,
    showLessLabel: PropTypes.string.isRequired,
    showMoreLabel: PropTypes.string.isRequired,
    showMoreThreshold: PropTypes.number.isRequired,
    clearAllFilterLabel: PropTypes.string.isRequired,
}

export default FacetHorizontalBar
