export interface CartItemFulfillmentSource {
    quantity: number
    productSourceType: string
    etaEarliest: string
    etaLatest: string
    NextOrderCutOffDate: string
    MinOrderQty: number
    storeShelfLocation: string
}

/**
 * function to get source type data
 * @param { boolean } sourceTypes
 * @param { string } type
 * @return { CartItemFulfillmentSource }
 */
export const getSourceTypeData = (
    sourceTypes: CartItemFulfillmentSource[] | [],
    type: string,
): CartItemFulfillmentSource => {
    return (
        sourceTypes.find((source: CartItemFulfillmentSource) => source.productSourceType === type) ||
        ({} as CartItemFulfillmentSource)
    )
}
