import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setOgImageTag } from '../../redux/actionCreators/ogImageTag.actionCreators'

interface OpenGraphImageTagComponentProps {
    component: string
    imageLink: string
}

// This component use for writing data to ogImageTag Redux State only for AEM components
export const OpenGraphImageTagComponent: React.FC<OpenGraphImageTagComponentProps> = ({ ...props }) => {
    const { component, imageLink } = props
    const dispatch = useDispatch()
    useEffect(() => {
        if (!imageLink) {
            return
        }
        dispatch(setOgImageTag({ component: component, imageLink: imageLink }))
    }, [component, imageLink, dispatch])

    return null
}

export default OpenGraphImageTagComponent
