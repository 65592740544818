export enum ChartTypes {
    BAR = 'bar',
    BUBBLE = 'bubble',
    DOUGHNUT = 'doughnut',
    PIE = 'pie',
    LINE = 'line',
    POLAR_AREA = 'polarArea',
    RADAR = 'radar',
    SCATTER = 'scatter',
}

/* chart plugin ids */
export enum FeatureTypes {
    PROGRESS_OUTLINE = 'progressOutline',
    CUSTOM_BACKGROUND = 'customCanvasBackgroundColor',
}

export interface IPluginFeatures {
    isPlugin: boolean
    type?: FeatureTypes
    styles?: Record<string, unknown>
    [key: string]: unknown
}

export interface ChartsProps {
    id?: string
    type: 'bar' | 'bubble' | 'doughnut' | 'pie' | 'line' | 'polarArea' | 'radar' | 'scatter' | ChartTypes
    chartData: Record<string, unknown[]>
    chartOptions: Record<string, unknown>
    chartFeatures?: IPluginFeatures
    children?: React.ReactNode
}

export interface IProgressBarData {
    data: number[]
    backgroundColor: string // color string
    borderRadius?: number
    borderWidth?: number
    borderColor: string // color string
    progressBackgroundColor: string // color string
    borderSkipped: boolean
    barThickness?: number
    barPercentage: number
    progress?: number // progress, should be <= then number in data
    fontHeight?: number
    padding?: number
}

export interface IChartPlugin {
    // roundRect function should be present in CanvasRenderingContext2D type, but probably the type itself is not
    // updated because roundRect was added in 2021
    ctx: CanvasRenderingContext2D & {
        roundRect: (x: number, y: number, width: number, height: number, radius: number[]) => void
    }
    data: {
        datasets: IProgressBarData[]
    }
    chartArea: {
        left: number
        width: number
    }
    scales: {
        y: { getPixelForValue: (i: number) => number }
    }
    width: number
    height: number
}

export interface DrawProgressStrokePlugin {
    id: string
    isPlugin: boolean
    beforeDatasetsDraw: (chart: IChartPlugin) => void
}

export interface CustomBGPlugin {
    id: string
    isPlugin: boolean
    beforeDraw: (chart: IChartPlugin) => void
}
