import { createAction } from 'typesafe-actions'

import {
    SEARCH_BAR_QUERY,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAILURE,
} from '../types/products/search.actionTypes.constant'
import { SearchErrorResponse } from '../models/search.interface'
import { SearchState } from '../reducers/search.reducer'

export const searchBarAction = createAction(SEARCH_BAR_QUERY, (payload: string) => payload)<string>()
export const getProductSearchAction = createAction(PRODUCT_SEARCH_SUCCESS, (payload: string | SearchState) => payload)<
    string | SearchState
>()
export const getProductSearchError = createAction(
    PRODUCT_SEARCH_FAILURE,
    (payload: SearchErrorResponse) => payload,
)<SearchErrorResponse>()
