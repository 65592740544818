import { hideClassName } from '../globalConstants/global.constant'
import { addClass } from './addClass'

/**
 * Remove a class(s) to an element
 * @param {HTMLElement|string} selector  An dom element or a CSS selector string
 * @param {string[]} classNames The class name
 */
export const removeClass = (selector: HTMLElement | string, classNames: string[]): void => {
    if (selector instanceof Element) {
        selector.classList.remove(...classNames)
    } else if (typeof selector === 'string') {
        document.querySelector(selector).classList.remove(...classNames)
    }
}

/**
 * Adds and removes hide class from given elements
 * @param {HTMLElement} addToElement  An dom element to add hide class
 * @param {HTMLElement} removeFromElement An dom element to remove hide class
 */
export const addAndRemoveHideClass = (
    addToElement: HTMLElement | null,
    removeFromElement: HTMLElement | null,
): void => {
    addToElement && addClass(addToElement, hideClassName)
    removeFromElement && removeClass(removeFromElement, [hideClassName])
}
