import { getEnvironment } from '../../environments'
import { DevConfig } from '../../environments/dev.environment'
import { getHttpClient } from '../../httpClient'
import {
    ActivateOffersPayload,
    OfferActivation,
    CustomerOffers,
    FetchOffersPayload,
    ActivateTargetedOfferPayload,
    TargetedOfferActivation,
} from '../../redux/models/offers.interface'
import BaseService from '../base.service'

const httpClient = getHttpClient()
const environment: DevConfig = getEnvironment()

/**
 * Offers service. All offers related server interaction will be done by this service
 */
class OffersService extends BaseService {
    /**
     * Load offers for logged in customer
     * @param  {FetchOffersPayload} requestPayload
     * @param {profileId} lmsProfileId
     * @return {CustomerOffers}
     */
    getOffers(requestPayload: FetchOffersPayload, lmsProfileId?: string): Promise<{ data: CustomerOffers }> {
        const url: URL = this.createOffersUrl('dssCustomerOffers')
        const queryUrl = lmsProfileId ? url.toString() + '?p1=' + lmsProfileId : url
        return OffersService.isMock()
            ? httpClient.apiGet(queryUrl.toString(), { ...requestPayload }, undefined, true)
            : httpClient.apiPost(queryUrl.toString(), { ...requestPayload }, undefined, true)
    }

    /**
     * Activate offers for customer
     * @param  {ActivateOffersPayload} requestPayload
     * @param {string} lmsProfileId
     * @return {CustomerOffers}
     */
    activateOffer(requestPayload: ActivateOffersPayload, lmsProfileId?: string): Promise<{ data: OfferActivation }> {
        const url: URL = this.createOffersUrl('dssActivateCustomerOffers')
        const queryUrl = lmsProfileId ? url.toString() + '?p1=' + lmsProfileId : url
        return OffersService.isMock()
            ? httpClient.apiGet(queryUrl.toString(), { ...requestPayload }, undefined, true)
            : httpClient.apiPost(queryUrl.toString(), { ...requestPayload }, undefined, true)
    }

    /**
     * Activate targeted offer by offer code in parameters
     * @param {ActivateTargetedOfferPayload} requestPayload
     * @param {boolean} multiOffers
     * @return {TargetedOfferActivation} promise
     */
    activateTargetedOffer(
        requestPayload: ActivateTargetedOfferPayload,
        multiOffers: boolean,
    ): Promise<{ data: TargetedOfferActivation[] }> {
        const url: URL = this.createOffersUrl('dssActivateTargetedOffer')
        const deconstructedParams = `p1=${requestPayload?.p1 ? requestPayload.p1 : ''}&p2=${requestPayload.p2}${
            requestPayload.lang ? `&lang=${requestPayload.lang}` : ''
        }`
        const parsedParams = multiOffers ? deconstructedParams : new URLSearchParams(requestPayload).toString()
        const queryUrl = url.toString() + '?' + parsedParams
        return OffersService.isMock()
            ? httpClient.apiGet(queryUrl, undefined, undefined, true)
            : httpClient.apiPost(queryUrl, undefined, undefined, true)
    }

    /**
     * Create offers url.
     * @param {string} endpoint
     * @return {URL}
     */
    createOffersUrl(endpoint: string): URL {
        return OffersService.createUri(environment.API_ENDPOINTS[endpoint])
    }
}

const offersService = new OffersService()

export { offersService }
export default offersService
