import { ActionType, getType } from 'typesafe-actions'

import {
    changeBopisStoreAction,
    checkoutButtonClickAction,
    paypalButtonClickAction,
    paypalButtonClickValidationAction,
    resetCheckOutVariablesAction,
    setPostalCodeOpenAction,
    setTNCOpenAction,
    setTncAccepted,
    clickToPayButtonClickAction,
} from '../actionCreators/checkoutButtonClick.actionCreators'
type Action = ActionType<typeof checkoutButtonClickAction>
export interface CheckButtonClickState {
    isPCOpen: boolean
    isTNCOpen: boolean
    isChangeStore: boolean
    isTncAccepted: boolean
    isPaypalButtonClick: boolean
    isPaypalButtonClickValid: boolean
    isCheckoutButtonClick: boolean
    isClickToPayClicked: boolean
}

const initialState: CheckButtonClickState = {
    isPCOpen: false,
    isTNCOpen: false,
    isChangeStore: false,
    isTncAccepted: false,
    isPaypalButtonClick: false,
    isPaypalButtonClickValid: false,
    isCheckoutButtonClick: false,
    isClickToPayClicked: false,
}

export const checkoutButtonClickReducer = (
    state: CheckButtonClickState = initialState,
    action: Action,
): CheckButtonClickState => {
    if (action.type === getType(checkoutButtonClickAction)) {
        return {
            ...state,
            isCheckoutButtonClick: action.payload,
            isClickToPayClicked: false,
        }
    } else if (action.type === getType(setPostalCodeOpenAction)) {
        return { ...state, isPCOpen: action.payload }
    } else if (action.type === getType(resetCheckOutVariablesAction)) {
        return {
            ...state,
            isCheckoutButtonClick: false,
            isClickToPayClicked: false,
        }
    } else if (action.type === getType(setTNCOpenAction)) {
        return { ...state, isTNCOpen: action.payload }
    } else if (action.type === getType(changeBopisStoreAction)) {
        return { ...state, isChangeStore: action.payload }
    } else if (action.type === getType(setTncAccepted)) {
        return { ...state, isTncAccepted: action.payload }
    } else if (action.type === getType(paypalButtonClickAction)) {
        return { ...state, isPaypalButtonClick: action.payload }
    } else if (action.type === getType(paypalButtonClickValidationAction)) {
        return { ...state, isPaypalButtonClickValid: action.payload }
    } else if (action.type === getType(clickToPayButtonClickAction)) {
        return {
            ...state,
            isCheckoutButtonClick: false,
            isClickToPayClicked: action.payload,
        }
    } else {
        return state
    }
}
