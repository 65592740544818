import { magicNumber } from '@nl/lib'
import { getEnvironment } from '../../environments'
import localStorageService from '../../utils/localStorageService'
import cryptoRandomString from 'crypto-random-string'
import { signifydProps, SignifydSessionId } from './signifyd.type'

const environment = getEnvironment()
/**
 * SignifydService is to create profiling for fraud team to capture unique session id from pafe via script that will genrate from
 * below profling service.
 */
class SignifydService {
    /**
     * @param {signifydProps} data
     * @param {boolean} enableSignifydIframeTag
     * @return {HTMLElement}
     */
    profiling(data: signifydProps, enableSignifydIframeTag: boolean): HTMLElement[] {
        this.checkSignifydSessionId(data)
        const attributes = {
            src: data.signifydJsUrl,
            type: data.type,
            id: data.id,
            'data-tmx-noscript': enableSignifydIframeTag ? 'true' : 'false',
            'data-order-session-id': this.getSessionId() ? this.getSessionId() : '',
        }
        const script = document.createElement('script')
        script.defer = true
        this.setAttributes(script, attributes)
        return [script]
    }

    /**
     * This method using for setting attributes
     * @param {HTMLScriptElement} element
     * @param {object} attributes
     */
    setAttributes(element: HTMLScriptElement, attributes: { [x: string]: string }) {
        Object.keys(attributes).forEach(attr => {
            element.setAttribute(attr, attributes[attr])
        })
    }

    /**
     * This method using for checking signifyd sessionId
     * @param {signifydProps} data
     * @return {void} nothing
     */
    checkSignifydSessionId(data: signifydProps): void {
        this.clearExpiredSignifydSessionId(data)
        const sessionId = this.getSessionId()
        if (!sessionId) {
            this.provideSignifydSessionId(data)
        }
    }

    /**
     * This method using for clearing sessionId
     * @param {signifydProps} data
     * @return {void} nothing
     */
    clearExpiredSignifydSessionId(data: signifydProps): void {
        try {
            const sessionvalue = localStorageService.getItem(environment.SIGNIFYD_SESSION_VALUE)
            if (sessionvalue) {
                const signifydSessionIdInfo = JSON.parse(sessionvalue) as SignifydSessionId
                const lifeTimeinMinutes = data.sessionLifeTime || (magicNumber.SIXTY as number)
                const isSignifydSessionIdExpired =
                    signifydSessionIdInfo &&
                    signifydSessionIdInfo.timeStamp +
                        lifeTimeinMinutes * (magicNumber.SIXTY as number) * (magicNumber.THOUSAND as number) <
                        Date.now()

                if (isSignifydSessionIdExpired) {
                    localStorageService.removeItem(environment.SIGNIFYD_SESSION_VALUE)
                }
            }
        } catch (error) {
            console.warn(error)
        }
    }

    /**
     * This method using for getting sessionId
     * @return {string} sessionId
     */
    getSessionId(): string {
        try {
            const sessionvalue = localStorageService.getItem(environment.SIGNIFYD_SESSION_VALUE)
            if (sessionvalue) {
                const signifydSessionIdInfo = JSON.parse(sessionvalue) as SignifydSessionId
                return signifydSessionIdInfo.sessionId
            }
        } catch (error) {
            console.warn(error)
        }
        return ''
    }

    /**
     * This method provide signifyd sessionId
     * @param {signifydProps} data
     * @return {string} signifydSessionId
     */
    provideSignifydSessionId(data: signifydProps): string {
        const signifydSessionId = `${data.sessionPrefix}${cryptoRandomString({
            length: data.sessionIdSuffixLength,
            type: data.sessionIdSuffixType,
        })}`
        localStorageService.setItem(
            environment.SIGNIFYD_SESSION_VALUE,
            JSON.stringify({ sessionId: signifydSessionId, timeStamp: new Date().getTime() }),
        )
        return signifydSessionId
    }
}
export { SignifydService }
export default SignifydService
