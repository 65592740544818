/**
 * Redirect to return URL only for same host to prevent redirection to other domains
 * @param {URL} newUrl
 * @param {string} returnURL
 * @param {string} redirectUrl
 */
const redirectionBasedOnHost = (newUrl: URL, returnURL: string, redirectUrl: string): void => {
    if (newUrl.host === window.location.host) {
        window.location.href = decodeURIComponent(returnURL || `${redirectUrl}`)
    } else {
        console.error('Invalid redirection')
    }
}

export default redirectionBasedOnHost
export { redirectionBasedOnHost }
