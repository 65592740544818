import React from 'react'
import PropTypes from 'prop-types'

import { PillProps } from './Pill.types'
import { PREFIX } from '../config'
import Icon from '../Icon'

/**
 * Pill component
 * @param {PillProps} props - path, onCloseClick, pillLabel
 * @return {JSX.Element} returns Pill component
 */
const Pill: React.FC<PillProps> = (props: PillProps): JSX.Element => {
    const { path, onCloseClick, pillLabel } = props
    const handleCloseClick = (): void => {
        onCloseClick(pillLabel)
    }
    return (
        <button className={`${PREFIX}-pill-container ${PREFIX}-pill-button`} onClick={handleCloseClick}>
            <span className="sr-only">
                {props.filterType}: {pillLabel} {props.a11yRemoveFilter}
            </span>
            <span className={`${PREFIX}-pill-container__label`} aria-hidden="true">
                {pillLabel}
            </span>
            <span className={`${PREFIX}-pill-container__icon`} aria-hidden="true">
                <Icon type="ct-close" size="md" path={path} />
            </span>
        </button>
    )
}

Pill.propTypes = {
    path: PropTypes.string,
    onCloseClick: PropTypes.func,
    pillLabel: PropTypes.string.isRequired,
}

export default Pill
