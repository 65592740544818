import { minimumBreadcrumbLength } from './Breadcrumb.constant'
import { BreadcrumbAutomotive, BreadcrumbParentPageLink } from './Breadcrumb.type'

export const isLastLinkAndNotInteractive = (
    linksCount: number,
    index: number,
    isLastElementInteractive: boolean,
): boolean => index === linksCount - minimumBreadcrumbLength && !isLastElementInteractive

export const normalizeLinkArray = (
    links: BreadcrumbParentPageLink[],
    isMobile: boolean,
    vehicleBreadCrumb?: BreadcrumbAutomotive,
): BreadcrumbParentPageLink[] =>
    links.reduce<BreadcrumbParentPageLink[]>((normalizedLinks, linkItem, index) => {
        const isTheLastOne = index === links.length - minimumBreadcrumbLength

        if (vehicleBreadCrumb && !isMobile && isTheLastOne) {
            const { name, link, pName } = vehicleBreadCrumb

            const vehicleToLinkMapLink: BreadcrumbParentPageLink = {
                name,
                link,
                pName,
            }

            return [...normalizedLinks, vehicleToLinkMapLink]
        }

        if (!linkItem.link && !isTheLastOne) {
            return normalizedLinks
        }

        return [...normalizedLinks, linkItem]
    }, [])
