import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { redirectToPage, libUtils } from '@nl/lib'

import {
    setCheckoutContact,
    setCheckoutPickup,
    setCheckoutShipping,
    setCheckoutPayment,
    setShowSpinner,
    setPaymentFailureError,
    setCheckoutContactUpdateStatus,
    resetCheckoutValidationsAction,
    setCheckoutShippingStatus,
    updateCartDataSuccessAction,
    setPaymentSuccess,
} from '../actionCreators'
import {
    successStatus,
    checkoutContactUrl,
    checkoutDeliveryUrl,
    checkoutPickupUrl,
    checkoutPaymentUrl,
} from '../../components/Checkout/Checkout.constant'
import {
    CheckoutShippingInfo,
    CheckoutPickupInfo,
    CheckoutContactInfo,
    CheckoutPaymentInfo,
} from '../models/checkout.interface'
import { drawerInteractionService } from '../../services/checkoutService/drawerInteraction.service'
import { RootState } from '../reducers'
import appCacheService from '../../utils/appCacheService'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { isOneTimeCartForAuthUser } from '../../utils/isOneTimeCartForAuthUser.utils'
import { InitPaymentPayload } from '../../components/Checkout/PaymentInformation/MasterPassPayment.type'
import { CheckoutService } from '../../services/checkoutService'
import { StoreSharedCart } from '../reducers/sharedCart.reducer'
import { CartResponseErrorDTO } from '../models/cart.interface'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { getCartItemsData } from './cart.action'
import { globalPartialAuthCode } from '../../globalConstants/cdsErrorCodes'

/**
 * function to set redux state after user enters data in contact information
 * @param {checkoutContactInfo}datapost
 * @param {string}guid
 * @return {Promise<void>}
 * @param {boolean} showSpinner
 */
export const checkoutContact =
    (datapost: CheckoutContactInfo, guid: string, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return drawerInteractionService
            .drawerApiSave(
                { ...datapost, phone: datapost.phone.replace(/-/g, '') },
                guid,
                checkoutContactUrl,
                null,
                isOneTimeCartFlag,
            )
            .then((response: AxiosResponse) => {
                if (response.status === successStatus) {
                    dispatch(setCheckoutContact(datapost))
                    dispatch(setCheckoutContactUpdateStatus({ errCode: 'No Error' } as unknown as CartResponseErrorDTO))
                }
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(setCheckoutContactUpdateStatus(err.response?.data))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to set redux state after user enters data in shipping information
 * @param {checkoutShippingInfo}datapost
 * @param {string}guid
 * @return {Promise<void>}
 */
export const checkoutShipping =
    (datapost: CheckoutShippingInfo, guid: string) => (dispatch: Dispatch, getState: () => RootState) => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        const canadaPostCountry = getState().commonContent.commonAPIContentAvailable.canadapost?.Country
        const updatedDataPost = { ...datapost, updateMyProfile: false, country: canadaPostCountry }
        !updatedDataPost.addressLineTwo && delete updatedDataPost.addressLineTwo
        delete updatedDataPost.Country
        delete updatedDataPost.next
        delete updatedDataPost.id
        const { isStoreSharedCart, oneTimeCartStore } = enableDestructOnUndefinedData(
            getState()?.sharedCart?.cartConsuming?.storeSharedCart,
        ) as StoreSharedCart
        const selectedPreferredStoreId =
            getState().storeDetails.selectedPreferredStoreId || appCacheService.preferredStoreId.get()
        const storeId = isStoreSharedCart ? oneTimeCartStore?.id : selectedPreferredStoreId

        drawerInteractionService
            .drawerApiSave(updatedDataPost, guid, checkoutDeliveryUrl, storeId, isOneTimeCartFlag)
            .then(response => {
                dispatch(setCheckoutShipping(datapost))
                dispatch(
                    setCheckoutShippingStatus({
                        isUpdateSuccess: true,
                        errorResponse: {} as unknown as CartResponseErrorDTO,
                    }),
                )
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
            })
            // TODO have to handle the error handling
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(
                    setCheckoutShippingStatus({
                        isUpdateSuccess: false,
                        errorResponse: err?.response as unknown as CartResponseErrorDTO,
                    }),
                )
            })
    }

/**
 * function to reset checkout validation error messages
 * @return {Dispatch}
 */
export const resetCheckoutValidations =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCheckoutValidationsAction())
    }

/**
 * function to set redux state after user enters data in pickup information
 * @param {checkoutPickupInfo}datapost
 * @param {string}guid
 * @return {Promise<void>}
 * @param {boolean} showSpinner
 */
export const checkoutPickup =
    (datapost: CheckoutPickupInfo, guid: string, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        drawerInteractionService
            .drawerApiSave({ ...datapost }, guid, checkoutPickupUrl, null, isOneTimeCartFlag)
            .then((response: AxiosResponse) => {
                if (response.status === successStatus) {
                    dispatch(setCheckoutPickup(datapost))
                }
            })
            // TODO have to handle the error handling
            .catch(err => console.warn(err))
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to set redux state after user enters data in payment information
 * @param {checkoutPaymentInfo} datapost
 * @param {string} guid
 * @param {boolean} showSpinner
 * @param {InitPaymentPayload | null} initPaymentPayload
 * @param {number} initPaymentVersion
 * @param {boolean} isAuthenticatedUser
 * @param {boolean} isHideSpinnerAtExit
 * @return {Promise<void>}
 */
export const checkoutPayment =
    (
        datapost: CheckoutPaymentInfo,
        guid: string,
        showSpinner = false,
        initPaymentPayload: InitPaymentPayload | null = null,
        initPaymentVersion: number,
        isAuthenticatedUser = false,
        isHideSpinnerAtExit = true,
    ) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        !datapost.billingAddress?.line2 && delete datapost.billingAddress?.line2
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        drawerInteractionService
            .drawerApiSave({ ...datapost }, guid, checkoutPaymentUrl, null, isOneTimeCartFlag)
            .then((response: AxiosResponse) => {
                if (response.status === successStatus) {
                    dispatch(setCheckoutPayment(datapost))
                    dispatch(setPaymentSuccess(true))
                    // OCCP 21565 - design solution proposed to call init payment post payment details success
                    initPaymentPayload &&
                        initPaymentPayload.cartId &&
                        CheckoutService.hppToken(
                            initPaymentPayload.cartId,
                            initPaymentPayload.storeId,
                            initPaymentVersion,
                            'anonymous',
                            initPaymentPayload.semafoneEnabled as unknown as string,
                            isOneTimeCartFlag,
                        )
                    getCartItemsData(
                        guid,
                        '',
                        false,
                        false,
                        isAuthenticatedUser,
                        true,
                        isHideSpinnerAtExit,
                    )(dispatch, getState)
                    isHideSpinnerAtExit && dispatch(setShowSpinner(false))
                }
            })
            // TODO have to handle the error handling
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                console.warn(err)
                dispatch(
                    setCheckoutPayment({
                        newCreditCard: false,
                    }),
                )
                dispatch(setPaymentSuccess(false))
                dispatch(setShowSpinner(false))
                if (err.response?.data?.errCode !== globalPartialAuthCode) {
                    // temporary solution as a fix for DDL-5791
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    window.newrelic?.addPageAction?.('JSTRACE:checkoutPaymentFailed', {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        err,
                    })
                    redirectToPage(`/${libUtils.getLanguage()}/shopping-cart.html`)
                }
            })
    }

/**
 * function to set checkout payment error occurred during place order
 * @param {string} value
 * @return {void}
 */
export const setPaymentError =
    (value: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setPaymentFailureError(value))
    }
