import React from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '@nl/lib'
import { PREFIX } from '../../config'
import PropTypes from 'prop-types'

import { AutoServiceButtonProps } from './AutoServiceButton.type'
import { commonContentAvailableSelector, commonContentSelector } from '../../redux/selectors/commonContent.selectors'

const AutoServiceButton: React.FC<AutoServiceButtonProps> = ({ ...props }): JSX.Element => {
    const { storeNumberForFrame } = props

    const { commonContentAvailable } = useSelector(commonContentSelector)

    const { autoAppointment } = useSelector(commonContentAvailableSelector)

    const { autoServiceButtonLabel, autoAppointmentPagePath, autoServiceButtonAriaLabel } =
        autoAppointment || ({} as typeof commonContentAvailable.autoAppointment)

    /**
     * function to return auto service button redirection link
     * @return string
     */

    const autoServiceLink = (): string => {
        return storeNumberForFrame ? autoAppointmentPagePath + `?store=${storeNumberForFrame}` : autoAppointmentPagePath
    }

    return (
        <a
            className={`${PREFIX}-need-auto-service-button`}
            data-testid="auto-appointment-button"
            href={autoServiceLink()}
            aria-label={autoServiceButtonAriaLabel}>
            <Icon type={props.icon} size={props.iconSize} />
            {autoServiceButtonLabel}
        </a>
    )
}

AutoServiceButton.defaultProps = {
    icon: 'ct-wrench-in-green',
    iconSize: 'md',
}

AutoServiceButton.propTypes = {
    storeNumberForFrame: PropTypes.string,
}

export default AutoServiceButton
