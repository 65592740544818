import { AxiosPromise } from 'axios'

import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import {
    getParamValue,
    updateParam,
    queryParameters,
    Vehicle,
    isArrayNotEmpty,
    getCookieValue,
    getSAPIRequestQueryParam,
    searchConstants,
    removeParam,
    addParam,
    updateUrlHistory,
} from '@nl/lib'
import { MagicNumber } from '../../analytics/analytics.type'
import getCategoriesFromURL from '../../utils/PLP/getCategoriesFromURL'
import getPageType from '../../utils/getPageType'
import { pageTypes, REF_URL_KEY } from '../../config'
import { REQUEST_CASHING_DURATION, searchPageTypes } from '../service.constants'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { categoryLevelPrefix, FacetList } from '../../components/Vehicles/Vehicles.constant'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import sessionStorageService from '../../utils/sessionStorageService'
import { WeatherTechVehicle } from '../../redux/models/weatherTech.interface'
import { GetProductDataOptionalValues, ProductDataType } from '../../redux/models/productData.interface'
import memoizeAsync from '../../utils/HttpClientUtils/memoizeAsync.utils'
import { getFullTireSize } from '../../components/Vehicles/Vehicle.helper'

const environment = getEnvironment()
const httpClient = getHttpClient()
const apiGetWithCaching = memoizeAsync(REQUEST_CASHING_DURATION, httpClient.apiGet)
const pageType = getPageType()

/**
 * Search service
 */
class PlpService extends BaseService {
    /**
     * Function to fetch Search Results.
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @return {AxiosPromise}
     */
    static getProductData(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        pageNumber: number = MagicNumber.ONE,
        optionalValues: GetProductDataOptionalValues,
    ): AxiosPromise<ProductDataType> {
        const headers: HttpReqHeaders = this.getHttpReqHeaders(optionalValues, isFitmentRequired)
        const httpGetRequest = optionalValues.withCaching ? apiGetWithCaching : httpClient.apiGet
        return httpGetRequest(
            this.getPlpUrl(requestPayload, store, isFitmentRequired, pageNumber, optionalValues),
            {},
            headers,
        )
    }

    /**
     * function to getHideFacets
     * @param {boolean} isFitmentRequired
     * @param {GetProductDataOptionalValues} optionalValues
     * @return {string}
     */
    static getHideFacets = (isFitmentRequired?: boolean, optionalValues?: GetProductDataOptionalValues): string => {
        let hideFacets = ''

        if (optionalValues?.searchSuppressFacets?.length) {
            optionalValues?.searchSuppressFacets?.map((facet, i) => {
                hideFacets += facet.searchSuppressFacet
                if (i !== optionalValues?.searchSuppressFacets?.length - MagicNumber.ONE) {
                    hideFacets += '|'
                }
            })
        }

        if (!!isFitmentRequired) {
            hideFacets +=
                (hideFacets === '' ? '' : hideFacets.endsWith('|') ? '' : '|') + FacetList.deliveryPickupOptions

            if (optionalValues?.isCompleteVehicleState === false) {
                hideFacets += '|' + FacetList.partFacet + '|' + FacetList.positionFacet
            }
        }

        return hideFacets
    }

    /**
     * Function to fetch Weathertech Products.
     * @param {string} requestPayload
     * @param {string} store
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @return {AxiosPromise}
     */
    static getWeatherTechProductData(
        requestPayload: string,
        store: string,
        pageNumber: number = MagicNumber.ONE,
        optionalValues: GetProductDataOptionalValues,
    ): AxiosPromise<ProductDataType> {
        const headers: HttpReqHeaders = this.getHttpReqHeaders(optionalValues, false)
        const vendorResults = optionalValues?.weatherTechVehicle?.vendorResults

        return httpClient.apiPost(
            this.getPlpUrl(requestPayload, store, undefined, pageNumber, optionalValues),
            checkDataLength(vendorResults) ? { vendorResults } : {},
            headers,
        )
    }

    /**
     * Function to generate HttpReqHeaders.
     * @param {GetProductDataOptionalValues} optionalValues
     * @param {boolean} isFitmentRequired
     * @return {HttpReqHeaders}
     */
    static getHttpReqHeaders(
        optionalValues: GetProductDataOptionalValues,
        isFitmentRequired?: boolean,
    ): HttpReqHeaders {
        const brUID = getCookieValue('_br_uid_2')
        const brUIDheader = brUID ? { 'X-BR-UID': brUID } : undefined
        const refURL = sessionStorageService.getItem(REF_URL_KEY)
        const referrer = refURL && { 'X-BR-REF-URL': refURL }
        const xBrRef = { 'X-BR-REF': window.location.href }
        const locale = PlpService.getEnvironmentLanguage()
        let hideFacetsHeader = {}
        let widgetIdHeader = {}
        let widgetTypeHeader = {}
        let experienceHeader = {}
        const hideFacets = this.getHideFacets(isFitmentRequired, optionalValues)
        if (hideFacets) {
            hideFacetsHeader = { hideFacets: hideFacets }
        }
        if (optionalValues.pathwayId) {
            widgetIdHeader = { widgetId: optionalValues.pathwayId }
        }
        if (optionalValues.searchWidgetType) {
            widgetTypeHeader = { widgetType: optionalValues.searchWidgetType }
        }
        if (optionalValues.searchExperience) {
            experienceHeader = { experience: optionalValues.searchExperience }
        }
        return {
            'x-ringfence': !!optionalValues.enableXRingFence,
            ...brUIDheader,
            ...referrer,
            ...xBrRef,
            lang: locale,
            count: optionalValues.productToFetch,
            ...hideFacetsHeader,
            ...widgetIdHeader,
            ...widgetTypeHeader,
            ...experienceHeader,
        }
    }

    /**
     * Function to get environment language.
     * @return {string}
     */
    static getEnvironmentLanguage = (): string => environment.language

    /**
     * Function to check if the page belongs to cat or not.
     * @return {boolean}
     */
    static isCategoryPage = (): boolean => pageTypes.categoryPages.includes(pageType)

    /**
     * Function to check if the page is packagelanding page.
     * @return {boolean}
     */
    static isPackageLandingPage = (): boolean => pageTypes.packageLanding === pageType

    /**
     * Function to get category path
     * @param {string} path
     * @return {string | undefined}
     */
    static getCategoryPath = (path: string): string | undefined => {
        return path?.slice(0, path?.lastIndexOf('-'))
    }

    /**
     * Function to get category Id
     * @param {string | undefined} categoryId
     * @param {string} path
     * @return {string}
     */
    static getCategoryId = (categoryId: string | undefined, path: string): string => {
        if (PlpService.isCategoryPage()) {
            /**
             * Example: a/b-c/c-id - return value of extractCategoryPath
             * below will extract a/b-c/c
             * then will replace '/' with '::'
             */
            return path?.slice(path?.lastIndexOf('-') + MagicNumber.ONE)
        } else if (PlpService.isPackageLandingPage() && categoryId) {
            return categoryId
        } else {
            return ''
        }
    }

    /**
     * function to append page number
     * @param {string} url
     * @param {string} page
     * @param {string} pageNumber
     * @return {string}
     */
    static appendPageNumber = (url: string, page: string, pageNumber: string): string => {
        const pageNumberFromRequest = getParamValue(url, queryParameters.page, queryParameters.divider)
        return page && !pageNumberFromRequest
            ? `${url}`
            : `${updateParam(url, queryParameters.page, queryParameters.divider, `${pageNumber}`)}`
    }

    /**
     * function to append price availability lazy load param
     * @param {string} url
     * @param {string} enabled
     * @return {string}
     */
    static appendPriceAvailabilityLazyLoadParam = (url: string, enabled: boolean): string => {
        return getParamValue(url, queryParameters.light, queryParameters.divider)
            ? PlpService.updatePriceAvailabilityLazyLoadParam(url)
            : PlpService.addPriceAvailabilityLazyLoadParam(url, enabled)
    }

    /**
     * function to update price availability lazy load param in case when
     * it already exists in url with ';' divider (adding or changing filters)
     * @param {string} url
     * @return {string}
     */
    static updatePriceAvailabilityLazyLoadParam = (url: string): string => {
        let newUrl = url
        newUrl = removeParam(newUrl, queryParameters.light, queryParameters.divider)
        newUrl = addParam(newUrl, queryParameters.light, 'true')
        updateUrlHistory(newUrl)
        return newUrl
    }

    /**
     * function to add price availability lazy load param
     * @param {string} url
     * @param {string} enabled
     * @return {string}
     */
    static addPriceAvailabilityLazyLoadParam = (url: string, enabled: boolean): string => {
        return enabled
            ? getParamValue(url, queryParameters.light, queryParameters.plpCDSDivider)
                ? url
                : addParam(url, queryParameters.light, 'true')
            : url
    }

    /**
     * function to get tire/wheel filter option query string
     * @param {string[]} vehicleQueryString
     * @param {number} vehicleQueryStringIndex
     * @param {Vehicle} defaultVehicle
     * @return {string[]}
     */
    static getAutomotiveSearchQueryString = (
        vehicleQueryString: string[],
        vehicleQueryStringIndex: number,
        defaultVehicle: Vehicle,
    ): string[] => {
        if (defaultVehicle && checkDataLength(defaultVehicle) && defaultVehicle?.autoAttributes) {
            const knownVehicle = Object.keys(defaultVehicle?.autoAttributes)
            knownVehicle?.forEach((attributeName: string) => {
                vehicleQueryString.push(
                    `x${vehicleQueryStringIndex}=auto.${attributeName};q${vehicleQueryStringIndex}=${encodeURIComponent(
                        defaultVehicle.autoAttributes[attributeName],
                    )}`,
                )
                vehicleQueryStringIndex++
            })
        } else {
            const fullTireSize = getFullTireSize(defaultVehicle)
            if (defaultVehicle && fullTireSize) {
                vehicleQueryString.push(`q=${String(fullTireSize)}`)
            } else {
                // this is added for OCCP-22619 without sending vehicletype products are not returned from api
                vehicleQueryString.push(`&x${vehicleQueryStringIndex}=auto.vehicleType;q${vehicleQueryStringIndex}=*`)
            }
        }
        return vehicleQueryString
    }

    /**
     * function to get vehicle information
     * @param {Vehicle} defaultVehicle
     * @param {number} indexPosition
     * @param {string[]} breadcrumbList
     * @param {string} categoryLevel
     * @param {string} id
     * @param {string} currentPackageId
     * @param {boolean | undefined} isFitmentRequired
     * @return {string}
     */
    static getVehicleInformation = (
        defaultVehicle: Vehicle,
        indexPosition: number,
        breadcrumbList: string[],
        categoryLevel: string,
        id: string,
        currentPackageId?: string,
        isFitmentRequired?: boolean,
    ): string => {
        let vehicleQueryString: string[] = []
        let vehicleQueryStringIndex = indexPosition

        if (!!isFitmentRequired) {
            vehicleQueryString = PlpService.getAutomotiveSearchQueryString(
                vehicleQueryString,
                vehicleQueryStringIndex,
                defaultVehicle,
            )
            vehicleQueryStringIndex = vehicleQueryString.length + MagicNumber.ONE
            if (!currentPackageId && vehicleQueryString.length > 0) {
                vehicleQueryString.push(`x${vehicleQueryStringIndex}=auto.application;q${vehicleQueryStringIndex}=both`)
                vehicleQueryStringIndex++
            }
        }

        if (isArrayNotEmpty(breadcrumbList) && !!isFitmentRequired) {
            breadcrumbList.forEach((category: string, index: number) => {
                vehicleQueryString.push(
                    `x${vehicleQueryStringIndex}=c.${categoryLevelPrefix}-${
                        index + MagicNumber.ONE
                    };q${vehicleQueryStringIndex}=${encodeURIComponent(category)}`,
                )
                vehicleQueryStringIndex++
            })
        } else {
            vehicleQueryString.push(
                `x${vehicleQueryStringIndex}=ast-id-level-${
                    categoryLevel?.split('/')?.length
                }&q${vehicleQueryStringIndex}=${id}`,
            )
            vehicleQueryStringIndex++
        }
        return `&${vehicleQueryString?.join(';')}`
    }

    /**
     * function to get category level
     * @param {string} path
     * @param {string} categoryURL
     * @return {string}
     */
    static getCategoryLevel = (path: string, categoryURL: string): string => {
        return PlpService.isCategoryPage()
            ? PlpService.getCategoryPath(path)
            : getCategoriesFromURL([]).extractCategoryPath(categoryURL)
    }

    /**
     * function to append SEARCH params to the url
     * @param {string} plpCDSUrl
     * @param {boolean | undefined} searchPassQParameter
     * @param {string} searchQParameter
     * @return {string}
     */
    static createUrlWithSearchParams(
        plpCDSUrl: string,
        searchPassQParameter?: boolean | undefined,
        searchQParameter?: string,
    ): string {
        // if pageType corresponds to bcplp/promo/event then append the WidgetId
        const enableSearchPageParameter = () => {
            return (
                pageType === pageTypes.brandCategoryPage ||
                pageType === pageTypes.promoListing ||
                pageType === pageTypes.eventListing
            )
        }

        const urlHasQueryParam = (url: string) => {
            return url.includes(';q=') || url.includes('&q=') || url.includes('?q=')
        }

        // if true append q parameter to the url. Applicable for global promotion and event pages
        if (enableSearchPageParameter() && !urlHasQueryParam(plpCDSUrl)) {
            if (searchPassQParameter && searchQParameter) {
                plpCDSUrl = `${plpCDSUrl}&q=${searchQParameter}`
            } else {
                plpCDSUrl = `${plpCDSUrl}&q=.`
            }
        }

        return plpCDSUrl
    }

    /**
     * function that adds param for universal products if AEM toggle enableUniversalProductFilter is true AND vehicle has been defined AND is auto parts PLP
     * @param {string} plpCDSUrl
     * @param {boolean} filterUniversalProducts
     * @return {string}
     */
    static parameterizeUniversalProducts = (plpCDSUrl: string, filterUniversalProducts?: boolean): string => {
        return filterUniversalProducts
            ? (plpCDSUrl = `${plpCDSUrl}&${searchConstants.AUTO_UNIVERSAL_PRODUCTS_FILTER}=true`)
            : plpCDSUrl
    }

    /**
     * function used to generate weather tech url
     * @param {string} apimEndPoint
     * @param {WeatherTechVehicle} weatherTechVehicle
     * @return {string}
     */
    static generateWeatherTechUrl = (apimEndPoint: string, weatherTechVehicle: WeatherTechVehicle): string => {
        return `${apimEndPoint}/${weatherTechVehicle.year}/${weatherTechVehicle.vehicleId}/${weatherTechVehicle.groupId}/${weatherTechVehicle.choiceIds}/`
    }

    /**
     * function to append params to the url
     * @param {string} plpCDSUrl
     * @param {string | undefined} minPrice
     * @param {string | undefined} maxPrice
     * @param {boolean | undefined} isFitmentRequired
     * @param {boolean} searchParamExists
     * @param {string} requestPayload
     * @param {boolean | undefined} searchPassQParameter
     * @param {string} searchQParameter
     * @param {string} tireOrWheelSize
     * @param {boolean} filterUniversalProducts
     * @return {string}
     */
    static createUrlWithParams(
        plpCDSUrl: string,
        minPrice?: string,
        maxPrice?: string,
        isFitmentRequired?: boolean | undefined,
        searchParamExists?: boolean,
        requestPayload?: string,
        searchPassQParameter?: boolean | undefined,
        searchQParameter?: string,
        tireOrWheelSize?: string,
        filterUniversalProducts?: boolean,
    ): string {
        if (minPrice) {
            plpCDSUrl = `${plpCDSUrl}&priceLowerBound=${minPrice}`
        }
        if (maxPrice) {
            plpCDSUrl = `${plpCDSUrl}&priceUpperBound=${maxPrice}`
        }

        if (tireOrWheelSize) {
            plpCDSUrl = `${plpCDSUrl}&q=${tireOrWheelSize}`
        }

        plpCDSUrl = this.parameterizeUniversalProducts(plpCDSUrl, filterUniversalProducts)

        // if automotive , use this flag to update CDS about automotive flag
        if (isFitmentRequired) {
            plpCDSUrl += '&automotive=true'
        }
        // if any one of the search param exists, use the url from api
        if (searchParamExists) {
            plpCDSUrl = requestPayload
        }
        // Append the Search params to the url
        plpCDSUrl = `${this.createUrlWithSearchParams(plpCDSUrl, searchPassQParameter, searchQParameter)}`
        return plpCDSUrl
    }

    /**
     * function to get CDS PLP Url
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @return {string}
     */
    /* eslint-disable require-jsdoc */
    /* eslint-disable complexity */
    static getCDSPlpUrl(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        pageNumber: number = MagicNumber.ONE,
        optionalValues: GetProductDataOptionalValues,
    ): string {
        const {
            API_ENDPOINTS: { getSearchResults, apimEndPointWeatherTechProducts },
        } = environment
        const {
            categoryId,
            pathwayId,
            categoryURL,
            breadcrumbList,
            defaultVehicle,
            currentPackageId,
            searchCategoryLevel,
            searchPassQParameter,
            searchQParameter,
            tireOrWheelSize,
            isWeatherTech,
            weatherTechVehicle,
            filterUniversalProducts,
            priceAvailabilityLazyLoadNeeded,
        } = optionalValues
        const url = isWeatherTech
            ? this.generateWeatherTechUrl(apimEndPointWeatherTechProducts, weatherTechVehicle)
            : getSearchResults
        const locale = PlpService.language
        const productItem = getParamValue(requestPayload, queryParameters.searchQuery, queryParameters.divider)
        const rqParam = getParamValue(requestPayload, queryParameters.searchRequestQuery, queryParameters.divider)
        const minPrice = getParamValue(requestPayload, queryParameters.priceLowerBound, queryParameters.divider)
        const maxPrice = getParamValue(requestPayload, queryParameters.priceUpperBound, queryParameters.divider)
        const storeParam = getParamValue(requestPayload, queryParameters.store, queryParameters.divider)
        const anyUtmParamExists =
            requestPayload?.toLowerCase().includes(queryParameters.utm) ||
            requestPayload?.toLowerCase().includes(queryParameters.googleAd)
        const searchParamExists =
            (requestPayload?.split(queryParameters.divider)?.length > MagicNumber.TWO ||
                requestPayload?.split(queryParameters.plpCDSDivider)?.length > MagicNumber.TWO) &&
            !anyUtmParamExists

        let plpCDSUrl = `?${queryParameters.store}=${store}`

        if (Boolean(getSAPIRequestQueryParam())) {
            plpCDSUrl = `${plpCDSUrl}${queryParameters.plpCDSDivider}rq=${rqParam as string}`
        }

        const path = getCategoriesFromURL([]).extractCategoryPath()
        const categoryLevel = searchCategoryLevel ? searchCategoryLevel : this.getCategoryLevel(path, categoryURL)
        const id = this.getCategoryId(categoryId, path)

        const indexPosition = searchParamExists ? MagicNumber.HUNDRED : MagicNumber.ONE
        const vehicleQueryString = PlpService.getVehicleInformation(
            defaultVehicle,
            indexPosition,
            breadcrumbList,
            categoryLevel,
            id,
            currentPackageId,
            isFitmentRequired,
        )

        const pageTypeValue = searchPageTypes[`${pageType}` as keyof typeof searchPageTypes]?.x1
        // if product item is given in url, then append product to the url
        if (productItem && !id) {
            plpCDSUrl = `${plpCDSUrl}&q=${productItem}`
        } else if (
            (pageType === pageTypes.brandCategoryPage || pageType === pageTypes.promoListing) &&
            !!searchCategoryLevel &&
            !!categoryId
        ) {
            plpCDSUrl = `${plpCDSUrl}&x${indexPosition}=${searchCategoryLevel}&q${indexPosition}=${categoryId}`
        } else if (
            !!pathwayId &&
            !!pageTypeValue &&
            pageType !== pageTypes.promoListing &&
            pageType !== pageTypes.eventListing
        ) {
            const showExtraParam = pageType === pageTypes.brandPage ? `&q=${pathwayId}` : ``
            plpCDSUrl = `${plpCDSUrl}&x${indexPosition}=${pageTypeValue}&q${indexPosition}=${pathwayId}${showExtraParam}`
        } else if (pageType !== pageTypes.promoListing && pageType !== pageTypes.eventListing) {
            plpCDSUrl = `${plpCDSUrl}${vehicleQueryString}`
        }

        if (searchParamExists && !storeParam) {
            requestPayload = `${requestPayload};store=${store}`
        }

        plpCDSUrl = `${this.createUrlWithParams(
            plpCDSUrl,
            minPrice,
            maxPrice,
            isFitmentRequired,
            searchParamExists,
            requestPayload,
            searchPassQParameter,
            searchQParameter,
            tireOrWheelSize,
            filterUniversalProducts,
        )}`
        let lang = getParamValue(plpCDSUrl, queryParameters.language, queryParameters.plpCDSDivider)
        if (lang) {
            if (lang.includes(queryParameters.divider)) {
                lang = lang.split(queryParameters.divider)[MagicNumber.ZERO]
            }
            if (lang !== locale) {
                plpCDSUrl = updateParam(plpCDSUrl, queryParameters.language, queryParameters.plpCDSDivider, locale)
            }
        }
        const page = getParamValue(plpCDSUrl, queryParameters.page, queryParameters.divider)
        plpCDSUrl = this.appendPageNumber(plpCDSUrl, page, pageNumber)
        plpCDSUrl = this.appendPriceAvailabilityLazyLoadParam(plpCDSUrl, priceAvailabilityLazyLoadNeeded)
        return `${url}${plpCDSUrl}`
    }

    /**
     * Function used to construct the query params for the search url
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} pageNumber
     * @param {GetProductDataOptionalValues} optionalValues
     * @return {string} plp url
     */
    static getPlpUrl(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        pageNumber: number = MagicNumber.ONE,
        optionalValues: GetProductDataOptionalValues,
    ): string {
        const { API_BASE_URL } = environment
        return `${API_BASE_URL}${this.getCDSPlpUrl(
            requestPayload,
            store,
            isFitmentRequired,
            pageNumber,
            optionalValues,
        )}`
    }
}

export { PlpService }
export default PlpService
