export const FETCH_CUSTOMER_OFFERS_IN_PROGRESS = 'FETCH_CUSTOMER_OFFERS_IN_PROGRESS'
export const FETCH_CUSTOMER_OFFERS_SUCCESS = 'FETCH_CUSTOMER_OFFERS_SUCCESS'
export const FETCH_CUSTOMER_OFFERS_FAILURE = 'FETCH_CUSTOMER_OFFERS_FAILURE'
export const ACTIVATE_CUSTOMER_OFFERS_SUCCESS = 'ACTIVATE_CUSTOMER_OFFERS_SUCCESS'
export const ACTIVATE_CUSTOMER_OFFERS_FAILURE = 'ACTIVATE_CUSTOMER_OFFERS_FAILURE'
export const CLEAR_ACTIVATION_SUCCESS = 'CLEAR_ACTIVATION_SUCCESS'
export const CLEAR_ACTIVATION_ERROR = 'CLEAR_ACTIVATION_ERROR'
export const ACTIVATE_TARGETED_OFFER_SUCCESS = 'ACTIVATE_TARGETED_OFFER_SUCCESS'
export const FETCH_FILTERED_OFFERS_SUCCESS = 'FETCH_FILTERED_OFFERS_SUCCESS'
export const FETCH_FILTERED_OFFERS_FAILURE = 'FETCH_FILTERED_OFFERS_FAILURE'
export const UPDATE_FILTERED_OFFERS_SORTING = 'UPDATE_FILTERED_OFFERS_SORTING'
export const UPDATE_FILTERED_OFFERS = 'UPDATE_FILTERED_OFFERS'
export const SET_LOYALTY_OFFERS_FETCH_SUCCESS = 'SET_LOYALTY_OFFERS_FETCH_SUCCESS'
