import React from 'react'
import Slider from 'react-slick'
import { useState } from 'react'

import { CarouselListProps } from './Carousel.type'
import { PREFIX, BREAKPOINTS } from '../config'
import { carouselSettings } from './Carousel.constant'
import CarouselNavigationArrow from './CarouselNavigationArrow'
import { carouselSettingValues } from './Carousel.helper'
import { magicNumber } from './../../../../lib/src/utils/magicNumber'
import { isPrerenderOrNot } from '../../utils/isPrerender'

const {
    desktopThresholdDefault,
    mDefaultCarouselSettings,
    mobileLandscapeThresholdDefault,
    tabletPortraitThresholdDefault,
    carouselDotsDefault,
    carouselInfiniteScrollingDefault,
    variableWidthClassName,
} = carouselSettings

/**
 * Carousel component
 * @param {CarouselListProps} props
 * @return {JSX.Element} returns Carousel component
 */
const Carousel: React.FC<CarouselListProps> = props => {
    const {
        title,
        carouselList,
        path,
        a11yCarouselNextLabel,
        a11yCarouselPreviousLabel,
        desktopThreshold,
        mobileLandscapeThreshold,
        tabletPortraitThreshold,
        carouselClassName,
        mobileCarouselSettings,
        tabletArrowSettings,
        sliderRef,
        initialSlide,
        displayArrows,
        displayDots,
        responsiveDesign,
        infiniteScrolling,
        carouselCallbackHandler,
    } = props
    const { mobilePortrait, mobileLandscape, tabletPortrait, tabletMaxWidth } = BREAKPOINTS
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)

    /**
     * slick carousel settings
     */

    const settings = {
        onLazyLoad: () => setTimeout(carouselCallbackHandler),
        slidesToShow: carouselList.length < desktopThreshold ? carouselList.length : desktopThreshold,
        className: carouselList.length < desktopThreshold ? variableWidthClassName : null,
        dots: displayDots,
        infinite: infiniteScrolling ?? carouselInfiniteScrollingDefault,
        speed: 500,
        beforeChange(prev, next) {
            const slideWidth = carouselList.length < desktopThreshold ? carouselList.length : desktopThreshold
            setActiveSlideIndex(next > 0 ? next / slideWidth : 0)
        },
        slidesToScroll: carouselList.length < desktopThreshold ? carouselList.length : desktopThreshold,
        variableWidth: carouselList.length < desktopThreshold,
        initialSlide: initialSlide,
        arrows: displayArrows,
        customPaging: function (i: number) {
            return (
                <button
                    aria-current={i === activeSlideIndex ? 'true' : 'false'}
                    aria-label={`${title} Slide ${i + magicNumber.ONE}`}
                    data-testid="slick-button">
                    {i + magicNumber.ONE}
                </button>
            )
        },
        lazyLoad: !isPrerenderOrNot(), // to lazy load the images, lazy load will be false for googlebot
        nextArrow: (
            <CarouselNavigationArrow ariaLabel={a11yCarouselNextLabel} type="ct-chevron-right" size="md" path={path} />
        ),
        prevArrow: (
            <CarouselNavigationArrow
                ariaLabel={a11yCarouselPreviousLabel}
                type="ct-chevron-left"
                size="md"
                path={path}
            />
        ),
        responsive: responsiveDesign ?? [
            {
                breakpoint: mobilePortrait,
                settings: mobileCarouselSettings,
            },
            {
                breakpoint: mobileLandscape,
                settings: carouselSettingValues(carouselList, mobileLandscapeThreshold, tabletArrowSettings),
            },
            {
                breakpoint: tabletPortrait,
                settings: carouselSettingValues(carouselList, tabletPortraitThreshold, tabletArrowSettings),
            },
            {
                breakpoint: tabletMaxWidth,
                settings: carouselSettingValues(carouselList, desktopThreshold, tabletArrowSettings),
            },
        ],
    }
    return (
        <div className={`${carouselClassName} ${PREFIX}-carousel`}>
            <Slider {...settings} ref={sliderRef}>
                {carouselList}
            </Slider>
        </div>
    )
}

Carousel.defaultProps = {
    desktopThreshold: desktopThresholdDefault,
    mobileCarouselSettings: mDefaultCarouselSettings,
    mobileLandscapeThreshold: mobileLandscapeThresholdDefault,
    tabletPortraitThreshold: tabletPortraitThresholdDefault,
    carouselClassName: '',
    initialSlide: 0,
    displayArrows: true,
    displayDots: carouselDotsDefault,
}

Carousel.propTypes = {
    ...Carousel.propTypes,
}

export default Carousel
