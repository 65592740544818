import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getAccessibilityPriceId, getAccessibilityTitleId } from '../../utils/getAccessibilityId'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { magicNumber } from '../../utils/magicNumber'
import { scrollToFooter } from '../../utils/scrollToFooter'
import Button from '../Button'
import { PREFIX } from '../config'
import Icon from '../Icon'
import Price from '../Price'
import { useGenerateSaveMessage } from '../Price/Price.helper'
import ProductInfo from '../ProductInfo'
import { ProductListCardProps } from './productListCard.type'

/**
 * ProductListCard component
 * @param {ProductListCardProps} props
 * @return {JSX.Element} returns ProductListCard component
 */

const ProductListCard: React.FC<ProductListCardProps> = props => {
    const {
        productProps: { ratingsAndReview, a11yPriceRangeFrom, a11yPriceRangeTo },
        product,
        title,
        brand,
        url,
        badges,
        discount,
        hideDescription,
        hideSaveLabel,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yCloseIconLabel,
        a11yTooltipIcon,
        images,
        currentPrice,
        originalPrice,
        displayWasLabel,
        language,
        feeTitle,
        feeDisclaimerMessage,
        feeDisclaimerTitle,
        priceMessage,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        feeDisclaimerType,
        imageDataComponentName,
        returnPolicy,
        overridePriceHeight,
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue,
        enableSaveStory,
        isWheelOrTirePDP,
        eachLabel,
        isLazyRequire,
        productDataId,
        saleMessagesRules,
        a11yClickToReadFootnote,
        accessibilityId,
        addToCartClickHandler,
        isYouNeedThis,
        isProductAdded,
        addedToCartLabel,
        plusMinusSymbol,
        isOnSale,
    } = props

    const [showSpinner, setShowSpinner] = useState(false)

    type imgAttribute = {
        'data-src'?: string
        src?: string
        className?: string
        'data-component-name': string
    }

    const imageAttributes = (path: string, dataComponentName: string) => {
        const attributeObject: imgAttribute = {
            'data-component-name': dataComponentName,
        }
        if (isLazyRequire) {
            attributeObject['data-src'] = path

            attributeObject.className = 'lazyload'
        } else {
            attributeObject.src = path
        }
        return attributeObject
    }

    /**
     * Function to return image url appended with akamai policies
     * @param {string} source
     * @param {string} policyType
     * @param {function} returnPolicy
     * @return {string} image url
     */
    const createUrlWithPolicy = (source: string, policyType: string): string => {
        return policyType
            ? `${source?.toString()?.split('?', magicNumber.ONE).toString()}${returnPolicy(policyType)}`
            : `${source}`
    }

    /**
     * function to return rating of product
     *
     * @return {JSX.Element}
     */
    const ratingsView = (): JSX.Element => {
        // TODO: if cds sends rating as an object i.e. averageRating and number of reviews need to pass the object keys as the props.
        return (
            ratingsAndReview && (
                <div
                    className={`${PREFIX}-reviews__list`}
                    data-bv-show="inline_rating"
                    data-bv-product-id={productDataId}
                    data-bv-seo="false"
                    aria-hidden={true}
                />
            )
        )
    }

    const currentLevelData = useMemo(() => {
        return {
            ...product,
            originalPrice,
            currentPrice,
            badges,
            displayWasLabel,
            priceMessage,
        }
    }, [product, originalPrice, currentPrice, badges, displayWasLabel, priceMessage])

    const saveMessage = useGenerateSaveMessage(
        currentLevelData?.saleCut,
        currentLevelData?.originalPrice,
        saleMessagesRules,
        currentLevelData?.currentPrice,
    )

    /**
     * function to render price component
     *
     * @return {JSX.Element}
     */
    const renderPriceComponent = (): JSX.Element => {
        return (
            <Price
                discountValue={discount}
                promotionalPriceLabel={discount ? promotionalPriceLabel : null}
                thresholdValue={thresholdValue}
                unitPriceLabel={unitPriceLabel}
                clearancePriceLabel={clearancePriceLabel}
                a11yPriceRangeFrom={a11yPriceRangeFrom}
                a11yPriceRangeTo={a11yPriceRangeTo}
                hideSaveLabel={hideSaveLabel}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                a11yTooltipIcon={a11yTooltipIcon}
                a11yCloseIconLabel={a11yCloseIconLabel}
                feeTitle={feeTitle}
                feeDisclaimerTitle={feeDisclaimerTitle}
                feeDisclaimerMessage={feeDisclaimerMessage}
                currentPrice={currentLevelData?.currentPrice}
                originalPrice={currentLevelData?.originalPrice}
                displayWasLabel={currentLevelData?.displayWasLabel}
                language={language}
                priceMessage={currentLevelData?.priceMessage}
                scrollToFooter={scrollToFooter}
                nowFromLabel={nowFromLabel}
                saveFromLabel={saveFromLabel}
                wasFromLabel={wasFromLabel}
                fromLabel={fromLabel}
                feeDisclaimerType={feeDisclaimerType}
                overridePriceHeight={overridePriceHeight}
                showEachTextForSaveLabel={enableSaveStory}
                isAutomotiveEachLabel={isWheelOrTirePDP}
                eachLabel={eachLabel}
                saveMessage={saveMessage}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                accessibilityPriceId={getAccessibilityPriceId(accessibilityId)}
                ariaHidden={true}
                highlightDiscountedPrice={true}
                plusMinusSymbol={plusMinusSymbol}
                isOnSaleOrClearance={isOnSale}
            />
        )
    }

    /**
     * function to call addToCartClickHandler
     * @return {void}
     */
    const handleAddToCart = (): void => {
        addToCartClickHandler(product?.skus?.[0]?.code)
        setShowSpinner(true)
    }

    useEffect(() => {
        isProductAdded && setShowSpinner(false)
    }, [isProductAdded])

    /**
     * function to get button or label
     * @return {JSX.Element}
     */
    const renderAddToCartButton = (): JSX.Element => {
        return (
            <>
                {!isProductAdded ? (
                    <Button
                        type="secondary"
                        size="mini"
                        onClick={() => !showSpinner && handleAddToCart()}
                        label={props.addToCartLabel}
                        showSpinner={showSpinner}
                    />
                ) : (
                    <div className={`${PREFIX}-product-list-card__btn-added-to-cart`}>
                        <Icon type="ct-checkmark" size="sm" />
                        {addedToCartLabel}
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            <div className={`${PREFIX}-product-list-card`}>
                <a className={`${PREFIX}-product-list-card__image-wrap`} href={url}>
                    <img
                        alt={title}
                        {...imageAttributes(
                            isArrayNotEmpty(images) &&
                                `${createUrlWithPolicy(images?.[0]?.url, imageDataComponentName)}`,
                            imageDataComponentName,
                        )}></img>
                </a>
                <div className={`${PREFIX}-product-list-card__product-details`}>
                    <a className={`${PREFIX}-product-info`} href={url}>
                        {' '}
                        <ProductInfo
                            brand={brand}
                            title={title}
                            hideDescription={hideDescription}
                            titleStyle={`${PREFIX}-product-card__title`}
                            titleContainerStyle={`${PREFIX}-product-card__title-container`}
                            accessibilityTitleId={getAccessibilityTitleId(accessibilityId)}
                        />
                    </a>

                    {ratingsView()}
                    {renderPriceComponent()}
                    {isYouNeedThis && renderAddToCartButton()}
                </div>
            </div>
        </>
    )
}
ProductListCard.propTypes = {
    productProps: PropTypes.any,
    product: PropTypes.any,
    title: PropTypes.string,
    brand: PropTypes.string,
    url: PropTypes.string,
    badges: PropTypes.array,
    discount: PropTypes.any,
    hideDescription: PropTypes.bool,
    hideSaveLabel: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    images: PropTypes.array,
    currentPrice: PropTypes.object,
    originalPrice: PropTypes.object,
    displayWasLabel: PropTypes.bool,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    priceMessage: PropTypes.array,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeDisclaimerType: PropTypes.string,
    imageDataComponentName: PropTypes.string,
    returnPolicy: PropTypes.func,
    overridePriceHeight: PropTypes.bool,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    thresholdValue: PropTypes.number,
    enableSaveStory: PropTypes.bool,
    isWheelOrTirePDP: PropTypes.bool,
    eachLabel: PropTypes.string,
    isLazyRequire: PropTypes.bool,
    productDataId: PropTypes.string,
    saleMessagesRules: PropTypes.array,
    a11yClickToReadFootnote: PropTypes.string,
    accessibilityId: PropTypes.string,
    addToCartClickHandler: PropTypes.func,
    isYouNeedThis: PropTypes.bool,
    isProductAdded: PropTypes.bool,
    addedToCartLabel: PropTypes.string,
}
export default ProductListCard
