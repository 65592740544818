import { parse } from 'qs'

export enum AOAWorkflowType {
    Regular = 'Regular',
    ChangeOrCancel = 'ChangeOrCancel',
    ETire = 'eTire',
}

/**
 * Interface for AOAWorkFlowQueryParams
 * @interface
 */
export interface AOAWorkflowQueryParams {
    orderReferenceNumber?: string
    documentTokenId?: string
    storeId?: string
}

/**
 * getAOAWorkFlowQueryParams will fetch AOAWorkflow specific query parameters from url
 * @return {AOAWorkflowQueryParams}
 */
export const getAOAWorkFlowQueryParams = (): Required<AOAWorkflowQueryParams> => {
    const {
        orderId = '',
        t = '',
        branch = '',
    } = parse(window.location.search.replace(/%20/g, ''), {
        ignoreQueryPrefix: true,
    })

    return {
        orderReferenceNumber: orderId,
        documentTokenId: t,
        storeId: branch,
    } as Required<AOAWorkflowQueryParams>
}

/**
 * A utility function that returns workflow type based on the query string parameters
 * @return {AOAWorkflowType} the workflow type
 */
export default function getAOAWorkflowType(): AOAWorkflowType {
    const { orderReferenceNumber, documentTokenId, storeId } = getAOAWorkFlowQueryParams()

    if (documentTokenId && storeId) {
        return AOAWorkflowType.ChangeOrCancel
    }

    if (orderReferenceNumber) {
        return AOAWorkflowType.ETire
    }

    return AOAWorkflowType.Regular
}
