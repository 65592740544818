import { createAction } from 'typesafe-actions'

import {
    EARN_CT_MONEY,
    RESET_EARN_CREDIT_CARD,
    EARN_CT_MONEY_ERROR,
} from '../types/upsellingRewards/triangleRewardsUpsell.actionTypes.constant'
import { ErrorResponse } from '../models/upsellingRewards.interface'

export const earnCtMoneyAction = createAction(EARN_CT_MONEY, (payload: number) => payload)<number>()
export const earnCtMoneyErrorAction = createAction(
    EARN_CT_MONEY_ERROR,
    (payload: ErrorResponse) => payload,
)<ErrorResponse>()
export const resetEarnCreditCardAction = createAction(RESET_EARN_CREDIT_CARD)()
