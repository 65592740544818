import { Dispatch } from 'redux'
import { AxiosPromise, AxiosError } from 'axios'
import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import {
    notificationPreferenceSuccess,
    notificationUpdateSuccessAction,
    notificationUpdateErrorAction,
    notificationPreferenceError,
    resetNotificationPreferenceAction,
    shoppingPreferenceSuccess,
    shoppingPreferenceError,
    shoppingUpdateSuccessAction,
    shoppingUpdateErrorAction,
    resetShoppingPreferenceAction,
} from '../actionCreators/user.preferences.actionCreators'
import {
    PreferenceError,
    NotificationPreferenceBody,
    NotificationPreference,
    PreferencesJnjtraitsSuccessResponse,
    PreferencesJnjtraitsBody,
} from '../models/user.preferences.interface'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import { API_ENDPOINTS } from '../../environments/apiConfig'
import BaseService from '../../services/base.service'

const userNotificationPreferenceAPI = `${API_ENDPOINTS.notificationPreference}.json`

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * fetch notification preferences
 * @return { void }
 */
export const fetchNotificationPreferences =
    () =>
    (dispatch: Dispatch): void => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.notificationPreference}`
        // get the user shopping preference
        httpClient
            // TODO :uncomment below code for final integration
            .apiGet(url, { lang: BaseService.language }, headers, true)
            .then((preferenceData: { data: NotificationPreference }) => {
                // store the CDS access token in local storage
                dispatch(notificationPreferenceSuccess(preferenceData.data))
            })
            .catch((err: AxiosError<PreferenceError>) => {
                dispatch(notificationPreferenceError(err.response))
            })
    }

const getUpadatedNotificationPreference = (
    headers: { authorization?: string },
    requestPayload?: NotificationPreferenceBody,
): AxiosPromise<any> => {
    let url = ''
    if (BaseService.isMock()) {
        url = `${environment.API_MOCK_URL}${userNotificationPreferenceAPI}`
        return httpClient.apiGet(url, { ...requestPayload }, headers, true)
    } else {
        url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.notificationPreference}`
        return httpClient.apiPost(url, { ...requestPayload }, headers, true)
    }
}
/**
 * update notification preferences
 * @param { NotificationPreferenceBody } requestPayload payload for updating preferences
 * @return { Promise }
 */
export const updateNotificationPreferences =
    (requestPayload?: NotificationPreferenceBody) =>
    (dispatch: Dispatch): Promise<void> => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }

        return getUpadatedNotificationPreference(headers, requestPayload)
            .then(data => {
                const successStatus = 200
                const preferenceUpdated = data.status === successStatus
                dispatch(notificationUpdateSuccessAction(preferenceUpdated))
            })
            .catch((err: AxiosError<PreferenceError>) => {
                dispatch(notificationUpdateErrorAction(err.response.data))
            })
    }

/**
 * reset notification preferences
 *
 * @return { void }
 */
export const resetNotificationPreference =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetNotificationPreferenceAction())
    }

/**
 * fetch shopping preferences
 *
 * @return { void }
 */
export const fetchShoppingPreferences =
    () =>
    (dispatch: Dispatch): void => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const shoppingPreferenceheaders: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.shoppingPreference}`
        // get the user shopping preference
        httpClient
            .apiGet(url, { lang: BaseService.language, includeContent: true }, shoppingPreferenceheaders, true)
            .then((preferenceData: { data: PreferencesJnjtraitsSuccessResponse }) => {
                // store the CDS access token in local storage
                dispatch(shoppingPreferenceSuccess(preferenceData.data))
            })
            .catch((err: AxiosError<PreferenceError>) => {
                dispatch(shoppingPreferenceError(err.response))
            })
    }

/**
 * Update Shopping preferences e.g: subscribe to eFlyer
 * @param { PreferencesJnjtraitsBody } requestPayload payload for updating preferences
 * @return { Promise }
 */
export const updateShoppingPreferences =
    (requestPayload: PreferencesJnjtraitsBody[]) =>
    (dispatch: Dispatch): Promise<void> => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const shoppingPreferenceheaders: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.shoppingPreference}`

        return httpClient
            .apiPost(url, requestPayload, shoppingPreferenceheaders, true)
            .then(data => {
                const successStatus = 200
                const preferenceUpdated = data.status === successStatus
                dispatch(shoppingUpdateSuccessAction(preferenceUpdated))
            })
            .catch((err: AxiosError<PreferenceError>) => {
                dispatch(shoppingUpdateErrorAction(err.response))
            })
    }

/**
 * fetch shopping preferences
 *
 * @return { void }
 */
export const resetShoppingPreference =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetShoppingPreferenceAction())
    }
