import { createAction } from 'typesafe-actions'

import {
    CREDIT_CARD_ASSOCIATION_SUCCESS,
    CREDIT_CARD_ASSOCIATION_ERROR,
    VALIDATE_LOYALTY_SUCCESS,
    VALIDATE_LOYALTY_ERROR,
    VALIDATE_LOYALTY_EXTENDED_SUCCESS,
    VALIDATE_LOYALTY_EXTENDED_ERROR,
    MERGE_LOYALTY_CARD_SUCCESS,
    MERGE_LOYALTY_CARD_ERROR,
    CLEAR_CREDIT_CARD_ASSOCIATION_ERROR,
    CLEAR_VALIDATE_LOYALTY_ERROR,
    CLEAR_VALIDATE_LOYALTY_EXTENDED_ERROR,
    CLEAR_MERGE_LOYALTY_CARD_ERROR,
    CLEAR_MERGE_LOYALTY_CARD,
    CLEAR_MERGE_LOYALTY_VALIDATE,
} from '../types/loyaltyCard/mergeLoyaltyCard.actionTypes.constant'
import {
    MergeData,
    ErrorResponse,
    LoyaltyCardBodyResponse,
    ExtendedBodyResponse,
} from '../models/mergeLoyaltyCard.interface'

export const creditCardAssociationSuccessAction = createAction(CREDIT_CARD_ASSOCIATION_SUCCESS)<MergeData>()
export const creditCardAssociationErrorAction = createAction(CREDIT_CARD_ASSOCIATION_ERROR)<ErrorResponse>()
export const clearCreditCardAssociationError = createAction(CLEAR_CREDIT_CARD_ASSOCIATION_ERROR)()

export const validateLoyaltySuccessAction = createAction(VALIDATE_LOYALTY_SUCCESS)<LoyaltyCardBodyResponse>()
export const validateLoyaltyErrorAction = createAction(VALIDATE_LOYALTY_ERROR)<ErrorResponse>()
export const clearValidateLoyaltyError = createAction(CLEAR_VALIDATE_LOYALTY_ERROR)()

export const validateLoyaltyExtendedSuccessAction = createAction(
    VALIDATE_LOYALTY_EXTENDED_SUCCESS,
)<ExtendedBodyResponse>()
export const validateLoyaltyExtendedErrorAction = createAction(VALIDATE_LOYALTY_EXTENDED_ERROR)<ErrorResponse>()
export const clearValidateLoyaltyExtended = createAction(CLEAR_VALIDATE_LOYALTY_EXTENDED_ERROR)()

export const mergeLoyaltySuccessAction = createAction(MERGE_LOYALTY_CARD_SUCCESS)<number>()
export const mergeLoyaltyErrorAction = createAction(MERGE_LOYALTY_CARD_ERROR)<ErrorResponse>()
export const clearMergeLoyaltyError = createAction(CLEAR_MERGE_LOYALTY_CARD_ERROR)()

export const clearMergeLoyaltyCardAction = createAction(CLEAR_MERGE_LOYALTY_CARD)()
export const clearMergeLoyaltyValidateAction = createAction(CLEAR_MERGE_LOYALTY_VALIDATE)()
