import { regexPatterns } from '../globalConstants/regexPatterns.constant'

/**
 * returns a certain number of last characters of a string
 * @param {string} string "12345"
 * @param {number} amount 2
 * @return {string} "45"
 */
export const getLastCharacters = (string = '', amount: number): string => {
    return string.slice(-amount)
}

/**
 * returns true if string is in the list
 * @param {string} list "item 1, item 2, item 3,..."
 * @param {string} string "item 2"
 * @param {string} delimiter
 * @return {boolean}
 */
export const isStringInList = (list: string, string: string, delimiter = ','): boolean => {
    return !!list.split(delimiter)?.find(item => item.trim().toLowerCase() === string.trim().toLowerCase())
}

/**
 * function to change casing from all caps to sentence case
 * @param {string} string 'my test strIng'
 * @param {boolean} shouldLowercaseLetters
 * @return {string} 'My Test String'
 */
export const capitalizeWords = (string = '', shouldLowercaseLetters?: boolean): string => {
    string = shouldLowercaseLetters ? string.toLowerCase() : string

    return string.replace(regexPatterns.firstLetters, letter => letter.toUpperCase())
}

/**
 * function to replace all the occurances of given character
 * @param {string} string '01P3P56P'
 * @param {string} searchValue 'P'
 * @param {string} replaceValue 'R'
 * @return {string} '01R3R56R'
 */
export const replaceStringCharacter = (string: string, searchValue: string | RegExp, replaceValue: string): string => {
    return string.split(searchValue).join(replaceValue)
}
