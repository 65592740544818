/**
 * This file contains ShoppingCart constants
 */
export const sthOption = 'sm-sth'
export const expressOption = 'sm-express'
export const bopisOption = 'sm-bopis'
export const information = 'information'
export const updatedCart = 'updatedCart'
// Will be removed during actually api integration.
export const selectedServiceCartData = 'selectedServiceCartData'
export const keepServiceAdded = '/keepServiceItem'
export const removeEntireProduct = '/removeEntireProduct'
export const deliveryOption = 'delivery-option'
export const shippingMethodOption = 'shipping-method-option'
export const mergeCart = 'mergeCart'
export const authMergeCart = 'authMergeCart'
export const minimumVal = 1
export const maxNumberOfExtraHardware = 3
export const staggeredGroup = {
    type: 'STAGGERED',
    priorityZero: '0',
    priorityOne: '1',
}

export const extraHardwareSteps = {
    initial: 'initial',
    getPrice: 'getPrice',
    setPrice: 'setPrice',
    groupByVehicle: 'groupByVehicle',
}
export const cartSetTimeOut = 700
export const successCt = 'ct-notification-success'
export const cautionCt = 'ct-notification-caution'

export const successMergeFromCheckoutParam = 'successMerge'
export const saveForLaterParam = 'moveToSFL'

export const deliveryStatusMapping: Record<string, Record<string, unknown>> = {
    ORDER_RECEIVED: {
        icon: successCt,
        bgType: 'white',
        label: 'dpStatus10',
    },
    READY_FOR_PICKUP: {
        icon: successCt,
        bgType: 'green',
        label: 'dpStatus1',
    },
    IN_PROGRESS: {
        icon: 'ct-information-grey',
        bgType: 'white',
        label: 'dpStatus2/dpStatus9',
    },
    COMPLETED: {
        icon: successCt,
        bgType: 'white',
        label: 'dpStatus3/dpStatus8',
    },
    SHIPPED: {
        icon: successCt,
        bgType: 'green',
        label: 'dpStatus4',
    },
    CANCELLATION_PENDING: {
        icon: cautionCt,
        bgType: 'orange',
        label: 'dpStatus5',
    },
    CANCELLED: {
        icon: cautionCt,
        bgType: 'orange',
        label: 'dpStatus6',
    },
    RETURN_REQUESTED: {
        icon: cautionCt,
        bgType: 'orange',
        label: 'dpStatus11',
    },
    RETURNED: {
        icon: cautionCt,
        bgType: 'orange',
        label: 'dpStatus12',
    },
}

export const prePackagedBallonBouquet = 'Pre-packaged bouquet'
export const checkoutIdKey = 'checkoutid='
export const bopisOutOfStockErrorCode = '00125'
export const sthOutOfStockErrorCode = '00126'

// Value Bags
export const valueBagRadioOptIn = 'vbOptIn'
export const valueBagRadioOptOut = 'vbOptOut'
