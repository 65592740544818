import {
    ProductlistingpanelType,
    RecommendationsType,
    ShoppingCartType,
    CheckoutType,
    PurchaseType,
    AccountType,
    AccountCustomerserviceStoreType,
    CartType,
    Categorylevel2Type,
    ProductType,
    ExtraType,
    WishlistType,
    ProductWishlistType,
    DigitalFlyerType,
    OrderStatusType,
    AutoServiceAppointmentType,
    ErrorSearchBar,
    OrderSummaryType,
    RewardsType,
    VehiclesType,
    ContactUsType,
    StoreLocatorType,
    WeatherTechVehicleSelectorType,
    WeatherTechProductListingPanelType,
    BannersType,
    StoreDetailsType,
    HeaderType,
    LoyaltyOffersType,
    TruefitType,
    BreadcrumbType,
    BookingSummaryType,
    siteMapType,
    categoryStackedType,
    categoryType,
} from './types'

const ChunksLoader: { [key: string]: () => Promise<any> } = {
    productlistingpanel: () => import(/* webpackChunkName: "productlistingpanel" */ './productListingPanel'),
    recommendations: () => import(/* webpackChunkName: "recommendations" */ './recommendations'),
    shoppingCart: () => import(/* webpackChunkName: "shoppingCart" */ './shoppingCart'),
    checkout: () => import(/* webpackChunkName: "checkout" */ './checkout'),
    purchase: () => import(/* webpackChunkName: "purchase" */ './purchase'),
    account: () => import(/* webpackChunkName: "account" */ './account'),
    accountCustomerserviceStore: () =>
        import(/* webpackChunkName: "accountCustomerserviceStore" */ './accountCustomerserviceStore'),
    cart: () => import(/* webpackChunkName: "cart" */ './cart'),
    categorylevel2: () => import(/* webpackChunkName: "categorylevel2" */ './categorylevel2'),
    product: () => import(/* webpackChunkName: "product" */ './product'),
    extra: () => import(/* webpackChunkName: "extra" */ './extra'),
    wishlist: () => import(/* webpackChunkName: "wishlist" */ './wishlist'),
    productWishlist: () => import(/* webpackChunkName: "productWishlist" */ './productWishlist'),
    digitalFlyer: () => import(/* webpackChunkName: "digitalFlyer" */ './digitalFlyer'),
    orderStatus: () => import(/* webpackChunkName: "orderStatus" */ './orderStatus'),
    autoServiceAppointment: () => import(/* webpackChunkName: "autoServiceAppointment" */ './autoServiceAppointment'),
    errorPages: () => import(/* webpackChunkName: "errorPages" */ './errorPages'),
    orderSummary: () => import(/* webpackChunkName: "orderSummary" */ './orderSummary'),
    rewards: () => import(/* webpackChunkName: "rewards" */ './rewards'),
    vehicles: () => import(/* webpackChunkName: "vehicles" */ './vehicles'),
    contactUs: () => import(/* webpackChunkName: "contactUs" */ './contactUs'),
    storeLocator: () => import(/* webpackChunkName: "storeLocator" */ './storeLocator'),
    weatherTechVehicleSelector: () =>
        import(/* webpackChunkName: "weatherTechVehicleSelector" */ './weatherTechVehicleSelector'),
    weatherTechProductListingPanel: () =>
        import(/* webpackChunkName: "weatherTechProductListingPanel" */ './weatherTechProductListingPanel'),
    banners: () => import(/* webpackChunkName: "banners" */ './banners'),
    storeDetails: () => import(/* webpackChunkName: "storeDetails" */ './storeDetails'),
    header: () => import(/* webpackChunkName: "header" */ './header'),
    loyaltyOffers: () => import(/* webpackChunkName: "loyaltyOffers" */ './loyaltyOffers'),
    truefit: () => import(/* webpackChunkName: "truefit" */ './truefit'),
    breadcrumb: () => import(/* webpackChunkName: "breadcrumb" */ './breadcrumb'),
    bookingSummary: () => import(/* webpackChunkName: "bookingSummary" */ './bookingSummary'),
    siteMap: () => import(/* webpackChunkName: "siteMap" */ './siteMap'),
    categoryStacked: () => import(/* webpackChunkName: "categoryStacked" */ './categoryStacked'),
    category: () => import(/* webpackChunkName: "category" */ './category'),
}

/**
 * Dynamically imported components
 */
const dynamicImportComponentMap: { [key: string]: () => Promise<any> } = {
    StoreLocator: async () => {
        const module = (await ChunksLoader.storeLocator()) as StoreLocatorType
        return module.default.StoreLocator
    },
    ContactUsFormComponent: async () => {
        const module = (await ChunksLoader.contactUs()) as ContactUsType
        return module.default.ContactUsFormComponent
    },
    WeatherTechVehicleSelector: async () => {
        const module = (await ChunksLoader.weatherTechVehicleSelector()) as WeatherTechVehicleSelectorType
        return module.default.WeatherTechVehicleSelector
    },
    WeatherTechProductListingPanel: async () => {
        const module = (await ChunksLoader.weatherTechProductListingPanel()) as WeatherTechProductListingPanelType
        return module.default.WeatherTechProductListingPanel
    },
    DynamicVehicleSeoCopyBlock: async () => {
        const module = (await ChunksLoader.vehicles()) as VehiclesType
        return module.default.DynamicVehicleSeoCopyBlock
    },
    VehicleAttributesGrid: async () => {
        const module = (await ChunksLoader.vehicles()) as VehiclesType
        return module.default.VehicleAttributesGrid
    },
    VehicleFetchConsumer: async () => {
        const module = (await ChunksLoader.vehicles()) as VehiclesType
        return module.default.VehicleFetchConsumer
    },
    VehicleSelectorRedesigned: async () => {
        const module = (await ChunksLoader.vehicles()) as VehiclesType
        return module.default.VehicleSelectorRedesigned
    },
    OrderSummary: async () => {
        const module = (await ChunksLoader.orderSummary()) as OrderSummaryType
        return module.default.OrderSummary
    },
    WishlistSummary: async () => {
        const module = (await ChunksLoader.orderSummary()) as OrderSummaryType
        return module.default.WishlistSummary
    },
    LinkRewardsWrapper: async () => {
        const module = (await ChunksLoader.rewards()) as RewardsType
        return module.default.LinkRewardsWrapper
    },
    LinkCardOptions: async () => {
        const module = (await ChunksLoader.rewards()) as RewardsType
        return module.default.LinkCardOptions
    },
    NewRewardsCard: async () => {
        const module = (await ChunksLoader.rewards()) as RewardsType
        return module.default.NewRewardsCard
    },
    ExistingRewardsCard: async () => {
        const module = (await ChunksLoader.rewards()) as RewardsType
        return module.default.ExistingRewardsCard
    },
    LinkMasterCard: async () => {
        const module = (await ChunksLoader.rewards()) as RewardsType
        return module.default.LinkMasterCard
    },
    Buybox: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.BuyBox
    },
    stickyBuyBox: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.StickyBuyBoxComp
    },
    stickyBuyBar: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.StickyBuyBarComp
    },
    ProductListingPanel: async () => {
        const module = (await ChunksLoader.productlistingpanel()) as ProductlistingpanelType
        return module.default.ProductListPanel
    },
    FilterPanel: async () => {
        const module = (await ChunksLoader.productlistingpanel()) as ProductlistingpanelType
        return module.default.FacetPanel
    },
    SearchTitle: async () => {
        const module = (await ChunksLoader.productlistingpanel()) as ProductlistingpanelType
        return module.default.SearchTitle
    },
    ProductCarousel: async () => {
        const module = (await ChunksLoader.recommendations()) as RecommendationsType
        return module.default.ProductCarousel
    },
    CartItems: async () => {
        const module = (await ChunksLoader.shoppingCart()) as ShoppingCartType
        return module.default.ShoppingCart
    },
    Checkout: async () => {
        const module = (await ChunksLoader.checkout()) as CheckoutType
        return module.default.Checkout
    },
    ContactInformation: async () => {
        const module = (await ChunksLoader.checkout()) as CheckoutType
        return module.default.ContactInformation
    },
    StoreInitiatedCartPopup: async () => {
        const module = (await ChunksLoader.checkout()) as CheckoutType
        return module.default.StoreInitiatedCartPopup
    },
    SaveTheSaleCartPopup: async () => {
        const module = (await ChunksLoader.checkout()) as CheckoutType
        return module.default.SaveTheSaleCartPopup
    },
    WarnCustomerPopup: async () => {
        const module = (await ChunksLoader.checkout()) as CheckoutType
        return module.default.WarnCustomerPopup
    },
    ProductCardAndBilling: async () => {
        const module = (await ChunksLoader.purchase()) as PurchaseType
        return module.default.ProductCardAndBilling
    },
    TriangleUpsell: async () => {
        const module = (await ChunksLoader.purchase()) as PurchaseType
        return module.default.TriangleRewardsUpsell
    },
    OrderDetailsHeader: async () => {
        const module = (await ChunksLoader.purchase()) as PurchaseType
        return module.default.OrderDetailsHeader
    },
    OrderConfirmationHeader: async () => {
        const module = (await ChunksLoader.purchase()) as PurchaseType
        return module.default.OrderHeader
    },
    PaymentComponent: async () => {
        const module = (await ChunksLoader.account()) as AccountType
        return module.default.PaymentInfo
    },
    ShoppingPreferencesComponent: async () => {
        const module = (await ChunksLoader.account()) as AccountType
        return module.default.ShoppingPreferences
    },
    AccountDashboard: async () => {
        const module = (await ChunksLoader.account()) as AccountType
        return module.default.AccountDashboard
    },
    OrderHistory: async () => {
        const module = (await ChunksLoader.account()) as AccountType
        return module.default.OrderHistory
    },
    GreetingSummary: async () => {
        const module = (await ChunksLoader.account()) as AccountType
        return module.default.GreetingSummary
    },
    ProfileComponent: async () => {
        const module = (await ChunksLoader.accountCustomerserviceStore()) as AccountCustomerserviceStoreType
        return module.default.ProfileInfo
    },
    AddressComponent: async () => {
        const module = (await ChunksLoader.accountCustomerserviceStore()) as AccountCustomerserviceStoreType
        return module.default.Addresses
    },
    MergeRewardsComponent: async () => {
        const module = (await ChunksLoader.accountCustomerserviceStore()) as AccountCustomerserviceStoreType
        return module.default.MergeLoyaltyCard
    },
    TriangleTransactionHistory: async () => {
        const module = (await ChunksLoader.accountCustomerserviceStore()) as AccountCustomerserviceStoreType
        return module.default.TriangleTransactionHistory
    },
    PreferencesComponent: async () => {
        const module = (await ChunksLoader.accountCustomerserviceStore()) as AccountCustomerserviceStoreType
        return module.default.NotificationPreferences
    },
    RewardsComponent: async () => {
        const module = (await ChunksLoader.accountCustomerserviceStore()) as AccountCustomerserviceStoreType
        return module.default.OffersWrapper
    },
    MergeCartPopup: async () => {
        const module = (await ChunksLoader.cart()) as CartType
        return module.default.MergeCartPopup
    },
    ShareCartButton: async () => {
        const module = (await ChunksLoader.cart()) as CartType
        return module.default.ShareCartButton
    },
    PackageLanding: async () => {
        const module = (await ChunksLoader.categorylevel2()) as Categorylevel2Type
        return module.default.PackageLanding
    },
    ResponsiveCockpitIFrameMessenger: async () => {
        const module = (await ChunksLoader.extra()) as ExtraType
        return module.default.ResponsiveCockpitIFrameMessenger
    },
    SaveForLater: async () => {
        const module = (await ChunksLoader.cart()) as CartType
        return module.default.SaveForLater
    },
    BannerSkinnyVideo: async () => {
        const module = (await ChunksLoader.extra()) as ExtraType
        return module.default.BannerSkinnyVideo
    },
    MediaGallery: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.MediaGallery
    },
    Resource: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.Resource
    },
    Specifications: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.Specifications
    },
    StickyTOC: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.StickyTOC
    },
    BazaarVoice: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.BazaarVoice
    },
    DemoVideo: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.DemoVideo
    },
    Features: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.Features
    },
    ProductDetails: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.ProductDetails
    },
    RoadRating: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.RoadRating
    },
    FrequentlyBoughtTogether: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.FrequentlyBoughtTogether
    },
    PotentialToEarn: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.PotentialToEarn
    },
    WishlistItems: async () => {
        const module = (await ChunksLoader.wishlist()) as WishlistType
        return module.default.WishList
    },
    CartFlyout: async () => {
        const module = (await ChunksLoader.productWishlist()) as ProductWishlistType
        return module.default.CartFlyout
    },
    DigitalFlyer: async () => {
        const module = (await ChunksLoader.digitalFlyer()) as DigitalFlyerType
        return module.default.DigitalFlyer
    },
    OrderStatusFormComponent: async () => {
        const module = (await ChunksLoader.orderStatus()) as OrderStatusType
        return module.default.CheckOrderStatus
    },
    AutoAppointmentFrame: async () => {
        const module = (await ChunksLoader.autoServiceAppointment()) as AutoServiceAppointmentType
        return module.default.AutoAppointmentFrame
    },
    BookingForm: async () => {
        const module = (await ChunksLoader.autoServiceAppointment()) as AutoServiceAppointmentType
        return module.default.AOABookingForm
    },
    AOACancelChangeAppointment: async () => {
        const module = (await ChunksLoader.autoServiceAppointment()) as AutoServiceAppointmentType
        return module.default.AOACancelChangeAppointment
    },
    AOAHeader: async () => {
        const module = (await ChunksLoader.autoServiceAppointment()) as AutoServiceAppointmentType
        return module.default.AOAHeader
    },
    BookingResult: async () => {
        const module = (await ChunksLoader.autoServiceAppointment()) as AutoServiceAppointmentType
        return module.default.AOABookingResultCard
    },
    SkusDetails: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.SkusDetails
    },
    ErrorSearchBar: async () => {
        const module = (await ChunksLoader.errorPages()) as ErrorSearchBar
        return module.default.ErrorSearchBar
    },
    BannerDisclaimer: async () => {
        const module = (await ChunksLoader.banners()) as BannersType
        return module.default.BannerDisclaimer
    },
    BannerVideoModal: async () => {
        const module = (await ChunksLoader.banners()) as BannersType
        return module.default.BannerVideoModal
    },
    UltraSkinnyBanner: async () => {
        const module = (await ChunksLoader.banners()) as BannersType
        return module.default.UltraSkinnyBanner
    },
    NotificationBanner: async () => {
        const module = (await ChunksLoader.banners()) as BannersType
        return module.default.NotificationBanner
    },
    PackageProgressBanner: async () => {
        const module = (await ChunksLoader.banners()) as BannersType
        return module.default.PackageProgressBanner
    },
    TriangleMCBannerComponent: async () => {
        const module = (await ChunksLoader.banners()) as BannersType
        return module.default.TriangleMCBannerComponent
    },
    StickyBanner: async () => {
        const module = (await ChunksLoader.banners()) as BannersType
        return module.default.StickyBanner
    },
    TitleWrapper: async () => {
        const module = (await ChunksLoader.product()) as ProductType
        return module.default.TitleWrapper
    },
    StoreDetailsComp: async () => {
        const module = (await ChunksLoader.storeDetails()) as StoreDetailsType
        return module.default.StoreDetailsComp
    },
    NearbyStores: async () => {
        const module = (await ChunksLoader.storeDetails()) as StoreDetailsType
        return module.default.NearbyStores
    },
    PencilBannerLinks: async () => {
        const module = (await ChunksLoader.header()) as HeaderType
        return module.default.PencilBannerLinks
    },
    WishlistAndCart: async () => {
        const module = (await ChunksLoader.header()) as HeaderType
        return module.default.WishlistAndCart
    },
    SearchBar: async () => {
        const module = (await ChunksLoader.header()) as HeaderType
        return module.default.SearchBar
    },
    SideNavigation: async () => {
        const module = (await ChunksLoader.header()) as HeaderType
        return module.default.SideNavigation
    },
    SecondaryNavigation: async () => {
        const module = (await ChunksLoader.header()) as HeaderType
        return module.default.SecondaryNavigation
    },
    LoyaltyOffers: async () => {
        const module = (await ChunksLoader.loyaltyOffers()) as LoyaltyOffersType
        return module.default.LoyaltyOffers
    },
    LoyaltyOffersCarousel: async () => {
        const module = (await ChunksLoader.loyaltyOffers()) as LoyaltyOffersType
        return module.default.LoyaltyOffersCarousel
    },
    TruefitButton: async () => {
        const module = (await ChunksLoader.truefit()) as TruefitType
        return module.default.TruefitButton
    },
    TruefitComponent: async () => {
        const module = (await ChunksLoader.truefit()) as TruefitType
        return module.default.TruefitComponent
    },
    Breadcrumb: async () => {
        const module = (await ChunksLoader.breadcrumb()) as BreadcrumbType
        return module.default.Breadcrumb
    },
    BookingSummary: async () => {
        const module = (await ChunksLoader.bookingSummary()) as BookingSummaryType
        return module.default.BookingSummary
    },
    HtmlSiteMap: async () => {
        const module = (await ChunksLoader.siteMap()) as siteMapType
        return module.default.SiteMap
    },
    CategoryStacked: async () => {
        const module = (await ChunksLoader.categoryStacked()) as categoryStackedType
        return module.default.CategoryStacked
    },
    CategoryMasthead: async () => {
        const module = (await ChunksLoader.category()) as categoryType
        return module.default.CategoryMasthead
    },
    CategoryCarousel: async () => {
        const module = (await ChunksLoader.category()) as categoryType
        return module.default.CategoryCarousel
    },
    StackedCategoryList: async () => {
        const module = (await ChunksLoader.category()) as categoryType
        return module.default.StackedCategoryList
    },
}

export default {
    ChunksLoader,
    dynamicImportComponentMap,
}
