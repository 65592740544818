/* eslint no-invalid-this: 0 */
// TODO: need to investigate above eslint issue further
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { AnalyticsComponent } from './analyticsComponent.abstract'
import { analyticsAttributes, AnalyticsDomSelector } from '../../globalConstants/analyticsParams.constant'
import { TriggerEventType } from './component.type'
import { anchorTag, anchorTagSmall, promoCtaClass, anchorTargetBlank } from './analytics.constant'

/**
 * CTA component analytics
 */
export class PromoCta extends AnalyticsComponent {
    ctaText: string | undefined
    /**
     * Register event listeners of CTAs
     */
    registerListeners = (): void => {
        this.triggerEvents.forEach((event: TriggerEventType) => {
            if (event === TriggerEventType.CLICK) {
                this.registerClickListener()
            }
        })
    }

    /**
     * Register Click listeners on anchor and button elements
     * On Click pushing data to analytics layer
     *
     */
    private registerClickListener = (): void => {
        // checking for anchor element
        if (this.domElement.nodeName === anchorTag) {
            this.domElement.addEventListener('click', (event: MouseEvent) => {
                const element = event.target as HTMLAnchorElement
                let hrefValue = element.href
                let targetValue = element.target

                if (!hrefValue) {
                    const anchorClosest = element.closest(anchorTagSmall) as HTMLAnchorElement
                    hrefValue = anchorClosest.href
                    targetValue = anchorClosest.target
                }

                const promoCtaLink = (element.closest(AnalyticsDomSelector.promo) as HTMLElement).querySelectorAll(
                    `.${promoCtaClass}`,
                ) // find the closest anchor tag and check children with 'js-promo-cta-name' class

                if (promoCtaLink.length > 0 && element.getAttribute('data-analytics-type') !== 'promoCta') {
                    this.ctaText =
                        promoCtaLink[0]?.textContent?.trim() ||
                        promoCtaLink[0]?.children[0]?.getAttribute('alt') ||
                        undefined
                } else {
                    this.ctaText = element?.text?.trim() || undefined // Returning the CTA text as undefined in case of empty string
                }

                this.push(this.createData())

                // open anchor url
                if (targetValue === anchorTargetBlank) {
                    window.open(hrefValue, targetValue, 'noopener')
                } else {
                    window.location.href = hrefValue
                }
            })
        } else {
            // button element click event listener
            this.domElement.addEventListener('click', (event: MouseEvent) => {
                const element = event.target as HTMLButtonElement
                this.ctaText = element?.textContent?.trim() || undefined
                this.push(this.createData())
            })
        }
    }

    /**
     * Create analytics data
     * @return {AnalyticsRecordInterface}
     */
    createData = (): AnalyticsRecordInterface => {
        return {
            event: analyticsAttributes.event.promoClick,
            promotion: Object.assign({}, this.analyticsData, { cta: this.ctaText }),
        }
    }

    /**
     * Get text of cta
     * @return {string}
     */
    getCtaText = (): string | null | undefined => {
        // check for anchor text
        if (this.domElement.nodeName === anchorTag) {
            if (this.domElement.children.length > 0) {
                // if feature list have wrapper component anchor tag and extract the link with 'js-promo-cta-name'
                return this.domElement.querySelector(`.${promoCtaClass}`)?.textContent
            }
            return (this.domElement as HTMLAnchorElement).text
        } else {
            return this.domElement.textContent
        }
    }
}
