// External Integrations
import ReactCMSComp from 'react-cms-components/index'
import { PayloadAction } from 'typesafe-actions'

import * as serviceWorker from './serviceWorker'
import { setCommonAPIContent, setLoyaltyOffers } from './redux/actions'
import store from './store'
import { fetchUserProfile } from './redux/actions/user.profile.action'
import localStorageService from './utils/localStorageService'
import { setCommonAPIContentSuccess } from './redux/actionCreators'
import handleAppInit from './appInit/index'
import { Component } from './global.type'
import { renderAEMComponents, renderDynamicReactComponent } from './helpers/cms.helper'
import injectStoreInInterceptor from './redux/utils/interceptors/injectStoreInInterceptor'

injectStoreInInterceptor.setStoreValue(store)

const componentArray = ReactCMSComp.return({
    componentList: [
        ...document.querySelectorAll('[data-component-prioritized]'),
        ...document.querySelectorAll('[data-component]:not([data-component-prioritized])'),
    ],
})

// For rendering AEM components
renderAEMComponents(document)
const fetchCommonAPIContentData = () => {
    const commonAPIContent = localStorageService.getItem('common_api_props')
    if (!commonAPIContent) {
        store.dispatch(setCommonAPIContent() as unknown as PayloadAction<'SET_COMMON_API_START', null>)
    } else {
        store.dispatch(setCommonAPIContentSuccess(JSON.parse(commonAPIContent)))
    }
}

const fetchLoyaltyOffersData = () => {
    store.dispatch(setLoyaltyOffers() as unknown as PayloadAction<'SET_LOYALTY_OFFERS_FETCH_SUCCESS', null>)
}

if (componentArray.length > 0) {
    // TODO: this if statement need to be more specific to component rendering only.
    // get the user profile data
    // if the user JWT token is available get the user profile
    store.dispatch(fetchUserProfile() as unknown as PayloadAction<'FETCH_USER_PROFILE', null>)
    // Fetch common content
    fetchCommonAPIContentData()
    fetchLoyaltyOffersData()
    document.addEventListener('DOMContentLoaded', handleAppInit)
    componentArray.forEach((component: Component) => {
        renderDynamicReactComponent(component)
    })
}

serviceWorker.unregister()
