import { STICKY_TO_BOTTOM } from '../../globalConstants'

const handleScroll = () => {
    const privacyComp = document.getElementsByClassName('privacy-charter')[0]
    const elHeight = privacyComp?.clientHeight || 0
    if (window.scrollY > elHeight) {
        document
            .getElementsByClassName('privacy-charter__link-button-container')[0]
            ?.classList.remove('privacy-charter__sticky', `${STICKY_TO_BOTTOM}`)
    } else {
        document
            .getElementsByClassName('privacy-charter__link-button-container')[0]
            ?.classList.add('privacy-charter__sticky', `${STICKY_TO_BOTTOM}`)
    }
}

const privacyCharter = (): void => {
    window.addEventListener('scroll', handleScroll)
}

export { privacyCharter }
