import React from 'react'

import { PREFIX } from '../config'
import { checkoutTypes } from './GuestCheckout.type'

const GuestCheckout: React.FC<checkoutTypes> = ({ ...props }): JSX.Element => {
    const { gLoginImage, gLoginImageAltText, gLoginHeader, gLoginDesc } = props

    return (
        <div className={`${PREFIX}-signin-copy`}>
            <div className={`${PREFIX}-login-header--copy`}>
                <img className={`${PREFIX}-login--logo`} src={`${gLoginImage}`} alt={`${gLoginImageAltText}`} />
                <div className={`${PREFIX}-login-header`}>{gLoginHeader}</div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: gLoginDesc }} />
        </div>
    )
}

export default GuestCheckout
