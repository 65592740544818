import { Dispatch } from 'redux'

import { VEHICLE_SELECTOR_TABS } from '../types/automotiveBuyBox/automotiveBuyBoxSelector.actionTypes.constants'
import { vehicleSelectorTabsAction } from '../actionCreators/automotiveBuyBox.actionCreators'
import { VehicleSelectorTabs } from '../reducers/automotiveBuyBox.reducer'

export const vehicleSelectorTabs =
    (requestPayload: VehicleSelectorTabs) =>
    (dispatch: Dispatch<{ type: typeof VEHICLE_SELECTOR_TABS; payload: VehicleSelectorTabs }>): void => {
        dispatch(vehicleSelectorTabsAction(requestPayload))
    }
