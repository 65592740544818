import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import BrazeService from '../../services/brazeService/braze.service'
import { BrazeInitType } from './PageInit.type'

const brazeService = new BrazeService()

/** BrazeInit to initiate Braze Web SDK, to track a user between App and mobile browser, permitting us to allow continuity between experiences.
 * @param {BrazeInitType} props
 * @return {null}
 */
const BrazeInit: React.FC<BrazeInitType> = ({ ...props }) => {
    const { apiKey, sdkEndpointUrl } = props
    /**
     * useEffect to initialize braze web sdk
     */
    useEffect(() => {
        if (apiKey && sdkEndpointUrl) {
            // initialize the SDK
            brazeService.init(apiKey, sdkEndpointUrl)
        }
    }, [apiKey, sdkEndpointUrl])

    return null
}

BrazeInit.propTypes = {
    apiKey: PropTypes.string.isRequired,
    sdkEndpointUrl: PropTypes.string.isRequired,
}

export default BrazeInit
export { BrazeInit }
