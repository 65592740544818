import { Dispatch } from 'redux'

import { RecommendationResponseDataDTO } from '../models/recommendations.interface'
import {
    fetchRecommendationsDataSuccessAction,
    fetchRecommendationsDataErrorAction,
} from '../actionCreators/recommendationsData.actionCreators'
import { productDetailsService } from '../../services/productDetailsService'
import { AxiosError, AxiosResponse } from 'axios'
import { getEnvironment } from '../../environments'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'

export const fetchRecommendationsData =
    (
        pCodes: string[],
        storeRecommendationData?: RecommendationResponseDataDTO[],
        scheme?: string,
        setIsFetched?: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        const config = getEnvironment()
        return productDetailsService
            .getProductDetailsUsingPCodes(
                storeRecommendationData as RecommendationResponseDataDTO[],
                pCodes,
                scheme as string,
            )
            .then(data => {
                if (config.defaultProductImage) {
                    Array.isArray(data) &&
                        data.forEach(recommendationScheme => {
                            replaceEmptyImagesWithDefault(recommendationScheme?.productData, 'images')
                        })
                }
                dispatch(fetchRecommendationsDataSuccessAction(data))
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchRecommendationsDataErrorAction({ data, status }))
            })
            .finally(() => {
                setIsFetched && setIsFetched(true)
            })
    }
