import { createAction } from 'typesafe-actions'

import { ScreenSetResponse } from './../../components/GigyaScreen/gigyaScreen.type'
import {
    GigyaScreenSet,
    IS_GIGYA_LOADED,
    VALIDATE_SSO_SESSION_PENDING,
} from './../../components/GigyaScreen/gigya.constants'

export const setGigyaScreenSetData = createAction(
    GigyaScreenSet,
    (payload: ScreenSetResponse) => payload,
)<ScreenSetResponse>()

export const setIsGigyaLoaded = createAction(IS_GIGYA_LOADED)<boolean>()
export const setIsSsoSessionPending = createAction(VALIDATE_SSO_SESSION_PENDING)<boolean>()
