import React from 'react'

import { PREFIX } from '../../config'
import { SkeletonComponent } from '@nl/lib'

/**
 * returns Loading Skeleton for DynamicFeaturedList
 * @return {JSX.Element}
 */
const DynamicFeaturedList = (): JSX.Element => {
    const cols = 10
    return (
        <>
            <div className={`${PREFIX}-dynamic-featured-list  ${PREFIX}-full-width-container`}>
                <div className={`${PREFIX}-dynamic-featured-list__display-row nl-row nl-container`}>
                    <div className={`${PREFIX}-col-xs-6 ${PREFIX}-col-sm-12 ${PREFIX}-col-md-12`}>
                        {<SkeletonComponent skeletonClass={`${PREFIX}-dynamic-featured-list__heading-skeleton`} />}
                    </div>
                </div>

                <div className={`${PREFIX}-dynamic-featured-list__display-row nl-row nl-container`}>
                    <div
                        className={`${PREFIX}-dynamic-featured-list__display-row--five nl-col-md-12 nl-col-sm-12 nl-col-xs-6`}>
                        {Array(cols)
                            .fill('')
                            .map((el, index: number) => (
                                <SkeletonComponent
                                    key={index}
                                    skeletonClass={`${PREFIX}-dynamic-featured-list__component-skeleton`}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DynamicFeaturedList
