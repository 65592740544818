import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { BadgesProps, priorityAndColourProps } from './Badges.type'
import Tag from '../Tag'
import { getMaxPrioritizedBadges, updateDynamicBadges } from './badgeService'
import { PREFIX } from '../config'
import UrgencyMessage from '../UrgencyMessage'

/**
 * Badges component
 * @param {BadgesProps} props
 * @return {JSX.Element} returns badges component
 */
const Badges: React.FC<BadgesProps> = ({ ...props }) => {
    const {
        badges,
        badgesAndPriorities,
        hideDisclaimer = false,
        clickable = false,
        saleEndDaySoonMessage = null,
        salePriceEndDate = null,
        isShoppingCart = false,
        analyticsLabelValue = null,
        saleEndDisableShift = false,
    } = props

    const [badgesToShow, setBadgesToShow] = useState<priorityAndColourProps[]>([])

    /**
     * set capped badges max number as per priorities
     */
    useEffect(() => {
        if (badges && badges.length) {
            const { maxNumberToDisplay, priorityAndColour = [] } = badgesAndPriorities || {}
            const { updatedBadges, updatedpriorityAndColour } = updateDynamicBadges(badges, priorityAndColour)
            setBadgesToShow(getMaxPrioritizedBadges(updatedBadges, updatedpriorityAndColour, maxNumberToDisplay))
        } else {
            setBadgesToShow([])
        }
    }, [badges, badgesAndPriorities])

    const badgeWithDisclaimer = badgesToShow.filter(badge => badge.disclaimer)[0]

    /**
     * function to render Urgency Message
     * @return {JSX.Element} returns Urgency Message component
     */
    const renderUrgencyMessage = (): JSX.Element => {
        return (
            <UrgencyMessage
                saleEndDaySoonMessage={saleEndDaySoonMessage}
                salePriceEndDate={salePriceEndDate}
                saleEndDisableShift={saleEndDisableShift}
            />
        )
    }

    /**
     * function to return product sale ends message component text
     * @return {JSX.Element} returns product sale ends message component text
     */
    const renderSaleEndDaySoonMessageComp = (): JSX.Element => {
        const saleBadgeDisplay = !!salePriceEndDate
            ? `${PREFIX}-shopping-cart__product__sale-end-day-soon-message`
            : `${PREFIX}-shopping-cart__product__sale-end-day-soon-message--hide`
        return (
            salePriceEndDate &&
            (!!isShoppingCart ? (
                <div className={saleBadgeDisplay}>{renderUrgencyMessage()}</div>
            ) : (
                <div className={`${PREFIX}-buy-box__sale-end-day-soon-message`}>
                    <p className={`${PREFIX}-body-md`}>{renderUrgencyMessage()}</p>
                </div>
            ))
        )
    }

    return (
        <>
            {badgesToShow.map(badge => (
                <Tag
                    type="product"
                    subType={`product--${badge.colorId}`}
                    key={badge.badgeId}
                    withBorder={badge.colorId == 'tested'}
                    badgeUrl={clickable ? badge.badgeUrl : null}
                    analyticsLabelValue={
                        analyticsLabelValue?.[badge.badgeId as keyof typeof analyticsLabelValue] ?? ''
                    }>
                    {badge.badgeLabel}
                </Tag>
            ))}
            {renderSaleEndDaySoonMessageComp()}
            {!hideDisclaimer && badgeWithDisclaimer && (
                <div
                    className={`${PREFIX}-badge-disclaimer`}
                    dangerouslySetInnerHTML={{ __html: badgeWithDisclaimer.disclaimer }}></div>
            )}
        </>
    )
}

Badges.propTypes = {
    badges: PropTypes.arrayOf(PropTypes.string).isRequired,
    badgesAndPriorities: PropTypes.any,
    hideDisclaimer: PropTypes.bool,
    clickable: PropTypes.bool,
    saleEndDaySoonMessage: PropTypes.string,
    salePriceEndDate: PropTypes.string,
    isShoppingCart: PropTypes.bool,
    analyticsLabelValue: PropTypes.any,
    saleEndDisableShift: PropTypes.bool,
}

export default Badges
