import { getType } from 'typesafe-actions'

/**
 * Function to check if action type is part of certain set of actions
 * @param {any[]} actionsList
 * @param {string} actionType
 * @return {boolean}
 */
const isActionPartOfList = (actionsList: any[], actionType: string): boolean => {
    return !!actionsList.filter(actionCreatorType => actionType === getType(actionCreatorType)).length
}

export default isActionPartOfList
