import { useEffect } from 'react'

const pseudoSrcAttribute = 'data-src'
const className = 'lazyload'

export const lazyLoadImages = (images?: NodeListOf<HTMLImageElement>): void => {
    if (!images) {
        images = document.querySelectorAll(`img.${className}`)
    }

    images.forEach(image => {
        const wH = window.innerHeight
        const offset = 450
        const yPosition = image.getBoundingClientRect().top - wH

        // if the top of the image is within 450px from the bottom of the viewport
        if (yPosition <= offset) {
            if (image.getAttribute(pseudoSrcAttribute)) {
                image.src = image.getAttribute(pseudoSrcAttribute)
            }

            image.addEventListener('load', function (event) {
                ;(event.currentTarget as HTMLElement).classList.remove(className)
            })

            image.addEventListener('error', function (event) {
                ;(event.currentTarget as HTMLElement).classList.remove(className)
            })
        }
    })
}

export const useLazyLoadImage = () => {
    const images: NodeListOf<HTMLImageElement> = document.querySelectorAll(`img.${className}`)

    useEffect(() => lazyLoadImages(images), [images])
}
