import { createAction } from 'typesafe-actions'
import { CartResponseErrorDTO } from '../models/cart.interface'

import { CancellationReasons } from '../models/getOrderCancellationReasons.interface'
import {
    GET_CANCELLATION_REASONS_SUCCESS,
    GET_CANCELLATION_REASONS_FAILURE,
} from '../types/orderCancellationReasons/orderCancellationReasons.actionTypes.constants'

export const orderCancellationReasonsSuccess = createAction(
    GET_CANCELLATION_REASONS_SUCCESS,
    (payload: CancellationReasons) => payload,
)<CancellationReasons>()

export const orderCancellationReasonsFailure = createAction(GET_CANCELLATION_REASONS_FAILURE)<CartResponseErrorDTO>()
