import { ActionType, getType } from 'typesafe-actions'
import { setStaticPrerenderReadyTrue } from '../actionCreators/prerenderReadyState.actionCreators'

export interface IPrerenderState {
    staticPrerenderReady: boolean
}

type Action = ActionType<typeof setStaticPrerenderReadyTrue>

const initialState: IPrerenderState = {
    staticPrerenderReady: false,
}

export const prerenderReducer = (state: IPrerenderState = initialState, action: Action): IPrerenderState => {
    if (action.type === getType(setStaticPrerenderReadyTrue)) {
        return { ...state, staticPrerenderReady: action.payload }
    }
    return state
}
