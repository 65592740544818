import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalPropsHelper } from '../analytics/helpers/globalProps'
import { isRedirectedAfterPayPal } from '../components/Checkout/Checkout.helper'
import { AddNewCardGlobalProps } from '../components/Checkout/PaymentInformation/AddNewCard.type'
import { getHPPToken } from '../redux/actions/checkout.action'
import { storeId } from '../components/Checkout/Checkout.constant'
import { isPaypalButtonClickValidSelector } from '../redux/selectors/checkoutButtonClick.selectors'
import { commonContentSelector } from '../redux/selectors/commonContent.selectors'
import appCacheService from '../utils/appCacheService'
import { FilteredCartData } from '../redux/models/cart.interface'
import { MagicNumber } from '../analytics/analytics.type'
import getPageType from '../utils/getPageType'
import { pageTypes, paymentTypes } from '../config'
import { showSpinner } from '../redux/actions/spinner.action'
import { cartItemsDataSelector } from '../redux/selectors/cart.selectors'
import { checkoutSelector } from '../redux/selectors/checkout.selectors'
import { savedPaymentToCartSelector } from '../redux/selectors/checkoutDrawer.selectors'
import { isStoreSharedCartSelector, storeInitiatedCartSelector } from '../redux/selectors/sharedCart.selectors'
import { preferredStoreDetailsSelector } from '../redux/selectors/storeDetails.selectors'
/**
 * This function used to process initPayment logic on checkout page
 * and process regular/standard paypal functional
 * @param {number} initPaymentVersion
 * @param {boolean | undefined} mergePlaceOrderAndReviewOrderFeature
 * @return {Object} initPaymentInfo
 */
export function useCheckout(
    initPaymentVersion: number,
    mergePlaceOrderAndReviewOrderFeature?: boolean | undefined,
): Partial<AddNewCardGlobalProps> {
    const dispatch = useDispatch()
    const globalPropsHelper = new GlobalPropsHelper()
    const digicertlogourl = globalPropsHelper.digiCertLogoUrl()
    const cartData = useSelector(cartItemsDataSelector)
    const isStoreSharedCart = useSelector(isStoreSharedCartSelector)
    const storeInitiatedCart = useSelector(storeInitiatedCartSelector)
    const preferredStoreDetails = useSelector(preferredStoreDetailsSelector)

    const cartItemsData = isStoreSharedCart ? storeInitiatedCart : cartData
    const { cart } = cartItemsData

    const { hppToken, requestId, paymentPlanInfo, semaFoneEnabled, validUntil, iframeUrl } =
        useSelector(checkoutSelector)
    const savedPaymentToCart = useSelector(savedPaymentToCartSelector)
    const isPaypalButtonClickValid = useSelector(isPaypalButtonClickValidSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const {
        regularPayPalSuccessRedirectLink,
        regularPayPalErrorRedirectLink,
        regularPayPalCancelRedirectLink,
        expressPayPalSuccessRedirectLink,
        expressPayPalErrorRedirectLink,
        expressPayPalCancelRedirectLink,
    } = commonContentAvailable?.globalLinks || {}

    // To-Do: temporary fix as CDS does not return store data and init payment is not getting called
    // to be removed when CDS returns store data in cart response
    const cartStoreId = preferredStoreDetails?.id || storeId

    const createPayPalParameters = useCallback(
        (isCartPage: boolean): string[] => {
            return isCartPage
                ? ([
                      paymentTypes.PAYPAL_EXPRESS,
                      expressPayPalSuccessRedirectLink,
                      expressPayPalErrorRedirectLink,
                      expressPayPalCancelRedirectLink,
                  ] as string[])
                : ([
                      paymentTypes.PAYPAL_REGULAR,
                      regularPayPalSuccessRedirectLink,
                      regularPayPalErrorRedirectLink,
                      regularPayPalCancelRedirectLink,
                  ] as string[])
        },
        [
            expressPayPalSuccessRedirectLink,
            expressPayPalCancelRedirectLink,
            expressPayPalErrorRedirectLink,
            regularPayPalCancelRedirectLink,
            regularPayPalErrorRedirectLink,
            regularPayPalSuccessRedirectLink,
        ],
    )

    const callInitPayment = useCallback(() => {
        const isCartPage = getPageType() === pageTypes.cart

        if (isPaypalButtonClickValid) {
            dispatch(showSpinner(true))
        }

        const payPalParameters = isPaypalButtonClickValid ? createPayPalParameters(isCartPage) : []
        dispatch(
            getHPPToken(
                cart.cartId,
                cartStoreId,
                'anonymous',
                semaFoneEnabled,
                initPaymentVersion,
                mergePlaceOrderAndReviewOrderFeature,
                ...payPalParameters,
            ),
        )
    }, [
        cart,
        cartStoreId,
        semaFoneEnabled,
        initPaymentVersion,
        createPayPalParameters,
        isPaypalButtonClickValid,
        dispatch,
        mergePlaceOrderAndReviewOrderFeature,
    ])

    // Trigger HPP action when cart data loaded
    useEffect(() => {
        const isCartPage = getPageType() === pageTypes.cart
        const noCartIdOrStoreId = !cart?.cartId || !cartStoreId

        if (noCartIdOrStoreId || isRedirectedAfterPayPal() || (isCartPage && !isPaypalButtonClickValid)) {
            return
        }

        callInitPayment()
    }, [cart, cartStoreId, dispatch, savedPaymentToCart, isPaypalButtonClickValid, callInitPayment])

    /**
     * Converts amount to full decimal format
     * @param {number | null | undefined} amount
     * @return {number} return decimal implied format number
     */
    const convertDecimalImpliedFormat = (amount: number | null | undefined): number => {
        const multipliedAmt = amount * MagicNumber.HUNDRED || 0
        return parseInt(multipliedAmt.toString())
    }
    return {
        hppToken,
        requestId,
        amount: convertDecimalImpliedFormat(cart?.cartSummary?.totalWithTaxesAmt?.value),
        cartId: cart?.cartId,
        digicertlogourl,
        paymentPlanInfo,
        iframeUrl,
        validUntil,
        callInitPayment,
    }
}

/**
 * Returns cart id from the page
 * @param {FilteredCartData} cartItemsData
 * @return {string} cart guid
 */
export function useCurrentCartGUID(cartItemsData?: FilteredCartData): string {
    const isStoreSharedCart = useSelector(isStoreSharedCartSelector)
    const storeInitiatedCart = useSelector(storeInitiatedCartSelector)

    return isStoreSharedCart
        ? storeInitiatedCart.cart.cartId
        : cartItemsData
        ? cartItemsData.cart.cartId
        : appCacheService.getCartGuid()
}

export default useCheckout
