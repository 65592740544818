import checkNestedProps from '../../utils/checkNestedProps'
import { UserProfileData } from '../../redux/models/user.profile.interface'
import { magicNumber } from '@nl/lib'
/**
 * @method redirectToLoginPage: Redirect to login Page with return Url
 * @param loginPageLink
 * @return {void}
 **/

export const redirectToLoginPage = (loginPageLink: string): void => {
    window.location.href = encodeURI(
        `${loginPageLink}?returnURL=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`,
    )
}

/**
 * @method redirectToPDPIfLoyalty: Redirect to PDP Page if profile has Loyalty card
 * @param userProfileData
 * @return {void}
 **/

export const redirectToPDPIfLoyalty = (userProfileData: UserProfileData | null): void => {
    if (userProfileData !== null && checkNestedProps(userProfileData.loyalty, 'cardNumber')) {
        window.location.href = window.ODP?.globalLinks.homePageLink || ''
    }
}

/**
 * @method isRewardPage: returns true if reward page
 * @return {boolean}
 **/

export const isRewardPage = (): boolean => {
    return (
        window.location.pathname === window.ODP?.globalLinks.linkExisingCard ||
        window.location.pathname === window.ODP?.globalLinks.linkMasterCard ||
        window.location.pathname === window.ODP?.globalLinks.requestNewCard ||
        window.location.pathname === window.ODP?.globalLinks.linkRewardOptionsLink
    )
}

/**
 * @method isRegistrationAuthPage: returns true if registration page
 * @return {boolean}
 **/

export const isRegistrationAuthPage = (): boolean => {
    return (
        window.location.pathname === window.ODP?.globalLinks.registrationPageLink ||
        window.location.pathname === window.ODP?.globalLinks.passwordResetPageLink
    )
}

/**
 * @method deleteCookie: its responsible for deleting cookie from browser
 * @return {void}
 **/

export const deleteCookie = (cookieName: string): void => {
    const domain = document.location.hostname.split('www')[magicNumber.ONE]
    const dateToExpire = new Date()
    dateToExpire.setTime(dateToExpire.getTime() - magicNumber.MILLISINDAY)
    document.cookie = cookieName + `=; expires= ${dateToExpire.toString()}; domain=${domain};path=/`
}
