import { RootState } from '../reducers'

export const getIsPendingDictionaryForPreselection = (state: RootState): boolean =>
    state.preselectedVehicleFromURL.IsPendingDictionaryForPreselection

export const getIsAdditionalFetchNeeded = (state: RootState): boolean =>
    state.preselectedVehicleFromURL.isAdditionalFetchNeeded

export const getPreselectedVehicleType = (state: RootState): string | undefined =>
    state.preselectedVehicleFromURL.preselectedValues?.vehicleType

export const getPreselectedVehicleYear = (state: RootState): string | undefined =>
    state.preselectedVehicleFromURL.preselectedValues?.year

export const getPreselectedVehicleMake = (state: RootState): string | undefined =>
    state.preselectedVehicleFromURL.preselectedValues?.make

export const getPreselectedVehicleModel = (state: RootState): string | undefined =>
    state.preselectedVehicleFromURL.preselectedValues?.model

export const isPreselectedVehicleFromUrl = (state: RootState): boolean =>
    state.preselectedVehicleFromURL.isPreselectedFromURL

export const getVehicleData = (state: RootState) => state.preselectedVehicleFromURL

export const getIsPreselectedMakeOnlyCase = (state: RootState): boolean =>
    state.preselectedVehicleFromURL.isPreselectedFromURL &&
    Boolean(state.preselectedVehicleFromURL.preselectedValues.make) &&
    !Boolean(state.preselectedVehicleFromURL.preselectedValues.model)

export const getIsPreselectedMakeModelOnlyCase = (state: RootState): boolean =>
    state.preselectedVehicleFromURL.isPreselectedFromURL &&
    Boolean(state.preselectedVehicleFromURL.preselectedValues.make) &&
    Boolean(state.preselectedVehicleFromURL.preselectedValues.model) &&
    !Boolean(state.preselectedVehicleFromURL.preselectedValues.year)
