import { AxiosPromise } from 'axios'
import { SaveForLaterParams } from '../../components/ShoppingCart/SaveForLater.type'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * get service for SFL item list
 */
class SafeForLaterListService extends BaseService {
    /**
     * Send the GET request with params
     * @param {SaveForLaterParams} getSFLParams
     * @return {URL} url
     */
    getSaveForLaterListService(getSFLParams: SaveForLaterParams): AxiosPromise<any> {
        const url = this.createGetSaveForLaterListURL()
        const requestedParams = {
            currentPage: getSFLParams.currentPage,
            pageSize: getSFLParams.pageSize,
            userID: getSFLParams.userId,
            saveForLaterId: getSFLParams.saveForLaterId,
            storeId: getSFLParams.storeId,
            lang: SafeForLaterListService.language,
        }
        return httpClient.apiGet(url.toString(), requestedParams, undefined, true)
    }

    /**
     * Sends the Delete request to de CDS service
     * @param {SaveForLaterParams} getSFLParams
     * @param {number} entryNumber
     * @return {URL} url
     */
    removeFromSaveForLater(getSFLParams: SaveForLaterParams, entryNumber: number[]): AxiosPromise {
        const url: URL = this.createRemoveSaveForLaterListURL('removeSaveForLater', getSFLParams)
        const payload = {
            entryNumbers: entryNumber,
        }

        return SafeForLaterListService.isMock()
            ? httpClient.apiGet(url.toString(), payload)
            : httpClient.apiDelete(url.toString(), payload)
    }

    /**
     * Creates the SFL URL
     * @param {string} endpoint Creates the SFL URL
     * @return {URL} url
     */
    createGetSaveForLaterListURL(): URL {
        return new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS.getSaveForLater}`)
    }

    /**
     * Creates the URL for Delete request
     * @param {string} endpoint
     * @param {SaveForLaterParams} params
     * @return {URL} url
     */
    createRemoveSaveForLaterListURL(endpoint: string, params: SaveForLaterParams) {
        return new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.removeFromSaveForLater}?&currentPage=${String(
                params.currentPage,
            )}&pageSize=${String(params.pageSize)}&storeId=${String(params.storeId)}&lang=${
                SafeForLaterListService.language
            }&code=${params.saveForLaterId}`,
        )
    }
}

const safeForLaterListService = new SafeForLaterListService()
export { safeForLaterListService }
export default safeForLaterListService
