import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { EstimateFeesModalProps } from './EstimateFeesModal.type'
import TextInput from '../TextInput'
import { PREFIX, previousElementName } from '../config'
import Button from '../Button'
import ReactModal from '../ReactModal'
import Icon from '../Icon'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { useGlobalResizeEvent } from '../../utils/useWindowEvent'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'

/**
 * EstimateFeesModal component
 * @param {EstimateFeesModalProps} props
 * @return {JSX.Element} returns ReactModal and TextInput
 */
const EstimateFeesModal: React.FC<EstimateFeesModalProps> = ({ ...props }): JSX.Element => {
    const {
        isOpen,
        modalTitle,
        modalDescription,
        modalDisclaimer,
        modalInputPlaceholder,
        modalEstimatedFees,
        modalEstimatedFeesPrice,
        modalContinueLabel,
        modalContinueShoppingLabel,
        modalCancelLabel,
        a11ypcmCloseLabel,
        emptyPostalCodeMessage,
        onCloseEFAction,
        onContinueEFAction,
        onPostalCodeUpdateAction,
        pcmErrorMsg,
        selectedPostalCode,
        estimateFeesSuccessMsg,
        postalCodeRegex,
        validatePostalCode,
        showEstimatedFees,
        showEFSpinner,
        isExpressDeliveryEnabledForProduct,
        enableExpressDeliveryOption,
        estimateFeesDisclaimerExpressDelivery,
        modalEstimatedFeesPriceExpressDelivery,
        standardShippingFeesText,
        expressShippingFeesText,
        notAvailableShippingFeesText,
        isExpressDeliveryEligibleForPostalCode,
        setIsEstimateFeeBtnPressed,
    } = props
    const [postalCode, setPostalCode] = useState(selectedPostalCode)
    const [errorMsg, setErrorMsg] = useState(pcmErrorMsg)
    const [isSmallWindow, setIsSmallWindow] = useState(false)
    const componentClassName = `${PREFIX}-postal-code-modal`
    const smallWindowClass = `${componentClassName}__small-window`
    const minHeight = 650
    const isExpressDeliveryAvailable = enableExpressDeliveryOption && isExpressDeliveryEnabledForProduct

    /**
     * function to be called on postal code change action
     */
    const onPCChangeClick = useCallback((): void => {
        if (postalCode) {
            const validate = postalCodeRegex.test(postalCode)
            setErrorMsg(!validate ? emptyPostalCodeMessage : '')
            if (onContinueEFAction && validate) {
                setIsEstimateFeeBtnPressed(true)
                onContinueEFAction(postalCode)
            }
        } else {
            setIsEstimateFeeBtnPressed(false)
            setErrorMsg(emptyPostalCodeMessage)
        }
    }, [postalCode, postalCodeRegex, emptyPostalCodeMessage, onContinueEFAction, setIsEstimateFeeBtnPressed])

    useEffect(() => {
        setPostalCode(postalCode)
    }, [postalCode])

    useEffect(() => {
        setPostalCode(selectedPostalCode)
        !selectedPostalCode && setErrorMsg('')
    }, [isOpen, selectedPostalCode])

    useEffect(() => {
        if (isOpen && selectedPostalCode && postalCode && !pcmErrorMsg && !showEstimatedFees) {
            onPCChangeClick()
        }
    }, [isOpen, selectedPostalCode, postalCode, pcmErrorMsg, showEstimatedFees, onPCChangeClick])

    useEffect(() => {
        setErrorMsg(pcmErrorMsg)
        // TODO: This show spinner false may be removed in the future based on error condition
    }, [pcmErrorMsg])

    const closeHandler = () => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName: `${PREFIX}-react-modal` })
        onCloseEFAction()
        setIsEstimateFeeBtnPressed(false)
        onPostalCodeUpdateAction()
        const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    /**
     * function to check whether the device is tablet/mobile/desktop
     */
    const handleResize = (): void => {
        setIsSmallWindow(window.innerHeight <= minHeight)
    }

    useGlobalResizeEvent(handleResize)

    const modalRef = useRef(null)
    useClickOutsideClose(modalRef, closeHandler, isOpen, true)

    /**
     * function to return estimate shipping fees price and success text
     * @return {JSX.Element} returns estimate shipping fees price and success text
     */
    const renderEstimateFees = (): JSX.Element => {
        return (
            <>
                {estimateFeesSuccessMsg && (
                    <div className={`${componentClassName}__success`} role="alert">
                        <Icon type="ct-checkmark" size="sm" />
                        <span className={`${componentClassName}__success-text`}>{estimateFeesSuccessMsg}</span>
                    </div>
                )}
                {modalEstimatedFees && modalEstimatedFeesPrice && (
                    <div className={`${componentClassName}__price`}>
                        <span>{modalEstimatedFees}</span>
                        <span>{modalEstimatedFeesPrice}</span>
                    </div>
                )}
            </>
        )
    }

    /**
     * function to return estimate shipping fees price and success text for express delivery
     * @return {JSX.Element} returns estimate shipping fees price and success text
     */
    const renderEstimateFeesExpressDelivery = (): JSX.Element => {
        return (
            <>
                {estimateFeesSuccessMsg && (
                    <div className={`${componentClassName}__success`} role="alert">
                        <Icon type="ct-checkmark" size="sm" />
                        <span className={`${componentClassName}__success-text`}>{estimateFeesSuccessMsg}</span>
                    </div>
                )}
                <div className={`${componentClassName}__price-info`}>
                    {standardShippingFeesText && modalEstimatedFeesPrice && (
                        <div
                            className={`${componentClassName}__price--standard ${PREFIX}-xs-flex ${PREFIX}-xs-justify-space-between`}>
                            <span>{standardShippingFeesText}</span>
                            <span>{modalEstimatedFeesPrice}</span>
                        </div>
                    )}
                    {expressShippingFeesText && (
                        <div
                            className={`${componentClassName}__price--express ${PREFIX}-xs-flex ${PREFIX}-xs-justify-space-between`}>
                            <span>{expressShippingFeesText}</span>
                            <span>
                                {isExpressDeliveryEligibleForPostalCode
                                    ? modalEstimatedFeesPriceExpressDelivery
                                    : notAvailableShippingFeesText}
                            </span>
                        </div>
                    )}
                </div>
            </>
        )
    }

    return (
        <ReactModal closeHandler={closeHandler} isOpen={isOpen} isHeightFix={true}>
            <div role="dialog" aria-label={modalTitle} className={`${componentClassName}`} ref={modalRef}>
                <div className={`${componentClassName}__close-container`}>
                    <button
                        className={`${componentClassName}__close-btn`}
                        onClick={closeHandler}
                        aria-label={a11ypcmCloseLabel}>
                        <Icon type="ct-close" size="lg"></Icon>
                    </button>
                </div>
                <div className={`${componentClassName}__content`}>
                    <h3 className={`${componentClassName}__title`}>{modalTitle}</h3>
                    <div className={`${componentClassName}__description`}>
                        <p>{modalDescription}</p>
                    </div>
                    <TextInput
                        id="postal-code-modal"
                        label={modalInputPlaceholder}
                        value={postalCode}
                        error={errorMsg}
                        success={showEstimatedFees}
                        maxLength={7}
                        onChange={val => setPostalCode(validatePostalCode(val))}
                        size="default"
                    />
                    {isExpressDeliveryAvailable
                        ? showEstimatedFees && renderEstimateFeesExpressDelivery()
                        : showEstimatedFees && renderEstimateFees()}
                    <div className={`${componentClassName}__footer-buttons ${isSmallWindow ? smallWindowClass : ''}`}>
                        <div
                            className={`${componentClassName}__disclaimer`}
                            data-testid="tooltip-text"
                            dangerouslySetInnerHTML={{
                                __html: isExpressDeliveryAvailable
                                    ? estimateFeesDisclaimerExpressDelivery
                                    : modalDisclaimer,
                            }}></div>
                        <Button
                            type="primary"
                            size="medium"
                            onClick={() => {
                                showEstimatedFees ? closeHandler() : onPCChangeClick()
                            }}
                            label={
                                isExpressDeliveryAvailable
                                    ? modalContinueLabel
                                    : showEstimatedFees
                                    ? modalContinueShoppingLabel
                                    : modalContinueLabel
                            }
                            showSpinner={showEFSpinner}
                            disabled={!postalCode}></Button>
                        <Button type="tertiary" onClick={closeHandler}>
                            {modalCancelLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}

EstimateFeesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    modalDisclaimer: PropTypes.string,
    modalInputPlaceholder: PropTypes.string,
    modalEstimatedFees: PropTypes.string,
    modalEstimatedFeesPrice: PropTypes.string,
    modalContinueLabel: PropTypes.string,
    modalContinueShoppingLabel: PropTypes.string,
    modalCancelLabel: PropTypes.string,
    a11ypcmCloseLabel: PropTypes.string,
    emptyPostalCodeMessage: PropTypes.string,
    pcmErrorMsg: PropTypes.string,
    selectedPostalCode: PropTypes.string,
    estimateFeesSuccessMsg: PropTypes.string,
    onCloseEFAction: PropTypes.func,
    onContinueEFAction: PropTypes.func,
    onPostalCodeUpdateAction: PropTypes.func,
    postalCodeRegex: PropTypes.instanceOf(RegExp).isRequired,
    validatePostalCode: PropTypes.func,
    showEstimatedFees: PropTypes.bool,
    showEFSpinner: PropTypes.bool,
}

export default EstimateFeesModal
