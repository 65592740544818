import { MagicNumber } from '../analytics/analytics.type'
import { isArrayNotEmpty } from '@nl/lib'
import { CategoryBreadCrumb, CategoryBreadCrumbLevels } from '../redux/models/category.interface'

/**
 * @param {CategoryBreadCrumb[]}  breadecrumb array which needs to manipulate and get categoryLevel data
 * @return {CategoryBreadCrumbLevels}
 **/
const getCategoryLevelData = (breadecrumb: CategoryBreadCrumb[]): CategoryBreadCrumbLevels => {
    return isArrayNotEmpty(breadecrumb)
        ? breadecrumb.reduce(
              (initialValue: CategoryBreadCrumbLevels, currentValue: CategoryBreadCrumb, index: MagicNumber) => {
                  return {
                      ...initialValue,
                      [`categoryLevel${index + MagicNumber.ONE}`]: currentValue.label || '',
                  }
              },
              {},
          )
        : {
              categoryLevel1: undefined,
              categoryLevel2: undefined,
              categoryLevel3: undefined,
              categoryLevel4: undefined,
              categoryLevel5: undefined,
          }
}

export default getCategoryLevelData
