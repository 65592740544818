import { BrandingColors } from '../../globalConstants/global.constant'

export interface SeparatorProps {
    size?: SeparatorSize
    colors: Array<BrandingColors>
    style?: SeparatorStyle
    reverseStyle?: boolean
}

export enum SeparatorSize {
    FULL_WIDTH = 'full-width',
    THREE_QUARTER_WIDTH = 'three-quarter-width',
    TWO_THIRD_WIDTH = 'two-third-width',
    HALF_WIDTH = 'half-width',
    QUARTER_WIDTH = 'quarter-width',
    THIRD_WIDTH = 'third-width',
    EIGHTH_WIDTH = 'eighth-width',
    SIXTEENTH_WIDTH = 'sixteenth-width',
}

export enum SeparatorStyle {
    SKEW = 'skew',
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}
