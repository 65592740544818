import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/stickyBuyBar.actionCreators'
type Action = ActionType<typeof actions>

export interface StickyBuyBarState {
    isStickyBBInView: boolean
    height: number
    showSBBSpinner: boolean
}

const initialState: StickyBuyBarState = {
    isStickyBBInView: false,
    height: 0,
    showSBBSpinner: false,
}

export const stickyBBReducer = (state: StickyBuyBarState = initialState, action: Action): StickyBuyBarState => {
    if (action.type === getType(actions.setIsStickyBuyBarInViewAction)) {
        return { ...state, isStickyBBInView: action.payload }
    } else if (action.type === getType(actions.setStickyBuyBuyBarHeightAction)) {
        return { ...state, height: action.payload }
    } else if (action.type === getType(actions.setStickyBuyBarATCSpinnerAction)) {
        return { ...state, showSBBSpinner: action.payload }
    } else {
        return state
    }
}
