import {
    srpURL,
    minimumBreadcrumbLength,
    productLength,
    toGetLastBreadcrumb,
    defaultCheckValue,
    toGetPrevCategory,
    loc,
    plpURLParam,
} from './Breadcrumb.constant'
import { isArrayNotEmpty } from '@nl/lib'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { BreadcrumbParentPageLink, BreadcrumbDataProps } from './BreadcrumbsComp.type'
import { BreadcrumbLinkData, Facet, PremiumFilter, SortOption } from '../../redux/models/productData.interface'
import { MagicNumber } from '../../analytics/analytics.type'
import sessionStorageService from '../../utils/sessionStorageService'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { storageData } from '../../globalConstants'

const { plp, pdpPage, searchPage, categoryPages, eventListing, promoListing, checkout, packageLanding, store } =
    pageTypes
/**
 *  function to display breadcrumb links for back navigation in pdp page based on plp and srp in url condition
 * @param {string | undefined} backToResults
 * @param {string} backToSearchResults
 * @return {string | undefined}
 */
const backNavigationPDP = (backToResults: string | undefined, backToSearchResults: string): string | undefined => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (!!RegExp(srpURL).exec(window.location.href)) {
        return backToSearchResults
    } else if (urlSearchParams.get(loc) === plpURLParam) {
        return backToResults
    } else return
}

export const getBreadcrumbParentPageLink = (name?: string, link?: string, pName?: string): BreadcrumbParentPageLink => {
    return {
        name,
        link,
        pName,
    }
}

/**
 *  function to display breadcrumb mobile links in pdp page
 * @param {BreadcrumbParentPageLink} homePageItem
 * @param {BreadcrumbParentPageLink[]} breadcrumbData
 * @param {BreadcrumbParentPageLink[]} backNavigation
 * @param {string} backToLabel
 * @param {string} currentPageName
 * @param {boolean} isStaticPages
 * @return {BreadcrumbParentPageLink[]} returns breadcrumb mobile array of brandPage
 */
const getMobileBreadcrumb = (
    homePageItem: BreadcrumbParentPageLink,
    breadcrumbData: BreadcrumbParentPageLink[],
    backNavigation: BreadcrumbParentPageLink,
    backToLabel = '',
    currentPageName: string,
    isStaticPages: boolean,
): BreadcrumbParentPageLink => {
    const pageType = getPageType()
    if (isArrayNotEmpty(breadcrumbData)) {
        const breadcrumbLength = breadcrumbData.length
        const mobileBreadcrumb =
            breadcrumbLength > minimumBreadcrumbLength
                ? categoryPages.includes(pageType)
                    ? breadcrumbData[breadcrumbLength - toGetPrevCategory]
                    : breadcrumbData[breadcrumbLength - toGetLastBreadcrumb]
                : isStaticPages && currentPageName
                ? breadcrumbData[MagicNumber.ZERO]
                : homePageItem
        if (!backNavigation.name) {
            return {
                name: `${backToLabel} ${String(mobileBreadcrumb.name)}`,
                link: `${String(mobileBreadcrumb.link)}`,
            }
        } else return mobileBreadcrumb
    } else {
        return homePageItem
    }
}

/**
 *  function to get desktop breadcrumbs list
 * @param {BreadcrumbDataProps} breadcrumbDataProps
 * @return {BreadcrumbParentPageLink[]} returns breadcrumb array
 */
// eslint-disable-next-line complexity
export const getBreadcrumbDesktopLink = (breadcrumbDataProps: BreadcrumbDataProps): BreadcrumbParentPageLink[] => {
    const {
        breadcrumb,
        backToResults,
        backToSearchResults,
        currentPageName,
        currentPageLink,
        homePageItem,
        displayProductName,
        isBackToSearchResults,
        backToCartItem,
    } = breadcrumbDataProps
    const pageType = getPageType()
    const currentPageItem = getBreadcrumbParentPageLink(currentPageName, currentPageLink)
    const navLabel = backNavigationPDP(backToResults, backToSearchResults)
    const backNavigationLink = checkDataLength(sessionStorageService.getItem(storageData.backToResultLink))
        ? sessionStorageService.getItem(storageData.backToResultLink)
        : document.referrer
    const backNavigation = getBreadcrumbParentPageLink(navLabel, backNavigationLink)

    /**
     * function to get Product Name with a character count approx. 25 characters before truncation
     * @return {string}
     */
    const fetchProductName = (): string => {
        const pName: string = breadcrumb[breadcrumb.length - MagicNumber.ONE].name
        return pName.length > productLength ? `${pName.substring(0, productLength)}...` : pName
    }

    const defaultPLPBreadCrumbList: BreadcrumbParentPageLink[] = [homePageItem, ...breadcrumb]
    const defaultSRPBreadCrumbList: BreadcrumbParentPageLink[] = [
        homePageItem,
        breadcrumb[toGetLastBreadcrumb - MagicNumber.ONE],
    ]

    if (pageType === plp) {
        return defaultPLPBreadCrumbList
    } else if (pageType === pdpPage) {
        // pageType is pdp
        const productName = fetchProductName()
        const pName = breadcrumb[breadcrumb.length - MagicNumber.ONE].name
        const link = breadcrumb[breadcrumb.length - MagicNumber.ONE].link
        if (!!productName) {
            breadcrumb.pop()
            // if product Name is available and check for backNavigation so breadcrumb
            if (!!backNavigation.name) {
                if (!!displayProductName) {
                    return [
                        backNavigation,
                        homePageItem,
                        ...breadcrumb,
                        { name: productName, pName: pName, link: link },
                    ] as BreadcrumbParentPageLink[]
                } else {
                    return [backNavigation, homePageItem, ...breadcrumb] as BreadcrumbParentPageLink[]
                }
            } else if (!!displayProductName) {
                return [
                    homePageItem,
                    ...breadcrumb,
                    { name: productName, pName: pName, link: link },
                ] as BreadcrumbParentPageLink[]
            } else {
                return [homePageItem, ...breadcrumb] as BreadcrumbParentPageLink[]
            }
        } else {
            if (!!backNavigation.name) {
                return [backNavigation, homePageItem, ...breadcrumb] as BreadcrumbParentPageLink[]
            } else {
                return [homePageItem, ...breadcrumb] as BreadcrumbParentPageLink[]
            }
        }
    } else if (pageType === searchPage || categoryPages.includes(pageType || '')) {
        return isBackToSearchResults
            ? ([homePageItem, breadcrumb[toGetLastBreadcrumb]] as BreadcrumbParentPageLink[])
            : pageType === searchPage
            ? defaultSRPBreadCrumbList
            : defaultPLPBreadCrumbList
    } else if (pageType === checkout) {
        return [backToCartItem] as BreadcrumbParentPageLink[]
    } else {
        // non pdp/plp pages
        return [homePageItem, ...breadcrumb, currentPageItem] as BreadcrumbParentPageLink[]
    }
}

/**
 *  function to get mobile breadcrumbs list
 * @param {BreadcrumbDataProps} breadcrumbDataProps
 * @return {BreadcrumbParentPageLink[]} returns breadcrumb array
 */
// eslint-disable-next-line complexity
export const getBreadcrumbMobileLink = (breadcrumbDataProps: BreadcrumbDataProps): BreadcrumbParentPageLink[] => {
    const {
        breadcrumb,
        backToResults,
        backToSearchResults,
        backToLabel = '',
        homePageItem,
        isBackToSearchResults,
        backToCartItem,
    } = breadcrumbDataProps

    const pageType = getPageType()
    const breadcrumbLength = breadcrumb.length
    const navLabel = backNavigationPDP(backToResults, backToSearchResults)
    const backNavigationLink = checkDataLength(sessionStorageService.getItem(storageData.backToResultLink))
        ? sessionStorageService.getItem(storageData.backToResultLink)
        : document.referrer
    const backNavigation = getBreadcrumbParentPageLink(navLabel, backNavigationLink)
    const isStaticPages =
        pageType === eventListing || pageType === promoListing || pageType === packageLanding || pageType === store
    const isDynamicPages = pageType === plp || pageType === pdpPage || categoryPages.includes(pageType || '')
    if (isDynamicPages || isStaticPages) {
        if (pageType === pdpPage) {
            breadcrumb.pop()
        }
        const mobileBreadcrumb = getMobileBreadcrumb(
            homePageItem,
            breadcrumb,
            backNavigation,
            backToLabel,
            breadcrumbDataProps.currentPageName,
            isStaticPages,
        )
        return !!backNavigation.name ? [backNavigation, mobileBreadcrumb] : [mobileBreadcrumb]
    } else if (pageType === searchPage) {
        return isBackToSearchResults
            ? [
                  {
                      name: backToSearchResults,
                      link: breadcrumb[toGetLastBreadcrumb].link,
                  },
              ]
            : [
                  {
                      name: `${backToLabel} ${String(homePageItem.name)}`,
                      link: homePageItem.link,
                  },
              ]
    } else if (pageType === checkout) {
        return [backToCartItem] as BreadcrumbParentPageLink[]
    } else {
        return isArrayNotEmpty(breadcrumb)
            ? ([breadcrumb[breadcrumbLength - toGetLastBreadcrumb]] as BreadcrumbParentPageLink[])
            : [
                  {
                      name: `${backToLabel} ${String(homePageItem.name)}`,
                      link: homePageItem.link,
                  },
              ]
    }
}

/**
 * This function returns breadcrumbList
 * @param {BreadcrumbLinkData[]} breadcrumbList
 * @return {BreadcrumbParentPageLink[]} returns breadcrumbList
 */
export const getBreadcrumbList = (breadcrumbList: BreadcrumbLinkData[]): BreadcrumbParentPageLink[] => {
    return breadcrumbList.map((breadcrumb: BreadcrumbLinkData) => {
        const { label, url } = breadcrumb
        return {
            name: label,
            link: url,
        }
    })
}

/**
 *  Memorized FacetValue selected check function
 * @param {Facet[]} facetsData
 * @return {boolean} returns boolean
 */
export const checkFacetSelectedValue = (facetsData: Facet[]): boolean => {
    let selectedFacet = 0
    isArrayNotEmpty(facetsData) && facetsData.forEach((value: Facet) => (value.selected ? selectedFacet++ : null))
    return !!selectedFacet
}

/**
 *  Memorized SortValue selected check function
 * @param {SortOption[]} sortData
 * @return {boolean} returns boolean
 */
export const checkSortSelectedValue = (sortData: SortOption[]): boolean => {
    let selectedSort = false
    const updatedSortList = isArrayNotEmpty(sortData) ? sortData.slice(defaultCheckValue) : []
    updatedSortList.forEach((value: SortOption) => (value.selected ? (selectedSort = true) : null))
    return selectedSort
}

/**
 *  Memorized  function to check SaleItem & inStockAtMyStore selected value
 * @param {PremiumFilter | undefined } itemData
 * @return {boolean|undefined} returns boolean or undefined as these objects are optional
 */
export const checkSaleInstoreItemSelected = (itemData: PremiumFilter | undefined): boolean | undefined => {
    return itemData && Object.values(itemData) && itemData.selected
}

/**
 * function to check for non pdp/non plp pages
 * @return {boolean}
 */
export const isDynamicPage = (): boolean => {
    const pageType = getPageType()
    return (
        pageType !== pdpPage &&
        pageType !== plp &&
        pageType !== searchPage &&
        !categoryPages.includes(pageType || '') &&
        pageType !== pageTypes.store
    )
}
