import { ProductOption, ProductOptionValue } from '../components/VariantsWrapper/VariantsWrapper.type'

/**
 * Check if all elements from tareget array present in another array
 * @param {number[]} source - source array
 * @param {number[]} target - target array
 * @return {boolean}
 */
export const compareTwoArrays = (source: number[], target: number[]): boolean => {
    return target.every((v: number) => source.includes(v))
}

/**
 * Function to return all available sku arrays from skus
 * @param {number[]} variantIds - List of variant Id
 * @param {ProductOption[]} options - options array
 * @return {string[]}
 */
export const getSkuArray = (variantIds: string[], options: ProductOption[]): string[] => {
    let getSkuArr: string[] = []
    let isInitialSetDone
    const intersect = (arr1: string[] = [], arr2: string[] = []) => arr1.filter(n => ~arr2.indexOf(n))

    variantIds &&
        variantIds.forEach((variantid: string) => {
            options.forEach((option: ProductOption) => {
                option.values.forEach((value: ProductOptionValue) => {
                    if (value.id === variantid) {
                        if (isInitialSetDone) {
                            getSkuArr = intersect(getSkuArr, value.skuCodes)
                        } else {
                            getSkuArr.push(...value.skuCodes)
                            isInitialSetDone = true
                        }
                    }
                })
            })
        })

    return getSkuArr
}
