import { ActionType, getType } from 'typesafe-actions'
import { setSiteWidePromoCodeSuccess } from '../actionCreators'
import * as actions from '../actionCreators'
import { SiteWidePromotionalCode } from '../models/cart.interface'

type Action = ActionType<typeof actions>

export interface SiteWidePromoCodeState {
    siteWidePromoCode: SiteWidePromotionalCode | null
}

export const initialState: SiteWidePromoCodeState = {
    siteWidePromoCode: null,
}

export const siteWidePromoCodeReducer = (
    state: SiteWidePromoCodeState = initialState,
    action: Action,
): SiteWidePromoCodeState => {
    if (action.type === getType(setSiteWidePromoCodeSuccess)) {
        return { ...state, siteWidePromoCode: action.payload }
    } else {
        return state
    }
}
