import { magicNumber } from '../utils'

/**
 * This function returns interactive elements in a node
 * @param { HTMLElement } node
 * @return { NodeListOf<Element> }
 */
export const getInteractiveElements = (node: HTMLElement): NodeListOf<HTMLElement> => {
    return node.querySelectorAll('button, [href], input, [data-accessibility="true"], [role="tab"]')
}

/**
 * This function returns last interactive element in a node
 * @param { HTMLElement } node
 * @return { Node }
 */
export const getLastInteractiveElement = (node: HTMLElement): Node => {
    const interactiveElements = getInteractiveElements(node)
    const lastIndex = interactiveElements.length - magicNumber.ONE
    return interactiveElements[lastIndex]
}

/**
 * This function is dedicated for node (radio froup, list etc)
 * This function returns next interactive element in a node
 * @param { number } currentIndex
 * @param { NodeListOf<HTMLElement> } list
 * @return { HTMLElement }
 */
export const getNextInteractiveElement = (currentIndex: number, list: NodeListOf<HTMLElement>): HTMLElement => {
    const lastIndex = list.length - magicNumber.ONE
    return currentIndex === lastIndex ? list[magicNumber.ZERO] : list[currentIndex + magicNumber.ONE]
}

/**
 * This function is dedicated for node (radio group, list etc)
 * This function returns previous interactive element in a node
 * @param { number } currentIndex
 * @param { NodeListOf<HTMLElement> } list
 * @return { HTMLElement }
 */
export const getPreviousInteractiveElement = (currentIndex: number, list: NodeListOf<HTMLElement>): HTMLElement => {
    const lastIndex = list.length - magicNumber.ONE
    return currentIndex === magicNumber.ZERO ? list[lastIndex] : list[currentIndex - magicNumber.ONE]
}
