import { getType, ActionType } from 'typesafe-actions'

import * as actions from '../actionCreators/popupInfo.actionCreators'
import { setPackageInfoPopupShowStatus } from '../actionCreators/popupInfo.actionCreators'

type Action = ActionType<typeof actions>

export interface popupInfoState {
    packageInfoPopup: {
        showPackageInfoPopup: boolean
    }
}

export const initialState: popupInfoState = {
    packageInfoPopup: {
        showPackageInfoPopup: false,
    },
}

// To maintain popup related information
export const popupInfoReducer = (state: popupInfoState = initialState, action: Action): popupInfoState => {
    if (action.type === getType(setPackageInfoPopupShowStatus)) {
        return { ...state, packageInfoPopup: { showPackageInfoPopup: action.payload } }
    } else {
        return state
    }
}
