import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { SeparatorProps, SeparatorSize, SeparatorStyle } from './Separator.types'
import { BrandingColors } from '../../globalConstants/global.constant'

const Separator: React.FC<SeparatorProps> = ({ ...props }): JSX.Element => {
    const sepratorClass = `${PREFIX}-separator`
    const { colors, size, style, reverseStyle = false } = props
    const separatorColor = (): JSX.Element[] => {
        return colors.map((color: string, i: number) => (
            <div key={i} className={`${sepratorClass}__line ${sepratorClass}__line-${color}`} />
        ))
    }

    return (
        <section className={`${sepratorClass}`}>
            <div className={`${PREFIX}-${size}-wrapper`}>
                <div
                    className={`${sepratorClass}__container ${sepratorClass}__${style}${
                        reverseStyle ? '--reverse' : ''
                    }`}>
                    {separatorColor()}
                </div>
            </div>
        </section>
    )
}

Separator.defaultProps = {
    size: SeparatorSize.FULL_WIDTH,
    style: SeparatorStyle.VERTICAL,
}
Separator.propTypes = {
    size: PropTypes.oneOf([
        SeparatorSize.FULL_WIDTH,
        SeparatorSize.THREE_QUARTER_WIDTH,
        SeparatorSize.TWO_THIRD_WIDTH,
        SeparatorSize.HALF_WIDTH,
        SeparatorSize.QUARTER_WIDTH,
        SeparatorSize.THIRD_WIDTH,
        SeparatorSize.EIGHTH_WIDTH,
        SeparatorSize.SIXTEENTH_WIDTH,
    ]),
    colors: PropTypes.arrayOf(PropTypes.oneOf([BrandingColors.TEAL, BrandingColors.RED, BrandingColors.BURGUNDY]))
        .isRequired,
    style: PropTypes.oneOf([SeparatorStyle.SKEW, SeparatorStyle.VERTICAL, SeparatorStyle.HORIZONTAL]),
}

export default Separator
