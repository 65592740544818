import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Icon from '../Icon'
import { PREFIX } from '../config'
import { MegaNavigationProps } from './MegaNavigation.type'
import MegaNavigationList from './MegaNavigationList'
import MegaNavigationTile from './MegaNavigationTile'
import { getAriaCurrentValue } from './MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'

/* MegaNavigation component
 * @param {MegaNavigationProps} props
 * @return {JSX.Element} returns MediaGalleryViewer component
 */

const MegaNavigation: React.FC<MegaNavigationProps> = ({ ...props }): JSX.Element => {
    const { categoryData, path, onCategoryClicked, onBackLabelClicked, currentView, megaNavClicked } = props

    const renderListOrShopList = (): JSX.Element => {
        if (categoryData && Array.isArray(categoryData)) {
            return (
                <MegaNavigationList
                    path={path}
                    categoryMapData={categoryData}
                    onCategoryClicked={onCategoryClicked}
                    currentView={currentView}
                    style="bold"
                />
            )
        } else {
            return (
                <div className={`${PREFIX}-mega-navigation--mobile`}>
                    <div className={`${PREFIX}-mega-navigation__back-section`}>
                        <Icon type={'ct-chevron-left'} size="md" path={path} />
                        <Button
                            type="tertiary"
                            onClick={onBackLabelClicked}
                            id={`mega-navigation-list-${currentView}-back`}>
                            {categoryData.backLinkLabel}
                        </Button>
                    </div>
                    <div className={`${PREFIX}-mega-navigation__shopall-section`}>
                        <a
                            aria-current={getAriaCurrentValue(categoryData) ? ariaType : false}
                            href={`${
                                typeof categoryData.url === 'string'
                                    ? encodeURI(categoryData.url)
                                    : encodeURI(categoryData.url?.url)
                            }`}>
                            {categoryData.megaNavTitle}
                        </a>
                    </div>
                    <MegaNavigationList
                        style={'normal'}
                        path={path}
                        categoryMapData={[...categoryData.categories]}
                        onCategoryClicked={onCategoryClicked}
                        currentView={currentView}
                        megaNavClicked={megaNavClicked}
                    />

                    {categoryData.saleCategories && currentView === 'L2' ? (
                        <MegaNavigationTile tileData={categoryData.saleCategories} />
                    ) : null}
                </div>
            )
        }
    }

    return <>{renderListOrShopList()}</>
}

MegaNavigation.propTypes = {
    categoryData: PropTypes.any,
    path: PropTypes.string,
    onCategoryClicked: PropTypes.func,
    onBackLabelClicked: PropTypes.func,
    currentView: PropTypes.string,
    backToLabel: PropTypes.string,
    shopAllLabel: PropTypes.string,
    megaNavClicked: PropTypes.func,
    autoServiceButton: PropTypes.func,
}

export default MegaNavigation
