import { ActionType, getType } from 'typesafe-actions'

import { setBuyBoxHeight, setBuyBoxThumbnailUrl } from '../actionCreators/buybox.actionCreators'
import * as actions from '../actionCreators/buybox.actionCreators'

type Action = ActionType<typeof actions>

export interface BuyboxState {
    height: number
    thumbnailUrl: string
}
const initialState: BuyboxState = {
    height: 0,
    thumbnailUrl: '',
}

export const buyboxReducer = (state: BuyboxState = initialState, action: Action): BuyboxState => {
    switch (action.type) {
        case getType(setBuyBoxHeight): {
            return { ...state, height: action.payload }
        }
        case getType(setBuyBoxThumbnailUrl): {
            return { ...state, thumbnailUrl: action.payload }
        }
        default:
            return state
    }
}
