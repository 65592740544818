import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators'
import {
    LinkLoyaltyValidateResponse,
    LinkLoyaltyCardErrorResponse,
    LinkLoyaltyProfileResponse,
} from '../models/linkLoyaltyCard.interface'
import {
    linkLoyaltyValidateSuccessAction,
    linkLoyaltyValidateErrorAction,
    clearLinkLoyaltyValidateResponseAction,
    clearLinkLoyaltyValidateErrorAction,
    linkLoyaltyProfileSuccessAction,
    linkLoyaltyProfileErrorAction,
    clearLinkLoyaltyProfileResponseAction,
    clearLinkLoyaltyProfileErrorAction,
    saveLoyaltyCardAction,
    saveLinkTypeAction,
} from '../actionCreators'

type Action = ActionType<typeof actions>

export interface LinkLoyaltyValidateState {
    linkLoyaltyValidateResponse: LinkLoyaltyValidateResponse | null
    linkLoyaltyValidateError: LinkLoyaltyCardErrorResponse | null
    linkLoyaltyProfileResponse: LinkLoyaltyProfileResponse | null
    linkLoyaltyProfileError: LinkLoyaltyCardErrorResponse | null
    loyaltyLinkCardNumber: string
    linkType: string
}

export const initialState: LinkLoyaltyValidateState = {
    linkLoyaltyValidateResponse: null,
    linkLoyaltyValidateError: null,
    linkLoyaltyProfileResponse: null,
    linkLoyaltyProfileError: null,
    loyaltyLinkCardNumber: '',
    linkType: '',
}

// eslint-disable-next-line complexity
export const linkLoyaltyValidateReducer = (
    state: LinkLoyaltyValidateState = initialState,
    action: Action,
): LinkLoyaltyValidateState => {
    switch (action.type) {
        case getType(linkLoyaltyValidateSuccessAction): {
            return { ...state, linkLoyaltyValidateResponse: action.payload }
        }
        case getType(linkLoyaltyValidateErrorAction): {
            return { ...state, linkLoyaltyValidateError: action.payload }
        }
        case getType(linkLoyaltyProfileSuccessAction): {
            return { ...state, linkLoyaltyProfileResponse: action.payload }
        }
        case getType(linkLoyaltyProfileErrorAction): {
            return { ...state, linkLoyaltyProfileError: action.payload }
        }
        case getType(clearLinkLoyaltyProfileResponseAction): {
            return { ...state, linkLoyaltyProfileResponse: null }
        }
        case getType(clearLinkLoyaltyProfileErrorAction): {
            return { ...state, linkLoyaltyProfileError: null }
        }
        case getType(clearLinkLoyaltyValidateResponseAction): {
            return { ...state, linkLoyaltyValidateResponse: null }
        }
        case getType(clearLinkLoyaltyValidateErrorAction): {
            return { ...state, linkLoyaltyValidateError: null }
        }
        case getType(saveLoyaltyCardAction): {
            return { ...state, loyaltyLinkCardNumber: action.payload }
        }
        case getType(saveLinkTypeAction): {
            return { ...state, linkType: action.payload }
        }
        default:
            return state
    }
}
