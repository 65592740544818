import { createAction } from 'typesafe-actions'

import {
    UPDATE_REDEEM_CTMONEY,
    UPDATE_REDEEM_CT_MONEY_ERROR,
    RESET_REDEEM_CT_MONEY,
} from '../types/ctMoneyRedeemed/ctMoneyRedeemUpsell.actionTypes.constant'
import { CtMoneyRedeemResponse } from '../models/ctMoneyRedeem.interface'
import { ErrorResponse } from '../models/upsellingRewards.interface'

export const redeemCTMoneyAction = createAction(
    UPDATE_REDEEM_CTMONEY,
    (payload: CtMoneyRedeemResponse) => payload,
)<CtMoneyRedeemResponse>()

export const redeemCTMoneyErrorAction = createAction(
    UPDATE_REDEEM_CT_MONEY_ERROR,
    (payload: ErrorResponse) => payload,
)<ErrorResponse>()

export const resetRedeemCtMoneyAction = createAction(RESET_REDEEM_CT_MONEY)()
