import { magicNumber } from './magicNumber'
import { dateOptions } from '../globalConstants/global.constant'

/**
 * Is used to get date from "MMDDYYYY" string
 * @param {String} dateString - date in format "MMDDYYYY"
 * @param {Number} daysToAdd - add days to date if needed
 * @return {Date} - Date recognizable string format (ISO string in this case)
 */
export const convertEpsilonDate = (dateString = '', daysToAdd?: number): Date => {
    const month = dateString.slice(0, magicNumber.TWO)
    const day = dateString.slice(magicNumber.TWO, magicNumber.FOUR)
    const year = dateString.slice(magicNumber.FOUR)
    // -1 for month is needed because backend returns month in format of a number from 1 to 12 instead of 0 to 11
    return new Date(Date.UTC(Number(year), Number(month) - magicNumber.ONE, Number(day) + (daysToAdd || 0)))
}

/**
 * @method maxDaysInMonth : returns array of 31 days in dropdownList format
 */

export const maxDaysInMonth = (): {
    label: string
    id: string
}[] => {
    return Array.from(new Array(magicNumber.THIRTYONE), (val, index) => index + magicNumber.ONE).map(day => {
        return { label: `${day}`, id: `${day}` }
    })
}

/**
 * @method isDateValid : Validates if date exists
 * @param {string} selectedYear
 * @param {string} selectedMonth
 * @param {string} selectedDay
 * @returns {boolean}
 */

export const isDateValid = (selectedYear: string, selectedMonth: string, selectedDay: string): boolean => {
    const year = Number(selectedYear)
    const month = Number(selectedMonth) - magicNumber.ONE
    const day = Number(selectedDay)
    const date = new Date(year, month, day)
    if (date.getMonth() != month || date.getDate() != day || date.getFullYear() != year) {
        return false
    }
    return true
}

export const getDateOptions = () => dateOptions

/**
 * Function to check if date is between 2 specific dates
 * @param {Date} fromDate - from date
 * @param {Date} toDate - to date
 * @param {Date} currentDate - current date
 * @return {boolean}
 */
export const validateDateBetweenTwoDates = (fromDate: Date, toDate: Date, currentDate: Date): boolean => {
    return new Date(currentDate) >= new Date(fromDate) && new Date(currentDate) <= new Date(toDate)
}
