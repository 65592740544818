export const SET_GENERATED_SHARED_CART_LINK = 'SET_GENERATED_SHARED_CART_LINK'
export const SET_GENERATED_SHARED_CART_ERROR = 'SET_GENERATED_SHARED_CART_ERROR'
export const SET_CUSTOMER_SHARED_CART_MERGING_ERROR = 'SET_CUSTOMER_SHARED_CART_MERGING_ERROR'
export const SET_CUSTOMER_SHARED_CART = 'SET_CUSTOMER_SHARED_CART'
export const SET_CUSTOMER_SHARED_CART_ERROR = 'SET_CUSTOMER_SHARED_CART_ERROR'
export const SET_STORE_INITIATED_CART_TEMPLATE = 'SET_STORE_INITIATED_CART_TEMPLATE'
export const SET_STORE_INITIATED_CART_TEMPLATE_ERROR = 'SET_STORE_INITIATED_CART_TEMPLATE_ERROR'
export const SET_STORE_INITIATED_CART = 'SET_STORE_INITIATED_CART'
export const SET_STORE_INITIATED_CART_ERROR = 'SET_STORE_INITIATED_CART_ERROR'
export const RESET_STORE_INITIATED_CART_DATA = 'RESET_STORE_INITIATED_CART_DATA'
export const SET_ONE_TIME_CART_DELIVERY_MODE_CHANGED = 'SET_ONE_TIME_CART_DELIVERY_MODE_CHANGED'
export const SET_CHANGE_DELIVERY_MODE_ERROR = 'SET_CHANGE_DELIVERY_MODE_ERROR'
export const SET_SEND_LINK_FORM_DATA = 'SET_SEND_LINK_FORM_DATA'
export const SET_SHARED_CART_LINK_SENT = 'SET_SHARED_CART_LINK_SENT'
export const SET_SHARED_CART_LINK_SENDING_ERROR = 'SET_SHARED_CART_LINK_SENDING_ERROR'
export const SET_ONE_TIME_CART_STORE = 'SET_ONE_TIME_CART_STORE'
export const SET_ONE_TIME_CART_STORE_FAILURE = 'SET_ONE_TIME_CART_STORE_FAILURE'
export const UPDATE_STORE_INITIATED_CART = 'UPDATE_STORE_INITIATED_CART'
export const UPDATE_STORE_INITIATED_CART_POSTAL_CODE = 'UPDATE_STORE_INITIATED_CART_POSTAL_CODE'
export const UPDATE_STORE_INITIATED_CART_POSTAL_CODE_FAIL = 'UPDATE_STORE_INITIATED_CART_POSTAL_CODE_FAIL'
export const CLEAR_POSTAL_CODE_ERROR_STATE_FOR_ONE_TIME_CART = 'CLEAR_POSTAL_CODE_ERROR_STATE_FOR_ONE_TIME_CART'
export const SET_SAVE_THE_SALE_CART_TEMPLATE = 'SET_SAVE_THE_SALE_CART_TEMPLATE'
