import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { ToastProps } from './Toast.type'
import { PREFIX } from '../config'
import Icon from '../Icon'
import {
    checkoutSimpleHeaderAEMClassName,
    headers,
    headersOnScroll,
    primaryNavigationAEMClassName,
    secondaryNavigation,
    stickyHeadersOnScroll,
    timeOutValue,
} from './toast.constant'
import {
    calculateNavigationHeight,
    calculatePrimaryNavigationTopValue,
    calculateToastHeight,
    defaultToastTopPosition,
} from './Toast.utils'
import { ToastMount } from './ToastMount'

/**
 * Toast component
 * @param {ToastProps} props
 * @return {JSX.Element} returns Toast Component
 */
const Toast: React.FC<ToastProps> = props => {
    const {
        options,
        success,
        toastUndoFunction,
        toastCloseFunction,
        hideUndoLabel = false,
        resetPageVariables,
        hideCTA = false,
        toastMountClassName,
        modifierClass,
        failed = false,
        enableTimer = true,
        isCloseFunctionWorksOnlyByClick,
        toastTimeOutValue,
        information = false,
        toastUnMountFunction,
    } = props
    const [visibility, setVisibility] = useState(true)
    const [clearTime, setClearTime] = useState(false)

    // State variables for calculating height of the toast when it is attached to the body.
    const [stickyHeadersHeight] = useState<number>(calculateNavigationHeight(stickyHeadersOnScroll))
    const [stickyPrimaryHeaderHeight] = useState<number>(
        calculateNavigationHeight([primaryNavigationAEMClassName, checkoutSimpleHeaderAEMClassName]),
    )
    const [headersHeight] = useState<number>(calculateNavigationHeight(headersOnScroll))
    const [toastHeight, setToastHeight] = useState<number>(calculateToastHeight(headers))
    const [primaryNavigationHeight] = useState<number>(calculatePrimaryNavigationTopValue())

    const {
        toastCloseLabel,
        toastErrorIcon,
        toastErrorMessage,
        toastSuccessIcon,
        toastSuccessMessage,
        toastUndoLabel,
        toastWishListLabel,
        toastWishListLabelURL,
        toastSaveForLaterLabel,
        toastSaveForLaterURL,
    } = options

    const toastSuccessOrFailure = success ? 'success' : 'error'
    const toastMessage = success ? toastSuccessMessage : toastErrorMessage
    const toastIcon = success ? toastSuccessIcon : toastErrorIcon
    const toastUndoCloseLabel = success ? toastUndoLabel : toastCloseLabel
    const disableUndo = success ? hideUndoLabel : false
    const toastTimers = toastTimeOutValue ? toastTimeOutValue : timeOutValue

    const callToastCloseFunc = useCallback((): void => {
        typeof toastCloseFunction === 'function' && toastCloseFunction()
    }, [toastCloseFunction])

    // implemented to show toast in cart pages as visibility wasnt changing to true otherwise
    useEffect(() => {
        let timeOut: ReturnType<typeof setTimeout>
        /**
         * Function is triggered on when we need timer for the toast.
         */
        const visibilityFunction = (): void => {
            timeOut = setTimeout(() => {
                if (isCloseFunctionWorksOnlyByClick) {
                    setVisibility(false)
                } else {
                    callToastCloseFunc()
                    setVisibility(false)
                }
            }, toastTimers)
        }
        setVisibility(true)
        // all toasts should auto close irrespective of presence of any cta
        enableTimer && visibilityFunction()
        return () => clearTimeout(timeOut)
    }, [callToastCloseFunc, enableTimer, isCloseFunctionWorksOnlyByClick, props, toastTimers])

    /**
     * Calculate overall Toast height based on the headers and banners present in the page and set it to the toast.
     * This function triggers only when the toast is loaded.
     *
     * @param {HTMLElement} toastElement - Reference to the toast element.
     * @param {number} topHeadersValue - combined height of all top banners and headers except content body.
     * @param {number} toastTopValue - Default Toast value which is defined in the styling.
     */
    const toastHeightHandler = (toastElement: HTMLElement, topHeadersValue: number, toastTopValue: number): void => {
        toastElement.style.top = `${topHeadersValue + toastTopValue}px` // Final top value
    }

    /**
     * Check for secondary header and toggle between the all sticky headers and normal height value.
     *
     * @param {number} scrollHeadersHeight - collective height of all sticky headers.
     * @param {number} defaultHeight - height which needs to be used when one of the header is not present.
     * @return {number}
     */
    const checkSecondaryNavigation = (scrollHeadersHeight: number, defaultHeight: number): number => {
        return Boolean(document.querySelector(`.${secondaryNavigation}__sticky`)) ? scrollHeadersHeight : defaultHeight
    }

    /**
     * Calculate the toast height when the user scrolls on the page.
     * This is applicable only when the toast is with respect to the body.
     *
     * @param {HTMLElement} toastElement - Reference to the toast element.
     * @param {number} toastTopValue - Default Toast value which is defined in the styling.
     *
     * 1. First if statement is used to calculate the toast height when it loads for the first time.
     * 2. Second if statement is used to calculate the toast height when the user starts scrolling i.e the height between the top and the primary navigation.
     * 3. Else statement runs when the primary navigation becomes sticky.
     */
    const onScrollToastHeightHandler = useCallback(
        (toastElement: HTMLElement, toastTopValue: number): void => {
            const windowScrollValue = window.scrollY
            if (windowScrollValue === 0) {
                toastHeightHandler(toastElement, toastHeight, toastTopValue)
            } else if (windowScrollValue > 0 && windowScrollValue <= primaryNavigationHeight) {
                toastElement.style.top = `${headersHeight + calculatePrimaryNavigationTopValue() + toastTopValue}px`
            } else {
                // For modifying the toast height when the user scrolls up which introduces the secondary navigation
                const dynamicHeight = checkSecondaryNavigation(stickyHeadersHeight, stickyPrimaryHeaderHeight)
                toastElement.style.top = `${dynamicHeight + toastTopValue}px`
            }
        },
        [headersHeight, primaryNavigationHeight, stickyHeadersHeight, stickyPrimaryHeaderHeight, toastHeight],
    )

    /**
     * On Load calculate the toast height.
     * Height of toast no longer depends on sticky navigation (https://canadian-tire.atlassian.net/browse/BRWS2-96)
     * When the user is scrolling, the position should be calculated according to scroll behavior.
     *
     * @param {HTMLElement} toastElement - toast element reference.
     * @param {number} toastDefaultValue - onLoad toast default value mentioned in the styling sheet.
     */
    const onLoadToastHeight = useCallback(
        (toastElement: HTMLElement, toastDefaultValue: number): void => {
            // Below if becomes only valid only when the toast is with respect to the body.
            if (toastElement && !toastMountClassName) {
                onScrollToastHeightHandler(toastElement, toastDefaultValue)
                window.addEventListener('scroll', () => onScrollToastHeightHandler(toastElement, toastDefaultValue))
            }
        },
        [onScrollToastHeightHandler, toastMountClassName],
    )

    useEffect(() => {
        const toastDefaultTopValue = defaultToastTopPosition() // Fetching the default toast height only once.
        const toast = document.getElementsByClassName(`${PREFIX}-toast`)[0] as HTMLElement
        const calculatedToastHeight = calculateToastHeight(headers)
        if (toastHeight !== calculatedToastHeight) {
            setToastHeight(calculatedToastHeight)
        }
        onLoadToastHeight(toast, toastDefaultTopValue)
        return () =>
            !toastMountClassName &&
            window.removeEventListener('scroll', () => onScrollToastHeightHandler(toast, toastDefaultTopValue))
    }, [props, visibility, toastHeight, onLoadToastHeight, toastMountClassName, onScrollToastHeightHandler])

    useEffect(() => {
        if (!visibility && resetPageVariables) {
            resetPageVariables()
        }
    }, [resetPageVariables, visibility])

    useEffect(() => {
        return () => toastUnMountFunction && toastUnMountFunction()
    }, [toastUnMountFunction])

    const toastUndoFunc = () => {
        setVisibility(!visibility)
        setClearTime(!clearTime)
        toastUndoFunction && toastUndoFunction()
    }

    const toastCloseFunc = () => {
        callToastCloseFunc()
        setVisibility(false)
    }

    const toastUndoCloseComponent = (): JSX.Element => {
        return (
            !disableUndo && (
                <span className={`${PREFIX}-toast__action--right`}>
                    <button
                        data-testid="undo"
                        data-dismiss="toast"
                        className={`${PREFIX}-button ${PREFIX}-button--tertiary `}
                        onClick={success ? () => toastUndoFunc() : () => toastCloseFunc()}>
                        {toastUndoCloseLabel || ''}
                    </button>
                </span>
            )
        )
    }

    const toastRightContainer = (): JSX.Element => {
        const toastLabel = toastSaveForLaterLabel || toastWishListLabel

        return (
            !hideCTA &&
            (toastLabel || toastUndoCloseLabel) && (
                <div className={`${PREFIX}-toast__rightcontainer`}>
                    {toastLabel && (
                        <span className={`${PREFIX}-toast__action--left`}>
                            <a href={toastWishListLabelURL || toastSaveForLaterURL} data-link-value={toastLabel}>
                                {toastLabel}
                            </a>
                        </span>
                    )}
                    {toastUndoCloseComponent()}
                </div>
            )
        )
    }

    const errorContainer = `${PREFIX}-toast__${toastSuccessOrFailure}-container`

    const toastClassNameType = (): string => {
        if (failed) return `${errorContainer}--error`
        else if (information) return `${errorContainer}--info`
        else return `${errorContainer}--warning`
    }

    /**
     * Checks if modifier class is present then will append modifier class
     * else will return empty string
     * @return {string}
     */
    const getModifierClass = (): string => {
        return !!modifierClass ? modifierClass : ''
    }

    return (
        visibility && (
            <ToastMount elementClassName={toastMountClassName}>
                <div
                    aria-live="assertive"
                    role="alert"
                    aria-atomic="true"
                    data-testid="toast"
                    className={`${PREFIX}-toast ${getModifierClass()}`}>
                    <div className={`${errorContainer} ${toastClassNameType()}`}>
                        <div className={`${PREFIX}-toast__leftcontainer`}>
                            <span className={`${PREFIX}-toast__${toastSuccessOrFailure}-icon`}>
                                <Icon type={toastIcon} size="lg" />
                            </span>
                            <span className={`${PREFIX}-toast__message`}>{toastMessage}</span>
                        </div>
                        {toastRightContainer()}
                    </div>
                </div>
            </ToastMount>
        )
    )
}

Toast.defaultProps = {
    success: true,
    isCloseFunctionWorksOnlyByClick: false,
}

Toast.propTypes = {
    success: PropTypes.bool,
    options: PropTypes.shape({
        toastSuccessMessage: PropTypes.string,
        toastErrorMessage: PropTypes.string,
        toastSuccessIcon: PropTypes.string,
        toastErrorIcon: PropTypes.string,
        toastWishListLabel: PropTypes.string,
        toastWishListLabelURL: PropTypes.string,
        toastSaveForLaterLabel: PropTypes.string,
        toastSaveForLaterURL: PropTypes.string,
        toastUndoLabel: PropTypes.string,
        toastCloseLabel: PropTypes.string,
    }),
    toastUndoFunction: PropTypes.func,
    toastCloseFunction: PropTypes.func,
    hideUndoLabel: PropTypes.bool,
    resetPageVariables: PropTypes.func,
    toastMountClassName: PropTypes.string,
    hideCTA: PropTypes.bool,
    enableTimer: PropTypes.bool,
    failed: PropTypes.bool,
    isCloseFunctionWorksOnlyByClick: PropTypes.bool,
    toastTimeOutValue: PropTypes.number,
    toastUnMountFunction: PropTypes.func,
}

export default Toast
