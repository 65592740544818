import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { FullPageSpinnerProps } from './FullPageSpinner.types'
import Spinner from '../Spinner'

const FullPageSpinner: React.FC<FullPageSpinnerProps> = ({ ...props }): JSX.Element | null => {
    const spinnerColor = props.globalSpinnerColor // Color of the spinner ('white', 'black')
    const { showSpinner, spinnerCounter } = props
    return (
        (showSpinner || spinnerCounter > 0) && (
            <div role="alert" className={`${PREFIX}-spinner__full`}>
                <div className={`${PREFIX}-spinner__backdrop`} />
                <div className={`${PREFIX}-spinner__content`}>
                    <span className="sr-only">{props.a11yLoadingSpinnerLabel}</span>
                    <Spinner spinnerColor={spinnerColor} />
                </div>
            </div>
        )
    )
}

FullPageSpinner.propTypes = {
    showSpinner: PropTypes.bool,
    globalSpinnerColor: PropTypes.string,
}

export default FullPageSpinner
