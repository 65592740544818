import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { VehicleHeaderProps } from './VehicleHeader.type'

const VehicleHeader: React.FC<VehicleHeaderProps> = props => {
    const newVehicleBannerClass = `${PREFIX}-vehicle-banner`
    return (
        <div className={`${newVehicleBannerClass}__details__header`}>
            <div className={`${newVehicleBannerClass}__details__header__sub-title`}>{props.shoppingForTitle}</div>
            <h2 className={`${newVehicleBannerClass}__details__header__title`}>{props.vehicleDetails}</h2>
        </div>
    )
}

VehicleHeader.propTypes = {
    shoppingForTitle: PropTypes.string.isRequired,
    vehicleDetails: PropTypes.string.isRequired,
}

export default VehicleHeader
