import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../redux/reducers'
import { FullPageSpinner } from '@nl/lib'
import checkNestedProps from '../../utils/checkNestedProps'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'

/**
 * function to render Full page spinner
 * @return {JSX.Element}
 */
export const FullPageSpinnerComponent = (): JSX.Element => {
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const globalSpinnerColor = checkNestedProps(commonContentAvailable, 'general', 'globalSpinnerColor') as string
    const a11yLoadingSpinnerLabel = checkNestedProps(
        commonContentAvailable,
        'accessibility',
        'a11yLoadingSpinnerLabel',
    ) as string
    const { showSpinner, spinnerCounter } = useSelector((state: RootState) => state.spinner)
    return (
        <FullPageSpinner
            showSpinner={showSpinner}
            spinnerCounter={spinnerCounter}
            a11yLoadingSpinnerLabel={a11yLoadingSpinnerLabel}
            globalSpinnerColor={globalSpinnerColor}></FullPageSpinner>
    )
}

export default FullPageSpinnerComponent
