import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators'
import {
    saveForLaterListSuccess,
    saveForLaterListFailure,
    removeSaveForLaterFailure,
    getNewWishListState,
} from '../actionCreators/saveForLater.actionCreators'
import { iSaveForLater } from '../models/SaveForLater.interface'
import { CartResponseErrorDTO } from '../models/cart.interface'

export interface SaveForLaterState {
    saveForLaterList: iSaveForLater
    saveForLaterListFailure: CartResponseErrorDTO
    removeSaveForLaterSuccess: iSaveForLater
    removeSaveForLaterFailure: CartResponseErrorDTO
}

type Action = ActionType<typeof actions>

export const initialState: SaveForLaterState = {
    saveForLaterList: {} as iSaveForLater,
    saveForLaterListFailure: {} as CartResponseErrorDTO,
    removeSaveForLaterSuccess: {} as iSaveForLater,
    removeSaveForLaterFailure: {} as CartResponseErrorDTO,
}

export const saveForLaterListReducer = (state: SaveForLaterState = initialState, action: Action): SaveForLaterState => {
    switch (action.type) {
        case getType(saveForLaterListSuccess):
            return { ...state, saveForLaterList: action.payload }
        case getType(saveForLaterListFailure):
            return { ...state, saveForLaterListFailure: action.payload }
        case getType(removeSaveForLaterFailure):
            return { ...state, removeSaveForLaterFailure: action.payload }
        case getType(getNewWishListState):
            return { ...state, saveForLaterList: { ...state.saveForLaterList, entries: action.payload } }
        default:
            return state
    }
}
