import { ActionType, getType } from 'typesafe-actions'

import { setAuthouredBreadcrumbDataAction } from '../actionCreators/breadcrumbAuthouredData.actionCreator'
import * as actions from '../actionCreators/breadcrumbAuthouredData.actionCreator'
import { BreadcrumbLinkData } from '../models/productData.interface'

type Action = ActionType<typeof actions>

export interface breadcrumbDataAuthouredState {
    breadcrumbAuthouredData: BreadcrumbLinkData[]
}
const initialState: breadcrumbDataAuthouredState = {
    breadcrumbAuthouredData: [],
}

export const breadcrumbAuthouredReducer = (
    state: breadcrumbDataAuthouredState = initialState,
    action: Action,
): breadcrumbDataAuthouredState => {
    if (action.type === getType(setAuthouredBreadcrumbDataAction)) {
        return { ...state, breadcrumbAuthouredData: action.payload }
    } else {
        return state
    }
}
