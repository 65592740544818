import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators'
import {
    fetchInterceptorErrorAction,
    actionPerformedOnScreenAction,
    fetchInterceptorPartialResponse,
} from '../actionCreators/fetchInterceptorError.actionCreators'
import { PartialResponse } from '../models/errorInterceptor.interface'

type Action = ActionType<typeof actions>

export interface timeoutErrorState {
    errorInterceptorData: Record<string, unknown>
    actionPerformedOnScreen: string
    partialResponseData: null | PartialResponse
}

export const initialState: timeoutErrorState = {
    errorInterceptorData: {},
    actionPerformedOnScreen: '',
    partialResponseData: null,
}

export const fetchInterceptorErrorReducer = (state = initialState, action: Action): timeoutErrorState => {
    if (action.type === getType(fetchInterceptorErrorAction)) {
        return { ...state, errorInterceptorData: action.payload }
    }
    if (action.type === getType(actionPerformedOnScreenAction)) {
        return { ...state, actionPerformedOnScreen: action.payload }
    }
    if (action.type === getType(fetchInterceptorPartialResponse)) {
        return { ...state, partialResponseData: action.payload }
    } else {
        return state
    }
}
