import { ActionType, getType } from 'typesafe-actions'
import { CertonaResponseType } from '../../certona/certona.type'

import * as actions from '../actionCreators'

type Action = ActionType<typeof actions>

const mergeShemesAndItems = (state: CertonaResponseType, payload: CertonaResponseType) => {
    const filteredOld = state.resonance?.schemes?.filter(schemeItem => {
        return !payload.resonance.schemes.some(newSchemeItem => newSchemeItem.scheme === schemeItem.scheme)
    })

    const newSchemas = payload.resonance.schemes.map(payloadScheme => {
        const stateScheme = state.resonance?.schemes?.find(oldScheme => payloadScheme.scheme === oldScheme.scheme)

        if (stateScheme) {
            const oldItems = stateScheme.items.filter(stateItem => {
                return !payloadScheme.items.some(payloadItem => payloadItem.prod_id === stateItem.prod_id)
            })

            payloadScheme.items = [...oldItems, ...payloadScheme.items]

            return payloadScheme
        }

        return payloadScheme
    })

    return [...(filteredOld || []), ...newSchemas]
}

export const getCertonaData = (state = {} as CertonaResponseType, action: Action): CertonaResponseType => {
    if (action.type === getType(actions.storeCertonaProductRecommendationData)) {
        return {
            ...state,
            resonance: {
                schemes: mergeShemesAndItems(state, action.payload),
            },
        }
    } else {
        return state
    }
}
