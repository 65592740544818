import React from 'react'
import { HiddenLabelProps } from './HiddenLabel.types'

/**
 * HiddenLabel component
 * It would be use for avoiding strikes from accessability auto-scan systems.
 * - The param 'a11yReadable' controls: it is read or it isn't by readers applications.
 * @param {HiddenLabelProps} props HiddenLabel props
 * @return {JSX.Element} returns label component
 */
export const HiddenLabel: React.FC<HiddenLabelProps> = ({
    children,
    className = '',
    textClassName = '',
    a11yReadable = false,
    labelText = 'input',
}): JSX.Element => {
    const objAriaHidden = a11yReadable ? { 'aria-hidden': false } : {}
    return (
        <label className={className}>
            <span className={textClassName} hidden={true} {...objAriaHidden}>
                {labelText}
            </span>
            {children}
        </label>
    )
}

export default HiddenLabel
