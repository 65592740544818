import { BREAKPOINTS, PREFIX } from '../../components/config'
import { calculateHeaderHeight } from '../../components/StickyTOC/CalculateHeaderHeight'
import { magicNumber } from '../magicNumber'
import { ProductCardScrollEvent } from './productCardScrollToTop.type'
export const scrollToTarget = (event: ProductCardScrollEvent): void => {
    event.stopPropagation()
    event.preventDefault()

    const element = event.targetElement || event.target
    const { top: bodyTop } = document.body.getBoundingClientRect()
    const { top: elemTop } = element.getBoundingClientRect()
    const offset = elemTop - bodyTop
    const filterHeight = document.getElementsByClassName(`${PREFIX}-filters`)[0]?.clientHeight
    const headerHeight = calculateHeaderHeight()
    const isSticky = !document.getElementsByClassName(`${PREFIX}-filters--filters-sticky`)[0]
    const focusAbleElems = [`${PREFIX}-product-card__no-button`, `${PREFIX}-product-card__content`]
    const isProductCard = Array.from(element.classList).some((r: string) => focusAbleElems.includes(r))
    const isMobile = window.innerWidth <= BREAKPOINTS.tabletMaxWidth

    if (isSticky && isProductCard && !isMobile) {
        window.scroll({
            top: offset - headerHeight - filterHeight - magicNumber.SIXTEEN,
            behavior: 'smooth',
        })
    }
}

export const productCardScrollToTop = scrollToTarget
