export const LINK_LOYALTY_CARD_SUCCESS = 'LINK_LOYALTY_CARD_SUCCESS'
export const LINK_LOYALTY_CARD_FAILURE = 'LINK_LOYALTY_CARD_FAILURE'
export const LINK_LOYALTY_CHECK_SUCCESS = 'LINK_LOYALTY_CHECK_SUCCESS'
export const LINK_LOYALTY_CHECK_FAILURE = 'LINK_LOYALTY_CHECK_FAILURE'
export const LINK_LOYALTY_VALIDATE_SUCCESS = 'LINK_LOYALTY_VALIDATE_SUCCESS'
export const LINK_LOYALTY_VALIDATE_FAILURE = 'LINK_LOYALTY_VALIDATE_FAILURE'
export const LINK_LOYALTY_PROFILE_SUCCESS = 'LINK_LOYALTY_PROFILE_SUCCESS'
export const LINK_LOYALTY_PROFILE_FAILURE = 'LINK_LOYALTY_PROFILE_FAILURE'
export const CLEAR_LINK_LOYALTY_CARD_RESPONSE = 'CLEAR_LINK_LOYALTY_CARD_RESPONSE'
export const CLEAR_LINK_LOYALTY_CARD_FAILURE = 'CLEAR_LINK_LOYALTY_CARD_FAILURE'
export const CLEAR_LINK_LOYALTY_PROFILE_RESPONSE = 'CLEAR_LINK_LOYALTY_PROFILE_RESPONSE'
export const CLEAR_LINK_LOYALTY_PROFILE_FAILURE = 'CLEAR_LINK_LOYALTY_PROFILE_FAILURE'
export const CLEAR_LINK_LOYALTY_CHECK_RESPONSE = 'CLEAR_LINK_LOYALTY_CHECK_RESPONSE'
export const CLEAR_LINK_LOYALTY_CHECK_FAILURE = 'CLEAR_LINK_LOYALTY_CHECK_FAILURE'
export const CLEAR_LINK_LOYALTY_VALIDATE_RESPONSE = 'CLEAR_LINK_LOYALTY_VALIDATE_RESPONSE'
export const CLEAR_LINK_LOYALTY_VALIDATE_FAILURE = 'CLEAR_LINK_LOYALTY_VALIDATE_FAILURE'
export const SAVE_LOYALTY_CARD_NUMBER = 'SAVE_LOYALTY_CARD_NUMBER'
export const SAVE_LINK_TYPE = 'SAVE_LINK_TYPE'
