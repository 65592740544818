export enum ProductCodeTypes {
    OMC = 'OMC',
    OMJ = 'OMJ',
    OMG = 'OMG',
    OML = 'OML',
    OMP = 'OMP',
    OMR = 'OMR',
    OVC = 'OVC',
    OMX = 'OMX',
    OMY = 'OMY',
    OMZ = 'OMZ',
}

export enum AccountRelationshipTypes {
    PRIMARY = 'PRIMARY',
    SUPPLEMENTAL = 'SUPPLEMENTAL',
}

export interface LinkMasterCardSuccessResponse {
    loyaltyCardNumber: string
    productCode: ProductCodeTypes
    accountRelationship: AccountRelationshipTypes
}

export interface LinkMasterCardErrorResponse {
    errOrigin?: string
    statusCode: number
    error: string
    message: string
    errCode: string
    errors: string[]
    data?: {
        description: string
        statusCode: string
    }
}

export interface LinkMasterCardRequest {
    masterCard: {
        lastName: string
        last4PAN: string
        dob: string
        postalCode: string
    }
}
