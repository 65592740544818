import { getEnvironment } from '../../environments'
import { DevConfig } from '../../environments/dev.environment'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import { EstimateDeliveryDateParams, EstimateDeliveryDate } from '../../redux/models/estimateDeliveryDate.interface'

const environment: DevConfig = getEnvironment()
const httpClient = getHttpClient()
const postalCodeFormat = /\s+/g

/**
 * Estimate Delivery Date service. To fetch the Estimated Delivery Date
 */
class EstimateDeliveryDateService extends BaseService {
    /**
     * Load Estimate Delivery Date
     * @param  {EstimateDeliveryDateParams} params
     * @return {SuccessResponse}
     */
    fetchEstimateDeliveryDate(params: EstimateDeliveryDateParams): Promise<{ data: EstimateDeliveryDate }> {
        const url: URL = this.createEstimateDeliveryDateUrl()
        const strippedPostal = params.postalCode?.replace(postalCodeFormat, '')
        const payload = {
            sku: params.sku,
            postalCode: strippedPostal,
            selectedStoreId: params.selectedStoreId,
            quantity: params.quantity,
        }

        return httpClient.apiPost(url, payload, undefined, true)
    }

    /**
     * Create Estimate Delivery Date url based on mock or cds
     * @return {URL}
     */
    createEstimateDeliveryDateUrl(): URL {
        const url = new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS.estimatedDeliveryDate}`)
        if (EstimateDeliveryDateService.isMockUri(environment.API_ENDPOINTS.estimatedDeliveryDate)) {
            return EstimateDeliveryDateService.prepareMockUrl(url, environment.API_MOCK_URL)
        } else {
            return url
        }
    }
}

const estimateDeliveryDateService = new EstimateDeliveryDateService()

export { estimateDeliveryDateService }
export default estimateDeliveryDateService
