import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import {
    SuccessResponse,
    HardwareParams,
    HardwareList,
    HardwareSkuData,
} from '../../redux/models/extraHardware.interface'
import { VehicleInformation } from '../../redux/models/cart.interface'
import { ItemAvailability } from '../../redux/models/product.interface'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Extra Hardware service. To fetch the extra hardware products
 */
class ExtraHardwareService extends BaseService {
    locale = ExtraHardwareService.language
    /**
     * Load extra-hardware
     * @param  {ExtraHardwareParams} params
     * @return {SuccessResponse}
     */
    fetchExtraHardware(params: HardwareParams): Promise<{ data: SuccessResponse }> {
        const url = this.createExtraHardwareUrl(
            params?.vehicleInformation?.type,
            params?.vehicleInformation?.year,
            params?.vehicleInformation?.make,
            params?.vehicleInformation?.model,
            encodeURIComponent(params.vehicleInformation?.body),
            encodeURIComponent(params.vehicleInformation?.options),
            params.sku,
        )
        return httpClient.apiGet(url, {}, undefined, true)
    }

    /**
     * Create extra-hardware url based on mock or cds
     * @param  {string} type
     * @param  {string} year
     * @param  {string} make
     * @param  {string} model
     * @param  {string} body
     * @param  {string} option
     * @param  {string} sku
     * @return {URL}
     */
    createExtraHardwareUrl(
        type: string,
        year: string,
        make: string,
        model: string,
        body: string,
        option: string,
        sku: string,
    ): string {
        const {
            API_ENDPOINTS: { extraHardware, extraHardwareMock },
        } = environment
        return `${ExtraHardwareService.switchEndpoints(
            extraHardwareMock,
            extraHardware,
        )}?q=${sku}&x1=auto.type&q1=${type}&x2=auto.year&q2=${year}&x3=auto.make&q3=${make}&x4=auto.model&q4=${model}&x5=auto.body&q5=${body}&x6=auto.option&q6=${option}&x7=auto.application&q7=both&x8=baseItemsCount&q8=2&lang=${
            this.locale
        }`
    }

    /**
     * Transform response as ExtraHardware
     * @param  {SuccessResponse} data
     * @param  {string} sku
     * @param  {VehicleInformation} vehicleInformation
     * @return {HardwareList}
     */
    transformData(data: SuccessResponse, sku: string, vehicleInformation: VehicleInformation): HardwareList[] {
        const hardwareList: HardwareList[] = []

        if (data.results && data.results?.length) {
            data.results.forEach(result => {
                hardwareList.push({
                    serviceType: result.field['type'],
                    maxQuantity: result.quantity,
                    url: result.field['pdp-url'],
                    name: result.field['prod-name'],
                    shortDescription: result.field['features'],
                    sku: result.field['sku-id'],
                    brand: { label: result.field['brand'], url: '' },
                    images: [
                        {
                            altText: result.field['prod-name'],
                            isListingThumbnailImage: 'N',
                            mediaType: 'GALLERY_IMAGE',
                            url: result.field['thumb-img-url']
                                ? result.field['thumb-img-url']
                                : environment.defaultProductImage,
                        },
                    ],
                    automotiveSku: sku,
                    vehicleInformation: vehicleInformation,
                    price: null,
                    isAvailable: false,
                })
            })
        }

        return hardwareList
    }

    /**
     * Get SKUs from hardwareList
     * @param  {HardwareList} hardwareList
     * @return {HardwareList}
     */
    getProductSkus(hardwareList: HardwareList[]): HardwareList[] {
        return hardwareList.filter((v, i, a) => a.findIndex(t => t.sku === v.sku) === i)
    }

    /**
     * Validate if the product is available
     * @param  {boolean} active
     * @param  {boolean} sellable
     * @param  {ItemAvailability} availability
     * @return {boolean}
     */
    _isAvailable(active: boolean, sellable: boolean, availability: ItemAvailability): boolean {
        return !!(
            active &&
            sellable &&
            (availability?.quantity > 0 || (availability.Corporate && availability.Corporate?.Quantity > 0))
        )
    }

    /**
     * Set Price and Availability
     * @param  {HardwareSkuData} hardwareSkuData
     * @param  {HardwareList} hardwareList
     * @return {HardwareList}
     */
    setPriceAndAvailability(hardwareSkuData: HardwareSkuData, hardwareList: HardwareList[]): HardwareList[] {
        const { code, active, sellable, availability } = hardwareSkuData

        hardwareList.forEach(hardware => {
            if (hardware.sku === code) {
                hardware.price = hardwareSkuData?.currentPrice?.value || 0
                hardware.isAvailable = !!this._isAvailable(active, sellable, availability)
            }
        })

        return hardwareList.filter(hardware => hardware.isAvailable || hardware.sku !== code)
    }
}

const extraHardwareService = new ExtraHardwareService()

export { extraHardwareService }
export default extraHardwareService
