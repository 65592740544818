import { ActionType, getType } from 'typesafe-actions'

import { fetchTokenUpdateAction } from '../actionCreators/tokenUpdate.actionCreators'

type Action = ActionType<typeof fetchTokenUpdateAction>

export interface TokenState {
    tokenState: string
}

export const initialState: TokenState = {
    tokenState: '',
}

export const tokenUpdateReducer = (state: TokenState = initialState, action: Action): TokenState => {
    if (action.type === getType(fetchTokenUpdateAction)) {
        return { ...state, tokenState: action.payload }
    } else {
        return state
    }
}
