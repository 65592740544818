import { createAction } from 'typesafe-actions'

import { ErrorResponse } from '../models/loyaltyRegisterForm.interface'
import { DeletePaymentSuccess, PaymentUnitType } from '../models/profile.payment.interface'
import {
    PROFILE_PAYMENT_SUCCESS,
    PROFILE_PAYMENT_ERROR,
    CLEAR_PROFILE_PAYMENT,
    DELETE_PAYMENT_INFO_SUCCESS,
    HIDE_RECOMMENDATION_COMPONENT,
    DELETE_PAYMENT_INFO_ERROR,
    CLEAR_DELETE_PAYMENT_INFO_STATES,
} from '../types/profile/profile.payment.actionTypes.constant'

export const fetchPaymentSuccessAction = createAction(PROFILE_PAYMENT_SUCCESS, (payload: PaymentUnitType[]) => payload)<
    PaymentUnitType[]
>()

export const fetchPaymentErrorAction = createAction(PROFILE_PAYMENT_ERROR)<ErrorResponse>()
export const resetPaymentAction = createAction(CLEAR_PROFILE_PAYMENT)()
export const deletePaymentInfoAction = createAction(
    DELETE_PAYMENT_INFO_SUCCESS,
    (payload: DeletePaymentSuccess) => payload,
)<DeletePaymentSuccess>()

export const deletePaymentInfoErrorAction = createAction(DELETE_PAYMENT_INFO_ERROR)<ErrorResponse>()

export const hideRecommendation = createAction(HIDE_RECOMMENDATION_COMPONENT, (payload: boolean) => payload)<boolean>()

export const resetdeletePaymentInfoStates = createAction(CLEAR_DELETE_PAYMENT_INFO_STATES)()
