export const slectionTypeChecked = 'checked'

export const arrowActionIdentifier = {
    NEXT: 'next',
    PREVIOUS: 'previous',
}

import { magicNumber } from '../utils'
import {
    getInteractiveElements,
    getNextInteractiveElement,
    getPreviousInteractiveElement,
} from './getInteractiveElements.helper'

/**
 * This function resets tabindex
 * @param { NodeListOf<HTMLElement> } list
 */
export const resetTabindex = (list: NodeListOf<HTMLElement>) => {
    list.forEach(item => {
        item.setAttribute('tabindex', '-1')
    })
}

/**
 * This function resets aria-selected
 * @param { NodeListOf<HTMLElement> } list
 */
export const resetAriaSelected = (list: NodeListOf<HTMLElement>) => {
    list.forEach(item => {
        item.setAttribute('aria-selected', 'false')
    })
}

/**
 * This function resets aria-checked
 * @param { NodeListOf<HTMLElement> } list
 */
export const resetAriaChecked = (list: NodeListOf<HTMLElement>) => {
    list.forEach(item => {
        item.setAttribute('aria-checked', 'false')
    })
}

/**
 * Below function checks selection type is checked or not
 * @param { string } selectionType
 * @return { boolean }
 */
const isSelectionTypeChecked = (selectionType: string) => {
    return selectionType === slectionTypeChecked
}

/**
 * This function is dedicated for arrows accessibility
 * @param { React.KeyboardEvent<HTMLDivElement> } event
 * @param { string } action
 * @param { number } currentIndex
 * @param { HTMLUListElement } listNode
 */
export const handleArrowsAccessibility = (
    event: React.KeyboardEvent<HTMLDivElement>,
    action: string,
    currentIndex: number,
    listNode: HTMLUListElement,
) => {
    event.preventDefault()
    event.stopPropagation()
    const list = getInteractiveElements(listNode)
    resetTabindex(list)
    let interactiveElement: HTMLElement
    if (action === arrowActionIdentifier.NEXT) {
        interactiveElement = getNextInteractiveElement(currentIndex, list)
    } else if (action === arrowActionIdentifier.PREVIOUS) {
        interactiveElement = getPreviousInteractiveElement(currentIndex, list)
    }
    interactiveElement?.setAttribute('tabindex', '0')
    interactiveElement?.focus()
}

/**
 * This function handles click or enter event accessibility
 * @param { React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> } event
 * @param { number } currentIndex
 * @param { HTMLUListElement } listNode
 * @param { string } selectionType
 */
export const handleClickOrEnterAccessibility = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    currentIndex: number,
    listNode: HTMLUListElement,
    selectionType?: string,
) => {
    event.preventDefault()
    event.stopPropagation()
    const list = getInteractiveElements(listNode)
    resetTabindex(list)
    isSelectionTypeChecked(selectionType) ? resetAriaChecked(list) : resetAriaSelected(list)
    list[currentIndex].setAttribute('tabindex', '0')
    list[currentIndex].setAttribute(
        `${isSelectionTypeChecked(selectionType) ? 'aria-checked' : 'aria-selected'}`,
        'true',
    )
}

/**
 * Below function handles outside actions of a listNode
 * @param { HTMLUListElement } listNode
 * @param { string } selectionType
 */
export const outsideActionHandler = (listNode: HTMLUListElement, selectionType?: string) => {
    const list = getInteractiveElements(listNode)
    // reset tabindex to "-1"
    resetTabindex(list)
    const selectedItem = listNode?.querySelector(
        isSelectionTypeChecked(selectionType) ? '[aria-checked="true"]' : '[aria-selected="true"]',
    )
    // set tabindex to "0" for selected item, if selected item is not present set tabindex to "0" for first item
    selectedItem ? selectedItem.setAttribute('tabindex', '0') : list[magicNumber.ZERO]?.setAttribute('tabindex', '0')
}
