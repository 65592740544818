import { carouselSettings } from './Carousel.constant'
import { CarouselSettingInterface } from './Carousel.type'

const { variableWidthClassName } = carouselSettings

/**
 * function to get carouselSetting object based on device
 * @param {JSX.Element[]} carouselList
 * @param {number} deviceThreshold
 * @param {boolean | undefined} tabletArrowSettings
 * @return {CarouselSettingInterface}
 */
export const carouselSettingValues = (
    carouselList: JSX.Element[],
    deviceThreshold: number,
    tabletArrowSettings: boolean | undefined,
): CarouselSettingInterface => {
    return {
        arrows: tabletArrowSettings ? tabletArrowSettings : false,
        slidesToShow: carouselList.length < deviceThreshold ? carouselList.length : deviceThreshold,
        slidesToScroll: carouselList.length < deviceThreshold ? carouselList.length : deviceThreshold,
        variableWidth: carouselList.length < deviceThreshold,
        className: carouselList.length < deviceThreshold ? variableWidthClassName : null,
    }
}
