import React from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import { CustomTextAreaProps } from './CustomTextArea.type'

const CustomTextArea: React.FC<CustomTextAreaProps> = ({ ...props }): JSX.Element => {
    const { id, name, placeholderText } = props

    const componentClassName = `${PREFIX}-custom-text-area`

    return (
        <>
            <textarea
                className={`${componentClassName}`}
                data-testid={id}
                id={id}
                name={name}
                placeholder={placeholderText}
                onChange={props.onChange}
                value={props.description}></textarea>
        </>
    )
}

CustomTextArea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholderText: PropTypes.string,
    onChange: PropTypes.func,
    description: PropTypes.string,
}

export default CustomTextArea
