import { Dispatch } from 'redux'
import { sharedCartService } from '../../services/sharedCartService'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { HybrisErrorResponse, ShareCartTemplate } from '../models/sharedCart.interface'
import { CartItemsData, FilteredCartData } from '../models/cart.interface'
import { ErrorTypes, STORE_INITIATED_CART_KEY } from '../../config'
import { RootState } from '../reducers'
import fireAnalyticsForSharedCartMerge from '../../analytics/components/mergeSharedCartAnalytics'
import sessionStorageService from '../../utils/sessionStorageService'
import appCacheService from '../../utils/appCacheService'
import { fillCheckoutDataFromAPI } from './cart.action'

import {
    setGeneratedSharedCartLink,
    setGeneratedSharedCartError,
    setCustomerSharedCart,
    setCustomerSharedCartError,
    setCustomerSharedCartMergingError,
    updateCartDataSuccessAction,
    setStoreInitiatedCartTemplate,
    setStoreInitiatedCartTemplateError,
    setStoreInitiatedCart,
    setStoreInitiatedCartError,
    resetStoreInitiatedCartData,
    setOneTimeCartDeliveryModeChanged,
    setChangeDeliveryModeError,
    setSendLinkFormData,
    setSharedCartLinkSent,
    setSharedCartLinkSendingError,
    clearPostalErrorStateForOneTimeCart,
} from '../actionCreators'
import { SendLinkFormData } from '../models/sharedCart.interface'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'

export const flushStoreInitiatedCartData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetStoreInitiatedCartData())
    }

export const generateSharedCartLink =
    (cartIdentifier: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return sharedCartService
            .generateShareCartLink(cartIdentifier)
            .then((response: { data: { link: string } }) => {
                dispatch(setGeneratedSharedCartLink(response.data.link))
                dispatch(setGeneratedSharedCartError(''))
            })
            .catch((err: { response: { data: HybrisErrorResponse } }) => {
                const errorType = err?.response?.data?.data?.errors
                    ? err.response.data.data.errors[0].type
                    : ErrorTypes.genericError
                dispatch(setGeneratedSharedCartLink(''))
                dispatch(setGeneratedSharedCartError(errorType))
            })
    }

export const getSharedCartTemplate =
    (templateId: string, isStoreSharedCart?: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        return sharedCartService
            .getSharedCartTemplate(templateId)
            .then((response: { data: ShareCartTemplate }) => {
                replaceEmptyImagesWithDefault(response?.data?.orderEntries, 'images')
                if (isStoreSharedCart) {
                    dispatch(setStoreInitiatedCartTemplate(response.data))
                    dispatch(setStoreInitiatedCartTemplateError(''))
                    return
                }
                dispatch(setCustomerSharedCart(response.data))
                dispatch(setCustomerSharedCartError(''))
            })
            .catch((err: { response: { data: HybrisErrorResponse } }) => {
                const errorType = err?.response?.data?.data?.errors
                    ? err.response.data.data.errors[0].type
                    : ErrorTypes.genericError
                if (isStoreSharedCart) {
                    dispatch(setStoreInitiatedCartTemplate({} as ShareCartTemplate))
                    dispatch(setStoreInitiatedCartTemplateError(errorType))
                    return
                }
                dispatch(setCustomerSharedCartError(errorType))
                dispatch(setCustomerSharedCart({} as ShareCartTemplate))
            })
    }

export const mergeUserCartWithCustomerSharedCart =
    (cartIdentifier: string, sharedCartCode: string, sharedCartEntryCodes: string[], storeToChange: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { customerSharedCart } = getState().sharedCart.cartConsuming.customerToCustomerSharedCart
        const storeId = storeToChange || appCacheService.preferredStoreId.get()
        const lastCartDeliveryMode = getState().cart?.cartItemsData?.cart?.deliveryMode
        const previousCartHadBOPISItems = getState().cart?.cartItemsData?.bopis.length > 0

        return sharedCartService
            .mergeSharedCartWithUserCart(cartIdentifier, sharedCartCode, sharedCartEntryCodes, storeId)
            .then((cartData: { data: CartItemsData }) => {
                replaceEmptyImagesWithDefault(cartData?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(cartData.data)
                if (cartFilteredData.cart.deliveryMode !== lastCartDeliveryMode && previousCartHadBOPISItems) {
                    appCacheService.isDeliveryModeConflict.set('yes')
                }
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                dispatch(setCustomerSharedCartMergingError(''))
                fireAnalyticsForSharedCartMerge(customerSharedCart, sharedCartEntryCodes)
            })
            .catch((err: { response: { data: HybrisErrorResponse } }) => {
                const errorType = err?.response?.data?.data?.errors
                    ? err.response.data.data.errors[0].type
                    : ErrorTypes.genericError
                dispatch(setCustomerSharedCartMergingError(errorType))
            })
    }

export const getStoreInitiatedCart =
    (templateCode: string, storeId: string, entryCodesForSelectedProducts: string[] = []) =>
    (dispatch: Dispatch): Promise<void> => {
        return sharedCartService
            .getStoreInitiatedCart(templateCode, storeId, entryCodesForSelectedProducts)
            .then((cartData: { data: CartItemsData }) => {
                replaceEmptyImagesWithDefault(cartData?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(cartData.data)
                sessionStorageService.setItem(STORE_INITIATED_CART_KEY, JSON.stringify(cartFilteredData))
                fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
                dispatch(setStoreInitiatedCart(cartFilteredData))
            })
            .catch(() => {
                dispatch(setStoreInitiatedCartError(true))
            })
    }

export const setStoreInitiatedSharedCart =
    (sharedCartData: FilteredCartData) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setStoreInitiatedCart(sharedCartData))
    }

export const changeStoreInitiatedCartItemsDeliveryMode =
    (cartId: string, deliveryMode: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return sharedCartService
            .changeStoreInitiatedCartDeliveryMode(cartId, deliveryMode)
            .then(() => {
                dispatch(setOneTimeCartDeliveryModeChanged(true))
                dispatch(clearPostalErrorStateForOneTimeCart())
            })
            .catch(() => {
                dispatch(setChangeDeliveryModeError(true))
            })
    }

export const clearDeliveryModeChanged =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(setOneTimeCartDeliveryModeChanged(false))
    }

export const clearChangeDeliveryModeError =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(setChangeDeliveryModeError(false))
    }

export const sendSharedCartLink =
    (cartId: string, sender: string, emails: string[], phones: string[]) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setSharedCartLinkSent(false))
        dispatch(setSharedCartLinkSendingError(''))
        dispatch(setSendLinkFormData({} as SendLinkFormData))

        return sharedCartService
            .sendShareCartLink(cartId, sender, emails, phones)
            .then(() => {
                dispatch(setSharedCartLinkSent(true))
            })
            .catch((err: { response: { data: HybrisErrorResponse } }) => {
                const errorType = err?.response?.data?.data?.errors[0]?.type || ErrorTypes.genericError
                dispatch(setSharedCartLinkSendingError(errorType))
            })
    }
