/**
 * added constants for validating different error scenarios and to fetch respective mock json
 * to be removed during actual api integration
 */

export const postalCodeConstants = {
    invalidPC: 'invalidPC',
    outOfRangePC: 'outOfRangePC',
    validPC: 'validPC',
    errorPC: 'errorPC',
}
