import { Offers } from '../components/OfferCard/OfferCard.type'
import { offerConstants } from '../components/OfferCard/OfferCard.constant'
import { checkToggle, offerTypes } from '../globalConstants/global.constant'

const offerZeroMultiplierFilter = (offer: Offers) => offer.details.awardValue === offerConstants.zeroMultiplier

const percentsSavingOffersFilter = (offer: Offers) => offer.details.bonusDescription?.includes(offerConstants.percent)

const remainingOffersFilter = (offer: Offers) => !offer.details.bonusDescription?.includes(offerConstants.percent)

const compareByAwardValue = (offer1: Offers, offer2: Offers): number => {
    return parseInt(offer2.details.awardValue) - parseInt(offer1.details.awardValue)
}

const compareByDaysLeft = (offer1: Offers, offer2: Offers): number => {
    return parseInt(offer1.daysLeft) - parseInt(offer2.daysLeft)
}

const compareByAwardValueAndDaysLeft = (offer1: Offers, offer2: Offers): number => {
    const awardComparison = compareByAwardValue(offer1, offer2)
    if (awardComparison === 0) {
        return compareByDaysLeft(offer1, offer2)
    }
    return awardComparison
}

export const sortOffers = (offers: Offers[]): Offers[] => {
    const redeemedOffers = offers.filter(
        offer => offer?.offerStatus === offerConstants.redeemed && offer?.redeemed === checkToggle.yes,
    )
    const redeemedSpentAndGetOffers = redeemedOffers.filter(
        offer => offer.details.bonusType === offerConstants.flatrate,
    )
    const redeemedmultiplierOffers = redeemedOffers.filter(offer => !redeemedSpentAndGetOffers.includes(offer))
    const allOffersExceptRedeemed = offers.filter(offer => !redeemedOffers.includes(offer))
    const spentAndGetOffers = allOffersExceptRedeemed.filter(
        offer => offer.details.bonusType === offerConstants.flatrate,
    )
    const nonSpentAndGetOffers = allOffersExceptRedeemed.filter(offer => !spentAndGetOffers.includes(offer))

    const notActivatedOffers = nonSpentAndGetOffers.filter(
        offer => offer.offerActivated !== checkToggle.yes && offer.offerActivationRequired !== checkToggle.no,
    )
    const zeroMultiplierNotActivatedOffers = notActivatedOffers.filter(offerZeroMultiplierFilter)
    const multiplierNotActivatedOffers = notActivatedOffers.filter(
        offer => !zeroMultiplierNotActivatedOffers.includes(offer),
    )
    const percentsSavingNotActivatedOffers = zeroMultiplierNotActivatedOffers.filter(percentsSavingOffersFilter)
    const remainingNotActivatedOffers = zeroMultiplierNotActivatedOffers.filter(remainingOffersFilter)

    const activatedOffers = nonSpentAndGetOffers.filter(offer => !notActivatedOffers.includes(offer))
    const zeroMultiplierActiveOffers = activatedOffers.filter(offerZeroMultiplierFilter)
    const multiplierActiveOffers = activatedOffers.filter(offer => !zeroMultiplierActiveOffers.includes(offer))
    const percentsSavingOffers = zeroMultiplierActiveOffers.filter(percentsSavingOffersFilter)
    const remainingOffers = zeroMultiplierActiveOffers.filter(remainingOffersFilter)

    const spentAndGetNotActivatedOffers = spentAndGetOffers.filter(
        offer => offer.offerActivated !== checkToggle.yes && offer.offerActivationRequired !== checkToggle.no,
    )
    const spentAndGetActivatedOffers = spentAndGetOffers.filter(offer => !spentAndGetNotActivatedOffers.includes(offer))

    spentAndGetNotActivatedOffers.sort(compareByAwardValueAndDaysLeft)
    spentAndGetActivatedOffers.sort(compareByAwardValueAndDaysLeft)
    multiplierNotActivatedOffers.sort(compareByAwardValueAndDaysLeft)
    percentsSavingNotActivatedOffers.sort(compareByDaysLeft)
    multiplierActiveOffers.sort(compareByAwardValueAndDaysLeft)
    percentsSavingOffers.sort(compareByDaysLeft)
    redeemedSpentAndGetOffers.sort(compareByAwardValueAndDaysLeft)
    redeemedmultiplierOffers.sort(compareByAwardValueAndDaysLeft)

    return [
        ...spentAndGetNotActivatedOffers,
        ...multiplierNotActivatedOffers,
        ...percentsSavingNotActivatedOffers,
        ...remainingNotActivatedOffers,
        ...spentAndGetActivatedOffers,
        ...multiplierActiveOffers,
        ...percentsSavingOffers,
        ...remainingOffers,
        ...redeemedSpentAndGetOffers,
        ...redeemedmultiplierOffers,
    ]
}

/** to get whether it is a multiplier offer
 * @param {string} nppOfferType
 * @return {boolean}
 */
export const isMultiplierOffer = (nppOfferType: string): boolean => {
    return nppOfferType === offerTypes.MULTIPLIER
}
