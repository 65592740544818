import { MediaType } from '../components/MediaGallery/MediaGallery.type'
import { getEnvironment } from '../environments'

export const replaceEmptyImagesWithDefault = (
    array: Record<string, any>[] | undefined,
    imageKey: string,
    defaultImgObject?: string | ImageObject[],
): void => {
    const config = getEnvironment()
    if (config.defaultProductImage && Array.isArray(array)) {
        array
            ?.filter(elem => elem)
            .forEach(elem => {
                if (!elem[imageKey] || (Array.isArray(elem[imageKey]) && !(elem[imageKey] as Array<unknown>).length)) {
                    elem[imageKey] = defaultImgObject ?? [{ ...defaultImageObject, url: config.defaultProductImage }]
                }
            })
    }
}

export interface ImageObject {
    altText: string
    displayPriority?: number | null
    isListingThumbnailImage?: boolean | string
    mediaType?: string
    url: string
}

export const defaultImageObject = {
    altText: '',
    displayPriority: null,
    isListingThumbnailImage: 'false',
    mediaType: MediaType.galleryImage,
    url: '',
}
