/**
 * @param { string } text - text to find width of
 * @param { string } font - font to be used if provided and default body font is not defined
 * @return { number } - text width
 */
export const getTextWidth = (text: string, font?: string): number => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    // use the font from body element first before using the 'font' as fallback
    if (context?.font) context.font = getComputedStyle(document.body)?.font || font

    return context?.measureText(text)?.width || 0
}
