import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { VideoProps } from './video.type'
import { constantValues } from './Video.constant'
import { setOgImageTag } from '../../redux/actionCreators/ogImageTag.actionCreators'

export const Video: React.FC<VideoProps> = ({ ...props }) => {
    const elemRef = useRef(null)
    const dispatch = useDispatch()
    useEffect(() => {
        const { autoPlay, videoUrl, coverImageUrl } = props
        dispatch(setOgImageTag({ component: 'Video', imageLink: coverImageUrl }))
        const hostnames = [new URL(videoUrl)?.hostname, new URL(coverImageUrl)?.hostname]
        const resultHostnames: string[] = []

        hostnames.forEach((hostname: string) => {
            if (!resultHostnames.includes(hostname)) {
                resultHostnames.push(hostname)
            }
        })

        new window.Akamai.AsyncViewer(elemRef.current, {
            video: {
                muted: constantValues.isMutedWhenPlayed,
                autoplay: autoPlay ?? constantValues.isMutedWhenPlayed,
            },
            items: {
                hostnames: resultHostnames,
                data: [
                    {
                        type: 'video',
                        url: videoUrl,
                        tags: '',
                        poster: coverImageUrl,
                        alt: constantValues.playButtonAltText,
                    },
                ],
            },
        })
    }, [props, dispatch])

    return <div data-akamai-viewer ref={elemRef} />
}

export default Video

Video.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    coverImageUrl: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool,
}
