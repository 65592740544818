import analyticsLayer from '../../utils/analyticsService'
import convertStrToObj from '../../utils/convertStrToObj'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'

/**
 * Analytics layer for customer service accordion - OOTB AEM component
 *
 */
const accordionClick = (): void => {
    const accordionEle = document.getElementsByClassName('cmp-accordion__header') as HTMLCollectionOf<HTMLElement>
    const parseHTMLMethod = (data: string): string => {
        const div = document.createElement('div')
        div.innerHTML = data
        return div.textContent?.trim() || div.innerText.trim() || ''
    }
    const accClickHandler = (headerEle: HTMLElement): void => {
        headerEle.onclick = function () {
            const analyticsData = headerEle.getAttributeNode('data-analytics')?.value
            const convertedData = convertStrToObj(analyticsData as string)
            const isButtonEle = headerEle.firstElementChild as HTMLButtonElement
            const isButtonExpanded = isButtonEle?.classList.contains('cmp-accordion__button--expanded')
            if (isButtonExpanded) {
                const { label, action, category, value, location } = convertedData
                analyticsLayer.pushData({
                    event: analyticsAttributes.event.interaction,
                    eventParameters: {
                        action: action,
                        category: category,
                        label: parseHTMLMethod(label as string),
                        value: value,
                        location: location,
                    },
                })
            }
        }
    }
    for (let i = 0; i < accordionEle.length; i++) {
        accClickHandler(accordionEle[i])
    }
}

export { accordionClick }
