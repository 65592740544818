/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { customDebounce } from '../../utils/customDebounce'

import { googlePlaceServices } from '../../utils/GooglePlaceServices/GooglePlaceService'
import {
    AutoCompletePredictionResponseType,
    GetPlaceDetailsParamType,
    GetPlaceDetailsResponseType,
} from '../../utils/GooglePlaceServices/GooglePlaceService.type'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import Autocomplete from '../Autocomplete/Autocomplete'
import { PREFIX } from '../config'
import { GoogleAutocompleteProps } from './GoogleAutocomplete.type'
import { autocompleteMinLength } from '../../globalConstants/global.constant'
import { Suggestions } from '../Autocomplete/Autocomplete.types'

/**
 * Google AutoComplete component
 * @param {GoogleAutocompleteProps} GoogleAutocompleteProps
 *
 * @return {JSX.Element}
 */
const GoogleAutocomplete: React.FC<GoogleAutocompleteProps> = ({
    searchInputPH,
    a11ySearchIconAriaLabel,
    a11ySuggestionResultAvailable,
    fetchNearbyStoreListOnSearch,
    inputMaxLength,
    windowRef,
    setIsInputInValid,
    searchStoreAnalyticsHandler,
    onFocusHandler,
}: GoogleAutocompleteProps) => {
    const [predictions, setPredictions] = useState<AutoCompletePredictionResponseType[]>([])
    const [locationDetails, setLocationDetails] = useState({} as GetPlaceDetailsResponseType)
    const [debounceTimeOut, setDebounceTimeOut] = useState<NodeJS.Timeout>(null)
    const [selectedAddress, setSelectedAddress] = useState('')
    const [externalPlaceService] = useState(googlePlaceServices(windowRef))

    useEffect(() => {
        // Set the variable to store the predictions and location details when the component is loaded.
        externalPlaceService.setStorageVariables({
            storePredictions: setPredictions,
            storeLocationDetails: setLocationDetails,
        })
    }, [])

    useEffect(() => {
        // When the location details are ready make an api call to get near by store list.
        if (locationDetails && Object.keys(locationDetails).length > 0) {
            const { lat, long } = locationDetails
            fetchNearbyStoreListOnSearch(lat, long)
        } else {
            selectedAddress && setIsInputInValid(true)
        }
    }, [locationDetails])

    /**
     * Function will trigger when suggested item is selected and when user press enter.
     * @param {AutoCompletePredictionResponseType} data - single prediction data.
     * @param {boolean} invokeStoreDetails if true, make call to get store details
     * @return {string}
     */
    const onAddressSelect = (data: AutoCompletePredictionResponseType, invokeStoreDetails?: boolean): string => {
        const { description: address } = data || {}
        /**
         * If else block is used because, when the user types a address for which suggestion are not opening and he press enter,
         * in autocomplete component the cursor variable is going to -1 and the above data params is null and wont return address.
         * To fix the error added else part and returning the selectedAddress
         */
        if (address) {
            setIsInputInValid(false)
            setSelectedAddress(address)
            invokeStoreDetails && searchCallBack(address)
            externalPlaceService.updateSessionToken() // generate a new token when a click happens other wise continuing use the same previous token.
            return address // this return is used when we click on the suggested list.
        } else selectedAddress // this return is used when enter is pressed in the input.
    }

    /**
     * Function will trigger when user clicks on search icon or press enter.
     * @param {string} existingAddress
     */
    const searchCallBack = (existingAddress: string): void => {
        if (!!existingAddress && existingAddress.trim().length && isArrayNotEmpty(predictions)) {
            let address = ''
            const isAddressExistInPredictions = predictions.find(
                (prediction: AutoCompletePredictionResponseType) =>
                    prediction.description.trim().toString() === existingAddress.trim().toString(),
            )

            // if block, if user typed address exactly matching from suggestion, taken as it is.
            // Else block, If user's typed only few letters and clicked on search icon, taken the first suggestion as selected address
            if (isAddressExistInPredictions) {
                address = isAddressExistInPredictions.description
                setSelectedAddress(existingAddress)
            } else {
                address = predictions[0].description
                setSelectedAddress(predictions[0].description)
            }
            // Get the lat and long based on the address given by the autocomplete api.
            externalPlaceService.getPlaceDetails({
                type: GetPlaceDetailsParamType.ADDRESS, // can pass either address or placeId
                value: address, // selected Address from auto complete api
            })
            searchStoreAnalyticsHandler()
            // hide the warning.
            setIsInputInValid(false)
        }
    }

    /**
     * Function will trigger on input change.
     * @param {string} inputValue
     */
    const handleInputChange = (inputValue: string): void => {
        setSelectedAddress(inputValue) // If user entered address and clicked directly to search icon without selecting option, in this case we needed to take as selected. OCCP-14502.
        if (!inputValue) {
            // when no value, clear the predictions
            setPredictions([])
            return
        }
        if (inputValue.length >= autocompleteMinLength) {
            // execute the set timeout after 1second
            const waitTime = 1000
            // debounce is used to avoid the calls on each character.
            customDebounce(
                () => externalPlaceService.autoSuggestion(inputValue),
                waitTime,
                debounceTimeOut,
                setDebounceTimeOut,
            )()
            setIsInputInValid(false)
            setPredictions([])
        }
    }

    /**
     * Render's single suggestion list item
     * @param {AutoCompletePredictionResponseType} singlePredictions
     *
     * @return {JSX.Element}
     */
    const renderItem = (singlePredictions: AutoCompletePredictionResponseType): JSX.Element => {
        const { description } = singlePredictions
        const autocompleteClass = `${PREFIX}-autocomplete-container`

        return (
            <div className={`${autocompleteClass}__item`}>
                <span className={`${autocompleteClass}__clamp-text--mobile`}>{description}</span>
            </div>
        )
    }

    return (
        <Autocomplete
            suggestions={predictions as unknown as Suggestions[]}
            label={searchInputPH}
            language={document.documentElement.lang}
            id={`${PREFIX}-store-locator-search-box`}
            renderItem={renderItem}
            onSelect={(address: AutoCompletePredictionResponseType, invokeStoreDetails = true) => {
                return onAddressSelect(address, invokeStoreDetails)
            }}
            maxLength={Number(inputMaxLength)}
            placeholder=" "
            showSearchIcon={true}
            a11ySearchIconLabel={a11ySearchIconAriaLabel}
            searchCallBack={searchCallBack}
            onAutocompleteChange={handleInputChange}
            selectedAddress={selectedAddress}
            onFocusHandler={ev => onFocusHandler && onFocusHandler(ev)}
            a11ySuggestionResultAvailable={a11ySuggestionResultAvailable}
        />
    )
}

export default GoogleAutocomplete
