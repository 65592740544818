import React from 'react'

import { PREFIX } from '../config'
import ProductCard from '../ProductReusableCard'
import { FeaturedProductListDataType, ProductDetails } from './FeaturedProductList.type'
import Carousel from '../Carousel'
import { HybrisMedia, ProductCardType } from '../ProductGridView/ProductGrid.types'
import { addDynamicToken, isArrayNotEmpty, magicNumber, replaceStrWithDynamicVal } from '../../utils'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { carouselSettings } from '../Carousel/Carousel.constant'
import { ProductDataTypeObj } from '../../../../app/src/redux/models/productData.interface'
import { featureProductListConst } from './FeaturedProductList.constant'
import { getAccessibilityId } from '../../utils/getAccessibilityId'

/**
 * Featured Product list component
 * @param {FeaturedProductListDataType} {} props sent to render FeaturedProductList
 * @return {JSX.Element} returns Featured Product list component
 */
export const FeaturedProductList: React.FC<FeaturedProductListDataType> = ({
    aemProps,
    productList,
    badgePriorities,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    language,
    title,
    maxRecommendations,
    priceProps,
    productCardClickHandler,
    imageDataComponentName,
    returnPolicy,
    a11yClickToReadFootnote,
    enableBoldedBrandName = false,
}: FeaturedProductListDataType): JSX.Element => {
    const {
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    } = priceProps

    const productProps = {
        ratingsAndReview: aemProps.enableRatingAndReviewInfo,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        navigateTo: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    /**
     * On Product Card Click open pdp page for passed product Code
     * @param {React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>} event
     * @param {ProductCardType|ProductDataTypeObj} productData
     * @param {number} idx product index to pass to parent
     * @return {void}
     */
    const cardClickHandler = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
        productData: ProductCardType | ProductDataTypeObj,
        idx: number,
    ): void => {
        event.preventDefault()
        event.stopPropagation()
        productCardClickHandler?.(idx)
        window.open(productData.url, aemProps.linkTarget || '_self', 'noopener')
    }

    /**
     * Render Product Cards
     * @return {JSX.Element[]}
     */
    const productCards = (): JSX.Element[] => {
        return productList.slice(0, maxRecommendations).map((productData, i) => {
            const {
                title: productTitle,
                brand,
                code,
                discount,
                onlineOnly,
                price,
                priceShortMessage,
                priceLongMessage,
                longDescription,
                salePrice,
                badges: productBadges,
                rating,
                ratingsCount,
                images,
                currentPrice,
                originalPrice,
                priceMessage,
                displayWasLabel,
                feeMessages,
                url,
            } = productData
            const sPrice = aemProps.enableSaveStory ? salePrice : null
            const badges = aemProps.enableBadges ? productBadges : []
            const aemImage =
                aemProps.products &&
                isArrayNotEmpty(aemProps.products) &&
                aemProps?.products?.find((singleDetail: ProductDetails) => singleDetail.skuId === code)?.productImage
            const hybrisImageData = isArrayNotEmpty(images) && images[0]
            const finalImages: HybrisMedia[] = !!aemImage
                ? [
                      {
                          ...hybrisImageData,
                          url: aemImage,
                      },
                  ]
                : images
            return (
                <ProductCard
                    product={productData as unknown as ProductCardType}
                    badgePriorities={badgePriorities}
                    brand={brand}
                    cardType="grid"
                    key={i}
                    url={url}
                    code={productData?.skus?.length === magicNumber.ONE ? productData?.skus?.[0]?.formattedCode : ''}
                    idx={i}
                    images={finalImages}
                    showRatingSection={true}
                    rating={rating}
                    price={price}
                    salePrice={sPrice}
                    productProps={productProps}
                    ratingsCount={ratingsCount}
                    title={productTitle}
                    badges={badges}
                    hideDisclaimer={true}
                    priceShortMessage={priceShortMessage}
                    priceLongMessage={priceLongMessage}
                    longDescription={longDescription}
                    discount={discount}
                    onlineOnly={onlineOnly}
                    productCardClick={(event, product, idx: number) => cardClickHandler(event, product, idx)}
                    hideDescription={aemProps.disableProductDescription}
                    hideSaveLabel={!aemProps.enableSaveStory}
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    language={language}
                    feeTitle={
                        feeMessages?.[0]?.value
                            ? replaceStrWithDynamicVal(
                                  addDynamicToken(feeMessages?.[0]?.feeTitle, '$x'),
                                  getFormattedPriceValue(language, feeMessages?.[0]?.value),
                              )
                            : ''
                    }
                    feeDisclaimerType={feeMessages?.[0]?.type}
                    feeDisclaimerMessage={feeMessages?.[0]?.feeDisclaimerMessage}
                    feeDisclaimerTitle={feeMessages?.[0]?.feeDisclaimerTitle}
                    currentPrice={currentPrice}
                    originalPrice={originalPrice}
                    priceMessage={priceMessage}
                    nowFromLabel={nowFromLabel}
                    saveFromLabel={saveFromLabel}
                    wasFromLabel={wasFromLabel}
                    fromLabel={fromLabel}
                    displayWasLabel={displayWasLabel}
                    unitPriceLabel={unitPriceLabel}
                    promotionalPriceLabel={promotionalPriceLabel}
                    clearancePriceLabel={clearancePriceLabel}
                    thresholdValue={threshold}
                    imageDataComponentName={imageDataComponentName}
                    returnPolicy={returnPolicy}
                    productDataId={code}
                    a11yClickToReadFootnote={a11yClickToReadFootnote}
                    accessibilityId={getAccessibilityId(
                        featureProductListConst,
                        productData?.skus?.[0].code ? productData?.skus?.[0].code : code,
                    )}
                    boldBrand={enableBoldedBrandName}
                />
            )
        })
    }

    return (
        <div className={`${PREFIX}-featured-product-list ${PREFIX}-container`}>
            <div className={`${PREFIX}-col-md-12 ${PREFIX}-col-sm-12 ${PREFIX}-col-xs-6`}>
                <h2 className={`${PREFIX}-featured-product-list__title`} data-testid="featured-product-title">
                    {aemProps.title || title}
                </h2>
                <div className={`${PREFIX}-product__grid-view`}>
                    <Carousel
                        carouselList={productCards()}
                        carouselClassName="carousel-authored"
                        a11yCarouselNextLabel={aemProps.a11yCarouselNextLabel}
                        a11yCarouselPreviousLabel={aemProps.a11yCarouselPreviousLabel}
                        desktopThreshold={carouselSettings?.desktopThresholdDefault}
                        title={aemProps.title || title}
                    />
                </div>
            </div>
        </div>
    )
}
