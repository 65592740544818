import { libUtils, magicNumber, isArrayNotEmpty } from '@nl/lib'
import { getCookie } from '../components/LoyaltyOffers/LoyaltyOffers.helper'
import localStorageService from './localStorageService'
import { offersService } from '../services/offersService'
import { FetchOffersPayload, CustomerOffers, ActiveOffer, Offers } from '../redux/models/offers.interface'
import { offerConstants } from '../components/Accounts/Rewards/Offers/OffersCard.constants'
import { sortOffers } from './offersSorter'
import sessionStorageService from './sessionStorageService'

export const offersCacheCookie = 'areOffersCached'
export const offersCacheLocalStorage = 'offersCache'
export const offerLanguageKey = 'offerLanguage'
const millisInDay = 86400000

export const getOffersPayload = (offersSortBy?: string[]): FetchOffersPayload => {
    return {
        lang: libUtils.getLanguage(),
        resultsPerPage: offerConstants.maxRewardsCount.toString(),
        pageNumber: offerConstants.currentPageInitialValue.toString(),
        ...(isArrayNotEmpty(offersSortBy) && { sortByBanners: offersSortBy }),
    }
}

const setOffersCacheCookie = (): void => {
    const dateToExpire = new Date()
    dateToExpire.setTime(dateToExpire.getTime() + millisInDay)
    dateToExpire.setHours(magicNumber.SEVEN, magicNumber.ZERO, magicNumber.ZERO, magicNumber.ZERO)
    document.cookie = offersCacheCookie + `=true; expires= ${dateToExpire.toString()}; path=/`
}

export const clearOffersCacheCookie = (): void => {
    const dateToExpire = new Date()
    dateToExpire.setTime(dateToExpire.getTime() - millisInDay)
    document.cookie = offersCacheCookie + `=true; expires= ${dateToExpire.toString()}; path=/`
}

export const areOffersCached = (): boolean => {
    return !!getCookie(offersCacheCookie) && !!localStorageService.getItem(offersCacheLocalStorage)
}

export const parseCachedOffersString = (): CustomerOffers => {
    const offersCache = localStorageService.getItem(offersCacheLocalStorage)
    if (offersCache) {
        return JSON.parse(offersCache) as CustomerOffers
    }
}

export const cacheOffers = (offersData: CustomerOffers, pathLanguage: string): void => {
    sessionStorageService.setItem(offerLanguageKey, pathLanguage)
    localStorageService.setItem(offersCacheLocalStorage, JSON.stringify(offersData))
    setOffersCacheCookie()
}

/**
 * Function to handle offers caching logic on sign-in
 */
export const handleOffersCacheOnSignIn = (): void => {
    if (!areOffersCached()) {
        offersService
            .getOffers(getOffersPayload())
            .then((offersData: { data: CustomerOffers }) => {
                offersData.data.offers = sortOffers(offersData.data.offers)
                cacheOffers(offersData.data)
            })
            .catch(() => {
                return
            })
    }
}

/**
 * Function to handle offers caching logic on sign-out
 */
export const handleOffersCacheOnSignOut = (): void => {
    clearOffersCacheCookie()
    localStorageService.removeItem(offersCacheLocalStorage)
}

export const updateCacheOnActivateSingle = (activeOffer: ActiveOffer): void => {
    if (activeOffer.activated === 'Y') {
        const updatedOffersCache = parseCachedOffersString()
        const newOffersArr = [] as Offers[]
        updatedOffersCache.offers.forEach(offer => {
            if (offer.offerCode === activeOffer.offerCode) {
                offer.offerActivated = 'Y'
                offer.offerStatus = 'ACTIVATED'
            }
            newOffersArr.push(offer)
        })
        updatedOffersCache.offers = newOffersArr
        localStorageService.setItem(offersCacheLocalStorage, JSON.stringify(updatedOffersCache))
    }
}

/**
 * Function to update offers cache
 */
export const updateOffersCache = (): void => {
    offersService
        .getOffers(getOffersPayload())
        .then((offersData: { data: CustomerOffers }) => {
            offersData.data.offers = sortOffers(offersData.data.offers)
            localStorageService.setItem(offersCacheLocalStorage, JSON.stringify(offersData.data))
            setOffersCacheCookie()
        })
        .catch(() => {
            return
        })
}
