import { RootState } from '../reducers'
import {
    CheckoutContactInfo,
    CheckoutDrawerFailure,
    CheckoutDrawerState,
    CheckoutPickupInfo,
} from '../models/checkout.interface'

export const checkoutDrawerSelector = (state: RootState): CheckoutDrawerState => state.checkoutDrawer

export const checkoutPickupInfoSelector = (state: RootState): CheckoutPickupInfo =>
    checkoutDrawerSelector(state).checkoutPickupInfo

export const checkoutContactInfoSelector = (state: RootState): CheckoutContactInfo =>
    checkoutDrawerSelector(state).checkoutContactInfo

export const savedPaymentToCartSelector = (state: RootState): boolean =>
    checkoutDrawerSelector(state).savedPaymentToCart

export const checkoutDrawerFailureSelector = (state: RootState): CheckoutDrawerFailure =>
    checkoutDrawerSelector(state).checkoutDrawerFailure
