import { ActionType, getType } from 'typesafe-actions'

import {
    linkLoyaltyCardSuccessAction,
    linkLoyaltyCardErrorAction,
    linkLoyaltyCheckSuccessAction,
    linkLoyaltyCheckErrorAction,
    clearLinkLoyaltyCheckResponseAction,
    clearLinkLoyaltyCheckErrorAction,
    clearLinkLoyaltyCardResponseAction,
    clearLinkLoyaltyCardErrorAction,
} from '../actionCreators/linkLoyaltyCard.actionCreators'
import * as actions from '../actionCreators/linkLoyaltyCard.actionCreators'
import {
    LinkLoyaltyCardType,
    LinkLoyaltyCardErrorResponse,
    LinkLoyaltyCheckResponse,
} from '../models/linkLoyaltyCard.interface'

type Action = ActionType<typeof actions>

export interface LinkLoyaltyCardState {
    linkLoyaltyCardResponse: LinkLoyaltyCardType | null
    linkLoyaltyCardError: LinkLoyaltyCardErrorResponse | null
    linkLoyaltyCheckResponse: LinkLoyaltyCheckResponse | null
    linkLoyaltyCheckError: LinkLoyaltyCardErrorResponse | null
}

export const initialState: LinkLoyaltyCardState = {
    linkLoyaltyCardResponse: null,
    linkLoyaltyCardError: null,
    linkLoyaltyCheckResponse: null,
    linkLoyaltyCheckError: null,
}

export const linkLoyaltyCardReducer = (
    state: LinkLoyaltyCardState = initialState,
    action: Action,
): LinkLoyaltyCardState => {
    switch (action.type) {
        case getType(linkLoyaltyCardSuccessAction): {
            return { ...state, linkLoyaltyCardResponse: action.payload }
        }
        case getType(linkLoyaltyCardErrorAction): {
            return { ...state, linkLoyaltyCardError: action.payload }
        }
        case getType(linkLoyaltyCheckSuccessAction): {
            return { ...state, linkLoyaltyCheckResponse: action.payload }
        }
        case getType(linkLoyaltyCheckErrorAction): {
            return { ...state, linkLoyaltyCheckError: action.payload }
        }
        case getType(clearLinkLoyaltyCardResponseAction): {
            return { ...state, linkLoyaltyCardResponse: null }
        }
        case getType(clearLinkLoyaltyCardErrorAction): {
            return { ...state, linkLoyaltyCardError: null }
        }
        case getType(clearLinkLoyaltyCheckResponseAction): {
            return { ...state, linkLoyaltyCheckResponse: null }
        }
        case getType(clearLinkLoyaltyCheckErrorAction): {
            return { ...state, linkLoyaltyCheckError: null }
        }
        default:
            return state
    }
}
