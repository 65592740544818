import React, { MouseEvent, KeyboardEvent, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { ProductCardColorSwatchesProps } from './ProductCardColorSwatches.types'
import { BREAKPOINTS, PREFIX } from '../config'
import { magicNumber, stringKeyCodes, useGlobalResizeEvent, useOnClickOutside } from '../../utils'
import { ProductOptionValue } from '../ProductGridView/ProductGrid.types'
import {
    getInteractiveElements,
    getNextInteractiveElement,
    getPreviousInteractiveElement,
} from '../../helpers/getInteractiveElements.helper'
import { useOnKeyDownOutside } from '../../utils/useOnKeyDownOutside'
import { arrowActionIdentifier } from './ProductCardColorSwatches.constants'

const ProductCardColorSwatches: React.FC<ProductCardColorSwatchesProps> = ({
    colors,
    onColorChange,
    selectedValue,
    display,
}) => {
    const [numberOfColorsToDisplay, setNumberOfColorsToDisplay] = useState(magicNumber.FIVE)
    const variantsListRef = useRef<HTMLUListElement>(null)

    const onVariantClick = (
        e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
        value: ProductOptionValue,
    ) => {
        e.preventDefault()
        e.stopPropagation()
        onColorChange(value)
    }

    const getColorsCount = useCallback(() => {
        let count = magicNumber.FIVE
        if (window.innerWidth < BREAKPOINTS.tabletMaxWidth) {
            count = magicNumber.FOUR
        }
        if (window.innerWidth < BREAKPOINTS.mobileMaxWidth) {
            count = magicNumber.THREE
        }

        setNumberOfColorsToDisplay(count)
    }, [])

    const additionalColorsCount = useMemo(() => {
        return colors.length - numberOfColorsToDisplay
    }, [colors.length, numberOfColorsToDisplay])

    useEffect(() => {
        getColorsCount()
    }, [getColorsCount])

    useGlobalResizeEvent(getColorsCount)

    /**
     * This function is dedicated for variants outside action handler
     * set tabindex to "0" for selected variant when user clicks outside of variant radio group
     */
    const variantsOutsideActionHandler = useCallback(() => {
        const variantsList = getInteractiveElements(variantsListRef?.current)
        // reset tabindex to "-1"
        resetTabindexForVariants(variantsList)
        // set tabindex to "0" for selected variant
        variantsListRef?.current?.querySelector('[aria-checked="true"]')?.setAttribute('tabindex', '0')
    }, [])

    useOnClickOutside(variantsListRef, variantsOutsideActionHandler)

    useOnKeyDownOutside(variantsListRef, variantsOutsideActionHandler)

    const getImageUrl = (url: string) => {
        return /^https?:\/\//i.test(url) ? url : `https://${url}`
    }

    /**
     * This function resets tabindex for variants (radio group)
     * @param { NodeListOf<HTMLElement> } variantsList
     */
    const resetTabindexForVariants = (variantsList: NodeListOf<HTMLElement>) => {
        variantsList.forEach(item => {
            item.setAttribute('tabindex', '-1')
        })
    }

    /**
     * This function resets aria-checked for variants (radio group)
     * @param { NodeListOf<HTMLElement> } variantsList
     */
    const resetAriaCheckedForVariants = (variantsList: NodeListOf<HTMLElement>) => {
        variantsList.forEach(item => {
            item.setAttribute('aria-checked', 'false')
        })
    }

    /**
     * This function is dedicated for arrows action with respect to variants
     * @param { React.KeyboardEvent<HTMLDivElement> } event
     * @param { string } action
     * @param { number } currentIndex
     */
    const handleArrowsForVariants = (
        event: React.KeyboardEvent<HTMLDivElement>,
        action: string,
        currentIndex: number,
    ) => {
        event.preventDefault()
        event.stopPropagation()
        const variantsList = getInteractiveElements(variantsListRef?.current)
        resetTabindexForVariants(variantsList)
        let interactiveElement: HTMLElement
        if (action === arrowActionIdentifier.NEXT) {
            interactiveElement = getNextInteractiveElement(currentIndex, variantsList)
        } else if (action === arrowActionIdentifier.PREVIOUS) {
            interactiveElement = getPreviousInteractiveElement(currentIndex, variantsList)
        }
        interactiveElement?.setAttribute('tabindex', '0')
        interactiveElement?.focus()
    }

    /**
     * This function handles keydown event for variants (radio group)
     * @param { React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { ProductOptionValue } variant
     */
    const handleKeyDownForVariants = (
        event: React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        variant: ProductOptionValue,
    ) => {
        if (event.key === stringKeyCodes.rightArrow || event.key === stringKeyCodes.upArrow) {
            handleArrowsForVariants(event, arrowActionIdentifier.NEXT, currentIndex)
        } else if (event.key === stringKeyCodes.leftArrow || event.key === stringKeyCodes.downArrow) {
            handleArrowsForVariants(event, arrowActionIdentifier.PREVIOUS, currentIndex)
        } else if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.space) {
            triggerVariantChange(event, currentIndex, variant)
        }
    }

    /**
     * This function handles click event for variant (inside radio group)
     * @param { React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { ProductOptionValue } variant
     */
    const triggerVariantChange = (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        variant: ProductOptionValue,
    ) => {
        const variantsList = getInteractiveElements(variantsListRef?.current)
        resetTabindexForVariants(variantsList)
        resetAriaCheckedForVariants(variantsList)
        variantsList[currentIndex].setAttribute('tabindex', '0')
        variantsList[currentIndex].setAttribute('aria-checked', 'true')
        onVariantClick(event, variant)
    }

    return (
        <>
            <ul
                role="radiogroup"
                ref={variantsListRef}
                aria-label={display}
                className={`${PREFIX}-variants__variant-list ${PREFIX}-variants__plp-color-swatches`}>
                {colors.slice(magicNumber.ZERO, numberOfColorsToDisplay).map((color, currentIndex) => {
                    const isVariantSelected = selectedValue === color.id
                    const isSelectedClass = isVariantSelected
                        ? `${PREFIX}-variants__variant--selected`
                        : `${PREFIX}-variants__variant--unselected`
                    return (
                        <li role="presentation" key={color.id} className="nl-variants__variant-item">
                            <div
                                role={currentIndex > magicNumber.MINUS_ONE ? 'radio' : ''}
                                data-accessibility={currentIndex > magicNumber.MINUS_ONE ? 'true' : 'false'}
                                aria-checked={currentIndex ? 'false' : 'true'}
                                aria-label={color.value}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                    triggerVariantChange(event, currentIndex, color)
                                }}
                                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                    handleKeyDownForVariants(event, currentIndex, color)
                                }}
                                className={`nl-variants__variant nl-variants__variant--colour-swatches nl-variants__variant-plp--colour-swatches ${isSelectedClass}`}>
                                <img src={getImageUrl(color.swatchImage)} alt={color.value} />
                            </div>
                        </li>
                    )
                })}
            </ul>
            {additionalColorsCount > 0 ? (
                <button className={`${PREFIX}-variants__plp-show-more`}>+{additionalColorsCount}</button>
            ) : null}
        </>
    )
}

export default ProductCardColorSwatches
