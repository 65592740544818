import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ToastMessage } from '../../redux/models/toastMessage.interface'
import { setToastMessageData } from '../../redux/actions/toastMessage.action'
import { ToastWrapper } from '../ToastWrapper'
import { toastService } from '../../services/toastService/toast.service'
import { toastDetailsSelector } from '../../redux/selectors/toastMessageData.selectors'

/**
 * ToastMessage component
 * @return {JSX.Element} returns ToastMessage component
 */
const ToastMessageComponent: React.FC<ToastMessage> = ({ ...props }) => {
    const toastDetails = useSelector(toastDetailsSelector)
    const dispatch = useDispatch()

    const runOnce = useRef(0)

    useEffect(() => {
        if (runOnce.current === 0) {
            dispatch(setToastMessageData(props))
            runOnce.current = 1
        }
    }, [props, dispatch])

    const toastComponent = (): JSX.Element => {
        const callbacks = toastService.getCallbacks()[toastDetails.componentName]
        return (
            <ToastWrapper
                toastProps={{
                    ...toastDetails.toastProps,
                    ...(callbacks as Record<string, string | boolean | unknown>),
                }}
            />
        )
    }

    const emptyComponent = (): null => {
        return null
    }

    return toastDetails?.showToast ? toastComponent() : emptyComponent()
}

export default ToastMessageComponent
