import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { NavItemProps, NavigationCategory } from './NavItem.types'
import SkeletonComponent from '../Skeleton/SkeletonComponent'
import { MegaNavigationDesktop } from '../MegaNavigationDesktop'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'

/**
 *  Navigation NavItem component
 * @param {NavItemProps} props
 * @return {JSX.Element} returns navPills
 */

const NavItem: React.FC<NavItemProps> = props => {
    const primaryMenuBarId = 'primaryMenuBar'
    const navigationRef = useRef<HTMLUListElement>(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { categoriesList } = props

    /**
     * function to open menu on click
     * @param {number} itemIndex
     */
    const showDropdownList = (itemIndex: number) => {
        setIsMenuOpen(true)
        const listItem = document.getElementsByClassName(`${PREFIX}-primary-nav-bar__nav-list--item`)
        if (categoriesList[itemIndex]['isSelected']) {
            setIsMenuOpen(false)
            categoriesList[itemIndex]['isSelected'] = false
            listItem[itemIndex].classList.remove(`${PREFIX}-primary-nav-bar__nav-list--item-active`)
        } else {
            categoriesList[itemIndex]['isSelected'] = true
            listItem[itemIndex].classList.add(`${PREFIX}-primary-nav-bar__nav-list--item-active`)
        }
        Object.values(listItem).forEach((element, elementIndex) => {
            if (elementIndex !== itemIndex) {
                categoriesList[elementIndex]['isSelected'] = false
                element.classList.remove(`${PREFIX}-primary-nav-bar__nav-list--item-active`)
            }
        })
    }

    /**
     * function to close menu on outside click
     */
    const closeHandler = () => {
        setIsMenuOpen(false)
        const listItem = document.getElementsByClassName(`${PREFIX}-primary-nav-bar__nav-list--item`)
        Object.values(listItem).forEach((element, elementIndex) => {
            categoriesList[elementIndex]['isSelected'] = false
            element.classList.remove(`${PREFIX}-primary-nav-bar__nav-list--item-active`)
        })
    }

    useClickOutsideClose(navigationRef, closeHandler, isMenuOpen, true)

    /**
     * function to create navigation list item
     * @param {string} category
     * @param {number} index
     * @return {JSX.Element}
     */
    const getNavigationListItem = (category: NavigationCategory, index: number): JSX.Element => {
        const { navTitle, navUrl, subSectionTab } = category
        const desiredData = {
            url: '',
            megaNavTitle: '',
        }

        const constructedData = {
            ...desiredData,
            url: navUrl,
            megaNavTitle: navTitle,
            subSectionTab,
        }

        return (
            <li className={`${PREFIX}-primary-nav-bar__nav-list--item`} key={`${index}-${navTitle}`}>
                <button
                    className={`${PREFIX}-menu-buttons ${PREFIX}-button--tertiary`}
                    onClick={() => showDropdownList(index)}
                    aria-haspopup={true}
                    aria-expanded={isMenuOpen}
                    data-testid={`primary-nav-link-${index}`}
                    id={`${index}-${navTitle}`}>
                    {navTitle}
                </button>
                <div className={`${PREFIX}-col-md-12`}>
                    <div className={`${PREFIX}-mega-navigation-container`}>
                        <MegaNavigationDesktop catalog={constructedData} isKp={true} />
                    </div>
                </div>
            </li>
        )
    }

    /**
     * function to create navigation list
     * @return {JSX.Element}
     */
    const getNavigationList = (): JSX.Element => {
        return (
            <ul
                ref={navigationRef}
                className={`${PREFIX}-primary-nav-bar__nav-list`}
                id={primaryMenuBarId}
                data-testid="primary-nav-list">
                {categoriesList.map((category: NavigationCategory, index: number) => {
                    return getNavigationListItem(category, index)
                })}
            </ul>
        )
    }
    const menuSizeModifier = 'size-default'

    return (
        <>
            {categoriesList.length ? (
                <div className={`${PREFIX}-primary-nav`} id="primary-nav" data-testid="primary-nav">
                    <div className={`${PREFIX}-row ${PREFIX}-container`}>
                        <div className={`${PREFIX}-col-md-12 ${PREFIX}-col-sm-12 ${PREFIX}-col-xs-6`}>
                            <nav className={`${PREFIX}-primary-nav-bar ${PREFIX}-primary-nav-bar__nav`}>
                                {getNavigationList()}
                            </nav>
                        </div>
                    </div>
                </div>
            ) : (
                <SkeletonComponent
                    skeletonClass={`${PREFIX}-full-width-container ${PREFIX}-primary-nav__skeleton ${PREFIX}-primary-nav__skeleton--${menuSizeModifier}`}
                />
            )}
        </>
    )
}

NavItem.propTypes = {
    categoriesList: PropTypes.array,
}

export default NavItem
