import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/balloonAddons.actionCreators'
import { HardwareList } from '../models/extraHardware.interface'
import {
    fetchBalloonAddonsAction,
    balloonAddonsFailureAction,
    setBalloonAddonsPriceAction,
} from '../actionCreators/balloonAddons.actionCreators'
import { balloonAddonsService } from '../../services/balloonAddonsService'

type Action = ActionType<typeof actions>

export interface balloonAddonsState {
    balloonAddonsList: HardwareList[]
}

export const initialState: balloonAddonsState = {
    balloonAddonsList: [],
}

export const balloonAddonsReducer = (state: balloonAddonsState = initialState, action: Action): balloonAddonsState => {
    switch (action.type) {
        case getType(fetchBalloonAddonsAction): {
            return {
                ...state,
                balloonAddonsList: action.payload
                    ? state.balloonAddonsList.concat(action.payload)
                    : state.balloonAddonsList,
            }
        }
        case getType(balloonAddonsFailureAction): {
            return state
        }
        case getType(setBalloonAddonsPriceAction): {
            return {
                ...state,
                balloonAddonsList: balloonAddonsService.setPriceAndAvailability(
                    action.payload,
                    state.balloonAddonsList,
                ),
            }
        }
        default:
            return state
    }
}
