export const MOCKED_BOOKING_NUMBER = '3061643260980'
export const DEFAULT_CTA_CONTINUE_SHOPPING = 'Continue Shopping'
export const DEFAULT_CONFIRMATION_REVIEW_HEADER = 'Your Booking Request'
export const AOA_CONTACT_INFO_DATA = 'AOAContactInfoData'
export const CONTINUE_BUTTON_ID = 'AOA-continue-button'
export const BACK_BUTTON_ID = 'AOA-back-button'
export enum ButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    CALL_TO_ACTION = 'call_to_action',
}
export const DEFAULT_CAUTION_TOAST_ICON = 'ct-notification-caution'
export enum StepsQueue {
    Location,
    Services,
    Date,
    Info,
    BookingReview,
    BookingResult,
    ModifyOrCancelAppointment,
    ModifyAppointment,
    ModifyBookingReview,
    ModifyBookingConfirmation,
    CancelledAppointment,
    ETireServices,
    ETireDate,
    ETireBookingReview,
    ETireBookingConfirmation,
    ErrorNoAutoService,
    ErrorAOANotEnabled,
}
export const REGULAR_FLOW_KEYS = ['info', 'location', 'services', 'dateTime']
export const ETIRE_FLOW_KEYS = ['services', 'dateTime']
export const defaultSidePadding = 15
export const eTireSidePadding = 30
export const additionalCommentsTextAreaId = 'AdditionalComments'
// AOAContactInfoFormId should not be duplicated
export const AOAContactInfoFormId = 'AOA-contact-info-form-element'

// These constatnts are responsible for inApp AOA flow
export const aoaInApp = 'inApp'
export const aoaInAppYear = 'vehicle.year'
export const aoaInAppMake = 'vehicle.make'
export const aoaInAppModel = 'vehicle.model'
export const automotiveCLPId = 'DC0000006'
export const appointmentBooked = 'APPOINTMENT_BOOKED'
export const continueShoppingClicked = 'CONTINUE_SHOPPING_CLICKED'
