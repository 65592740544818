import storeLocatorAnalytics from '../../analytics/components/storeLocatorAnalytics'
import { Position } from '../../components/Header/Header.type'
import {
    GetLocationUsingNavigatorResponseType,
    GetLocationUsingNavigatorRequestType,
} from '../../components/StoreLocator/StoreLocator.type'
import { getEnvironment } from '../../environments'
import { storeLatLongDetails, setIsGeolocationAllowed } from '../../redux/actionCreators'
import { fetchNearbyStoreList } from '../../redux/actions'
import { navigatorService } from '../navigatorService'
import sessionStorageService from '../sessionStorageService'

const getLocationUsingNavigator = ({
    dispatch,
    requestPayload,
    pageTypeForAnalytics = '',
    disallowCallback,
}: GetLocationUsingNavigatorRequestType): GetLocationUsingNavigatorResponseType => {
    const config = getEnvironment()
    /**
     * function called on click of allow location
     * @param {Position} position
     */
    const _getCurrentLocation = (position: Position): void => {
        const { latitude, longitude } = position.coords
        dispatch(
            storeLatLongDetails({
                latitude,
                longitude,
            }),
        )
        sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.geoLocationAuthorized, 'true')
        const locationRequestPayload = { ...requestPayload, latitude, longitude }
        dispatch(fetchNearbyStoreList(locationRequestPayload, undefined, true))
        dispatch(setIsGeolocationAllowed(true))
    }

    /**
     * function called on click of block location or closing popup without any button click
     */
    const _disallowLocation = (): void => {
        sessionStorageService.setItem(config.SESSION_STORAGE_KEYS.geoLocationAuthorized, 'false')
        dispatch(setIsGeolocationAllowed(false))
        disallowCallback && disallowCallback()
    }

    /**
     * Show "allow location dialog" when user clicks on map icon
     * @param {string} pageTypeForAnalytics
     */
    const setPreferredStoreOnClick = (): void => {
        const { searchStoreAnalytics } = storeLocatorAnalytics
        navigatorService.getCurrentPosition(_getCurrentLocation, _disallowLocation)
        searchStoreAnalytics('', pageTypeForAnalytics)
    }

    return {
        setPreferredStoreOnClick,
    }
}

export { getLocationUsingNavigator }
export default getLocationUsingNavigator
