import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX, previousElementName } from '../config'
import Button from '../Button'
import Icon from '../Icon'
import { ConfirmationModalProps } from './ConfirmationModal.type'
import ReactModal from '../ReactModal'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { confirmationModalConstant } from './ConfirmationModal.constant'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'

/**
 * ConfirmationModal component
 * @param {ConfirmationModalProps} props
 * `mirrorButtonActions` - by default we have `primary` YES button for confirmation and `tertiary` NO button for cancellation.
 * This prop allows us to change this behaviour. If `mirrorButtonActions = true`, then `primary` button has cancellation role and label
 * and `tertiary` button has confirmation role and label.
 * @return {JSX.Element}
 */
const ConfirmationModal: React.FC<ConfirmationModalProps> = props => {
    const {
        confirmationHeading,
        isConfirmationModalOpen,
        confirmationSubHeading,
        yesButtonLabel,
        noButtonLabel,
        path,
        closeConfirmationModal,
        a11yCloseLabel,
        confirmationModalClickHandler,
        mirrorButtonActions,
        notScroll,
        displayCloseButton = true,
        buttonSize = 'small',
        shouldCloseModalOnSubmit = true,
        showSubHeading,
    } = props
    const componentClassName = `${PREFIX}-confirmation-modal`
    const componentModificationClassName = displayCloseButton ? '' : `${componentClassName}--padding`
    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)
    const { close, yes, no } = confirmationModalConstant
    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-react-modal`
    const confirmationModalRef = useRef<HTMLDivElement>(null)

    /**
     * To handle accessibility on keyboard tab. Enables the modal accessibility when clicked on the trigger element.
     *
     * @param {string} isContinueClicked - passes yes, no, close based on whether clicked on continue or close/cancel button.
     * @param {boolean} isCarryOn - true or false based on whether clicked on yes or no buttons
     * @return {void}
     */
    const closeModal = (isContinueClicked: string, isCarryOn?: boolean): void => {
        // TODO: going forword need to remove isContinueClicked and use only boolean value
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        closeConfirmationModal(isContinueClicked, isCarryOn)
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    /**
     * To handle close out side click of modal popup
     *
     */
    const closeHandler = (): void => {
        closeModal(close) // Closing Modal UI
        previousElement?.focus() // Highlight the initiated button
        previousElement?.removeAttribute(previousElementName) // Removing it when user close it.
    }

    useClickOutsideClose(confirmationModalRef, closeHandler, isConfirmationModalOpen, true)

    const noButtonProps = {
        // TODO: going forword need to remove no and use only boolean value
        id: 'no-modal',
        onClick: () => closeModal(no, false),
    }

    const yesButtonProps = {
        // TODO: going forword need to remove yes and use only boolean value
        id: 'yes-modal',
        onClick: () => {
            shouldCloseModalOnSubmit && closeModal(yes, true)
            confirmationModalClickHandler && confirmationModalClickHandler()
        },
    }

    let leftButtonLabel = noButtonLabel
    let rightButtonLabel = yesButtonLabel
    let leftButtonProps = noButtonProps
    let rightButtonProps = yesButtonProps

    if (mirrorButtonActions) {
        leftButtonProps = yesButtonProps
        leftButtonLabel = yesButtonLabel
        rightButtonProps = noButtonProps
        rightButtonLabel = noButtonLabel
    }

    return (
        <ReactModal closeHandler={() => closeModal(close)} isOpen={isConfirmationModalOpen} notScroll={notScroll}>
            <div className={`${componentClassName}  ${componentModificationClassName}`} ref={confirmationModalRef}>
                {displayCloseButton && (
                    <div className={`${componentClassName}__close-container`}>
                        <button
                            data-testid="confirm-modal-close-btn"
                            className={`${componentClassName}__close-btn`}
                            aria-label={a11yCloseLabel}
                            onClick={() => closeModal(close)}>
                            <Icon type="ct-close" size="lg" path={path} />
                        </button>
                    </div>
                )}
                <div className={`${componentClassName}__heading`}>
                    <h3 className={`${componentClassName}__header-text`}>{confirmationHeading}</h3>
                    <p
                        className={`${componentClassName}__subheader-text`}
                        dangerouslySetInnerHTML={{ __html: confirmationSubHeading }}
                    />
                    {showSubHeading}
                </div>
                <div className={`${componentClassName}__button-container`}>
                    {leftButtonLabel && (
                        <Button type="tertiary" size={buttonSize} {...leftButtonProps}>
                            {leftButtonLabel}
                        </Button>
                    )}
                    {rightButtonLabel && (
                        <Button type="primary" size={buttonSize} {...rightButtonProps}>
                            {rightButtonLabel}
                        </Button>
                    )}
                </div>
            </div>
        </ReactModal>
    )
}

ConfirmationModal.propTypes = {
    confirmationHeading: PropTypes.string.isRequired,
    confirmationSubHeading: PropTypes.string.isRequired,
    yesButtonLabel: PropTypes.string.isRequired,
    noButtonLabel: PropTypes.string,
    path: PropTypes.string,
    isConfirmationModalOpen: PropTypes.bool,
    closeConfirmationModal: PropTypes.func,
    a11yCloseLabel: PropTypes.string,
    confirmationModalClickHandler: PropTypes.func,
    mirrorButtonActions: PropTypes.bool,
    notScroll: PropTypes.bool,
    displayCloseButton: PropTypes.bool,
}
export default ConfirmationModal
