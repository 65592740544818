import { ActionType, getType } from 'typesafe-actions'

import { fetchInterceptorPromiseState } from '../actionCreators/tokenUpdate.actionCreators'

type Action = ActionType<typeof fetchInterceptorPromiseState>

export interface InterceptorPromiseState {
    interceptorState: boolean
}

export const initialState: InterceptorPromiseState = {
    interceptorState: false,
}

/**
 * Intercepting any kind of error reducer
 *  @param {InterceptorPromiseState} state
 *  @param {Action} action
 * @return {InterceptorPromiseState}
 */
export const InterceptorStateReducer = (
    state: InterceptorPromiseState = initialState,
    action: Action,
): InterceptorPromiseState => {
    if (action.type === getType(fetchInterceptorPromiseState)) {
        return { ...state, interceptorState: action.payload }
    } else {
        return state
    }
}
