import React from 'react'

import { CTMoneyType } from './CTMoneySnippet.type'
import { PREFIX } from '../config'
import Icon from '../Icon'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'

const CTMoneySnippet: React.FC<CTMoneyType> = ({ ...props }): JSX.Element => {
    const { balance, title, url, target, allyLabel, isShowIcon } = props

    return (
        <a
            href={url}
            className={`${PREFIX}-pencil-banner__link`}
            target={target}
            rel="noopener noreferrer"
            aria-label={allyLabel}>
            {isShowIcon && <Icon size="md" type="ct-join-triangle-rewards-white" />}
            <SanitizeStringContentWrapper stringContent={title}>
                {memoizedStringContent => (
                    <p
                        className={`${PREFIX}-pencil-banner__name`}
                        dangerouslySetInnerHTML={{ __html: memoizedStringContent }}
                    />
                )}
            </SanitizeStringContentWrapper>
            <span className={`${PREFIX}-pencil-banner__balance`}>{balance}</span>
        </a>
    )
}

export default CTMoneySnippet
