/**
 * @class {ToastService}
 */
class ToastService {
    callbacks: Record<string, unknown>

    /**
     * @constructor
     * @param {Record<string, unknown>} callbacks
     */
    constructor(callbacks: Record<string, unknown>) {
        this.callbacks = callbacks
    }

    /**
     * Set toast callbacks.
     * @param {Record<string, unknown>} toastCallbacks
     */
    setCallback(toastCallbacks: Record<string, unknown>) {
        this.callbacks = { ...this.callbacks, ...toastCallbacks }
    }

    /**
     * get the callbacks.
     * @return {Record<string, unknown>} toast callbacks.
     */
    getCallbacks() {
        return this.callbacks
    }

    /**
     * clear all the set callbacks
     */
    clearAll() {
        this.callbacks = {}
    }

    /**
     * Delete a specific component callback.
     * @param {string} component
     */
    clearComponentCallback(component: string) {
        delete this.callbacks[component]
    }
}
const toastService = new ToastService({})

export default toastService
export { toastService }
