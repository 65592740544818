import { getCaLocale } from './getCaLocale'
import getLanguage from './getLanguage'
import { magicNumber } from './magicNumber'
import { formatInTimeZone } from 'date-fns-tz'

const defaultFormaOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
}
export enum dateFormats {
    YYYY_MM_DD_HH_MM = 'yyyy-MM-dd HH:mm',
}

interface FormatDateComparisionType {
    date: string
    isToday: boolean
    isTomorrow: boolean
}

/**
 * function splits date and returns array of date & time
 * @param {string} date
 * @param {object} options toLocaleTimeString formation options
 * @return {string[]}
 */
export const getFormattedDateTime = (date: string, options = defaultFormaOptions): string[] => {
    return new Date(date).toLocaleTimeString(getCaLocale() || 'en-CA', options).split(',')
}

/**
 * function returns date string with time ahead of date
 * @param {string} date
 * @param {object} options toLocaleTimeString formation options
 * @return {string}
 */

/**
 * function returns date string with time ahead of date
 * @param {string} date
 * @return {string}
 */
export const getFormattedTimeDate = (date: string): string => {
    const formatedDate = getFormattedDateTime(date)
    return `${formatedDate[magicNumber.ONE]}, ${formatedDate[magicNumber.ZERO]}`
}

/**
 * function splits date and returns array of date & time
 * @param {string} date
 * @return {string[]}
 */
export const getFormattedDateTimeWithYear = (date: string): string[] => {
    return new Date(date)
        .toLocaleTimeString(getLanguage() || 'en', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'UTC',
        })
        .split(',')
}

/**
 * function returns object with date and whether date is today/tomorrow
 * @param {string} date
 * @param {string} storeTimeZone
 * @return {object}
 */
export const getFormattedDateWithComparison = (date: string, storeTimeZone: string): FormatDateComparisionType => {
    const inputDate = new Date(date)
    const todaysDate = new Date(formatInTimeZone(new Date(), storeTimeZone, dateFormats.YYYY_MM_DD_HH_MM))
    const isInputDateIsToday = inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
    const isInputDateIsTommorrow =
        inputDate.setHours(0, 0, 0, 0) === todaysDate.setDate(todaysDate.getDate() + magicNumber.ONE)
    const formattedDate = getFormattedDateTime(date)?.[magicNumber.ZERO]

    return {
        date: formattedDate,
        isToday: isInputDateIsToday,
        isTomorrow: isInputDateIsTommorrow,
    }
}

/**
 * Difference in time between estimated time of sending from the store and the customer time in the store time zone.
 * @param {string} estimatedTimeOfSending - estimated time of sending a product
 * @param {string} storeTimeZone - time zone of preferred store
 * @return {number[]} - Returns hours and minutes
 */
export const getEstimatedTimeOfSendingProduct = (estimatedTimeOfSending: string, storeTimeZone: string): number[] => {
    const etaDate: Date = new Date(estimatedTimeOfSending)
    const customerTimeInStoreTimeZone: Date = new Date(
        formatInTimeZone(new Date(), storeTimeZone, dateFormats.YYYY_MM_DD_HH_MM),
    )
    const diffInMinutes: number =
        Math.abs(etaDate.getTime() - customerTimeInStoreTimeZone.getTime()) / magicNumber.SIXTYTHOUSAND
    const hours: number = Math.floor(diffInMinutes / magicNumber.SIXTY)
    const minutes: number = Math.round(diffInMinutes % magicNumber.SIXTY)

    return [hours, minutes]
}

/**
 * check if order in time is overdue
 * @param {string} estimatedTimeOfSending - estimated time of sending a product
 * @param {string} storeTimeZone
 * @return {boolean}
 */
export const checkIfDateIsOverdue = (estimatedTimeOfSending: string, storeTimeZone: string): boolean => {
    const etaDate: Date = new Date(estimatedTimeOfSending)
    const customerTimeInStoreTimeZone: Date = new Date(
        formatInTimeZone(new Date(), storeTimeZone, dateFormats.YYYY_MM_DD_HH_MM),
    )
    const diffInTime: number = etaDate.getTime() - customerTimeInStoreTimeZone.getTime()

    return diffInTime <= magicNumber.ZERO
}

/**
 * Function return current date with time in Date format eg: 'Tue Mar 21 2023 12:48:58 GMT+0530'
 * @return {Date} - Returns current date
 */
export const getCurrentDate = (): Date => {
    const timeElapsed = Date.now()
    return new Date(timeElapsed)
}
