import React from 'react'
import PropTypes from 'prop-types'
import { BalanceScreenProps, CTATypes } from './BalanceScreen.type'
import Button from '../Button'
import CardInput from '../CardInput'
import { PREFIX } from '../config'
import Icon from '../Icon'
import { ButtonSizes, ButtonTypes } from '../Button/Button.types'
import { linkTargetOptions } from '../../globalConstants/global.constant'
import { sendError } from '../../utils/FormatCardNumber/formatCardNumber'
import Heading from '../Heading'

/**
 * Balance Screen
 * @param {BalanceScreenProps} props
 * @return {JSX.Element} returns Balance screen
 */
const BalanceScreen: React.FC<BalanceScreenProps> = ({ ...props }): JSX.Element => {
    const {
        balanceScreenImg,
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        ctaList,
        selectedCardNumber,
        setSelectedCardNumber,
        cardNumberError,
        isValidationCheck,
        headingVariant,
    } = props.data

    const componentClassName = `${PREFIX}-balance-checker-screen`

    /**
     * renders ctaList
     * @return {JSX.Element[]}
     */
    const renderCTA = (): JSX.Element[] => {
        return ctaList?.map((cta: CTATypes, i: number) => {
            return cta.url ? (
                <a
                    href={cta.url}
                    key={i}
                    target={cta.target}
                    aria-label={cta.a11yLabel}
                    className={`${PREFIX}-button ${PREFIX}-button--${cta.type || 'primary'} ${cta.ctaClassName ?? ''}`}>
                    {cta.label}
                    {cta.target === linkTargetOptions.newTarget && <Icon type="ct-north-east" size="lg" />}
                </a>
            ) : (
                <Button
                    type={(cta.type as ButtonTypes) || 'primary'}
                    size={(cta.size as ButtonSizes) || 'large'}
                    onClick={cta.btnFunc}
                    key={i}
                    showSpinner={Boolean(cta.showSpinner)}
                    ariaLabel={cta.a11yLabel}>
                    {cta.label}
                    {cta.target === linkTargetOptions.newTarget && <Icon type="ct-north-east" size="lg" />}
                </Button>
            )
        })
    }

    return (
        <section className={`${componentClassName}`}>
            <div className={`${componentClassName}__content-container`}>
                <img
                    className={`${componentClassName}__img`}
                    src={balanceScreenImg.image}
                    alt={balanceScreenImg.imageAlt}
                />
                <Heading variant={headingVariant} componentClass={`${componentClassName}__title`}>
                    {balanceCheckerTitle}
                </Heading>
                <div
                    className={`${componentClassName}__description`}
                    dangerouslySetInnerHTML={{ __html: balanceCheckerDescription }}
                />
                <CardInput
                    id="card-input"
                    label={cardNumberLabel}
                    cardType="loyalty"
                    value={selectedCardNumber}
                    cardImage={cardImage}
                    cardImageAltText={cardImageAltText}
                    onChange={setSelectedCardNumber}
                    error={sendError(cardNumberError, isValidationCheck)}
                />
            </div>
            <div className={`${componentClassName}__button-container`}>{renderCTA()}</div>
        </section>
    )
}

BalanceScreen.propTypes = {
    data: PropTypes.exact({
        balanceScreenImg: PropTypes.exact({
            image: PropTypes.string,
            imageAlt: PropTypes.string,
        }),
        balanceCheckerTitle: PropTypes.string,
        balanceCheckerDescription: PropTypes.string,
        cardNumberLabel: PropTypes.string,
        cardImage: PropTypes.string,
        cardImageAltText: PropTypes.string,
        ctaList: PropTypes.array,
        setSelectedCardNumber: PropTypes.func,
        selectedCardNumber: PropTypes.string,
        cardNumberError: PropTypes.string,
        isValidationCheck: PropTypes.bool,
    }),
}

export default BalanceScreen
