export const GET_ORDER_DETAILS_DATA_SUCCESS = 'GET_ORDER_DETAILS_DATA_SUCCESS'
export const GET_ORDER_DETAILS_DATA_FAILURE = 'GET_ORDER_DETAILS_DATA_FAILURE'
export const GET_ORDER_DETAILS_EXPIRED = 'GET_ORDER_DETAILS_EXPIRED'
export const GENERATE_RETURN_SLIP_SUCCESS = 'GENERATE_RETURN_SLIP_SUCCESS'
export const GENERATE_RETURN_SLIP_ERROR = 'GENERATE_RETURN_SLIP_ERROR'
export const RESET_RETURN_SLIP_DATA = 'RESET_RETURN_SLIP_DATA'
export const UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_SUCCESS =
    'UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_SUCCESS'
export const UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE =
    'UPDATE_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE'
export const RESET_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE =
    'RESET_BILLING_INFORMATION_ORDER_DETAILS_DATA_FAILURE'
