import React from 'react'
import PropTypes from 'prop-types'
import { SubtitleMenuItemProps } from './CascadingDropdown.type'
import { PREFIX } from '../config'

/**
 * Cascading Dropdown component - Menu Item with Subtitle
 *
 * @param {DropdownProps} props - dropdownTitle,dropdownList,dropdownIcon,
        size,dropdownId,path,selectedItem,type,filterTitle,isFilter,
        closeButtonLabel,isMobile,error,isDatePicker,isDsm,
 * @return {JSX.Element} returns Dropdown component
 */
const SubtitleItem: React.FC<SubtitleMenuItemProps> = ({ ...props }) => {
    const { title, subtitle, ClassName } = props

    return (
        <div className={`${ClassName}__subtitle--block`}>
            <h4 className={`${PREFIX}-body-xl`}>{title}</h4>
            {subtitle && <p className={`${PREFIX}-body-sm`}>{subtitle}</p>}
        </div>
    )
}

SubtitleItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    ClassName: PropTypes.string,
}

export default SubtitleItem
