import React from 'react'

import { PREFIX } from '../config'
import { CardInputProps } from './CardColumn.type'
import Button from '../Button'
import Icon from '../Icon'

const CardColumn: React.FC<CardInputProps> = props => {
    const { componentName, cardDetails, ctaDetails, image } = props

    const rowClasses = `${PREFIX}-xs-flex ${PREFIX}-md-row ${PREFIX}-xs-col-row`

    return (
        <li className={`${componentName} ${rowClasses} between-xs middle-md top-xs`}>
            <div className={`${componentName}__content ${rowClasses} middle-md top-xs`}>
                <img className={`${componentName}__img--wrapper`} src={image?.logo} alt={image?.type} />
                <div className={`${componentName}__content--details ${rowClasses}`}>
                    {cardDetails.map((el, i) => {
                        return (
                            <div
                                className={`${componentName}__content--wrapper ${PREFIX}-xs-flex ${PREFIX}-xs-row ${PREFIX}-md-col-row`}
                                key={i}>
                                <p className={`${componentName}__label`}>{el.label}</p>
                                <p className={`${componentName}__value ${el?.isExpired ? el.isExpired : ''}`}>
                                    {el.value}
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={`${componentName}__cta--wrapper ${PREFIX}-xs-flex between-xs start-md`}>
                {ctaDetails.map((el, i) => {
                    return (
                        <Button id={el.id} key={i} type="tertiary" size="mini" onClick={el.onClickFunction}>
                            {el.icon && (
                                <>
                                    <div className={`${PREFIX}-xs-none ${PREFIX}-sm-block ${PREFIX}-md-block`}>
                                        <Icon type={el.icon} size="lg" />
                                    </div>
                                    <div className={`${PREFIX}-sm-none`}>
                                        <Icon type={el.icon} size="md" />
                                    </div>
                                </>
                            )}
                            <span className={`${PREFIX}-xs-none ${PREFIX}-sm-block ${PREFIX}-md-block`}>
                                {el.label}
                            </span>
                            <span className={`${PREFIX}-sm-none`}>{el.mobileLabel ? el.mobileLabel : el.label}</span>
                        </Button>
                    )
                })}
            </div>
        </li>
    )
}

export default CardColumn
