import { AxiosRequestConfig } from 'axios'
import { getCookieValue } from '@nl/lib'
import { browseOnlyCookieName } from '../../globalConstants'

/**
 * Function to add the browse-mode header key with value ON if browse only mode is enabled
 * or with value OFF if browse only mode is disabled.
 * @param {AxiosRequestConfig} config
 * @param {Record<string, unknown>} domainsConfig
 */
export const modifyHeadersConfigForBrowseOnlyMode = <T extends AxiosRequestConfig>(
    config: T,
    domainsConfig: Record<string, unknown>,
) => {
    const allowedDomains = Object.keys(domainsConfig)
    const isAllowedToModify = allowedDomains.some(domain => config.url?.includes(domain))
    const browseOnlyHeaderName = 'browse-mode'
    const browseOnlyCookie = getCookieValue(browseOnlyCookieName)
    const isBrowseOnlyMode = browseOnlyCookie === 'true'
    const headersPointer = config.headers as Record<string, unknown>
    if (isBrowseOnlyMode && isAllowedToModify) {
        headersPointer[browseOnlyHeaderName] = 'ON'
    } else if (!isBrowseOnlyMode && isAllowedToModify) {
        headersPointer[browseOnlyHeaderName] = 'OFF'
    }
}
