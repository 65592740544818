import { GetEventAnalytics, GetRewardsLabel } from './linkRewardsAnalytics.constant'
import { AnalyticsParamInterface } from './linkRewardsAnalytics.type'

/**
 * This Util is for Link Rewards Analytics layer
 * @param {string} linkType
 * @param {string} stepNumber
 * @param {string} screenName
 * @return {Object}
 */
const linkRewardsAnalytics = (() => {
    const _linkRewardsEvents = (linkType: string, stepNumber: string, screenName: string): AnalyticsParamInterface => {
        return {
            event: GetEventAnalytics.interaction,
            eventParameters: {
                action: linkType,
                category: GetEventAnalytics.account,
                label: GetRewardsLabel.step + ' ' + stepNumber + ' : ' + screenName + ' ' + GetRewardsLabel.completed,
            },
        }
    }
    const _linkRewardsSuccess = (linkType: string): AnalyticsParamInterface => {
        return {
            event: GetEventAnalytics.interaction,
            eventParameters: {
                action: linkType,
                category: GetEventAnalytics.account,
                label: GetRewardsLabel.rewardLinked,
            },
        }
    }

    /**
     * Error Instances:
     * - mastercard information cannot be found
     * - call customer 'service'
     * - rewards card deactivated
     * - already enrolled
     * - credit card and rewards card
     * - additional questions
     * - above $100
     * - age of majority
     * - information does not match records
     * @param {string} linkType
     * @param {string} errorType
     * @return {Object}
     */
    const _linkRewardsErrors = (linkType: string, errorType: string): AnalyticsParamInterface => {
        return {
            event: GetEventAnalytics.interaction,
            eventParameters: {
                action: linkType,
                category: GetEventAnalytics.account,
                label: GetRewardsLabel.error + ' - ' + errorType,
            },
        }
    }

    return {
        linkRewardsEvents: (linkType: string, stepNumber: string, screenName: string) => {
            return _linkRewardsEvents(linkType, stepNumber, screenName)
        },
        linkRewardsSuccess: (linkType: string) => {
            return _linkRewardsSuccess(linkType)
        },
        linkRewardsErrors: (linkType: string, errorType: string) => {
            return _linkRewardsErrors(linkType, errorType)
        },
    }
})()

export { linkRewardsAnalytics }
export default linkRewardsAnalytics
