import { subscriptionStatus } from '../globalConstants/global.constant'

const isTriangleSelectMember = (tsSubscriptionStatus?: string): boolean => {
    return tsSubscriptionStatus === subscriptionStatus.active
}

const wasTriangleSelectMember = (tsSubscriptionStatus?: string): boolean => {
    return tsSubscriptionStatus === subscriptionStatus.expired
}

export default isTriangleSelectMember
export { isTriangleSelectMember, wasTriangleSelectMember }
