import { Dispatch } from 'redux'

import { RequestBodyType } from '../utils/httpClient'
import {
    linkLoyaltyCardSuccessAction,
    linkLoyaltyCardErrorAction,
    linkLoyaltyCheckSuccessAction,
    linkLoyaltyCheckErrorAction,
    clearLinkLoyaltyCheckResponseAction,
    clearLinkLoyaltyCheckErrorAction,
    linkLoyaltyValidateSuccessAction,
    linkLoyaltyValidateErrorAction,
    clearLinkLoyaltyValidateResponseAction,
    clearLinkLoyaltyValidateErrorAction,
    linkLoyaltyProfileSuccessAction,
    linkLoyaltyProfileErrorAction,
    clearLinkLoyaltyProfileResponseAction,
    clearLinkLoyaltyProfileErrorAction,
    clearLinkLoyaltyCardResponseAction,
    clearLinkLoyaltyCardErrorAction,
    saveLoyaltyCardAction,
    saveLinkTypeAction,
} from '../actionCreators/linkLoyaltyCard.actionCreators'
import {
    LinkLoyaltyCardType,
    LinkLoyaltyCardRequest,
    LinkLoyaltyCheckRequest,
    LinkLoyaltyCheckResponse,
    LinkLoyaltyValidateRequest,
    LinkLoyaltyValidateResponse,
    LinkLoyaltyProfileResponse,
    LinkLoyaltyCardErrorResponse,
} from '../models/linkLoyaltyCard.interface'
import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import { AxiosResponse } from 'axios'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import BaseService from '../../services/base.service'

const environment = getEnvironment()
const httpClient = getHttpClient()
export const linkLoyaltyCard =
    (requestPayload: LinkLoyaltyCardRequest) =>
    (dispatch: Dispatch): Promise<AxiosResponse> => {
        // const baseEndPoint = API_ENDPOINTS.cdsLoyaltyLinkCard

        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string

        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }

        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsLoyaltyLinkCard}`

        return new Promise((resolve, reject) => {
            httpClient
                .apiPost(url, requestPayload.loyaltyCard, headers, true)
                .then(data => {
                    dispatch(linkLoyaltyCardSuccessAction(data.data as LinkLoyaltyCardType))
                    resolve(data)
                })
                .catch((error: Record<string, Record<string, unknown>>) => {
                    const errorResponse = (error.response ? error.response.data : error) as LinkLoyaltyCardErrorResponse
                    dispatch(linkLoyaltyCardErrorAction(errorResponse))
                    reject(errorResponse)
                })
        })
    }

export const linkLoyaltyCheck =
    (requestPayload: LinkLoyaltyCheckRequest) =>
    (dispatch: Dispatch): Promise<AxiosResponse> => {
        // const baseEndPoint = API_ENDPOINTS.cdsLoyaltyLinkCheck

        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string

        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }

        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsLoyaltyLinkCheck}`

        return new Promise((resolve, reject) => {
            httpClient
                .apiPost(url, requestPayload as unknown as RequestBodyType, headers, true)
                .then(data => {
                    dispatch(linkLoyaltyCheckSuccessAction(data.data as LinkLoyaltyCheckResponse))
                    resolve(data)
                })
                .catch((error: Record<string, Record<string, unknown>>) => {
                    const errorResponse = (error.response ? error.response.data : error) as LinkLoyaltyCardErrorResponse
                    dispatch(linkLoyaltyCheckErrorAction(errorResponse))
                    reject(errorResponse)
                })
        })
    }

export const linkLoyaltyValidate =
    (requestPayload: LinkLoyaltyValidateRequest) =>
    (dispatch: Dispatch): Promise<AxiosResponse> => {
        // const baseEndPoint = API_ENDPOINTS.cdsLoyaltyLinkValidate

        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string

        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }

        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsLoyaltyLinkValidate}`

        return new Promise((resolve, reject) => {
            httpClient
                .apiPost(url, requestPayload as unknown as RequestBodyType, headers, true)
                .then(data => {
                    dispatch(linkLoyaltyValidateSuccessAction(data.data as LinkLoyaltyValidateResponse))
                    resolve(data)
                })
                .catch((error: Record<string, Record<string, unknown>>) => {
                    const errorResponse = (error.response ? error.response.data : error) as LinkLoyaltyCardErrorResponse
                    dispatch(linkLoyaltyValidateErrorAction(errorResponse))
                    reject(errorResponse)
                })
        })
    }

export const linkLoyaltyProfile =
    (requestPayload: string) =>
    (dispatch: Dispatch): Promise<AxiosResponse> => {
        // const baseEndPoint = API_ENDPOINTS.cdsLoyaltyLinkProfile

        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string

        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }

        const profilePayload = {
            lmsProfileId: requestPayload,
            lang: BaseService.language,
        }

        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsLoyaltyLinkProfile}`

        return new Promise((resolve, reject) => {
            httpClient
                .apiGet(url, profilePayload as unknown as RequestBodyType, headers, true)
                .then(data => {
                    dispatch(linkLoyaltyProfileSuccessAction(data.data as LinkLoyaltyProfileResponse))
                    resolve(data)
                })
                .catch((error: Record<string, Record<string, unknown>>) => {
                    const errorResponse = (error.response ? error.response.data : error) as LinkLoyaltyCardErrorResponse
                    dispatch(linkLoyaltyProfileErrorAction(errorResponse))
                    reject(errorResponse)
                })
        })
    }
export const saveLinkType =
    (requestPayload: string) =>
    (dispatch: Dispatch): void => {
        dispatch(saveLinkTypeAction(requestPayload))
    }

export const saveLoyaltyCardNumber =
    (requestPayload: string) =>
    (dispatch: Dispatch): void => {
        dispatch(saveLoyaltyCardAction(requestPayload))
    }

export const clearLinkLoyaltyCardResponse =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyCardResponseAction())
    }

export const clearLinkLoyaltyCardError =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyCardErrorAction())
    }

export const clearLinkLoyaltyProfileResponse =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyProfileResponseAction())
    }

export const clearLinkLoyaltyProfileError =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyProfileErrorAction())
    }

export const clearLinkLoyaltyValidateResponse =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyValidateResponseAction())
    }

export const clearLinkLoyaltyValidateError =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyValidateErrorAction())
    }

export const clearLinkLoyaltyCheckResponse =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyCheckResponseAction())
    }

export const clearLinkLoyaltyCheckError =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkLoyaltyCheckErrorAction())
    }
