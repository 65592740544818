import { ProductResponseData } from '../../redux/models/product.interface'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import PageHelper from './page.helper'

/**
 * @class {PdpPageHelper}
 */
export class PdpPageHelper extends PageHelper {
    /**
     * constructor which get and save categories
     * @param {ProductResponseData} props
     */
    constructor(props: ProductResponseData) {
        super()
        this.categories = this.churnCategories(props?.breadcrumbList)
    }

    /**
     * This function get categories from breadcrumbList
     * @param {BreadcrumbLinkData[]} breadcrumbList
     * @return {string[]} categories
     */
    churnCategories(breadcrumbList?: BreadcrumbLinkData[]): string[] {
        const categoriesList: string[] = []
        breadcrumbList?.forEach((breadcrumbObj: BreadcrumbLinkData) => {
            !!breadcrumbObj?.categoryId && categoriesList.push(breadcrumbObj?.categoryId)
        })
        return categoriesList
    }
}

export default PdpPageHelper
