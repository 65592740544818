import { PostalCodeData } from '../redux/models/cart.interface'
import { postalCodeConstants } from './postalCodeConstants'
import { API_ENDPOINTS } from '../environments/apiConfig'

/**
 * function to get the endpoints for entered postal code value
 * TO-DO: to be removed completely during actual API integration
 * @param  {string} code - postal code
 * @param  {Object} postalCodeData - json of postal code list
 * @return {string}
 */
const getPostalCodeMock = (code: string, postalCodeData: PostalCodeData): string => {
    const pcError = getPostalCodeError(code, postalCodeData)
    let apiEndpoint = ''
    switch (pcError) {
        case postalCodeConstants.invalidPC: {
            apiEndpoint = API_ENDPOINTS.invalidPC
            break
        }
        case postalCodeConstants.outOfRangePC: {
            apiEndpoint = API_ENDPOINTS.outOfRangePC
            break
        }
        case postalCodeConstants.errorPC: {
            apiEndpoint = API_ENDPOINTS.errorPC
            break
        }
        case postalCodeConstants.validPC: {
            apiEndpoint = API_ENDPOINTS.validPC
            break
        }
        default: {
            apiEndpoint = ''
            break
        }
    }
    return apiEndpoint
}

/**
 * function to get the error for entered postal code value
 *  TO-DO: to be removed completely during actual API integration
 * @param  {string} code - postal code
 * @param  {Object} postalCodeData - json of postal code list
 * @return {string}
 */
const getPostalCodeError = (code: string, postalCodeData: PostalCodeData): string => {
    for (const [key, value] of Object.entries(postalCodeData)) {
        const list = value as string[]
        const index = list.filter(
            (val: string) => val.replace(' ', '').toUpperCase() === code.replace(' ', '').toUpperCase(),
        )
        if (index.length) {
            return key
        }
    }
    return ''
}

export default getPostalCodeMock
