import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/secondaryNavigation.actionCreators'
import { setMegaNavLinks } from '../actionCreators/secondaryNavigation.actionCreators'
import { InitialCategoryPayloadType } from '../models/secondaryNavigation.interface'

type Action = ActionType<typeof actions>

export const initialState = []
/**
 * update secondaryNavigation Values
 * @param { Object } state set to initial state by default
 * @param { Action } action action called
 * @return {Object}
 *
 */
export const secondaryNavigationReducer = (state = initialState, action: Action): InitialCategoryPayloadType[] => {
    if (action.type === getType(setMegaNavLinks)) {
        return [...action.payload]
    }
    return state
}
