import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/triangleTransactionHistory.actionCreators'
import {
    fetchTriangleTransactionSuccessAction,
    fetchTriangleTransactionErrorAction,
} from '../actionCreators/triangleTransactionHistory.actionCreators'
import { TriangleTransactionResponse, ErrorResponse } from '../models/triangleTransactionHistory.interface'

type Action = ActionType<typeof actions>

export interface TriangleTransactionHistoryState {
    fetchTriangleTransaction: TriangleTransactionResponse | null
    ErrorResponse: ErrorResponse | null
}

export const initialState: TriangleTransactionHistoryState = {
    fetchTriangleTransaction: {} as TriangleTransactionResponse,
    ErrorResponse: {} as ErrorResponse,
}

export const triangleTransactionHistoryReducer = (
    state: TriangleTransactionHistoryState = initialState,
    action: Action,
): TriangleTransactionHistoryState => {
    switch (action.type) {
        case getType(fetchTriangleTransactionSuccessAction): {
            return { ...state, fetchTriangleTransaction: action.payload, ErrorResponse: null }
        }
        case getType(fetchTriangleTransactionErrorAction): {
            return { ...state, ErrorResponse: action.payload, fetchTriangleTransaction: null }
        }
        default:
            return state
    }
}
