import React from 'react'

import { PREFIX } from '../config'
import ProductImageComponent from '../ProductCartItem/ProductImageComponent'
import ProductItemInfo from '../ProductCartItem/ProductItemInfo'
import Button from '../Button/Button'
import { UnfinishedPackageCardProps } from './UnfinishedPackageCard.type'
import { BREAKPOINTS } from '../../components/config'
import Icon from '../Icon'

/* UnfinishedPackageCard component
 * @param {UnfinishedPackageCardProps} props
 * @return {JSX.Element} returns
 */

const UnfinishedPackageCard: React.FC<UnfinishedPackageCardProps> = ({
    ...props
}: UnfinishedPackageCardProps): JSX.Element => {
    const { images, name } = props.productData || {}
    const componentName = `${PREFIX}-unfinished-package`
    const cardClickableClass = props.servicesIcon ? `${componentName}-card-clickable` : ''
    const isMobile = window.innerWidth <= BREAKPOINTS.mobileMaxWidth

    /**
     * This function is called when complete package button is clicked
     * @return {JSX.Element}
     */
    const addToPackageButton = (): JSX.Element => {
        return (
            <div className={`${componentName}-button`}>
                <Button
                    id="add-to-package-button"
                    type="secondary"
                    size={isMobile ? 'medium' : 'small'}
                    onClick={() =>
                        props.completePackage(props.productData.productWheelType, props.productData.packageId)
                    }>
                    {props.createPackageTitle}
                </Button>
            </div>
        )
    }

    /**
     * This function is called when start a new package card is clicked
     * @return {void}
     */
    const startNewPackage = (): void => {
        props.servicesIcon && props.createNewPackage()
    }

    return (
        <div
            data-testid="unfinishedPackage"
            className={`${componentName}-card ${cardClickableClass}`}
            onClick={() => startNewPackage()}
            onKeyPress={() => startNewPackage()}
            role={props.servicesIcon ? 'button' : ''}
            tabIndex={0}>
            {!props.servicesIcon ? (
                <>
                    <div className={`${componentName}__product-detail`}>
                        <ProductImageComponent images={images} name={name} isUnfinishedPackage={true} />
                        <ProductItemInfo
                            productData={props.productData}
                            cartModal={true}
                            totalLabel={props.totalLabel}
                            mmQtyLabel={props.mmQtyLabel}
                            isUnfinishedPackage={true}
                            language={props.language}
                            productSizeLabel={`${props.productSizeLabel}:`}
                            attributeOffsetLabel={props.attributeOffsetLabel}
                            specificationsFromProps={props.specificationsFromProps}
                            a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                            a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        />
                    </div>
                    {addToPackageButton()}
                </>
            ) : (
                <div className={`${componentName}__icon-container`}>
                    <div className={`${componentName}__start-package-icon`}>
                        <Icon type={props.servicesIcon} size="lg" />
                    </div>
                    <div className={`${componentName}-new-package`}>{props.startNewPackageCTA}</div>
                </div>
            )}
        </div>
    )
}

export default UnfinishedPackageCard
