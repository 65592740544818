import { offerConstants } from '../components/OfferCard/OfferCard.constant'
import { AkamaiProp } from '../components/OfferCard/OfferCard.type'

/**
 * Get Akamai policy result
 * @param {string} returnPolicy
 * @param {string} fallbackOfferImageUrl
 * @param {string} fallbackImageForBrandLogo
 * @param {string} fallbackImageForBrandLogoAltText
 * @return {AkamaiProp}
 */
export const getAkamaiPolicy = (
    returnPolicy: (policyType: string) => string,
    fallbackOfferImageUrl?: string,
    fallbackImageForBrandLogo?: string,
    fallbackImageForBrandLogoAltText?: string,
): AkamaiProp => {
    return {
        offersCardImgComponentName: offerConstants.offersCardImgComponentName,
        bannerLogoImgComponentName: offerConstants.bannerLogoImgComponentName,
        returnPolicy: returnPolicy,
        fallbackOfferImageUrl: fallbackOfferImageUrl,
        fallbackImageForBrandLogo: fallbackImageForBrandLogo,
        fallbackImageForBrandLogoAltText: fallbackImageForBrandLogoAltText,
    }
}
