import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/ctMoneyBalance.actionCreators'
import {
    fetchCTMoneyBalanceSuccessAction,
    fetchCTMoneyBalanceErrorAction,
    resetCTMoneyBalanceAction,
} from '../actionCreators/ctMoneyBalance.actionCreators'
import { CTMoneyBalanceResponse, ErrorResponse } from '../models/ctMoneyBalance.interface'

type Action = ActionType<typeof actions>

export interface CTMoneyBalanceState {
    ctMoneyBalanceSuccess: CTMoneyBalanceResponse | null
    errorResponse: ErrorResponse | null
}

export const initialState: CTMoneyBalanceState = {
    ctMoneyBalanceSuccess: {} as CTMoneyBalanceResponse,
    errorResponse: {} as ErrorResponse,
}

export const ctMoneyBalanceReducer = (
    state: CTMoneyBalanceState = initialState,
    action: Action,
): CTMoneyBalanceState => {
    switch (action.type) {
        case getType(fetchCTMoneyBalanceSuccessAction): {
            return { ...state, ctMoneyBalanceSuccess: action.payload, errorResponse: null }
        }
        case getType(fetchCTMoneyBalanceErrorAction): {
            return { ...state, errorResponse: action.payload, ctMoneyBalanceSuccess: null }
        }
        case getType(resetCTMoneyBalanceAction): {
            return { ...state, ctMoneyBalanceSuccess: {} as CTMoneyBalanceResponse, errorResponse: null }
        }
        default:
            return state
    }
}
