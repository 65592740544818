import React from 'react'

import { PREFIX } from '../config'
import Button from '../Button'
import Icon from '../Icon'
import { InfoMessageProps } from './InfoMessage.type'
import { isArrayNotEmpty, magicNumber } from '../../utils'

/**
 * Info Message component
 * @param {InfoMessageProps} props
 * @return {JSX.Element} returns Info Message Component
 */
const InfoMessage: React.FC<InfoMessageProps> = ({ ...props }): JSX.Element | null => {
    const { fitmentmessage, ctas, icon, type, fitmentTitle } = props
    const modifierClass = !isArrayNotEmpty(ctas) ? `${PREFIX}-infomessage--no-margin` : ''

    return fitmentmessage ? (
        <div role="alert" data-testid="infomessage" className={`${PREFIX}-infomessage ${PREFIX}-infomessage--${type}`}>
            <div className={`${PREFIX}-infomessage__fitment ${modifierClass}`}>
                <div className={`${PREFIX}-infomessage__fitment-icon`}>{icon && <Icon type={icon} size="lg" />}</div>
                <div className={`${PREFIX}-infomessage__fitment-text`}>
                    {fitmentTitle && <h4 className={`${PREFIX}-infomessage__fitment-title`}>{fitmentTitle}</h4>}
                    {fitmentmessage}
                </div>
            </div>
            <div className={`${PREFIX}-infomessage__button`}>
                {isArrayNotEmpty(ctas) &&
                    ctas.map((cta, index: number) => (
                        <Button
                            data-testid={cta.ctaId ? cta.ctaId : 'infomessage-changevehicle-button'}
                            type="tertiary"
                            label={cta.text}
                            key={`${index + magicNumber.ONE}`}
                            onClick={cta.changeHandler}></Button>
                    ))}
            </div>
        </div>
    ) : null
}
export default InfoMessage
