import { AnalyticsRecordInterface, BaseProvider, PromotionRecord } from '../providers/provider.type'

/**
 * Custom event name to push the analytics data.
 */
export enum TriggerEventType {
    PREVIEW = 'Preview',
    CLICK = 'Click',
}

export interface AnalyticsComponentInterface {
    domElement: HTMLElement
    triggerEvents: TriggerEventType[]
    // TODO: generic implementation for any
    analyticsData: PromotionRecord
    push: (data: AnalyticsRecordInterface) => void
    analyticsProvider: BaseProvider
    registerListeners: () => void
}

export interface ReactComponentInterface {
    analyticsProvider: BaseProvider
    push: (data: AnalyticsRecordInterface) => void
}
