import { AxiosPromise } from 'axios'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Get order cancellation reasons
 */
class GetOrderCancellationReasons extends BaseService {
    /**
     * Gets the list of reasons from API
     * @param {string} lang
     * @return  {URL} url
     */
    getOrderCancellationReasons(lang: string): AxiosPromise<any> {
        const url: URL = this.createGetCancellationOrderReasonsUrl('getCancelOrderReasons')
        return httpClient.apiGet(url.toString(), { lang })
    }

    /**
     * Creates the url for the service
     * @param {string} endpoint
     * @return {URL} URL
     */
    createGetCancellationOrderReasonsUrl(endpoint: string): URL {
        return new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS[endpoint]}`)
    }
}

const getOrderCancellationReasons = new GetOrderCancellationReasons()

export { getOrderCancellationReasons }
export default getOrderCancellationReasons
