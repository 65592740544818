import { magicNumber } from '../magicNumber'
import { PaymentUnitType } from './isCreditCardExpired.type'

/**
 *
 * function to check card expired or not
 * @param {PaymentUnitType} card - payment info
 * @param {Date} referenceDate - date to compare the card's expiry to
 * @return {boolean} - returns true if card is expired, false if not
 */
export const isCreditCardExpired = (card: PaymentUnitType, referenceDate: Date): boolean => {
    const yearLength = card.validToYear.length
    const year = +card.validToYear
    const month = +card.validToMonth
    const currentYear =
        yearLength === magicNumber.TWO ? referenceDate.getFullYear() % magicNumber.HUNDRED : referenceDate.getFullYear()
    const currentMonth = referenceDate.getMonth() + magicNumber.ONE
    if (year > currentYear) {
        return false
    } else if (year === currentYear && month >= currentMonth) {
        return false
    } else {
        return true
    }
}
