import { allWhitespace } from '../globalConstants/regexPatterns.constant'

/**
 * function to remove blank space  from string
 * @param {string} dataString
 * @return {string}
 */
export const removeBlankSpaceFromString = (dataString: string): string => {
    return dataString.replace(allWhitespace, '')
}
