import { CartOrderEntries, ProductCodeData } from '../../redux/models/cart.interface'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'
import { WishlistProduct } from '../../redux/models/wishlist.interface'

export const retrieveProductCode = <T extends ProductCodeData>(
    items: Array<T> | undefined,
    shouldGetFromBaseProduct = false,
): string => {
    return (
        items
            ?.map((item: CartOrderEntries | WishlistProduct | ProductDataTypeObj) =>
                shouldGetFromBaseProduct ? (item as CartOrderEntries | WishlistProduct).baseProduct : item.code,
            )
            .join(';') || ''
    )
}
