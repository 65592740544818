import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { SpinnerProps } from './Spinner.types'

const Spinner: React.FC<SpinnerProps> = ({ ...props }): JSX.Element => {
    const { spinnerColor, spinnerA11Label } = props
    const borderColor = spinnerColor ? `${spinnerColor} ${spinnerColor} ${spinnerColor} transparent` : ''

    return (
        <div
            data-testid="spinner"
            aria-label={spinnerA11Label}
            className={`${PREFIX}-spinner${spinnerColor ? '-custom' : ''}`}
            style={{ borderColor: borderColor }}
        />
    )
}

Spinner.propTypes = {
    spinnerColor: PropTypes.string,
}

export default Spinner
