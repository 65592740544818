import React from 'react'
import PropTypes from 'prop-types'
import { TimePickerProps } from './TimePicker.type'
import { PREFIX } from '../config'
import { getTimeFormat } from './TimePicker.helper'

/**
 * TimePicker component
 * @param {TimePickerProps} props
 * @return {JSX.Element}
 */
const TimePicker: React.FC<TimePickerProps> = ({ ...props }) => {
    const {
        selectedTime,
        onChangeTime,
        morningSlots,
        afternoonSlots,
        eveningSlots,
        morningTitle,
        afternoonTitle,
        eveningTitle,
        timesAvailableTitle,
        timeSlotHoverText,
        formattedCalendarValueForTimePicker,
    } = props

    /**
     * This function returns available time slots. Morning, Afternoon and Evening slots.
     * @param { string } slotTitle
     * @param { string[] } slots
     * @return { JSX.Element | null }
     */
    const returnAvailableTimeSlots = (slotTitle: string, slots: string[]): JSX.Element | null => {
        if (slots.length) {
            return (
                <div className={`${PREFIX}-time-picker__slot-wise`}>
                    <h4 className={`${PREFIX}-time-picker__slot-title`}>{slotTitle}</h4>
                    <div className={`${PREFIX}-row`}>
                        {slots.map(slot => {
                            const isSlotMatchedWithSelectedTime = slot === selectedTime
                            const selectedModifier = isSlotMatchedWithSelectedTime
                                ? `${PREFIX}-time-picker__slot-available--selected`
                                : ''
                            return (
                                <button
                                    key={slot}
                                    className={`${PREFIX}-time-picker__slot-available ${selectedModifier}`}
                                    onClick={() => onChangeTime(slot)}
                                    data-testid={`${slot}__button`}
                                    aria-label={slot}
                                    title={isSlotMatchedWithSelectedTime ? '' : timeSlotHoverText}>
                                    {getTimeFormat(slot)}
                                    <div className={`${PREFIX}-time-picker__available-effect`}></div>
                                </button>
                            )
                        })}
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div className={`${PREFIX}-time-picker`}>
            <h4 className={`${PREFIX}-time-picker__times-available-title`}>
                {`${timesAvailableTitle} ${
                    formattedCalendarValueForTimePicker ? formattedCalendarValueForTimePicker : ''
                }`}
            </h4>
            {returnAvailableTimeSlots(morningTitle, morningSlots)}
            {returnAvailableTimeSlots(afternoonTitle, afternoonSlots)}
            {returnAvailableTimeSlots(eveningTitle, eveningSlots)}
        </div>
    )
}

export default TimePicker

TimePicker.propTypes = {
    selectedTime: PropTypes.string,
    onChangeTime: PropTypes.func.isRequired,
    morningTitle: PropTypes.string.isRequired,
    afternoonTitle: PropTypes.string.isRequired,
    eveningTitle: PropTypes.string.isRequired,
    timesAvailableTitle: PropTypes.string.isRequired,
    timeSlotHoverText: PropTypes.string.isRequired,
    formattedCalendarValueForTimePicker: PropTypes.string,
}
