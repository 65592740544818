import { DropdownOptions, DropdownType } from '../models/vehicleDropdowns.types'
import { VehicleDropdownLocalization } from '../types/vehiclesData/vehicleActions.types'

/**
 * Update dropdown with localized text from the action setDropdownAttributeLocalization
 * @param {DropdownType[]} stateDropdowns - the current dropdowns.
 * @param {VehicleDropdownLocalization} payload - localized action payload with text by option Id.
 * @return {DropdownType[]} get all dropdown where localized dropdown by payload.
 */
export const generateLocalizedDropdowns = (
    stateDropdowns: DropdownType[],
    payload: VehicleDropdownLocalization,
): DropdownType[] => {
    const dropdownToLocalize = stateDropdowns.find(dropdown => payload.id === dropdown.id)
    if (dropdownToLocalize) {
        dropdownToLocalize.options = payload.options.map(option => ({
            ...option,
            selected: isSelected(dropdownToLocalize.options, String(option.id)),
        }))
    }
    return [...stateDropdowns]
}

const isSelected = (options: DropdownOptions[], id: string) =>
    Boolean(options.find(item => String(item.id) === id)?.selected)
