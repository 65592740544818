import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import GigyaService from '../../services/gigyaService/gigya.service'

import appCacheService from '../../utils/appCacheService'
import { GigyaJWTResp } from '../../utils/gigya.type'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { getGigyaJwtTokenExpirationStatus, parseGigyaJwtToken } from './GigyaTokenRefresh.helper'
import { oneMinuteToTokenExpiration, tokenExpirationCheckInterval } from './GigyaTokenRefresh.constant'

const gigyaService = new GigyaService()
const JwtTokenHelper: React.FC = () => {
    const userProfileData = useSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const [intervalVal, setIntervalVal] = useState<NodeJS.Timeout | string>('')
    const isAuth = isAuthFlowExecuted && checkDataLength(userProfileData)

    /**
     * @method updateJwtBeforeExpire uses to check if expire time is 1 minute or less than that and refreshing token in each 5 seconds.
     */
    const updateJwtBeforeExpire = useCallback(() => {
        const gigyaJWTToken = appCacheService.gigyaJWTToken.get()
        const parsedData = parseGigyaJwtToken(gigyaJWTToken)

        if (checkDataLength(parsedData)) {
            const expTime = parsedData.exp as number

            const currInterval = setInterval(() => {
                // Gigya token is expires after 5 mins. So here we are refreshing token on 4th minute. e.g exprire time 10:10:00, refresh call will be at 10:09:00
                const isTokenAboutToExpire = getGigyaJwtTokenExpirationStatus(expTime, oneMinuteToTokenExpiration)

                if (isTokenAboutToExpire) {
                    clearTimeInterval(currInterval)
                    gigyaService
                        .jwtToken()
                        .then((resp: GigyaJWTResp) => {
                            appCacheService.gigyaJWTToken.set(resp.id_token)
                            updateJwtBeforeExpire()
                        })
                        .catch(err => {
                            // TODO: Need to either navigate user to login screen or show the partial auth modal.
                            console.error(err)
                        })
                }
            }, tokenExpirationCheckInterval)
            setIntervalVal(currInterval)
        }
    }, [setIntervalVal])

    useEffect(() => {
        if (isAuth) {
            updateJwtBeforeExpire()
        }
    }, [isAuth, updateJwtBeforeExpire])

    useEffect(() => {
        return () => {
            if (intervalVal) {
                clearTimeInterval(intervalVal)
            }
        }
    }, [intervalVal])

    /**
     * Function uses to clear the timer.
     * @param {NodeJS.Timeout} intervalValue
     * @return {void}
     */
    const clearTimeInterval = (intervalValue: NodeJS.Timeout): void => {
        if (intervalValue) {
            clearInterval(intervalValue)
        }
    }

    return null
}

export default JwtTokenHelper
export { JwtTokenHelper }
