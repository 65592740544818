import React from 'react'
import { PREFIX } from '../config'

/**
 * Overlay component
 * @return {JSX.Element} returns Overlay component
 */
const Overlay: React.FC = (): JSX.Element => {
    return <div className={`${PREFIX}-overlay`} data-testid="overlay" />
}

export default Overlay
