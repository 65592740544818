import React, { useState, useRef, KeyboardEvent } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX } from '../config'
import { CardInputProps, CardType } from './CardInput.type'
import { cardTypes, eventKeys } from './CardInput.constant'

/**
 * CardInput component
 * @param {CardInputProps} props
 * @return {JSX.Element} returns CardInput Component
 */
const CardInput: React.FC<CardInputProps> = props => {
    const { id, label, error, cardType, path, value, onChange, errorIcon, cardImage, cardImageAltText, errorIconSize } =
        props

    // props taken from constant of cardtypes
    const [selectedCardType] = cardTypes.filter(item => item.type === cardType)
    const { pattern, readOnlyValue, placeholder, maxLength } = selectedCardType

    const [isFocused, setIsFocused] = useState(false)
    const cardInputRef = useRef<HTMLInputElement>(null)
    const componentClassName = `${PREFIX}-cardinput`
    const errorClass = error ? `${componentClassName}__container--error` : ''
    const focusClass = isFocused ? `${componentClassName}__container--focus` : ''

    /**
     * function to check entered text is numeric
     * @param {KeyboardEvent<HTMLInputElement>} e event object
     */
    const checkIsNumeric = (e: KeyboardEvent<HTMLInputElement>) => {
        if (!/^\d*$/.test(e.key) && e.key !== eventKeys.enter) {
            e.preventDefault()
        }
    }

    /**
     * function to format card number
     *
     * @param {string} cardNumber
     * @return {string} cardNumber
     */
    const formatCardNumber = (cardNumber: string): string => {
        if (cardType === CardType.master || cardType === CardType.linkmaster) return cardNumber

        // for loyalty card
        const execResult = pattern.exec(cardNumber.split(' ').join(''))
        if (execResult) {
            const rangeStart = 1
            const rangeEnd = 3
            return execResult
                .splice(rangeStart, rangeEnd)
                .filter(x => x)
                .join(' ')
        }
        return cardNumber
    }

    /**
     * event to fire on CardInputChange
     *
     * @param {string} changedValue
     */
    const onCardInputChange = (changedValue: string): void => {
        cardInputRef.current.value = formatCardNumber(changedValue)
        onChange(cardInputRef.current.value)
    }

    /**
     * event to fire on onCardInputKeyPress
     *
     * @param {KeyboardEvent<HTMLInputElement>} e
     */
    const onCardInputKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
        const inputValue = (e.target as HTMLTextAreaElement).value
        checkIsNumeric(e)
        if (inputValue) {
            const valueLength = inputValue.split(' ').join('').length
            if (valueLength > maxLength) {
                e.preventDefault()
            }
        }
    }

    return (
        <div className={`${componentClassName}`}>
            <div className={`${componentClassName}__container ${focusClass} ${errorClass}`}>
                <label className={`${componentClassName}__label`} htmlFor={id}>
                    {label}
                </label>
                <label className={`${componentClassName}__disabled-label`}>{readOnlyValue}</label>
                <input
                    id={id}
                    data-testid={id}
                    ref={cardInputRef}
                    type="tel"
                    className={`${componentClassName}__input`}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => onCardInputChange(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onKeyUp={e => onCardInputKeyPress(e)}
                    maxLength={maxLength}
                />
                {cardImage && (
                    <img className={`${componentClassName}__card-img`} src={cardImage} alt={cardImageAltText} />
                )}
            </div>
            {error && (
                <div className={`${componentClassName}__error`} role="alert">
                    <Icon type={errorIcon} size={errorIconSize ? errorIconSize : 'sm'} path={path} />
                    <span className={`${componentClassName}__error-text`}>{error}</span>
                </div>
            )}
        </div>
    )
}

CardInput.defaultProps = {
    cardType: 'loyalty',
    errorIcon: 'ct-warning',
}

CardInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    cardType: PropTypes.string,
    error: PropTypes.string,
    path: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    errorIcon: PropTypes.string,
    cardImage: PropTypes.string,
    cardImageAltText: PropTypes.string,
}

export default CardInput
