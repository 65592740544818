import { Dispatch } from 'react'

import { getOrderCancellationReasons } from '../../services/getCancelOrderReasons/getCancelOrderReasons.service'
import {
    orderCancellationReasonsFailure,
    orderCancellationReasonsSuccess,
} from '../actionCreators/cancelOrderReasons.actionCreators'
import { CancellationReasons } from '../models/getOrderCancellationReasons.interface'
import { AxiosResponse } from 'axios'

/**
 * Function to get cancellation reasons
 * @param {string} lang
 * @param {Function} setShowCancelOrder
 * @return {Promise}
 */
export const getOrderCancellationReasonsService =
    (lang: string) =>
    (dispatch: Dispatch<any>): void => {
        getOrderCancellationReasons
            .getOrderCancellationReasons(lang)
            .then((response: AxiosResponse<CancellationReasons>) => {
                dispatch(orderCancellationReasonsSuccess(response.data?.reasons || []))
            })
            .catch(err => {
                dispatch(orderCancellationReasonsFailure(err))
            })
    }
