import React, { useState } from 'react'
import { ToggleTabsPropTypes } from './ToggleTabs.type'
import { PREFIX } from '../config'
import Icon from '../Icon'
import Button from '../Button'

const ToggleTabs: React.FC<ToggleTabsPropTypes> = ({ ...props }): JSX.Element => {
    const [activeBtn, setActiveBtn] = useState(false)
    const firstBtnSelectedClassName = !activeBtn ? `${PREFIX}-first-btn--selected` : `${PREFIX}-first-btn--unselected`
    const secondBtnSelectedClassName = activeBtn ? `${PREFIX}-second-btn--selected` : `${PREFIX}-second-btn--unselected`

    return (
        <div className={`${PREFIX}-toggle-btn`}>
            <Button
                size="mini"
                type="secondary"
                modifierClass={firstBtnSelectedClassName}
                onClick={() => {
                    setActiveBtn(false)
                    props.onFirstBtnClick()
                }}
                ariaLabel={props.firstBtnAriaLabel}>
                {props.showIcon && <Icon type={props.firstBtnIconType} size="lg" path={props.path} />}
                {props.firstBtnLabel}
            </Button>
            <Button
                size="mini"
                type="secondary"
                modifierClass={secondBtnSelectedClassName}
                onClick={() => {
                    setActiveBtn(true)
                    props.onSecondBtnClick()
                }}
                ariaLabel={props.secondBtnAriaLabel}>
                {props.showIcon && <Icon type={props.secondBtnIconType} size="lg" path={props.path} />}
                {props.secondBtnLabel}
            </Button>
        </div>
    )
}

export default ToggleTabs
