import React from 'react'
import { createPortal } from 'react-dom'
import { BodyEndProps } from './Tooltip.types'
// TODO AFTER DISCUSSION

/**
 * @param {React.ReactNode} children - html which is wrapped inside tooltipbody.
 * @param {string} elementClassName - Class name of the container on which u want to mount the toast.
 * @return {React.ReactPortal}
 */
const TooltipBody = ({ children, elementClassName }: BodyEndProps): React.ReactPortal => {
    const mount =
        document.getElementById('modalToolTipContainer') ||
        document.getElementsByClassName(elementClassName)[0] ||
        document.getElementById('toastDiv') ||
        document.body

    return createPortal(React.createElement('div', null, children), mount)
}

export default TooltipBody
