// / <reference path="../../externals.ts" />
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { libUtils } from '@nl/lib'
import { PREFIX, BREAKPOINTS } from '../../config'
import localStorageService from '../../utils/localStorageService'
import getQueryString from '../../utils/getQueryString'
import {
    status,
    emailRegEx,
    gigyaScreens,
    gigyaOperations,
    loginUserId,
    welcomeBackUserId,
    subscribeToEmailScreenset,
    gigyaWideModalClass,
    gigyaScreenDialogClass,
    gigyaScreenContentClass,
    gigyaSignInModalDefaultContentClass,
    loginPasswordId,
    welcomeBackPasswordId,
    password,
    passwordComplexity,
    registrationGigyaScreen,
    checkoutLoginClass,
    checkoutSigninFlag,
    forgotUserId,
    postalCodeId,
    liteSignupUserId,
} from './gigya.constants'
import { isCheckoutSignInRedirect } from '../Checkout/Checkout.constant'
import { GigyaAccountResp, GigyaAccountRespData, GigyaAccountRespProfile } from '../../utils/gigya.type'
import { gigyaEventType, responseStatusType, responseType, screensetProps, ScreenSetResponse } from './gigyaScreen.type'
import { addPasswordVisibilityToggle } from './PasswordVisibilityToggle'
import checkNestedProps from '../../utils/checkNestedProps'
import { getGigyaJWTToken, getGigyaUserStatus, sendVerificationEmail } from '../../utils/gigyaToken'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { animateGigyaFields } from '../../utils/animateGigyaFields.utils'
import loginRegisAnalytics from '../../analytics/components/loginRegisAnalytics'
import { MagicNumber } from '../../analytics/analytics.type'
import { TmxService } from '../../services/tmxService/tmx.service'
import { getTranslateErrorMsg, gigyaErrorHandler, onFormError } from './gigyaErrorHandler'
import appCacheService from '../../utils/appCacheService'
import getPageType from '../../utils/getPageType'
import spaAnalytics from '../../analytics/components/spaAnalytics'
import { useSelector } from 'react-redux'
import redirectionBasedOnHost from './redirectionBasedOnHost'
import sessionStorageService from '../../utils/sessionStorageService'
import { setGigyaScreenSetData } from '../../redux/actionCreators/gigyaScreenSet.actionCreators'
import store from '../../store'
import { fetchInterceptorErrorAction, setShowSpinner } from '../../redux/actionCreators'
import { netWorkErrorCode } from '../../globalConstants/cdsErrorCodes'
import { RbaTMXResponse } from '../../services/tmxService/tmx.type'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { checkEmptyValue } from '../../utils/checkNotNullAndUndefined'
import { scrollToTop } from '../ShoppingCart/CartItem.util'
import { postalCodeRegex } from '../../globalConstants'

let count = 1
let isTooltipVisible = false
let isFirstFocus = true
let tooltipIconClicked = false
const transformLabelClass = 'transform-label'
const displayMessageClass = 'display-message'
const verificationSelector = '.sent-verification-icon, .sent-verification-label'
const onClickHandlerRegisteredAttribute = 'data-click-registered'

const {
    eventParameters: { action, labels, location },
} = analyticsAttributes
const { invalid, firstFocus, valid } = passwordComplexity
const { loginScreen, welcomeBack, forgotPassword, emailSubscribeScreen } = gigyaScreens

export const passwordCheckList = {
    minNumCharaters: {
        id: 'minNumCharaters',
        validator: (val: string): boolean => val.length >= MagicNumber.EIGHT,
    },
    oneUpperCase: {
        id: 'oneUpperCase',
        validator: (val: string): boolean => !!val.match(/[A-Z]/g),
    },
    oneLowerCase: {
        id: 'oneLowerCase',
        validator: (val: string): boolean => !!val.match(/[a-z]/g),
    },
    oneNumber: {
        id: 'oneNumber',
        validator: (val: string): boolean => !!val.match(/\d/g),
    },
    oneSpecialChar: {
        id: 'oneSpecialChar',
        validator: (val: string): RegExpMatchArray | null => val.match(/([~!@#$%^&*()_+])/g),
    },
}

export const isPwdScreen = (currentScreen: string): boolean =>
    [
        gigyaScreens.resetPasswordScreen,
        gigyaScreens.registrationScreen,
        gigyaScreens.securityResetScreen,
        gigyaScreens.forcedPasswordChange,
    ].includes(currentScreen)

export const validatePasswordCheckList = (event: gigyaEventType, isChangePassword?: boolean): void => {
    const validatePwdRulesList = isPwdScreen(event.screen)
    const isPwdFiled = isChangePassword
        ? ['newPassword'].includes(event.field)
        : ['newPassword', 'password'].includes(event.field)

    if (!validatePwdRulesList || !isPwdFiled) {
        return
    }
    const isFocus = isFirstFocus ? firstFocus : invalid
    for (const [id, rule] of Object.entries(passwordCheckList)) {
        const isValid = rule.validator(event.value)
        const listElem = document.querySelectorAll(`.pwd-check-rule-${id}`)
        listElem.forEach(el => {
            el.classList.remove(firstFocus)
            el.classList.remove(valid, invalid)
            el.classList.add(isValid ? valid : isFocus)
        })
    }
}

export const enablePwdRulesCheck = (currentScreen: string, passwordFields: NodeListOf<HTMLInputElement>): void => {
    const showPwdRulesList = isPwdScreen(currentScreen)
    if (!showPwdRulesList) {
        return
    }
    const resetPwdField: HTMLInputElement | null = document.querySelector('#gigya-password-newPassword')
    const regPwdField: HTMLInputElement | null = Array.from(passwordFields).filter(
        el => el.closest('.ciam-valid-check:not(.ciam-retype-password)') && el.id,
    )?.[0]

    if (!regPwdField && !resetPwdField) {
        return
    }

    const pwdField = regPwdField || resetPwdField
    const rulesItems = document.querySelectorAll('.pwd-check-rule')

    if (!rulesItems.length) {
        return
    }
    let isFocusedFlag = false
    const focusBlurEventHandler = () => {
        if (isFocusedFlag) {
            isFirstFocus = false
        }
        rulesItems.forEach(rule => {
            if (isFirstFocus) {
                rule.classList.add(firstFocus)
            } else {
                rule.classList.replace(firstFocus, invalid)
            }
        })

        isFocusedFlag = true
    }

    rulesItems.forEach(rule => {
        rule.classList.add('visible')
    })

    pwdField.addEventListener('focus', focusBlurEventHandler, false)
    pwdField.addEventListener('blur', focusBlurEventHandler, false)
}

export const gigyaLoadFunctions = (
    screenSet: string,
    setStartScreenId?: React.Dispatch<React.SetStateAction<string | undefined>>,
    startScreenId?: string,
    showInPage = true,
    shouldRedirect = true,
    source?: string,
    triggeredFrom?: string,
    callback?: () => void,
): void => {
    // Used to set user email id
    const setUserId = (id: string, currentScreen?: string, welcomeBackPwd?: string) => {
        const forgotUser = localStorageService.getItem('forgotEmail') as string
        const userField = document.getElementById(id) as HTMLInputElement
        const user = localStorageService.getItem('userId') as string
        const pwd = document.getElementById(welcomeBackPwd || '') as HTMLInputElement
        const welcomeBackPasswordValue: unknown = pwd?.getAttribute('value') as string

        if (!!forgotUser) {
            userField?.previousElementSibling?.classList.add(transformLabelClass)
            userField.value = forgotUser
        }

        if (
            (currentScreen === gigyaScreens.welcomeBack ||
                (!showInPage && currentScreen === gigyaScreens.loginScreen)) &&
            user
        ) {
            localStorageService.setItem('forgotEmail', user)
            userField.previousElementSibling?.classList.add(transformLabelClass)
            if (!!welcomeBackPasswordValue) {
                pwd.previousElementSibling?.classList.add(transformLabelClass)
            }
            userField.value = user
            const firstName = localStorageService.getItem('firstName') as string
            const header = document.querySelector('.ODP-firstName') as HTMLElement
            if (!!firstName) {
                header.textContent = `, ${firstName}!`
            }
            document.getElementById(id)?.classList.add('stored-email')
        }
    }

    // Used to resend email verification link
    const resendVerification = (item: HTMLElement) => {
        const registrationToken = localStorageService.getItem('regToken') as string
        const addClickHandler = () => {
            item.setAttribute(onClickHandlerRegisteredAttribute, 'true')
            item.addEventListener('click', function () {
                /** Todo if counter needs to be implemented in resend verification **/
                // let counter = 1
                // const index = 1
                // const resendLimit = 2
                const showSentMsg = (response: responseStatusType) => {
                    if (response.status === status.ok || response?.status?.toString() === status.success) {
                        // counter = counter + index
                        // // resend verification only 2 times
                        // if (counter > resendLimit) {
                        //     setStartScreenId('gigya-cannot-resend-verification')
                        // }
                        const addDisplayClass = (verificationItem: HTMLElement) => {
                            verificationItem.parentElement?.classList.add('verification-sent-msg')
                            verificationItem.classList.add(displayMessageClass)
                        }
                        const verification: NodeListOf<HTMLElement> = document.querySelectorAll(verificationSelector)
                        verification.forEach(addDisplayClass)
                    } else if (response.status === status.fail) {
                        /* setStartScreenId state will not be available when screenset is opened in modal (from pencil banner)
                            in case state is not available invoking gigya load function */
                        if (!!setStartScreenId) {
                            setStartScreenId(gigyaScreens.forgotPasswordSuccess)
                        } else {
                            // TODO read the screenset id from common content, required store to be injected here
                            gigyaLoadFunctions(
                                'ODP-Registration',
                                undefined,
                                'gigya-cannot-resend-verification',
                                false,
                                false,
                            )
                        }
                    }
                }
                const UID = localStorageService.getItem('UID') as string
                if (!checkEmptyValue(UID)) {
                    sendVerificationEmail(UID, showSentMsg)
                } else {
                    const params = {
                        regToken: registrationToken,
                        callback: showSentMsg,
                    }
                    window.gigya.accounts.resendVerificationCode(params)
                }
            })
        }

        if (!item.getAttribute(onClickHandlerRegisteredAttribute)) {
            addClickHandler()
        }
    }

    /**
     * reset password API gigya call
     * @param  {HTMLElement} item userID to reset password
     * @return {void}
     */
    const resetPasswordAPICall = (item?: HTMLElement) => {
        const callResetPassword = () => {
            const loginID = localStorageService.getItem('resetEmail') as string
            window.gigya.accounts.resetPassword({ loginID })
        }

        const addOnClickResetPasswordHandler = () => {
            item?.setAttribute(onClickHandlerRegisteredAttribute, 'true')
            item?.addEventListener('click', callResetPassword)
        }

        !item
            ? callResetPassword()
            : !item.hasAttribute(onClickHandlerRegisteredAttribute) && addOnClickResetPasswordHandler()
    }

    /**
     * combine screen name with page / modal from where event is fired
     * @param  {string} screenName screenId from gigya console
     * @return {string} screen name with type modal/page
     */
    const eventLocation = (screenName: string): string => {
        return `${screenName} ${locationToSend}`
    }

    /**
     * Called when an error occurs
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */
    const onError = (event: gigyaEventType) => {
        const response = event.response.info.response
        const validationErrors = response.validationErrors
        const validationErrorMessage = validationErrors ? validationErrors[0].message : response.errorMessage
        loginRegisAnalytics.loginRegisErrorEvents(eventLocation(event.response.info?.screen), validationErrorMessage)
    }

    /**
     * registration screen
     * @param {string} currentScreen
     */
    const registrationScreen = (currentScreen: string) => {
        if (currentScreen !== 'gigya-register-screen-2' && currentScreen !== registrationGigyaScreen) return
        const zeroIndex = 0
        const tooltip = document.querySelector('.tooltip__icon')
        const tooltipHeadTextElements = document.getElementsByClassName('tooltip__head')
        const tooltipHeadText =
            tooltipHeadTextElements[MagicNumber.TWO] ||
            Array.from(tooltipHeadTextElements).find(element => element.innerHTML)
        const tooltipContentTextElements = document.getElementsByClassName('tooltip__text')
        const tooltipContentText =
            tooltipContentTextElements[MagicNumber.TWO] ||
            Array.from(tooltipContentTextElements).find(element => element.innerHTML)
        const tooltipHeadingContainer = document.getElementsByClassName('tooltip__heading')[zeroIndex]
        const tooltipContentContainer = document.getElementsByClassName('tooltip__content')[zeroIndex]

        tooltipHeadingContainer.innerHTML = tooltipHeadText?.innerHTML
        tooltipContentContainer.innerHTML += tooltipContentText?.innerHTML

        tooltipContentContainer?.addEventListener('click', function (event) {
            event.preventDefault()
        })

        tooltip?.addEventListener('click', function (event) {
            checkTooltipResponsive()

            isTooltipVisible = true
            tooltipIconClicked = !tooltipIconClicked
            closeTooltip()

            const tooltipClose = document.getElementsByClassName('tooltip__close')[0]
            tooltipClose.addEventListener('click', function (e) {
                isTooltipVisible = true
                tooltipIconClicked = false
                closeTooltip()
                e.preventDefault()
            })
            event.preventDefault()
        })

        const checkTooltipResponsive = () => {
            if (BREAKPOINTS.mobileMaxWidth >= window.innerWidth) {
                const backdrop = document.createElement('div')
                backdrop.setAttribute('id', 'backdrop')
                backdrop.classList.add('tooltip__backdrop')
                document.querySelectorAll('.subscription')[MagicNumber.TWO]?.appendChild(backdrop)
                tooltipContentContainer.classList.add('responsive')
            }
        }

        const closeTooltip = () => {
            if (isTooltipVisible && !tooltipIconClicked) {
                if (BREAKPOINTS.mobileMaxWidth >= window.innerWidth) {
                    document.getElementById('backdrop')?.remove()
                }
                tooltipContentContainer.setAttribute('style', 'visibility: hidden')
            } else if (isTooltipVisible) {
                tooltipContentContainer.setAttribute('style', 'visibility: visible')
            }
        }
    }

    const passwordSuccessScreen = (currentScreen: string) => {
        if (currentScreen === gigyaScreens.forgotPasswordSuccess || currentScreen === 'gigya-login-attempts') {
            const userId = localStorageService.getItem('forgotEmail') as string
            const resendLink = document.querySelectorAll('.js-resend-pwd-link')
            if (currentScreen === gigyaScreens.forgotPasswordSuccess) {
                const emailID = document.querySelector('.email-id') as HTMLElement
                emailID.innerHTML = userId
            }
            const showSentMsg = (response: responseStatusType): void => {
                if (response.status === status.ok && currentScreen === gigyaScreens.forgotPasswordSuccess) {
                    const addDisplayClass = (item: HTMLElement) => {
                        item.classList.add(displayMessageClass)
                    }
                    const verification: NodeListOf<HTMLElement> = document.querySelectorAll(verificationSelector)
                    verification.forEach(addDisplayClass)
                }
            }

            const resendEmail = () => {
                window.gigya.accounts.resetPassword({ loginID: userId, callback: showSentMsg })
                /* setStartScreenId state will not be available when screenset is opened in modal (from pencil banner)
                in case state is not available invoking gigya load function */
                if (!!setStartScreenId) {
                    setStartScreenId(gigyaScreens.forgotPasswordSuccess)
                } else {
                    // TODO read the screenset id from common content, required store to be injected here
                    gigyaLoadFunctions('ODP-LoginSignIn', undefined, gigyaScreens.forgotPasswordSuccess, false, false)
                }
            }
            resendLink.forEach(e => {
                e.addEventListener('click', resendEmail)
            })
        }
    }
    const timeout = () => {
        const countNumber = 400
        const time = 300
        const margin = '56px 0 16px !important'
        const errorMargin = '8px 0 15px !important'
        count++
        setTimeout(() => {
            const tfainputField: NodeListOf<HTMLInputElement> = document.querySelectorAll('.gig-tfa-code-textbox')
            document.querySelector('.gig-tfa-code-remember-checkbox')?.setAttribute('checked', 'checked')
            document.querySelector('.gig-tfa-code-textbox')?.setAttribute('maxLength', MagicNumber.SIX)
            document.querySelector('.gig-tfa-code-remember-label')?.setAttribute('style', `margin: ${margin}`)
            document.querySelector('.gig-tfa-error')?.setAttribute('style', `margin: ${errorMargin}`)

            // avoid continuous loop maximum wait time is limited to 2 minutes
            if (tfainputField.length === 0 && count <= countNumber) {
                timeout()
            }
            // blur is added because by default input is focused if there is way to remove focus from inwidget this line can be removed
            tfainputField.forEach((item: HTMLInputElement) => item.blur())
            tfainputField.forEach(animateGigyaFields)
            // focus in input will be visible if the timeout is 500 or greater
        }, time)
    }

    /**
     * load Tfa Screen
     * @param {string} currentScreen
     */
    const loadTfaScreen = (currentScreen: string) => {
        if (currentScreen !== gigyaScreens.tfaVerificationScreen) return

        timeout()
        loginRegisAnalytics.interactionAnalytics(action.tfa, eventLocation(currentScreen))
    }

    /**
     * forced Password
     * @param {string} currentScreen
     */
    const forcedPassword = (currentScreen: string) => {
        if (currentScreen !== 'gigya-password-change-required-screen') return
        loginRegisAnalytics.interactionAnalytics(
            action.resetPasswordAction,
            eventLocation(currentScreen),
            labels.forcePwdDisplayLabel,
        )
    }

    /**
     * pass analytics data on verification screen and actions to perform
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */
    const actionVerificationScreen = (event: gigyaEventType) => {
        if (event.currentScreen !== gigyaScreens.verificationSent) return

        const opt = checkNestedProps(event, 'response', 'requestParams', 'data', 'enableSubscription') as boolean
        loginRegisAnalytics.interactionAnalytics(
            action.createAccount,
            eventLocation(event.currentScreen),
            labels.withTriangle,
            opt,
        )

        const resendVerificationLink: NodeListOf<HTMLElement> =
            document.querySelectorAll('.ODP-email-verification-link')
        setTimeout(() => {
            const isAlreadyRegisteredUser = localStorageService.getItem('isRegistered')

            !!isAlreadyRegisteredUser
                ? resendVerificationLink.forEach(resetPasswordAPICall)
                : resendVerificationLink.forEach(resendVerification)
        }, MagicNumber.ONETHOUSAND)
    }

    /**
     * Called to send userID and current screen
     * @param {string} currentScreen - currentScreen
     * @param {string} rememberMe - rememberMe
     */
    const showUserId = (currentScreen: string, rememberMe: string) => {
        if (currentScreen === gigyaScreens.welcomeBack && rememberMe === 'true') {
            setUserId(welcomeBackUserId, currentScreen, welcomeBackPasswordId)
        }
        if (!showInPage && currentScreen === gigyaScreens.loginScreen) {
            setUserId(loginUserId, currentScreen, loginPasswordId)
        }
        if (currentScreen === 'gigya-forgot-password-screen') {
            setUserId('gigya-textbox-loginID', currentScreen)
        }
    }
    /**
     * Called in order to avoid the password field and email field placeholder
     * and label overlapping issue.
     * @param {string} currentScreen - currentScreen
     */

    const autoFillPassword = (currentScreen: string) => {
        const loginUserIdElement: HTMLElement | null = document.getElementById(loginUserId)
        const loginUserIdValue: string | null | undefined = loginUserIdElement?.getAttribute('value')
        const loginPasswordElement: HTMLElement | null = document.getElementById(loginPasswordId)
        const loginPasswordValue: string | null | undefined = loginPasswordElement?.getAttribute('value')

        if (currentScreen === gigyaScreens.loginScreen && loginUserIdValue) {
            passwordFilled(loginUserId)
        }
        if (currentScreen === gigyaScreens.loginScreen && loginPasswordValue) {
            passwordFilled(loginPasswordId)
        }
    }

    /**
     * Set email into local storage while registering
     * @param {gigyaEventType} event - event
     */
    const setRegEmail = (event: gigyaEventType) => {
        if (event.currentScreen === 'gigya-register-screen-2') {
            localStorageService.setItem('regEmail', event.profile?.email)
        }
    }

    /**
     * unset UID from local storage except emailVerificationExpired screen
     * @param {gigyaEventType} event - event
     */
    const unsetUID = (event: gigyaEventType) => {
        if (event.currentScreen !== gigyaScreens.emailVerificationExpired) {
            localStorageService.removeItem('UID')
        }
    }

    /**
     * Called in order to force screen reader to reader screenSets header value
     * @param {string} currentScreen - currentScreen
     */
    const setHeadingValueForA11y = (currentScreen: string) => {
        if (currentScreen === gigyaScreens.registrationScreen) {
            const gigyaFormHeadingSelector = '#gigya-register-form > .gigya-layout-row > h2'
            const gigyaFormHeadingValue = document.querySelector(gigyaFormHeadingSelector)?.innerHTML
            const gigyaScreenA11yElement = document.querySelector(`.${PREFIX}-gigya-screen__not-visible-element`)
            if (gigyaScreenA11yElement !== null) {
                gigyaScreenA11yElement.innerHTML = gigyaFormHeadingValue ?? ''
            }
        }
    }

    /**
     * Set textContent to emailId element if exists
     * @param {HTMLElement} emailId html element to be updated
     */
    const setEmailIdTextContent = (emailId: HTMLElement) => {
        const userId = localStorageService.getItem('resetEmail')
        const regId = localStorageService.getItem('regEmail')
        emailId.textContent = !!userId ? userId : regId
    }

    /**
     * Called after a new screen is rendered
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */
    const onAfterScreenLoad = (event: gigyaEventType) => {
        if (event.currentScreen !== gigyaScreens.verificationSent) {
            store.dispatch(setShowSpinner(false))
            localStorageService.removeItem('isRegistered')
            localStorageService.removeItem('resetEmail')
            localStorageService.removeItem('regEmail')
        }
        unsetUID(event)

        setRegEmail(event)

        const inputFields: NodeListOf<HTMLInputElement> = document.querySelectorAll('.gigya-input-text')
        const passwordFields: NodeListOf<HTMLInputElement> = document.querySelectorAll('.gigya-input-password')
        const container: HTMLElement | null = document.getElementById(containerID)
        const rememberMe = localStorageService.getItem('remember') as string
        const gigyaModalOverlay = document.querySelector('#gigya-screen-dialog-page-overlay')

        inputFields.forEach(animateGigyaFields)
        passwordFields.forEach(animateGigyaFields)
        passwordFields.forEach(el => addPasswordVisibilityToggle(el, container))

        if (gigyaModalOverlay) {
            // BRWS1-907: on iOS mobile view, the overlay element with id="gigya-screen-dialog-page-overlay" is added, even though the modal is displayed as full screen.
            //            as a result of this, overlay is displayed on top of the modal and covers the modal. simple solution is to remove this overlay but this has side effects
            //            1. when viewport is changed from mobile/desktop, overlap will not be visible since it's removed
            //            2. clicking "X" button does not close the modal on the first click. it always requires 2 clicks
            //            the proper solution is to fix from gigya screen to not add the overlay on mobile view, but this is a temporary "dirty" fix for now
            if (window.innerWidth < BREAKPOINTS.desktopMinWidth) {
                gigyaModalOverlay.remove()
            } else {
                gigyaModalOverlay.addEventListener('click', function () {
                    const gigyaModal = document.querySelector('.gigya-screen-dialog, .gigya-screen-dialog-mobile')
                    gigyaModal && gigyaModal.setAttribute('style', 'display: none')
                    const gigyaMobileModalMode = document.querySelector('.gigya-mobile-modal-mode')
                    gigyaMobileModalMode?.classList.remove('gigya-mobile-modal-mode')
                })
            }
        }

        if (event.currentScreen === gigyaScreens.loginScreen) {
            localStorageService.removeItem('forgotEmail')
        }

        if (event.currentScreen === gigyaScreens.verificationSent) {
            const emailId = document.querySelector('.ciam-email-id') as HTMLElement
            emailId && setEmailIdTextContent(emailId)
        }

        if (event.currentScreen === 'gigya-email-verification-expired') {
            loginRegisAnalytics.loginRegisErrorEvents('', labels.errorMessage)

            const resendVerificationLink: NodeListOf<HTMLElement> = document.querySelectorAll(
                '.ODP-email-verification-button',
            )
            resendVerificationLink.forEach(resendVerification)
        }

        if (event.currentScreen === 'gigya-complete-registration-screen-2') {
            const index = 1
            const continueBtn = document.getElementsByClassName('continue')[index]

            continueBtn?.addEventListener('click', function () {
                loginRegisAnalytics.signInAnalytics(
                    action.signInEmail,
                    labels.dontRemember,
                    eventLocation(event.screen),
                )
                const completeRegToken = appCacheService.gigyaJWTToken.get()
                getGigyaJWTToken(redirectUser, undefined, completeRegToken)
            })
        }

        if (startScreenId !== event.currentScreen) {
            // Checked current screen id with start screen id because onload we have global analytics and need to stamp it when screen changes.
            setPageAnalyticsByScreen(event.currentScreen)
        }

        setHeadingValueForA11y(event.currentScreen)
        enablePwdRulesCheck(event.currentScreen, passwordFields)
        showUserId(event.currentScreen, rememberMe)
        actionVerificationScreen(event)
        resetPasswordSuccessScreen(event)
        loadTfaScreen(event.currentScreen)
        passwordSuccessScreen(event.currentScreen)
        registrationScreen(event.currentScreen)
        forcedPassword(event.currentScreen)
        autoFillPassword(event.currentScreen)
        scrollToTop()
    }

    /**
     * Uses to stamp SPA pages analytics according to different screens.
     * @param { string } currentScreen
     * @return { void }
     */
    const setPageAnalyticsByScreen = (currentScreen: string): void => {
        spaAnalytics.setAnalytics(currentScreen)
    }

    /**
     * reset Password Success Screen
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */

    const resetPasswordSuccessScreen = (event: gigyaEventType) => {
        if (
            event.currentScreen === 'gigya-reset-password-success-screen' &&
            event.response.operation === '/accounts.setAccountInfo'
        ) {
            const signInBtn = document.querySelectorAll('.ODP-SignIn')
            signInBtn.forEach(e => {
                e.classList.add('hide')
            })

            const continueBtn = document.querySelectorAll('.ODP-Continue')
            continueBtn.forEach(e => {
                e.classList.remove('hide')
                e.addEventListener('click', function () {
                    getGigyaJWTToken(redirectUser, event.response.UID)
                })
            })
        }
    }

    /*
     This function taking loginPasswordId and welcomeBackPasswordId
     as argument and adding transformLabel class to moved the label up
     to avoid overlapping issue.
    */
    const passwordFilled = (id: string) => {
        const pwdField = document.getElementById(id) as HTMLInputElement
        pwdField.previousElementSibling?.classList.add(transformLabelClass)
    }
    /**
     * @param {gigyaEventType} event - object returns from gigya when page loads
     * Called in order to avoid the password field and placeholder overlapping
     * brower specific method i.e firefox but will not affect on the other browsers
     * will add transformLabelClass on click  username while autopopulating password
     */
    const autoPopulatePasswordField = (event: gigyaEventType) => {
        if (event.field === password && event.value) {
            /*
              This is code is not move in switch case
              because its has only two cases and it will
              throw clauseseslint(sonarjs/no-small-switch)
            */
            if (event.screen === gigyaScreens.loginScreen) {
                passwordFilled(loginPasswordId)
            }
            if (event.screen === gigyaScreens.welcomeBack) {
                passwordFilled(welcomeBackPasswordId)
            }
        }
    }

    /**
     * Called when a form field is changed
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */
    const onFieldChanged = (event: gigyaEventType) => {
        document
            .querySelector('.gigya-form-error-msg.gigya-error-msg-active')
            ?.classList.remove('gigya-error-msg-active')

        if (event.screen === gigyaScreens.loginScreen && event.field === 'loginID' && event.isValid) {
            localStorageService.setItem('forgotEmail', event.value)
        }
        if (event.screen === 'gigya-forgot-password-screen' && event.field === 'loginID' && event.isValid) {
            const user = localStorageService.getItem('forgotEmail') as string
            if (user !== event.value) {
                localStorageService.setItem('forgotEmail', event.value)
            }
        }
        autoPopulatePasswordField(event)
        validatePasswordCheckList(event)
    }

    /**
     *  reset Password Analytics
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */
    const resetPasswordAnalytics = (event: gigyaEventType) => {
        if (
            event.response.operation === gigyaOperations.resetPassword &&
            event.response.status.toUpperCase() !== status.fail
        ) {
            const labelToSend: string =
                event.screen === gigyaScreens.resetPasswordScreen ? labels.userCompleted : labels.userInitiated
            loginRegisAnalytics.interactionAnalytics(
                action.resetPasswordAction,
                eventLocation(event.screen),
                labelToSend,
            )
        }

        if (
            event.response.operation === gigyaOperations.resetPassword &&
            event.response.status.toUpperCase() === status.ok
        ) {
            event.profile.useremailId = event.response.loginID
        }
    }

    /**
     * function to call analytics
     * @param {gigyaEventType} event
     * @param {string} label
     * @return { void }
     */
    const errorAnalyticsEvent = (event: gigyaEventType, label: string): void => {
        if (event.screen === welcomeBack || event.screen === loginScreen || event.screen === forgotPassword) {
            loginRegisAnalytics.loginRegisErrorEvents(eventLocation(event.screen), label)
        }
    }

    /**
     * function to get id of the element based on the screen
     * @param {gigyaEventType} event
     * @return { string }
     */
    const getElementId = (event: gigyaEventType): string => {
        switch (event.screen) {
            case welcomeBack:
                return welcomeBackUserId
            case emailSubscribeScreen:
                return liteSignupUserId
            case forgotPassword:
                return forgotUserId
            default:
                return loginUserId
        }
    }

    /**
     * function to foucs to element based on id
     * @param {string} id
     * @return { void }
     */
    const setFocusToElement = (id: string): void => {
        document.getElementById(id)?.focus()
    }

    //	changed the on before validation to on after validation
    const onBeforeValidation = (event: gigyaEventType) => {
        const validate = emailRegEx.test(
            event.screen === emailSubscribeScreen ? event.formData['profile.email'] : event.formData.loginID,
        )
        const passwordValidation = event.formData.password
        const postalCode = postalCodeRegex.test(event.formData['data.eFlyers.postalCode'])

        if (!validate) {
            sessionStorageService.setItem('isLoginErrorPresent', 'true')
            errorAnalyticsEvent(event, getTranslatedErrorMessage(0, 'email_address_is_invalid'))
            setFocusToElement(getElementId(event))
            return { loginID: getTranslatedErrorMessage(0, 'email_address_is_invalid') }
        } else if (!passwordValidation && event.screen !== emailSubscribeScreen) {
            sessionStorageService.setItem('isLoginErrorPresent', 'true')
            setFocusToElement(event.screen === welcomeBack ? welcomeBackPasswordId : loginPasswordId)
            errorAnalyticsEvent(event, labels.fieldRequired)
            return
        } else if (!postalCode && event.screen === emailSubscribeScreen) {
            setFocusToElement(postalCodeId)
            return
        }
        if (event.screen === welcomeBack || event.screen === loginScreen) {
            store.dispatch(setShowSpinner(true))
            return beforeValidationHandler(event)
        }
    }

    // Called before form field validates
    const beforeValidationHandler = (event: gigyaEventType) => {
        const removeErrorActive = (item: HTMLElement): void => {
            item.classList.remove(`gigya-error-display-active`)
        }
        const currentFormError: NodeListOf<HTMLElement> = document.querySelectorAll(
            `.${event.form} .gigya-composite-control-form-error`,
        )
        currentFormError.forEach(removeErrorActive)

        /**
         * function to store common keys in local storage
         */
        const storeCommonKeys = (): void => {
            localStorageService.setItem('remember', event.formData.remember.toString())
            sessionStorageService.setItem('isLoggedInForToast', 'true')
            localStorageService.setItem('tokenState', 'SUCCESS')
            appCacheService.cartMergeFlag.set('true')
            appCacheService.removeTmxSessionId()
        }

        const doneHandler = function (tmxdata: { data: RbaTMXResponse }) {
            storeCommonKeys()
            loginRegisAnalytics.signInAnalytics(
                action.signInTriangle,
                labels.rememberMe + event.formData.remember.toString(),
                eventLocation(event.screen),
            )
            document.cookie = `${tmxdata.data.sessionInfo.cookieName}=${tmxdata.data.sessionInfo.cookieValue}`
            typeof callback === 'function' && callback()
            window.gigya.socialize.refreshUI({
                callback: getGigyaJWTToken(redirectUser, undefined, undefined, true),
            })
        }

        const failHandler = function (data: { errorCode: number; cdsResponse: { token: string } }) {
            errorAnalyticsEvent(event, getTranslatedErrorMessage(data.errorCode))
            localStorageService.setItem('regToken', data?.regToken)
            switch (data.errorCode) {
                case status.tfaErrorCode:
                    gigyaLoadFunctions(
                        screenSet,
                        setStartScreenId,
                        gigyaScreens.tfaVerificationScreen,
                        showInPage,
                        shouldRedirect,
                    )
                    storeCommonKeys()
                    break
                case status.verificationErrorCode:
                    gigyaLoadFunctions(
                        screenSet,
                        setStartScreenId,
                        gigyaScreens.verificationPendingScreen,
                        showInPage,
                        shouldRedirect,
                    )
                    break
                case status.accountLocked:
                    window.gigya.accountLocked = true
                    gigyaLoadFunctions(
                        screenSet,
                        setStartScreenId,
                        gigyaScreens.loginAttempts,
                        showInPage,
                        shouldRedirect,
                    )
                    break
                case status.pendingPasswordChange:
                    gigyaLoadFunctions(
                        screenSet,
                        setStartScreenId,
                        gigyaScreens.forcedPasswordChange,
                        showInPage,
                        shouldRedirect,
                    )
                    break
                default:
                    onFormError(event, getTranslatedErrorMessage(data.errorCode))
                    setFocusToElement(getElementId(event))
                    appCacheService.removeTmxSessionId()
            }
        }
        const formdata: Record<string, string | boolean> = {
            loginID: event.formData.loginID,
            password: event.formData.password,
            remember: event.formData.remember,
            targetEnv: 'browser',
        }
        const tmxservice = new TmxService()
        tmxservice
            .tmxAuthorization(formdata)
            .then(data => {
                doneHandler(data)
            })
            .catch(data => {
                store.dispatch(setShowSpinner(false))
                failHandler(data)
            })
    }

    /**
     * get translated gigya locale
     * @param {number} errorCode - error code returned from API response
     * @param {string} errorName - error value authored in gigya
     * @return {string} applicable error message
     */
    const getTranslatedErrorMessage = (errorCode: number, errorName?: string) => {
        const rootState = store.getState()
        const { gigyaScreenSetData } = rootState
        return gigyaErrorHandler(
            errorCode,
            gigyaScreenSetData?.screenSets[0]?.translations[libUtils.getLanguage()],
            errorName,
        )
    }

    const onBeforeSubmit = (event: gigyaEventType) => {
        // Returning false from login and welcome-back screen per new gigya flow to redirect to TMX service
        if (event.screen === 'gigya-welcome-back-screen' || event.screen === 'gigya-login-screen') {
            return false
        } else if (event.screen === 'gigya-register-screen' && !navigator.onLine) {
            const error = {
                message: netWorkErrorCode,
            }
            // don't use "await" her, if we add "async" in onBeforeSubmit method it Gigya will internally call accounts.login
            void import('../../store').then(storeObj => {
                storeObj.default.dispatch(fetchInterceptorErrorAction(error))
            })
        }
    }

    /**
     * show Verification Sent Message
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */
    const showVerificationSentMessage = (event: gigyaEventType) => {
        if (event.response.operation === '/accounts.resendVerificationCode' && event.response.status === 'OK') {
            const addDisplayClass = (item: HTMLElement) => {
                item.classList?.add(displayMessageClass)
            }
            const verification: NodeListOf<HTMLElement> = document.querySelectorAll(verificationSelector)
            verification.forEach(addDisplayClass)
        }
    }

    /**
     * forced Reset Password Analytics
     * @param {gigyaEventType} event - object returns from gigya when page loads
     *
     */
    const forcedResetPasswordAnalytics = (event: gigyaEventType) => {
        if (
            event.screen === 'gigya-password-change-required-screen' &&
            event.response.operation === '/accounts.getAccountInfo' &&
            event.response.isActive &&
            event.response.isRegistered &&
            event.response.isVerified
        ) {
            loginRegisAnalytics.interactionAnalytics(
                action.resetPasswordAction,
                eventLocation(event.screen),
                labels.forcePwdCompletedLabel,
            )
        }
    }

    /**
     * calls the function to check User Data
     *
     * @param {GigyaAccountRespData} data
     * @param {GigyaAccountRespProfile} profile
     * @param {string} email
     * @return {boolean}
     */
    const checkUserData = (data: GigyaAccountRespData, profile: GigyaAccountRespProfile, email: string): boolean => {
        let check = false
        if (data.loyalty || profile.email === email) {
            check = true
        }
        return check
    }

    /**
     * redirect User from signin page
     * @param {GigyaAccountResp} response
     * @return {string}
     */
    const redirectUrlFunc = (response: GigyaAccountResp): string => {
        const item = localStorageService.getItem('userDetail')

        return checkUserData(
            response.data,
            response.profile,
            item ? (JSON.parse(item) as GigyaAccountRespProfile)?.email : '',
        )
            ? window.ODP.globalLinks.homePageLink || ''
            : window.ODP.globalLinks.linkRewardOptionsLink || ''
    }

    /**
     * checking if the user has loyalty object or has selcted skipped the linkrewards flow
     * @param {GigyaAccountResp} gigyaAccountResponse
     * @return {string} redirectUrl
     */
    const hasLoyaltyCard = (gigyaAccountResponse?: GigyaAccountResp): string => {
        let url = ''
        if (gigyaAccountResponse && gigyaAccountResponse.data && gigyaAccountResponse.profile) {
            url = !window.gigya.showSignInModal ? redirectUrlFunc(gigyaAccountResponse) : ''
        }
        return url
    }

    /**
     * redirect User
     *
     * @param {GigyaAccountResp} gigyaAccountResponse
     *
     */
    const redirectUser = (gigyaAccountResponse?: GigyaAccountResp): void => {
        if (shouldRedirect) {
            const urlParams = new URLSearchParams(window.location.search)
            const returnURLParam = urlParams.get('returnURL')
            const returnURL = returnURLParam ? returnURLParam : ''
            const redirectUrl = hasLoyaltyCard(gigyaAccountResponse)
            window.gigya.showSignInModal = false
            // added condition to to restrict user from redirecting to Arbitrary URL
            const newUrl = new URL(returnURL, window.location.href)
            redirectionBasedOnHost(newUrl, returnURL, redirectUrl)
        }
    }
    /**
     * Called when a form is submitted
     *
     * @param {GigyaAccountResp} event
     *
     */
    const onAfterSubmit = (event: gigyaEventType) => {
        if (event.response.operation === '/accounts.register' && event.response.isActive) {
            localStorageService.setItem('regToken', event.response.regToken)
        }

        if (event.screen === gigyaScreens.tfaVerificationScreen) {
            store.dispatch(setShowSpinner(true))
            const tfaRegToken = localStorageService.getItem('regToken') as string

            const TFACallback = (response: responseType) => {
                if (response.errorCode === status.pendingPasswordChange) {
                    store.dispatch(setShowSpinner(false))
                    gigyaLoadFunctions(screenSet, setStartScreenId, gigyaScreens.forcedPasswordChange, showInPage)
                } else {
                    getGigyaJWTToken(redirectUser, undefined, undefined, true)
                    sessionStorageService.setItem('isLoggedInForToast', 'true')
                }
            }
            window.gigya.accounts.finalizeRegistration({
                dontHandleScreenSet: true,
                regToken: tfaRegToken,
                callback: TFACallback,
            })

            loginRegisAnalytics.signInAnalytics(
                action.signInEmail,
                `${labels.rememberMe}${String(localStorageService.getItem('remember'))}`,
                eventLocation(event.screen),
            )
        }

        if (event.response.errorCode === status.verificationErrorCode) {
            localStorageService.setItem('resetEmail', event.response?.profile?.email)
            const accountInfo = (response: responseType) => {
                if (response.errorCode === status.userStatusCode) {
                    localStorageService.setItem('isRegistered', String(event.response.isRegistered))
                    resetPasswordAPICall()
                }
            }
            getGigyaUserStatus(event?.response?.regToken, accountInfo)
        }

        emailSignup(event)
        resetPasswordAnalytics(event)
        forcedResetPasswordAnalytics(event)
        showVerificationSentMessage(event)
    }

    const setSignInModalContentStyle = (): void => {
        const gigyaScreenContent = document.getElementsByClassName(gigyaScreenContentClass)[0] as HTMLDivElement
        const checkoutSignin = localStorageService.getItem(checkoutSigninFlag) as string
        const styleClass = checkoutSignin ? checkoutLoginClass : gigyaSignInModalDefaultContentClass
        gigyaScreenContent?.classList.add(styleClass)
    }

    /**
     * unset checkoutSignin flag from local storage except login screen
     * @return {void}
     */
    const unsetChekoutFlag = (): void => {
        !window.location.search.includes(isCheckoutSignInRedirect) && localStorageService.removeItem(checkoutSigninFlag)
    }

    // Called before a new screen is rendered
    const onBeforeScreenLoad = (event: gigyaEventType) => {
        document.querySelector('.email-icon')?.parentElement?.parentElement?.classList.add('sales-promotions')
        const gigyaScreenDialog = document.querySelector('.gigya-screen-dialog')
        const gigyaScreenDialogMobile = document.querySelector('.gigya-screen-dialog-mobile')
        const checkoutSignin = localStorageService.getItem(checkoutSigninFlag) as string
        unsetChekoutFlag()

        const remember = localStorageService.getItem('remember') as string
        if (
            event.nextScreen === gigyaScreens.loginScreen &&
            remember === 'true' &&
            !window.location.search.includes(isCheckoutSignInRedirect)
        ) {
            setStartScreenId?.(gigyaScreens.welcomeBack)
        }
        if (event.nextScreen === gigyaScreens.forgotPassword) {
            setStartScreenId?.(gigyaScreens.forgotPassword)
        }
        if (event.nextScreen === gigyaScreens.verificationSent) {
            setStartScreenId?.(gigyaScreens.verificationSent)
        }
        if (subscribeToEmailScreenset.includes(event.nextScreen)) {
            gigyaScreenDialog?.classList.add(gigyaWideModalClass)
            gigyaScreenDialogMobile?.classList.add(gigyaScreenDialogClass, gigyaWideModalClass)
        } else {
            gigyaScreenDialog?.classList.remove(gigyaWideModalClass)
            gigyaScreenDialogMobile?.classList.remove(gigyaScreenDialogClass, gigyaWideModalClass)
        }

        if (checkoutSignin) {
            setSignInModalContentStyle()
        }
    }

    /**
     * Called when Signup is clicked
     * @param {gigyaEventType} event - object returns from gigya on submit click
     *
     */
    const emailSignup = (event: gigyaEventType) => {
        if (event.response.operation === gigyaOperations.accountInfo) {
            const screenId =
                event.response.errorCode === 0 ? 'gigya-subscribe-thank-you-screen' : 'gigya-subscribe-error-screen'
            gigyaLoadFunctions(screenSet, setStartScreenId, screenId, false)
        }
    }

    // Moved from the React componet to the loadFunction Method since it's needed when it's called directly as well
    const translatedMessageCallback = (gigyaScreenSetResponse: ScreenSetResponse) => {
        store.dispatch(setGigyaScreenSetData(gigyaScreenSetResponse))
    }

    // Fetch the error translation object for later usage in the screens
    // when not using the react component but calling the gigyaLoadFunctions directly like in the modals
    getTranslateErrorMsg(translatedMessageCallback, screenSet)

    const gigyaScreenData = {
        screenSet: screenSet,
        startScreen: startScreenId,
        onBeforeScreenLoad,
        onAfterScreenLoad,
        onFieldChanged,
        onBeforeValidation,
        onBeforeSubmit,
        onAfterSubmit,
        onError,
    }

    const containerID = 'gigya-screen-container'
    const regToken = localStorageService.getItem('regToken') as string
    const showGigyaInPage = showInPage
        ? {
              ...gigyaScreenData,
              context: { source: source },
              regToken,
              containerID: 'gigya-screen-container',
          }
        : {
              ...gigyaScreenData,
              context: { source: source },
              regToken: regToken,
          }
    const locationToSend = showInPage ? location.page : location.modal
    const pageType = getPageType()
    const analyticsLabel = triggeredFrom as string
    !showInPage && loginRegisAnalytics.signInInteractionAnalytics(pageType, analyticsLabel)
    window.gigya.accounts.showScreenSet(showGigyaInPage)
}

/**
 * GigyaScreen Component
 * @param {screensetProps} props - screenSet, startScreen
 * @return {JSX.Element} returns GigyaScreen Component
 */
const GigyaScreen: React.FC<screensetProps> = ({ ...props }) => {
    const { screenSet, startScreen } = props
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const [startScreenId, setStartScreenId] = useState(startScreen)
    const passwordVisibilityToggleLabel = checkNestedProps(
        commonContentAvailable,
        'accessibility',
        'a11yPasswordVisibilityToggleIcon',
    ) as string

    useEffect(() => {
        const queryParams = window.location.search
        const errorCode = getQueryString(queryParams, 'errorCode')
        const UID = getQueryString(queryParams, 'UID')

        UID && localStorageService.setItem('UID', UID)
        queryParams.includes(isCheckoutSignInRedirect) && localStorageService.setItem(checkoutSigninFlag, 'true')

        if (errorCode && errorCode === status.successCode) {
            setStartScreenId('gigya-complete-registration-screen-2')
            gigyaLoadFunctions(screenSet, setStartScreenId, startScreenId, true)
        } else if (errorCode && errorCode === status.verificationExpired) {
            startScreenId !== 'gigya-cannot-resend-verification' && setStartScreenId('gigya-email-verification-expired')
            gigyaLoadFunctions(screenSet, setStartScreenId, startScreenId, true)
        } else {
            gigyaLoadFunctions(screenSet, setStartScreenId, startScreenId, true)
        }
    }, [screenSet, startScreenId])

    return (
        <div className={`${PREFIX}-gigya-screen ${PREFIX}-full-width-container`}>
            {startScreen === gigyaScreens.registrationScreen ? (
                <div aria-live="assertive" className={`${PREFIX}-gigya-screen__not-visible-element`}></div>
            ) : null}
            <div
                id="gigya-screen-container"
                className={`${PREFIX}-gigya-screen__container`}
                data-pass-visibility-toggle={passwordVisibilityToggleLabel}></div>
        </div>
    )
}

GigyaScreen.propTypes = {
    screenSet: PropTypes.string.isRequired,
    startScreen: PropTypes.string,
}

export default GigyaScreen
