// Use this file for keyboard key codes.
/**
 * Key board key codes
 */
// TODO: This should be a Enum
export const keyCodes: Record<string, number> = Object.freeze({
    esc: 27,
    backspace: 8,
    shift: 16,
    ctrl: 17,
    alt: 18,
    delete: 46,
    leftArrow: 37,
    upArrow: 38,
    rightArrow: 39,
    downArrow: 40,
    cmd: 97,
    A: 65,
    enter: 13,
    tab: 9,
})
