import { currencyFormat, currencyShortForm, replaceNumberFormattedPrice } from '../globalConstants/global.constant'
import { localization } from '../helpers/Localization.helper'
import getLanguage from './getLanguage'

/**
 * getFormattedPriceWithLocale - Return price format price based on language and the currency code
 */

export const getFormattedPriceWithLocale = (price: number): string => {
    const language = getLanguage()
    return localization.formattedPrice(language, price, currencyShortForm, currencyFormat)
}

export const getNumberFromFormattedPrice = (currency: string) =>
    Number(currency.replace(replaceNumberFormattedPrice, ''))

export default { getFormattedPriceWithLocale, getNumberFromFormattedPrice }
