import { createAction } from 'typesafe-actions'
import { SET_REVIEW_BUTTON_STATE, SET_QUESTION_BUTTON_STATE } from '../types/buybox/BVSection.actionTypes.constant'

export const setIsReviewSectionButtonClicked = createAction(
    SET_REVIEW_BUTTON_STATE,
    (payload: boolean) => payload,
)<boolean>()

export const setIsQuestionSectionButtonClicked = createAction(
    SET_QUESTION_BUTTON_STATE,
    (payload: boolean) => payload,
)<boolean>()
