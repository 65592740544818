import { PREFIX } from '../../config'
/**
 * Constant to be used in simpleHeader checkoutComponent
 */
export const simpleHeaderCheckoutConst = {
    name: `${PREFIX}-simple-header-checkout`,
    sticky: `${PREFIX}-simple-header-checkout__sticky`,
    logo: 'simple-header-checkout-logo',
    logoText: 'Logo',
}
