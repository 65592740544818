export const PROFILE_PAYMENT_SUCCESS = 'PROFILE_PAYMENT_SUCCESS'
export const PROFILE_PAYMENT_ERROR = 'PROFILE_PAYMENT_ERROR'
export const CLEAR_PROFILE_PAYMENT = 'CLEAR_PROFILE_PAYMENT'
export const DELETE_PAYMENT_INFO_SUCCESS = 'DELETE_PAYMENT_INFO_SUCCESS'
export const HIDE_RECOMMENDATION_COMPONENT = 'HIDE_RECOMMENDATION_COMPONENT'
export const DELETE_PAYMENT_INFO_ERROR = 'DELETE_PAYMENT_INFO_ERROR'
export const CLEAR_DELETE_PAYMENT_INFO_STATES = 'CLEAR_DELETE_PAYMENT_INFO_STATES'
export const SET_HPP_TOKEN = 'SET_HPP_TOKEN'
export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD'
export const CLEAR_ADD_CREDIT_STATES = 'CLEAR_ADD_CREDIT_STATES'
export const ADD_CREDIT_CARD_ERROR = 'ADD_CREDIT_CARD_ERROR'
