import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/user.preferences.actionCreators'
import { PreferencesJnjtraitsSuccessResponse } from '../models/user.preferences.interface'
import {
    shoppingPreferenceSuccess,
    shoppingPreferenceError,
    shoppingUpdateSuccessAction,
    shoppingUpdateErrorAction,
    resetShoppingPreferenceAction,
} from '../actionCreators/user.preferences.actionCreators'

type Action = ActionType<typeof actions>

export interface ShoppingPreferenceState {
    shoppingPreferenceData: PreferencesJnjtraitsSuccessResponse | null
    shoppingPreferenceUpdated: boolean | null
}

export const initialState: ShoppingPreferenceState = {
    shoppingPreferenceData: {} as unknown as PreferencesJnjtraitsSuccessResponse,
    shoppingPreferenceUpdated: null,
}

/**
 * update user preference state preferences
 * @param { ShoppingPreferenceState } state set to initial state by default
 * @param { Action } action action called
 * @return { PreferenceState }
 */
export const shoppingPreferenceReducer = (
    state: ShoppingPreferenceState = initialState,
    action: Action,
): ShoppingPreferenceState => {
    switch (action.type) {
        case getType(shoppingPreferenceSuccess): {
            return { ...state, shoppingPreferenceData: action.payload, shoppingPreferenceUpdated: null }
        }
        case getType(shoppingPreferenceError): {
            return { ...state, shoppingPreferenceData: null }
        }
        case getType(shoppingUpdateSuccessAction): {
            return { ...state, shoppingPreferenceUpdated: true }
        }
        case getType(shoppingUpdateErrorAction): {
            return { ...state, shoppingPreferenceUpdated: false }
        }
        case getType(resetShoppingPreferenceAction): {
            return { ...state, shoppingPreferenceData: null }
        }
        default:
            return state
    }
}
