import { checkDataLength } from '../Addresses/checkDataLength'
import { pageTypes } from '../../../config'

/**
 * function to check order details or order confirmation page
 * @param {string} pageType
 * @return {boolean}
 */
const isOrderPageType = (pageType: string): boolean => {
    return Boolean(pageType === pageTypes.orderConfirmation)
}

/**
 * function to check for other page types and when its order confirmation or order details wait for order data to fire the analytics
 * @param {string} pageType
 * @param {unknown} storeData
 * @param {unknown} orderData
 * @return {boolean}
 */
const globalAnalyticsHelper = (pageType: string, storeData: unknown, orderData: unknown): boolean => {
    return Boolean(
        (pageType !== pageTypes.checkout && !isOrderPageType(pageType) && checkDataLength(storeData)) ||
            pageType === pageTypes.checkout ||
            (isOrderPageType(pageType) && checkDataLength(orderData)),
    )
}

export default globalAnalyticsHelper
