// Custom Components
import GigyaScreen from '../components/GigyaScreen'
import ButtonToggle from '../components/GridListToggle'
import StickyFilters from '../components/StickyFilters'
import FeaturedProductListComponent from '../components/FeaturedProductList'
import ToastMessageComponent from '../components/ToastMessage'
import { CertonaInit } from '../components/CertonaInit'
import { TmxInit } from '../components/TmxInit'
import OrderUpdatesSubscription from '../components/OrderUpdatesSubscription'
import DynamicFeaturedList from '../components/DynamicFeaturedList'
import LanguageToggleHandler from '../components/AccountsPencilBanner/PencilBanner/LanguageToggle/LanguageToggleHandler'
import PageInit from '../components/PageInit'
import { FeaturedProductListDynamic } from '../components/FeaturedProductListDynamic'
import StoreSelectorModalComp from '../components/StoreSelectorModal'
import ReferenceComponentWrapper from '../components/ReferenceComponentWrapper/ReferenceComponentWrapper'
import OrderExpiredError from '../components/OrderDetails/OrderExpired/OrderExpiredError'
import Video from '../components/Video'
import { FullPageSpinnerComponent } from '../components/FullPageSpinnerComponent'
import AutoServiceButton from '../components/AutoServiceButton'
import BackToTop from '../components/BackToTopButton/BackToTop'
import EmailOfferActivation from '../components/EmailOfferActivation/EmailOfferActivation'
import OpenGraphImageTagComponent from '../components/OpenGraphImageTagComponent'
import SignifydInit from '../components/SignifydInit'
import BrowseOnlyInformationBanner from '../components/BrowseOnlyInformationBanner'
import BalanceCheckerWrapper from '../components/BalanceChecker/BalanceCheckerWrapper'
import DynamicVehicleSeoCopyBlock from '../components/DynamicVehicleSeoCopyBlock'
import ProductTriangleRewards from '../components/ProductTriangleRewards'

export const staticComponentMap: {
    [key: string]: unknown
} = {
    GigyaScreen: GigyaScreen,
    ButtonToggle: ButtonToggle,
    StickyFilters,
    ThreatMetrixTag: TmxInit,
    SignifydTag: SignifydInit,
    FeaturedProductListComponent,
    ToastMessage: ToastMessageComponent,
    CertonaInit,
    DynamicFeaturedList,
    ReferenceComponent: ReferenceComponentWrapper,
    LanguageToggleHandler,
    PageInit,
    FeaturedProductListDynamic,
    StoreSelectorModalComp,
    OrderExpiredError,
    Video,
    FullPageSpinnerComponent,
    OrderUpdatesSubscription,
    AutoServiceButton,
    BackToTop,
    EmailOfferActivation,
    OpenGraphImageTagComponent,
    BrowseOnlyInformationBanner,
    BalanceChecker: BalanceCheckerWrapper,
    DynamicVehicleSeoCopyBlock,
    TriangleRewards: ProductTriangleRewards,
}
