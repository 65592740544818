// map of AEM component names to its human readable form
export const dataComponentConstants: { [key: string]: string } = {
    Specifications: 'Specifications',
    RoadRating: 'Road Rating',
    Resource: 'Resource',
    StickyTOC: 'Sticky TOC',
    Overview: 'Overview',
    Features: 'Features',
    Buybox: 'Buy Box',
    Breadcrumb: 'Breadcrumb',
    GigyaScreen: 'Gigya Screen',
    ButtonToggle: 'Button Toggle',
    SearchBar: 'Search Bar',
    ErrorSearchBar: 'Error Search Bar',
    SearchTitle: 'Search Title',
    StickyFilters: 'Sticky Filters',
    ThreatMetrixTag: 'Threat Metrix Tag',
    LinkRewardsWrapper: 'Link Rewards Wrapper',
    LinkCardOptions: 'Triangle Rewards AccountCard',
    NewRewardsCard: 'New Loyalty Card',
    LinkMasterCard: 'Link Master Card',
    PencilBannerLinks: 'Pencil Banner',
    BazaarVoice: 'Bazaar Voice',
    WishlistAndCart: 'Wishlist and Cart',
    SideNavigation: 'Side Navigation',
    CartFlyout: 'Cart Flyout',
    NotificationBanner: 'Notification Banner',
    FeaturedProductListComponent: 'Featured Product List',
    AddressComponent: 'Addresses',
    CartItems: 'Cart Items',
    StoreDetailsComp: 'Store Details',
    ProfileComponent: 'Profile Information',
    ContactUsFormComponent: 'Contact Us',
    PaymentComponent: 'Payment Information',
    MediaGallery: 'Media Gallery',
    OrderSummary: 'Order Summary',
    WishlistItems: 'Wishlist',
    MergeRewardsComponent: 'Merge Loyalty Card',
    ShoppingPreferencesComponent: 'Shopping Preferences',
    Checkout: 'Checkout',
    ContactInformation: 'Contact Information',
    ToastMessage: 'Toast Message',
    TriangleTransactionHistory: 'Triangle Transaction History',
    AccountDashboard: 'Account Dashboard',
    RewardsComponent: 'Rewards',
    WishlistSummary: 'Wishlist Summary',
    PreferencesComponent: 'Notification Preferences',
    StoreLocator: 'Store Locator',
    OrderConfirmationHeader: 'Order Confirmation',
    TriangleUpsell: 'Triangle Rewards Upsell',
    ProductCardAndBilling: 'Product Card And Billing',
    CertonaInit: 'Certona Init',
    ShareCartButton: 'Share Cart',
    MergeCartPopup: 'Merge Cart Popup',
    WarnCustomerPopup: 'Warn Customer Popup',
    StoreInitiatedCartPopup: 'Store Initiated Cart Popup',
    OrderDetailsHeader: 'Order Details Header',
    OrderHistory: 'Order History',
    NearbyStores: 'Nearby Stores',
    SecondaryNavigation: 'Secondary Navigation',
    DynamicFeaturedList: 'Dynamic Featured List',
    CategoryMasthead: 'Category Masthead',
    DemoVideo: 'Video Gallery',
    ReferenceComponent: 'Reference Component Wrapper',
    LanguageToggleHandler: 'Language Toggle',
    FrequentlyBoughtTogether: 'Frequently Bought Together',
    CategoryStacked: 'Category Stacked',
    PageInit: 'Page Init',
    FeaturedProductListDynamic: 'Featured Product List Dynamic',
    CategoryCarousel: 'Category Carousel',
    StoreSelectorModalComp: 'Store Selector',
    VehicleSelector: 'Vehicle Container',
    StackedCategoryList: 'Stacked Category List',
    OrderExpiredError: 'Order Expired Error',
    Video: 'Video',
    FullPageSpinnerComponent: 'Full Page Spinner Component',
    OrderUpdatesSubscription: 'Order Updates Subscription',
    HtmlSiteMap: 'Site Map',
    AutoAppointmentFrame: 'Auto Appointment Frame',
    ResponsiveCockpitIFrameMessenger: 'Responsive Cockpit IFrame Messenger',
    AutoServiceButton: 'Auto Service Button',
    DigitalFlyer: 'Digital Flyer',
    BackToTop: 'BackToTop',
    PackageLandingPage: 'select package product',
    TriangleMCBanner: 'Triangle MC Banner',
    BannerDisclaimer: 'Banner Disclaimer',
    CheckOrderStatus: 'Check Order Status',
    SaveForLater: 'Safe for Later',
    SaveTheSaleCartPopup: 'SaveTheSaleCartPopup',
    SkusDetails: 'Skus Details',
}
