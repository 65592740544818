import React, { Validator } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import {
    OptionsOnCartItem,
    ProductItemInfoProps,
    ProductOption,
    FulfillmentMethods,
    ItemInlineErrorType,
} from './ProductItemInfo.type'
import { useMobileLayoutState } from '../../hooks/layout.hook'
import Icon from '../Icon'
import Tag from '../Tag'
import { getLanguage, isArrayNotEmpty, replaceStrWithDynamicVal, magicNumber, addModalAttribute } from '../../utils'
import Price from '../Price'
import { totalCurrentPrice } from '../VehicleProductCard/VehicleProduct.helper'
import { filterSpecification } from './ProductCartItem.helper'
import PromoMessages from '../PromoMessages'
import { useGenerateSaveMessage } from '../Price/Price.helper'
import { wishlistPrefixId } from '../../utils/getAccessibilityId'
import { isAtleastOneParamValid } from '../../utils/validParams'

/**
 * ProductItemInfo component
 * @param {ToastProps} props -changeShipToHomeCTALabel,
        isFreePickUpAtStore,
        isShipToHome,
        pickAtStoreCTALabel,
        isServices,
        servicesDisclaimer,
        productData,
        serviceData,
        onChangeFulfillmentOption,
        availaibleShipLabel
 * @return {JSX.Element} returns Toast Component
 */
// eslint-disable-next-line complexity
const ProductItemInfo: React.FC<ProductItemInfoProps> = ({ ...props }) => {
    const {
        isFreePickUpAtStore,
        isShipToHome,
        onlineOrdering,
        isServices,
        productData,
        onChangeFulfillmentOption,
        availableShipLabel,
        isProductService,
        productServiceTitle,
        serviceData,
        itemAvailabilityInfo,
        bulkLabel,
        isWishList,
        cartModal,
        totalLabel,
        mmQtyLabel,
        renderRebate,
        reviewsContainerId,
        hideQuantitySelector,
        qtyAbbrLabel,
        changeBrowserBackUrl,
        getChangeFulfillmentOption,
        isLowStock,
        isDetailPage,
        availabilityBulletSubtype,
        getStockInfoMessage,
        limitedStockMessage,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        singleShippingMethodOption,
        a11yCloseIconLabel,
        a11yTooltipIcon,
        appliedPromoPrefix,
        saveLabel,
        isSTS,
        saleMessagesRules,
        enableShowPromotionsAndRomanceCopy,
    } = props

    const {
        name,
        baseProduct,
        brand,
        isBulk,
        rebate,
        quantity: itemQuantity,
        totalPrice,
        options,
        url,
        productWheelType,
        basePrice,
        adjustedPrice,
        basePriceValue,
        adjustedPriceValue,
    } = productData

    const infoClass = `${PREFIX}-shopping-cart__product`
    const unfinishedPkgClass = `${PREFIX}-unfinished-package__product`
    const serviceClass = isServices ? `${infoClass}__service-title` : ''
    const serviceInfoClass = isServices ? `${infoClass}__service-info` : ''
    const mergeModalClass = `${PREFIX}-merge-cart-modal`
    const orderConfirmationClass = `${PREFIX}-order-confirmation`
    const promoMessagesStyleClassMobile = `${PREFIX}-md-none ${PREFIX}-xs-block ${PREFIX}-sm-block ${PREFIX}-promo-messages-wrapper`
    const promoMessagesStyleClassDesktop = `${PREFIX}-md-block ${PREFIX}-xs-none ${PREFIX}-sm-none ${PREFIX}-promo-messages-wrapper`
    const [isMobileLayout] = useMobileLayoutState() as [boolean]

    /**
     * This function is used to filter the specification based on size, style and offest
     * @param {number} index
     * @return {Specification}
     */
    const specificationsArr = filterSpecification(
        productData?.specifications,
        props.specificationsFromProps?.[props.isUnfinishedPackage ? magicNumber.ONE : magicNumber.ZERO],
    )

    /* Below are props sent to common component: StoreSelectorModal */

    /**
     * function to render bulk items
     * @return {JSX.Element}
     */
    const renderBulkItems = (): JSX.Element => {
        return (
            isBulk && (
                <div className={`${infoClass}__bulk-label-container`}>
                    <Icon type="ct-error" size="md"></Icon>
                    <span className={`${infoClass}__bulk-label`}>{bulkLabel}</span>
                </div>
            )
        )
    }

    /**
     * function to check item availability
     * @return {boolean}
     */
    const checkIfAvailable = (): boolean => {
        return !(
            itemAvailabilityInfo?.showInlineError &&
            !itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.AVAILABILITY_INFO)
        )
    }

    /**
     * This is used to check whether the position class should apply on availability option
     * @param {string} showChangeFulfillmentOption
     * @return {boolean}
     */
    const isAvailabilityPosition = (showChangeFulfillmentOption): boolean => {
        return (
            isDetailPage &&
            !(!!showChangeFulfillmentOption && onlineOrdering) &&
            !isArrayNotEmpty(options) &&
            !(rebate && Object.keys(rebate).length)
        )
    }

    /**
     * function to check if it is possible to render promo message component
     * @return {boolean}
     */
    const isRenderPromoMessages = (): boolean => {
        const isMessagePresent = isAtleastOneParamValid(
            isArrayNotEmpty(productData?.promoMessages),
            isArrayNotEmpty(productData?.restrictedPromoMessages),
            isArrayNotEmpty(productData?.appliedPromoMessages),
        )
        return isMessagePresent && (!isWishList || enableShowPromotionsAndRomanceCopy)
    }

    /**
     * function to render availability info of the product
     * @return {JSX.Element}
     */
    const renderAvailability = (): JSX.Element => {
        const showChangeFulfillmentOption = getChangeFulfillmentOption()
        const availabilityPositionClass = isAvailabilityPosition(showChangeFulfillmentOption)
            ? `${infoClass}__availability-position`
            : ''
        return (
            <>
                <div className={`${infoClass}--desktop`}>{renderUrgencyLimitedStock()}</div>
                <div
                    className={`${infoClass} ${infoClass}__availability_section ${PREFIX}-md-block ${PREFIX}-xs-none ${PREFIX}-sm-none ${availabilityPositionClass}`}>
                    {!isDetailPage && <ul className={`${infoClass}__availability`}>{availableShipLabel()}</ul>}
                    {isServiceDisclaimer()}
                    {!singleShippingMethodOption && !!showChangeFulfillmentOption && onlineOrdering && (
                        <button
                            data-testid="fulfillment-btn"
                            className={`${infoClass}__fulfillment-btn`}
                            onClick={e => {
                                addModalAttribute(e)
                                onChangeFulfillmentOption(
                                    !isShipToHome ? FulfillmentMethods.STH : FulfillmentMethods.BOPIS,
                                    productData?.entryNumber,
                                )
                            }}>
                            {showChangeFulfillmentOption}
                        </button>
                    )}
                    {/* TO-DO: modifying rebate UI as per working implementation */}
                    {!isServices && renderRebate()}
                </div>
            </>
        )
    }

    const rebateAndInStockLabelforWishList = () => {
        return (
            <>
                <div
                    className={`${infoClass} ${PREFIX}-md-block ${
                        isWishList ? `${PREFIX}-sm-block` : `${PREFIX}-sm-none`
                    } ${PREFIX}-xs-none`}>
                    {isWishList &&
                    availabilityBulletSubtype &&
                    itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.IN_STOCK_TAG) ? (
                        <Tag type="availability-bullet" subType={`${availabilityBulletSubtype(isLowStock)}`} isLarge>
                            {getStockInfoMessage()}
                        </Tag>
                    ) : null}
                </div>
                {isWishList ? (
                    <div className={`${infoClass} ${PREFIX}-md-block ${PREFIX}-xs-none ${PREFIX}-sm-none`}>
                        {renderRebate()}
                    </div>
                ) : null}
            </>
        )
    }

    /**
     * Render an urgency limited stock if it is necessary
     * @return {JSX.Element}
     */
    const renderUrgencyLimitedStock = (): JSX.Element | null => {
        const productQty = productData?.fulfillment?.stockItemAvailability?.storeQuantity
        return productData?.isUrgentLowStock && !!productQty ? (
            <div className={`${infoClass}__limitedStock`}>
                {replaceStrWithDynamicVal(limitedStockMessage, productQty)}
            </div>
        ) : null
    }

    /**
     * function to display product info text
     *
     * @return {JSX.Element} returns product info text
     */
    const isProductInfo = (): JSX.Element => {
        return (
            <>
                {renderBulkItems()}
                {isArrayNotEmpty(options) && (
                    <div className={`${infoClass}__colour`}>
                        {options.map((option: ProductOption) => {
                            return (
                                <div key={option.value}>
                                    {option.display}: {option.value}
                                </div>
                            )
                        })}
                    </div>
                )}
                {isRenderPromoMessages() ? (
                    <div className={promoMessagesStyleClassDesktop}>{renderPromoMessages()}</div>
                ) : null}
                {checkIfAvailable() && renderAvailability()}
            </>
        )
    }

    const unfinishedPackageClass = props.isUnfinishedPackage ? `${PREFIX}-unfinished-package__product-name` : ''

    const mergeCartModalTitleClamp = (): JSX.Element => {
        return (
            <div className={`${!isMobileLayout ? `${unfinishedPackageClass}` : ``}`}>
                <div
                    className={`${
                        isMobileLayout
                            ? `${infoClass}__title--mobile-ellipsis`
                            : `${infoClass}__title--desktop-ellipsis`
                    }`}>
                    {name}
                </div>
            </div>
        )
    }

    const packageClass = props.isUnfinishedPackage ? `${infoClass}__package` : ''
    const unfinishedSizeClass = props.isUnfinishedPackage
        ? `${infoClass}__colour ${packageClass}-colour ${unfinishedPkgClass}-colour`
        : `${infoClass}__specification`

    /**
     * function to return specifications
     * in case of unfinished packages we are showing just size not dimension
     * @return {JSX.Element}
     */
    const renderSpecifications = (): JSX.Element => {
        return (
            isArrayNotEmpty(specificationsArr) && (
                <div className={`${unfinishedSizeClass}`}>
                    {specificationsArr.map(spec => (
                        <div key={spec.code}>{`${spec?.label}: ${spec?.value}`}</div>
                    ))}
                </div>
            )
        )
    }

    /**
     * function to return true for unfinished package and plp package modal
     * @return {boolean}
     */
    const showSpecificationBelowName = (): boolean => {
        return props.isPackage || props.isUnfinishedPackage
    }

    /**
     * Toggle between product and service and render the title of the cart Item.
     *
     * @return {JSX.Element} title component.
     */
    const titleComponent = (): JSX.Element => {
        const productLink = isProductService ? serviceData?.url : url
        return (
            <div className={`${infoClass}__title ${serviceClass} ${packageClass}-title`}>
                {!isWishList ? (
                    productLink ? (
                        <a
                            data-testid="product-title"
                            href={isProductService ? serviceData.url : url}
                            className={`${infoClass}__title-link`}
                            onClick={() => {
                                hideQuantitySelector && changeBrowserBackUrl()
                            }}>
                            {/* TODO: Interface for name should be changed to string
                        Changing the name.label to name. */}
                            {displayproductName()}
                        </a>
                    ) : (
                        displayproductName()
                    )
                ) : (
                    // TODO: Interface for name should be changed to string
                    // Changing the name.label to name.
                    <a
                        href={url}
                        className={`${infoClass}__title-link`}
                        data-link-value={name}
                        id={wishlistPrefixId(productData?.code)}>
                        {name}
                    </a>
                )}
                {showSpecificationBelowName() && renderSpecifications()}
            </div>
        )
    }

    /**
     * function to display product name
     * @return {JSX.Element | string}
     */
    const displayproductName = (): JSX.Element | string => {
        return isProductService ? serviceData?.name : cartModal ? mergeCartModalTitleClamp() : name
    }

    /**
     * function to check for services
     * @return {boolean}
     */
    const checkServices = (): boolean => {
        return isServices && isProductService
    }

    /**
     * function to render variant details for wishlistitems
     * @return {JSX.Element | null}
     */
    const renderVariantDetails = (): JSX.Element | null => {
        return isWishList && !productWheelType && isArrayNotEmpty(options) ? (
            <ul className={`${infoClass}__variants`}>
                {options.map((item: ProductOption, index: number) => (
                    <li
                        key={`${index}${item?.display}`}
                        className={`${infoClass}__colour ${infoClass}__colour--wishlist`}>
                        {`${item?.display}: ${item?.value}`}
                    </li>
                ))}
            </ul>
        ) : null
    }

    /**
     * renders review component
     * @return {JSX.Element}
     */
    const renderReview = (): JSX.Element => {
        return (
            <>
                {isWishList && (
                    <div className={`${PREFIX}-buy-box__rating`}>
                        <a href={`${url}#${reviewsContainerId}`} className={`${PREFIX}-buy-box__ratings-btn`}>
                            <div
                                data-bv-show="inline_rating"
                                data-bv-product-id={baseProduct}
                                data-bv-seo="false"></div>
                        </a>
                    </div>
                )}
            </>
        )
    }

    const packageQuantity = props.isUnfinishedPackage ? `${mergeModalClass}_package-quantity` : ''

    const showTotalPrice = !props.isPackage && !props.isUnfinishedPackage

    /**
     * Function caluculates the discount variations on the product in percent
     * @param { number } basePricevalue Base price of product
     * @param { number } adjustedPricevalue Price after discount
     * @return { number } discount price variations in percent
     */
    const calculateSavePercentage = (basePricevalue: number, adjustedPricevalue: number): number => {
        const savePercent = 100
        const percent = ((basePricevalue - adjustedPricevalue) / basePricevalue) * savePercent
        return Math.round(percent)
    }

    /**
     * Function caluculates the discount variations on the product in dollar
     * @param { number } basePricevalue Base price of product
     * @param { number } adjustedPricevalue Price after discount
     * @return { number } discount price variations in dollar
     */
    const calculateSavedDollarValue = (basePricevalue: number, adjustedPricevalue: number): number => {
        return Math.round(basePricevalue - adjustedPricevalue)
    }

    const saveMessage = useGenerateSaveMessage(
        productData.saleCut,
        productData?.originalPrice,
        saleMessagesRules,
        productData?.currentPrice,
    )

    /**
     * function to return quantity and price for merge cart modal
     * @return {JSX.Element|null}
     */
    const mergeCardTotal = (): JSX.Element | null => {
        return cartModal ? (
            <>
                <div className={`${mergeModalClass}_quantity ${packageQuantity}`}>
                    <div
                        className={
                            isSTS
                                ? `${PREFIX}-xs-none ${PREFIX}-sm-block ${PREFIX}-price-margin-bottom-sts`
                                : `${PREFIX}-xs-none ${PREFIX}-sm-block`
                        }>
                        {`${mmQtyLabel} ${itemQuantity}`}
                    </div>
                    <div
                        className={
                            isSTS
                                ? `${PREFIX}-sm-none ${PREFIX}-xs-block ${mergeModalClass}__quantity-xs-margin ${PREFIX}-price-margin-bottom-sts`
                                : `${PREFIX}-sm-none ${PREFIX}-xs-block ${mergeModalClass}__quantity-xs-margin`
                        }>
                        {`${mmQtyLabel} ${itemQuantity}`}
                    </div>
                    {isSTS && (
                        <>
                            {!adjustedPriceValue && (
                                <>
                                    <div
                                        className={`${PREFIX}-xs-none
                                        ${PREFIX}-sm-block ${PREFIX}-price-info`}>
                                        {basePrice}
                                    </div>
                                    <div
                                        className={`${PREFIX}-sm-none
                                        ${PREFIX}-xs-block ${mergeModalClass}__quantity-xs-margin
                                        ${PREFIX}-price-info`}>
                                        {basePrice}
                                    </div>
                                </>
                            )}
                            {adjustedPriceValue && (
                                <>
                                    <div
                                        className={`${PREFIX}-xs-none
                                        ${PREFIX}-sm-block`}>
                                        {adjustedPrice}
                                    </div>
                                    <div
                                        className={`${PREFIX}-sm-none
                                        ${PREFIX}-xs-block ${mergeModalClass}__quantity-xs-margin`}>
                                        {adjustedPrice}
                                    </div>
                                    <div
                                        className={`${PREFIX}-xs-none
                                        ${PREFIX}-sm-block ${PREFIX}-price-info-sts`}>
                                        {basePrice}
                                    </div>
                                    <div
                                        className={`${PREFIX}-sm-none
                                        ${PREFIX}-xs-block ${mergeModalClass}__quantity-xs-margin
                                        ${PREFIX}-price-info-sts`}>
                                        {basePrice}
                                    </div>
                                    <div className={`${PREFIX}-xs-none ${PREFIX}-sm-block`}>
                                        {`${saveLabel} ${calculateSavePercentage(
                                            basePriceValue,
                                            adjustedPriceValue,
                                        )}% ($${calculateSavedDollarValue(basePriceValue, adjustedPriceValue)})`}
                                    </div>
                                    <div
                                        className={`${PREFIX}-sm-none
                                        ${PREFIX}-xs-block ${mergeModalClass}__quantity-xs-margin`}>
                                        {`${saveLabel} ${calculateSavePercentage(
                                            basePriceValue,
                                            adjustedPriceValue,
                                        )}% ($${calculateSavedDollarValue(basePriceValue, adjustedPriceValue)})`}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {props.isUnfinishedPackage && (
                        <div className={`${mergeModalClass}_package-price`}>
                            <Price
                                currentPrice={totalCurrentPrice(
                                    props.skus,
                                    productData?.currentPrice,
                                    props.isStaggered,
                                    props.vehicleInformationPresent,
                                    props.tireInformationPresent,
                                )}
                                language={props.language}
                                a11yStrikeOutPrice={a11yStrikeOutPrice}
                                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                                saveMessage={saveMessage}
                                a11yCloseIconLabel={a11yCloseIconLabel}
                                a11yTooltipIcon={a11yTooltipIcon}
                            />
                        </div>
                    )}
                </div>
                {showTotalPrice && totalPrice && (
                    <div className={`${mergeModalClass}_price`}>
                        {totalLabel}&nbsp;
                        <Price
                            currentPrice={totalPrice}
                            language={getLanguage()}
                            a11yStrikeOutPrice={a11yStrikeOutPrice}
                            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                            a11yCloseIconLabel={a11yCloseIconLabel}
                            a11yTooltipIcon={a11yTooltipIcon}></Price>
                    </div>
                )}
            </>
        ) : null
    }
    const renderQuantity = (): JSX.Element | null => {
        return hideQuantitySelector ? (
            <div
                className={`${orderConfirmationClass}__quantity ${
                    isServices ? `${orderConfirmationClass}__quantity--service` : ''
                }`}>
                {`${qtyAbbrLabel}`} {isProductService ? serviceData.quantity : itemQuantity}
            </div>
        ) : null
    }

    /**
     * function to display service disclaimer text
     *
     * @return {JSX.Element} returns service disclaimer text
     */
    const isServiceDisclaimer = (): JSX.Element => {
        const isServiceSellable = serviceData ? serviceData.sellable : true
        return isServices && isServiceSellable ? (
            <p
                className={`${infoClass}__service-disclaimer ${infoClass} ${PREFIX}-md-block ${PREFIX}-xs-block ${PREFIX}-sm-block`}>
                {props.servicesDisclaimer}
            </p>
        ) : (
            isShipToHome && isBulk && (
                <p
                    className={`${infoClass}__service-disclaimer ${infoClass} ${PREFIX}-md-block ${PREFIX}-xs-block ${PREFIX}-sm-block`}>
                    <Icon type="ct-information-details" size="sm" />
                    {props.productDisclaimer}
                </p>
            )
        )
    }

    const packageProduct = props.isUnfinishedPackage ? `${PREFIX}-unfinished-package__product` : ''

    /**
     * function to render PromoMessages
     * @return {JSX.Element}
     */
    const renderPromoMessages = (): JSX.Element => {
        return (
            <PromoMessages
                isProductLevel={true}
                productPromoMessages={productData?.promoMessages}
                productRestrictedPromoMessages={productData?.restrictedPromoMessages}
                appliedPromoPrefix={appliedPromoPrefix}
                appliedPromoMessages={productData?.appliedPromoMessages}
                a11yTooltipIcon={a11yTooltipIcon}
                a11yCloseIconLabel={a11yCloseIconLabel}
            />
        )
    }

    const isRenderProductInfo = (): boolean => {
        return (isFreePickUpAtStore || isShipToHome) && !isProductService
    }

    return (
        <div
            className={`${infoClass} ${serviceInfoClass} ${packageProduct} ${PREFIX}-col-md-8 ${PREFIX}-col-sm-12 ${PREFIX}-col-xs-6`}>
            {!isServices && (
                <div className={`${infoClass}__brand} ${packageClass}-brand ${unfinishedPkgClass}-brand`}>
                    {brand?.label}
                </div>
            )}
            {checkServices() && <div className={`${infoClass}__brand`}>{productServiceTitle}</div>}
            {titleComponent()}
            {renderReview()}
            {renderQuantity()}
            {renderVariantDetails()}
            {isRenderProductInfo() && isProductInfo()}
            {mergeCardTotal()}
            {isRenderPromoMessages() ? (
                <div className={isRenderProductInfo() ? promoMessagesStyleClassMobile : promoMessagesStyleClassDesktop}>
                    {renderPromoMessages()}
                </div>
            ) : null}
            {!showSpecificationBelowName() &&
                !cartModal &&
                productData.specifications &&
                Object.keys(productData.specifications).length > 0 &&
                renderSpecifications()}
            {rebateAndInStockLabelforWishList()}
        </div>
    )
}

ProductItemInfo.defaultProps = {
    isProductService: false,
}

ProductItemInfo.propTypes = {
    changeShipToHomeCTALabel: PropTypes.string,
    betweenLabel: PropTypes.string,
    availableLabel: PropTypes.string,
    pickAtStoreCTALabel: PropTypes.string,
    isFreePickUpAtStore: PropTypes.bool,
    isShipToHome: PropTypes.bool,
    onlineOrdering: PropTypes.bool,
    isServices: PropTypes.bool,
    servicesDisclaimer: PropTypes.string,
    homePageLink: PropTypes.string,
    productData: PropTypes.object,
    onChangeFulfillmentOption: PropTypes.func,
    receiveBetweenLabel: PropTypes.string,
    availableShipLabel: PropTypes.func,
    getChangeFulfillmentOption: PropTypes.func,
    isProductService: PropTypes.bool,
    productServiceTitle: PropTypes.string,
    serviceData: PropTypes.any,
    itemAvailabilityInfo: PropTypes.object.isRequired as Validator<ItemInlineErrorType>,
    bulkLabel: PropTypes.string,
    isWishList: PropTypes.bool,
    cartModal: PropTypes.bool,
    a11yTooltipIcon: PropTypes.string,
    totalLabel: PropTypes.string,
    mmQtyLabel: PropTypes.string,
    renderRebate: PropTypes.func,
    productColorLabel: PropTypes.string,
    PDPLink: PropTypes.string,
    reviewsContainerId: PropTypes.string,
    a11yReviewRating: PropTypes.string,
    hideQuantitySelector: PropTypes.bool,
    productDisclaimer: PropTypes.string,
    qtyAbbrLabel: PropTypes.string,
    changeBrowserBackUrl: PropTypes.func,
    isLowStock: PropTypes.bool,
    isDetailPage: PropTypes.bool,
    isPackage: PropTypes.bool,
    outOfStockLabel: PropTypes.string,
    discontinuedLabel: PropTypes.string,
    roadRatedLabel: PropTypes.string,
    isUnfinishedPackage: PropTypes.bool,
    language: PropTypes.string,
    attributeOffsetLabel: PropTypes.string,
    skus: PropTypes.array,
    isStaggered: PropTypes.bool,
    vehicleInformationPresent: PropTypes.bool,
    tireInformationPresent: PropTypes.bool,
    availabilityBulletSubtype: PropTypes.func,
    getStockInfoMessage: PropTypes.func,
    showOrderDetailDisclaimer: PropTypes.func,
    specificationsFromProps: PropTypes.array,
    limitedStockMessage: PropTypes.string,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    singleShippingMethodOption: PropTypes.bool,
    saveLabel: PropTypes.string,
}

export default ProductItemInfo
