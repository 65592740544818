export const UPDATE_TIRE_DATA = 'UPDATE_TIRE_DATA'
export const SET_IS_TIRES_DATA_SYNCED_WITH_LS_ON_PAGE_LOAD = 'SET_IS_TIRES_DATA_SYNCED_WITH_LS_ON_PAGE_LOAD'
export const UPDATE_VEHICLE_DATA = 'UPDATE_VEHICLE_DATA'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export const UPDATE_IS_PACKAGE_FLOW = 'UPDATE_IS_PACKAGE_FLOW'
export const UPDATE_CURRENT_PACKAGE_ID = 'UPDATE_CURRENT_PACKAGE_ID'
export const UPDATE_AUTO_PACKAGES = 'UPDATE_AUTO_PACKAGES'
export const FETCH_TIRES_DATA_SUCCESS = 'FETCH_TIRES_DATA_SUCCESS'
export const FETCH_WHEELS_DATA_SUCCESS = 'FETCH_WHEELS_DATA_SUCCESS'
export const START_NEW_PACKAGE_FLOW = 'START_NEW_PACKAGE_FLOW'
export const VEHICLE_SELECTION_MODE = 'VEHICLE_SELECTION_MODE'
export const RESET_TIRE_DATA = 'RESET_TIRE_DATA'
export const SET_PACKAGE_LANDING_BREADCRUMB = 'SET_PACKAGE_LANDING_BREADCRUMB'
export const SET_VEHICLE_API_STATUS = 'SET_VEHICLE_API_STATUS'
export const RESET_AUTO_PACKAGES = 'RESET_AUTO_PACKAGES'
export const SET_IS_CREATE_PACKAGE_MODAL_OPEN = 'SET_IS_CREATE_PACKAGE_MODAL_OPEN'
export const SET_IS_CREATE_PACKAGE_CLICK = 'SET_IS_CREATE_PACKAGE_CLICK'
export const SET_VEHICLE_BANNER_VISIBLE = 'SET_VEHICLE_BANNER_VISIBLE'
export const SET_VEHICLE_LIST_VISIBILITY = 'SET_VEHICLE_LIST_VISIBILITY'
