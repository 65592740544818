import { RootState } from '../reducers'
import { WishlistProduct, WishlistResponse, wishlistSuccess } from '../models/wishlist.interface'
import { wishlistState } from '../reducers/wishlist.reducer'

export const wishlistStateSelector = (state: RootState): wishlistState => state.wishlist
export const wishlistDataSelector = (state: RootState): wishlistSuccess => state.wishlist.wishlistData
export const wishlistItemsSelector = (state: RootState): WishlistResponse => state.wishlist.wishlistItems
export const wishlistItemsProductsSelector = (state: RootState): WishlistProduct[] =>
    state.wishlist.wishlistItems.products
export const wishlistRemoveSuccessSelector = (state: RootState): boolean => state.wishlist.wishlistRemoveSuccess
export const wishlistLoadSuccessSelector = (state: RootState): boolean => state.wishlist.wishlistLoadSuccess
