import { createStore, applyMiddleware, Middleware, Dispatch } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../redux/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as actionCreators from '../redux/actions'
import { prerenderMiddleware } from '../redux/customMiddlewares/prerenderMiddleware'

// Below modification is used to get the trace option in the redux dev tool.
const composeEnhancers = composeWithDevTools({
    actionCreators,
    trace: true,
    traceLimit: 25,
})

const configureStore = () =>
    createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk, prerenderMiddleware as Middleware<unknown, unknown, Dispatch>)),
    )

export default configureStore()
