import { Dispatch } from 'redux'
import { AxiosResponse, AxiosError } from 'axios'

import { RequestBodyType } from '../utils/httpClient'
import {
    linkMasterCardSuccessAction,
    linkMasterCardErrorAction,
    saveMasterCardEntryAction,
    clearLinkMasterCardResponseAction,
    clearLinkMasterCardErrorAction,
} from '../actionCreators/linkMasterCard.actionCreators'
import {
    LinkMasterCardSuccessResponse,
    LinkMasterCardRequest,
    LinkMasterCardErrorResponse,
} from '../models/linkMasterCard.interface'
import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'

const environment = getEnvironment()
const httpClient = getHttpClient()

export const linkMasterCard =
    (requestPayload: LinkMasterCardRequest) =>
    (dispatch: Dispatch): Promise<AxiosResponse> => {
        // const baseEndPoint = API_ENDPOINTS.dssLoyaltyCardLookup
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string

        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }

        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.dssLoyaltyCardLookup}`

        return new Promise((resolve, reject) => {
            httpClient
                .apiPost(url, requestPayload.masterCard as unknown as RequestBodyType, headers, true)
                .then((data: AxiosResponse<LinkMasterCardSuccessResponse>) => {
                    dispatch(linkMasterCardSuccessAction(data.data))
                    resolve(data)
                })
                .catch((error: AxiosError<LinkMasterCardErrorResponse>) => {
                    const errorResponse = error.response ? error.response.data : error
                    dispatch(linkMasterCardErrorAction(errorResponse))
                    reject(errorResponse)
                })
        })
    }

export const saveMasterCardEntry =
    (requestPayload: LinkMasterCardRequest) =>
    (dispatch: Dispatch): void => {
        dispatch(saveMasterCardEntryAction(requestPayload))
    }

export const clearLinkMasterCardResponse =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkMasterCardResponseAction())
    }

export const clearLinkMasterCardError =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearLinkMasterCardErrorAction())
    }
