import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import store from '../../store'
import { setIsGigyaLoaded } from '../../redux/actionCreators/gigyaScreenSet.actionCreators'
import { fetchUserProfile } from '../../redux/actions/user.profile.action'
import { isSsoSessionPendingSelector } from '../../redux/selectors/gigyaScreenSetData.selectors'
import { getQueryString } from '@nl/lib'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import PropTypes from 'prop-types'

interface GigyaInitType {
    isFlyerPage?: boolean
}

const GigyaInit: React.FC<GigyaInitType> = ({ ...props }) => {
    const isSsoSessionPending = useSelector(isSsoSessionPendingSelector)

    const dispatch = useDispatch()
    const windowGigyaObj = window.gigya

    /**
     * set flag true when gigya is loaded
     */
    useEffect(() => {
        if (windowGigyaObj) {
            store.dispatch(setIsGigyaLoaded(true))
            isSsoSessionPending && dispatch(fetchUserProfile())
            // show email signup modal on pageload
            const queryParams = window.location.search
            const pageType = getPageType()
            const showEmailSignupModal = getQueryString(queryParams, 'emailSignup')
            const signupParamFromQueryParams = getQueryString(queryParams, 'signup')
            if (
                (showEmailSignupModal && pageType === pageTypes.homePage) ||
                (signupParamFromQueryParams === 'true' && props.isFlyerPage)
            ) {
                gigyaLoadFunctions('ODP-LiteRegistration', undefined, '', false, false)
            }
        }
    }, [dispatch, isSsoSessionPending, windowGigyaObj, props.isFlyerPage])

    return null
}

GigyaInit.propTypes = {
    isFlyerPage: PropTypes.bool,
}
export default GigyaInit
export { GigyaInit }
