import { createAction } from 'typesafe-actions'
import {
    FETCH_CT_MONEY_BALANCE_SUCCESS,
    FETCH_CT_MONEY_BALANCE_ERROR,
    RESET_CT_MONEY_BALANCE,
} from '../types/ctMoneyBalance/ctMoneyBalance.actionTypes.constant'
import { CTMoneyBalanceResponse, ErrorResponse } from '../models/ctMoneyBalance.interface'

export const fetchCTMoneyBalanceSuccessAction = createAction(FETCH_CT_MONEY_BALANCE_SUCCESS)<CTMoneyBalanceResponse>()
export const fetchCTMoneyBalanceErrorAction = createAction(FETCH_CT_MONEY_BALANCE_ERROR)<ErrorResponse>()
export const resetCTMoneyBalanceAction = createAction(RESET_CT_MONEY_BALANCE)()
