export interface DropdownOptions {
    label: string
    id: number
    selected?: boolean
}

export interface DropdownType {
    optionsState: DropdownOptionsState
    label: string
    id: string
    isDisabled: boolean
    value: string
    options: DropdownOptions[]
}

export enum DropdownOptionsState {
    NOT_LOADED = 'NOT_LOADED',
    TO_SKIP = 'TO_SKIP',
    LOADED = 'LOADED',
    UPDATED = 'UPDATED',
    PRESELECTED_AND_UPDATED = 'PRESELECTED_AND_UPDATED',
    PRESELECTED = 'PRESELECTED',
    DELETED = 'DELETED',
    API_FAILED = 'API_FAILED',
}
