import {
    CartItemsData,
    MiniCartData,
    MiniCartDeliveryAddress,
    MiniCartVehicleInformation,
    CartOrderEntries,
    MiniCartOrderEntries,
    VersionedMiniCartData,
} from '../redux/models/cart.interface'
import { versionForMiniCartData } from '../globalConstants/global.constant'

/**
 * Function to convert CartItemsData to MiniCartData or VersionedMiniCartData
 * @param {CartItemsData} cartData CartItemsData for converting
 * @param {boolean} isSetToLocalStorage used for identify version for setting to LocalStorage
 * @return {MiniCartData | VersionedMiniCartData} returns MiniCartData or VersionedMiniCartData
 */
export const convertToMiniCartData = (
    cartData: CartItemsData,
    isSetToLocalStorage: boolean,
): MiniCartData | VersionedMiniCartData => {
    const cartId = cartData?.cartId
    const numberOfItems = cartData?.numberOfItems
    const updatedCart = cartData?.updatedCart?.numberOfItems
    const deliveryAddress = cartData?.deliveryAddress?.address.postalCode as MiniCartDeliveryAddress
    const orderEntries = cartData?.orderEntries && convertToMiniCartOrderEntries(cartData.orderEntries)
    let version
    if (isSetToLocalStorage) {
        version = versionForMiniCartData
    }

    return {
        cartId,
        numberOfItems,
        updatedCart,
        orderEntries,
        deliveryAddress,
        version,
    }
}

/**
 * Function to convert VersionedMiniCartData to MiniCartData
 * @param {VersionedMiniCartData} versionedMiniCartData VersionedMiniCartData for converting
 * @return {MiniCartData} returns MiniCartData
 */
export const convertToMiniCartDataWithoutVersion = (versionedMiniCartData: VersionedMiniCartData): MiniCartData => {
    if (!!versionedMiniCartData.version) {
        versionedMiniCartData.version = undefined
    }
    return versionedMiniCartData
}

/**
 * Function to convert CartOrderEntries[] to MiniCartOrderEntries[]
 * @param {CartOrderEntries[]} orderEntries CartOrderEntries[] for converting
 * @return {MiniCartOrderEntries[]} returns MiniCartOrderEntries[]
 */
const convertToMiniCartOrderEntries = (orderEntries: CartOrderEntries[]): MiniCartOrderEntries[] => {
    return orderEntries.map(entry => {
        const vehicleInformation = entry.vehicleInformation
            ? {
                  year: entry.vehicleInformation.year,
                  body: entry.vehicleInformation.body,
                  make: entry.vehicleInformation.make,
                  model: entry.vehicleInformation.model,
                  options: entry.vehicleInformation.options,
              }
            : null
        const deliveryMode = entry.fulfillment?.deliveryMode
        return {
            vehicleInformation: vehicleInformation as MiniCartVehicleInformation,
            entryType: entry.entryType,
            cartEntryReference: entry.cartEntryReference,
            fulfillment: deliveryMode,
            code: entry.code,
            isBulk: entry.isBulk,
        }
    })
}
