import { useEffect } from 'react'

/**
 * useWindowEvent Global window event listener
 *
 * @param {string} event - window events like resize, scroll etc
 * @param {func} callback - callback function for listening to the event
 */

const debounceTime = 300
const scrollDebounceTime = 100

export const useThrottleWindowEvent = (event: string, time: number, callback: () => void): void => {
    useEffect(() => {
        const throttleHandler = throttle(callback, time)
        window.addEventListener(event, throttleHandler)
        return () => window.removeEventListener(event, throttleHandler)
    }, [event, time, callback])
}

export const useWindowEvent = (event: string, callback: (event: UIEvent) => void): void => {
    useEffect(() => {
        window.addEventListener(event, callback)
        return () => window.removeEventListener(event, callback)
    }, [event, callback])
}

/**
 * useGlobalResizeEvent Global window resize event listener
 *
 * @param {func} callback - callback function for listening to the event
 * @return {func} returns global window event listener
 */
export const useGlobalResizeEvent = (callback: () => void): void => {
    return useThrottleWindowEvent('resize', debounceTime, callback)
}

/**
 * useGlobalScrollEvent Global window scroll event listener
 *
 * @param {func} callback - callback function for listening to the event
 * @return {func} returns global window event listener
 */
export const useGlobalScrollEvent = (callback: () => void): void => {
    return useThrottleWindowEvent('scroll', scrollDebounceTime, callback)
}

/**
 * useGlobalBeforeUnloadEvent Global window beforeunload event listener
 *
 * @param {func} callback - callback function for listening to the event
 * @return {func} returns global window event listener
 */
export const useGlobalBeforeUnloadEvent = (callback: (event: UIEvent) => void): void => {
    return useWindowEvent('beforeunload', callback)
}

/**
 * throttle Global window beforeunload event listener
 *
 * @param {func} callback - callback function for listening to the event
 * @param {number} time - throttle time
 * @return {void}
 */
const throttle = (callback: () => void, time: number): (() => void) => {
    let postponed = false
    let isLock = false
    const runCallBack = () => {
        try {
            isLock = true
            callback()
        } finally {
            setTimeout(function () {
                try {
                    isLock = false
                    postponed && runCallBack()
                } finally {
                    postponed = false
                }
            }, time)
        }
    }

    return () => {
        if (isLock) {
            postponed = true
        } else {
            runCallBack()
        }
    }
}
