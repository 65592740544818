import React, { ErrorInfo } from 'react'
import { logNewRelic } from '../NewRelic/newRelic.helper'

interface Props {
    name: string
    children: JSX.Element
}

class ErrorBoundary extends React.Component<Props> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logNewRelic({
            error,
            errorInfo,
            resource: this.props.name,
            logErrorName: 'renderReactComponent',
        })
    }

    render() {
        return this.props.children
    }
}

export default ErrorBoundary
