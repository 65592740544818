import React from 'react'
import { MessageActivationType } from './EmailActivationCard.type'
import PropTypes from 'prop-types'
import { magicNumber } from '../../utils/magicNumber'
import { replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { offerEndDateRegex } from '../../globalConstants/regexPatterns.constant'

const ActivationMessage = (props: MessageActivationType): JSX.Element => {
    const {
        ClassName,
        selectedCard,
        title,
        details,
        isCardVisible,
        messages,
        shopNowCtaA11yLabel,
        shopNowCtaLabel,
        shopNowCtaLink,
        shopNowCtaLinkTarget,
        weeklyOffersCtaLabel,
        weeklyOffersCtaLink,
        weeklyOffersCtaLinkTarget,
        weeklyOffersCtaA11yLabel,
        isLoyaltyIdInvalid,
    } = props

    const activationOfferDetails = (): JSX.Element => {
        if (isCardVisible) {
            const { daysLeft, shortDesc, offerEndDate } = selectedCard
            const message =
                daysLeft === String(magicNumber.ZERO)
                    ? messages?.staticMessage
                    : replaceStrWithDynamicVal(messages?.dynamicMessage, daysLeft)

            const updatedMessage =
                Number(daysLeft) > magicNumber.SEVEN
                    ? `${messages?.dynamicMessageWithDate || 'Offer Ends:'} ${offerEndDate?.replace(
                          offerEndDateRegex,
                          '$1/$2/$3',
                      )}`
                    : message

            return (
                <>
                    <p className={`${ClassName}_activation-message_description`}>{shortDesc}</p>
                    <p className={`${ClassName}_activation-message_offer-duration`}>{updatedMessage}</p>
                </>
            )
        } else return
    }
    return (
        <div
            className={`${ClassName}_activation-message ${
                isCardVisible ? '' : ClassName + '_activation-message-without-offer'
            }`}
            role="button"
            aria-pressed="false"
            tabIndex={0}>
            <div className={`${ClassName}_activation-message_title`}>
                <h1 className={`${ClassName}_activation-message_title__item`}>{title}</h1>
                {activationOfferDetails()}
            </div>
            <div className={`${ClassName}_activation-message_details`} dangerouslySetInnerHTML={{ __html: details }} />
            {isCardVisible && (
                <div className={`${ClassName}_button`}>
                    <a
                        aria-label={shopNowCtaA11yLabel}
                        className={`${ClassName}_button__link`}
                        href={shopNowCtaLink}
                        target={shopNowCtaLinkTarget}>
                        {shopNowCtaLabel}
                    </a>
                </div>
            )}
            {isLoyaltyIdInvalid && (
                <div className={`${ClassName}_button`}>
                    <a
                        aria-label={weeklyOffersCtaA11yLabel}
                        className={`${ClassName}_button__link`}
                        href={weeklyOffersCtaLink}
                        target={weeklyOffersCtaLinkTarget}>
                        {weeklyOffersCtaLabel}
                    </a>
                </div>
            )}
        </div>
    )
}

ActivationMessage.propTypes = {
    ClassName: PropTypes.string,
    selectedCard: PropTypes.object,
    title: PropTypes.string,
    details: PropTypes.string,
    isCardVisible: PropTypes.bool,
    messages: PropTypes.object,
    shopNowCtaA11yLabel: PropTypes.string,
    shopNowCtaLabel: PropTypes.string,
    shopNowCtaLink: PropTypes.string,
    shopNowCtaLinkTarget: PropTypes.string,
}

export default ActivationMessage
