import React from 'react'
import { PREFIX } from '../config'
import { OfferBannerType } from './OfferBanner.type'
import { applyAkamaiPolicy } from '../../utils'

const OfferBanner: React.FC<OfferBannerType> = props => {
    const { bannerImagePath, bannerImageAlt, badgeImageUrl, returnPolicy, bannerLogoImgComponentName } = props
    return (
        <>
            <div className={`${PREFIX}-offers-card__banner`}>
                <img
                    className={`${PREFIX}-offers-card__banner-icon`}
                    alt={bannerImageAlt}
                    {...applyAkamaiPolicy(bannerImagePath, bannerLogoImgComponentName, false, returnPolicy)}
                />
                <img
                    className={`${PREFIX}-offers-card__banner-icon`}
                    alt=""
                    {...applyAkamaiPolicy(badgeImageUrl, bannerLogoImgComponentName, false, returnPolicy)}
                />
            </div>
        </>
    )
}

export default OfferBanner
