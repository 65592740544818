import { Dispatch } from 'redux'

import {
    fetchEstimateDeliveryDateAction,
    estimateDeliveryDateFailureAction,
    setShowSpinner,
    resetEstimateDeliveryDateAction,
} from '../actionCreators'
import { estimateDeliveryDateService } from '../../services/estimateDeliveryDateService'
import {
    EstimateDeliveryDate,
    ErrorResponse,
    EstimateDeliveryDateParams,
} from '../models/estimateDeliveryDate.interface'
import { AxiosError } from 'axios'

export const getEstimateDeliveryDate =
    (params: EstimateDeliveryDateParams, showSpinner = false) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner(true))
        estimateDeliveryDateService
            .fetchEstimateDeliveryDate(params)
            .then((estimateDeliveryDateData: { data: EstimateDeliveryDate }) => {
                const isEstimateDeliveryDateData =
                    estimateDeliveryDateData.data &&
                    estimateDeliveryDateData.data?.startDate &&
                    estimateDeliveryDateData.data?.endDate
                if (isEstimateDeliveryDateData) {
                    dispatch(fetchEstimateDeliveryDateAction(estimateDeliveryDateData.data))
                } else {
                    dispatch(estimateDeliveryDateFailureAction({} as ErrorResponse))
                }
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(estimateDeliveryDateFailureAction(error.response))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to reset Estimate Delivery Date
 * @return {Dispatch}
 */
export const resetEstimateDeliveryDate =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetEstimateDeliveryDateAction())
    }
