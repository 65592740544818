export const WISHLIST_ERROR = 'WISHLIST_ERROR'
export const ADD_PRODUCT_TO_WISHLIST_SUCCESS = 'ADD_PRODUCT_TO_WISHLIST_SUCCESS'
export const REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS = 'REMOVE_PRODUCT_TO_WISHLIST_SUCCESS'
export const CHANGE_SUCCESS_VALUE_ON_CLOSE = 'CHANGE_SUCCESS_VALUE_ON_CLOSE'
export const RESET_WISHLIST_ACTIVE = 'RESET_WISHLIST_ACTIVE'
export const GET_WISHLIST_DATA_SUCCESS = 'GET_WISHLIST_DATA_SUCCESS'
export const GET_WISHLIST_DATA_FAILURE = 'GET_WISHLIST_DATA_FAILURE'
export const GET_WISHLIST_PRODUCT_CODES_DATA_SUCCESS = 'GET_WISHLIST_PRODUCT_CODES_DATA_SUCCESS'
export const GET_WISHLIST_PRODUCT_CODES_DATA_FAILURE = 'GET_WISHLIST_PRODUCT_CODES_DATA_FAILURE'
export const GET_WISHLIST_DETAILS_DATA_SUCCESS = 'GET_WISHLIST_DETAILS_DATA_SUCCESS'
export const GET_WISHLIST_DETAILS_DATA_FAILURE = 'GET_WISHLIST_DETAILS_DATA_FAILURE'
export const REMOVE_WISHLIST_DATA_SUCCESS = 'REMOVE_WISHLIST_DATA_SUCCESS'
export const RESET_SUCCESS_TOAST = 'RESET_SUCCESS_TOAST'
export const MERGE_WISHLIST_SUCCESS = 'MERGE_WISHLIST_SUCCESS'
