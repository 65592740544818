import { ActionType, getType } from 'typesafe-actions'
import { AOAHeaderState } from '../models/aoaHeader.interface'
import * as actions from '../actionCreators/aoaHeader.actionCreators'

const initialState = {
    isAOAHeaderVisible: true,
}

type Action = ActionType<typeof actions>
export const aoaHeaderReducer = (state: AOAHeaderState = initialState, action: Action): AOAHeaderState => {
    if (action.type === getType(actions.setAOAHeaderVisible)) {
        return { ...state, isAOAHeaderVisible: action?.payload }
    }
    return state
}
