import getLanguage from './getLanguage'
import { currencyShortForm, currencyFormat } from '../globalConstants/global.constant'
import { magicNumber } from './magicNumber'

const getBalance = (balanceAmount?: string): string => {
    const language = getLanguage() + '-' + currencyShortForm
    return new Intl.NumberFormat(language, {
        style: 'currency',
        currency: currencyFormat,
    }).format(Number(balanceAmount))
}

/**
 * check the value is greaterThan balance
 * @param {number} value
 * @param {number} balance
 * @return {boolean}
 */
const isAmountGreaterThan = (value?: number, balance?: number): boolean => {
    return value > balance
}

/**
 * function calculate divided value of currentPrice
 * @param {number} price
 * @param {number} month
 * @return {string}
 */
const getDivideByAmount = (price, month) => {
    const amount = (price / month).toFixed(magicNumber.TWO)
    return getBalance(amount)
}

/**
 * function calculate multiple value of currentPrice
 * @param {number} percentage
 * @param {number} price
 * @return {string}
 */
const getMultipleByAmount = (percentage, price) => {
    const amount = ((percentage / magicNumber.HUNDRED) * price)?.toFixed(magicNumber.TWO)
    return getBalance(amount)
}

export default getBalance
export { getBalance, isAmountGreaterThan, getDivideByAmount, getMultipleByAmount }
