import { createAction } from 'typesafe-actions'
import { CartPageAnalyticsInfo } from '../../analytics/components/Cart/cartPage.type'

import { wishlistSuccess, wishListError, WishlistResponse } from '../models/wishlist.interface'
import { UPDATE_ANALYTICS_INFO } from '../types/cart/cart.actionTypes.constant'

import {
    ADD_PRODUCT_TO_WISHLIST_SUCCESS,
    REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS,
    WISHLIST_ERROR,
    CHANGE_SUCCESS_VALUE_ON_CLOSE,
    RESET_WISHLIST_ACTIVE,
    GET_WISHLIST_DATA_FAILURE,
    GET_WISHLIST_DATA_SUCCESS,
    REMOVE_WISHLIST_DATA_SUCCESS,
    RESET_SUCCESS_TOAST,
    MERGE_WISHLIST_SUCCESS,
    GET_WISHLIST_PRODUCT_CODES_DATA_SUCCESS,
    GET_WISHLIST_PRODUCT_CODES_DATA_FAILURE,
    GET_WISHLIST_DETAILS_DATA_SUCCESS,
    GET_WISHLIST_DETAILS_DATA_FAILURE,
} from '../types/wishlist/wishlist.actionTypes.constant'

export const addProductToWishListSuccessAction = createAction(
    ADD_PRODUCT_TO_WISHLIST_SUCCESS,
    (payload: wishlistSuccess) => payload,
)<wishlistSuccess>()

export const wishListErrorAction = createAction(WISHLIST_ERROR, (payload: wishListError) => payload)<wishListError>()

export const removeProductFromWishListActionSuccess = createAction(
    REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS,
    (payload: wishlistSuccess) => payload,
)<wishlistSuccess>()

export const changeSuccessValue = createAction(
    CHANGE_SUCCESS_VALUE_ON_CLOSE,
    (payload: wishListError) => payload,
)<wishListError>()

export const resetWishlistActive = createAction(RESET_WISHLIST_ACTIVE)()

export const wishlistSuccessAction = createAction(
    GET_WISHLIST_DATA_SUCCESS,
    (payload: WishlistResponse) => payload,
)<WishlistResponse>()

export const wishlistFailureAction = createAction(GET_WISHLIST_DATA_FAILURE)()

export const wishlistUpdateAnalyticsInfo = createAction(UPDATE_ANALYTICS_INFO)<Partial<CartPageAnalyticsInfo>>()

export const removeWishlistSuccessAction = createAction(
    REMOVE_WISHLIST_DATA_SUCCESS,
    (payload: WishlistResponse) => payload,
)<WishlistResponse>()

export const resetSuccessToastAction = createAction(RESET_SUCCESS_TOAST)()

export const setMergeWishlistAction = createAction(MERGE_WISHLIST_SUCCESS, (payload: boolean) => payload)<boolean>()

export const wishlistProductCodesSuccessAction = createAction(
    GET_WISHLIST_PRODUCT_CODES_DATA_SUCCESS,
    (payload: WishlistResponse) => payload,
)<WishlistResponse>()

export const wishlistProductCodesFailureAction = createAction(GET_WISHLIST_PRODUCT_CODES_DATA_FAILURE)()

export const wishlistDetailsSuccessAction = createAction(
    GET_WISHLIST_DETAILS_DATA_SUCCESS,
    (payload: WishlistResponse) => payload,
)<WishlistResponse>()

export const wishlistDetailsFailureAction = createAction(GET_WISHLIST_DETAILS_DATA_FAILURE)()
