import { useState, useEffect, useRef } from 'react'

import { BREAKPOINTS } from '../config'
import { useGlobalResizeEvent } from '@nl/lib'

const checkForMobileLayout = (): boolean => window.innerWidth <= BREAKPOINTS.mobileMaxWidth

export const useMobileLayoutState = () => {
    const [isMobileLayout, setIsMobileLayout] = useState(checkForMobileLayout())

    const handleResize = (): void => {
        setIsMobileLayout(checkForMobileLayout())
    }

    useGlobalResizeEvent(handleResize)

    return [isMobileLayout, setIsMobileLayout]
}

export const useIntersect = (
    callback: (entry?: IntersectionObserverEntry) => void,
    options: IntersectionObserverInit,
) => {
    const [node, setNode] = useState<Element | null>(null)
    const observer = useRef<IntersectionObserver | null>(null)

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect()
        }

        observer.current = new window.IntersectionObserver(([eventEntry]) => callback(eventEntry), options)

        const { current: currentObserver } = observer

        if (node) {
            currentObserver.observe(node)
        }

        return () => currentObserver.disconnect()
    }, [node, callback, options])

    return setNode
}
