import { errorCodes } from '../globalConstants/global.constant'

/**
 * Checks if error string is partial auth CDS error
 * @param {String} err
 * @return {Boolean}
 */
export const isPartialAuthError = (err: string): boolean => err === errorCodes.errorPartialAuth

/**
 * Checks if error code is 101
 * @param {String} errCode
 * @return {Boolean}
 */
export const checkError101 = (errCode: string): boolean => errCode === errorCodes.error101

/**
 * Checks if error code is 00001
 * @param {String} errCode
 * @return {Boolean}
 */
export const checkError00001 = (errCode: string): boolean => errCode === errorCodes.error00001

/**
 * Checks if error code is 400
 * @param {String} errCode
 * @return {Boolean}
 */
export const checkError400 = (errCode: string): boolean => errCode === errorCodes.error400
