/**
 * generic function to reduce complexity by and operator
 * @param {boolean[]}params
 * @return {boolean}
 */
const areAllParamsValid = (...params: boolean[]): boolean => {
    return params.reduce((paramA, paramB) => paramA && paramB)
}

/**
 * generic function to reduce complexity by or operator
 * @param {boolean[]}params
 * @return {boolean | undefined}
 */
const isAtleastOneParamValid = (...params: boolean[]): boolean | undefined => {
    return params.reduce((paramA, paramB) => paramA || paramB)
}

export { areAllParamsValid, isAtleastOneParamValid }
