import { ActionType, getType } from 'typesafe-actions'
import { setRoadRatingFetchSuccess } from '../actionCreators'
import * as actions from '../actionCreators'
import { RoadRating } from '../models/roadRating.interface'

type Action = ActionType<typeof actions>

export interface RoadRatingState {
    roadRating: RoadRating
}

export const initialState: RoadRatingState = {
    roadRating: {} as RoadRating,
}

export const roadRatingReducer = (state: RoadRatingState = initialState, action: Action): RoadRatingState => {
    if (action.type === getType(setRoadRatingFetchSuccess)) {
        return { ...state, roadRating: action.payload }
    } else {
        return state
    }
}
