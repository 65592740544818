import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { TagProps } from './Tag.type'

/**
 * Tag component
 * @return {JSX.Element} returns Tag component
 */
const Tag: React.FC<TagProps> = ({ ...props }): JSX.Element => {
    const tagClass = `${PREFIX}-tag`
    const type = props.type ? `${tagClass}--${props.type}` : ''
    const subType = props.subType ? `${tagClass}--${props.subType}` : ''
    const tagSizeModifier = props.isLarge ? `${tagClass}--large` : ''
    const badgeUrl = props.badgeUrl
    const isClickable = typeof badgeUrl !== 'undefined' && badgeUrl !== null
    const tagClickableModifier = isClickable ? `${tagClass}--clickable` : ''

    const anchorTag = (): JSX.Element => {
        return (
            <a
                href={`${badgeUrl}`}
                target="_blank"
                rel="noreferrer"
                data-link-value={props.analyticsLabelValue}
                className={`${tagClass} ${type} ${subType} ${tagSizeModifier} ${tagClickableModifier}`}>
                {props.withBorder ? (
                    <span className={`${tagClass}__inner-border`} data-testid="tagWithBorder">
                        {props.children}
                    </span>
                ) : (
                    props.children
                )}
            </a>
        )
    }

    const spanTag = (): JSX.Element => {
        return (
            <span className={`${tagClass} ${type} ${subType} ${tagSizeModifier} `}>
                {props.withBorder ? (
                    <span className={`${tagClass}__inner-border`} data-testid="tagWithBorder">
                        {props.children}
                    </span>
                ) : (
                    props.children
                )}
            </span>
        )
    }

    return isClickable ? anchorTag() : spanTag()
}

Tag.propTypes = {
    type: PropTypes.oneOf(['product', 'availability-bullet', 'search-input']),
    subType: PropTypes.string,
    withBorder: PropTypes.bool,
    isLarge: PropTypes.bool,
    children: PropTypes.node,
    badgeUrl: PropTypes.string,
}

export default Tag
