import React from 'react'
import PropTypes from 'prop-types'

import { AutoPartsType } from './VehicleProductCard.type'
import Price from '../Price'
import { PREFIX } from '../config'
import Badges from '../Badges'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { replaceStrWithDynamicVal, addDynamicToken } from '../../utils/replaceStrWithDynamicVal'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { getAccessibilityPriceId } from '../../utils/getAccessibilityId'

/**
 * Product Vehicle information component displays per tire, front, rare and setOfFourTire details
 * @param {AutoPartsType} props
 * @return {JSX.Element} returns Product vehicle information component
 */
const AutoPartsProductInformation: React.FC<AutoPartsType> = props => {
    const ProductCardVehicle = `${PREFIX}-product-card-vehicle`
    const feeMessage = props.feeMessages?.[0]
    /**
     * return badges detail for product
     * @return {JSX.Element}
     */
    const productBadges = (): JSX.Element => {
        return (
            isArrayNotEmpty(props.badges) && (
                <div className={`${PREFIX}-plp-badges`} aria-hidden={true}>
                    <Badges
                        badges={props.vehicleInformationPresent ? props.selectedSKUData?.badges : props.badges}
                        badgesAndPriorities={props.badgePriorities}
                        hideDisclaimer={props.hideDisclaimer}
                    />
                </div>
            )
        )
    }

    /**
     * function to get fee message
     * @return { string }
     */
    const getFeeMessage = (): string => {
        return props.vehicleInformationPresent ? feeMessage?.feeTitle : props.feeTitle
    }

    /**
     * function to get fee title
     * @return {string}
     */
    const getFeeTitle = (): string => {
        const feeValue = props.vehicleInformationPresent ? props.selectedSKUData?.feeValue : props.feeValue
        return feeValue
            ? replaceStrWithDynamicVal(
                  addDynamicToken(getFeeMessage(), '$x'),
                  getFormattedPriceValue(props.language, feeValue),
              )
            : ''
    }
    /**
     * functionto check if selected sku data present or not
     * @return { boolean }
     */
    const checkIfSelectedSKUDataAvailable = (): boolean => {
        return !!(props.vehicleInformationPresent && props.selectedSKUData)
    }

    return (
        <div className={`${ProductCardVehicle}`}>
            <div className={`${ProductCardVehicle}--per-tire__price-code`}>
                <Price
                    unitPriceLabel={props.productProps?.unitPriceLabel}
                    clearancePriceLabel={props.productProps?.clearancePriceLabel}
                    a11yPriceRangeFrom={props.productProps?.a11yPriceRangeFrom}
                    a11yPriceRangeTo={props.productProps?.a11yPriceRangeTo}
                    a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                    a11yTooltipIcon={props.a11yTooltipIcon}
                    a11yCloseIconLabel={props.a11yCloseIconLabel}
                    feeTitle={getFeeTitle()}
                    feeDisclaimerTitle={
                        checkIfSelectedSKUDataAvailable() ? feeMessage?.feeDisclaimerTitle : props.feeDisclaimerTitle
                    }
                    feeDisclaimerMessage={
                        checkIfSelectedSKUDataAvailable()
                            ? feeMessage?.feeDisclaimerMessage
                            : props.feeDisclaimerMessage
                    }
                    currentPrice={
                        checkIfSelectedSKUDataAvailable() ? props.selectedSKUData?.currentPrice : props.currentPrice
                    }
                    originalPrice={
                        checkIfSelectedSKUDataAvailable() ? props.selectedSKUData?.originalPrice : props.originalPrice
                    }
                    displayWasLabel={
                        checkIfSelectedSKUDataAvailable()
                            ? props.selectedSKUData?.displayWasLabel
                            : props.displayWasLabel
                    }
                    language={props.language}
                    priceMessage={
                        checkIfSelectedSKUDataAvailable() ? props.selectedSKUData?.priceMessage : props.priceMessage
                    }
                    nowFromLabel={props.nowFromLabel}
                    saveFromLabel={props.saveFromLabel}
                    wasFromLabel={props.wasFromLabel}
                    fromLabel={props.fromLabel}
                    overridePriceHeight={props.overridePriceHeight}
                    accessibilityPriceId={getAccessibilityPriceId(props.accessibilityId)}
                    plusMinusSymbol={props.plusMinusSymbol}
                />
                {productBadges()}
            </div>
        </div>
    )
}

AutoPartsProductInformation.propTypes = {
    productProps: PropTypes.any,
    currentPrice: PropTypes.object,
    originalPrice: PropTypes.object,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    hideDisclaimer: PropTypes.bool,
    priceMessage: PropTypes.array,
    feeDisclaimerMessage: PropTypes.string,
    displayWasLabel: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeValue: PropTypes.number,
    vehicleInformationPresent: PropTypes.bool,
    selectedSKUData: PropTypes.any,
    overridePriceHeight: PropTypes.bool,
    feeMessages: PropTypes.array,
}

export default AutoPartsProductInformation
