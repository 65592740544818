import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { PreviewDetailType } from './PreviewDetail.type'

const PreviewDetail: React.FC<PreviewDetailType> = ({ ...props }): JSX.Element => {
    const {
        detail,
        title,
        isSecurityInfo,
        statusInProgress,
        changeClass,
        isRowWithTwoColumnsOnMobile,
        shouldRenderWithoutDetail,
    } = props
    const securedTextClass = isSecurityInfo ? `${PREFIX}-profile-info__secured-text` : ''
    const progressStatusClass = statusInProgress ? `${PREFIX}-profile-info__status-progress` : ''
    const ClassNameTitle = changeClass
        ? `${PREFIX}-col-md-5 ${PREFIX}-col-sm-5`
        : `${PREFIX}-col-md-3 ${PREFIX}-col-sm-3`
    const ClassNameDetail = changeClass
        ? `${PREFIX}-col-md-7 ${PREFIX}-col-sm-7`
        : `${PREFIX}-col-md-9 ${PREFIX}-col-sm-9`
    const mobileClass = isRowWithTwoColumnsOnMobile ? `${PREFIX}-col-xs-3` : `${PREFIX}-col-xs-6`

    return (
        <>
            {(detail || isSecurityInfo || shouldRenderWithoutDetail) && (
                <div className={`${PREFIX}-row ${PREFIX}-row--no-gutters ${PREFIX}-profile-info__row`}>
                    <div className={`${ClassNameTitle} ${mobileClass}`}>
                        <p className={`${PREFIX}-profile-info__label`}>{title}</p>
                    </div>
                    <div className={`${ClassNameDetail} ${mobileClass}`}>
                        <p className={`${PREFIX}-profile-info__detail ${securedTextClass} ${progressStatusClass}`}>
                            {detail}
                        </p>
                    </div>
                </div>
            )}
        </>
    )
}

PreviewDetail.propTypes = {
    detail: PropTypes.string,
    title: PropTypes.string.isRequired,
    isSecurityInfo: PropTypes.bool,
    statusInProgress: PropTypes.bool,
    changeClass: PropTypes.bool,
}

export default PreviewDetail
