import { multiLanguageLabel } from '../globalConstants/global.constant'
import getLanguage from './getLanguage'

/**
 * function to get ETATimeOptions
 * used in cart and order details for 24 hours time format for french language and 12 hour time format for english language
 * @param {Record<unknown, unknown>} etaTimeOptions
 * @return {Record<string, unknown>}
 */
export const getETATimeOptions = (etaTimeOptions: Record<string, unknown>): Record<string, unknown> => {
    if (getLanguage().toLowerCase() === multiLanguageLabel.frenchLangLabel.toLowerCase()) {
        etaTimeOptions.hour12 = false
    }
    return etaTimeOptions
}
