/**
 * @param {function} callback - callback function
 * @param {number} wait - time interval
 * @param {string} debounceTimeout - getter function
 * @param {string} setDebounceTimeout - setter function
 * @return {function} - clears timeout and returns function
 */
export const customDebounce = (
    callback: () => void,
    wait: number,
    debounceTimeout: NodeJS.Timeout,
    setDebounceTimeout: React.Dispatch<any>,
): (() => void) => {
    return (...args) => {
        const next = () => callback(...args)
        clearTimeout(debounceTimeout)
        setDebounceTimeout(setTimeout(next, wait))
    }
}
