import { categoryURLIdentifier, vehicleParametersRegExp } from '../../config'
import getPageType from '../../utils/getPageType'

/**
 * @class {PageHelper}
 */
export class PageHelper {
    pageType: string
    primaryId: string
    secondaryId: string
    categories: string[]

    /**
     * constructor
     */
    constructor() {
        this.pageType = getPageType()
        this.primaryId = this.churnPrimaryId()
        this.secondaryId = this.churnSecondaryId()
        this.categories = this.churnCategories()
    }

    /**
     * The function which find churn primaryId in the current page URL
     * @return {string} churnPrimaryId
     */
    churnPrimaryId(): string {
        const pathWithoutVehicleParameters = window.location.pathname.split(vehicleParametersRegExp)
        const pathNameArr = pathWithoutVehicleParameters[0].split('-')
        const lastPath = pathNameArr.pop()

        return lastPath?.replace('.html', '') || ''
    }

    /**
     * The function which find churn secondaryId
     * @return {string} churnSecondaryId
     */
    churnSecondaryId(): string {
        return ''
    }

    /**
     * The function which find churn categories in the current page URL
     * @return {string[]} churnCategories
     */
    churnCategories(): string[] {
        const pathNameArr = window.location.pathname.split(`/${categoryURLIdentifier}/`)
        // return empty array if not a category page
        if (pathNameArr.length === 1) {
            return []
        }
        const lastPath = pathNameArr.pop()
        if (lastPath?.includes('.html')) {
            const stringWithoutFileExtension = lastPath.split('.html')[0]
            const categoriesStringWithoutVehicleParameters =
                stringWithoutFileExtension.split(vehicleParametersRegExp)[0]

            return categoriesStringWithoutVehicleParameters.split('/')
        } else {
            return []
        }
    }
}

export default PageHelper
