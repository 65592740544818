import { Dispatch } from 'redux'

import {
    CHANGE_BOPIS_STORE,
    CHECKOUT_BUTTON_CLICKED,
    PAYPAL_BUTTON_CLICKED,
    PAYPAL_BUTTON_CLICKED_VALID,
    SET_PC_MODAL_OPEN,
    SET_TNC_MODAL_OPEN,
    SET_TNC_ACCEPTED,
    CLICK_TO_PAY_BUTTON_CLICKED,
} from '../types/checkout/checkoutButtonClick.actionTypes.constant'
import {
    checkoutButtonClickAction,
    setPostalCodeOpenAction,
    resetCheckOutVariablesAction,
    setTNCOpenAction,
    changeBopisStoreAction,
    setTncAccepted,
    paypalButtonClickAction,
    paypalButtonClickValidationAction,
    clickToPayButtonClickAction,
} from '../actionCreators/checkoutButtonClick.actionCreators'

export const checkoutButtonClick =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof CHECKOUT_BUTTON_CLICKED; payload: boolean }>): void => {
        dispatch(checkoutButtonClickAction(requestPayload))
    }

export const isShowTNCModal =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_TNC_MODAL_OPEN; payload: boolean }>): void => {
        dispatch(setTNCOpenAction(requestPayload))
    }

export const setPostalCodeOpen =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_PC_MODAL_OPEN; payload: boolean }>): void => {
        dispatch(setPostalCodeOpenAction(requestPayload))
    }

/**
 * Used to dispatch an action to the reducer.
 * @return {Function} return a function which is triggered in shopping cart.
 */
export const resetCheckOutVariables =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCheckOutVariablesAction())
    }

/**
 * Function to trigger store locator modal in shopping cart component when change store from order summary is clicked
 * @param {boolean} requestPayload
 * @return {Function}
 */
export const changeBopisStore =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof CHANGE_BOPIS_STORE; payload: boolean }>): void => {
        dispatch(changeBopisStoreAction(requestPayload))
    }

/**
 * Funtion to set tnc modal accepted for cart when there is atleast one service addon
 * @param {boolean} requestPayload
 * @return {Function}
 */
export const isTncAccepted =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_TNC_ACCEPTED; payload: boolean }>): void => {
        dispatch(setTncAccepted(requestPayload))
    }

export const paypalButtonClick =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof PAYPAL_BUTTON_CLICKED; payload: boolean }>): void => {
        dispatch(paypalButtonClickAction(requestPayload))
    }

export const paypalButtonClickValidation =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof PAYPAL_BUTTON_CLICKED_VALID; payload: boolean }>): void => {
        dispatch(paypalButtonClickValidationAction(requestPayload))
    }

/**
 * Function to perform action event for click to pay button click
 * @param {boolean} requestPayload
 * @return {Function}
 */
export const clickToPayButtonClick =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof CLICK_TO_PAY_BUTTON_CLICKED; payload: boolean }>): void => {
        dispatch(clickToPayButtonClickAction(requestPayload))
    }
