import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import LinkBackground from './LinkRewardsBackground'
import { linkPropTypes } from './LinkRewardsCard.types'

/**
 * LinkRewardsCard component
 * @param {linkPropTypes} props
 * @return {JSX.Element} returns LinkRewardsCard component
 */

const LinkRewardsCard: React.FC<linkPropTypes> = ({ ...props }): JSX.Element => {
    const { children, path, dynamicBackground } = props
    return (
        <>
            <LinkBackground path={path} dynamicBackground={dynamicBackground}>
                <div className={`${PREFIX}-link-card`} data-testid="link-card">
                    {children}
                </div>
            </LinkBackground>
        </>
    )
}

LinkRewardsCard.propTypes = {
    children: PropTypes.element,
    path: PropTypes.string,
    dynamicBackground: PropTypes.object,
}

export default LinkRewardsCard
