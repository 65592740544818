import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import { WishlistResponse, wishlistPayload, AddToWishListPayload } from '../../redux/models/wishlist.interface'
import appCacheService from '../../utils/appCacheService'

const httpClient = getHttpClient()
const environment = getEnvironment()
/**
 * Wishlist service. To fetch the wishlist products for guest/authenticated user
 */
class WishlistService extends BaseService {
    /**
     * Load wishlist for logged in customer
     * @param  {string} guid - guid for the user
     * @param {string} store
     * @return {WishlistResponse}
     */
    getWishlist(guid: string, store: string): Promise<{ data: WishlistResponse }> {
        const url = this.createWishlistUrl(guid, store)
        return httpClient.apiGet(url)
    }

    /**
     * Load wishlistProductCodes for logged in customer
     * @param  {string} guid - guid for the user
     * @param {string} store
     * @return {WishlistResponse}
     */
    getWishlistProductCodes(guid: string, store: string): Promise<{ data: WishlistResponse }> {
        const url = this.createWishlistProductCodesUrl(guid, store)
        return httpClient.apiGet(url)
    }

    /**
     * Load wishlistDetails for logged in customer
     * @param  {string} guid - guid for the user
     * @param {string} store
     * @return {WishlistResponse}
     */
    getWishlistDetails(guid: string, store: string): Promise<{ data: WishlistResponse }> {
        const url = this.createWishlistDetailsUrl(guid, store)
        return httpClient.apiGet(url)
    }

    /**
     *
     * @param  {wishlistPayload} req
     * @param {string} store
     * @param {AddToWishListPayload} addToWishlistPayLoad
     * @param {boolean} isStaggeredProduct
     * @return {Promise}
     */
    addItemToWishlist(
        req: wishlistPayload,
        store: string,
        addToWishlistPayLoad: AddToWishListPayload,
        isStaggeredProduct = false,
    ): Promise<any> {
        const guidParam = !!req.guid ? `&guid=${req.guid}` : ''
        const {
            API_BASE_URL,
            API_ENDPOINTS: { addWishlist },
        } = environment
        const url = `${API_BASE_URL}${addWishlist}?storeId=${store}${guidParam}&lang=${
            WishlistService.language
        }&isGrouped=${String(isStaggeredProduct)}`
        return httpClient.apiPut(url, addToWishlistPayLoad)
    }

    /**
     *
     * Create wishlist url based on mock or cds
     * @param {string} guid - guid for guest user
     * @param {string} storeId
     * @param {AddToWishListPayload} wishlistRequestPayload - wishlistRequestPayload for product
     * @param {boolean} isStaggered
     * @return {URL}
     */
    removeProductFromWishlist(
        guid: string,
        storeId: string,
        wishlistRequestPayload: AddToWishListPayload,
    ): Promise<any> {
        const guidParam = !!guid ? `&guid=${guid}` : ''
        const store = storeId || appCacheService.preferredStoreId.get()
        const {
            API_BASE_URL,
            API_ENDPOINTS: { removeWishlist },
        } = environment
        const url = `${API_BASE_URL}${removeWishlist}?storeId=${store}${guidParam}&lang=${WishlistService.language}`
        return httpClient.apiDelete(url, wishlistRequestPayload)
    }

    /**
     * Function to merge wishlist.
     * @param {string} guid
     * @param {string} storeId
     *
     * @return {Promise}
     */
    mergeWishlistItems(guid: string, storeId: string): Promise<any> {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { mergeWishlist },
        } = environment
        const url = `${API_BASE_URL}${mergeWishlist}?storeId=${
            storeId || appCacheService.preferredStoreId.get()
        }&lang=${WishlistService.language}&guid=${guid}`
        return httpClient.apiPost(url)
    }

    /**
     * Create wishlist url based on mock or cds
     * @param {string} guid - guid for guest user
     * @param {string} storeId
     * @return {string}
     */
    createWishlistUrl(guid: string, storeId: string): string {
        const {
            API_ENDPOINTS: { wishlist, getWishlist },
        } = environment

        const guidParam = !!guid ? `&guid=${guid}` : ''

        const store = storeId || appCacheService.preferredStoreId.get()

        return `${WishlistService.switchEndpoints(wishlist, getWishlist)}?storeId=${store}&lang=${
            WishlistService.language
        }${guidParam}`
    }

    /**
     * Create wishlistProductCodes url based on mock or cds
     * @param {string} guid - guid for guest user
     * @param {string} storeId
     * @return {string}
     */
    createWishlistProductCodesUrl(guid: string, storeId: string): string {
        const {
            API_ENDPOINTS: { wishlistProducts, getWishlistProductCodes },
        } = environment

        const guidParam = !!guid ? `&guid=${guid}` : ''

        const store = storeId || appCacheService.preferredStoreId.get()

        return `${WishlistService.switchEndpoints(wishlistProducts, getWishlistProductCodes)}?storeId=${store}&lang=${
            WishlistService.language
        }${guidParam}`
    }

    /**
     * Create wishlistDetails url based on mock or cds
     * @param {string} guid - guid for guest user
     * @param {string} storeId
     * @return {string}
     */
    createWishlistDetailsUrl(guid: string, storeId: string): string {
        const {
            API_ENDPOINTS: { wishlistProducts, getWishlistDetails },
        } = environment

        const guidParam = !!guid ? `&guid=${guid}` : ''

        const store = storeId || appCacheService.preferredStoreId.get()

        return `${WishlistService.switchEndpoints(wishlistProducts, getWishlistDetails)}?storeId=${store}&lang=${
            WishlistService.language
        }${guidParam}`
    }

    /**
     * Create wishlist url based on mock or cds
     * @param {string} guid - guid for guest user
     * @param {string} storeId
     * @param {string} sku - sku for product
     * @return {URL}
     */
    createRemoveWishlistUrl(guid: string, storeId: string, sku?: string): string {
        const {
            API_ENDPOINTS: { removeWishlistMock, removeWishlist },
        } = environment

        const guidParam = !!guid ? `&guid=${guid}` : ''

        const store = storeId || appCacheService.preferredStoreId.get()

        return `${WishlistService.switchEndpoints(
            removeWishlistMock,
            `${removeWishlist}/${String(sku)}`,
        )}?storeId=${store}&lang=${WishlistService.language}${guidParam}`
    }
}

const wishlistService = new WishlistService()

export { wishlistService }
export default wishlistService
