import { getCookieValue } from './getCookieValue'
import { localeCookieName, multiLanguageLabel } from '../globalConstants/global.constant'

const getLanguage = (): string => {
    const htmlLang = document.documentElement.lang.toLowerCase()
    if (
        htmlLang !== multiLanguageLabel.frenchLangLabel.toLowerCase() &&
        htmlLang !== multiLanguageLabel.englishLangLabel.toLowerCase()
    ) {
        return getCookieValue(localeCookieName) || multiLanguageLabel.englishLangLabel.toLowerCase()
    } else {
        return htmlLang
    }
}

export default getLanguage
export { getLanguage }
