import { isArrayNotEmpty, Vehicle } from '@nl/lib'
import { AutoAttributes } from '@nl/lib/src/components/ProductGridView/ProductGrid.types'
import appCacheService from './appCacheService'

/**
 *
 * @return {boolean} It will returns the vehicle data from localstorage if exist.
 */
export const checkShopByVehicleData = (): boolean => {
    const vehicleData = appCacheService.getVehicles()
    return Boolean(isArrayNotEmpty(vehicleData))
}

/**
 *
 * @return {Record<string, string> | undefined} It will returns the vehicle data from localstorage if exist.
 */
export const fetchShopByVehicleData = (): Vehicle | undefined => {
    const vehicleData: Vehicle[] = appCacheService.getVehicles() as Vehicle[]
    return isArrayNotEmpty(vehicleData) ? vehicleData[0] : undefined
}

/**
 *
 * @return {string} returns the vehicle information in string like `vehicle year: 2000 vehicle make:  vehicle model: audi vehicle body:  vehicle option: `
 */
export const prepareVehicleDataInLabel = (): string => {
    let vehicleDataInLabel = ''

    const vehicleData = appCacheService.getVehicles()
    if (isArrayNotEmpty(vehicleData) && !Array.isArray(vehicleData[0])) {
        const {
            year = '',
            make = '',
            model = '',
            option = '',
            body = '',
        } = vehicleData[0].autoAttributes as AutoAttributes
        vehicleDataInLabel = `vehicle year: ${year} vehicle make: ${make} vehicle model: ${model} vehicle body: ${body} vehicle option: ${option}`
    }

    return vehicleDataInLabel
}

/**
 *
 * @param {Record<string, string>} vehicleData
 * @return {string} checking is year make and model present then returns true in string format.
 */
export const isPartialVehicleJIT = (vehicleData: Vehicle): string => {
    let isPartialVehicle = ''
    if (vehicleData?.autoAttributes?.year && vehicleData?.autoAttributes?.make && vehicleData?.autoAttributes?.model) {
        isPartialVehicle = 'true'
    }
    if (vehicleData?.autoAttributes?.body && vehicleData?.autoAttributes?.option) {
        isPartialVehicle = 'false'
    }
    return isPartialVehicle
}

/**
 * @param {Record<string, string>} vehicleData
 * @return {Record<string, string>}
 */
export const prepareVehicleDataByAttribute = (vehicleData: Vehicle | undefined): Record<string, string> => {
    return {
        type: vehicleData?.vehicleType || '',
        make: vehicleData?.autoAttributes?.make || '',
        model: vehicleData?.autoAttributes?.model || '',
        year: vehicleData?.autoAttributes?.year || '',
        body: vehicleData?.autoAttributes?.body || '',
        option: vehicleData?.autoAttributes?.option,
    }
}
