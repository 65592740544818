import { Dispatch } from 'redux'

import {
    addHppToken,
    addCreditCardAction,
    addCreditCardErrorAction,
    resetAddCreditCardAction,
} from '../actionCreators/profilePaymentCard.actionCreators'
import { ErrorResponse } from '../models/loyaltyRegisterForm.interface'
import { profilePaymentService } from '../../services/profilePaymentService'
import { AxiosError, AxiosResponse } from 'axios'
import { InitAddUpdateCardRequestType } from '../../redux/models/profile.payment.interface'

/**
 * Get hpp token
 * @param {InitAddUpdateCardRequestType} payload
 * @return {Promise<void>} returns api response promise
 */

export const fetchAddPaymentHppToken =
    (payload: InitAddUpdateCardRequestType) =>
    (dispatch: Dispatch): Promise<void> => {
        return profilePaymentService
            .hppToken(payload)
            .then((resp: AxiosResponse<Record<string, unknown>>) => {
                dispatch(
                    addHppToken({
                        hppToken: String(resp.data?.transactionId || resp.data?.hppToken),
                        validUntil: Number(resp.data.validUntil),
                    }),
                )
            })
            .catch(err => {
                console.warn(err)
            })
    }

/**
 * add credit card
 * @param {string} tratransactionId
 * @return {Promise<void>} returns api response promise
 */

export const addCreditCard =
    (tratransactionId: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return profilePaymentService
            .addCreditCard(tratransactionId)
            .then(resp => {
                dispatch(addCreditCardAction(resp.data))
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                dispatch(addCreditCardErrorAction(err?.response))
            })
    }

/**
 * reset credit card
 * @return {Dispatch}
 */
export const resetAddCreditCard =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetAddCreditCardAction())
    }
