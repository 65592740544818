import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators'
import { setAccountSettingsProps } from '../actionCreators'
import { AccountSettingsProps } from '../models/globalData.interface'

type Action = ActionType<typeof actions>

export const initialState: globalDataState = {
    accountSettings: {
        isEdit: false,
    } as AccountSettingsProps,
}

export interface globalDataState {
    accountSettings: AccountSettingsProps
}

export const globalDataReducer = (state: globalDataState = initialState, action: Action): globalDataState => {
    if (action.type === getType(setAccountSettingsProps)) {
        return { ...state, accountSettings: action.payload }
    } else {
        return state
    }
}
