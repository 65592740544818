import { stringKeyCodes } from '../utils'

/**
 * function to check if Esc is pressed from keyboard
 * @param {string} eventKey
 * @return {boolean}
 */
export const isEscPressed = (eventKey: string): boolean => {
    return eventKey === stringKeyCodes.esc
}

/**
 * function to check if tab is pressed from keyboard
 * @param {string} eventKey
 * @return {boolean}
 */
export const isTabPressed = (eventKey: string): boolean => {
    return eventKey === stringKeyCodes.tab
}

/**
 * function to check if enter is pressed from keyboard
 * @param {string} eventKey
 * @return {boolean}
 */
export const isEnterPressed = (eventKey: string): boolean => {
    return eventKey === stringKeyCodes.enter
}

/**
 * function to check if space is pressed from keyboard
 * @param {string} eventKey
 * @return {boolean}
 */
export const isSpacePressed = (eventKey: string): boolean => {
    return eventKey === stringKeyCodes.space
}
/**
 * @param {React.KeyboardEvent<HTMLElement>} event
 * @param { string } errorMsg
 * @return {boolean}
 */
export const isSpaceOrEnter = (event: React.KeyboardEvent<HTMLElement>, errorMsg: string): boolean => {
    return Boolean((isSpacePressed(event.key) || isEnterPressed(event.key)) && errorMsg)
}
