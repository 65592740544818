/**
 * Extract multiple keys from an object.
 *
 * Example:
 *
 * const obj = {a : {b : {c: 'e'}, c: 'd'}, e: 'a'}
 *
 * For NestedValues
 * const a = extractKeyValues(obj, ['a','b','c']) // returns ['e]
 * a will be e
 *
 * For Multiple keys on a single key
 * const a = extractKeyValues(obj, [{a : ['b', 'c']}])  // returns [[{c: 'e'}, 'd']] inside array is the value for key 'a'
 *
 * @author "Yashwanth Korla"
 * @param {Record<string, unknown>} mainObj
 * @param {Array} keys
 * @return {Array}
 */
const extractKeyValues = (
    mainObj: Record<string, unknown>,
    keys: (string[] | Record<string, string[]>)[],
): unknown[] => {
    const ONE = 1

    const _extractMultipleValues = (
        obj: Record<string, unknown>,
        mainKey: string,
        currentValue: Record<string, string[]>,
    ): unknown[] => {
        return (
            (currentValue?.[mainKey]?.map((el: string) => (obj?.[mainKey]?.[el] as unknown[]) || '') as unknown[]) || []
        )
    }

    const _extractSingleValue = (obj: Record<string, unknown>, array: string[]): string => {
        if (array.length === ONE) {
            const [finalValue] = array
            return (obj[finalValue] as string) || ''
        }
        const value = obj?.[array.shift()]
        return _extractSingleValue(value as Record<string, unknown>, array)
    }

    return keys.reduce((accumulator: unknown[], currentValue) => {
        const value = !Array.isArray(currentValue)
            ? _extractMultipleValues(mainObj, Object.keys(currentValue)[0], currentValue)
            : _extractSingleValue(mainObj, [...currentValue])
        accumulator.push(value)
        return accumulator
    }, [])
}

export { extractKeyValues }
export default extractKeyValues
