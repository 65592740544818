import { AccountOptionsType } from '../components/OfferCard/OfferCard.type'

export const getPageType = (): string => {
    return document.getElementsByTagName('body')[0]?.getAttribute('data-pagetype') || ''
}

/**
 * Current page Index
 * @param {AccountOptionsType[]} navigation navigation list array
 * @return {number} navigation array index
 */
export const checkNavigationUrl = (navigation: AccountOptionsType[]): number => {
    const currentUrl = window.location.href
    return (
        navigation &&
        navigation.findIndex(item => currentUrl.includes(decodeURIComponent(item.navigationRedirectionLink)))
    )
}
