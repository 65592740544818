import { RootState } from '../reducers'
import {
    SharedCartState,
    CustomerToCustomerSharedCart,
    CartSharing,
    StoreSharedCart,
} from '../reducers/sharedCart.reducer'
import { FilteredCartData } from '../models/cart.interface'

export const sharedCartSelector = (state: RootState): SharedCartState => state.sharedCart
export const cartSharingSelector = (state: RootState): CartSharing => state.sharedCart.cartSharing
export const storeSharedCartSelector = (state: RootState): StoreSharedCart =>
    state.sharedCart.cartConsuming.storeSharedCart
export const isStoreSharedCartSelector = (state: RootState): boolean =>
    state.sharedCart.cartConsuming.storeSharedCart.isStoreSharedCart
export const storeInitiatedCartSelector = (state: RootState): FilteredCartData =>
    state.sharedCart.cartConsuming.storeSharedCart.storeInitiatedCart
export const customerToCustomerSharedCartSelector = (state: RootState): CustomerToCustomerSharedCart =>
    state.sharedCart.cartConsuming.customerToCustomerSharedCart
export const isLabourSharedCartSelector = (state: RootState) =>
    state.sharedCart.cartConsuming.storeSharedCart.storeInitiatedCartTemplate.isLabourSharedCart
