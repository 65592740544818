/**
 * Parsing url query parameters when it has delimeters
 * filteredElement - turns string into an array and remove duplicates and empty items
 * stringWithDelimeter - convert array to string again and replace comma to "+"
 * @param {string} element - string that needs to be parsed
 * @param {boolean} hasDelimeter - checks string if it will be parsed or not if it finds a delimeter
 * @return {string} element
 */
export const parseQuery = (element: string, hasDelimeter: boolean) => {
    const filteredElement = Array.from(new Set(element.split(' ').filter(e => e)))
    const stringWithDelimeter = filteredElement.toString().replace(/,/g, '+')
    return hasDelimeter ? stringWithDelimeter : element
}
