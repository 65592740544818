import { collapseControl } from '@nl/lib'
import { preProcessSvgUseTag } from '../../utils/preProcessSvgUseTag'

/**
 * adds collapse control to dropdown links
 */
const footerCollapseControl = (): void => {
    preProcessSvgUseTag('js-icon', 'data-icon') // converts a data attribute to href in the use tag
    collapseControl(
        'footer-links__mobile-heading',
        'footer-links__dropdown-links',
        'footer-links__icon--plus',
        'footer-links__icon--minus',
        'footer-links__icon--hide',
        'footer-links__dropdown-links--show',
    )
}

export { footerCollapseControl }
