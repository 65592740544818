import { staggeredSkuLength } from '@nl/lib'
import { ProductState } from '../redux/models/product.interface'

/**
 * @param {Record<string, string>} productDetails We are checking if product data contains sku
 * @return {boolean} returns true if IsStraggered
 */
const checkIsStraggered = (productDetails?: ProductState): boolean => {
    return productDetails?.productData?.skus?.length === staggeredSkuLength
}

export default checkIsStraggered
