import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators'

type Action = ActionType<typeof actions>

export interface tncContentState {
    tncContentData: string
    tncFrContentData: string
}

export const initialState: tncContentState = {
    tncContentData: '',
    tncFrContentData: '',
}

export const getTncContentReducer = (state: tncContentState = initialState, action: Action): tncContentState => {
    if (action.type === getType(actions.fetchTncContent)) {
        return { ...state, tncContentData: action.payload }
    } else if (action.type === getType(actions.fetchFrTncContent)) {
        return { ...state, tncFrContentData: action.payload }
    } else {
        return state
    }
}
