import React from 'react'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { LoyaltyCardsType } from './LoyaltyCards.type'
import { userGreetingClassName } from '../UserGreeting/UserGreeting.constant'
import { PREFIX } from '../config'

const LoyaltyCards: React.FC<LoyaltyCardsType> = ({ ...props }): JSX.Element => {
    const { loyalty, hasLoyalty, isDashboard, isNotTriangle } = props
    const loyaltyImgWidth = 86
    const loyaltyImgHeight = 53
    const greetingsClass = `${userGreetingClassName}__card-items--${hasLoyalty ? 'loyalty' : 'no-loyalty'}`

    return (
        <div className={`${greetingsClass}`}>
            {isArrayNotEmpty(loyalty) &&
                loyalty?.map((item, i) => (
                    <div
                        key={i}
                        className={`${userGreetingClassName}__item ${
                            isDashboard && hasLoyalty ? `${PREFIX}-row` : ''
                        }`}>
                        {hasLoyalty ? (
                            <>
                                {isDashboard && (
                                    <div className={`${userGreetingClassName}--dashboard-loyalty__image`}>
                                        <img
                                            src={item?.icon}
                                            alt={item?.iconAlt}
                                            width={loyaltyImgWidth}
                                            height={loyaltyImgHeight}
                                        />
                                    </div>
                                )}
                                <div className={`${userGreetingClassName}--dashboard-loyalty__content`}>
                                    <h6
                                        className={`${PREFIX}-body-${isDashboard ? 'md' : 'sm'}`}
                                        dangerouslySetInnerHTML={{ __html: item?.title }}
                                    />
                                    <p
                                        className={`${userGreetingClassName}__balance ${PREFIX}-body-${
                                            isDashboard ? 'lg' : 'xl'
                                        }`}>
                                        {item?.value}
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={`${userGreetingClassName}--no-loyalty__image`}>
                                    <img src={item?.icon} alt="" />
                                    <h6
                                        className={`${PREFIX}-body-lg ${PREFIX}-xs-inline-block`}
                                        dangerouslySetInnerHTML={{ __html: item?.title }}
                                    />
                                </div>
                                <div
                                    className={`${PREFIX}-body-md`}
                                    dangerouslySetInnerHTML={{ __html: isDashboard ? item?.subtitle : item?.linkCta }}
                                />
                                {isNotTriangle && (
                                    <div className={`${userGreetingClassName}__register-button`}>
                                        <a
                                            data-link-value={item?.registerLabel}
                                            href={item?.registerNowLink}
                                            className={`${PREFIX}-button ${PREFIX}-button--primary`}>
                                            {item?.registerNowLabel}
                                        </a>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ))}
        </div>
    )
}

export default LoyaltyCards
