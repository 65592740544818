import classnames from 'classnames'
import { PREFIX } from '../config'
import React from 'react'
import { NavExpansionPanelProps } from './NavExpansionPanel.types'
import { divideIntoColumns } from './NavExpansionPanel.helper'
import { magicNumber } from '../../utils'

/**
 * NavExpansionPanel is a React functional component that displays a navigation panel with sub-categories.
 * @param {NavExpansionPanelProps} props - The props for the NavExpansionPanel component.
 * @returns {JSX.Element} The JSX representation of the NavExpansionPanel component.
 */

const NavExpansionPanel: React.FC<NavExpansionPanelProps> = ({ catalog }) => {
    const subNavigationItem = divideIntoColumns(catalog?.subSectionTab, magicNumber.FOUR)
    return (
        <div className={classnames(`${PREFIX}-row`, `${PREFIX}-sub-categories`)}>
            {subNavigationItem.map((subNavItem, columnIndex) => (
                <div
                    key={columnIndex}
                    className={classnames(`${PREFIX}-col-md-3`, `${PREFIX}-sub-categories__right-spacing`)}>
                    <ul key={columnIndex}>
                        {subNavItem.map((item, index) => (
                            <li key={index} className={classnames(`${PREFIX}-sub-categories__category`)}>
                                <a data-link-value={item?.subNavLabel} href={item?.subNavLink}>
                                    {item?.subNavLabel}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}

export default NavExpansionPanel
