import { Dispatch } from 'redux'

import { CertonaResponseType } from '../../certona/certona.type'
import { storeCertonaProductRecommendationData } from '../actionCreators'

export const storeCertonaRecommendationData =
    (data: CertonaResponseType) =>
    (dispatch: Dispatch): void => {
        dispatch(storeCertonaProductRecommendationData(data))
    }
