export const certonaEventConst = {
    addToCart: 'addtocart',
    addToWishlist: 'addtowishlist',
    removeFromCart: 'cartremove',
    removeFromWishlist: 'wishlistremove',
    viewoffer: 'viewoffer',
    weekelyOffer: 'viewoffer2',
    dashboardOffer: 'viewofferdashboard',
    emailOffer: 'viewofferemail',
}
