import { createAction } from 'typesafe-actions'
import { CartResponseErrorDTO } from '../models/cart.interface'

import { OrderCancellationData } from '../models/orderCancellation.interface'
import {
    ORDER_CANCELLATION_FAILURE,
    ORDER_CANCELLATION_SUCCESS,
} from '../types/orderCancellation/orderCancellation.actionTypes.constants'

export const orderCancellationSuccess = createAction(
    ORDER_CANCELLATION_SUCCESS,
    (payload: OrderCancellationData) => payload,
)<OrderCancellationData>()

export const orderCancellationFailure = createAction(ORDER_CANCELLATION_FAILURE)<CartResponseErrorDTO>()
