import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators'
import {
    orderDetailsConfirmationFailure,
    orderDetailsConfirmationSuccess,
    orderDetailsExpired,
    generateReturnSlipSuccess,
    generateReturnSlipError,
    resetReturnSlipData,
    updateBillingInformationOrderDetailsSuccess,
    updateBillingInformationOrderDetailsFailure,
    resetBillingInformationOrderDetailsFailure,
} from '../actionCreators'
import { CartResponseErrorDTO, FilteredCartData } from '../models/cart.interface'
import { OrderDetaiilsErrorDTO } from '../models/orderDetails.interface'

const initialOrderModificationErrorState = {
    statusCode: 0,
    error: '',
    message: '',
    errors: [],
    requestId: '',
    errCode: '',
    errOrigin: '',
    isCdsError: false,
    correlationId: '',
    cartId: '',
}

export interface OrderDetailsState {
    getOrderDetailsError: CartResponseErrorDTO
    orderDetailsData: FilteredCartData
    orderExpired: boolean
    returnSlipLink: string
    returnSlipLinkGenerationError: string
    isUpdateBillingInformationOrderDetails?: boolean
    updateBillingInformationOrderDetailsFailure?: OrderDetaiilsErrorDTO
}

type Action = ActionType<typeof actions>

export const initialState: OrderDetailsState = {
    getOrderDetailsError: {} as CartResponseErrorDTO,
    orderDetailsData: {} as FilteredCartData,
    orderExpired: false,
    returnSlipLink: '',
    returnSlipLinkGenerationError: '',
    isUpdateBillingInformationOrderDetails: false,
    updateBillingInformationOrderDetailsFailure: initialOrderModificationErrorState,
}

export const orderDetailsReducer = (state: OrderDetailsState = initialState, action: Action): OrderDetailsState => {
    switch (action.type) {
        case getType(orderDetailsConfirmationSuccess): {
            return { ...state, orderDetailsData: action.payload }
        }
        case getType(orderDetailsConfirmationFailure): {
            return { ...state, getOrderDetailsError: action.payload }
        }
        case getType(orderDetailsExpired): {
            return { ...state, orderExpired: action.payload }
        }
        case getType(generateReturnSlipSuccess): {
            return { ...state, returnSlipLink: action.payload }
        }
        case getType(generateReturnSlipError): {
            return { ...state, returnSlipLinkGenerationError: action.payload }
        }
        case getType(resetReturnSlipData): {
            return { ...state, returnSlipLink: '', returnSlipLinkGenerationError: '' }
        }
        case getType(updateBillingInformationOrderDetailsSuccess): {
            return { ...state, isUpdateBillingInformationOrderDetails: action.payload }
        }
        case getType(updateBillingInformationOrderDetailsFailure): {
            return { ...state, updateBillingInformationOrderDetailsFailure: action.payload }
        }
        case getType(resetBillingInformationOrderDetailsFailure): {
            return { ...state, updateBillingInformationOrderDetailsFailure: initialOrderModificationErrorState }
        }

        default:
            return state
    }
}
