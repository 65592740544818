import getPageType from '../getPageType'
import { pageTypes } from '../../config'
import { MagicNumber } from './../../analytics/analytics.type'
import getQueryString from '../getQueryString'
import { colorCodeParam } from '../../globalConstants'

/**
 * function to fetch & return pCode from url
 * @return {Record<string, string>} pCode
 */

const extractPCodeFromUrl = (): Record<string, string> => {
    const path = window.location.pathname.toLowerCase().split('.')
    const pCode =
        isProductPage() && path.length > MagicNumber.ONE
            ? path[0].slice(path[0].lastIndexOf('-') + MagicNumber.ONE, path[0].length)
            : ''

    /** Below code will be used later to fetch sku and select variants on page load */
    let staggeredSkus = ''
    let skuCode = ''
    if (isProductPage() && path.length > MagicNumber.THREE) {
        staggeredSkus = path.slice(MagicNumber.ONE, MagicNumber.MINUS_ONE).join(',')
    } else {
        skuCode = isProductPage() && path.length > MagicNumber.TWO ? path[MagicNumber.ONE] : ''
    }
    const colorCode = getQueryString(window.location.href, colorCodeParam)
    return { pCode, skuCode, colorCode, staggeredSkus }
}

const isProductPage = (): boolean => {
    const { pdpPage, productPage } = pageTypes
    const pageType = getPageType()
    return pageType === pdpPage || pageType === productPage
}

export default extractPCodeFromUrl
