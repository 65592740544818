import { userState } from '../config'

export const getUserType = (
    authenticatedUser: boolean,
    isLoyaltyUser: boolean,
    rewardsCardType: string,
    linkFlag: boolean,
): string => {
    let userType: string
    if (authenticatedUser === false) userType = userState.guest
    else if (!isLoyaltyUser || rewardsCardType === null) userType = userState.noLoyaltyCard
    else if (isLoyaltyUser && !linkFlag) userType = userState.loyaltyCard
    else if (isLoyaltyUser && !!linkFlag) userType = userState.triangleCreditCard
    else userType = ''
    return userType
}
