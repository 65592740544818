import { AxiosPromise } from 'axios'
import { getEnvironment } from '../environments'

import { getHttpClient } from '../httpClient'
import { apiPost, apiGet, RequestBodyType, ExternalSystemNameType } from '../redux/utils/httpClient'
import { HttpReqHeaders } from '../redux/utils/httpClient.type'
import { apiMethods, falsyMockIndex, bannersProdOriginMap } from './service.constants'
import { isMockParamInURL } from '@nl/lib'
const httpClient = getHttpClient()
const environment = getEnvironment()

/**
 * Base service class for new service implementations
 */
abstract class BaseService {
    static externalSystem: ExternalSystemNameType
    static language = environment.language

    /**
     * make post request for provided arguments
     * @param  {URL} url
     * @param  {RequestBodyType|string} body?
     * @param  {HttpReqHeaders} headers?
     */
    static post(url: URL, body?: RequestBodyType | string, headers?: HttpReqHeaders): void {
        void apiPost(url, body, BaseService.externalSystem, headers)
    }

    /**
     *
     * @param {URL} url
     * @return {AxiosPromise}
     */
    static get(url: URL): AxiosPromise {
        return apiGet(url.toString())
    }
    // TODO: Get function need to defined
    /**
     * prepare mock url for provided url.
     * normally replace provided origin with mock origin
     * @param  {URL} url
     * @param {string} mockBaseUrl
     * @param {boolean} queryStringB64
     * @return {URL}
     */
    static prepareMockUrl(url: URL, mockBaseUrl: string, queryStringB64 = false): URL {
        return new URL(`${mockBaseUrl}${url.pathname}${queryStringB64 ? `/${btoa(url.search)}` : ''}.json${url.search}`)
    }

    /**
     * This function used to create uri
     * @param  {string} urlPathWithQueryParams
     * @return {URL} url
     */
    static createUri(urlPathWithQueryParams: string): URL {
        let url = new URL(`${environment.API_BASE_URL}${urlPathWithQueryParams}`)
        if (BaseService.isMock()) {
            const pathWithoutApiV1Prefix = url.pathname.replace(environment.API_V1, '')
            url = new URL(`${environment.API_MOCK_URL}/mock${pathWithoutApiV1Prefix}.json${url.search}`)
        }
        return url
    }

    /**
     * check provided uri is mock or real api.
     * @param  {string} uri
     * @return {boolean}
     */
    static isMockUri(uri: string): boolean {
        return uri?.indexOf('mock') > falsyMockIndex
    }

    /**
     * check if the url has mock query param or not
     * @return {boolean}
     */
    static isMock(): boolean {
        const origin = window.location.origin
        const prodUrl = bannersProdOriginMap[environment.banner]
        if (origin === prodUrl) {
            return false
        }
        return isMockParamInURL(window.location.search)
    }

    /**
     *
     * Switch between mock and api Endpoint
     *
     * @param {string} mockEndpoint
     * @param {string} apiEndpoint
     *
     * @return {string}
     */
    static switchEndpoints(mockEndpoint: string, apiEndpoint: string): string {
        return mockEndpoint && this.isMock()
            ? `${environment.API_MOCK_URL}${mockEndpoint}.json`
            : `${environment.API_BASE_URL}${apiEndpoint}`
    }

    /**
     * Switch between various http methods.
     * @param {string} reqMethod
     * @param {string} url
     * @param {object} requestBody
     * @param {HttpReqHeaders} headers
     * @param {boolean} credentials
     * @return {AxiosPromise}
     */
    static __configureAPIMethod = (
        reqMethod: string,
        url: string,
        requestBody?: Record<string, unknown>,
        headers?: HttpReqHeaders,
        credentials?: boolean,
    ): AxiosPromise => {
        switch (reqMethod.toUpperCase()) {
            case apiMethods.POST:
                return httpClient.apiPost(url, JSON.stringify(requestBody), headers, credentials)
            case apiMethods.PUT:
                return httpClient.apiPut(url, requestBody, headers, credentials)
            case apiMethods.DELETE:
                return httpClient.apiDelete(url, requestBody, headers, credentials)
            case apiMethods.PATCH:
                return httpClient.apiPatch(url, requestBody, headers, credentials)
            default:
                return httpClient.apiGet(url, requestBody, headers, credentials)
        }
    }

    /**
     * Switch between get and post calls.
     * @param {string} reqMethod
     * @param {string} url
     * @param {object} requestBody
     * @param {HttpReqHeaders} headers
     * @param {boolean} credentials
     * @return {AxiosPromise}
     */
    static apiMethod = (
        reqMethod: string,
        url: string,
        requestBody?: Record<string, unknown>,
        headers?: HttpReqHeaders,
        credentials = false,
    ): AxiosPromise =>
        BaseService.isMock()
            ? httpClient.apiGet(url, requestBody)
            : BaseService.__configureAPIMethod(reqMethod, url, requestBody, headers, credentials)
}

export { BaseService }
export default BaseService
