import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { ProductDoesNotFitProps } from './ProductDoesNotFit.type'
import Button from '../Button'
import { replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'

const ProductDoesNotFit: React.FC<ProductDoesNotFitProps> = ({ ...props }) => {
    const doesNotFitClassName = `${PREFIX}-product-does-not-fit`
    const vehicleNameHtmlString = `<b>${props.vehicleOrTireName}</b>`
    const productDoesNotFitMessage = replaceStrWithDynamicVal(props.productDoesNotFitPrompt, vehicleNameHtmlString)

    /**
     * This function returns the analytics and redirects to product list
     * @param {string} productListLink
     * @param {string} seeProductFitVehicleCTAButton
     */
    const redirectToProductList = (productListLink: string, seeProductFitVehicleCTAButton: string): void => {
        props.productFitAnalytics(seeProductFitVehicleCTAButton)
        window.location.href = encodeURI(productListLink)
    }

    return (
        <>
            <div className={`${doesNotFitClassName}`}>
                <div className={`${doesNotFitClassName}-title`}>
                    <div dangerouslySetInnerHTML={{ __html: productDoesNotFitMessage }}></div>
                </div>
                <div className={`${doesNotFitClassName}-content`}>{props.productDoesNotFitCopy}</div>
            </div>
            <div className={`${PREFIX}-buy-box__add-cart-wishlist`}>
                <Button
                    id="redirect-to-product"
                    disabled={false}
                    type="primary"
                    size="large"
                    onClick={() => redirectToProductList(props.productListLink, props.seeProductFitVehicleCTAButton)}
                    label={props.seeProductFitVehicleCTAButton}></Button>
            </div>
        </>
    )
}

ProductDoesNotFit.propTypes = {
    productDoesNotFitPrompt: PropTypes.string,
    productDoesNotFitCopy: PropTypes.string,
    seeProductFitVehicleCTAButton: PropTypes.string,
    vehicleOrTireName: PropTypes.string,
    productListLink: PropTypes.string,
    productFitAnalytics: PropTypes.func,
}

export default ProductDoesNotFit
