import getQueryString from '../../utils/getQueryString'
import { timeOutMilliSecond } from '../Accounts/OrderHistory/GlobalPageLoadAnalytics.constant'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import { OnlineSource } from '../../globalConstants'

/**
 * Lite Registration from footer signUp click or query param has 'open-sign-up-modal'
 */
const liteRegistration = (): void => {
    const signupButton = document.getElementById('email-signup') as HTMLElement
    const queryParams = window.location.search
    const openSignupModal = getQueryString(queryParams, 'open-sign-up-modal')

    /**
     * function to open email sign-up modal
     */
    const signUpClicked = (): void => {
        gigyaLoadFunctions('ODP-LiteRegistration', undefined, '', false, false, OnlineSource.footer)
    }

    if (openSignupModal === 'true') {
        // PERFORMANCE: added timeout as gigya script loaded with async
        setTimeout(() => {
            signUpClicked()
        }, timeOutMilliSecond)
    }

    signupButton &&
        signupButton.addEventListener('click', () => {
            signUpClicked()
        })
}

export { liteRegistration }
