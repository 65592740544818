import { errorCodes } from '../globalConstants/global.constant'
import { ErrorMessageType } from './FormatCardNumber/formatCardNumber.type'

/**
 * function decide which error code will be sent to modal to display error message
 * @param { string } errorCode
 * @param { string } messages
 * @return { string }
 */
export const getErrorCodeMessage = (errorCode: string | undefined, messages: ErrorMessageType): string => {
    const { errorMsg, redirectError } = messages

    switch (errorCode) {
        case errorCodes.error00138: // return inline error for linked card
            return redirectError
        case errorCodes.error00140: // return error screen for ineligible card
            return errorCodes.error00140
        case errorCodes.error00049: // return error screen for merge process
            return errorCodes.error00049
        case errorCodes.error00130: // return error screen for rate limiting
            return errorCodes.error00130
        case errorCodes.error00055: // return error screen for try again
        case errorCodes.error00030:
            return errorCodes.error00055
        default:
            return errorMsg
    }
}
