/**
 * This file is designed to read global props from AEM/HTML
 * and make available at application level with Redux
 */
import { ISEOmetadata } from './globalProps.types'

/**
 * This file is designed to read global props from AEM/HTML
 * and make available at application level with Redux
 */
export class GlobalPropsHelper {
    // TODO: improvement required [vinay.mavi] need to work
    /**
     * This function read data from config
     * @return {Record<string, unknown>} configs
     */
    readDataConfig(): Record<string, unknown> {
        return this.dataset('configs')
    }

    /**
     * This function return property by name from dataset
     * @param {string} name of property which need to find
     * @param {boolean} isObject
     * @return {string} property
     */
    dataset(name: string, isObject = true): Record<string, unknown> | string {
        const dataset = document.querySelector('body')?.dataset
        try {
            return isObject ? (JSON.parse(dataset?.[name]) as Record<string, unknown>) : (dataset?.[name] as string)
        } catch (error) {
            return {}
        }
    }
    /**
     * Generate function to read alternateLanguagesDetails data-attribute from body tag
     * @return {ISEOmetadata} returns alternate language details JSON
     */
    readDataAlternateLangDetails(): ISEOmetadata {
        return this.dataset('seometadata')
    }

    /**
     * Function to get SeoImage configuration
     * @return {string}
     */
    readSeoImageConfig(): string {
        return this.dataset('seoimage', false)
    }

    /**
     * function to get digitalFlyer Iframe url
     * @return {string}
     */
    getDigitalFlyerIFrameURL(): string {
        return this.dataset('digitalflyeriframeurl', false) as string
    }

    /**
     * This function return digiCertLogoUrl
     * @return {string} digiCertLogoUrl
     */
    digiCertLogoUrl() {
        return this.dataset('digicertlogourl', false)
    }

    /**
     * This function get canadapost configs
     * @return {string} canadapost
     */
    getCanadaPostConfig() {
        return this.dataset('data-canadapost', false)
    }

    /**
     * This function read data config
     * @return {Record<string, unknown>} dataConfig
     */
    init() {
        return this.readDataConfig()
    }

    /**
     * This function push shared cart link in history
     */
    pushSharedCartLinkInHistory(): void {
        const languageData = this.dataset('seometadata') as Record<string, unknown>
        const path = (languageData?.currentPageDetails as Record<string, string>)?.path || window.location.pathname
        window.history.pushState(null, document.title, path)
    }

    /**
     * Function to get allowedDomainsWithHeaders configuration
     * @return {Record<string, unknown>}
     */
    readAllowedDomainsWithHeadersConfig(): Record<string, unknown> {
        return this.dataset('alloweddomainswithheaders')
    }
}

export default GlobalPropsHelper
