import { Dispatch } from 'redux'

import {
    fetchProductDataSuccessAction,
    fetchProductDataBySkuAction,
    fetchProductDataErrorAction,
    setSelectedSizeProductCodeAction,
    setStickyBuyboxCTAClickedAction,
    toggleAddToCartCTAAction,
    setIfAllVariantsSelectedAction,
    setFBTProductCodeAction,
    setBuyBoxPropsAction,
    setIsOutOfStockAction,
    setQuantitySelectedAction,
    setSelectedImageUrlAction,
    resetProductSKUDataAction,
    setRearQuantitySelectedAction,
    setFrontQuantitySelectedAction,
    fetchFBTProductDataBySkuAction,
    fetchVariantsProductDataBySkuAction,
    fetchProductDataBySkuErrorAction,
    fetchFBTProductDataBySkuErrorAction,
    setIsTireOrWheelShopWithNoVehicleAction,
    setIsSKUPresentInUrlAction,
    setIsOOSCurrentStoreAction,
    setIsProductDataAvailableAction,
    setIsPriceAvailabilityApiDoneAction,
    fetchVariantsProductDataBySkuErrorAction,
    setIsVehicleChangedAction,
    fetchSizeChartDataAction,
    fetchSizeChartDataErrorAction,
    setBrandListFetchSuccess,
    setSelectedVariantIdAction,
    setSelectedVariantAction,
    setSelectedCurrentPriceAction,
} from '../actionCreators'
import {
    ProductResponseData,
    StickyBuyBoxCTARequestPayload,
    SizeChartDataType,
    ProductResponseErrorDTO,
    BrandValues,
    SelectedVariantID,
    SelectedVariant,
} from '../models/product.interface'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import { productDetailsService } from '../../services/productDetailsService/productDetails.service'
import { isArrayNotEmpty } from '@nl/lib'
import { SkuListType } from '../../components/FrequentlyBoughtTogether/FrequentlyBoughtTogether.type'
import { ThresholdValuesType } from '../../components/BuyBox/BuyBox.type'
import { AxiosError, AxiosResponse } from 'axios'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import httpClient from '../utils/httpClient'
import { firstVariantKey, secondVariantKey, thirdVariantKey } from '../../components/BuyBox/BuyBox.constant'
import { Price } from '../models/cart.interface'
import GlobalPropsHelper from '../../analytics/helpers/globalProps/globalProps.helper'
import { logNewRelic } from '../../components/NewRelic/newRelic.helper'

const globalProps = new GlobalPropsHelper()

/**
 * @param {string} productCode productID from query param
 * @param {string} selectedPreferredStoreId selected preferred store id
 * @param {boolean} lightLoadingEnabled
 * @return {Promise<void>} returns api call promise
 */
export const fetchProductData =
    (productCode: string, selectedPreferredStoreId: string, lightLoadingEnabled?: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getProductDetails(productCode, selectedPreferredStoreId, lightLoadingEnabled)
            .then(data => {
                replaceEmptyImagesWithDefault([data?.data], 'images')
                dispatch(fetchProductDataSuccessAction(data.data))
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchProductDataErrorAction({ data, status }))
            })
    }

/**
 * Function to fetch product data by sku
 * @param {string[]|SkuListType[]} skuCode
 * @param {string} selectedPreferredStoreId
 * @param {ThresholdValuesType} thresholdValues
 * @param {boolean} isStaggered
 * @param {boolean} isStaggeredWithSameSku
 * @param {string} brandName
 * @return {Promise<void>}
 */
export const fetchProductDataBySku =
    (
        skuCode: string[] | SkuListType[],
        selectedPreferredStoreId: string,
        thresholdValues?: ThresholdValuesType,
        isStaggered?: boolean,
        isStaggeredWithSameSku?: boolean,
        brandName?: string,
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setIsVehicleChangedAction(false))
        return productDetailsService
            .getProductDetailsOfSku(skuCode, selectedPreferredStoreId, thresholdValues, isStaggered, brandName)
            .then((data: AxiosResponse<ProductResponseData>) => {
                replaceEmptyImagesWithDefault([data?.data], 'images')
                // for Staggered with same sku pushing same object again
                if (isStaggeredWithSameSku && isArrayNotEmpty(data?.data?.skus)) {
                    data.data.skus = [{ ...data?.data?.skus?.[0] }, { ...data?.data?.skus?.[0] }]
                }
                dispatch(fetchProductDataBySkuAction(data.data))
                dispatch(setIsPriceAvailabilityApiDone(true))
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchProductDataBySkuErrorAction({ data, status }))
                dispatch(setIsPriceAvailabilityApiDone(true))
                // temporary logging
                logNewRelic({
                    error,
                    errorInfo: {
                        allowedDomainHeaders: globalProps.readAllowedDomainsWithHeadersConfig(),
                    },
                    resource: 'priceAvailability',
                })
            })
    }

export const setIsOutOfStock =
    (outOfStock: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsOutOfStockAction(outOfStock))
    }

export const setIsOOSCurrentStore =
    (outOfStock: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsOOSCurrentStoreAction(outOfStock))
    }

export const setSelectedFirstVariant =
    (selectedFirstVariant: string, selectedFirstVariantDisplayName?: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariant: SelectedVariant = {
            variantLevel: firstVariantKey,
            selectedVariant: selectedFirstVariant,
            selectedFirstVariantDisplayName: selectedFirstVariantDisplayName,
        }
        dispatch(setSelectedVariantAction(selectedVariant))
    }

export const setSelectedSecondVariant =
    (selectedSecondVariant: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariant: SelectedVariant = {
            variantLevel: secondVariantKey,
            selectedVariant: selectedSecondVariant,
        }
        dispatch(setSelectedVariantAction(selectedVariant))
    }

export const setSelectedProductCode =
    (selectedCode: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedSizeProductCodeAction(selectedCode))
    }

export const setSelectedCurrentPrice =
    (currentPrice: Price) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedCurrentPriceAction(currentPrice))
    }

export const setSelectedThirdVariant =
    (selectedThirdVariant: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariant: SelectedVariant = {
            variantLevel: thirdVariantKey,
            selectedVariant: selectedThirdVariant,
        }
        dispatch(setSelectedVariantAction(selectedVariant))
    }
export const setQuantitySelected =
    (quantitySelected: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setQuantitySelectedAction(quantitySelected))
    }
export const setSelectedFirstVariantId =
    (selectedFirstVariantId: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariantID: SelectedVariantID = {
            variantLevel: firstVariantKey,
            selectedVariantID: selectedFirstVariantId,
        }
        dispatch(setSelectedVariantIdAction(selectedVariantID))
    }

export const setSelectedSecondVariantId =
    (selectedSecondVariantId: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariantID: SelectedVariantID = {
            variantLevel: secondVariantKey,
            selectedVariantID: selectedSecondVariantId,
        }
        dispatch(setSelectedVariantIdAction(selectedVariantID))
    }

export const setSelectedThirdVariantId =
    (selectedThirdVariantId: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariantID: SelectedVariantID = {
            variantLevel: thirdVariantKey,
            selectedVariantID: selectedThirdVariantId,
        }
        dispatch(setSelectedVariantIdAction(selectedVariantID))
    }

export const setStickyBuyboxCTAClicked =
    (requestPayload: StickyBuyBoxCTARequestPayload) =>
    (dispatch: Dispatch): void => {
        dispatch(setStickyBuyboxCTAClickedAction(requestPayload))
    }

export const toggleAddToCartCTA =
    (showCTA: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(toggleAddToCartCTAAction(showCTA))
    }

export const setIfAllVariantsSelected =
    (isSelected: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIfAllVariantsSelectedAction(isSelected))
    }

export const setFBTProductCode =
    (productCode: string[]) =>
    (dispatch: Dispatch): void => {
        dispatch(setFBTProductCodeAction(productCode))
    }

export const setBuyBoxProps =
    (buyBoxProps: Record<string, unknown>) =>
    (dispatch: Dispatch): void => {
        dispatch(setBuyBoxPropsAction(buyBoxProps))
    }

export const setSelectedImageUrl =
    (url: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedImageUrlAction(url))
    }

export const resetProductSKUData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetProductSKUDataAction())
    }
export const setFrontQuantitySelected =
    (frontQuantitySelected: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setFrontQuantitySelectedAction(frontQuantitySelected))
    }
export const setRearQuantitySelected =
    (rearQuantitySelected: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setRearQuantitySelectedAction(rearQuantitySelected))
    }

/**
 * Function to fetch FBT product data by sku
 * @param {string[]|SkuListType[]} skuCode
 * @param {string} selectedPreferredStoreId
 * @param {ThresholdValuesType} thresholdValues
 * @param {boolean} isFBT
 * @return {Promise<void>}
 */

export const fetchFBTProductDataBySku =
    (skuCode: SkuListType[], selectedPreferredStoreId: string, thresholdValues: ThresholdValuesType, isFBT: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getProductDetailsOfSku(skuCode, selectedPreferredStoreId, thresholdValues, isFBT)
            .then(data => {
                dispatch(fetchFBTProductDataBySkuAction(data.data))
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchFBTProductDataBySkuErrorAction({ data, status }))
                // temporary logging
                logNewRelic({
                    error,
                    errorInfo: {
                        allowedDomainHeaders: globalProps.readAllowedDomainsWithHeadersConfig(),
                    },
                    resource: 'priceAvailability',
                })
            })
    }

/**
 * Function to fetch variants product data by sku
 * @param {string[]|SkuListType[]} skuCode
 * @param {string} selectedPreferredStoreId
 * @return {Promise<void>}
 */

export const fetchVariantsProductDataBySku =
    (skuCode: SkuListType[], selectedPreferredStoreId: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getProductDetailsOfSku(skuCode, selectedPreferredStoreId, undefined, true)
            .then(data => {
                dispatch(fetchVariantsProductDataBySkuAction(data.data))
            })
            .catch((error: AxiosError<string>) => {
                const response = error.response
                if (response && response.data) {
                    const { data, status } = error.response as AxiosResponse<string>
                    dispatch(fetchVariantsProductDataBySkuErrorAction({ data, status }))
                }
                // temporary logging
                logNewRelic({
                    error,
                    errorInfo: {
                        allowedDomainHeaders: globalProps.readAllowedDomainsWithHeadersConfig(),
                    },
                    resource: 'priceAvailability',
                })
            })
    }

/**
 * function to set redux state if shopwith no vehicle selected or not
 * @param {boolean} isTireOrWheelShopWithNoVehicle
 * @return {void}
 */
export const setIsTireOrWheelShopWithNoVehicle =
    (isTireOrWheelShopWithNoVehicle: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsTireOrWheelShopWithNoVehicleAction(isTireOrWheelShopWithNoVehicle))
    }

/**
 * function to check sku present in the url
 * @param {boolean} isSKUPresent
 * @return {void}
 */
export const setIsSKUPresentInUrl =
    (isSKUPresent: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsSKUPresentInUrlAction(isSKUPresent))
    }

/**
 * function to check sku present in the url
 * @param {boolean} isApisDone
 * @return {void}
 */
export const setIsProductDataAvailable =
    (isApisDone: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsProductDataAvailableAction(isApisDone))
    }

/**
 * function to reset IsNearByStoreListApiDone
 * @param {boolean} isApiCalled
 * @return {Dispatch}
 */
export const setIsPriceAvailabilityApiDone =
    (isApiCalled: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsPriceAvailabilityApiDoneAction(isApiCalled))
    }

/**
 * function to set vehicleChanged
 * @param {boolean} isApiCalled
 * @return {Dispatch}
 */
export const setIsVehicleChanged =
    (isApiCalled: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsVehicleChangedAction(isApiCalled))
    }

/**
 * function to fetch size chart for current product
 * @param {BreadcrumbLinkData[]} breadcrumbList
 * @param {string} brand
 * @param {string} language
 * @return {Dispatch}
 */
export const fetchSizeChartData =
    (breadcrumbList: BreadcrumbLinkData[], brand: string, language: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getSizeChartData(breadcrumbList, brand, language)
            .then(({ data: sizeChartData }: any) =>
                dispatch(fetchSizeChartDataAction(sizeChartData as SizeChartDataType)),
            )
            .catch((error: { response: { data: any; status: any } }) => {
                const { data, status } = error.response as ProductResponseErrorDTO
                dispatch(fetchSizeChartDataErrorAction({ data, status }))
            })
    }

/**
 * function call to get the brandList api values
 * @param {string} brandUrlList
 * @return {Dispatch}
 */
export const fetchBrandList =
    (brandUrlList: string) =>
    (dispatch: Dispatch): Promise<void> | void => {
        return httpClient.apiGet(brandUrlList, {}, 'AEM_EXP_FRAG').then(data => {
            if (data.data) {
                dispatch(setBrandListFetchSuccess(data.data as BrandValues[]))
            }
        })
    }
