import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { refDisplayName } from './ReferenceComponent.constant'
import usePrevious from '../../hooks/usePrevious.hook'

/**
 * This function returns dangerouslySetInnerHTML for ReferenceComponent
 *
 * @return {JSX.Element} returns dangerouslySetInnerHTML
 */
export const ReferenceComponent = React.forwardRef(
    (
        props: { htmlStr: string; renderReactComponent: (content: string) => void },
        ref: React.MutableRefObject<null>,
    ) => {
        const { htmlStr, renderReactComponent } = props
        const previousHtmlStr = usePrevious(htmlStr)

        /**
         * function to remove <script></script> tag
         * @param {string} targetedScript
         * @return {string} returns javascript function
         */
        const stripScriptsTag = (targetedScript: string): string => {
            const result = targetedScript.replace(/<script\b[^>]*>/g, '')
            return result.replace(/<\/script\b[^>]*>/g, '')
        }

        useEffect(() => {
            !!htmlStr && htmlStr !== previousHtmlStr && renderReactComponent(htmlStr)
            const extractScriptRegex = /<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g

            if (htmlStr.includes('script')) {
                const extractScript = htmlStr.match(extractScriptRegex)

                // handle muliple scripts within a banner
                extractScript?.length &&
                    extractScript.map(targetedScript => {
                        const scriptContent = stripScriptsTag(targetedScript)
                        // Use of eval() is strongly discauraged due to security and performance implications,
                        // hence we can safely disable this rule.
                        // eslint-disable-next-line @typescript-eslint/no-implied-eval
                        Function(scriptContent)()
                    })
            }
        }, [htmlStr, previousHtmlStr, renderReactComponent])

        return <div ref={ref} dangerouslySetInnerHTML={{ __html: htmlStr }}></div>
    },
)

ReferenceComponent.displayName = refDisplayName

ReferenceComponent.propTypes = {
    htmlStr: PropTypes.string.isRequired,
    renderReactComponent: PropTypes.func.isRequired,
}

export default ReferenceComponent
