import { createAction } from 'typesafe-actions'

import { RecommendationResponseDataDTO, RecommendationsErrorResponse } from '../models/recommendations.interface'
import {
    FETCH_RECOMMENDATIONS_SUCCESS,
    FETCH_RECOMMENDATIONS_FAILURE,
} from '../types/products/recommendationsData.actionTypes.constant'

export const fetchRecommendationsDataSuccessAction = createAction(
    FETCH_RECOMMENDATIONS_SUCCESS,
    (payload: RecommendationResponseDataDTO[]) => payload,
)<RecommendationResponseDataDTO[]>()

export const fetchRecommendationsDataErrorAction = createAction(
    FETCH_RECOMMENDATIONS_FAILURE,
    (payload: RecommendationsErrorResponse) => payload,
)<RecommendationsErrorResponse>()
