import { frenchLocale } from '../../globalConstants/global.constant'
import { getLanguage } from '../../utils'
import { getUnpaddedTimeFormat } from '../../utils/getUnpaddedTimeFormat'
import { getUsLocale } from '../../utils/getUsLocale'
import { timeConstants } from './TimePicker.constants'

/**
 * Prepares the time format in the AM, PM and h (fr) language style
 * @param { string } time
 * @return { string }
 */
export const getTimeFormat = (time: string): string => {
    const unpaddedTimeFormat = getUnpaddedTimeFormat(
        new Date(time).toLocaleTimeString(getUsLocale(), {
            hour: 'numeric',
            minute: 'numeric',
        }),
    )
    return getLanguage() === frenchLocale
        ? unpaddedTimeFormat.replace(timeConstants.timeDelimiter, timeConstants.hFormatReplacement)
        : unpaddedTimeFormat
}
