import { Vehicle, magicNumber } from '@nl/lib'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProductCardData } from '../../redux/actions/ProductData.action'

/**
 * Interface for providing custom fetch strategy, it should consist:
 * - Uniq id string
 * - priority - to select best from applicable strategies
 * - isApplicable - predicate to filter out not applicable strategies
 * - dispatch - method on necessary action like a fetch action or service call when facet try to update the products
 */
export interface FetchStrategy {
    id: string
    priority: number
    isApplicable: () => boolean
    dispatch: (
        requestPayload: string,
        selectedPreferredStoreId: string,
        isFitmentRequired?: boolean,
        productToFetch?: number,
        pageNumber?: number,
        pathwayId?: string,
        breadcrumbList?: string[],
        defaultVehicle?: Vehicle,
        currentPackageId?: string,
        isCompleteVehicleState?: boolean,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchCategoryId?: string,
        searchPassQParameter?: boolean,
        searchQParameter?: string,
        searchExperience?: string,
        tireWheelSpecification?: string,
    ) => void
}

// object to collect uniq by id strategies
const strategies = {} as Record<string, FetchStrategy>

export const useDispatchOfFetchProductData = (customStrategy?: FetchStrategy) => {
    const dispatch = useDispatch()

    if (customStrategy) {
        strategies[customStrategy?.id] = customStrategy
    }

    /**
     * use effect register default strategy once (at least once per component)
     */
    useEffect(() => {
        strategies['default'] = {
            id: 'default',
            priority: magicNumber.MINUS_ONE,
            isApplicable: () => true,
            dispatch: (
                queryParams: string,
                selectedPreferredStoreId: string,
                isFitmentRequired?: boolean,
                productToFetch?: number,
            ) => {
                dispatch(fetchProductCardData(queryParams, selectedPreferredStoreId, isFitmentRequired, productToFetch))
            },
        } as FetchStrategy
    }, [dispatch])

    /**
     * It is proxy callback what select correct strategy to fetch base on the current applicability context
     * @param {string} requestPayload
     * @param {string} selectedPreferredStoreId
     * @param {boolean} isFitmentRequired
     * @param {number} productToFetch
     * @param {number} pageNumber
     * @param {string} pathwayId
     * @param {string[]} breadcrumbList
     * @param {Vehicle} defaultVehicle
     * @param {string} currentPackageId
     * @param {boolean} isCompleteVehicleState
     * @param {string[]} searchSuppressFacets
     * @param {string} searchWidgetType
     * @param {string} searchCategoryLevel
     * @param {string} searchCategoryId
     * @param {boolean} searchPassQParameter
     * @param {string} searchQParameter
     * @param {string} searchExperience
     * @param {string} tireWheelSpecification
     * @returns {void}
     */
    return useCallback(
        (
            requestPayload: string,
            selectedPreferredStoreId: string,
            isFitmentRequired?: boolean,
            productToFetch?: number,
            pageNumber?: number,
            pathwayId?: string,
            breadcrumbList?: string[],
            defaultVehicle?: Vehicle,
            currentPackageId?: string,
            isCompleteVehicleState?: boolean,
            searchSuppressFacets?: string[],
            searchWidgetType?: string,
            searchCategoryLevel?: string,
            searchCategoryId?: string,
            searchPassQParameter?: boolean,
            searchQParameter?: string,
            searchExperience?: string,
            tireWheelSpecification?: string,
        ) => {
            Object.values(strategies)
                .filter(strategy => strategy.isApplicable())
                .reduce(
                    (max: FetchStrategy, current: FetchStrategy) =>
                        !max || max.priority < current.priority ? current : max,
                    null as unknown as FetchStrategy,
                )
                ?.dispatch?.(
                    requestPayload,
                    selectedPreferredStoreId,
                    isFitmentRequired,
                    productToFetch,
                    pageNumber,
                    pathwayId,
                    breadcrumbList,
                    defaultVehicle,
                    currentPackageId,
                    isCompleteVehicleState,
                    searchSuppressFacets,
                    searchWidgetType,
                    searchCategoryLevel,
                    searchCategoryId,
                    searchPassQParameter,
                    searchQParameter,
                    searchExperience,
                    tireWheelSpecification,
                )
        },
        [],
    )
}
