import { analyticsAttributes } from '../../globalConstants'
import { Vehicle } from '@nl/lib'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers/index'
import { AnalyticsRecordInterface } from '../providers/provider.type'

const provider = getProvider()

const shopByVehicleAnalytics = (() => {
    /**
     * This function is to trigger analytics for shop by vehicle/tire size
     * @param {Vehicle} vehicle
     * @param {string} shopByVehicle
     * @param {string} tireLabel
     * @param {string} componentType
     * @param {string} actionName
     * @return {AnalyticsRecordInterface} - analytics data for interaction event
     */
    const _vehicleAnalyticsData = (
        vehicle: Vehicle,
        shopByVehicle?: string,
        tireLabel?: string,
        componentType?: string,
        actionName?: string,
    ): AnalyticsRecordInterface => {
        const {
            event: { vehicleInteraction, interaction },
            eventParameters: {
                category: { vehicleType },
            },
        } = analyticsAttributes
        const hasYearMakeModel =
            vehicle?.autoAttributes?.make && vehicle?.autoAttributes?.year && vehicle?.autoAttributes?.model
        const hasOptionBody = vehicle?.autoAttributes?.body && vehicle?.autoAttributes?.option
        const partialVehicleJIT = hasYearMakeModel && !hasOptionBody
        return {
            event: shopByVehicle === 'true' ? vehicleInteraction : interaction,
            eventParameters: {
                action: actionName,
                location: `${getPageType()} - ${String(componentType)}`,
                category: vehicleType,
                label: tireLabel,
                value: undefined,
            },
            vehicle: {
                partialVehicleJIT: String(!!partialVehicleJIT),
                shopWithVehicle: String(!!shopByVehicle),
                type: vehicle?.vehicleType,
                make: vehicle?.autoAttributes?.make,
                model: vehicle?.autoAttributes?.model,
                year: vehicle?.autoAttributes?.year,
                body: vehicle?.autoAttributes?.body,
                option: vehicle?.autoAttributes?.option,
            },
        }
    }

    return {
        shopByAnalytics: (
            vehicle: Vehicle,
            shopByVehicle?: string,
            tireLabel?: string,
            componentType?: string,
            actionName?: string,
        ) => {
            provider.push(_vehicleAnalyticsData(vehicle, shopByVehicle, tireLabel, componentType, actionName))
        },
    }
})()

export default shopByVehicleAnalytics
