import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX, BREAKPOINTS } from '../config'
import Button from '../Button'
import Price from '../Price'
import Review from '../Review'
import ProductInformation from '../ProductInfo'
import { useGlobalScrollEvent } from '../../utils/useWindowEvent'
import { STICKY_TO_BOTTOM } from '../../globalConstants/global.constant'
import { StickyBuyboxProps } from './StickyBuyBox.type'
import Badges from '../Badges'
import ProductDoesNotFit from '../AutomotivePDP/ProductDoesNotFit'
import { magicNumber } from '../../utils'
import { calculateHeaderHeight } from '../StickyTOC/CalculateHeaderHeight'

/**
 * StickyBuyBox component
 * @param {StickyBuyboxProps} props
 * @return {JSX.Element} returns StickyBuyBox component
 */
const StickyBuyBox: React.FC<StickyBuyboxProps> = props => {
    const {
        brandUrl,
        brand,
        title,
        desktopATCLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        thresholdValue,
        productImageUrl,
        productAverageRating,
        numberOfReviews,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        onlineOrdering,
        feeTitle,
        originalPrice,
        currentPrice,
        displayWasLabel,
        discount,
        language,
        badges,
        badgesAndPriorities,
        addToCartClickHandler,
        showAddToCartOnStickyBuyBox,
        priceMessage,
        scrollToFooter,
        a11yRecycleFeesTriangleIcon,
        feeDisclaimerType,
        isOutOfStock,
        otherTiresFitCTAHandler,
        seeOtherTiresFitCTA,
        feeDisclaimerMessage,
        feeDisclaimerTitle,
        hideDisclaimer,
        showEachTextForSaveLabel,
        a11yClickToReadFootnote,
    } = props
    const [isSticky, setSticky] = useState(false)
    let buyBoxCompEL: HTMLElement | null
    let fbtCompEL: HTMLElement | null
    const removeStickyClassName = '--sticky-none'
    const { mobileMaxWidth } = BREAKPOINTS
    const isMobile = window.innerWidth <= mobileMaxWidth
    const handleScroll = () => {
        buyBoxCompEL = document.getElementById(`${PREFIX}-buy-box`) || null
        const buyBoxCompELHeight = buyBoxCompEL?.clientHeight || 0
        const buyBoxCompELTop = buyBoxCompEL?.offsetTop || 0
        const buyBoxContentHeight = buyBoxCompELHeight + buyBoxCompELTop

        fbtCompEL = document.getElementById(`${PREFIX}-fbt`) || null
        const fbtCompELHeight = fbtCompEL?.clientHeight || 0
        const fbtCompELTop = fbtCompEL?.offsetTop || 0
        const fbtContentHeight = fbtCompELHeight + fbtCompELTop

        if (fbtCompEL && isMobile) {
            setStickyWithFBTComponent(buyBoxContentHeight, fbtCompELTop, fbtContentHeight)
        } else {
            setStickyComponent(buyBoxContentHeight)
        }
    }

    /**
     * @return {number} height of the navbar
     */
    const getNavbarHeight = (): number => {
        const primaryNavElm = document.getElementById(`${PREFIX}-nav`) || null
        return primaryNavElm?.clientHeight || 0
    }

    /**
     * @return {number} height of the primary-navbar
     */
    const getPrimaryNavHeight = (): number => {
        const primaryNav = document.querySelector(`.${PREFIX}-primary-navigation`) || null
        return primaryNav?.clientHeight || 0
    }

    /* Set Sticky State When Frequently Brought Together is available */
    const setStickyWithFBTComponent = (buyBoxContentHeight: number, fbtCompELTop: number, fbtContentHeight: number) => {
        if (
            window.pageYOffset > buyBoxContentHeight &&
            (window.pageYOffset < fbtCompELTop - window.innerHeight ||
                window.pageYOffset > fbtContentHeight ||
                fbtCompEL?.getBoundingClientRect()?.bottom - (calculateHeaderHeight() + getNavbarHeight()) <
                    magicNumber.FIVE)
        ) {
            setSticky(true)
        } else {
            setSticky(false)
        }
    }

    const setStickyComponent = (buyBoxContentHeight: number) => {
        window.pageYOffset > buyBoxContentHeight - getPrimaryNavHeight() ? setSticky(true) : setSticky(false)
    }

    useGlobalScrollEvent(handleScroll)

    const replacePrice = () => {
        return props.staggeredMobileATCText
    }

    /**
     * Updates the cart data when quantity selector value is changed.
     * @return {JSX.Element} - returns Review component
     */
    const renderReviewComp = (): JSX.Element | undefined => {
        const reviewsToPass = numberOfReviews ? numberOfReviews : 0

        return (
            <div className={`${PREFIX}-sticky-buy-box__reviews`}>
                <Review numberOfReviews={reviewsToPass} averageRating={productAverageRating} />
            </div>
        )
    }

    const checkAllPropsAreNull = price => Object.values(price).filter(val => !!val).length === 0
    const isSupressedPrice =
        currentPrice || originalPrice
            ? checkAllPropsAreNull(currentPrice) && checkAllPropsAreNull(originalPrice)
            : false
    const isDisabledATC = isSupressedPrice

    /**
     * show badges in sticky buy box
     *
     * @return {JSX.Element}
     */
    const showBadges = (): JSX.Element => {
        return (
            !props.isAutomotiveProductNotFit &&
            !props.homeService && (
                <div className={`${PREFIX}-buy-box__badges`}>
                    <Badges badges={badges} badgesAndPriorities={badgesAndPriorities} hideDisclaimer={hideDisclaimer} />
                </div>
            )
        )
    }

    /**
     * render auto part does not fit component in sticky buybox
     *
     * @return {JSX.Element}
     */
    const showAutoPartNotFit = (): JSX.Element => {
        return (
            props.isAutomotiveProductNotFit && (
                <ProductDoesNotFit
                    productDoesNotFitPrompt={props.productDoesNotFitPrompt}
                    productDoesNotFitCopy={props.productDoesNotFitCopy}
                    vehicleOrTireName={props.vehicleOrTireName}
                    seeProductFitVehicleCTAButton={props.seeProductFitVehicleCTAButton}
                    productFitAnalytics={props.productFitAnalytics}
                    productListLink={props.productListLink}
                />
            )
        )
    }

    /**
     * render desktop ass to cart
     *
     * @return {JSX.Element}
     */
    const showDesktopATCLabel = (): JSX.Element => {
        return (
            !props.isAutomotiveProductNotFit &&
            showAddToCartOnStickyBuyBox &&
            props.AddTocartCTAStatus &&
            !props.isBrowseOnlyMode &&
            onlineOrdering && (
                <Button type="primary" size="large" onClick={addToCartClickHandler} disabled={isDisabledATC}>
                    {desktopATCLabel}
                </Button>
            )
        )
    }

    /**
     * renders other tires that fit button
     * @return {JSX.Element}
     */
    const renderOtherFitButton = (): JSX.Element => {
        return (
            <Button type="primary" size="large" onClick={otherTiresFitCTAHandler}>
                {seeOtherTiresFitCTA}
            </Button>
        )
    }

    const showAddVehicleCTA = (): JSX.Element => {
        return (
            !props.AddTocartCTAStatus &&
            !props.isTireOrWheelShopWithNoVehicle &&
            !props.isAutomotiveProductNotFit && (
                <div className={`${PREFIX}-sticky-buy-box__button-container`}>
                    <Button id="shop-by-size" type="primary" size="large" onClick={props.shopBySizeHandler}>
                        {props.seeProductFitVehicleCTAButton}
                    </Button>
                    {props.showShopWithNoVehicle && (
                        <Button id="shop-with-no-vehicle" type="tertiary" onClick={props.shopWithNoVehicleHandler}>
                            {props.shopWithNoVehicle}
                        </Button>
                    )}
                </div>
            )
        )
    }

    // function will return price component for sticky buyBox
    const showPriceComponent = (): JSX.Element => {
        return (
            <Price
                unitPriceLabel={unitPriceLabel}
                clearancePriceLabel={clearancePriceLabel}
                promotionalPriceLabel={promotionalPriceLabel}
                thresholdValue={thresholdValue}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                feeTitle={feeTitle}
                originalPrice={originalPrice}
                currentPrice={currentPrice}
                displayWasLabel={displayWasLabel}
                discountValue={discount}
                language={language}
                priceMessage={priceMessage}
                feeDisclaimerType={feeDisclaimerType}
                a11yRecycleFeesTriangleIcon={a11yRecycleFeesTriangleIcon}
                scrollToFooter={scrollToFooter}
                nowFromLabel={props.nowFromLabel}
                saveFromLabel={props.saveFromLabel}
                wasFromLabel={props.wasFromLabel}
                fromLabel={props.fromLabel}
                feeDisclaimerMessage={feeDisclaimerMessage}
                feeDisclaimerTitle={feeDisclaimerTitle}
                isWheelOrTire={props.showSetOfFourLabel}
                setOfFourLabel={props.setOfFourLabel}
                totalCurrentPrice={props.totalCurrentPrice}
                totalOriginalPrice={props.totalOriginalPrice}
                isStaggered={props.isStaggered}
                eachLabel={props.eachLabel}
                isAutomotiveEachLabel={props.isAutomotiveEachLabel}
                isWheelOrTirePDP={props.showOfEachLabel}
                showFromNow={true}
                setOfFourNowLabel={props.setOfFourNowLabel}
                showEachTextForSaveLabel={showEachTextForSaveLabel}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
            />
        )
    }

    // function to return Product information for sticky BuyBox
    const productInformation = (): JSX.Element => {
        return (
            <ProductInformation
                brand={brand}
                brandUrl={brandUrl}
                title={title}
                isFitmentRequired={props.isFitmentRequired}
                tireCategory={props.tireCategory}
                isSticky={true}
            />
        )
    }

    // function to return add to cart button or ProductDoesNotFit for stickyBuyBox
    const showProductDoesNotFit = (): JSX.Element => {
        return (
            <>
                {!props.isBrowseOnlyMode &&
                    showAddToCartOnStickyBuyBox &&
                    onlineOrdering &&
                    !props.isTireOrWheelShopWithNoVehicle && (
                        <div id={`${PREFIX}-sticky-buy-box-mobile`} className={`${PREFIX}-sticky-buy-box-mobile`}>
                            <div
                                className={`${PREFIX}-sticky-buy-box-mobile${
                                    isSticky ? `--sticky-atc ${STICKY_TO_BOTTOM}` : removeStickyClassName
                                }`}>
                                {!props.isAutomotiveProductNotFit ? (
                                    <Button
                                        id="add-to-cart"
                                        type="primary_light"
                                        onClick={addToCartClickHandler}
                                        size="large"
                                        disabled={isDisabledATC}>
                                        {replacePrice()}
                                    </Button>
                                ) : (
                                    <ProductDoesNotFit
                                        productDoesNotFitPrompt={props.productDoesNotFitPrompt}
                                        productDoesNotFitCopy={props.productDoesNotFitCopy}
                                        vehicleOrTireName={props.vehicleOrTireName}
                                        seeProductFitVehicleCTAButton={props.seeProductFitVehicleCTAButton}
                                        productListLink={props.productListLink}
                                        productFitAnalytics={props.productFitAnalytics}
                                    />
                                )}
                            </div>
                        </div>
                    )}
            </>
        )
    }

    return (
        <>
            <div className={`${PREFIX}-sticky-buy-box-hide-content`}>
                <div
                    data-testid="sticky-buybox"
                    id={`${PREFIX}-sticky-buy-box`}
                    className={` ${PREFIX}-sticky-buy-box ${PREFIX}-sticky-buy-box${
                        isSticky ? '--sticky' : removeStickyClassName
                    }`}>
                    <img
                        data-component-name="sticky-buy-box"
                        src={productImageUrl}
                        alt={brand}
                        className={`${PREFIX}-sticky-buy-box__responsive-img`}
                    />
                    {productInformation()}
                    {/* not rendering Review component because of increase in height in sticky buy box */}
                    {!renderReviewComp()}
                    {showPriceComponent()}
                    {showAutoPartNotFit()}
                    {/* not rendering Badges component because of increase in height in sticky buy box */}
                    {!showBadges()}
                    {isOutOfStock ? renderOtherFitButton() : showDesktopATCLabel()}
                    {showAddVehicleCTA()}
                </div>
            </div>
            {showProductDoesNotFit()}
            {!props.isBrowseOnlyMode && !props.isAutomotiveProductNotFit && !props.AddTocartCTAStatus && (
                <div id={`${PREFIX}-sticky-buy-box-mobile`} className={`${PREFIX}-sticky-buy-box-mobile`}>
                    <div
                        className={`${PREFIX}-sticky-buy-box-mobile${
                            isSticky ? `--sticky-atc ${STICKY_TO_BOTTOM}` : removeStickyClassName
                        }`}>
                        {showAddVehicleCTA()}
                    </div>
                </div>
            )}
        </>
    )
}
StickyBuyBox.propTypes = {
    brandUrl: PropTypes.string,
    brand: PropTypes.string,
    title: PropTypes.string,
    desktopATCLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    thresholdValue: PropTypes.number,
    productImageUrl: PropTypes.string,
    numberOfReviews: PropTypes.number,
    productAverageRating: PropTypes.number,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    onlineOrdering: PropTypes.bool,
    feeTitle: PropTypes.string,
    originalPrice: PropTypes.any,
    currentPrice: PropTypes.any,
    displayWasLabel: PropTypes.bool,
    discount: PropTypes.any,
    language: PropTypes.string,
    badges: PropTypes.arrayOf(PropTypes.string).isRequired,
    badgesAndPriorities: PropTypes.exact({
        maxNumberToDisplay: PropTypes.number.isRequired,
        priorityAndColour: PropTypes.array.isRequired,
    }).isRequired,
    addToCartClickHandler: PropTypes.func.isRequired,
    showAddToCartOnStickyBuyBox: PropTypes.bool.isRequired,
    priceMessage: PropTypes.array,
    isAutomotiveProductNotFit: PropTypes.bool,
    productDoesNotFitPrompt: PropTypes.string,
    vehicleOrTireName: PropTypes.string,
    productDoesNotFitCopy: PropTypes.string,
    scrollToFooter: PropTypes.func,
    a11yRecycleFeesTriangleIcon: PropTypes.string,
    tireCategory: PropTypes.string,
    isFitmentRequired: PropTypes.bool,
    productListLink: PropTypes.string,
    seeProductFitVehicleCTAButton: PropTypes.string,
    AddTocartCTAStatus: PropTypes.bool,
    shopWithNoVehicle: PropTypes.string,
    showShopWithNoVehicle: PropTypes.bool,
    shopWithNoVehicleHandler: PropTypes.func,
    productFitAnalytics: PropTypes.func,
    shopBySizeHandler: PropTypes.func,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeDisclaimerType: PropTypes.string,
    isOutOfStock: PropTypes.bool,
    otherTiresFitCTAHandler: PropTypes.func,
    seeOtherTiresFitCTA: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    hideDisclaimer: PropTypes.bool,
    setOfFourLabel: PropTypes.string.isRequired,
    showSetOfFourLabel: PropTypes.bool,
    showOfEachLabel: PropTypes.bool,
    staggeredMobileATCText: PropTypes.string,
    totalCurrentPrice: PropTypes.object,
    totalOriginalPrice: PropTypes.object,
    isStaggered: PropTypes.bool,
    eachLabel: PropTypes.string.isRequired,
    isAutomotiveEachLabel: PropTypes.bool.isRequired,
    setOfFourNowLabel: PropTypes.string.isRequired,
    homeService: PropTypes.bool,
    isTireOrWheelShopWithNoVehicle: PropTypes.bool,
    showEachTextForSaveLabel: PropTypes.bool,
    isBrowseOnlyMode: PropTypes.bool,
    a11yClickToReadFootnote: PropTypes.string,
}
export default StickyBuyBox
