import { currencyShortForm } from '../globalConstants/global.constant'

/**
 * To get the summary line
 * @param {string} value
 *  @param {string} country
 *  @param {string} stateProvince
 *  @return {boolean}
 */
export const checkStateProvince = (value: string, country: string, stateProvince: string): boolean => {
    if (country === currencyShortForm) {
        return value?.toUpperCase() === `${country}-${stateProvince}`
    } else return false
}
