import { previousElementName } from '../components/config'
import { handleAccessibilityType } from '../components/FlyoutModalComponent/FlyoutModalComponent.type'

/**
 * Handles accessibility for modals.
 *
 * @param {boolean} modalOpen - when false, removes the tab index and aria-hidden from the non modal elements and vice versa.
 * @param {string} modalClassName - Modal Container class name. ex: nl-flyout-overlay
 */
export const modalAccessibilityHandler = ({ modalOpen, modalClassName }: handleAccessibilityType): void => {
    const ariaHiddenLabel = 'aria-hidden'

    /**
     * This variable gives all focusable elements present in DOM as stringified
     */
    const focusableElementSelectorsString = [
        'a[href]',
        'area[href]',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
        'button:not([disabled])',
        'iframe',
        'object',
        'embed',
        '[contenteditable]',
        '.snapper_pane',
        '#secondaryMenuBar',
        '#legal-disclaimer-container',
        '#nl-banner-skinny__button',
    ].join(',')

    /** @type {Element} */
    const modalContainer: Element = document.querySelector(`.${modalClassName}`)

    /**
     * @type {Record<string,string>}
     */
    const dataAttrObject: Record<string, string> = {
        'data-add-tabindex': 'tabindex',
        'data-add-aria-hidden': ariaHiddenLabel,
    }

    /**
     * Remove the tabindex, aria-hidden, data-add-tabindex, data-add-aria-hidden attributes from the focusable elements.
     *
     * @param {Element} focusableElement - Interactive element.
     */
    const removeAddedAttributes = (focusableElement: Element): void => {
        Object.keys(dataAttrObject).forEach(dataAttrName => {
            focusableElement.hasAttribute(dataAttrName) &&
                focusableElement.removeAttribute(dataAttrObject[dataAttrName])
            focusableElement.removeAttribute(dataAttrName)
        })
    }

    /**
     * Steps:
     * 1. Iterate through all the interactive elements.
     * 2. For each element check whether it has tabindex and aria-hidden
     * 3. If modal is open add the tabindex, aria-hidden and custom data attributes to the elements which doesn't have either one of the attribute.
     * 4. If modal is closed, remove all the added attributes only on which the custom attributes are added. In this way we can persists the original tabindex="-1" and aria-hidden=true
     */
    document.querySelectorAll(focusableElementSelectorsString).forEach(focusableElement => {
        const checkTabIndex = focusableElement.getAttribute('tabindex') !== '-1'
        const checkAriaHidden = focusableElement.getAttribute(ariaHiddenLabel) !== 'false'
        if (modalOpen) {
            if (checkTabIndex) {
                focusableElement.setAttribute('tabindex', '-1')
                focusableElement.setAttribute('data-add-tabindex', 'true')
            }
            if (checkAriaHidden) {
                focusableElement.setAttribute(ariaHiddenLabel, 'true')
                focusableElement.setAttribute('data-add-aria-hidden', 'true')
            }
        } else {
            removeAddedAttributes(focusableElement)
        }
    })

    // If modal is open, remove the added attributes so that we can have tab interaction only with in the modal in the entire page.
    if (modalOpen) {
        Array.from(modalContainer.querySelectorAll(focusableElementSelectorsString)).forEach(modalFocusableElement => {
            removeAddedAttributes(modalFocusableElement)
        })

        const focusableElement: HTMLElement = modalContainer.querySelector(focusableElementSelectorsString)
        focusableElement?.focus({ preventScroll: true })
    }
}

/**
 * Function to add data attribute to the element which invoked the modal.
 *
 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - Click Event.
 */
export const addModalAttribute = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent | KeyboardEvent | MouseEvent,
): void => {
    const button = event.currentTarget as HTMLElement
    button.setAttribute(previousElementName, 'true')
}
