import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setShowSpinner } from '../redux/actionCreators'
import {
    GiftCardInterface,
    NewGiftCardInterface,
    GiftCardData,
    ApplyGiftCard,
    DeleteGiftCard,
    GiftCardService,
    RemainingGiftCardInterface,
} from '@nl/lib/src/components/GiftCard/GiftCard.types'
import appCacheService from '../utils/appCacheService'
import { applyGiftCardAction, deleteGiftCardAction, updateEditPaymentState } from '../redux/actions/checkout.action'
import { MagicNumber } from '../analytics/analytics.type'
import { editPaymentClickedSelector } from '../redux/selectors/checkout.selectors'

/**
 * Custom hook that returns applied Gift Cards data and methods.
 * @param {GiftCardService} giftCardService
 * @param {Dispatch<any>} dispatch
 * @return {{giftCardData: GiftCardData, applyGiftCard: ApplyGiftCard, deleteGiftCard: DeleteGiftCard}}
 */
export const useGiftCard = (
    giftCardService: GiftCardService,
    dispatch?: Dispatch<any>,
): {
    giftCardData: GiftCardData
    applyGiftCard: ApplyGiftCard
    deleteGiftCard: DeleteGiftCard
} => {
    const [giftCardData, setGiftCardData] = useState<GiftCardData>({
        numberOfGiftCards: 0,
        giftCardAmount: 0,
        giftCardList: [],
    })
    const editPaymentClicked = useSelector(editPaymentClickedSelector)

    const formatGiftCards = (giftCardList: GiftCardInterface[]): RemainingGiftCardInterface[] => {
        const formattedGiftCardArray: RemainingGiftCardInterface[] = []
        giftCardList?.forEach((giftCard: GiftCardInterface) => {
            const { id: tenderId, amount } = giftCard
            formattedGiftCardArray.push({ tenderId, amount })
        })
        return formattedGiftCardArray
    }

    useEffect(() => {
        giftCardService.giftCardsApplied = (
            numberOfGiftCards: number,
            giftCardAmount: number,
            giftCardList: GiftCardInterface[],
            remainingAmount: number,
        ) => {
            const cartId = appCacheService.getCartGuid()
            const selectedPreferredStoreId = appCacheService.preferredStoreId.get()
            if (giftCardData.numberOfGiftCards < numberOfGiftCards) {
                const tenderId = giftCardList[numberOfGiftCards - MagicNumber.ONE]?.id
                const amount = giftCardList[numberOfGiftCards - MagicNumber.ONE]?.amount
                dispatch && dispatch(applyGiftCardAction(amount, tenderId, cartId, selectedPreferredStoreId))
            } else if (editPaymentClicked) {
                dispatch && dispatch(updateEditPaymentState(false))
            } else {
                const deletedGiftCard = giftCardData?.giftCardList?.filter(
                    previousGiftCard =>
                        !giftCardList?.some(currentGiftCard => currentGiftCard?.id === previousGiftCard?.id),
                )

                const tenderId = deletedGiftCard?.[MagicNumber.ZERO]?.id
                if (tenderId) {
                    dispatch &&
                        dispatch(
                            deleteGiftCardAction(
                                tenderId,
                                cartId,
                                formatGiftCards(giftCardList),
                                selectedPreferredStoreId,
                            ),
                        )
                }
            }
            setGiftCardData({ numberOfGiftCards, giftCardAmount, giftCardList, remainingAmount })
        }
        giftCardService.completeGiftCardUpdate = () => {
            dispatch && dispatch(setShowSpinner(false))
        }
    }, [setGiftCardData, dispatch, giftCardService, giftCardData, editPaymentClicked])

    const applyGiftCard = useCallback(
        (card: NewGiftCardInterface): void => {
            dispatch && dispatch(setShowSpinner(true))
            giftCardService.applyGiftCard(card)
        },
        [dispatch, giftCardService],
    )

    const deleteGiftCard = useCallback(
        (card: { id: string }): void => {
            dispatch && dispatch(setShowSpinner(true))
            giftCardService.deleteGiftCard(card)
        },
        [dispatch, giftCardService],
    )

    return { giftCardData, applyGiftCard, deleteGiftCard }
}
