export interface InlineToastProps {
    icon: string
    type: InlineToastType
    label: string
}

export enum InlineToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}
