interface newRelicObj {
    error: Error
    errorInfo?: unknown
    resource: string
    logErrorName?: string
}

export const logNewRelic = ({ error, errorInfo = {}, resource, logErrorName = resource }: newRelicObj): void => {
    window.newrelic?.addPageAction?.(`JSTRACE:${logErrorName}`, {
        error,
        errorInfo,
        resource,
    })
}
