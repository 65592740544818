import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators'
import { LinkLoyaltyCardErrorResponse, LinkLoyaltyCardType } from '../models/linkLoyaltyCard.interface'
import {
    tryAgainErrorAction,
    tryAgainSuccessAction,
    tryAgainErrorClearAction,
    tryAgainSuccessClearAction,
} from '../actionCreators/tryAgain.actionCreators'

type Action = ActionType<typeof actions>

export interface tryAgainState {
    tryAgainSuccessState: LinkLoyaltyCardType | null
    tryAgainErrorState: LinkLoyaltyCardErrorResponse | null
}

export const initialState: tryAgainState = {
    tryAgainSuccessState: null,
    tryAgainErrorState: null,
}

export const tryAgainReducer = (state: tryAgainState = initialState, action: Action): tryAgainState => {
    switch (action.type) {
        case getType(tryAgainSuccessAction): {
            return { ...state, tryAgainSuccessState: action.payload }
        }
        case getType(tryAgainErrorAction): {
            return { ...state, tryAgainErrorState: action.payload }
        }
        case getType(tryAgainSuccessClearAction): {
            return { ...state, tryAgainSuccessState: null }
        }
        case getType(tryAgainErrorClearAction): {
            return { ...state, tryAgainErrorState: null }
        }
        default:
            return state
    }
}
