import { useRef } from 'react'
import { scrollRestorationKey } from './productCard.constant'

export const useScrollRestoration = () => {
    const restorationRef = useRef<HTMLAnchorElement>(null)

    const saveDataAsScrollRestorationId = (dataAsStringId: string) => {
        sessionStorage.setItem(scrollRestorationKey, dataAsStringId)
    }
    const getScrollRestorationId = () => sessionStorage.getItem(scrollRestorationKey)
    const removeScrollRestorationId = () => sessionStorage.removeItem(scrollRestorationKey)

    const restoreScroll = () => {
        if (restorationRef.current) {
            restorationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            restorationRef.current.focus()
        }
    }

    return {
        restorationRef,
        getScrollRestorationId,
        saveDataAsScrollRestorationId,
        removeScrollRestorationId,
        restoreScroll,
    }
}
