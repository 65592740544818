import { ActionType, getType } from 'typesafe-actions'

import { RecommendationResponseDataDTO } from '../models/recommendations.interface'
import { fetchRecommendationsDataSuccessAction } from '../actionCreators'
import * as actions from '../actionCreators/recommendationsData.actionCreators'

type Action = ActionType<typeof actions>
export interface RecommendationsDataState {
    recommendationsData: RecommendationResponseDataDTO[]
}

export const initialState: RecommendationsDataState = {
    recommendationsData: [] as RecommendationResponseDataDTO[],
}

export const recommendationsDataReducer = (
    state: RecommendationsDataState = initialState,
    action: Action,
): RecommendationsDataState => {
    if (action.type === getType(fetchRecommendationsDataSuccessAction)) {
        return { ...state, recommendationsData: action.payload }
    } else {
        return state
    }
}
