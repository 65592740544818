/**
 * function to convert object into string value
 * @param {string} str - string value to parse to object
 * @return {Object}
 */
const convertStrToObj = (str: string): Record<string, unknown> => {
    let obj: {
        [key: string]: unknown
    } = {}
    if (str && typeof str === 'string') {
        const objStr: RegExpMatchArray | null = str.match(/\{(.)+\}/g)
        try {
            obj = (objStr ? JSON.parse(objStr[0]) : {}) as Record<string, unknown>
        } catch (e) {
            obj = {}
        }
    }
    return obj
}
export default convertStrToObj
