import BaseService from '../base.service'
import { GlobalPropsHelper } from '../../analytics/helpers/globalProps'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import { OrderDetails } from '../../redux/models/orderDetails.interface'
import { OrderCancellationData } from '../../redux/models/orderCancellation.interface'
import { AxiosPromise } from 'axios'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Post the Order cancellation
 * @param {bannerId} bannerid
 * @param {userId} userId
 * @param {code} code
 */
class OrderCancellationService extends BaseService {
    /**
     * Send the orderCancellationPost
     * @param {OrderCancellationData} orderToCancel
     * @param {OrderDetails} requestedParams
     * @return {URL} url
     */
    getOrderCancellationData(orderToCancel: OrderCancellationData, requestedParams: OrderDetails): AxiosPromise {
        const url: URL = this.createOrderCancelUrl('cancelOrder', requestedParams)
        const payload = {
            entries: orderToCancel.entries,
            reason: orderToCancel.reason,
            notes: orderToCancel.notes,
        }

        return OrderCancellationService.isMock()
            ? httpClient.apiGet(url.toString(), payload)
            : httpClient.apiPost(url.toString(), payload)
    }

    /**
     * Create the necessary URL path
     * @param {string} endpoint
     * @param {OrderDetails} requestedParams
     * @return {URL} url
     */
    createOrderCancelUrl(endpoint: string, requestedParams: OrderDetails): URL {
        const globalProps = new GlobalPropsHelper()
        const configs = globalProps.init()
        const bannerId = configs?.['bannerId']
        const { userId, code, guid } = requestedParams
        const lang = OrderCancellationService.language

        return new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS[endpoint]}?lang=${lang}&$bannerId=${String(
                bannerId,
            )}&userId=${userId}&code=${code}&guid=${guid}`,
        )
    }
}

const orderCancellationService = new OrderCancellationService()
export { orderCancellationService }
export default orderCancellationService
