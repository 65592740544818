import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Button from '../Button'

import { PREFIX } from '../config'
import { ctaListType, SignInDropDownType } from './SignInDropDown.type'
import SignInDropDownItem from './SignInDropDownItem'
import { linkTargetOptions } from '../../globalConstants/global.constant'
import { stringKeyCodes } from '../../utils/stringKeyCodes'
import { magicNumber } from '../../utils/magicNumber'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'

const SignInDropDown: React.FC<SignInDropDownType> = (props: SignInDropDownType): JSX.Element => {
    const { signInCtaLabel, isLoggedIn, onSignInButtonClickHandler, changeButtonType, onFlyout, ctaA11ySignInLabel } =
        props
    const buttonType = changeButtonType ? changeButtonType : 'secondary'
    const [showDropDown, setShowDropDown] = useState(false)
    const contentRef = useRef(null)
    useClickOutsideClose(contentRef, () => onSignInButtonClick(), true, true)

    /**
     * SignIn Dropdown
     */
    const onSignInButtonClick = () => {
        setShowDropDown(!showDropDown)
    }

    // to handle click event on dropdown item
    const onDropDownItemClick = () => {
        onSignInButtonClickHandler()
        setShowDropDown(!showDropDown) // to close dropdown once the menu item is clicked to prevent event propagation in gigya screens
    }

    /**
     * Close the dropdown when user press esc button.
     * @param {KeyboardEvent} e - use to capture the esc keycode.
     */
    const menuEscapeHandler = useCallback((e: KeyboardEvent): void => {
        if (e.key === stringKeyCodes['esc']) {
            setShowDropDown(false) // close the dropdown
        }
    }, [])

    /**
     * Close the dropdown when user press tab key / shift+Tab when on last or first item respectively
     * @param {Element} firstItem - first item in menu.
     * @param {Element} lastItem - last item in menu.
     * @param {KeyboardEvent} e - use to capture the keycode.
     */

    const addKeyBoardAccessibility = useCallback((firstItem: Element, lastItem: Element, e: KeyboardEvent): void => {
        const elem = document.activeElement
        if (
            (e.key === stringKeyCodes['tab'] && elem === lastItem && !e.shiftKey) ||
            (elem === firstItem && e.shiftKey && e.key === stringKeyCodes['tab'])
        ) {
            e.stopPropagation()
            e.preventDefault()
            setShowDropDown(false)
        }
    }, [])

    /**
     * useEffect for adding event listeners
     */
    useEffect(() => {
        document.addEventListener('keyup', menuEscapeHandler)
        return () => {
            document.removeEventListener('keyup', menuEscapeHandler)
        }
    }, [menuEscapeHandler])

    /**
     * useEffect for adding event listeners and creating menuAccessibility
     */
    useEffect(() => {
        let firstItem = null
        let lastItem = null
        if (showDropDown) {
            const menuDropDownParent = document.getElementsByClassName(
                `${PREFIX}-right-dropdown__content`,
            )[0] as HTMLElement
            const interactiveElements: NodeListOf<Element> = menuDropDownParent?.querySelectorAll('a,button')
            const menuItems = interactiveElements && Array.from(interactiveElements)
            if (menuItems && menuItems.length > 0) {
                firstItem = menuItems[0] // Set the first item in the menu
                lastItem = menuItems[menuItems.length - magicNumber.ONE] // Get the last item in the menu
            }
            menuDropDownParent &&
                menuDropDownParent.addEventListener('keydown', addKeyBoardAccessibility.bind(null, firstItem, lastItem))
        }
        return () => {
            document.removeEventListener('keydown', addKeyBoardAccessibility.bind(null, firstItem, lastItem))
        }
    }, [showDropDown, addKeyBoardAccessibility])

    /**
     * Onclick of dropdown shows logo,image and if external links adds diagonal arrow
     * @param {ctaListType} item
     * @return {JSX.Element}
     */
    const renderDropdownContent = (): JSX.Element => {
        return (
            <nav className={`${PREFIX}-right-dropdown__content ${PREFIX}-row ${PREFIX}-col-xs-6`} ref={contentRef}>
                {props.ctaList.map((item: ctaListType, i: number) => (
                    <SignInDropDownItem
                        key={i}
                        item={item}
                        type={item.ctaLinkTarget === linkTargetOptions.selfTarget}
                        onClickHandler={onDropDownItemClick}
                        onFlyout={onFlyout}
                    />
                ))}
            </nav>
        )
    }

    return (
        <>
            {!isLoggedIn && (
                <div className={`${PREFIX}-right-dropdown ${PREFIX}-right-dropdown__container`}>
                    <Button
                        type={buttonType}
                        size="small"
                        onClick={onSignInButtonClick}
                        ariaExpanded={showDropDown}
                        aria-label={ctaA11ySignInLabel}>
                        {signInCtaLabel}
                        <Icon type={`ct-chevron-${showDropDown ? 'up' : 'down'}`} />
                    </Button>
                    {showDropDown && renderDropdownContent()}
                </div>
            )}
        </>
    )
}

SignInDropDown.propTypes = {
    onSignInButtonClickHandler: PropTypes.func,
}

export default SignInDropDown
