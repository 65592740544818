type envType = 'mock' | 'remote'
const env: envType = 'remote'
const productDetails = 'productDetails.json'
/**
 * @deprecated
 */
export const mock = {
    API_BASE_URL: './stubs/',
    EXT_SYSTEM_URLS: {
        // Add external systems' URLs here
        CERTONA: './stubs/',
        BLOOMREACH: './stubs/',
        AEM_MOCK_JSON: '/stubs/',
        CDS_ON_PREM: '../stubs/',
        AEM_EXP_FRAG: '/',
        BLOOMREACH_SEARCH: './stubs/',
        CDS_PREFERENCE_ODP: './stubs',
        CDS_CLOUD: './stubs',
    },
    BASE_SITE_IDS: ['ATM', 'CTR', 'SC', 'MKS', 'LEQ'],
    BASE_SITE: 'ct',
    JSON_RESPONSE_FORMAT: 'json',
    PARTY_CITY_STORE_ID: '834',
    API_ENDPOINTS: {
        productDetails: productDetails,
        resourceDetails: 'resources.json',
        recommendationsData: 'recommendationsData.json',
        carts: 'users/anonymous/carts',
        priceLtoH: 'priceLtoH.json',
        priceHtoL: 'priceHtoL.json',
        newArrivals: 'newArrivals.json',
        customerRatings: 'customerRatings.json',
        saveSorting: 'saveSorting.json',
        bestSellers: 'bestSellers.json',
        // NOSONAR
        cdsUserProfilePayment: '/cds/customer/account/profile/paymentinfo',
        notificationPreference: '/mock/cds/customer/account/preferences/communication',
        shoppingPreference: '/cds/customer/account/preferences/jnjtraits',
        cdsUserProfilePaymentmock: 'paymentInfo',
        cdsUserProfilePaymentDelete: 'noPaymentInfo',
        cdsLinkRegion: '/cds/customer/link-loyalty/regions',
        dssLinkRegion: 'regions',
        dssLinkCountries: 'countries',
        dssLoyaltyCardLookup: '/dss/services/triangle/loyaltyCardLookup',
        cdsLoyaltyLinkCard: '/cds/customer/link-loyalty/loyalty/link',
        cdsLoyaltyLinkCheck: '/cds/customer/link-loyalty/loyalty/check',
        cdsLoyaltyLinkValidate: '/cds/customer/link-loyalty/loyalty/validate',
        cdsLoyaltyLinkProfile: '/cds/customer/link-loyalty/loyalty',
        cdsSignOut: '/cds/customer/authorization/signout',
        search: 'product/',
        cartData: 'cartData.json',
        switchOptionData: 'switchOptionData.json',
        removeCartData: 'removeCartData.json',
        updateCartData: 'updateCartData.json',
        cartModification: 'cartModification.json',
        cartDeliveryData: 'cartDeliveryData.json',
        invalidPC: 'invalidPC.json',
        outOfRangePC: 'outOfRangePC.json',
        validPC: 'updatePostalCode.json',
        errorPC: 'errorPostalCode.json',
        getPostalCodeData: 'postalCodeData.json',
        creditCardAssociation: '/cds/customer/merge-loyalty-cards/validate/creditcard-association',
        validateLoyalty: '/cds/customer/merge-loyalty-cards/validate/loyaltycard',
        validateLoyaltyExtended: '/cds/customer/merge-loyalty-cards/validate/loyaltycard/extended',
        mergeLoyaltyCard: '/cds/customer/merge-loyalty-cards/merge',
        selectedServiceCartData: 'selectedServiceCartData.json',
        triangleTransactionHistory: 'triangleTransactionHistory.json',
        addToCartError: 'addToCartError.json',
        findAddress: 'Find/v2.10/json3.ws',
        orderConfirmation: '/mock/order/placeOrder.json',
    },
}
/**
 * @deprecated
 */
export const remote = {
    API_BASE_URL: 'https://api.c0onq03u1z-canadiant2-d2-public.model-t.cc.commerce.ondemand.com/rest/v2/ctr', // default api URL here. Hybris for now
    EXT_SYSTEM_URLS: {
        // Add external systems' URLs here
        CERTONA: 'https://api.c0onq03u1z-canadiant2-d1-public.model-t.cc.commerce.ondemand.com',
        BLOOMREACH: 'https://api.c0onq03u1z-canadiant2-d1-public.model-t.cc.commerce.ondemand.com',
        BLOOMREACH_SEARCH: 'https://api.canadiantire.ca/search/api/v0/',
        AEM_EXP_FRAG: '/',
        AEM_MOCK_JSON: '/content/dam/canadiantire/mock-json/',
        CDS_ON_PREM: 'https://stag-api.canadiantire.ca',
        CANADA_POST: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/',
        AEM_COMMON_API: '/bin/ctc',
        CDS_EXP_CART: '/cart/api/v1',
        CDS_PREFERENCE_ODP: 'https://stag-api.canadiantire.ca',
        CDS_CLOUD: 'https://apim-nprd-cc-agw.canadiantire.ca/public/dev/v1',
    },
    BASE_SITE_IDS: ['ATM', 'CTR', 'SC', 'MKS', 'LEQ'],
    BASE_SITE: 'ct',
    JSON_RESPONSE_FORMAT: 'json',
    PARTY_CITY_STORE_ID: '834',
    API_ENDPOINTS: {
        productDetails: '/products/',
        productsList: '/products/productsList/',
        recommendationsData: 'recommendationsData.json',
        carts: 'users/anonymous/carts',
        priceLtoH: 'priceLtoH.json',
        priceHtoL: 'priceHtoL.json',
        newArrivals: 'newArrivals.json',
        customerRatings: 'customerRatings.json',
        saveSorting: 'saveSorting.json',
        bestSellers: 'bestSellers.json',
        cdsUserProfilePayment: '/cds/customer/account/profile/paymentinfo',
        notificationPreference: '/mock/cds/customer/account/preferences/communication',
        shoppingPreference: '/cds/customer/account/preferences/jnjtraits',
        cdsUserProfilePaymentmock: 'paymentInfo',
        cdsUserProfilePaymentDelete: 'noPaymentInfo',
        cdsLinkRegion: '/cds/customer/link-loyalty/regions?locale=',
        dssLinkRegion: 'regions',
        dssLinkCountries: 'countries',
        dssLoyaltyCardLookup: '/dss/services/triangle/loyaltyCardLookup',
        cdsLoyaltyLinkCard: '/link-loyalty/loyalty/link',
        cdsLoyaltyLinkCheck: '/link-loyalty/loyalty/check',
        cdsLoyaltyLinkValidate: '/link-loyalty/loyalty/validate',
        cdsLoyaltyLinkProfile: '/link-loyalty/loyalty',
        cdsSignOut: '/cds/customer/authorization/signout',
        search: 'product/',
        cartData: 'cartData.json',
        switchOptionData: 'switchOptionData.json',
        removeCartData: 'removeCartData.json',
        updateCartData: 'updateCartData.json',
        cartModification: 'cartModification.json',
        cartDeliveryData: 'cartDeliveryData.json',
        invalidPC: 'invalidPC.json',
        outOfRangePC: 'outOfRangePC.json',
        validPC: 'updatePostalCode.json',
        errorPC: 'errorPostalCode.json',
        getPostalCodeData: 'postalCodeData.json',
        creditCardAssociation: '/cds/customer/merge-loyalty-cards/validate/creditcard-association',
        validateLoyalty: '/cds/customer/merge-loyalty-cards/validate/loyaltycard',
        validateLoyaltyExtended: '/cds/customer/merge-loyalty-cards/validate/loyaltycard/extended',
        mergeLoyaltyCard: '/cds/customer/merge-loyalty-cards/merge',
        checkCookie: 'cds/customer/authorization/sso/check-cookie?refClient=odp/ctrweb&refPath=%2F',
        setCookie: 'cds/customer/authorization/sso/set-cookie',
        selectedServiceCartData: 'selectedServiceCartData.json',
        addToCart: 'cart',
        triangleTransactionHistory: 'triangleTransactionHistory.json',
        addToCartError: 'addToCartError.json',
        findAddress: 'Find/v2.10/json3.ws',
        retrieveAddress: 'RetrieveFormatted/v2.10/json3.ws',
        commonApiContent: 'apidetails.json',
    },
}

export const apiConfig = {
    mock,
    remote,
}

/**
 * @deprecated
 */
export const API_BASE_URL = apiConfig[env].API_BASE_URL
/**
 * @deprecated
 */
export const API_ENDPOINTS = apiConfig[env].API_ENDPOINTS
/**
 * @deprecated
 */
export const EXT_SYSTEM_URLS = apiConfig[env].EXT_SYSTEM_URLS
/**
 * @deprecated
 */
export const BASE_SITE_IDS = apiConfig[env].BASE_SITE_IDS
/**
 * @deprecated
 */
export const BASE_SITE = apiConfig[env].BASE_SITE
/**
 * @deprecated
 */
export const JSON_RESPONSE_FORMAT = apiConfig[env].JSON_RESPONSE_FORMAT
/**
 * @deprecated
 */

export const PARTY_CITY_STORE_ID = apiConfig[env].PARTY_CITY_STORE_ID
