import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { getProvider } from '../providers'
import { StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import getPageType from '../../utils/getPageType'
import { AnalyticsRecordInterface } from '../providers/provider.type'

const provider = getProvider()

const storeLocatorAnalytics = (() => {
    /**
     * This Function used to fire data layer analytics event when store locator view changes
     * @param {boolean} isMapView store locator selected map view
     */

    const _switchStoreViewEvents = (isMapView: boolean): AnalyticsRecordInterface => {
        const {
            event: { interaction },
            eventParameters: {
                action: { viewStoreList, viewStoreMap },
                category: { store },
            },
        } = analyticsAttributes

        return {
            event: interaction,
            eventParameters: {
                action: isMapView ? viewStoreList : viewStoreMap,
                category: store,
                label: '',
                value: '',
            },
        }
    }

    /**
     * This Function used to fire data layer analytics on preferred store changes
     * @param {StoreWithAvailability} preferredStore preferred store
     * @param {boolean} isClosed flag to check if store is closed
     * @param {string} location flag to check page location
     */

    const _setPreferredStoreEvents = (
        preferredStore: StoreWithAvailability,
        isClosed: boolean,
        location: string,
        selectionType: string,
    ): AnalyticsRecordInterface => {
        const {
            event: { interaction },
            eventParameters: {
                action: { setPreferredStore },
                category: { store },
            },
        } = analyticsAttributes
        const {
            id,
            name,
            address: { region, town },
        } = preferredStore
        return {
            event: interaction,
            eventParameters: {
                action: setPreferredStore,
                category: store,
                label: `${id}`,
                value: '',
                location: location || getPageType() || '',
            },
            store: {
                id: `${id}`,
                name,
                province: region && region.name,
                city: town,
                openNow: String(!isClosed),
                selection: selectionType,
            },
        }
    }

    /**
     * This Function used to fire data layer analytics on search store
     * @param {string} searchTerm searched keyword
     * @param {string} location parameter for page location
     */

    const _searchStoreEvents = (searchTerm: string, location: string): AnalyticsRecordInterface => {
        const {
            event: { interaction },
            eventParameters: {
                action: { searchStore },
                labels: { userInput, geolocation },
                category: { store },
            },
        } = analyticsAttributes

        return {
            event: interaction,
            eventParameters: {
                action: searchStore,
                category: store,
                label: searchTerm ? userInput : geolocation,
                value: '',
                location: location || getPageType() || '',
            },
        }
    }

    /**
     * This Function is used to fire data layer analytics on store filter
     * @param {string} filter searched keyword
     * @param {boolean} isSelected filter is selected or deselected
     * @return {AnalyticsRecordInterface}
     */

    const _storeFilterEvents = (filter: string, isSelected: boolean): AnalyticsRecordInterface => {
        const {
            event: { interaction },
            eventParameters: {
                action: { filterStores },
                labels: { selected, deselected },
                category: { store },
            },
        } = analyticsAttributes

        const isToggled = isSelected ? selected : deselected
        return {
            event: interaction,
            eventParameters: {
                action: filterStores,
                category: store,
                label: `${filter} ${isToggled}`,
                value: '',
            },
        }
    }

    /**
     * This Function is used to fire data layer analytics on click of see more stores button in the headerStoreLocator
     * @param {string} label button label
     * @param {string} location page location
     */

    const _viewMoreStoreEvents = (buttonLabel: string, location: string): AnalyticsRecordInterface => {
        const {
            event: { interaction },
            eventParameters: {
                action: { storeLocator },
            },
        } = analyticsAttributes

        return {
            event: interaction,
            eventParameters: {
                action: storeLocator,
                category: getPageType() || '',
                label: buttonLabel,
                value: '',
                location,
            },
        }
    }

    return {
        switchStoreViewAnalytics: (isMapView: boolean) => {
            provider.push(_switchStoreViewEvents(isMapView))
        },
        setPreferredStoreAnalytics: (
            preferredStore: StoreWithAvailability,
            isClosed: boolean,
            location: string,
            selectionType: string,
        ) => {
            provider.push(_setPreferredStoreEvents(preferredStore, isClosed, location, selectionType))
        },
        searchStoreAnalytics: (searchTerm = '', location = '') => {
            provider.push(_searchStoreEvents(searchTerm, location))
        },
        storeFilterAnalytics: (filter = '', isSelected = true) => {
            provider.push(_storeFilterEvents(filter, isSelected))
        },
        viewMoreStoreAnalytics: (buttonLabel: string, location: string) => {
            provider.push(_viewMoreStoreEvents(buttonLabel, location))
        },
    }
})()

export default storeLocatorAnalytics
