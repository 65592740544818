import { HrefLangLinkData, ProductResponseData } from '../../redux/models/product.interface'
import { CategoryResponseById, CategoryUrlType } from '../../redux/models/category.interface'
import { multiLanguageLabel, multiLanguageAltValue } from '../../globalConstants/global.constant'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import { magicNumber } from '@nl/lib'

const languagesToggleService = () => {
    const { englishLangLabel, frenchLangLabel } = multiLanguageLabel
    const { englishLang, frenchLang } = multiLanguageAltValue
    const pageType = getPageType() || ''
    const { productPage, pdpPage, categoryPages, store } = pageTypes

    /**
     * Generate function to return either frenchLocale or defaultLocale on the basis of Languages label
     * @param {string} languageLabel
     * @return {string} returns languageLocale
     */
    const _getHrefLang = (languageLabel: string): string[] => {
        return languageLabel === englishLangLabel
            ? [englishLangLabel.toLowerCase(), englishLang]
            : [frenchLangLabel.toLowerCase(), frenchLang]
    }

    /**
     * Generate function to return hrefPath on the basis of Languages label
     * @param {string} languageLabel
     * @param {HrefLangLinkData[]|CategoryUrlType[]} hrefLangUrls
     * @param {string} selectedProductCode
     * @return {string} returns hrefPath
     */
    const _getProductDetailsAltLang = (
        languageLabel: string,
        hrefLangUrls: HrefLangLinkData[] | CategoryUrlType[],
        selectedProductCode?: string,
    ): string => {
        const currentHost = window.location.origin
        let hrefPath = ''
        hrefLangUrls?.forEach((hrefLangUrl: HrefLangLinkData | CategoryUrlType) => {
            const href = (hrefLangUrl as HrefLangLinkData)?.href || (hrefLangUrl as CategoryUrlType)?.url
            const { hreflang } = hrefLangUrl
            if (_getHrefLang(languageLabel).includes(hreflang)) {
                let updatedhref = href
                if (selectedProductCode && !selectedProductCode.includes('P') && !selectedProductCode.includes('F')) {
                    const hrefArr = href.split('.')
                    hrefArr.splice(magicNumber.ONE, magicNumber.ZERO, selectedProductCode)
                    updatedhref = hrefArr.join('.')
                }
                hrefPath = currentHost + updatedhref + window.location.search
            }
        })
        return hrefPath
    }

    /**
     * Generate function to return pagePath on the basis of pageType and Language Toggle Service
     * @param {string} languageLabel
     * @param {ProductResponseData} productData
     * @param {CategoryResponseById} categoryData
     * @param {StoreWithAvailability} storeDetails
     * @param {string} selectedProductCode
     * @return {string} returns hrefPath
     */
    const _getPageTypeLangUrl = (
        languageLabel: string,
        productData: ProductResponseData,
        categoryData: CategoryResponseById,
        storeDetails: StoreWithAvailability,
        selectedProductCode?: string,
    ): string => {
        let hrefData: HrefLangLinkData[] | CategoryUrlType[] | undefined
        switch (true) {
            case pageType === productPage:
            case pageType === pdpPage: {
                hrefData = productData?.hreflangLinkData
                break
            }
            case categoryPages.includes(pageType): {
                hrefData = categoryData?.url
                break
            }
            case pageType === store: {
                hrefData = storeDetails?.hreflangLinkData
                break
            }
            default: {
                break
            }
        }
        return hrefData ? _getProductDetailsAltLang(languageLabel, hrefData, selectedProductCode) : ''
    }

    return {
        getPageTypeLangUrl: _getPageTypeLangUrl,
    }
}

export { languagesToggleService }
export default languagesToggleService
