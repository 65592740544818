/**
 * Link to Tooltip component.
 * @return {JSX.Element} returns Backdrop to be shown behind Tooltip
 */

import React from 'react'
import { PREFIX } from '../config'

const Backdrop: React.FC = () => {
    return <div className={`${PREFIX}-backdrop-tooltip`}></div>
}

export default Backdrop
