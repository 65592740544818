import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { isArrayNotEmpty, magicNumber, stringKeyCodes, trim } from '../../../utils'
import Button from '../../Button'
import { PREFIX } from '../../config'
import Icon from '../../Icon'
import { Suggestion, SuggestionsProps } from '../SearchBar.type'

const Suggestions: React.FC<SuggestionsProps> = props => {
    const {
        handleMobileFillTypeahead,
        a11yUpdateQueryIconLabel,
        headerLabel,
        suggestedList,
        currentSuggestionIndex,
        handleMouseover,
        handleSuggestionClick,
        handleSuggestionEnter,
        originalSearchValue,
        clearHistory,
        searchHistoryClearLabel,
        indexMargin,
        type,
        showTypeahead,
        a11yArrowButtonsAriaLabel,
        setShowSuggestionPanel,
        currentSuggestionRef,
        currentTypeaheadRef,
    } = props

    /**
     * highlight all occurrences of the search term within the given label
     * @param {string} label - label to be displayed
     * @return {string} - label that was passed in, with search term highlighted
     */
    const highlightKeyword = useCallback(
        (label: string) => {
            if (originalSearchValue && label) {
                const regexp = new RegExp(
                    trim(originalSearchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).replace('\\*', ''),
                    'ig',
                )
                label = label.replace(regexp, '<br>$&<br>')
                const [firstPart, matchingPart, secondPart] = label.split('<br>')
                label = (
                    <>
                        <span className={`${PREFIX}-suggestion-text__highlighted`}>{firstPart}</span>
                        {matchingPart}
                        <span className={`${PREFIX}-suggestion-text__highlighted`}>{secondPart}</span>
                    </>
                ) as unknown as string
            }
            return <span className={`${PREFIX}-suggestion-text`}>{label}</span>
        },
        [originalSearchValue],
    )

    /**
     * handle key events
     * @param {KeyboardEvent} event - event
     */
    const closeSuggessionPanel = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            event.key === stringKeyCodes.esc ||
            (event.key === stringKeyCodes.tab &&
                document.activeElement?.id === `'trigger-typeahead-icon'_${suggestedList.length}` &&
                !document.getElementById('clear-history-icon'))
        ) {
            setShowSuggestionPanel(false)
        }
    }

    /**
     * This function  do comparison between current index and current suggestion index and returns boolean
     * @param {number} index
     * @return {boolean}
     */
    const currentIndexComparison = (index: number): boolean => {
        return index + indexMargin === currentSuggestionIndex
    }

    const renderTypeaheadButton = (label: string, index: number) => (
        <Button
            ref={currentIndexComparison(index) ? currentTypeaheadRef : null}
            type="icon_button"
            onClick={() => handleMobileFillTypeahead(label)}
            id={`'trigger-typeahead-icon'_${index + magicNumber.ONE}`}
            ariaLabel={`${a11yUpdateQueryIconLabel || ''} ${label.concat(' ', a11yArrowButtonsAriaLabel)}`}
            buttonType="button"
            onKeyDown={closeSuggessionPanel}>
            <Icon type="ct-arrow-typeahead" />
        </Button>
    )

    return (
        isArrayNotEmpty(suggestedList) && (
            <div className={`${PREFIX}-suggested-${type}-panel`}>
                <div
                    className={`${PREFIX}-suggestion-grid-title${
                        showTypeahead ? `` : ` ${PREFIX}-suggestion-grid-title__big-margin`
                    }`}>
                    {headerLabel}
                    {searchHistoryClearLabel && clearHistory && (
                        <Button
                            id="clear-history-icon"
                            type="tertiary"
                            onClick={clearHistory}
                            data-props={{ style: 'float: right' }}
                            onKeyDown={(event: React.KeyboardEvent) => {
                                if (event.key === stringKeyCodes.tab) {
                                    setShowSuggestionPanel(false)
                                }
                            }}>
                            {searchHistoryClearLabel}
                        </Button>
                    )}
                </div>
                {suggestedList.map((keyword: Suggestion, i: number) => {
                    const keywordData = { ...keyword, type, index: i + indexMargin }

                    return (
                        <div
                            style={{ marginBottom: showTypeahead ? '0' : '1rem' }}
                            key={`${type}_${i}`}
                            className={`${PREFIX}-suggestion-wrapper`}>
                            <div
                                role="button"
                                tabIndex={magicNumber.MINUS_ONE}
                                className={
                                    `${PREFIX}-suggestion ${PREFIX}-suggested-${type}` +
                                    (i + indexMargin == currentSuggestionIndex ? ' selected' : '')
                                }
                                data-testid={`${type}_${i + indexMargin}`}
                                onMouseEnter={() => handleMouseover(keywordData)}
                                onClick={() => handleSuggestionClick(keywordData)}
                                onKeyDown={e => handleSuggestionEnter(e)}
                                ref={currentIndexComparison(i) ? currentSuggestionRef : null}>
                                {highlightKeyword(keyword.label)}
                            </div>
                            {showTypeahead && renderTypeaheadButton(keyword.label, i)}
                        </div>
                    )
                })}
            </div>
        )
    )
}

Suggestions.propTypes = {
    handleMobileFillTypeahead: PropTypes.func.isRequired,
    a11yUpdateQueryIconLabel: PropTypes.string,
    headerLabel: PropTypes.string.isRequired,
    suggestedList: PropTypes.arrayOf(
        PropTypes.exact({
            isRedirect: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            searchUrl: PropTypes.string.isRequired,
            type: PropTypes.string,
            index: PropTypes.number,
        }),
    ).isRequired,
    currentSuggestionIndex: PropTypes.number.isRequired,
    handleMouseover: PropTypes.func.isRequired,
    handleSuggestionClick: PropTypes.func.isRequired,
    handleSuggestionEnter: PropTypes.func.isRequired,
    originalSearchValue: PropTypes.string,
    clearHistory: PropTypes.func,
    searchHistoryClearLabel: PropTypes.string,
    indexMargin: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    showTypeahead: PropTypes.bool.isRequired,
    setShowSuggestionPanel: PropTypes.func,
}

export default Suggestions
