import { createAction } from 'typesafe-actions'
import { AOADateSlotPayload } from '../models/aoaDateTimeStep.interface'
import {
    AOA_CLEAR_DATE_SLOTS,
    AOA_SAVE_DATE_SLOTS,
} from '../types/aoaDateTimeStep/aoaDateTimeStep.actionTypes.constants'

export const saveDateSlotsAction = createAction(
    AOA_SAVE_DATE_SLOTS,
    (payload: AOADateSlotPayload): AOADateSlotPayload => payload,
)<AOADateSlotPayload>()

export const clearAOADateSlotsAction = createAction(AOA_CLEAR_DATE_SLOTS)()
