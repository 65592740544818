import { YesNoNotApplicable } from '../analytics/providers/provider.type'
import {
    FilteredCartData,
    eEntryType,
    CartOrderEntries,
    CartOrderDeliveryModes,
    FreeShippingProgress,
    CartData,
} from '../redux/models/cart.interface'
import { isServiceEligible } from './automotiveProducts'

/**
 * generic function to reduce complexity by and operator
 * @param {boolean[]}params
 * @return {boolean}
 */
const areAllParamsValid = (...params: unknown[]): unknown => {
    return params.reduce((paramA, paramB) => paramA && paramB)
}

/**
 * generic function to reduce complexity by or operator
 * @param {boolean[]}params
 * @return {boolean | undefined}
 */
const isAtleastOneParamValid = (...params: unknown[]): unknown => {
    return params.reduce((paramA, paramB) => paramA || paramB)
}

const getSth = (orderEntries: CartOrderEntries[]) => {
    return orderEntries?.filter(item =>
        areAllParamsValid(
            item.fulfillment?.deliveryMode === CartOrderDeliveryModes.STH ||
                item.fulfillment?.deliveryMode === CartOrderDeliveryModes.EXPRESS,
            item.entryType === eEntryType.PRODUCT,
            !item.cartEntryReference,
        ),
    )
}

// TODO: This code should be moved to cart helper
const getFilteredCartItems = <T extends CartData>(cartData: T, quantity?: number): FilteredCartData => {
    const orderEntries = isAtleastOneParamValid(
        cartData.orderEntries,
        cartData.updatedCart?.orderEntries,
        cartData.entries,
        [],
    ) as CartOrderEntries[]
    const cart = isAtleastOneParamValid(cartData.updatedCart, cartData)
    const bopis = orderEntries?.filter(item =>
        areAllParamsValid(
            isAtleastOneParamValid(
                item.fulfillment?.deliveryMode === CartOrderDeliveryModes.BOPIS,
                item.fulfillment?.deliveryMode === CartOrderDeliveryModes.CURBSIDE,
            ),
            item.entryType === eEntryType.PRODUCT,
            !item.cartEntryReference,
        ),
    )
    const sth = getSth(orderEntries)
    const services = orderEntries.filter(item => item.entryType === eEntryType.SERVICE_STANDALONE)
    const storeData = isAtleastOneParamValid(
        cartData.entries ? cartData.deliveryPointOfService : cartData.store,
        cartData.updatedCart?.store,
        [],
    )
    const deliveryAddress = cartData?.deliveryAddress
    const selectedServiceList = orderEntries.filter(item =>
        isAtleastOneParamValid(
            item.entryType === eEntryType.SERVICE_ADDON,
            areAllParamsValid(item.entryType === eEntryType.PRODUCT, !!item.cartEntryReference),
        ),
    )
    const installationAvailable = !!orderEntries.filter((item: CartOrderEntries) => item.hasStoreAutoServiceCenter)
        .length
        ? isServiceEligible(bopis)
            ? YesNoNotApplicable.yes
            : YesNoNotApplicable.no
        : YesNoNotApplicable.notApplicable
    const freeShippingProgressData = cartData?.shippingProgress as FreeShippingProgress
    return {
        cart,
        bopis,
        sth,
        services,
        storeData,
        deliveryAddress,
        selectedServiceList,
        installationAvailable,
        freeShippingProgressData,
        isUpdateQuantity: !!quantity,
    }
}
export default getFilteredCartItems
export { areAllParamsValid, isAtleastOneParamValid, getSth }
