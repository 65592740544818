import { HrefLangLinkData } from './product.interface'
import { AxiosResponse } from 'axios'

/**
 ** Interface for Inventory
 * @interface
 */
export interface Inventory {
    Corporate: {
        Quantity: number
        MinOrderQty: number
        MinETA: string
        MaxETA: string
        NextOrderCutOffDate: string
    }
    IsOnline: {
        Sellable: boolean
        Orderable: boolean
    }
    Quantity: number
    sku: string
    Store: string
    isLimited?: boolean
}

export interface OpeningHours {
    code: string
    name: string
    specialDayOpeningList: [
        {
            closed?: boolean
            closingTime: StoreTime
            comment: string
            date: string
            formattedDate: string
            name: string
            openingTime: StoreTime
        },
    ]
    weekDayOpeningList: WeekDayOpeningList[]
}

export interface StoreService {
    name: string
    id: string
    phone: number
    additionalCopy?: string
    specialDayOpeningList: SpecialOpeningDayList[]
    weekDayOpeningList: WeekDayOpeningList[]
}

export interface StoreServiceWithStoreTimezone extends StoreService {
    storeTimezone?: string
}

/**
 ** Interface for SpecialOpeningDayList
 * @interface
 */

export interface SpecialOpeningDayList {
    closed?: boolean
    closingTime: StoreTime
    comment: string
    date: string
    formattedDate: string
    name: string
    openingTime: StoreTime
}

export interface WeekDayOpeningList {
    closed?: boolean
    closingTime: StoreTime
    openingTime: StoreTime
    storeArea?: string
    weekDay: string
    closesIn?: number
}

export interface StoreImage {
    altText: string
    code: string
    damCreateDate: string
    damPath: string
    description: string
    format: string
    galleryIndex: number
    imageSpinIndicator: boolean
    imageType: string
    isListingThumbnailImage: boolean
    isPrimary: boolean
    mediaType: string
    name: string
    order: string
    url: string
    videoImageThumbnailDAMPath: string
    videoImageThumbnailURL: string
}

export interface StoreTime {
    formattedHour: string
    hour: number
    minute: number
}

export interface StoreAddress {
    province: string
    cellphone: string
    city: StoreLocation
    province?: string
    cityDistrict: StoreLocation
    companyName: string
    country: StoreLocation
    defaultAddress: boolean
    district: string
    email: string
    formattedAddress: string
    id: string
    line1: string
    line2: string
    phone: string
    postalCode: string
    region: StoreLocation
    shippingAddress: boolean
    town: string
    visibleInAddressBook: boolean
    lastName: string
    firstName: string
    fax: string
}

export interface StoreLocation {
    isocode: string
    name: string
    countryIso?: string
    isocodeShort?: string
}

export interface FetchStoreRequest {
    storeId: string
}

export interface StoreResponse {
    storeDetails: StoreWithAvailability
    nearbyStoreList: StoreWithAvailability[]
    nearByStoreListForSelectedSku: StoreWithAvailability[]
    nearByStoreListForPostalCode: StoreWithAvailability[]
    nearByStoreListForAStore: StoreWithAvailability[]
    storeListForSTH: StoreWithAvailability[]
    selectedStoreName: string
    isStoreNameChangedSuccess: boolean
    storeFilters: Filter[]
    selectedPreferredStoreId: string
    preferredStoreDetails: StoreWithAvailability
    storeChangeSource?: string
    currentUserLocation: StoreGeoPoint
    nearByStoreListForStoreLocator: StoreWithAvailability[]
    StoreErrorResponse: AxiosResponse<StoreResponseErrorData>
    isNearbyStoreListApiDone: boolean
    isNearbyStoreListSuccess: boolean
    isStoreDetailsFetched?: boolean
    storeAPITriangulationFail: boolean
    isGeoLocationAllowed: boolean
}

export type StoreResponseErrorData = {
    message?: string
    statusCode?: string
    error?: string
    requestId?: string
    errCode?: string
    errorCode?: string
    correlationId?: string
}

export interface ItemAvailability {
    Corporate: {
        Quantity: number
        MinOrderQty: number
        bopisETA: {
            MinETA: string
            MaxETA: string
        }
        sthETA: {
            MinETA: string
            MaxETA: string
        }
    }
    quantity: number
    storeShelfLocation: string | null
}

export interface StoreWithAvailability {
    seoTag: string
    type: string
    name: string
    id: number | string
    url: string
    hreflangLinkData: HrefLangLinkData[]
    address: StoreAddress
    baseStoreId: string
    description: string
    displayName: string
    inventory?: Inventory[]
    openingHours: OpeningHours
    // updated from 92-c confluence page
    isFreeStorePickup: boolean
    inStorePickUp: boolean
    isExpressDeliveryEligible: boolean
    expressDeliveryPriceThreshold?: number
    storeEligibility: boolean
    isEligibleForCurbside: boolean
    estimatedDeliveryDateEnabled: boolean
    curbPickupInfo: string
    isCurbside: boolean
    geoPoint: StoreGeoPoint
    onlineOrdering: boolean
    fulfillment: {
        storePickUp: {
            enabled: boolean
            source: StoreWithAvailability | null
            availability: ItemAvailability
            etaEarliest: string
            etaLatest: string
        }
        shipToHome: {
            enabled: boolean
            quantity: number
            etaEarliest: string
            etaLatest: string
        }
    }
    distanceKm: number
    storeServices?: StoreService[]
    services: string[]
    formattedDistance: string
    mapIcon: StoreImage
    storeContent: string
    storeImages: StoreImage[]
    isSelected?: boolean
    isLimited?: boolean
    curbsideFee: number | null
    threshold: number
    isAOAFlowEnabled?: boolean
    sleConfigurationName?: string
    fee?: number
    storeTimezone?: string
    bopisEligibility?: boolean
}

export interface NearbyStoreList {
    stores: StoreWithAvailability[]
}

/**
 * Interface for fetch nearby stores api request payload
 * @interface
 */
export interface storeListRequestType {
    postalCode?: string
    maxCount: number
    radius: number
    latitude?: number
    longitude?: number
    type?: string
    sku?: string
    location?: string
    showNotInStock?: boolean
}

export interface Filter {
    id: string
    label: string
}

export interface StoresFilters {
    filters: Filter[]
}

export interface StoreFiltersErrorDTO {
    data: string
    status: number
}
export interface StoreGeoPoint {
    latitude: number
    longitude: number
}

export enum NearByOptions {
    SKU = 'SKU',
    POSTALCODE = 'POSTALCODE',
    STORELOCATOR = 'STORELOCATOR',
}
