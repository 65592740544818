import { createAction } from 'typesafe-actions'
import { HttpResponseDropdownHelper } from '../../global.type'
import { PreselectedVehicleValues } from '../models/preselectedVehicle.interface'
import {
    SET_IS_ADDITIONAL_FETCH_NEEDED,
    SET_IS_PENDING_DICTIONARY_FOR_PRESELECTION,
    SET_INITIAL_DROP_DOWNS_FOR_PRESELECTED_MAKE_ONLY,
    SET_PRESELECTED_DROP_DOWNS_VALUES,
    CLEAR_PRESELECTED_DROP_DOWNS_VALUES,
} from '../types/preselectedVehicle/preselectedVehicle.actionTypes.constant'

export const setIsPendingDictionaryForPreselection = createAction(
    SET_IS_PENDING_DICTIONARY_FOR_PRESELECTION,
    (payload: boolean) => payload,
)<boolean>()

export const setIsAdditionalFetchNeeded = createAction(
    SET_IS_ADDITIONAL_FETCH_NEEDED,
    (payload: boolean) => payload,
)<boolean>()

export const setPreselectedVehicleValues = createAction(
    SET_PRESELECTED_DROP_DOWNS_VALUES,
    (payload: PreselectedVehicleValues) => payload,
)<Partial<PreselectedVehicleValues>>()

export const setInitialDropDownsForPreselectedMakeOnly = createAction(
    SET_INITIAL_DROP_DOWNS_FOR_PRESELECTED_MAKE_ONLY,
    (payload: HttpResponseDropdownHelper) => payload,
)<HttpResponseDropdownHelper>()

export const clearPreselectedVehicleValues = createAction(CLEAR_PRESELECTED_DROP_DOWNS_VALUES)<void>()
