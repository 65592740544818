import { ShareCartTemplate } from '../../redux/models/sharedCart.interface'
import { getCartAnalytics } from './index'
/**
 * Fire analytics for merge cart event
 * @param {ShareCartTemplate} sharedCartTemplate
 * @param {string[]} sharedCartEntryCodes
 */
export const fireAnalyticsForSharedCartMerge = (
    sharedCartTemplate: ShareCartTemplate,
    sharedCartEntryCodes: string[],
): void => {
    const cartAnalytics = getCartAnalytics('')
    const sharedCartMergedSkus = sharedCartEntryCodes
        .map(code => {
            return sharedCartTemplate.entries.find(item => item.entryCode === code)?.sku
        })
        .join('-')

    cartAnalytics.fireMergeSharedCartAnalytics(sharedCartTemplate.code, sharedCartMergedSkus)
}

export default fireAnalyticsForSharedCartMerge
