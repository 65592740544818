import { magicNumber } from './magicNumber'

/**
 * Returns the value to two decimals
 * @param {number} num
 * @return {number}
 */

export const roundOfToTwoDecimals = (num: number): number => {
    return Math.round(Number(num) * magicNumber.HUNDRED) / magicNumber.HUNDRED
}
