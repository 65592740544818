import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { StoreDetailsModalProps } from './StoreDetailsModal.type'
import { StoreWithAvailability } from '../StoreSelectorModal/StoreSelectorModal.type'

/**
 * Renders map selected store
 * @param {StoreDetailsModalProps} props
 * @return {JSX.Element} returns detail of store selected on map pin point marker
 */
const StoreDetailsModal: React.FC<StoreDetailsModalProps> = props => {
    const { renderMaxNumberOfStores, storeList, markerClickHandler } = props
    const componentClassName = `${PREFIX}-store-details-modal`
    const { selectedIndex } = markerClickHandler
    const selectedStore = storeList.find((store: StoreWithAvailability) => markerClickHandler?.id === store?.id)
    return <div className={`${componentClassName}`}>{renderMaxNumberOfStores(selectedStore, selectedIndex)}</div>
}

StoreDetailsModal.propTypes = {
    markerClickHandler: PropTypes.any,
    renderMaxNumberOfStores: PropTypes.func,
    storeList: PropTypes.any,
}

export default StoreDetailsModal
