import { Dispatch } from 'redux'
import { CheckoutService } from '../../services/checkoutService'
import { orderService } from '../../services/orderConfirmation/orderService'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { setShowSpinner } from '../actionCreators'
import {
    orderConfirmationSuccess,
    orderConfirmationFailure,
    toggleNotificationSubscription,
    setOrderNotificationError,
    placeOrderConfirmationSuccess,
    placeOrderConfirmationFailure,
} from '../actionCreators/orderConfirmation.actionCreators'
import { CartResponseErrorDTO, FilteredCartData } from '../models/cart.interface'
import { fillCheckoutDataFromAPI } from './cart.action'
import { AxiosError, AxiosResponse } from 'axios'
import { IFeatureFlag } from '../models/commonContent.interface'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'

const CURRENT_ROLE = 'current'
const ANONYMOUS_ROLE = 'anonymous'

export const getOrderConfirmationDetails =
    (orderId: string) =>
    (dispatch: Dispatch): void => {
        orderService
            .getOrderConfirmation(orderId)
            .then((response: AxiosResponse<FilteredCartData>) => {
                replaceEmptyImagesWithDefault(response?.data?.order?.entries, 'images')
                const orderData = getFilteredCartItems(response.data.order)
                dispatch(orderConfirmationSuccess(orderData))
                fillCheckoutDataFromAPI(orderData.cart, dispatch)
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(orderConfirmationFailure(err?.response))
            })
    }

export const toggleOrderNotificationSubscription =
    (isSignedInUser: string, orderCode: string, phone: string, subscriptionFlag: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        const userRole = isSignedInUser ? CURRENT_ROLE : ANONYMOUS_ROLE
        return orderService
            .toggleOrderNotificationSubscription(userRole, orderCode, phone, subscriptionFlag)
            .then(response => {
                dispatch(toggleNotificationSubscription(response.data))
                dispatch(setOrderNotificationError(false))
            })
            .catch(() => {
                dispatch(setOrderNotificationError(true))
            })
    }

export const placeOrderFromConfirmationPage =
    (cartId: string, isNewCard: boolean, showSpinner = false, fraudFeatureFlag: IFeatureFlag) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        const fraudSessionID = CheckoutService.getFraudSessionID(fraudFeatureFlag)
        CheckoutService.placeOrder(cartId, false, isNewCard, fraudSessionID)
            .then((entryGroups: AxiosResponse<FilteredCartData>) => {
                replaceEmptyImagesWithDefault(entryGroups?.data?.order?.entries, 'images')
                const orderData = getFilteredCartItems(entryGroups.data.order)
                dispatch(placeOrderConfirmationSuccess(orderData))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(placeOrderConfirmationFailure(err?.response))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }
