import React from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import Tabs from '../Tabs'
import Checkbox from '../Checkbox'
import { QcTabProps, IQcTabTypes, IQcCheckboxTabType } from './QcTabProps.type'
/**
 * QcLanguageTab component
 * @return {JSX.Element} returns Tabs component for language toogle specific to Quebec users
 */
const QcLanguageTab: React.FC<QcTabProps> = ({ ...props }): JSX.Element => {
    const { tabContent, componentClassName, viewReverse = false } = props

    const genericClass = `${PREFIX}-xs`

    /**
     * Function to render Checkbox component
     * @param {IQcCheckboxTabType} checkbox
     * @return {JSX.Element} returns checkbox component
     */
    const renderCheckbox = (checkbox: IQcCheckboxTabType): JSX.Element => {
        const { label, checked, ariaLabel, value, handleCheckbox } = checkbox
        return (
            <div className={`${PREFIX}-${componentClassName}__checkbox`}>
                <Checkbox
                    id={checkbox.id}
                    label={label}
                    value={value}
                    checked={checked}
                    ariaLabel={ariaLabel}
                    onChange={handleCheckbox}
                    error={checkbox.sendError}
                />
            </div>
        )
    }
    const renderTabs = (): JSX.Element[] => {
        return tabContent.map((tab: IQcTabTypes, i: number) => (
            <section {...tab?.tabLabel} key={i}>
                <div
                    className={`${
                        tab?.tncContent &&
                        tab?.checkbox &&
                        `${genericClass}-flex ${genericClass}-${viewReverse ? 'column-reverse' : 'col-row'}`
                    }`}>
                    {tab.tncContent}
                    {tab?.checkbox && renderCheckbox(tab.checkbox)}
                </div>
            </section>
        ))
    }

    return <Tabs>{renderTabs()}</Tabs>
}
QcLanguageTab.propTypes = {
    tabContent: PropTypes.array,
    componentClassName: PropTypes.string,
}
export default QcLanguageTab
