import { getType, ActionType } from 'typesafe-actions'
import * as actions from '../actionCreators/popupsToggleState.actionCreators'
import {
    setSideNavigationPopupToggleState,
    setStoreLocatorPopupToggleState,
} from '../actionCreators/popupsToggleState.actionCreators'
import { RootState } from './index'

type Action = ActionType<typeof actions>

export interface popupsToggleState {
    sideNavigationIsOpen: boolean
    storeLocatorIsOpen: boolean
}

export const POPUPS_TOGGLE_STATE_KEY = 'popupsToggleState'

export const initialState: popupsToggleState = {
    sideNavigationIsOpen: false,
    storeLocatorIsOpen: false,
}

export const popupsToggleStateReducer = (
    state: popupsToggleState = initialState,
    action: Action,
): popupsToggleState => {
    switch (action.type) {
        case getType(setSideNavigationPopupToggleState): {
            return { ...state, sideNavigationIsOpen: action.payload }
        }
        case getType(setStoreLocatorPopupToggleState): {
            return { ...state, storeLocatorIsOpen: action.payload }
        }
        default:
            return state
    }
}

export const getStoreLocatorIsOpen = (state: RootState): boolean => state[POPUPS_TOGGLE_STATE_KEY].storeLocatorIsOpen
export const getSideNavigationIsOpen = (state: RootState): boolean =>
    state[POPUPS_TOGGLE_STATE_KEY].sideNavigationIsOpen
