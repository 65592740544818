import React from 'react'
import PropTypes from 'prop-types'

import { AutoProductCartItemProps } from '../ProductCartItem/ProductItemInfo.type'
import { isArrayNotEmpty } from '../../utils'
import Accordion from '../Accordion/Accordion'
import AutomotiveAddonsList from './AutomotiveAddonsList'

const AutomotiveAddonsListWrapper = (props: AutoProductCartItemProps): JSX.Element => {
    return (
        isArrayNotEmpty(props.addons) && (
            <Accordion
                title={props.hardwareTitle}
                isHeaderHidden={false}
                hideControls={false}
                disableOnClick={false}
                isHeaderOpen={props.isCollapsed}>
                <AutomotiveAddonsList
                    {...props}
                    addons={props.addons}
                    hideAccordion={props.setHideAccordionAfterRemove}
                />
            </Accordion>
        )
    )
}

AutomotiveAddonsListWrapper.propTypes = {
    addons: PropTypes.array,
    hardwareTitle: PropTypes.string,
    isCollapsed: PropTypes.bool,
    setHideAccordionAfterRemove: PropTypes.func,
}

export default AutomotiveAddonsListWrapper
