import { Dispatch } from 'redux'

import { TOGGLE_TO } from '../types/products/buttonToggle.actionTypes.constant'
import { fetchToggleAction } from '../actionCreators/buttonToggle.actionCreators'

export const fetchToggleData =
    (requestPayload: string) =>
    (dispatch: Dispatch<{ type: typeof TOGGLE_TO; payload: string }>): void => {
        dispatch(fetchToggleAction(requestPayload))
    }
