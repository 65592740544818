import React from 'react'
import PropTypes from 'prop-types'

import { CarouselArrowProps } from './Carousel.type'
import Icon from '../Icon'

/**
 * Carousel component
 * @param {CarouselArrowProps} props
 * @return {JSX.Element} returns Carousel component
 */
const CarouselNavigationArrow: React.FC<CarouselArrowProps> = props => {
    const { className, onClick, type, size, ariaLabel, path } = props

    return (
        <button className={className} onClick={onClick} aria-label={ariaLabel}>
            <Icon type={type} size={size} path={path} />
        </button>
    )
}

CarouselNavigationArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    ariaLabel: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    path: PropTypes.string,
}

export default CarouselNavigationArrow
