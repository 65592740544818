import { checkDataLength } from '@nl/lib'
import { ProductResponseData } from '../../redux/models/product.interface'
import { checkIsAutomotivePage } from '../Vehicles/Vehicle.helper'
import { COMPONENT_MODE, DataObject, PreselectedVehicleValue } from './DynamicVehicleSeoCopyBlock.type'

/**
 * Returns true when component should be rendered
 * @param { ProductResponseData } productData
 * @param { boolean } isFitmentRequired
 * @param { string } headline
 * @param { string } text
 * @return { boolean }
 */
export const hasBeenShown = (
    productData: ProductResponseData | undefined,
    isFitmentRequired: boolean,
    headline: string,
    text: string,
): boolean => {
    return checkIsAutomotivePage(productData, isFitmentRequired) && checkDataLength(headline) && checkDataLength(text)
}

/**
 * Function which returns a data object with content to render
 *
 * @param { string } yearMakeModelHeadline
 * @param { string } yearMakeModelText
 * @param { string } makeModelHeadline
 * @param { string } makeModelText
 * @param { string } makeHeadline
 * @param { string } makeText
 * @param { Array } preselectedVehicleValuesMap
 * @return { DataObject }
 */
export const getDataObject = (
    yearMakeModelHeadline: string,
    yearMakeModelText: string,
    makeModelHeadline: string,
    makeModelText: string,
    makeHeadline: string,
    makeText: string,
    preselectedVehicleValuesMap: [],
): DataObject => {
    switch (getComponentMode(preselectedVehicleValuesMap)) {
        case COMPONENT_MODE.YEAR_MAKE_MODEL:
            return createDataObject(yearMakeModelHeadline, yearMakeModelText, preselectedVehicleValuesMap)
        case COMPONENT_MODE.MAKE_MODEL:
            return createDataObject(makeModelHeadline, makeModelText, preselectedVehicleValuesMap)
        case COMPONENT_MODE.MAKE:
            return createDataObject(makeHeadline, makeText, preselectedVehicleValuesMap)
        default:
            return { headline: '', text: '' }
    }
}

/**
 * Function which returns component mode
 * @param { Array } preselectedVehicleValuesMap
 * @return { string }
 */
export const getComponentMode = (preselectedVehicleValuesMap: []): string => {
    return preselectedVehicleValuesMap.reduce(
        (accumulator, item: PreselectedVehicleValue) => (item.value ? accumulator + item.key : accumulator),
        '',
    )
}

/**
 * Function which creates a data object with content to render
 * @param { string } headline
 * @param { string } text
 * @param { Array } preselectedVehicleValuesMap
 * @return { DataObject }
 */
export const createDataObject = (headline: string, text: string, preselectedVehicleValuesMap: []): DataObject => {
    preselectedVehicleValuesMap.forEach((item: PreselectedVehicleValue) => {
        const regex = new RegExp(`\\[${item.key}\\]`, 'g')
        headline = headline.replace(regex, item.value)
        text = text.replace(regex, item.value)
    })
    return { headline: headline, text: text }
}
