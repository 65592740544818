import { RootState } from '../reducers'
import { GlobalAPIProps, IFeatureFlag, IGlobalProps } from '../models/commonContent.interface'
import { CommonContentState } from '../reducers/commonContent.reducer'
import { CategoryBreadCrumb } from '../models/category.interface'

const EMPTY_OBJECT = {}

export const commonContentAvailableSelector = (state: RootState): IGlobalProps =>
    state.commonContent?.commonContentAvailable

export const autoAppointmentSelector = (state: RootState): Record<string, unknown> | undefined =>
    commonContentAvailableSelector(state)?.autoAppointment
// default should be created each time due to call of selector - it will cause an extra call of useEffect that depends on this selectors result
export const commonContentSelector = (state: RootState): CommonContentState => state.commonContent || EMPTY_OBJECT

export const commonAPIContentAvailableSelector = (state: RootState): GlobalAPIProps =>
    state.commonContent.commonAPIContentAvailable

export const automotiveSelector = (state: RootState): Record<string, string> =>
    state.commonContent.commonContentAvailable.automotive

export const browseOnlyModeSelector = (state: RootState): boolean => state.browseOnly?.enable

export const enableMPNSearchPreselectionSelector = (state: RootState): boolean =>
    state.commonContent?.commonContentAvailable?.featureFlag?.enableMPNSearchPreselection || false

export const enableNewVehicleApiEndpointSelector = (state: RootState): boolean =>
    state.commonContent?.commonContentAvailable?.featureFlag?.enableNewVehicleApiEndpoint || false

export const plpBreadcrumbListSelector = (state: RootState): CategoryBreadCrumb[] => state.categoryIdData.breadcrumbList

export const featureFlagSelector = (state: RootState): IFeatureFlag =>
    state.commonContent?.commonContentAvailable?.featureFlag

export const noSearchResultsLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.noSearchResults || ''

export const singleSearchResultLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.singleSearchResult || ''

export const manySeachResultsLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.manySearchResults || ''

export const clearButtonLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.clearLabel || ''

export const jumplistPlaceholderTemplateSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.enterLabel || ''
