export const cardTypes = [
    {
        type: 'loyalty',
        pattern: /(\d{1,4})(\d{1,4})(\d{1,4})/,
        readOnlyValue: '6365',
        placeholder: '0000 0000 0000',
        maxLength: 14,
    }, // Object used for details and validations of Loyalty Card
    {
        type: 'master',
        pattern: /^[0-9]{4}/,
        readOnlyValue: '**** - **** - **** -',
        placeholder: '0000',
        maxLength: 4,
    }, // Object used for details and  validations of Master Card with masking character and placeholder
    {
        type: 'master-link',
        pattern: /^[0-9]{4}/,
        readOnlyValue: null,
        placeholder: null,
        maxLength: 4,
    }, // Object used for details and  validations of Master Card without masking character and placeholder
]

export const eventKeys = {
    enter: 'Enter',
} // Object used for key events
