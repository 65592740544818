import { AxiosPromise, AxiosRequestConfig } from 'axios'

export interface RequestBodyType {
    [key: string]: unknown
}

/**
 * Add external systems' names here
 * @deprecated we are not using external system types.
 */
export type ExternalSystemNameType =
    | 'CERTONA'
    | 'BLOOMREACH'
    | 'AEM_MOCK_JSON'
    | 'CDS_ON_PREM'
    | 'AEM_EXP_FRAG'
    | 'BLOOMREACH_SEARCH'
    | 'CANADA_POST'
    | 'AEM_COMMON_API'
    | 'CDS_EXP_CART'
    | 'CDS_PREFERENCE_ODP'
    | 'CDS_CLOUD'

export interface HttpReqHeaders {
    authorization?: string
    'x-web-host'?: string
    'app-client'?: string
    'X-XSRF-TOKEN'?: string
    'Content-Type'?: string
    lang?: string
    includeContent?: boolean
    baseSiteId?: string
    'Ocp-Apim-Subscription-Key'?: string
    'X-BR-UID'?: string
    'X-BR-REF-URL'?: string
}

export type HttpClient = {
    apiGet: () => AxiosPromise
    apiPost: () => AxiosPromise
    apiPut: () => AxiosPromise
    apiDelete: () => AxiosPromise
}

export enum HttpRequestState {
    notStarted = 'notStarted',
    inProgress = 'inProgress',
    done = 'done',
    failed = 'failed',
}

type AxiosInterceptorConfig = {
    baseURL: string
} & AxiosRequestConfig

export type InterceptorConfig = {
    config: AxiosInterceptorConfig
    resolveFunc: (response: any) => void
    rejectFunc: (error: any) => void
}
