import { ActionType, getType } from 'typesafe-actions'
import { vehicleSelectorTabsAction } from '../actionCreators/automotiveBuyBox.actionCreators'

type Action = ActionType<typeof vehicleSelectorTabsAction>

export interface VehicleSelectorTabs {
    needToRenderTabs: boolean
    selectedTabLabel: string
}

export interface AutomotiveBuyBoxState {
    vehicleSelectorTabs: VehicleSelectorTabs
}

export const initialState: AutomotiveBuyBoxState = {
    vehicleSelectorTabs: {
        needToRenderTabs: false,
        selectedTabLabel: '',
    },
}

export const automotiveBuyBoxReducer = (
    state: AutomotiveBuyBoxState = initialState,
    action: Action,
): AutomotiveBuyBoxState => {
    if (action.type === getType(vehicleSelectorTabsAction)) {
        return { ...state, vehicleSelectorTabs: action.payload }
    } else {
        return state
    }
}
