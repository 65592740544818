import { isArrayNotEmpty } from '@nl/lib'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { CartOrderEntries } from '../../redux/models/cart.interface'
import { isProductTire } from '../../utils/automotiveProducts'
import { checkNotNullAndUndefined, checkValueOrNull } from '../../utils/checkNotNullAndUndefined'
import { getDaysDifference } from '../../utils/getDaysDifference.utils'
import { MagicNumber } from '../analytics.type'
import { ProductHelper } from '../helpers/product.helper'
import { types } from '../providers'
import { Availability, DeliveryMethod, YesNoNotApplicable } from '../providers/provider.type'
import { checkIsGuaranteedFitment } from '../../utils/checkIsGuaranteedFitment'

/**
 * Analytics product record class
 * TODO: need to re-use cart.interface.ts
 */
export class ProductRecord implements types.ProductRecordInterface {
    // TODO: This variable declaration looks repeated
    availability?: types.Availability
    isBulk?: boolean
    guaranteedFitment?: string
    fulfillmentOptionAvailable?: types.FulfillmentOptionAvailable
    productStatus?: string
    badges?: [string]
    brand?: string
    fulfillmentStatus?: string
    category?: string
    level1?: string
    level2?: string
    level3?: string
    level4?: string
    level5?: string
    fulfillment?: DeliveryMethod
    fitted?: string
    package?: string
    fulfillmentDaysAway?: string
    fee?: string
    someoneElsePickingUp?: boolean
    pickupEmailProvided?: boolean
    name?: string
    code?: string
    price?: number | null
    rebate?: string
    quantity?: number
    rating?: string
    ror?: boolean
    shipFrom?: string
    list?: string
    sku?: string
    variant1?: string
    variant2?: string
    variant3?: string
    vehicle?: VehicleInfo
    baseProduct?: string
    /**
     * Convert class object to plan JS object
     * @return {ProductRecord}
     */
    toPlainObject(): ProductRecord {
        const plainObject = {
            availability: this.availability,
            bulk: this.processValues(this.isBulk),
            fulfillmentOptionAvailable: this.fulfillmentOptionAvailable,
            guaranteedFitment:
                this.guaranteedFitment === null ? YesNoNotApplicable.notApplicable : this.guaranteedFitment,
            corporateStatus: this.productStatus,
            badge: this.badges,
            brand: this.brand,
            fulfillmentStatus: this.fulfillmentStatus,
            category: this.category,
            level1: this.level1,
            level2: this.level2,
            level3: this.level3,
            level4: this.level4,
            level5: this.level5,
            fulfillment: this.fulfillment,
            fitted: this.processValues(this.fitted),
            package: this.processValues(this.isPackagedItem),
            fulfillmentDaysAway: this.fulfillmentDaysAway,
            fee: this.fee,
            someoneElsePickingUp: this.processValues(!!this.someoneElsePickingUp),
            pickupEmailProvided: this.processValues(!!this.pickupEmailProvided),
            name: this.name,
            pcode: this.baseProduct,
            price: this.price,
            rebate: checkValueOrNull(this.rebate),
            quantity: this.quantity,
            rating: this.processValues(this.rating),
            shipFrom: this.shipFrom,
            sku: this.sku,
            variant1: this.variant1,
            variant2: this.variant2,
            variant3: this.variant3,
            vehicle: this.vehicle,
            list: this.list,
            ror: this.processValues(this.ror),
        }
        return plainObject as ProductRecord
    }
    /**
     * function to return processed value for analytics object
     * @param {unknown}value
     * @return {unknown}
     */
    processValues(value: Record<string, unknown>): unknown {
        return checkNotNullAndUndefined(value) ? undefined : value.toString()
    }
    /**
     * update product with CartOrderEntries
     * @param  {CartOrderEntries} cartOrderEntry
     * @param {unknown} props - AEM + Analytics props.
     */
    processCardOrderEntry(cartOrderEntry: CartOrderEntries, props?: Record<string, string>): void {
        // TODO: heck need to fix with proper assignment

        const { isWishlist } = props || {}
        const errorObject = ProductHelper.getInlineFulfillmentObject(cartOrderEntry, props)
        const isFitted = (cartOrderEntry?.vehicleInformation && cartOrderEntry?.fitmentTypeCode) !== null

        Object.assign(this, cartOrderEntry)
        const feeMessage = isArrayNotEmpty(cartOrderEntry?.feeMessages) ? cartOrderEntry?.feeMessages?.[0] : {}
        this.list = isWishlist ? analyticsAttributes?.products?.wishlist : ''
        this.availability = ProductHelper.transformAvailability(cartOrderEntry) as Availability
        this.brand = cartOrderEntry?.brand?.label
        this.name = (cartOrderEntry as unknown as { name: { label: string } })?.name?.label || cartOrderEntry?.name
        this.price = ProductHelper.transformPrice(cartOrderEntry)
        this.fee = ProductHelper.transformFee(feeMessage)
        this.fulfillment = ProductHelper.transformFulfillment(cartOrderEntry, true)
        this.fulfillmentStatus = errorObject.showInlineError
            ? errorObject.errorMessage
            : analyticsAttributes?.eventParameters?.labels?.available
        this.fitted = isFitted
        this.guaranteedFitment = checkIsGuaranteedFitment(cartOrderEntry)
        this.rebate = ProductHelper.transformRebate(cartOrderEntry?.rebate)
        this.productStatus = cartOrderEntry?.productStatus?.toLowerCase()
        this.category = cartOrderEntry?.merchCategories?.join('/')
        const [level1, level2, level3, level4, level5] = cartOrderEntry?.merchCategories || []
        this.level1 = level1
        this.level2 = level2
        this.level3 = level3
        this.level4 = level4
        this.level5 = level5
        const { variant1, variant2, variant3 } = ProductHelper.transFormVariantData(cartOrderEntry)
        this.variant1 = variant1
        this.variant2 = variant2
        this.variant3 = variant3
        this.vehicle = ProductHelper.transformVehicle(cartOrderEntry)
        this.sku = cartOrderEntry?.code
        this.fulfillmentOptionAvailable = ProductHelper.transformFulfillment(cartOrderEntry)
        this.fulfillmentDaysAway = getDaysDifference(cartOrderEntry?.fulfillment?.sourceTypes?.[0]?.etaEarliest)
        this.rating = cartOrderEntry?.Rating
        this.ror = isProductTire(cartOrderEntry)
    }

    /**
     * update product with CartOrderEntries
     * @param  {CartOrderEntries} cartOrderEntry
     * @param {string} deliveryMethod
     * @param {boolean} someoneElsePickingUp
     * @param {boolean} pickupEmailProvided
     */
    static processBopisOrderEntry(
        cartOrderEntry: CartOrderEntries,
        deliveryMethod: string,
        someoneElsePickingUp: boolean,
        pickupEmailProvided: boolean,
    ): void {
        cartOrderEntry.fulfillment = deliveryMethod
        cartOrderEntry.someoneElsePickingUp = someoneElsePickingUp
        cartOrderEntry.pickupEmailProvided = pickupEmailProvided
    }

    /**
     * Create product record list from provided cartOrder Entries
     * @param  {CartOrderEntries[]} cartOrderEntries
     * @param {unknown} props - AEM Props + analytics props
     * @param {ProductRecord[]} list will be passed in recursive call to retain previous records.
     * @return {ProductRecord[]}
     */
    static processCardOrderEntries(
        cartOrderEntries: CartOrderEntries[],
        props?: Record<string, string>,
        list?: ProductRecord[],
    ): ProductRecord[] {
        const productList: ProductRecord[] = list || []
        cartOrderEntries?.forEach(product => {
            if (ProductRecord.isNeedToSplit(product)) {
                ProductRecord.processCardOrderEntries(ProductRecord.split(product), props, productList)
            } else {
                const productRecord = new ProductRecord()
                productRecord.processCardOrderEntry(product, props)
                productList.push(productRecord)
            }
        })
        return productList
    }

    /**
     * This function check is product need to split based on analytics requirement.
     * @param  {CartOrderEntries} product
     * @return {boolean}
     */
    static isNeedToSplit(product: CartOrderEntries): boolean {
        return product?.deliveryDates?.length > MagicNumber.ONE
    }

    /**
     * Split product based on multiple delivery dates and fulfillment
     * @param  {CartOrderEntries} product
     * @return {CartOrderEntries[]}
     */
    static split(product: CartOrderEntries): CartOrderEntries[] {
        const cartOrderEntries: CartOrderEntries[] = []
        product.deliveryDates?.forEach(deliveryDate => {
            const p1 = JSON.parse(JSON.stringify(product)) as CartOrderEntries
            p1.deliveryDates = [deliveryDate]
            cartOrderEntries.push(p1)
        })
        return cartOrderEntries
    }
}

export interface VehicleInfo {
    staggerdFitment?: string
    type?: string
    make?: string
    model?: string
    year?: string
    body?: string
    option?: string
}
