import { TireWheelFitmentNote } from '../globalConstants'

/**
 * function to check whether it is staggered front specification
 * @param {string} fitmentNote
 * @return {boolean}
 */
export const isStaggeredFront = (fitmentNote: string): boolean => {
    return fitmentNote === TireWheelFitmentNote.OEfront || fitmentNote === TireWheelFitmentNote.OIfront
}

/**
 * function to check whether it is staggered rear specification
 * @param {string} fitmentNote
 * @return {boolean}
 */
export const isStaggeredRear = (fitmentNote: string): boolean => {
    return fitmentNote === TireWheelFitmentNote.OErear || fitmentNote === TireWheelFitmentNote.OIrear
}
