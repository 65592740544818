import { ActionType, getType } from 'typesafe-actions'

import { resetCriticalFitmentDetails, setCriticalFitmentDetails } from '../actionCreators'
import { CriticalFitment } from '@nl/lib'

type Action = ActionType<typeof setCriticalFitmentDetails>

export interface CriticalFitmentState {
    criticalFitmentData: CriticalFitment[] | null
}

export const initialState: CriticalFitmentState = {
    criticalFitmentData: null,
}

export const fetchCriticalFitmentDataReducer = (state = initialState, action: Action): CriticalFitmentState => {
    if (action.type === getType(setCriticalFitmentDetails)) {
        return { ...state, criticalFitmentData: action.payload }
    } else if (action.type === getType(resetCriticalFitmentDetails)) {
        return { ...state, criticalFitmentData: null }
    } else {
        return state
    }
}
