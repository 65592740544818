import appCacheService from '../../utils/appCacheService'
import { isCheckoutPage, isShoppingCartPage } from '../../utils/checkPageType'
import { DrawerLevel } from './Checkout.type'
import { HttpRequestState } from '../../httpClient/client.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { CartAnalytics } from '../../analytics/components/cartAnalytics'
import { CartPaymentResponse, FilteredCartData } from '../../redux/models/cart.interface'
import { checkDataLength, getFormattedPriceWithLocale, isArrayNotEmpty, replaceStrWithDynamicVal } from '@nl/lib'
import { GiftCardInterface } from '@nl/lib/src/components/GiftCard/GiftCard.types'
import { paymentInformationConst } from './PaymentInformation/cardTypeConstants'
import { PlaceOrderErrorCode } from '../../components/Checkout/Checkout.constant'

/**
 * function to check if shipping drawer to be have edit mode or preview
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @return {boolean}
 */

export const shippingEditCheck = (level: number, drawerState: DrawerLevel): boolean => {
    return drawerState?.previous >= level ? drawerState?.edit === level : true
}

/**
 * function to check if pickup drawer to be activated
 *  @param {boolean} hasSTH
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @return {boolean}
 */
export const pickupInactiveCheck = (hasSTH: boolean, level: number, drawerState: DrawerLevel): boolean => {
    return (
        (hasSTH ? drawerState?.previous < level : drawerState?.previous < MagicNumber.TWO) && drawerState.edit !== level
    )
}

/**
 * function to check if pickup drawer to be have edit mode or preview
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @return {boolean}
 */
export const pickupEditCheck = (level: number, drawerState: DrawerLevel): boolean => {
    return drawerState?.previous >= level ? drawerState?.edit === level : true
}

/**
 * function to check if payment drawer to be activated
 * @param {boolean} hasBOPIS
 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @return {boolean}
 */
export const paymentInactiveCheck = (hasBOPIS: boolean, level: number, drawerState: DrawerLevel): boolean => {
    return (
        (hasBOPIS ? drawerState?.previous < level : drawerState?.previous < MagicNumber.THREE) &&
        drawerState.edit !== level
    )
}

/**
 * function to check if payment drawer to be edit mode or preview mode

 * @param {number} level
 * @param {DrawerLevel} drawerState
 * @return {boolean}
 */
export const paymentEditCheck = (level: number, drawerState: DrawerLevel): boolean => {
    return drawerState?.previous >= level ? drawerState?.edit !== level : false
}

/**
 * function to get selected delivery option
 * @param {any} deliveryOptions
 * @return {string}
 */
export const getSelectedDeliveryOption = (deliveryOptions: Record<string, unknown>[]) => {
    return deliveryOptions.find(deliveryOption => deliveryOption.isSelected as boolean)?.deliveryOptionType as string
}

export const isCartSuccessfullyFetched = (
    cartXhrInfo,
    profileXhrInfo,
    isStoreSharedCart: boolean,
    isAnalyticsSent: boolean,
    email: string,
    phone: string,
): boolean =>
    isCartSuccessfullyFetchedWithoutPhoneEmail(cartXhrInfo, profileXhrInfo, isStoreSharedCart, isAnalyticsSent) &&
    email &&
    phone

export const isCartSuccessfullyFetchedWithoutPhoneEmail = (
    cartXhrInfo: Record<string, unknown>,
    profileXhrInfo: Record<string, unknown>,
    isStoreSharedCart: boolean,
    isAnalyticsSent: boolean,
): boolean =>
    (cartXhrInfo?.getCartInfo === HttpRequestState.done || isStoreSharedCart) &&
    profileXhrInfo?.userProfile === HttpRequestState.done &&
    !isAnalyticsSent

/**
 *
 * @param {number}dirtyDrawerLevel
 * @param {number}drawerLevel
 * @return {boolean}
 */
export const checkIfModalTrigger = (dirtyDrawerLevel: number, drawerLevel: number): boolean => {
    return dirtyDrawerLevel === drawerLevel || !dirtyDrawerLevel
}

/**
 *
 * @param {number}drawerLevel
 * @return {number}
 */
export const drawerToAnalyticsStep = (drawerLevel: number): number => {
    switch (drawerLevel) {
        case MagicNumber.ONE:
            return MagicNumber.TWO
        case MagicNumber.TWO:
        case MagicNumber.THREE:
            return MagicNumber.THREE
        case MagicNumber.FOUR:
            return drawerLevel
    }
}

/**
 *
 * @param {DrawerLevel}drawerLevelState
 * @param {number}drawerLevel
 * @param {React.Dispatch<React.SetStateAction<DrawerLevel>>}setDrawerLevelState
 * @param {CartAnalytics|undefined } analyticsInstance
 * @param {boolean} isPayment
 */
export const drawerLevelSetter = (
    drawerLevelState: DrawerLevel,
    drawerLevel: number,
    setDrawerLevelState: React.Dispatch<React.SetStateAction<DrawerLevel>>,
    analyticsInstance: CartAnalytics | undefined,
    isPayment = false,
): void => {
    if (drawerLevelState.active === drawerLevel) {
        setDrawerLevelState(prevstate => ({
            ...prevstate,
            edit: prevstate.previous + MagicNumber.ONE,
            active: prevstate.previous + MagicNumber.ONE,
        }))
        if (!isPayment) {
            analyticsInstance?.fireCheckoutContactInfo(
                drawerToAnalyticsStep(drawerLevelState.previous + MagicNumber.ONE),
                undefined,
            )
        }
    } else {
        setDrawerLevelState(prevstate => ({
            ...prevstate,
            edit: prevstate.active,
        }))
        if (!isPayment) {
            analyticsInstance?.fireCheckoutContactInfo(drawerToAnalyticsStep(drawerLevelState.active), undefined)
        }
    }
}

/**
 * Function to check if error is related to Gift Card operations
 *
 * @param {Record<string, string>}error
 * @return {boolean}
 */
export const isGiftCardError = (error: Record<string, string>): boolean => error?.component?.includes('gc')

/**
 * Function to check is checkout opened after redirecting from PayPal
 *
 * @return {boolean}
 */
export const isRedirectedAfterPayPal = (): boolean => {
    const queryParams = window.location.search
    return queryParams.toLowerCase().includes('txn_id') && queryParams.toLowerCase().includes('rspcode=0')
}

/**
 * Function to check is clear tenders needed
 *
 * Note: In case of changing - ODP-Adobe cart call prefetch implementation should be considered
 * apps/ctcweb-core/components/page/headcartcall.html
 *
 * @param {Record<string, string>} cartGUID
 * @param {Record<string, boolean>} isInitialPageLoading
 *
 * @return {boolean}
 */
export const isNeedToClearTenders = (cartGUID: string, isInitialPageLoading = false): boolean => {
    return (
        (appCacheService.isPaypalTendersApplied.get(cartGUID) && isShoppingCartPage()) ||
        (isInitialPageLoading &&
            !isRedirectedAfterPayPal() &&
            appCacheService.isGiftCardWasApplied.get(cartGUID) &&
            (isCheckoutPage() || isShoppingCartPage())) ||
        (!isInitialPageLoading && isCheckoutPage() && isRedirectedAfterPayPal())
    )
}

/**
 * Function to check if CTMoney redemption is applied to cart
 * @param {boolean} isAuthUser
 * @param {CartPaymentResponse} paymentInfo
 * @return {boolean}
 */
export const isRedeemCTMoneyApplied = (isAuthUser: boolean, paymentInfo: CartPaymentResponse): boolean => {
    return isAuthUser && !!paymentInfo?.redeemCTAmount?.value
}

/**
 * Method to get the place order amount
 * @param {GiftCardInterface[] | undefined} giftCardList
 * @param {FilteredCartData} cartItemsData
 * @return {number}
 */
const getPlaceOrderAmount = (
    giftCardList: GiftCardInterface[] | undefined,
    cartItemsData: FilteredCartData,
): number => {
    if (checkDataLength(giftCardList) || checkDataLength(cartItemsData?.cart?.paymentInfo?.giftCards)) {
        return cartItemsData?.cart?.paymentInfo?.remainingTotalCost?.value
    }
    return isArrayNotEmpty(cartItemsData?.cart?.cartSummary?.taxLines)
        ? cartItemsData?.cart?.cartSummary?.totalWithTaxesAmt?.value
        : cartItemsData?.cart?.cartSummary?.totalAmt?.value
}

/**
 * Method to construct place order CTA label content
 * @param {GiftCardInterface[] | undefined} giftCardList
 * @param {FilteredCartData} cartItemsData
 * @param {string} placeOrderCTA
 * @return {string}
 */
export const getPlaceOrderCTA = (
    giftCardList: GiftCardInterface[] | undefined,
    cartItemsData: FilteredCartData,
    placeOrderCTA: string,
): string => {
    return replaceStrWithDynamicVal(
        placeOrderCTA,
        getFormattedPriceWithLocale(getPlaceOrderAmount(giftCardList, cartItemsData)),
    )
}

/** Function to find the button label for review or place order
 * @param {boolean | undefined} mergePlaceOrderAndReviewOrderFeature
 * @param {GiftCardInterface[] | undefined} giftCards
 * @param {FilteredCartData} cartItemsData
 * @param {string} selectedCard
 * @param {string} payPalCTALabel
 * @param {string} placeOrderCTALabel
 * @param {string} reviewOrderCTALabel
 * @return {string}
 */
export const getReviewOrPlaceOrderCTA = (
    mergePlaceOrderAndReviewOrderFeature: boolean | undefined,
    giftCards: GiftCardInterface[] | undefined,
    cartItemsData: FilteredCartData,
    selectedCard: string,
    payPalCTALabel: string,
    placeOrderCTALabel: string,
    reviewOrderCTALabel: string,
): string => {
    return selectedCard === paymentInformationConst.payPal
        ? payPalCTALabel
        : mergePlaceOrderAndReviewOrderFeature
        ? getPlaceOrderCTA(giftCards, cartItemsData, placeOrderCTALabel)
        : reviewOrderCTALabel
}

/** Function to getting Cart GUID
 * @param {string} isStoreSharedCart
 * @param {FilteredCartData} storeInitiatedCart
 * @param {FilteredCartData} cartItemsData
 * @return {string}
 */
export const getCartGUID = (
    isStoreSharedCart: boolean,
    storeInitiatedCart: FilteredCartData,
    cartItemsData: FilteredCartData,
): string => {
    return isStoreSharedCart ? storeInitiatedCart.cart.cartId : cartItemsData.cart.cartId
}

/** Function to getting class of component with modificator no-margin
 * @param {boolean} isExpressDeliveryMode
 * @param {string} componentName
 * @return {string}
 */
export const getNoMarginClass = (isExpressDeliveryMode: boolean, componentName: string): string => {
    return isExpressDeliveryMode ? `${componentName}--no-margin` : ''
}

/** Function to check if initPayment is success with merge place order review order feature
 * @param {boolean} isInitPaymentSuccess
 * @param {boolean} isPayPalPayment
 * @return {boolean}
 */
export const isProceedWithPlaceOrder = (isInitPaymentSuccess: boolean, isPayPalPayment: boolean): boolean => {
    return isPayPalPayment || isInitPaymentSuccess
}

/** Function to check if the error is full page takeover error
 * @param {string} errorCode
 * @return {boolean}
 */
export const isFullPageError = (errorCode: string): boolean => {
    return Object.values(PlaceOrderErrorCode).includes(`error${errorCode}`)
}
