import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import { SuccessResponse, HardwareList, HardwareSkuData } from '../../redux/models/extraHardware.interface'
import { ItemAvailability } from '../../redux/models/product.interface'

const environment = getEnvironment()
const httpClient = getHttpClient()

// TODO: Need to revisit once actual api integration is done to fetch balloon products on cart
// Currently using extra hardwre interfaces need to update once actual api integration is done.

/**
 * Extra Hardware service. To fetch the extra hardware products
 */
class BalloonAddonsService extends BaseService {
    locale = BalloonAddonsService.language
    /**
     * Load extra-hardware
     * @param  {ExtraHardwareParams} params
     * @return {SuccessResponse}
     */
    fetchBalloonAddons(): Promise<{ data: SuccessResponse }> {
        const url = this.createBalloonAddonsUrl()
        return httpClient.apiGet(url, {}, undefined, true)
    }

    /**
     * Create extra-hardware url based on mock or cds
     * @param  {string} _sku
     * @return {URL}
     */
    createBalloonAddonsUrl(): string {
        const {
            API_ENDPOINTS: { balloonAddonsMock },
        } = environment
        return `${environment.API_MOCK_URL}${balloonAddonsMock}.json`
    }

    /**
     * Transform response as BalloonAddons
     * @param  {SuccessResponse} data
     * @param  {string} sku
     * @return {HardwareList}
     */
    transformData(data: SuccessResponse, sku: string): HardwareList[] {
        const balloonAddonsList: HardwareList[] = []

        if (data.results && data.results?.length) {
            data.results.forEach(result => {
                balloonAddonsList.push({
                    serviceType: result.field['type'],
                    maxQuantity: result.quantity,
                    url: result.field['pdp-url'],
                    name: result.field['prod-name'],
                    shortDescription: result.field['features'],
                    sku: result.field['sku-id'],
                    brand: { label: result.field['brand'], url: '' },
                    images: [
                        {
                            altText: result.field['prod-name'],
                            isListingThumbnailImage: 'N',
                            mediaType: 'GALLERY_IMAGE',
                            url: result.field['thumb-img-url']
                                ? result.field['thumb-img-url']
                                : environment.defaultProductImage,
                        },
                    ],
                    automotiveSku: sku,
                    price: null,
                    isAvailable: false,
                })
            })
        }

        return balloonAddonsList
    }

    /**
     * Get SKUs from hardwareList
     * @param  {HardwareList} balloonAddonsList
     * @return {HardwareList}
     */
    getProductSkus(balloonAddonsList: HardwareList[]): HardwareList[] {
        return balloonAddonsList.filter((v, i, a) => a.findIndex(t => t.sku === v.sku) === i)
    }

    /**
     * Validate if the product is available
     * @param  {boolean} active
     * @param  {boolean} sellable
     * @param  {ItemAvailability} availability
     * @return {boolean}
     */
    _isAvailable(active: boolean, sellable: boolean, availability: ItemAvailability): boolean {
        return !!(
            active &&
            sellable &&
            (availability?.quantity > 0 || (availability.Corporate && availability.Corporate?.Quantity > 0))
        )
    }

    /**
     * Set Price and Availability
     * @param  {HardwareSkuData} balloonAddonsSkuData
     * @param  {HardwareList} balloonAddonsList
     * @return {HardwareList}
     */
    setPriceAndAvailability(balloonAddonsSkuData: HardwareSkuData, balloonAddonsList: HardwareList[]): HardwareList[] {
        const { code, active, sellable, availability } = balloonAddonsSkuData

        balloonAddonsList.forEach(balloonAddons => {
            if (balloonAddons.sku === code) {
                balloonAddons.price = balloonAddonsSkuData?.currentPrice?.value || 0
                balloonAddons.isAvailable = !!this._isAvailable(active, sellable, availability)
            }
        })

        return balloonAddonsList.filter(balloonAddons => balloonAddons.isAvailable || balloonAddons.sku !== code)
    }
}

const balloonAddonsService = new BalloonAddonsService()

export { balloonAddonsService }
export default balloonAddonsService
