import React, { useEffect } from 'react'

/**
 * This custom react hooks is dedicated to the keydown outside event
 * @param { React.MutableRefObject<Element> } ref
 * @param { function } handler
 * @param {React.MutableRefObject<HTMLElement>} parentRef
 */
export const useOnKeyDownOutside = (
    ref: React.MutableRefObject<Element>,
    handler: (event: KeyboardEvent) => void,
    parentRef?: React.MutableRefObject<HTMLElement>,
): void => {
    useEffect(() => {
        const parentRefNode = parentRef?.current || document
        const listener = (event: KeyboardEvent) => {
            // Do nothing if keydown ref's element or descendent elements
            if (!ref?.current || ref?.current?.contains(event.target as Node)) return
            handler(event)
        }
        parentRefNode.addEventListener('keydown', listener)

        return () => parentRefNode.removeEventListener('keydown', listener)
    }, [ref, handler, parentRef])
}
