/* eslint-disable no-magic-numbers */
import React from 'react'
import { PREFIX } from '../config'
import PropTypes from 'prop-types'
import { CardItemRowProps } from './CardItemRow.type'

/* Component for rendering the content for a Information card in a list fashion using data-list */
export const CardItemRow: React.FC<CardItemRowProps> = ({ ...props }): JSX.Element => {
    const { label, value, enteredField, informationField } = props
    return (
        <dl
            className={`${PREFIX}-row ${PREFIX}-information-card__info-size ${PREFIX}-mt-xs ${PREFIX}-mb-xs`}
            aria-label={enteredField + ' ' + label + ' ' + informationField}>
            <div className={`${PREFIX}-col-xs-6 ${PREFIX}-col-sm-4 ${PREFIX}-col-md-4  `}>
                <dt className={`${PREFIX}-information-card__dt-text-gray`}>{label}</dt>
            </div>
            <div className={`${PREFIX}-col-xs-6 ${PREFIX}-col-sm-8 ${PREFIX}-col-md-8 `}>
                <dd className={`${PREFIX}-information-card__dd-text-black`}>{value}</dd>
            </div>
        </dl>
    )
}

export default CardItemRow

CardItemRow.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    enteredField: PropTypes.string,
    informationField: PropTypes.string,
}
