import React from 'react'

import { PREFIX } from '../config'
import ProductImageComponent from '../ProductCartItem/ProductImageComponent'
import ProductItemInfo from '../ProductCartItem/ProductItemInfo'
import Checkbox from '../Checkbox/Checkbox'
import CommonCard from '../CommonCard/CommonCard'
import { eEntryType, ProductCartItemProps } from '../ProductCartItem/ProductItemInfo.type'

/* MediaGallMergeCardModaleryViewer component
 * @param {ProductCartItemProps} props
 * @return {JSX.Element} returns
 */

const MergeCartModal: React.FC<ProductCartItemProps> = ({ ...props }: ProductCartItemProps): JSX.Element => {
    const {
        productData,
        totalLabel,
        mmQtyLabel,
        getSelectedCartItems,
        index,
        isCheckboxDisabled,
        a11yServiceInstallation,
        saveLabel,
        isSTS,
    } = props

    const { images, name } = productData || {}
    const mergeModalClass = `${PREFIX}-merge-cart-modal`

    const getCheckbox = (): JSX.Element | null => {
        return !isCheckboxDisabled ? (
            <Checkbox
                id={`${index}`}
                label=""
                checked={productData.selected}
                onChange={() => {
                    getSelectedCartItems(!productData.selected, productData)
                }}
                data-testid="merge-cart"
            />
        ) : null
    }

    return (
        <CommonCard isSTS={isSTS}>
            <div className={`${mergeModalClass}_header`}>
                {(productData.entryType === eEntryType.SERVICE_ADDON ||
                    productData.entryType === eEntryType.SERVICE_STANDALONE) &&
                !productData?.productWheelType ? (
                    <ProductImageComponent
                        isService={true}
                        a11yServiceInstallation={a11yServiceInstallation}
                        servicesIcon="ct-tools"
                    />
                ) : (
                    <ProductImageComponent images={images} name={name} />
                )}
                <ProductItemInfo
                    productData={productData}
                    cartModal={true}
                    totalLabel={totalLabel}
                    mmQtyLabel={mmQtyLabel}
                    isUnfinishedPackage={props.isUnfinishedPackage}
                    language={props.language}
                    a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                    singleShippingMethodOption={true}
                    saveLabel={saveLabel}
                    isSTS={isSTS}
                />
                {getCheckbox()}
            </div>
        </CommonCard>
    )
}

export default MergeCartModal
