import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { ReactModalProps } from './ReactModal.types'
import { MODAL_OPEN_CLASS, MODAL_OPEN_CLASS_NOT_SCROLL } from './ReactModal.constant'
import { keyCodes } from '../../utils/keyCodes'
import { disableFocusLock, enableFocusLock } from '../../utils/focusLock'

/**
 * ReactModal component
 * @param {ReactModalProps} props
 * @return {JSX.Element} returns Outer wrapper for Modal component
 */
const ReactModal: React.FC<ReactModalProps> = (props): JSX.Element => {
    const modalHeightClass = props.isHeightFix
        ? `${PREFIX}-react-modal__modal-container--height-fix`
        : `${PREFIX}-react-modal__modal-container--height-not-fix`
    const packageClass = !props.isModalPadding ? '' : `${PREFIX}-react-modal__modal-container--auto-package`
    const { closeHandler } = props
    const closeModalEscRef = useRef(null)
    const modalRef = useRef<HTMLDivElement>(null)
    const focusLockEventRef = useRef<EventListener>()

    useEffect(() => {
        const modalClass = props.notScroll ? MODAL_OPEN_CLASS_NOT_SCROLL : MODAL_OPEN_CLASS
        if (props.isOpen) {
            document.body.classList.add(modalClass)
        } else {
            document.body.classList.remove(modalClass)
        }

        /**
         * Remove body `modalClass` on ReactModal unmount, is necessary for the case
         * when ReactModal unmount happens by not changing `isOpen` prop to `false`.
         */
        return () => {
            document.body.classList.remove(modalClass)
        }
    }, [props.isOpen, props.notScroll])

    const closeModalEsc = useCallback(
        (e: KeyboardEvent): void => {
            if (e.keyCode === keyCodes.esc) {
                closeHandler()
            }
        },
        [closeHandler],
    )

    useEffect(() => {
        if (props.isOpen) {
            /** To handle case when closeHandler is not wrapped in useCllback
             *  and
             *  rendering is adding more than one eventlistener */
            if (closeModalEscRef.current) {
                document.body.removeEventListener('keydown', closeModalEscRef.current)
            }
            document.body.addEventListener('keydown', closeModalEsc)
            closeModalEscRef.current = closeModalEsc
        } else {
            document.body.removeEventListener('keydown', closeModalEscRef.current)
        }
    }, [props.isOpen, closeModalEsc])

    useEffect(() => {
        if (props.isOpen) {
            focusLockEventRef.current = enableFocusLock(modalRef)
        } else {
            disableFocusLock(focusLockEventRef.current)
        }
    }, [props.isOpen])

    return (
        props.isOpen && (
            <div
                onClick={props.closeModalOnOutsideClick ? closeHandler : null}
                role="presentation"
                className={`${PREFIX}-react-modal ${PREFIX}-overlay`}>
                <div
                    ref={modalRef}
                    className={`${PREFIX}-react-modal__modal-container ${modalHeightClass} ${packageClass}`}
                    role={props.role}
                    aria-modal={props.ariaModal}>
                    {props.children}
                </div>
            </div>
        )
    )
}

ReactModal.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    isHeightFix: PropTypes.bool,
    isModalPadding: PropTypes.bool,
    notScroll: PropTypes.bool,
    closeHandler: PropTypes.func,
    role: PropTypes.string,
    ariaModal: PropTypes.bool,
}

export default ReactModal
