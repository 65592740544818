import { AxiosPromise } from 'axios'

import { BaseService } from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { CategoryResponseById } from '../../redux/models/category.interface'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Function to trigger header navigation.
 * @class
 * @extends BaseService
 */
class HeaderNavigationService extends BaseService {
    /**
     * Function to fetch categories.
     * TODO: remove the optional type once actual api is implemented.
     * @param {string} categoryId
     * @param {boolean} scope
     * @return {AxiosPromise}
     */
    static getCategories(categoryId?: string, scope?: string): AxiosPromise<CategoryResponseById> {
        return httpClient.apiGet(this.createCategoryURL(categoryId || '', scope || '').toString())
    }

    /**
     * Create url for the category api.
     * @param {string} categoryId - category
     * @param {string} sitemap - category
     * @return {URL}
     */
    static createCategoryURL(categoryId: string, sitemap: string): URL {
        const locale = HeaderNavigationService.language

        const {
            API_BASE_URL,
            API_ENDPOINTS: { getCategories },
        } = environment
        const categoryUrl = categoryId ? `/${categoryId}` : ''
        const sitemapParam = sitemap ? `&scope=${sitemap}` : ''

        return new URL(`${API_BASE_URL}${getCategories}${categoryUrl}?lang=${locale}${sitemapParam}`)
    }
}

export { HeaderNavigationService }
export default HeaderNavigationService
