/**
 * Return true if arrays are not undefined, have equal length, and each pair of elements are equal.
 * @param {T} array1
 * @param {T} array2
 * @return {boolean}
 */
const arraysElementsAreEqual = <T>(array1?: T[], array2?: T[]): boolean =>
    Boolean(
        array1 &&
            array2 &&
            array1.length === array2.length &&
            array1.every((element, index) => element === array2[index]),
    )

export default arraysElementsAreEqual
