import { Dispatch } from 'react'

import { setToastDetails } from '../../redux/actions'
import { ToastProps } from '../ToastWrapper/ToastWrapper.type'

/**
 *
 * @param {boolean} showToast
 * @param {ToastProps} toastProps - only static constants but not callbacks.
 * @param {string} componentName - Name to identify the toast to extract callbacks.
 * @param {Dispatch<any>} dispatch
 */
export const dispatchToast = (
    showToast: boolean,
    toastProps: ToastProps,
    componentName: string,
    dispatch: Dispatch<any>,
): void => {
    dispatch(
        setToastDetails({
            showToast,
            toastProps,
            componentName,
        }),
    )
}
