import { RootState } from '../reducers'
import { FilteredCartData, RedeemCTMoneyResponse } from '../models/cart.interface'
import { CheckoutState } from '../reducers/checkout.reducer'

export const checkoutSelector = (state: RootState): CheckoutState => state.checkout

export const placeOrderSuccessSelector = (state: RootState): FilteredCartData =>
    checkoutSelector(state).placeOrderSuccess

export const semaFoneEnabledSelector = (state: RootState): boolean | undefined =>
    checkoutSelector(state).semaFoneEnabled

export const redeemCTMoneyResponseSelector = (state: RootState): RedeemCTMoneyResponse | null =>
    checkoutSelector(state).redeemCTMoneyResponse

export const editPaymentClickedSelector = (state: RootState): boolean | undefined =>
    checkoutSelector(state).editPaymentClicked

export const isGiftCardDeletedSelector = (state: RootState): boolean => checkoutSelector(state).isGiftCardDeleted
