// React eco system
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from '../store'

// Dynamic Imports Chunks Loader
import ChunksLoader from '../chunks/loader'
import { Component } from '../global.type'
import { staticComponentMap } from '../globalConstants/authorableComponents.constant'
import { staticFunctionMap } from '../globalConstants/cmsStaticCompInit.constant'

import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'
import { logNewRelic } from '../components/NewRelic/newRelic.helper'

const initReactComponent = (component: Component, Comp: React.FC) => {
    try {
        ReactDOM.render(
            <React.StrictMode>
                <Provider store={store}>
                    <ErrorBoundary name={component.name}>
                        <Comp {...component.props}>{component.element.innerHTML}</Comp>
                    </ErrorBoundary>
                </Provider>
            </React.StrictMode>,
            component.element,
        )
    } catch (error) {
        console.error('failed to initialize react component: ' + component.name)
    }
}

/**
 * Render the authored elements
 * @param {Component} component
 */
export const renderDynamicReactComponent = (component: Component): void => {
    if (ChunksLoader.dynamicImportComponentMap[component.name]) {
        ChunksLoader.dynamicImportComponentMap[component.name]()
            .then((Comp: any) => {
                initReactComponent(component, Comp)
            })
            .catch(err => console.error(err))
    } else {
        const Comp = staticComponentMap[component.name] as React.FC
        if (Comp) initReactComponent(component, Comp)
        // component definition is not found in the static component list, throwing an error on console
        else console.error(`Element name didn't match *** ${component.name} *** `)
    }
}

/**
 * Render static components.
 * @param {Document | HTMLElement} ref
 */
export const renderAEMComponents = (ref: Document | HTMLElement) => {
    Object.keys(staticFunctionMap).forEach((key: string) => {
        const singleStaticComponentInstances = ref.querySelectorAll('.' + key)
        singleStaticComponentInstances.forEach(el => {
            const { method, param } = staticFunctionMap[key]
            try {
                method(el, param)
            } catch (error) {
                if (error instanceof Error) {
                    logNewRelic({
                        error,
                        resource: key,
                        logErrorName: 'renderAEMComponent',
                    })
                }
            }
        })
    })
}
