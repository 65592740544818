import { magicNumber } from './magicNumber'
/**
 * Returns Capitalize String
 * @param {string} - capitalize string
 * @return {string}
 */

export const getCapitalizeString = (capitalizeString: string): string => {
    return (
        capitalizeString &&
        capitalizeString.replace(
            /\w\S*/g,
            str => str.charAt(magicNumber.ZERO).toUpperCase() + str.substr(magicNumber.ONE).toLowerCase(),
        )
    )
}
