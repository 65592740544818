import fetchCommonContentData from './fetchCommonContentData'
import fetchSiteWidePromoCodeData from './fetchSiteWidePromoCodeData'
import handleRegistrationRedirection from './handleRegistrationRedirection'
import { StorageHelper } from '../helpers'

/**
 * function called on Page load to invoke actions on initialize
 */
const handleAppInit = (): void => {
    // The redirect handle should be first to avoid params manipulations by the site lifecycle
    handleRegistrationRedirection()
    fetchCommonContentData()
    fetchSiteWidePromoCodeData()
    StorageHelper.prepareRedux()
}

export default handleAppInit
