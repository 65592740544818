import React from 'react'
import { AppliedPromoType } from './AppliedPromo.type'
import { PREFIX } from '../config'
import Icon from '../Icon/Icon'

/**
 * AppliedPromo component
 * @param {AppliedPromoType} props - appliedPromoLabel
 * @return {JSX.Element} returns AppliedPromo component
 */
const AppliedPromo: React.FC<AppliedPromoType> = ({ ...props }): JSX.Element => {
    const { appliedPromoPrefix, appliedPromoLabel } = props

    return appliedPromoLabel ? (
        <div className={`${PREFIX}-applied-promo`}>
            <Icon type="g-confirm" size="lg" />
            <span className={`${PREFIX}-applied-promo__text`}>
                {appliedPromoPrefix} {appliedPromoLabel}
            </span>
        </div>
    ) : null
}

export default AppliedPromo
