import { DOBFormatConstant } from './DOBFormatConstant.constant'
import getLanguage from './getLanguage'

export const getDateOfBirth = (DOBValue: string, monthFormat = 'long'): string => {
    const language = getLanguage()
    const date = DOBValue.slice(0, DOBFormatConstant.dateLastIndex)
    const month = DOBValue.slice(DOBFormatConstant.dateLastIndex, DOBFormatConstant.monthLastIndex)
    const year = DOBValue.slice(DOBFormatConstant.yearFirstIndex)
    const formatedDOB = date + '/' + month + '/' + year
    const objDate = new Date(formatedDOB)
    return objDate.toLocaleDateString(language || 'en', {
        year: 'numeric',
        month: monthFormat,
        day: '2-digit',
    })
}
