import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Variants from '../Variants'
import { VariantsType } from './VariantsWrapper.type'
import { isArrayNotEmpty, magicNumber } from '../../utils'
import { COLOR } from '../Variants/Variants.constant'
import { checkDataLength } from '../../utils/checkDataLength'
import { VariantType } from '../BuyBox/Buybox.type'

/**
 * VariantsWrapper component
 * @param {VariantsType} props
 * @return {JSX.Element} returns Color, Size and DynamicVariant
 */
const VariantsWrapper: React.FC<VariantsType> = ({ ...props }): JSX.Element => {
    const {
        setSelectedFirstVariantValue,
        setSelectedFirstVariantIdValue,
        setSelectedSecondVariantValue,
        setSelectedSecondVariantIdValue,
        setSelectedThirdVariantValue,
        setSelectedThirdVariantIdValue,
        variantDetails,
        selectedProductCode,
        selectedColorCode,
        variantsProductSkuData,
        selectedFirstVariantId,
        selectedSecondVariantId,
        variantOptions,
        isColourSwatchesActive,
        setShowSizeChart,
        showSizeChart,
        isSizeChartAvailable,
        sizeChartButtonTitle,
        isOnlyOneVariant,
        isFirstVariantClicked,
        setIsFirstVariantClicked,
        checkOutOfStock,
        a11yVariantsSelectedCount,
        inStockLabel,
        outOfStockLabel,
        checkIfAllVariantsSelected,
        enableOnlyAvailableOnline,
        availableOnlinePurchaseLabel,
        onlyAvailableOnlineAtLabel,
        preferredStoreDetails,
        limitedStockMessage,
        isLimitedStock,
    } = props

    const { firstVariant, secondVariant } = variantDetails || {}
    const variantsRef = useRef(0)
    const variantClickedRef = useRef(0)

    /**
     * returns variants click handler
     * @return {void}
     */
    const variantClickHandler = (): void => {
        variantClickedRef.current = magicNumber.ONE
    }

    const getIndexBySelectedCode = (): number => {
        return firstVariant?.values?.findIndex(skuObj => skuObj?.skuCodes.includes(selectedProductCode))
    }

    const getIndexBySelectedColorCode = (): number => {
        return firstVariant?.values?.findIndex(skuObj => skuObj?.id === selectedColorCode)
    }

    const getIndexByDefaultColor = (): number => {
        return isColourSwatchesActive && firstVariant.descriptor === COLOR
            ? firstVariant?.values?.findIndex(item => item.isDefaultColour)
            : magicNumber.MINUS_ONE
    }

    const getIndexByAvailability = (): number => {
        if (variantOptions?.length === magicNumber.ONE && !isFirstVariantClicked) {
            variantsProductSkuData?.skus?.sort((skuobj1, skuobj2) => {
                return (
                    firstVariant?.values?.findIndex(obj => obj.skuCodes[magicNumber.ZERO] === skuobj1.code) -
                    firstVariant?.values?.findIndex(obj => obj.skuCodes[magicNumber.ZERO] === skuobj2.code)
                )
            })
            const availableVariant = variantsProductSkuData?.skus?.find(
                variant => variant.fulfillment.availability.quantity > 0,
            )
            if (availableVariant) {
                const skuIndex = firstVariant?.values?.findIndex(skuObj =>
                    skuObj?.skuCodes.includes(availableVariant?.code),
                )
                if (selectedFirstVariantId !== firstVariant?.values[skuIndex].id) {
                    variantsRef.current = 0
                }
                return skuIndex
            }
        } else if (variantOptions?.length !== magicNumber.ONE && variantClickedRef.current === 0) {
            const isVariantAvailable = (variant: VariantType): boolean => {
                return variantsProductSkuData?.skus
                    ?.filter(skus => {
                        return variant.skuCodes.some(variantSkuCode => {
                            return variantSkuCode === skus.code
                        })
                    })
                    .some(
                        skuCode =>
                            skuCode.fulfillment.availability.quantity > 0 ||
                            (skuCode.fulfillment.availability.Corporate.Quantity > 0 && skuCode.orderable),
                    )
            }

            const skuIndex = firstVariant?.values.findIndex(isVariantAvailable)

            if (skuIndex !== magicNumber.MINUS_ONE && selectedFirstVariantId !== firstVariant?.values[skuIndex]?.id) {
                variantsRef.current = magicNumber.ZERO
            }

            return skuIndex
        }
        return magicNumber.MINUS_ONE
    }

    useEffect(() => {
        let skuIndex = getIndexBySelectedCode()
        if (skuIndex === magicNumber.MINUS_ONE) {
            skuIndex = getIndexBySelectedColorCode()
        }
        if (skuIndex === magicNumber.MINUS_ONE) {
            skuIndex = getIndexByDefaultColor()
        }
        if (skuIndex === magicNumber.MINUS_ONE) {
            skuIndex = getIndexByAvailability()
        }
        if (skuIndex === magicNumber.MINUS_ONE) {
            skuIndex = magicNumber.ZERO
        }

        if (variantDetails && variantsRef.current === 0) {
            setSelectedFirstVariantValue(
                firstVariant.values[skuIndex]?.name,
                firstVariant.values[skuIndex]?.displayNameForVariants,
            )
            setSelectedFirstVariantIdValue(firstVariant.values[skuIndex]?.id)
            variantsRef.current = 1
        }

        // TODO: remove and fix dependencies. once missing dependencies are added, another error is encountered suggesting the dependency functions be embedded useEffect or useCallback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        variantDetails,
        selectedProductCode,
        firstVariant,
        variantOptions,
        variantsProductSkuData,
        isFirstVariantClicked,
        selectedFirstVariantId,
        setSelectedFirstVariantValue,
        setSelectedFirstVariantIdValue,
    ])

    /** useeffect to auto select variant when there is only one option for selection */
    useEffect(() => {
        if (variantDetails?.secondVariant) {
            const selectedFirstVariant = variantDetails?.firstVariant?.values?.find(
                variant => variant?.id === selectedFirstVariantId,
            )
            const selectedSecondVariant = selectedFirstVariant?.secondVariant?.find(variant =>
                variant?.skuCodes.includes(selectedFirstVariant.skuCodes[magicNumber.ZERO]),
            )

            const secondVariantLength = selectedFirstVariant?.secondVariant?.filter(
                variant => variant.isUnavailable === false,
            ).length

            if (secondVariantLength === magicNumber.ONE) {
                setSelectedSecondVariantValue(selectedSecondVariant.name)
                setSelectedSecondVariantIdValue(selectedSecondVariant.id)
            }

            if (variantDetails?.thirdVariant) {
                const thirdVariantLength = selectedFirstVariant?.thirdVariant?.filter(
                    variant => variant.isUnavailable === false,
                ).length

                if (thirdVariantLength === magicNumber.ONE) {
                    const selectedThirdVariant = selectedFirstVariant?.thirdVariant?.find(variant =>
                        variant?.skuCodes.includes(selectedFirstVariant.skuCodes[magicNumber.ZERO]),
                    )
                    setSelectedThirdVariantValue(selectedThirdVariant.name)
                    setSelectedThirdVariantIdValue(selectedThirdVariant.id)
                }
            }
        }
    }, [
        variantDetails,
        selectedFirstVariantId,
        selectedSecondVariantId,
        setSelectedSecondVariantIdValue,
        setSelectedSecondVariantValue,
        setSelectedThirdVariantIdValue,
        setSelectedThirdVariantValue,
        secondVariant,
    ])

    return (
        <>
            {variantDetails &&
                ((variantsProductSkuData && isArrayNotEmpty(variantsProductSkuData?.skus)) ||
                    !checkDataLength(variantsProductSkuData)) &&
                Object.keys(variantDetails).map((variant: string, index: number) => {
                    return (
                        <Variants
                            isLimitedStock={isLimitedStock}
                            preferredStoreDetails={preferredStoreDetails}
                            onlyAvailableOnlineAtLabel={onlyAvailableOnlineAtLabel}
                            enableOnlyAvailableOnline={enableOnlyAvailableOnline}
                            availableOnlinePurchaseLabel={availableOnlinePurchaseLabel}
                            limitedStockMessage={limitedStockMessage}
                            checkIfAllVariantsSelected={checkIfAllVariantsSelected}
                            outOfStockLabel={outOfStockLabel}
                            inStockLabel={inStockLabel}
                            a11yVariantsSelectedCount={a11yVariantsSelectedCount}
                            key={index}
                            variantsData={variantDetails[variant]}
                            variantKey={variant}
                            isFirstVariantClicked={isFirstVariantClicked}
                            setIsFirstVariantClicked={setIsFirstVariantClicked}
                            variantClickHandler={variantClickHandler}
                            setShowSizeChart={setShowSizeChart}
                            showSizeChart={showSizeChart}
                            isSizeChartAvailable={isSizeChartAvailable}
                            sizeChartButtonTitle={sizeChartButtonTitle}
                            isOnlyOneVariant={isOnlyOneVariant}
                            checkOutOfStock={checkOutOfStock}
                            {...props}
                        />
                    )
                })}
        </>
    )
}

VariantsWrapper.propTypes = {
    setSelectedFirstVariantIdValue: PropTypes.func,
    variantDetails: PropTypes.any,
    selectedProductCode: PropTypes.string,
    a11yVariantsSelectedCount: PropTypes.string,
    enableOnlyAvailableOnline: PropTypes.bool,
    availableOnlinePurchaseLabel: PropTypes.string,
    onlyAvailableOnlineAtLabel: PropTypes.string,
    preferredStoreDetails: PropTypes.string,
    limitedStockMessage: PropTypes.string,
    isLimitedStock: PropTypes.bool,
    checkIfAllVariantsSelected: PropTypes.bool,
    inStockLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
}

export default VariantsWrapper
