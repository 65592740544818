import React, { useEffect } from 'react'

import { useGlobalResizeEvent } from '../../utils/useWindowEvent'
import { TooltipPosition } from './Tooltip.types'

/**
 * @return {JSX.Element} returns tool tip coordinates
 */

export const useSetCoords = (
    tooltipRef: React.MutableRefObject<HTMLDivElement>,
    setcoords: React.Dispatch<React.SetStateAction<TooltipPosition>>,
    fetchCoords: () => TooltipPosition,
    handleResize: () => void,
    coords: HTMLElement,
): void => {
    useGlobalResizeEvent(handleResize)

    useEffect(() => {
        if (
            tooltipRef.current &&
            window.getComputedStyle(tooltipRef.current).getPropertyValue('position') === 'absolute'
        ) {
            setcoords(fetchCoords())
        }
    }, [setcoords, tooltipRef, coords, fetchCoords])
}
