import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/estimateFees.actionCreators'
import { EstimateFees } from '../models/estimateFees.interface'
import {
    fetchEstimateFeesAction,
    estimateFeesFailureAction,
    resetEstimateFeesAction,
} from '../actionCreators/estimateFees.actionCreators'

type Action = ActionType<typeof actions>

export interface estimateFeesState {
    estimateFeesData: EstimateFees | null
    estimateFeesFailure: boolean
    estimateFeesSuccess: boolean
}

export const initialState: estimateFeesState = {
    estimateFeesData: null,
    estimateFeesFailure: false,
    estimateFeesSuccess: false,
}

export const estimateFeesReducer = (state: estimateFeesState = initialState, action: Action): estimateFeesState => {
    switch (action.type) {
        case getType(fetchEstimateFeesAction): {
            return {
                ...state,
                estimateFeesData: action.payload,
                estimateFeesFailure: false,
                estimateFeesSuccess: true,
            }
        }
        case getType(estimateFeesFailureAction): {
            return {
                ...state,
                estimateFeesFailure: true,
                estimateFeesSuccess: false,
            }
        }
        case getType(resetEstimateFeesAction): {
            return {
                ...state,
                estimateFeesData: null,
                estimateFeesFailure: false,
                estimateFeesSuccess: true,
            }
        }
        default:
            return state
    }
}
