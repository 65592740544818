import { getEnvironment } from '../../environments'
import { DevConfig } from '../../environments/dev.environment'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import { EstimateFeesParams, EstimateFees } from '../../redux/models/estimateFees.interface'

const environment: DevConfig = getEnvironment()
const httpClient = getHttpClient()

/**
 * Estimate Fees service. To fetch the Estimate Shipping Fees
 */
class EstimateFeesService extends BaseService {
    /**
     * Load Estimate Fees
     * @param  {EstimateFeesParams} params
     * @return {SuccessResponse}
     */
    fetchEstimateFees(params: EstimateFeesParams): Promise<{ data: EstimateFees }> {
        const url: URL = this.createEstimateFeesUrl()
        const strippedPostal = params.postalCode.replace(/\s+/g, '')
        const payload = {
            sku: params.sku,
            postalCode: strippedPostal,
            selectedStoreId: params.selectedStoreId,
            quantity: params.quantity,
        }

        return httpClient.apiPost(url.toString(), payload, undefined, true)
    }

    /**
     * Create Estimate Fees url based on mock or cds
     * @return {URL}
     */
    createEstimateFeesUrl(): URL {
        const url = new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS.estimateFees}`)
        if (EstimateFeesService.isMockUri(environment.API_ENDPOINTS.estimateFees)) {
            return EstimateFeesService.prepareMockUrl(url, environment.API_MOCK_URL)
        } else {
            return url
        }
    }
}

const estimateFeesService = new EstimateFeesService()

export { estimateFeesService }
export default estimateFeesService
