import { checkDataLength } from '../components/Accounts/Addresses/checkDataLength'
import { globalPartialAuthCode } from '../globalConstants/cdsErrorCodes'
import { UserLoggedInStatus } from '../globalConstants/analyticsParams.constant'
import { GlobalOnloadAnalyticData } from '../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.type'

/**
 * @param {AnalyticsData} analyticsData
 * @returns {string} returning the user status Logged in | Partial logged in | anonymous
 */

const getUserStatusForLogin = (analyticsData: GlobalOnloadAnalyticData): string => {
    if (analyticsData && checkDataLength(analyticsData.profileData) && analyticsData.isAuthFlowExecuted) {
        return UserLoggedInStatus.loggedInUser
    } else if (analyticsData?.errorInterceptorData?.response?.data?.errCode?.toString() === globalPartialAuthCode) {
        return UserLoggedInStatus.partialLoggedInUser
    } else {
        return UserLoggedInStatus.anonymousUser
    }
}

export default getUserStatusForLogin
