export const variantsConstant = {
    timeoutValue: 1000,
    maxHeight: 212,
    variantLabel: 'Variant',
    firstVariantName: 'firstVariant',
    secondVariantName: 'secondVariant',
    thirdVariantName: 'thirdVariant',
    hideVariantClass: 'hide',
    showVariantClass: 'show',
    colorCodeParam: 'colorCode',
    SIZE: 'SIZE',
}

export const COLOR = 'COLOUR'
