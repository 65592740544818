import { keyCodes } from '../../utils/keyCodes'
import { navigationalKeysArray, specialKeysArray } from './TextArea.constant'

/**
 * For handling the limit on the text input and storing the input in the store variable.
 * @param {KeyboardEvent} event - keyboard event which is used to extract the text
 * @param {React.Dispatch<React.SetStateAction<string>>} setCharCount - state variable to set the text
 * @param {React.MutableRefObject<any>} textAreaRef - Reference to the text area element.
 * @param {func} onInputChange - function to capture the text and send back to the element where the text area is used.
 * @param {boolean} hasMaxLimit - to consider the text limit or not.
 * @param {number} maxCharacters - max limit of the text.
 * @return {boolean}
 */
// eslint-disable-next-line complexity
export const textAreaInputHandler = (
    event: KeyboardEvent,
    setCharCount: React.Dispatch<React.SetStateAction<string>>,
    textAreaRef: React.MutableRefObject<HTMLDivElement>,
    onInputChange: (value: unknown) => void,
    hasMaxLimit: boolean,
    maxCharacters: number,
): boolean => {
    const inputText = textAreaRef.current.innerText
    // For update the store variable which in turn used to show the char count
    setCharCount(
        inputText.length > 0 && inputText.includes('\n')
            ? inputText.replace('/\n/g/', '')
            : (inputText as unknown as string),
    )

    // For passing the value to the parent.
    onInputChange(inputText)

    // Object to handle the special cases.
    const utils = {
        special: {},
        navigational: {},
        isSpecial(e: KeyboardEvent) {
            return typeof (this as typeof utils).special[e.keyCode] !== 'undefined'
        },
        isNavigational(e: KeyboardEvent) {
            return typeof (this as typeof utils).navigational[e.keyCode] !== 'undefined'
        },
    }

    // Special keys, navigational keys to be excluded from preventDefault when the text reaches to the max limit.
    specialKeysArray.forEach(key => {
        utils.special[keyCodes[key]] = true
    })

    navigationalKeysArray.forEach(navKey => {
        utils.navigational[keyCodes[navKey]] = true
    })

    let hasSelection = false
    const selection = window.getSelection()
    const isSpecial = utils.isSpecial(event)
    const isNavigational = utils.isNavigational(event)

    /**
     * If text is selected, set the value to true.
     * If set to true, the content will be editable till it reaches the count limit.
     */
    if (selection) {
        hasSelection = !!selection.toString()
    }

    // Allows the user to use backspace or the other keys on the text even when reached the limit.
    if (isSpecial || isNavigational) {
        return true
    }

    const len =
        textAreaRef.current.innerText.length > 0 && textAreaRef.current.innerText.includes('\n')
            ? textAreaRef.current.innerText.replace('/\n/g', '').length
            : textAreaRef.current.innerText.length

    // Don't allow user to type more than max limit.
    if (hasMaxLimit && len >= maxCharacters && !hasSelection) {
        event.preventDefault()
        return false
    }
}

/**
 * For selecting the entire text when it is focused through tab.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsFocused - set the focus to true, to get the focus style.
 * @param {string} id - text area element.
 * @return {void}
 */
export const onFocusInputHandler = (setIsFocused: React.Dispatch<React.SetStateAction<boolean>>, id: string): void => {
    setIsFocused(true)
    if (window.getSelection) {
        const selection = window.getSelection() // Window function to select all the text in the content editable text.
        const range = document.createRange() // Create range to select the entire content.
        range.selectNodeContents(document.getElementById(id)) // Get all the content
        selection.removeAllRanges() // Remove previous ranges
        selection.addRange(range) // add the selection
    }
}

/**
 * To Remove the selection styling when the focus is lost.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsFocused - set the focus to true, to get the focus style.
 * @return {void}
 */
export const onFocusOutHandler = (setIsFocused: React.Dispatch<React.SetStateAction<boolean>>): void => {
    setIsFocused(false)
    window.getSelection().removeAllRanges()
}
