import React from 'react'

import { PREFIX } from '../config'
import Icon from '../Icon'
import { getNextDayStoreTimings, getStoreTimings } from '../StoreSelectorModal/StoreSelectorHelper'
import { StoreTooltipTypeProps } from './StoreTooltip.type'

/**
 * StoreToolTip component
 * @param {StoreTooltip} props
 * @return {JSX.Element} returns StoreTooltip Component
 */
const StoreTooltip: React.FC<StoreTooltipTypeProps> = ({ ...props }) => {
    const {
        storeData,
        openLabel,
        opensLabel,
        closesLabel,
        closeLabel,
        storeDetailsCTALabel,
        onlineOrdering,
        onlineOrdersNotAcceptedMsg,
        storeDetailsPageLinkClicked,
    } = props
    const { address, url } = storeData || {}
    const weekdayHours = getStoreTimings(storeData)
    const nextWeekDayHours = getNextDayStoreTimings(storeData)
    const { closingTime, closed } = weekdayHours || {}
    const { openingTime } = nextWeekDayHours || {}
    const storeTimingsPresent = closed ? openingTime?.formattedHour : closingTime?.formattedHour

    return (
        <div className={`${PREFIX}-order-summary__tooltip`}>
            <p>{address?.line1}</p>
            <p>{address?.postalCode}</p>
            {storeTimingsPresent && (
                <p>
                    <span className={`${PREFIX}-order-summary__tooltip__open-text`}>
                        {closed ? closeLabel : openLabel}
                    </span>
                    {' . '}
                    {closed
                        ? `${opensLabel} ${openingTime?.formattedHour}`
                        : `${closesLabel} ${closingTime?.formattedHour}`}
                </p>
            )}
            <a
                href={url}
                onClick={storeDetailsPageLinkClicked}
                className={`${PREFIX}-order-summary__tooltip__store-link`}>
                {storeDetailsCTALabel}
            </a>
            {!onlineOrdering && (
                <div className={`${PREFIX}-order-summary__tooltip__store-status`}>
                    <div>
                        <Icon type="ct-notification-caution-stroked" size="md" />
                    </div>
                    <span className={`${PREFIX}-order-summary__tooltip__store-status_message`}>
                        {onlineOrdersNotAcceptedMsg}
                    </span>
                </div>
            )}
        </div>
    )
}

export default StoreTooltip
