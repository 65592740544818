import { ActionType, getType } from 'typesafe-actions'
import { VehicleBannerState } from '../models/vehicleBanner.interface'
import * as actions from '../actionCreators/vehicleBanner.actionCreators'

const initialState = {
    hideVehicleBanner: false,
    vehicleListVisible: false,
}

type Action = ActionType<typeof actions>

export const vehicleBannerReducer = (state: VehicleBannerState = initialState, action: Action): VehicleBannerState => {
    switch (action.type) {
        case getType(actions.setVehicleBannerVisible):
            return { ...state, hideVehicleBanner: !action.payload }
        case getType(actions.setVehicleListVisibility):
            return { ...state, vehicleListVisible: action.payload }

        default:
            return state
    }
}
