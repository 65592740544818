import { getEnvironment } from '../../environments'
import { DevConfig } from '../../environments/dev.environment'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import { Order, OrderNotificationSubscriptionResponse } from '../../redux/models/orderConfirmation.interface'

const httpClient = getHttpClient()
const environment: DevConfig = getEnvironment()
/**
 * orderService To fetch the order confirmation and order detail products
 */
class OrderService extends BaseService {
    /**
     * Subscribe/unsubscribe notifications
     * @param {string} userRole
     * @param {string} orderCode
     * @param {string} phone
     * @param {boolean} subscriptionFlag
     * @return {string} OrderNotificationSubscriptionResponse
     */
    toggleOrderNotificationSubscription(
        userRole: string,
        orderCode: string,
        phone: string,
        subscriptionFlag: boolean,
    ): Promise<{ data: OrderNotificationSubscriptionResponse }> {
        const url: URL = this.createToggleOrderNotificationSubscriptionUrl(userRole, orderCode)
        const payload = {
            phone: phone,
            smsNotificationStatus: [
                {
                    key: 'ORDERREADYFORPICKUP',
                    value: subscriptionFlag,
                },
            ],
        }

        return OrderService.isMock() ? httpClient.apiGet(url.toString()) : httpClient.apiPost(url.toString(), payload)
    }

    /**
     * Load wishlist for logged in customer
     * @param {string} orderId
     * @param {string} userEmail
     * @param {string} sfsOrderNumber OCCP-23648: required for SFS orders
     * @param {string} hashedOrderCode
     * @return {orderConfirmationrresponse}
     */
    getOrderConfirmation(
        orderId: string,
        userEmail?: string,
        sfsOrderNumber?: string,
        hashedOrderCode?: string,
    ): Promise<{ data: Order }> {
        const url = this.createOrderConfirmationDetailsUrl(orderId, userEmail, sfsOrderNumber, hashedOrderCode)
        return httpClient.apiGet(url)
    }

    /**
     * Create orderConfirmation url.
     * @param {string} orderId
     * @param {string} userEmail
     * @param {string} sfsOrderNumber OCCP-23648: required for SFS orders
     * @param {string} hashedOrderCode
     * @return {URL}
     */
    createOrderConfirmationDetailsUrl(
        orderId: string,
        userEmail?: string,
        sfsOrderNumber?: string,
        hashedOrderCode?: string,
    ): string {
        const orderDetailsEndpoint = environment.API_ENDPOINTS.orderDetails
        const orderDetailsMockEndpoint = `/mock${environment.API_ENDPOINTS.orderDetails}`

        const url = OrderService.switchEndpoints(orderDetailsMockEndpoint, orderDetailsEndpoint)
        const email = userEmail ? `&email=${userEmail}` : ''
        const sfsOrderNumberParameter = sfsOrderNumber ? `&oNumber=${sfsOrderNumber}` : ''
        const hashedOrderCodeParameter = hashedOrderCode ? `&hashedCode=${hashedOrderCode}` : ''
        return `${url}?code=${orderId}&lang=${OrderService.language}${email}${sfsOrderNumberParameter}${hashedOrderCodeParameter}`
    }

    /**
     * Function to create url for notification subscription
     * @param {userRole} userRole
     * @param {orderCode} orderCode
     * @return {URL}
     */
    createToggleOrderNotificationSubscriptionUrl(userRole: string, orderCode: string): URL {
        const path = environment.API_ENDPOINTS.orderNotificationSubscription
            .replace('{userRole}', userRole)
            .replace('{orderCode}', orderCode)
        return OrderService.createUri(path)
    }
}

const orderService = new OrderService()

export { orderService }
export default orderService
