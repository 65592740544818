import { AnyAction, Dispatch, Store } from 'redux'
import { RootState } from '../reducers'
import PrerenderReadySetter from './utils/PrerenderReadySetter'

const prerenderReadySetter = new PrerenderReadySetter()

interface IPrerenderMiddleware {
    (store: Store<RootState, AnyAction>): (next: Dispatch<AnyAction>) => (action: AnyAction) => void
}

export const prerenderMiddleware: IPrerenderMiddleware =
    (store: Store<RootState, AnyAction>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
        if (!window.prerenderReady) {
            const { staticPrerenderReady } = store.getState().prerender
            prerenderReadySetter.setConditionsToVerify = staticPrerenderReady
        }
        return next(action)
    }
