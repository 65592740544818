import getPageTitle from '../../utils/getPageTitle'
import { onLoadEvent } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.constant'
import { getProvider } from '../providers'
import { SpaPageAnalytics, SpaPageEventAnalytics } from '../analytics.type'

const provider = getProvider()

const spaAnalytics = (() => {
    /**
     * Prepare analytics data for onload page
     * @param {string} pageName
     * @return {SpaPageAnalytics}
     */
    const prepareOnloadPageData = (pageName?: string): SpaPageAnalytics => {
        return {
            name: pageName ? pageName : getPageTitle(),
            url: window.location.href,
        }
    }

    /**
     * This function is to trigger analytics for single page
     * @param { string } pageName
     * @return { SpaPageEventAnalytics }
     */
    const _spaAnalyticsData = (pageName?: string): SpaPageEventAnalytics => {
        return {
            event: onLoadEvent,
            page: prepareOnloadPageData(pageName),
        }
    }
    return {
        setAnalytics: (pageName?: string): void => {
            provider.push(_spaAnalyticsData(pageName))
        },
    }
})()

export default spaAnalytics
