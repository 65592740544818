import React, { KeyboardEventHandler, MouseEventHandler, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Accordion from '../Accordion'
import Button from '../Button'
import Icon from '../Icon'
import Table from '../Table'
import Tooltip from '../Tooltip'
import NewCardForm from './NewGiftCardForm'
import { PREFIX } from '../config'
import { GiftCardProps } from './GiftCard.types'
import { formatCurrency } from './GiftCard.helpers'
import {
    TooltipInteractionKeys,
    componentClassName,
    defaultLogo,
    CARD_MASKING,
    CARD_NUMBER_DIGIT_SLICE,
} from './GiftCard.constants'
import { replaceStrWithDynamicVal } from '../../utils'

/**
 * GiftCard component
 * @param {GiftCardProps} GiftCardProps
 *
 * @return {JSX.Element}
 */
const GiftCard: React.FC<GiftCardProps> = ({ ...props }): JSX.Element => {
    const {
        gcMaxCardsAmount,
        gcTitle,
        gcLogoIcon,
        gcKeepCardsLabel,
        gcMaxCardsLabel,
        gcMaxCardsReachedLabel,
        gcOrderTotalCoveredLabel,
        gcApplyAnotherCardCTALabel,
        gcApplyCTALabel,
        gcCardNumberColHeader,
        gcBalanceColHeader,
        gcAppliedAmtColHeader,
        gcCardNumberInputLabel,
        gcPinInputLabel,
        gcCardNumberErrorMsg,
        gcPinErrorMsg,
        gcTooltipTitle,
        gcTooltipDesc,
        a11yRemoveLabel,
        giftCardData,
        applyGiftCard,
        deleteGiftCard,
        initiatedGiftCards,
        onGiftCardExpandHandler,
    } = props

    const tooltipButtonRef = useRef<HTMLButtonElement>(null)
    const [tooltipVisibility, setTooltipVisibility] = useState(false)
    const [showNewCardForm, setShowNewCardForm] = useState(false)

    const { numberOfGiftCards, giftCardList, remainingAmount } = giftCardData

    const noCardsApplied = !numberOfGiftCards
    const isMaxCardsReached = numberOfGiftCards === gcMaxCardsAmount
    const isOrderTotalCovered = remainingAmount === 0

    useEffect(() => {
        setShowNewCardForm(false)
    }, [giftCardData])

    const renderTooltip = () => {
        const tooltipBtnClickHandler: MouseEventHandler<HTMLButtonElement> = event => {
            event.stopPropagation()
            setTooltipVisibility(prev => !prev)
        }

        const tooltipKeyHandler: KeyboardEventHandler<HTMLSpanElement> = event => {
            if (event.key in TooltipInteractionKeys) {
                event.preventDefault()
                setTooltipVisibility(prev => !prev)
            }
        }

        return (
            <>
                <span
                    className={`${componentClassName}__tooltip-btn`}
                    ref={tooltipButtonRef}
                    onClick={tooltipBtnClickHandler}
                    onKeyDown={tooltipKeyHandler}
                    role="button"
                    tabIndex={0}
                    data-testid="tooltip-btn"
                    aria-label={gcTooltipTitle}>
                    <Icon type="ct-information-details" size="lg" />
                </span>
                {!!tooltipVisibility && (
                    <Tooltip
                        visibility={tooltipVisibility}
                        setVisibility={setTooltipVisibility}
                        iconID="ct-close"
                        headerText={gcTooltipTitle}
                        bodyText={gcTooltipDesc}
                        coords={tooltipButtonRef.current}
                    />
                )}
            </>
        )
    }

    const renderTitleSection = () => {
        return (
            <div className={`${componentClassName}__title-section`}>
                <div className={`${componentClassName}__logo`}>
                    <Icon type={gcLogoIcon ? gcLogoIcon : defaultLogo} />
                </div>
                <span className={`${PREFIX}-h4--sm`}>{gcTitle}</span>
                {renderTooltip()}
            </div>
        )
    }

    const renderRemoveCardButton = (cardId: string): JSX.Element => {
        return (
            <button
                className={`${componentClassName}__remove-card-btn`}
                onClick={() => deleteGiftCard({ id: cardId })}
                aria-label={a11yRemoveLabel}
                data-testid="gift-card-remove">
                <Icon type="ct-close" size="md" />
            </button>
        )
    }

    const renderAppliedCardsTable = () => {
        const tableData = giftCardList?.map(card => {
            const { id, number, balance, amount } = card
            const initiatedCard = initiatedGiftCards?.find(initiatedGiftCard => initiatedGiftCard?.id === id)
            return {
                [gcCardNumberColHeader]: `${CARD_MASKING} ${number?.substr(CARD_NUMBER_DIGIT_SLICE)}`,
                [gcBalanceColHeader]: formatCurrency(balance),
                [gcAppliedAmtColHeader]: formatCurrency(initiatedCard ? Number(initiatedCard?.amount) : amount),
                '': renderRemoveCardButton(id),
            }
        })

        return <Table tableData={tableData} headerType="row" tableType="borderless" />
    }

    const renderAddAnotherCardCTA = (): JSX.Element | null => {
        return !showNewCardForm ? (
            <Button type="tertiary" size="small" onClick={() => setShowNewCardForm(true)} id="gift-card-add">
                <Icon type="ct-add" size="md" />
                <span>{gcApplyAnotherCardCTALabel}</span>
            </Button>
        ) : null
    }

    const renderAppliedCardsSection = () => {
        return (
            <>
                {renderAppliedCardsTable()}
                {isOrderTotalCovered || isMaxCardsReached ? (
                    <p className={`${componentClassName}__label`}>
                        {isOrderTotalCovered ? gcOrderTotalCoveredLabel : gcMaxCardsReachedLabel}
                    </p>
                ) : (
                    renderAddAnotherCardCTA()
                )}
            </>
        )
    }

    const renderNewCardSection = () => {
        const newCardFormProps = {
            gcApplyCTALabel,
            gcCardNumberInputLabel,
            gcPinInputLabel,
            gcCardNumberErrorMsg,
            gcPinErrorMsg,
            applyGiftCard,
        }

        return (
            <>
                <NewCardForm {...newCardFormProps} />
                <p className={`${componentClassName}__label`}>
                    {isMaxCardsReached
                        ? gcMaxCardsReachedLabel
                        : replaceStrWithDynamicVal(gcMaxCardsLabel, gcMaxCardsAmount)}
                </p>
            </>
        )
    }

    return (
        <div className={componentClassName}>
            <Accordion title={renderTitleSection()} collapseControl={onGiftCardExpandHandler}>
                <>
                    <div className={`${componentClassName}__section`}>
                        <p className={`${componentClassName}__keep-label`}>{gcKeepCardsLabel}</p>
                        {noCardsApplied ? renderNewCardSection() : renderAppliedCardsSection()}
                    </div>
                    {showNewCardForm && (
                        <div className={`${componentClassName}__section`}>
                            <p className={`${componentClassName}__new-card-heading`}>{gcApplyAnotherCardCTALabel}</p>
                            {renderNewCardSection()}
                        </div>
                    )}
                </>
            </Accordion>
        </div>
    )
}
GiftCard.propTypes = {
    gcMaxCardsAmount: PropTypes.number.isRequired,
    gcTitle: PropTypes.string.isRequired,
    gcLogoIcon: PropTypes.string.isRequired,
    gcKeepCardsLabel: PropTypes.string.isRequired,
    gcMaxCardsLabel: PropTypes.string.isRequired,
    gcMaxCardsReachedLabel: PropTypes.string.isRequired,
    gcOrderTotalCoveredLabel: PropTypes.string.isRequired,
    gcApplyAnotherCardCTALabel: PropTypes.string.isRequired,
    gcApplyCTALabel: PropTypes.string.isRequired,
    gcCardNumberColHeader: PropTypes.string.isRequired,
    gcBalanceColHeader: PropTypes.string.isRequired,
    gcAppliedAmtColHeader: PropTypes.string.isRequired,
    gcCardNumberInputLabel: PropTypes.string.isRequired,
    gcPinInputLabel: PropTypes.string.isRequired,
    gcCardNumberErrorMsg: PropTypes.string.isRequired,
    gcPinErrorMsg: PropTypes.string.isRequired,
    gcTooltipTitle: PropTypes.string.isRequired,
    gcTooltipDesc: PropTypes.string.isRequired,
    a11yRemoveLabel: PropTypes.string.isRequired,
    applyGiftCard: PropTypes.func.isRequired,
    deleteGiftCard: PropTypes.func.isRequired,
}

export default GiftCard
