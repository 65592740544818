import { SubCategories } from '../MegaNavigationDesktop/MegaNavigationDesktop.type'

/**
 * Divides an array of SubCategories into groups of a specified number of items per column.
 *
 * @param {SubCategories[]} items - The array of SubCategories to be divided.
 * @param {number} itemsPerColumn - The number of items per column.
 *
 * @returns {SubCategories[][]} An array of arrays, where each inner array represents a column containing SubCategories.
 **/

export const divideIntoColumns = (items: SubCategories[], itemsPerColumn: number): SubCategories[][] => {
    return Array.from({ length: Math.ceil(items.length / itemsPerColumn) }, (_, index) =>
        items.slice(index * itemsPerColumn, index * itemsPerColumn + itemsPerColumn),
    )
}
