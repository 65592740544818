import { AnalyticsRecordInterface, BaseProvider } from './provider.type'

/**
 * GTM provider implementation
 */
export class Gtm implements BaseProvider {
    /**
     * Checking and initalizing analytics layer
     */
    constructor() {
        // check for layer object
        window.analyticsLayer = window.analyticsLayer || []
    }
    /**
     * Push data to analytics server
     * @param {AnalyticsRecordInterface} data
     */
    push(data: AnalyticsRecordInterface): void {
        window.analyticsLayer.push(data)
    }
}
