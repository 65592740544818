export enum SessionSuffixTypes {
    HEX = 'hex',
    BASE_64 = 'base64',
    URL_SAFE = 'url-safe',
    NUMERIC = 'numeric',
    DISTINGUISHABLE = 'distinguishable',
    ASCII_PRINTABLE = 'ascii-printable',
    ALPHANUMERIC = 'alphanumeric',
}

/**
 ** Interface for  signifydinit
 * @interface
 */
export interface signifydSessionInfoProps {
    signifydJsUrl: string
    id: string
    sessionIdSuffixType: SessionSuffixTypes
    sessionIdSuffixLength: number
    sessionPrefix: string
    sessionLifeTime: number | undefined
    type: string
}
