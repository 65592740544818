import { magicNumber } from '../magicNumber'
import { queryParameters } from './queryParameters.constants'
import { IQueryParameterSet, queryParametersKeyType } from './queryParameters.type'

export const getParamValue = (urlSearchParam: string, paramKey: string, splitKey: string): string | undefined => {
    const paramArray = urlSearchParam.replace('?', '').split(splitKey)
    const noParam = -1
    const arrayIndex = 1
    return paramArray
        .find(el => el.search(paramKey) !== noParam)
        ?.split('=')
        [arrayIndex]?.split(';')[0]
}

export const getParameterFromURL = (
    parameterKey: queryParametersKeyType,
    divider: string = queryParameters.divider,
): string | undefined => {
    const queryParams = window.location.search
    return getParamValue(queryParams, parameterKey, divider)
}

/**
 *  @param {string} urlSearchParam All the params in the url
 *  @param {string} paramKey Name of the param which u want to find in the url
 *  @param {string} splitKey delimiter between the params
 *  @return {boolean} Returns true or false
 */
export const findParamValue = (urlSearchParam: string, paramKey: string, splitKey: string): boolean => {
    const filteredParam = getParamValue(urlSearchParam, paramKey, splitKey)
    return filteredParam === 'true'
}

// Below function replaces history state of browser URL with passed query param
export const updateUrlHistory = (url?: string): void => {
    if (url) {
        window.history.replaceState({}, '', `${url}`)
    } else {
        // remove all search params from URL
        window.history.replaceState({}, '', window.location.pathname)
    }
}

// Below function pushes history state of browser URL with passed query param
export const pushUrlHistory = (url?: string): void => {
    if (url) {
        window.history.pushState({}, '', `${url}`)
    } else {
        // remove all search params from URL
        window.history.pushState({}, '', window.location.pathname)
    }
}

/**
 *  @param {string} url - page url i.e "https:/abc.com/?a=1;b=2".
 *  @param {string} queryParam - Name of the string which u want to modify.
 *  @param {string} paramKey - Delimiter which is used to separate the params.
 *  @param {string | number | boolean} paramUpdateVal - updated param value.
 *  @return {string} Returns the updated params.
 */
export const updateParam = (
    url: string,
    queryParam: string,
    paramKey: string,
    paramUpdateVal: boolean | number | string,
): string => {
    let updatedURLParams = ''
    const paramList = url.split('?')
    const [domainName, paramURL] = paramList
    let temp = ''
    if (paramURL) {
        const searchParamArray = paramURL.split(paramKey)
        const paramArrayLength = searchParamArray.length
        for (let i = 0; i < paramArrayLength; i++) {
            if (searchParamArray[i].split('=')[0] !== queryParam) {
                updatedURLParams += temp + searchParamArray[i]
                temp = paramKey
            }
        }
    }
    const updatedParam = `${temp}${queryParam}=${String(paramUpdateVal)}`
    return domainName + '?' + updatedURLParams + updatedParam
}

export const updateParameterInURL = (
    parameterKey: queryParametersKeyType,
    value: string | number,
    divider: string = queryParameters.divider,
    pushEntry = false,
): void => {
    const queryParams = window.location.search
    const newQueryParams = updateParam(queryParams, parameterKey, divider, value)
    if (pushEntry) {
        pushUrlHistory(newQueryParams)
    } else {
        updateUrlHistory(newQueryParams)
    }
}

/**
 *  @param {string} url - page url i.e "https:/abc.com/?a=1;b=2".
 *  @param {string} key - Name of the string which u want to remove
 *  @param {string} delimiter - Delimiter which is used to separate the params.
 *  @return {string} Returns the non removed params.
 */
export const removeParam = (url: string, key: string, delimiter: string): string => {
    let updatedParamsArray: Array<string> = []
    let modifiedParams = ''
    const constantValue = 1
    const queryString = url.indexOf('?') !== -constantValue ? url.split('?')[constantValue] : ''
    if (queryString !== '') {
        updatedParamsArray = queryString.split(delimiter)
        for (let i = updatedParamsArray.length - constantValue; i >= 0; i -= constantValue) {
            const param = updatedParamsArray[i].split('=')[0]
            if (param === key) {
                updatedParamsArray.splice(i, constantValue)
            }
        }
        const updatedParam = updatedParamsArray.length
            ? `?${updatedParamsArray.join(delimiter)}`
            : window.location.href.split('?')[0]
        modifiedParams = updatedParam
    }
    return modifiedParams
}

export const removeParameterFromURL = (
    parameterKey: queryParametersKeyType,
    divider: string = queryParameters.divider,
): void => {
    const queryParams = window.location.search
    const newQueryParams = removeParam(queryParams, parameterKey, divider)
    updateUrlHistory(newQueryParams)
}

const getQueryString = (params: string, partBy: string): string => {
    const urlParams = new URLSearchParams(params)
    const queryString = urlParams.get(partBy)
    return queryString ? queryString : ''
}

export const updateHashInURL = (hashKey: queryParametersKeyType, value: string | number): void => {
    window.location.hash = `${hashKey}=${value}`
}

export const checkStoreHashInURL = (): boolean => {
    const withoutHashSymbol = window.location.hash?.replace('#', '')
    const storeIdFromHash = getQueryString(withoutHashSymbol, queryParameters.store)
    return Boolean(storeIdFromHash)
}

export const checkStoreParamInURL = (): boolean => {
    const storeIdFromParams = getQueryString(window.location.search, queryParameters.store)
    return Boolean(storeIdFromParams)
}

export const isMockParamInURL = (queryParams: string): boolean => {
    const match = RegExp(
        `[?${queryParameters.plpCDSDivider};]${queryParameters.mock}[^${queryParameters.plpCDSDivider};]*`,
    ).exec(queryParams)
    const index = 0
    return !!match && !!match[index]
}

export const createPaginationHref = (pageNumber: number): string => {
    const { pathname, search } = window.location
    const updatedQuery =
        pageNumber === magicNumber.ONE
            ? ''
            : updateParam(search, queryParameters.page, queryParameters.divider, pageNumber)
    return `${pathname}${updatedQuery}`
}

export const getSAPIRequestQueryParam = (): string => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams?.entries())
    if (params.rq && params.rq.includes(queryParameters.divider)) {
        return params.rq.split(queryParameters.divider)[0]
    } else if (params.count && params.count?.includes('rq')) {
        let rqValue = ''
        params.count?.split(';').forEach(s => {
            if (s?.includes('rq=')) {
                rqValue = s.split('=')[magicNumber.ONE] || ''
            }
        })
        return rqValue
    } else {
        return params.rq || ''
    }
}

/**
 *  @param {string} url - Page url.
 *  @param {string} param - Name of the param which you want to add
 *  @param {string} value - Value of the paramemeter
 *  @return {string} Returns the updated URL.
 */
export const addParam = (url: string, param: string, value: string): string => {
    return url?.includes('?') ? `${url}${queryParameters.plpCDSDivider}${param}=${value}` : `${url}?${param}=${value}`
}

/**
 * Appends multiple query parameters to a URL
 *
 * @param {string} url - The URL to append parameters to
 * @param {Record<string, unknown>} - An object containing query parameter key-value pairs to append to the URL
 * @return {string} - A new URL string with query parameters appended
 */

export const appendQueryParamsToUrl = (url: string, params: Record<string, unknown>): string => {
    const queryString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
        .join('&')

    return url?.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`
}

/**
 * Removes all the provided query params from URL
 * @param {IQueryParameterSet[]} parameterSet // Array of all the params to be removed
 */
export const removeAllParametersFromURL = (parameterSet: IQueryParameterSet[]): void => {
    let queryParam = window.location.href
    parameterSet.forEach(param => {
        queryParam = removeParam(queryParam, param.parameterKey, param.divider)
    })
    updateUrlHistory(queryParam)
}
