import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../TextInput'
import { Suggestions, AutocompletePropTypes } from './Autocomplete.types'
import { PREFIX } from '../config'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import AutoCompleteItem from './AutocompleteItem'
import { negativeCursorValue, unityValue, ARROW_UP, ARROW_DOWN, ENTER, ESC } from './Autocomplete.constants'
import Icon from '../Icon'
import usePrevious from '../../hooks/usePrevious.hook'

/**
 * Autocomplete component
 * @param {AutocompletePropTypes} props
 * @return {JSX.Element} returns autocomplete component
 */
const Autocomplete: React.FC<AutocompletePropTypes> = ({ ...props }): JSX.Element => {
    const {
        suggestions = [],
        onSelect,
        label,
        onAutocompleteChange,
        id,
        imageUrl,
        imageAltText,
        renderItem,
        autocompleteText = '',
        error = '',
        onBlur,
        maxLength,
        placeholder,
        showSearchIcon,
        a11ySearchIconLabel,
        searchCallBack,
        selectedAddress,
        onFocusHandler,
        a11ySuggestionResultAvailable,
    } = props
    const [isVisible, setVisibility] = useState(false)
    const [search, setSearch] = useState(autocompleteText)
    const [cursor, setCursor] = useState(negativeCursorValue)
    const searchContainer = useRef(null)
    const searchResultRef = useRef<HTMLUListElement>(null)
    const [suggestionsList, setSuggestions] = useState<Suggestions[]>([])
    const prevSuggestions = usePrevious(suggestions)

    useEffect(() => {
        setCursor(negativeCursorValue)
        scrollIntoView(0)
    }, [search])

    useEffect(() => {
        if (Array.isArray(suggestions) && suggestions.length && suggestions !== prevSuggestions) {
            setSuggestions(suggestions)

            if (search) showSuggestion()
        }
    }, [search, suggestions, prevSuggestions])

    useEffect(() => {
        if (autocompleteText?.length) {
            setSearch(autocompleteText)
        } else {
            setSearch('')
        }
    }, [autocompleteText])

    /**
     * Callback on enter key. To minimize complexity written function
     * @param { React.KeyboardEvent<HTMLInputElement> } event
     * @return { void }
     */
    const searchSuggestionsCallback = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        showSearchIcon && searchCallBack(suggestions[cursor]?.description || (event.target as HTMLInputElement).value)
    }

    /**
     * @method keyboardNavigation  : handle keydown event
     *  @param {KeyboardEvent<HTMLInputElement>} event : event fired on key press
     */

    const keyboardNavigation = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        switch (event.key) {
            case ARROW_DOWN: {
                return isVisible ? setCursor(c => (c < suggestions.length - unityValue ? c + unityValue : c)) : null
            }
            case ARROW_UP: {
                return setCursor(c => (c > 0 ? c - unityValue : 0))
            }
            case ENTER: {
                if (cursor > negativeCursorValue || showSearchIcon) {
                    setSearch(onSelect(suggestions[cursor], !showSearchIcon))
                    event.preventDefault()
                    hideSuggestion()
                    searchSuggestionsCallback(event)
                }
                return
            }
            case ESC: {
                return hideSuggestion()
            }
            default:
                return
        }
    }
    // function used to show suggestion list
    const showSuggestion = () => setVisibility(true)

    // function used to hide the suggestion list
    const hideSuggestion = () => setVisibility(false)

    /**
     * @method onListItemClick  : function called when clicked on any suggestion
     *  @param {Suggestions} value : consist of address, province , city etc
     *
     */

    const onListItemClick = (value: Suggestions): void => {
        hideSuggestion()
        setSearch(onSelect(value))
    }

    /**
     * @method handleInputChange  : Fired when input feild changes
     *  @param {string} value : represents the current Event.target value
     *
     */

    const handleInputChange = (value: string): void => {
        setSearch(value)
        if (suggestions.length && value.length) {
            showSuggestion()
        } else {
            hideSuggestion()
        }
        onAutocompleteChange(value)
    }

    useEffect(() => {
        !suggestions.length ? hideSuggestion() : showSuggestion()
    }, [suggestions])

    useEffect(() => {
        setSearch(selectedAddress)
        hideSuggestion()
    }, [selectedAddress])

    useClickOutsideClose(searchContainer, hideSuggestion, isVisible, true)

    /**
     * @method scrollIntoView  : function used for scrolling the element in the visisble view whild handling focusing via keyboard
     *  @param {number} position : represent the offsetTop
     *
     */

    const scrollIntoView = (position: number): void => {
        const searchElem = searchResultRef.current as HTMLElement
        if (searchElem) {
            searchElem.scrollTo({
                top: position,
                behavior: 'smooth',
            })
        }
    }

    useEffect(() => {
        if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
            return
        }

        const listItems = Array.from(searchResultRef.current.children)
        listItems[cursor] && scrollIntoView((listItems[cursor] as HTMLElement).offsetTop)
    }, [suggestions.length, cursor])

    /**
     * @method footerImageSection  : function used to display Footer Image for Autocomplete
     *   * @returns {JSX.Element}
     */

    const footerImageSection = (): JSX.Element => {
        return (
            isVisible && (
                <span className={`${PREFIX}-autocomplete-container__footer-post`}>
                    <img src={imageUrl} alt={imageAltText} />
                </span>
            )
        )
    }
    return (
        <>
            <div className={`${PREFIX}-autocomplete-container`} ref={searchContainer}>
                <div className="sr-only">
                    <div aria-atomic="true" aria-live="polite" role="status">
                        {isVisible && suggestionsList && suggestionsList.length}{' '}
                        {isVisible && a11ySuggestionResultAvailable}
                    </div>
                </div>
                <div className={`${PREFIX}-autocomplete-container__text`}>
                    <TextInput
                        id={id}
                        label={label}
                        value={search}
                        onChange={handleInputChange}
                        maxLength={maxLength}
                        onKeyDown={keyboardNavigation}
                        error={error}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                        onFocus={ev => onFocusHandler && onFocusHandler(ev)}
                    />
                    {showSearchIcon && (
                        <button
                            className={`${PREFIX}-autocomplete-container__search-btn`}
                            onClick={() => searchCallBack(selectedAddress)}
                            aria-label={a11ySearchIconLabel}>
                            <Icon type="ct-search" size="md" />
                        </button>
                    )}
                </div>
                <div
                    className={`${PREFIX}-autocomplete-container__search-result ${
                        !isVisible ? `${PREFIX}-autocomplete-container__invisible` : ''
                    }`}>
                    <span>
                        <ul ref={searchResultRef} id="scroll-list">
                            {suggestionsList &&
                                suggestionsList.length &&
                                suggestionsList.map((item, index) => {
                                    return (
                                        <AutoCompleteItem
                                            key={index}
                                            onSelectItem={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                event.preventDefault()
                                                onListItemClick(item)
                                            }}
                                            isHighlighted={cursor === index}
                                            item={item}
                                            renderItem={renderItem}
                                        />
                                    )
                                })}
                        </ul>
                        {footerImageSection()}
                    </span>
                </div>
            </div>
        </>
    )
}

Autocomplete.propTypes = {
    suggestions: PropTypes.array,
    onSelect: PropTypes.func,
    onAutocompleteChange: PropTypes.func,
    label: PropTypes.string,
    language: PropTypes.string,
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    imageAltText: PropTypes.string,
    renderItem: PropTypes.func,
    setRegionDropdownReset: PropTypes.func,
    autocompleteText: PropTypes.string,
    error: PropTypes.string,
    onBlur: PropTypes.func,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    showSearchIcon: PropTypes.bool,
    searchCallBack: PropTypes.func,
    selectedAddress: PropTypes.string,
    a11ySearchIconLabel: PropTypes.string,
}

export default Autocomplete
