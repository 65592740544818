export const offerEndDateRegex = /^(\d{2})(\d{2})(\d{4})$/
export const regexPatterns = {
    firstLetters: /(^\w)|(\s+\w)/g,
}
export const allWhitespace = /\s/g

/* Regex patterns for input fields used in checkout screen */
export const checkoutRegexPatterns = {
    contactNumber: '[0-9-]*',
    ctMoneyAmount: '[0-9$,.\\s]*',
    giftCardNumberAndPin: '[0-9\\s]*',
}

export const numberRegEx = '[0-9]+'
export const inputFieldInvalidCharactersRegEx = /\.|,|\s/g
