import { getProvider } from '../providers'
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'

const provider = getProvider()

const loginRegisAnalytics = (() => {
    /**
     * function to send the Error analytics data
     * @param {string} sentLocation - location ti send
     * @param {string} errorMessage - Error message to send
     * @return {AnalyticsRecordInterface} - analytics data for Error events
     */
    const _errorEvents = (sentLocation: string, errorMessage: string): AnalyticsRecordInterface => {
        const {
            event,
            eventParameters: { action, category },
        } = analyticsAttributes
        return {
            event: event.error,
            eventParameters: {
                action: action.viewError,
                category: category.account,
                label: errorMessage,
                location: sentLocation,
            },
        }
    }

    /**
     * function to send interaction analytics in login registration flow
     *
     * @param {string} actionName - action triggered
     * @param {string} sentLocation - location triggered
     * @param {string} label - label for interaction
     * @param {string} emailPreferences - emailPreferences opted in or out
     * @return {AnalyticsRecordInterface} - analytics data for interaction event
     */
    const _interactionEvents = (
        actionName: string,
        sentLocation: string,
        label?: string,
        emailPreferences?: boolean,
    ): AnalyticsRecordInterface => {
        const {
            event,
            eventParameters: { category },
        } = analyticsAttributes
        const interactionData: AnalyticsRecordInterface = {
            event: event.interaction,
            eventParameters: {
                action: actionName,
                category: category.account,
                label: label || '',
                location: sentLocation,
                value: '',
            },
        }
        return emailPreferences !== undefined
            ? {
                  ...interactionData,
                  user: {
                      emailPreferences: emailPreferences ? 'opt-in' : 'opt-out',
                  },
              }
            : interactionData
    }

    /**
     * function to send interaction analytics in login flow
     *
     * @param {string} category - category of interaction event
     * @param {string} label - label for interaction
     * @return {AnalyticsRecordInterface} - analytics data for interaction event
     */
    const _signInInteractionEvents = (category: string, label: string): AnalyticsRecordInterface => {
        const {
            event,
            eventParameters: { action },
        } = analyticsAttributes
        return {
            event: event.interaction,
            eventParameters: {
                action: action.loginModalLabel,
                category: category,
                label: label,
                value: '',
            },
        }
    }

    /**
     * function to send signin analytics in login flow
     *
     * @param {string} actionName - action triggered
     * @param {string} label - Label for login remember
     * @param {string} sentLocation - loin modal/ login page
     * @return {AnalyticsRecordInterface} - analytics data for interaction event
     */
    const _signInEvents = (actionName: string, label: string, sentLocation: string): AnalyticsRecordInterface => {
        const {
            event,
            eventParameters: { category },
        } = analyticsAttributes
        return {
            event: event.signIn,
            eventParameters: {
                action: actionName,
                category: category.account,
                label: label,
                value: '',
                location: sentLocation,
            },
        }
    }

    return {
        loginRegisErrorEvents: (sentLocation: string, errorMessage: string) => {
            provider.push(_errorEvents(sentLocation, errorMessage))
        },
        interactionAnalytics: (
            actionName: string,
            sentLocation: string,
            label?: string,
            emailPreferences?: boolean,
        ) => {
            provider.push(_interactionEvents(actionName, sentLocation, label, emailPreferences))
        },
        signInInteractionAnalytics: (category: string, label: string) => {
            provider.push(_signInInteractionEvents(category, label))
        },
        signInAnalytics: (actionName: string, label: string, sentLocation: string) => {
            provider.push(_signInEvents(actionName, label, sentLocation))
        },
    }
})()

export default loginRegisAnalytics
