/**
 ** Interface for DynamicVehicleSeoCopyBlock component
 * @interface
 */
export interface DynamicVehicleSeoCopyBlockProps {
    makeHeadline: string
    makeText: string
    makeModelHeadline: string
    makeModelText: string
    yearMakeModelHeadline: string
    yearMakeModelText: string
    viewMoreLabel: string
    viewLessLabel: string
}

export interface HeadlineProps {
    headline: string
}

export interface DynamicVehicleSeoCopyBlockButtonProps {
    onClick: () => void
    label: string
    modifierClass: string
    isExpanded: boolean
}

export interface ButtonIconProps {
    iconType: string
}

export enum COMPONENT_MODE {
    YEAR_MAKE_MODEL = 'yearmakemodel',
    MAKE_MODEL = 'makemodel',
    MAKE = 'make',
}

export type PreselectedVehicleValue = {
    key: string
    value: string
}

export type DataObject = {
    headline: string
    text: string
}
