import { StoreWithAvailability } from '../../redux/models/storeDetails.interface'

/**
 * Function to set CTC customer context
 *
 * @param {StoreWithAvailability} store
 */
const setContextHubStoreData = (store: StoreWithAvailability): void => {
    if (window.ContextHub && typeof window.ContextHub.getStore === 'function') {
        const ctcCustomerContext = window.ContextHub.getStore('ctcstorecontext')

        if (
            ctcCustomerContext &&
            typeof ctcCustomerContext.loadCTCProfile === 'function' &&
            store.id &&
            store?.address?.region?.isocode
        ) {
            ctcCustomerContext.loadCTCProfile(String(store.id), String(store.address.region.isocode), false)
        }
    }
}

export { setContextHubStoreData }
export default setContextHubStoreData
