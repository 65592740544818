import { ProductCardType } from '../../redux/models/recommendations.interface'
import { getProvider } from '../providers/index'
import { getProductAnalyticsItem, getProductAnalyticsClickItem } from './frequentlyBoughtTogetherAnalytics'
import { constantValues } from '../../components/ProductGridView/ProductCard.constant'

export const recommendationAnalytics = (
    event: string,
    recommendationsData: ProductCardType[],
    title: string,
    recommendationType?: string,
    productIndex?: number,
): void => {
    const productList =
        productIndex && productIndex >= 0
            ? getProductAnalyticsClickItem(
                  recommendationsData,
                  productIndex + constantValues.positionIncrementValue,
                  title,
                  recommendationType,
              )
            : getProductAnalyticsItem(recommendationsData, title, recommendationType)

    getProvider().push({
        event: event,
        products: productList,
    })
}
