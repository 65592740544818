/**
 * @returns {string} getPageTitle()
 */

export const getPageTitle = (): string => {
    let pageTitle = ''
    const metaDetails = document.querySelector("meta[name='title']")
    if (!!metaDetails) {
        pageTitle = metaDetails.getAttribute('content') || ''
    }
    return pageTitle || document.title
}
