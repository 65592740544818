import React, { useEffect } from 'react'

/**
 * This hook is created to handle the click outside of the component and it's children.
 * This is a smaller version on the useOnClickOutsideClose (which you can find in the Tooltip directory).
 * It is recommended to use this variant if you don't need useOnClickOutsideClose full functionality.
 * @param {React.MutableRefObject} ref - ref to any element
 * @param {function} handler - handler
 */
export const useOnClickOutside = (ref: React.MutableRefObject<Element>, handler: (event: MouseEvent) => void): void => {
    useEffect(() => {
        const listener = (event: MouseEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref?.current || ref?.current?.contains(event.target as Node)) return
            handler(event)
        }
        document.addEventListener('mousedown', listener)

        return () => document.removeEventListener('mousedown', listener)
    }, [ref, handler])
}
