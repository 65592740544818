import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { SessionSuffixTypes, signifydSessionInfoProps } from './signifydinit.type'
import { magicNumber } from '@nl/lib'
import { SignifydService } from '../../services/signifydService'
import { sessionLife } from '../../services/signifydService/signifyd.constants'
import { useSelector } from 'react-redux'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
export const SignifydInit: React.FC<signifydSessionInfoProps> = ({ ...props }) => {
    props.sessionLifeTime = sessionLife.sessionLifeMins
    const signifydservice = new SignifydService()
    const lifeTimeInMinutes = props.sessionLifeTime
    const isEnabled = true
    const { featureFlag } = useSelector(commonContentAvailableSelector)
    let signifydScripts: HTMLElement[] = signifydservice.profiling({ ...props }, featureFlag?.enableSignifydIframeTag)
    setInterval(() => {
        signifydScripts = signifydservice.profiling({ ...props }, featureFlag?.enableSignifydIframeTag)
    }, lifeTimeInMinutes * (magicNumber.SIXTY as number) * (magicNumber.THOUSAND as number))
    useEffect(() => {
        const signifydComp = document.querySelector('head')
        if (isEnabled && featureFlag) {
            signifydScripts.forEach((scriptType: HTMLElement) => {
                signifydComp?.append(scriptType)
            })
        }
    }, [isEnabled, featureFlag, signifydScripts])
    return null
}
SignifydInit.propTypes = {
    signifydJsUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    sessionIdSuffixType: PropTypes.oneOf([
        SessionSuffixTypes.ALPHANUMERIC,
        SessionSuffixTypes.ASCII_PRINTABLE,
        SessionSuffixTypes.BASE_64,
        SessionSuffixTypes.DISTINGUISHABLE,
        SessionSuffixTypes.HEX,
        SessionSuffixTypes.NUMERIC,
        SessionSuffixTypes.URL_SAFE,
    ]).isRequired,
    sessionIdSuffixLength: PropTypes.number.isRequired,
    sessionPrefix: PropTypes.string.isRequired,
    sessionLifeTime: PropTypes.number,
    type: PropTypes.string.isRequired,
}
