import { ActionType, getType } from 'typesafe-actions'

import { RegisterFormType, ErrorResponse, CountriesResponse } from '../models/loyaltyRegisterForm.interface'
import { LinkLoyaltyCardRequest } from '../models/linkLoyaltyCard.interface'
import {
    fetchRegions,
    fetchRegionsErrorAction,
    setRegisterFormAction,
    fetchCountries,
    fetchCountriesErrorAction,
} from '../actionCreators'
import * as actions from '../actionCreators/loyaltyRegisterForm.actionCreators'

type Action = ActionType<typeof actions>

export interface registerFormState {
    regionsData: RegisterFormType
    countriesData: CountriesResponse
    errorResponse: ErrorResponse
    registerFormValues: LinkLoyaltyCardRequest
}

export const initialState: registerFormState = {
    regionsData: { regions: [] } as unknown as RegisterFormType,
    countriesData: [] as unknown as CountriesResponse,
    errorResponse: {} as unknown as ErrorResponse,
    registerFormValues: {} as LinkLoyaltyCardRequest,
}

export const registerFormReducer = (state: registerFormState = initialState, action: Action): registerFormState => {
    switch (action.type) {
        case getType(fetchRegions): {
            return { ...state, regionsData: action.payload }
        }
        case getType(fetchRegionsErrorAction): {
            return { ...state, errorResponse: action.payload }
        }
        case getType(fetchCountries): {
            return { ...state, countriesData: action.payload }
        }
        case getType(fetchCountriesErrorAction): {
            return { ...state, errorResponse: action.payload }
        }
        case getType(setRegisterFormAction): {
            return { ...state, registerFormValues: action.payload }
        }
        default:
            return state
    }
}
