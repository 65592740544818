import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { roadRatingProps, ratingObject } from './RoadRating.type'
import { RoadRatingsImageSize } from '../../globalConstants/global.constant'

/**
 * RoadRating component
 * @return {JSX.Element} returns RoadRating component
 */

const RoadRating: React.FC<roadRatingProps> = ({ ...props }): JSX.Element => {
    const { ratings, aggregateValue, title, roadRatingDescription, roadRatedLabel } = props
    return (
        <div id="road-rating" className={`${PREFIX}-section ${PREFIX}-road-rating`}>
            <h2 className={`${PREFIX}-road-rating__heading`} id="roadRatingHeading">
                {title}
            </h2>
            <div className={`${PREFIX}-road-rating__description-container`}>
                <div className={`${PREFIX}-road-rating__aggregate-icon`}>{aggregateValue}</div>
                <div className={`${PREFIX}-road-rating__bar-and-labels`}>
                    <h3 className={`${PREFIX}-road-rating__subheader`}>
                        {roadRatedLabel} {aggregateValue}
                    </h3>
                    <div
                        className={`${PREFIX}-road-rating__description`}
                        dangerouslySetInnerHTML={{ __html: roadRatingDescription }}
                    />
                </div>
            </div>
            <div>
                {ratings instanceof Array &&
                    ratings.map(rating => {
                        return renderRating(rating)
                    })}
            </div>
        </div>
    )
}

const renderRating = (rating: ratingObject) => {
    return (
        <div className={`${PREFIX}-road-rating__rating-container`} key={`${rating.roadRatedID}`}>
            <div className={`${PREFIX}-road-rating__icon-container`}>
                <img
                    className={`${PREFIX}-road-rating__icon`}
                    alt="icon"
                    src={`${rating.image}`}
                    style={{ color: rating.color }}
                    width={RoadRatingsImageSize.width}
                    height={RoadRatingsImageSize.height}
                />
            </div>
            <div className={`${PREFIX}-road-rating__bar-and-labels`}>
                <div className={`${PREFIX}-road-rating__labels`}>
                    <div className={`${PREFIX}-road-rating__type-label`}>{rating.label}</div>
                    <div className={`${PREFIX}-road-rating__value-label`}>{rating.value}</div>
                </div>
                <div className={`${PREFIX}-road-rating__bar-container`}>
                    <div
                        className={`${PREFIX}-road-rating__bar-filler`}
                        style={{ backgroundColor: rating.color, width: `${rating.value}%` }}></div>
                </div>
            </div>
        </div>
    )
}

RoadRating.propTypes = {
    ratings: PropTypes.any,
    aggregateValue: PropTypes.number,
    title: PropTypes.string,
    roadRatingDescription: PropTypes.string,
    roadRatedLabel: PropTypes.string,
}

export default RoadRating
