import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Tooltip from '../Tooltip'
import { PREFIX } from '../config'
import { RebateProps } from './Rebate.type'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'

const Rebate: React.FC<RebateProps> = ({ ...props }) => {
    const { title, greyCopyMsg, pdfLink, tooltipMessage, a11yTooltipIcon, hideGreyCopyMessage, ariaHidden } = props
    const [rebateVisibility, setRebateVisibility] = useState(false)
    const buttonRebateRef = useRef()

    const rebateClass = `${PREFIX}-rebate`

    /**
     * This function sets rebate tooltip details
     */
    const showRebateTooltip = (): void => {
        setRebateVisibility(!rebateVisibility)
    }

    /**
     * To display tooltip with links
     *
     * @param { string } tooltipDesc - tooltip text
     * @return { JSX.Element } - returns tooltip element
     */
    const toolTipText = (tooltipDesc: string): JSX.Element => {
        return (
            <SanitizeStringContentWrapper stringContent={tooltipDesc}>
                {memoizedStringContent => (
                    <div data-testid="tooltip-text" dangerouslySetInnerHTML={{ __html: memoizedStringContent }}></div>
                )}
            </SanitizeStringContentWrapper>
        )
    }

    /**
     * Stop Propagation On Click Of Grey Copy Link
     * @param { React.MouseEvent } event
     */
    const greyCopyClicked = (event: React.MouseEvent): void => {
        event.stopPropagation()
    }

    return (
        <div className={`${rebateClass}__wrapper`} aria-hidden={ariaHidden ? ariaHidden : false}>
            <div className={`${rebateClass}__section`}>
                <Icon type={'ct-offer'} size="sm" />
                <div className={`${rebateClass}-header`}>
                    {title}
                    {tooltipMessage && (
                        <>
                            <button
                                ref={buttonRebateRef}
                                className={`${rebateClass}-icon`}
                                onClick={showRebateTooltip}
                                data-testid="rebate-tooltip-btn"
                                aria-label={a11yTooltipIcon}>
                                <Icon type="ct-information-details" title={`${tooltipMessage}`} size="sm" />
                            </button>
                            {!!rebateVisibility && (
                                <Tooltip
                                    visibility={rebateVisibility}
                                    setVisibility={setRebateVisibility}
                                    iconID="ct-close"
                                    headerText={title}
                                    bodyText={toolTipText(tooltipMessage)}
                                    coords={buttonRebateRef.current as unknown as HTMLElement}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            {!hideGreyCopyMessage && greyCopyMsg ? (
                pdfLink ? (
                    <div className={`${rebateClass}__grey-copy-msg`}>
                        <SanitizeStringContentWrapper stringContent={pdfLink}>
                            {memoizedStringContent => (
                                <a
                                    className={`${rebateClass}__grey-copy-msg--link`}
                                    href={memoizedStringContent}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={(event: React.MouseEvent) => greyCopyClicked(event)}>
                                    {greyCopyMsg}
                                </a>
                            )}
                        </SanitizeStringContentWrapper>
                    </div>
                ) : (
                    <div className={`${rebateClass}__grey-copy-msg`}>{greyCopyMsg}</div>
                )
            ) : null}
        </div>
    )
}

Rebate.propTypes = {
    title: PropTypes.string.isRequired,
    tooltipMessage: PropTypes.string,
    index: PropTypes.number,
    a11yTooltipIcon: PropTypes.string,
    greyCopyMsg: PropTypes.string,
    rebateIcon: PropTypes.string,
    pdfLink: PropTypes.string,
    hideGreyCopyMessage: PropTypes.bool,
    ariaHidden: PropTypes.bool,
}

export default Rebate
