import { Dispatch } from 'redux'
import qs, { ParsedUrlQueryInput } from 'querystring'

import {
    contactUsSuccessAction,
    contactUsErrorAction,
    resetContactUsActionVariables,
} from '../actionCreators/contactUs.actionCreators'
import { ContactUsFormDetailsType } from '../../components/ContactUsForm/ContactUsForm.type'
import { ContactUsErrorResponse } from '../models/contactUs.interface'
import { contactUsService } from '../../services/contactUsService/contactus.service'

/**
 * post contact us api
 * @param {ContactUsFormDetailsType} requestPayload
 * @return {Promise}
 */
export const postContactUs =
    (requestPayload: ContactUsFormDetailsType) =>
    (dispatch: Dispatch): Promise<void> => {
        return contactUsService
            .postContactUs(qs.stringify(requestPayload as unknown as ParsedUrlQueryInput))
            .then(() => {
                dispatch(contactUsSuccessAction())
            })
            .catch((error: Record<string, ContactUsErrorResponse>) => {
                dispatch(contactUsErrorAction(error.response))
            })
    }

export const resetContactUsAction =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetContactUsActionVariables())
    }
