import { createAction } from 'typesafe-actions'

import { EstimateFees, ErrorResponse } from '../models/estimateFees.interface'
import {
    GET_ESTIMATE_FEES_DATA_SUCCESS,
    GET_ESTIMATE_FEES_DATA_ERROR,
    RESET_ESTIMATE_FEES,
} from '../types/estimateFees/estimateFees.actionTypes.constant'

export const fetchEstimateFeesAction = createAction(GET_ESTIMATE_FEES_DATA_SUCCESS)<EstimateFees>()

export const estimateFeesFailureAction = createAction(GET_ESTIMATE_FEES_DATA_ERROR)<ErrorResponse>()

export const resetEstimateFeesAction = createAction(RESET_ESTIMATE_FEES)()
