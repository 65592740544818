import localStorageService from '../utils/localStorageService'
import getAOAWorkflowType, { getAOAWorkFlowQueryParams } from './aoaWorkflow.helper'

/**
 * This helper will provide a string joining by aoaWorkflowType with its respective query param
 * @return {string}
 */
export const createSuffix = (): string => {
    const workflowType = getAOAWorkflowType()
    const { orderReferenceNumber, documentTokenId } = getAOAWorkFlowQueryParams() || {}
    return [workflowType, orderReferenceNumber, documentTokenId].filter(Boolean).join('_')
}

/**
 * This is wrapper for  localStorage and it will append suffix to the keys based on the different work flows
 * @param {function} suffixCreator
 * @return {Partial<Storage>}
 */
export const aoaLocalStorageService = (suffixCreator: () => string) => {
    const factorySuffix = suffixCreator()
    const createKey = (key: string) => `${key}_${factorySuffix}`
    return {
        getItem(key: string) {
            return localStorageService.getItem(createKey(key))
        },
        setItem(key: string, value: string) {
            return localStorageService.setItem(createKey(key), value)
        },
        removeItem(key: string) {
            return localStorageService.removeItem(createKey(key))
        },
        clear() {
            return localStorageService.clear()
        },
    }
}
export default aoaLocalStorageService(createSuffix)
