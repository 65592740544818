export const timeoutErrorCode = 'errorG001'
export const globalPartialAuthCode = '00033'
export const netWorkErrorCode = 'Network Error'
export const maxMindErrorCode = '00114'
export const maxMindErrorCodeCityNotFound = '00115'
export const tokenMissedErrorCode = '00021'
export const invalidTokenErrorCode = '00027'
export const gigyaTokenMissedErrorCode = '00035'
export const cartLimitItemsErrorCode = '00177'
export const hybAuthErrorCode = '713'
