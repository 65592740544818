/**
 * @class {InjectStoreInInterceptor}
 */
class InjectStoreInInterceptor {
    store: Record<string, unknown>

    /**
     * @constructor
     * @param {Record<string, unknown>} store
     */
    constructor(store: Record<string, unknown>) {
        this.store = store
    }

    /**
     * This function is used to set store value
     * @param {Record<string, unknown>} _store
     */
    setStoreValue(_store: Record<string, unknown>) {
        this.store = _store
    }

    /**
     * This function is used to get store value
     * @return {Record<string, unknown>} store
     */
    getStoreValue() {
        return this.store
    }
}

const injectStoreInInterceptor = new InjectStoreInInterceptor({})

export default injectStoreInInterceptor
