import { getType, ActionType } from 'typesafe-actions'

import { setIsReviewSectionButtonClicked, setIsQuestionSectionButtonClicked } from '../actionCreators'
import * as actions from '../actionCreators/BVSection.actionCreators'

type Action = ActionType<typeof actions>

export interface BVSectionState {
    isReviewClicked: boolean
    isQuestionClicked: boolean
}

export const initialBVSectionState: BVSectionState = {
    isReviewClicked: false,
    isQuestionClicked: false,
}

export const BVSectionReducer = (state: BVSectionState = initialBVSectionState, action: Action): BVSectionState => {
    switch (action.type) {
        case getType(setIsReviewSectionButtonClicked): {
            return { ...state, isReviewClicked: action.payload }
        }
        case getType(setIsQuestionSectionButtonClicked): {
            return { ...state, isQuestionClicked: action.payload }
        }
        default:
            return state
    }
}
