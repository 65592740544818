/* eslint no-invalid-this: 0 */

import { ActionType, MenuAccessibilityInterface } from './MenuAccessibility.type'
import { menuConstantsObject } from './MenuAccessibility.constant'
import { keyCodes } from '../keyCodes'

/**
 * Menu Accessibility Implementation
 *
 * Implemented as per https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html
 *
 * @constructor MenuAccessibility
 * @implements {MenuAccessibilityInterface}
 *
 */
export class MenuAccessibility implements MenuAccessibilityInterface {
    /**
     * Base constructor
     * @param {string} menuId - div element which holds the menu items.
     */
    constructor(public menuId: string | HTMLElement) {
        this.menuId = menuId
    }

    // Initializing the variables.
    firstItem = null
    lastItem = null
    menuRef = typeof this.menuId === 'string' ? document.getElementById(this.menuId) : this.menuId
    interactiveElements: NodeListOf<Element> = this.menuRef?.querySelectorAll('a,button')
    menuItems = this.interactiveElements && Array.from(this.interactiveElements)

    /**
     * @param {ActionType} actionType - "SET" or "REMOVE"
     */
    private modifyTabIndex = (actionType?: ActionType): void => {
        this.menuItems &&
            this.menuItems.forEach((element: Element) => {
                actionType === ActionType.SET
                    ? element.setAttribute('tabindex', '-1')
                    : element.removeAttribute('tabindex')
            })
    }

    /**
     * Add tab index = -1 to the menu items.
     */
    private disableTabFocus = (): void => {
        this.modifyTabIndex(ActionType.SET)
    }

    /**
     * Set the focus to the previous Item.
     * @param {Element} currentItem - Current focused element.
     * @param {ActionType} actionType - if prev, pick the previous elements and vice versa.
     */
    private setFocusToMenuItem = (currentItem: Element, actionType?: ActionType): void => {
        // prevStep is true when down arrow is pressed.
        const prevStep: boolean = actionType === ActionType.NEXT

        // Based on the arrows do addition or subtraction on the index to iterate among the items.
        const addOrSubtract = prevStep ? menuConstantsObject.unit_one : menuConstantsObject.negative_unit_one

        /**
         * If condition - If down arrow and u reached the end of the items, set the focus to first item and vice versa.
         * Else condition - otherwise move up and down.
         */
        if (currentItem === (prevStep ? this.lastItem : this.firstItem)) {
            prevStep ? (this.firstItem as HTMLElement).focus() : (this.lastItem as HTMLElement).focus() // if down arrow set focus to first or vice versa.
        } else {
            const index = this.menuItems.indexOf(currentItem) // get the index of the current item in the menu items array.
            const itemToFocus = this.menuItems[index + addOrSubtract] as HTMLElement // Get the up or down item based on the index.
            itemToFocus.focus() // Focus the current Element.
        }
    }

    /**
     * Add accessibility to the menu dropdown
     * @param {KeyboardEvent} event - current item.
     */
    private addKeyBoardAccessibility = (event: KeyboardEvent): void => {
        switch (event.keyCode) {
            case keyCodes['upArrow']:
                this.setFocusToMenuItem(event.target as Element, ActionType.PREVIOUS)
                break
            case keyCodes['downArrow']:
                event.stopPropagation()
                event.preventDefault()
                this.setFocusToMenuItem(event.target as Element, ActionType.NEXT)
                break
            case keyCodes['rightArrow']:
            case keyCodes['leftArrow']:
                event.stopPropagation()
                event.preventDefault()
                break
            default:
                break
        }
    }

    /**
     * On load set the first item and last item and add keyboard listener.
     * @param {boolean} selectLastItem - param is used when user press up arrow on the account button. Behavior - last item is selected.
     */
    public init = (selectLastItem?: boolean): void => {
        const menuItem = this.interactiveElements && Array.from(this.interactiveElements)
        if (menuItem && menuItem.length > 0) {
            this.firstItem = menuItem[0] // Set the first item in the menu
            this.lastItem = menuItem[menuItem.length - menuConstantsObject.unit_one] // Get the last item in the menu
        }
        this.disableTabFocus() // Disable the tab functionality
        /**
         * if user press on the up arrow, the selected last item will be true and the user will start from last item
         * The below is implemented as per the w3 accessibility rules.
         */
        selectLastItem ? (this.lastItem as HTMLElement)?.focus() : (this.firstItem as HTMLElement)?.focus()
        this.menuRef && this.menuRef.addEventListener('keydown', this.addKeyBoardAccessibility) // Event listener to listen for down and up arrows.
    }

    /**
     * Remove all initiations.
     */
    public destroy = (): void => {
        this.modifyTabIndex(ActionType.REMOVE)
        this.menuRef && this.menuRef.removeEventListener('keydown', this.addKeyBoardAccessibility) // Remove added event listener.
    }
}
