import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/orderHistory.actionCreators'
import { OrderHistory, ErrorResponse } from '../models/orderHistory.interface'
import {
    fetchOrderHistoryAction,
    hideRecommendation,
    orderHistoryFailure,
} from '../actionCreators/orderHistory.actionCreators'

type Action = ActionType<typeof actions>

export interface orderHistoryState {
    orderHistoryData: OrderHistory | null
    hideRecommendation: boolean
    orderHistoryFailure: ErrorResponse | null
}

export const initialState: orderHistoryState = {
    orderHistoryData: null,
    hideRecommendation: false,
    orderHistoryFailure: null,
}

export const orderHistoryReducer = (state: orderHistoryState = initialState, action: Action): orderHistoryState => {
    switch (action.type) {
        case getType(fetchOrderHistoryAction): {
            return { ...state, orderHistoryData: action.payload }
        }
        case getType(hideRecommendation): {
            return { ...state, hideRecommendation: action.payload }
        }
        case getType(orderHistoryFailure): {
            return { ...state, orderHistoryFailure: action.payload }
        }
        default:
            return state
    }
}
