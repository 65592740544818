import { createAction } from 'typesafe-actions'

import {
    ANALYTICS_COMMON_INTERACTION,
    ANALYTICS_CLEAR_COMMON_INTERACTION,
} from '../../analytics/components/React/redux/actionTypes'

import { AnalyticsRecordInterface } from '../../analytics/providers/provider.type'

export const analyticsInteractionCreator = createAction(
    ANALYTICS_COMMON_INTERACTION,
    (payload: AnalyticsRecordInterface) => payload,
)<AnalyticsRecordInterface>()

export const analyticsClearInteractionCreator = createAction(
    ANALYTICS_CLEAR_COMMON_INTERACTION,
    (payload: undefined) => payload,
)<undefined>()
