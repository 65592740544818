/**
 * AOABookingForm helper file
 */

import differenceBy from 'lodash/differenceBy'
import { BookingFormProps, DateTimeViewProps } from './AOABookingForm.type'
import { StepItemType } from './AOAProgressBarContainer/AOAProgressBarContainer.type'
import {
    aoaInApp,
    aoaInAppMake,
    aoaInAppModel,
    aoaInAppYear,
    AOA_CONTACT_INFO_DATA,
    defaultSidePadding,
    eTireSidePadding,
    StepsQueue,
} from './AOABookingForm.constants'
import {
    AOA_ADDITIONAL_COMMENTS,
    AOA_PREVIOUSLY_SELECTED_SERVICES,
    AOA_SELECTED_SERVICES,
    AOA_SERVICE_CODE,
} from './AOAServiceView/AOAServiceView.constants'
import { AOA_DATE_TIME_DATA } from './AOADateTimeStep/AOADateTimeStep.constants'
import { AOADateTimeInfoState } from '../../redux/models/aoaBookingForm.interface'
import { AOASelectedService } from './AOAServiceView/AOAServiceView.type'
import store from '../../store'
import { clearAOADateTimeData, clearPreviouslySelectedServicesAction } from '../../redux/actionCreators'
import { clearAOADateSlotsAction } from '../../redux/actionCreators/aoaDateTimeStep.actionCreators'
import getAOAWorkflowType, { AOAWorkflowType } from '../../helpers/aoaWorkflow.helper'
import aoaLocalStorageService from '../../helpers/aoaLocalStorageService.helper'
import getQueryString from '../../utils/getQueryString'

const aoaWorflowType = getAOAWorkflowType()

/**
 * Two way mapping between progress bar index and step index in ETire wofklow
 */
const eTireProgressBarIndexToStepMap: Record<number, number> = {
    0: StepsQueue.ETireServices,
    1: StepsQueue.ETireDate,
}
const eTireProgressBarStepToIndexMap: Record<number, number> = {
    [StepsQueue.ETireServices]: 0,
    [StepsQueue.ETireDate]: 1,
}

/**
 * Returns progress bar index mapped to given step
 * @param {number} index
 * @return {number}
 */
export const getProgressBarIndex = (index: number): number =>
    aoaWorflowType === AOAWorkflowType.ETire && eTireProgressBarStepToIndexMap[index] != null
        ? eTireProgressBarStepToIndexMap[index]
        : index

/**
 * Returns progress bar step mapped to given index
 * @param {number} index
 * @return {number}
 */
export const getStepIndex = (index: number): number =>
    aoaWorflowType === AOAWorkflowType.ETire && eTireProgressBarIndexToStepMap[index] != null
        ? eTireProgressBarIndexToStepMap[index]
        : index

/**
 * Generates step data desired for AOAProgressBarContainer component
 * @param {BookingFormProps} bookingFormProps - Props object received by booking form. It will be used to extract information and generate step data array
 * @param {Array<string>} keys - stepItems array is received based on aoaWorkflowType
 * to extract information and generate step data array
 * @return {Array<StepItemType>}
 */
export const formStepData = (bookingFormProps: BookingFormProps, keys: Array<string>): Array<StepItemType> => {
    const stepData = []

    for (const key in bookingFormProps) {
        if (keys.includes(key)) {
            const item = bookingFormProps[key] as Record<string, string>
            const obj = {
                title: item?.stepTitle,
                icon: item?.stepIcon,
            }
            stepData.push(obj)
        }
    }

    return stepData
}

/**
 * Clears booking form data (contact info, selected services, selected date, time and drop off option info) from local storage.
 * @return {void}
 */
export const clearBookingFormDataFromLocalStorage = (): void => {
    // Clear contact info data
    aoaLocalStorageService.removeItem(AOA_CONTACT_INFO_DATA)

    // Clear selected services data
    aoaLocalStorageService.removeItem(AOA_SELECTED_SERVICES)

    // Clear selected date, time, drop off option data
    aoaLocalStorageService.removeItem(AOA_DATE_TIME_DATA)

    // TODA - Clear current step data

    // Clear Additional Comment from Services
    aoaLocalStorageService.removeItem(AOA_ADDITIONAL_COMMENTS)
}

/**
 * This method validates the selected date, time, dropOff option on DateTime
 * step view and returns appropriate error message.
 * @param {AOADateTimeInfoState} dateTimeInfo - Object containing selected date, time and dropOffOption values
 * @param {DateTimeViewProps} props - DateTime Step view props containing error messages info
 * @return {string} appropriate error message if dateTimeInfo is invalid
 */
export const validateDateTimeView = (dateTimeInfo: AOADateTimeInfoState | null, props: DateTimeViewProps): string => {
    let errorMessage = ''
    if (!dateTimeInfo?.selectedTime && !dateTimeInfo?.selectedDropOffOption) {
        errorMessage = props.defaultDateTimeErrorMessage
    } else if (!dateTimeInfo?.selectedTime) {
        errorMessage = props.availableTimeErrorMessage
    }

    return errorMessage
}

/**
 * verify is there any change in service selection compared to previous selection
 * @param { Array<AOASelectedService> } selectedServices
 * @param { Array<AOASelectedService>} previouslySelectedServices
 * @return  { boolean }
 */
export const isChangeInServiceSelection = (
    selectedServices: Array<AOASelectedService>,
    previouslySelectedServices: Array<AOASelectedService>,
): boolean => {
    // if previous and current selected services length is equal then verify the change in selection
    if (previouslySelectedServices.length === selectedServices.length) {
        return differenceBy(previouslySelectedServices, selectedServices, AOA_SERVICE_CODE).length > 0
    }

    // previous and current selected services length is not equal there must be change in selection
    return true
}

/**
 * Checks if the vehicle data is available
 *  @param {any} vehicle
 * @return {boolean}
 */
export const isVehicleDataAvailable = (vehicle: Record<string, unknown>): boolean => {
    return !!vehicle?.defaultVehicle?.autoAttributes
}

/**
 * verify previouslySelectedServices available and clear dateTimeInfo if any change in service selection
 * @param {Array<AOASelectedService>} previouslySelectedServices
 * @param {Array<AOASelectedService>} selectedServices
 */
export const clearDateStepDataIfSelectionChanged = (
    previouslySelectedServices: Array<AOASelectedService> | undefined,
    selectedServices: Array<AOASelectedService>,
): void => {
    // previouslySelectedServices available compare with selectedServices and clear it from redux and LS
    if (previouslySelectedServices) {
        // If any change in services selection clear dateSlots & dateTimeInfo from redux and LS
        if (isChangeInServiceSelection(selectedServices, previouslySelectedServices)) {
            store.dispatch(clearAOADateTimeData())
            store.dispatch(clearAOADateSlotsAction())
            aoaLocalStorageService.removeItem(AOA_DATE_TIME_DATA)
        }
        store.dispatch(clearPreviouslySelectedServicesAction())
        aoaLocalStorageService.removeItem(AOA_PREVIOUSLY_SELECTED_SERVICES)
    }
}

/**
 * Returns the sidePadding for progress bar based on flow type
 * @param {AOAWorkflowType} aoaWorkflowType
 * @return {number}
 */
export const getProgressBarSidePadding = (aoaWorkflowType: AOAWorkflowType): number => {
    return aoaWorkflowType === AOAWorkflowType.ETire ? eTireSidePadding : defaultSidePadding
}

/**
 * This function is used to return the search object
 * @return { string }
 */
const returnSearchString = (): string => window.location.search

/**
 * Returns inApp query param
 * @return { boolean }
 */
export const returnInAppQueryParam = (): boolean => {
    const searchString = returnSearchString()
    const inAppIdentifier = aoaInApp
    const inAppQueryParam = getQueryString(searchString, inAppIdentifier)
    return inAppQueryParam === 'true'
}

interface inAppVehicleInfoInterface {
    year: string
    make: string
    model: string
}

/**
 * Returns inApp vehicle info object
 * @return { inAppVehicleInfoInterface }
 */
export const returnInAppVehicleInfoObject = (): inAppVehicleInfoInterface => {
    const searchString = returnSearchString()
    const yearIdentifier = aoaInAppYear
    const makeIdentifier = aoaInAppMake
    const modelIdentifier = aoaInAppModel
    const year = getQueryString(searchString, yearIdentifier)
    const make = getQueryString(searchString, makeIdentifier)
    const model = getQueryString(searchString, modelIdentifier)
    return {
        year,
        make,
        model,
    }
}
