import { keyCodes, magicNumber, stringKeyCodes } from '.'
import { RefObject } from 'react'

export const enableFocusLock = (focusLockRef: RefObject<HTMLElement | null>): EventListener => {
    const popup: Element = focusLockRef?.current
    if (!popup) {
        return null
    }
    const focusableElementsSelector = [
        'a[href]',
        'area[href]',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
        'button:not([disabled])',
        'iframe',
        'object',
        'embed',
        '[contenteditable]',
        'section',
    ].join(',')
    const firstElement = popup.querySelectorAll(focusableElementsSelector)[0] as HTMLElement
    firstElement.focus({ preventScroll: true })
    const eventListener = (e: KeyboardEvent) => {
        const content = popup.querySelectorAll(focusableElementsSelector)
        const lastElement = content[content.length - magicNumber.ONE] as HTMLElement
        const isTabPressed = e.key === stringKeyCodes.tab || e.keyCode === keyCodes.tab
        if (!isTabPressed) {
            return
        }

        if (e.shiftKey) {
            if (document.activeElement === firstElement) {
                lastElement.focus()
                e.preventDefault()
            }
        } else {
            if (document.activeElement === lastElement) {
                firstElement.focus()
                e.preventDefault()
            }
        }
    }
    document.addEventListener('keydown', eventListener)
    return eventListener
}

export const disableFocusLock = (e: EventListener | null): void => {
    document.removeEventListener('keydown', e)
}
