import { checkInstanceOfPrice } from '../components/ProductReusableCard/instanceCheckingUtil'
import { Price } from '../components/Price/Price.types'

/**
 * Helper function to calculate savings based on discount
 * @param { Price } original
 * @param { Price } discount
 * @return {string} - returns discount for the user
 */
export const calculateSavings = (original: Price, discount: Price): string => {
    const { value, minPrice, maxPrice } = original || {}
    const { value: discountValue, minPrice: discountMinPrice, maxPrice: discountMaxPrice } = discount || {}

    const percentageMultiplier = 100

    if (value && discountValue) {
        const percentageDifference = (discountValue / value) * percentageMultiplier
        return `${roundToFivePercent(percentageDifference)}%`
    } else if (minPrice && maxPrice && discountMinPrice && discountMaxPrice) {
        const maxPriceDiff = (discountMaxPrice / maxPrice) * percentageMultiplier
        const minPriceDiff = (discountMinPrice / minPrice) * percentageMultiplier
        return `${roundToFivePercent(minPriceDiff)}% - ${roundToFivePercent(maxPriceDiff)}%`
    }
}

/**
 *
 * @param {number} value - number to be rounded off
 * @return {number} - rounded off values
 */
const roundToFivePercent = (value: number): number => {
    const roundOffValue = 5
    /**
     * As per the discussion with BA team-> the round off should be nearest 5%
     *  i.e. 23% -> 20%, 24% -> 20% , 26-27% -> 25%
     */
    return value % roundOffValue >= roundOffValue // value should be 5 to satisfy the above condition
        ? Math.floor(value / roundOffValue) * roundOffValue + roundOffValue
        : Math.floor(value / roundOffValue) * roundOffValue
}

/**
 * Function to get the current price value
 * @param {Price} currentPrice - current price
 * @param {Price} originalPrice - original price
 * @param {string} language - language
 * @return {string} returns price value
 */
export const checkWasCurrentPrice = (currentPrice: Price, originalPrice: Price, language: string): string => {
    return currentPrice && Object.keys(currentPrice).length
        ? checkInstanceOfPrice(currentPrice, language)
        : checkInstanceOfPrice(originalPrice, language)
}

/**
 * To display total price in cart
 *@param {boolean} isMoreQuantityCartPage - current price
 *@param {Price} priceValue - current price
 *@param {Price} currentPrice - current price
 *@param {Price} originalPrice - current price
 *@param {string} language - current price
 *@param {boolean} showDiscountedPrice - flag to display price that does not include discount amount
 *@param {Price} totalPrice - total price value
 *@param {boolean} isZeroPriceShown - is zero price shown
 * @return {string} returns price value
 */

export const checkTotalPriceComponent = (
    isMoreQuantityCartPage: boolean,
    priceValue: Price,
    currentPrice: Price,
    originalPrice: Price,
    language: string,
    showDiscountedPrice?: boolean,
    totalPrice?: Price,
    isZeroPriceShown = false,
): string => {
    return isMoreQuantityCartPage
        ? checkInstanceOfPrice(priceValue, language, undefined, isZeroPriceShown)
        : showDiscountedPrice && isValidPrice(totalPrice)
        ? checkInstanceOfPrice(totalPrice, language, undefined, isZeroPriceShown)
        : currentPrice && Object.keys(currentPrice).length
        ? checkInstanceOfPrice(currentPrice, language, undefined, isZeroPriceShown)
        : checkInstanceOfPrice(originalPrice, language, undefined, isZeroPriceShown)
}

/**
 * To display was and was from label
 *@param {boolean} displayWasLabel
 *@param {Price} originalPrice
 *@param {string} unitPriceLabel
 *@param {string} wasFromLabel
 *@param {string} fromLabel
 *
 * @return {string}
 */
export const getNowWasLabel = (
    displayWasLabel: boolean,
    originalPrice: Price,
    unitPriceLabel: string,
    wasFromLabel: string,
    fromLabel: string,
): string => {
    return displayWasLabel
        ? !originalPrice?.value
            ? wasFromLabel
            : unitPriceLabel
        : !originalPrice?.value
        ? fromLabel
        : ''
}

/**
 * To display save and save from label
 *@param {Price} discountValue
 *@param {string} promotionalPriceLabel
 *@param {string} saveFromLabel
 * @return {string}
 */
export const getSaveLabel = (discountValue: Price, promotionalPriceLabel: string, saveFromLabel: string): string => {
    return !discountValue?.value ? saveFromLabel : promotionalPriceLabel
}

/**
 * Check that price is available to render
 *@param {Price | null | undefined} price
 * @return  {boolean}
 */
export const isValidPrice = (price: Price | null | undefined): boolean => {
    return typeof (price?.value ?? price?.minPrice) === 'number'
}
