import { MagicNumber } from '../../analytics/analytics.type'
import { PREFIX } from '../../config'

/**
 * show edge to edge banner
 */
const showEdgeToEdgeBanner = (): void => {
    const isEdgeBanner = document.querySelectorAll('.edge-to-edge-banner')
    const isMastHeadBanner = document.querySelectorAll('.nl-masthead-banner')

    const isBothMastheadAndEdgeToEadge = isMastHeadBanner.length > 0 && isEdgeBanner.length > MagicNumber.ONE

    if (isEdgeBanner.length > 0) {
        for (let i = 0; i < isEdgeBanner.length; i++) {
            // show edge-to-edge when both masthead and edge-to-edge are present
            if (isBothMastheadAndEdgeToEadge) {
                !isEdgeBanner[i].classList.contains('nl-masthead-banner') &&
                    isEdgeBanner[i].classList.remove(`${PREFIX}-edge-banner__hide`)
            } else isEdgeBanner[i].classList.remove(`${PREFIX}-edge-banner__hide`)
        }
    }
}

export { showEdgeToEdgeBanner }
