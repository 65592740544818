import { Dispatch } from 'redux'

import { orderCancellationSuccess, orderCancellationFailure } from '../actionCreators/orderCancellation.actionCreators'
import { orderCancellationService } from '../../services/cancelOrder/cancelOrder.service'
import { OrderDetails } from '../models/orderDetails.interface'
import { OrderCancellationData } from '../../redux/models/orderCancellation.interface'
import { setShowSpinner } from '../actionCreators/spinner.actionCreators'

export const getOrderCancellationService =
    (
        orderToCancel: OrderCancellationData,
        requestedParams: OrderDetails,
        setShowToast: React.Dispatch<React.SetStateAction<boolean>>,
        setToastSuccess: React.Dispatch<React.SetStateAction<boolean>>,
        refreshData: () => void,
        setShowReasonsModal: React.Dispatch<React.SetStateAction<boolean>>,
        showSpinner = false,
        setShowCancellationPendingStatus?: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner(true))
        orderCancellationService
            .getOrderCancellationData(orderToCancel, requestedParams)
            .then(response => {
                if (setShowCancellationPendingStatus) {
                    setShowCancellationPendingStatus(true)
                }
                setToastSuccess(true)
                refreshData()
                dispatch(orderCancellationSuccess(response))
            })
            .catch(err => {
                setToastSuccess(false)
                dispatch(orderCancellationFailure(err))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
                setShowReasonsModal(false)
                setShowToast(true)
            })
    }
