import { FilteredCartData } from '../redux/models/cart.interface'

/**
 * function to remove PII data from cartItems which saves in localStorage
 * @param {string} storageData
 * @return {string}
 */
export const removeContactInfoFromStorageData = (storageData: string) => {
    let transformedData
    try {
        const cartData = JSON.parse(storageData) as FilteredCartData
        delete cartData.cart.contactInfo
        transformedData = JSON.stringify(cartData)
    } catch (error) {
        transformedData = storageData
    }
    return transformedData
}
