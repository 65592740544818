import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers/index'
import checkNestedProps from '../../utils/checkNestedProps'
import { FilterAnalyticsData, FilterSelected } from '../providers/provider.type'
import cloneDeep from 'lodash/cloneDeep'

/**
 * function to send filter analytics data
 * @param {FilterSelected[]} filtersSelected
 * @param {number} resultsCount
 */
export const filterActionAdvancedAnalytics = (filtersSelected: FilterSelected[], resultsCount: number): void => {
    const fltrArr = cloneDeep(filtersSelected)
    fltrArr.forEach((fltr: FilterSelected) => {
        if (fltr?.type === 'range' || fltr?.type === 'custom_price') {
            fltr.range = []
            fltr.optionsAdded?.map((o: string) => {
                fltr.range?.push({ ge: parseFloat(o.split('-')[0]), le: parseFloat(o.split('-')[1]) })
            })
        }
        fltr.optionsSelected = fltr.optionsSelected.toLowerCase()
        fltr.filterName = fltr.filterName.toLowerCase()
        if (!fltr?.categoryId) {
            delete fltr.categoryId
        }
        delete fltr.optionsAdded
        delete fltr.type
        return fltr
    })

    getProvider().push({
        event: analyticsAttributes.event.filterInteraction,
        productResultsReturned: resultsCount,
        filtersSelected: fltrArr,
    })
}

/**
 * function to update filter analytics data
 * @param {string} filterName
 * @param {string} filterVal
 * @param {string} type
 * @param {string} id
 * @param {number} count
 * @param {FilterAnalyticsData} filterAnalyticsData
 * @param {categoryId} categoryId
 * @return {FilterAnalyticsData} filterAnalyticsData
 */
export const updateFilterAnalyticsObject = (
    filterName: string,
    filterVal: string,
    type: string,
    id: string,
    count: number,
    filterAnalyticsData: FilterAnalyticsData,
    categoryId?: string,
): FilterAnalyticsData => {
    const fltrVal = type === 'range' || type === 'custom_price' ? filterVal.replace(/\$/g, '') : filterVal
    let currentFilterData: FilterSelected = {
        filterName: filterName,
        actionOperator: type === 'range' || type === 'custom_price' ? 'in' : 'eq',
        optionsSelected: filterVal,
        facetId: id,
        optionsAdded: [fltrVal],
        productResults: count,
        type: type,
        categoryId: categoryId,
    }

    if (type === 'rating') {
        currentFilterData.actionOperator = 'ge'
    }

    if (type === 'custom_price') {
        currentFilterData.actionOperator = 'custom'
    }

    if (Object.keys(filterAnalyticsData).includes(filterName) && type !== 'rating') {
        currentFilterData = filterAnalyticsData[filterName]
        currentFilterData.actionOperator = 'in'
        currentFilterData.productResults += count
        currentFilterData.optionsAdded?.push(fltrVal)
        currentFilterData.optionsSelected = currentFilterData?.optionsAdded?.join(', ') as string
        if (!currentFilterData.optionsAdded?.length) {
            delete filterAnalyticsData[filterName]
        }
    } else {
        currentFilterData.optionsSelected = currentFilterData.optionsAdded?.join(', ') as string
        filterAnalyticsData[filterName] = currentFilterData
    }
    return filterAnalyticsData
}

/**
 * function to send filter analytics data
 * When a filter action is selected (ie. either user clicks a category on the dropdown, or clicks a filter item in the filter panel or clicks one of the premium filter options
 * (i.e. in stock at my store or sales item), the below dataLayer event should fire with label returning '<filter category> - <filter name> - enabled'.
 * If the user clears a particular filter by either removing a filter option in the filter panel or clicking remove filter pill the label will return '<filter category> - <filter name> - cleared'.
 * @param {string} filterCategory
 * @param {string}filterName
 * @param {string} filterStatus
 */
export const filterActionAnalytics = (filterCategory: string, filterName: string, filterStatus: string): void => {
    const { eventParameters } = analyticsAttributes
    const analyticsLocation = checkNestedProps(eventParameters, 'location', 'filterPanel') as string

    getProvider().push({
        event: analyticsAttributes.event.facetFilterAction,
        eventParameters: {
            action: checkNestedProps(eventParameters, 'action', 'filterSelected') as string,
            category: getPageType() || undefined,
            label: `${filterCategory} - ${filterName} ${filterStatus}`.trim(),
            value: undefined,
            location: `${getPageType()} - ${analyticsLocation}`,
        },
    })
}

/**
 * function to send filter analytics data when clear all filter button is clicked
 */
export const clearFilterAnalytics = (): void => {
    const { eventParameters } = analyticsAttributes

    getProvider().push({
        event: analyticsAttributes.event.facetFilterAction,
        eventParameters: {
            action: checkNestedProps(eventParameters, 'action', 'clearAllFilter') as string,
            category: getPageType() || undefined,
            label: checkNestedProps(eventParameters, 'labels', 'clearAllFilter') as string,
            value: undefined,
        },
    })
}

/**
 * function to send analytics data when user clicks a filter button on the plp/srp/dlp that opens the filter panel,
 * @param {string} filterButton
 */
export const openFilterActionAnalytics = (filterButton: string): void => {
    const { eventParameters } = analyticsAttributes
    const analyticsLocation = checkNestedProps(eventParameters, 'location', 'filterPanel') as string

    getProvider().push({
        event: analyticsAttributes.event.facetFilterAction,
        eventParameters: {
            action: checkNestedProps(eventParameters, 'action', 'openFilterPanel') as string,
            category: getPageType() || undefined,
            label: filterButton,
            value: undefined,
            location: `${getPageType()} - ${analyticsLocation}`,
        },
    })
}
