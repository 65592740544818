import { ActionType, getType } from 'typesafe-actions'
import { setCommonContentFetchSuccess, setCommonAPIContentSuccess } from '../actionCreators'
import * as actions from '../actionCreators'
import { IGlobalProps, GlobalAPIProps } from '../models/commonContent.interface'

type Action = ActionType<typeof actions>

export interface CommonContentState {
    commonContentAvailable: IGlobalProps
    commonAPIContentAvailable: GlobalAPIProps
    isCommonContentAvailableInit: boolean
}

export const initialState: CommonContentState = {
    commonContentAvailable: {} as IGlobalProps,
    commonAPIContentAvailable: {} as GlobalAPIProps,
    isCommonContentAvailableInit: false,
}

export const commonContentReducer = (state: CommonContentState = initialState, action: Action): CommonContentState => {
    if (action.type === getType(setCommonContentFetchSuccess)) {
        return { ...state, commonContentAvailable: action.payload, isCommonContentAvailableInit: true }
    } else if (action.type === getType(setCommonAPIContentSuccess)) {
        return { ...state, commonAPIContentAvailable: action.payload }
    } else {
        return state
    }
}
