/**
 * props to be used in googleMap
 */
export const googleMapsProps = {
    googleMapsUrl: 'http://maps.google.com?q=',
    defaultMapId: 'storeMap',
    mapZoomValue: 12,
    defaultStoreLocations: [
        {
            name: '',
            latitude: 42.7836,
            longitude: -80.981806,
            storeMarker: '',
        },
    ],
    preferredMarkerVal: 'preferred',
}

const svgMIMEType = 'data:image/svg+xml;utf-8, '

// constant for markerProps
export const markerProps = {
    initialMarkerIndex: 0,
    markerWidth: 36,
    markerHeight: 52,
    markerTypes: {
        defaultMarker: (color: string): string =>
            svgMIMEType +
            encodeURIComponent(
                `<svg id="bead8c54-3539-4f31-99a6-81138e114afb" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 52"><path d="M18,0A18.09,18.09,0,0,0,0,18.18v0C0,31.85,18,52,18,52S36,31.85,36,18.2A18.09,18.09,0,0,0,18,0Z" fill="${color}"/><circle cx="18" cy="18" r="14" fill="#fff"/></svg>`,
            ),
        preferredSelectedMarker: (color: string): string =>
            svgMIMEType +
            encodeURIComponent(
                `<svg id="ff0a47fc-3bb4-47f2-8b75-c3df7c3f23c7" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 52"><path d="M18,0A18.09,18.09,0,0,0,0,18.18v0C0,31.85,18,52,18,52S36,31.85,36,18.2A18.09,18.09,0,0,0,18,0Z" fill="${color}"/><path d="M8,8H28V28H8Z" fill="none"/><path d="M8,8H28V28H8Z" fill="none"/><path d="M18,22.39l3.46,2.09a.83.83,0,0,0,1.14-.27.86.86,0,0,0,.1-.63l-.92-3.93L24.84,17a.83.83,0,0,0,.09-1.17.85.85,0,0,0-.56-.29l-4-.34-1.57-3.72a.83.83,0,0,0-1.1-.43.88.88,0,0,0-.44.43l-1.57,3.71-4,.34a.84.84,0,0,0-.76.9.81.81,0,0,0,.29.56l3.06,2.65-.92,3.93a.82.82,0,0,0,.62,1,.83.83,0,0,0,.62-.09Z" fill="#fff" fill-rule="evenodd"/></svg>`,
            ),
        preferredMarker: (color: string): string =>
            svgMIMEType +
            encodeURIComponent(
                `<svg id="b47c8d03-ac23-4259-a2f8-3d58f75a4a44" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 52"><path d="M18,0A18.09,18.09,0,0,0,0,18.18v0C0,31.85,18,52,18,52S36,31.85,36,18.2A18.09,18.09,0,0,0,18,0Z" fill="${color}"/><circle cx="18" cy="18" r="14" fill="#fff"/><path d="M18,22.39l3.46,2.09a.83.83,0,0,0,1.14-.27.86.86,0,0,0,.1-.63l-.92-3.93L24.84,17a.83.83,0,0,0,.09-1.17.85.85,0,0,0-.56-.29l-4-.34-1.57-3.72a.83.83,0,0,0-1.1-.43.88.88,0,0,0-.44.43l-1.57,3.71-4,.34a.84.84,0,0,0-.76.9.81.81,0,0,0,.29.56l3.06,2.65-.92,3.93a.82.82,0,0,0,.62,1,.83.83,0,0,0,.62-.09Z" fill="${color}" fill-rule="evenodd"/></svg>`,
            ),
        numericMarker: (color: string): string =>
            svgMIMEType +
            encodeURIComponent(
                `<svg id="bead8c54-3539-4f31-99a6-81138e114afb" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 52"><path d="M18,0A18.09,18.09,0,0,0,0,18.18v0C0,31.85,18,52,18,52S36,31.85,36,18.2A18.09,18.09,0,0,0,18,0Z" fill="${color}"/></svg>`,
            ),
    },
    activeMarkerLabelColor: '#fff',
    markerLabelColor: '#008248',
    markerLabelFontSize: '16px',
    markerLabelFontWeight: '800',
    markerLabelXPos: 18,
    markerLabelYPos: 18,
    preferredMarkerVal: 'preferred',
    defaultMarkerColor: 'transparent',
}

// constant for minimumStore
export const minimumStore = 1
