import { PREFIX } from '../config'
import { checkoutSimpleHeaderAEMClassName, primaryNavigationAEMClassName } from './toast.constant'

/**
 * Setting the height of the toast by calculating the height of all the headers and top banners.
 * Assign the calculated height to window object.
 *
 * @param {Array} headers - Headers whom height to be calculated.
 * @return {number}
 */
export const calculateToastHeight = (headers: Array<string>): number => {
    // Initialize the height.
    let height = 0
    // Loop through all the items except elements without display none.
    headers.forEach(el => {
        const topElement = document.body.getElementsByClassName(el)[0]
        topElement &&
            window.getComputedStyle(topElement, null).display !== 'none' &&
            (height = height + topElement.clientHeight)
    })

    return height
}

/**
 * Calculate the height of the headers i.e. primary navigation and secondary navigation.
 *
 * @param {Array} headersOnScroll - Headers whom height to be calculated.
 * @return {number}
 */
export const calculateNavigationHeight = (headersOnScroll: Array<string>): number => {
    let navigationHeight = 0
    headersOnScroll.forEach(element => {
        const topElement = document.getElementsByClassName(element)[0]
        if (topElement && window.getComputedStyle(topElement, null).display !== 'none') {
            navigationHeight = navigationHeight + topElement.getBoundingClientRect().height
        }
    })
    return navigationHeight
}

/**
 * Calculate the default primaryNavigation position i.e. to get the top value set in the scss file.
 *
 * @return {number}
 */
export const calculatePrimaryNavigationTopValue = (): number => {
    const primaryNavigation =
        document.querySelector(`.${primaryNavigationAEMClassName}`) ||
        document.querySelector(`.${checkoutSimpleHeaderAEMClassName}`)
    return primaryNavigation && primaryNavigation.getBoundingClientRect().top | 0
}

/**
 * Calculate the default toast position i.e. to get the top value set in the scss file.
 *
 * @return {number}
 */
export const defaultToastTopPosition = (): number => {
    return document.querySelector(`.${PREFIX}-toast`)?.getBoundingClientRect().top
}
