import React from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import { StaticProductTitleProps } from './StaticProductTitle.types'

/**
 * Static Product Title component
 * @param {StaticProductTitleProps} props - title
 * @return {JSX.Element} returns Static Product Title component
 */
const StaticProductTitle: React.FC<StaticProductTitleProps> = ({ title }): JSX.Element => {
    return (
        <div className={`${PREFIX}-static-title-wrapper`}>
            <div className={`${PREFIX}-product-information-wrapper`}>
                <div className={`${PREFIX}-product`}>
                    <div className={`${PREFIX}-product-title-sku`}>
                        <h1 className={`${PREFIX}-product__title`}>{title}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

StaticProductTitle.propTypes = {
    title: PropTypes.string,
}

export default StaticProductTitle
