import { removeHtmlTagsFromString } from '../removeHtmlTagsFromString'
import { TooltipType, AccessibleType } from './getTooltipText.type'

/**
 * common function to get tooltip props
 * @param {string} title
 * @param {string} desc
 * @param {AccessibleType} accessibility
 * @param {string} tooltipIcon
 * @param {boolean} isTooltipPreventEvent
 * @return {TooltipType}
 */
export const getTooltipText = (
    title: string,
    desc: string,
    accessibility: AccessibleType,
    tooltipIcon?: string,
    isTooltipPreventEvent?: boolean,
): TooltipType => {
    const { a11yTooltipIcon, a11yCloseIconLabel } = accessibility

    return {
        title: removeHtmlTagsFromString(title),
        desc,
        a11yTooltipIcon,
        a11yCloseIconLabel,
        tooltipIcon,
        isTooltipPreventEvent,
    }
}
