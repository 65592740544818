import React from 'react'
import PropTypes from 'prop-types'

import { Toast } from '@nl/lib'
import { DisplayToastType } from './DisplayToast.types'

/**
 * display toast message
 *
 * @return {JSX.Element}
 */
const DisplayToast: React.FC<DisplayToastType> = ({ ...props }) => {
    // TODO: These options/messages are supposed to come from AEM post backend configuration is done
    const options = {
        toastSuccessMessage: 'toastBannerSuccessMessage',
        toastErrorMessage: props.errorMessage || 'Timeout error toast message',
        toastSuccessIcon: 'ct-notification-success',
        toastErrorIcon: 'ct-toast-error',
        toastCloseLabel: props.errorPopupCloseButtonDefaultText || 'Close',
    }

    return (
        <Toast
            success={false}
            failed={true}
            options={options}
            toastCloseFunction={() => {
                props.setShowTimeoutErrorToast(false)
            }}
            hideCTA={false}
        />
    )
}
DisplayToast.propTypes = {
    setShowTimeoutErrorToast: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    errorPopupCloseButtonDefaultText: PropTypes.string,
}
export default DisplayToast
