import React, { Validator } from 'react'
import PropTypes from 'prop-types'
import Badges from '../Badges/Badges'

import { PREFIX } from '../config'
import { BadgePriority, TransactionColumnBadgeProps } from './TransactionColumnBadge.type'

const TransactionColumnBadge: React.FC<TransactionColumnBadgeProps> = props => {
    const { badge, badgeData } = props
    const componentName = `${PREFIX}-triangle-transaction-history`
    return (
        <div className={`${componentName}-badge`}>
            <Badges badges={[badge.toUpperCase()]} badgesAndPriorities={badgeData} />
        </div>
    )
}

TransactionColumnBadge.propTypes = {
    badge: PropTypes.string.isRequired,
    badgeData: PropTypes.object.isRequired as Validator<BadgePriority>,
}

export default TransactionColumnBadge
