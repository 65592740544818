import { AxiosPromise } from 'axios'
import { getEnvironment } from '../../environments'
import { DevConfig } from '../../environments/dev.environment'
import { getHttpClient } from '../../httpClient'
import { UserProfileData } from '../../redux/models/user.profile.interface'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import { GigyaAccountResp, GigyaJWTResp, TypesGigyaAccountResp } from '../../utils/gigya.type'
import localStorageService from '../../utils/localStorageService'
import BaseService from '../base.service'
import { GigyaGetCookieResp } from '../../utils/gigya.type'
import { status } from '../../components/GigyaScreen/gigya.constants'

const httpClient = getHttpClient()
const environment: DevConfig = getEnvironment()
/**
 * Sso login service
 * TODO: name need to be changed
 */
class GigyaService {
    /**
     * Gigya check if session is available once isGigyaReady is true
     * @return {Promise}
     */
    hasSession(): Promise<boolean | void> {
        return new Promise<boolean>(resolve => {
            // eslint-disable-next-line
            window.gigya.hasSession().then((sessionExist: boolean) => {
                resolve(sessionExist)
            })
        })
    }

    /**
     * Gigya check session call
     * @return {Promise}
     */
    checkCookie(): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = this.createCheckCookieUrl()
            httpClient
                .apiGet(url, undefined, undefined, true)
                .then((resp: { data: GigyaGetCookieResp }) => {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    resp?.data?.result ? resolve() : reject()
                })
                .catch(() => {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject()
                })
        })
    }

    /**
     * set cookie after successful login
     * @param {string} ssoToken
     * @param {boolean} isSSOEnabled
     * @return {Promise}
     */
    setCookie(ssoToken: string, isSSOEnabled: boolean): Promise<unknown> {
        const url = this.createSetCookieUrl(isSSOEnabled)
        const headers: HttpReqHeaders = {
            'X-Auth': `Bearer ${ssoToken}`,
        }
        return httpClient.apiGet(url, {}, headers)
    }

    /**
     * Create check cookie URL
     * @return {string}
     */
    createCheckCookieUrl(): string {
        // TODO: mock  url checking is pending
        return `${environment.API_BASE_URL}${environment.API_ENDPOINTS.checkCookie}`
    }

    /**
     * Create set cookie URL
     * @param {boolean} isSSOEnabled
     * @return {string}
     */
    createSetCookieUrl(isSSOEnabled: boolean): string {
        return `${environment.API_BASE_URL}${
            environment.API_ENDPOINTS.setCookie +
            `?refClient=odp/ctrweb&serviceClient=odp/ctrweb&refPath=%2F&cookiesEnabled=${isSSOEnabled.toString()}`
        }`
    }

    /**
     * Get JWT token with Gigya web sdk
     * @return {Promise}
     */
    jwtToken(): Promise<GigyaJWTResp> {
        return new Promise((resolve, reject) => {
            window?.gigya?.accounts?.getJWT({
                callback: (response: GigyaJWTResp) => {
                    response.id_token ? resolve(response) : reject(response)
                },
                apiKey: window.gigya.apiKey,
            })
        })
    }

    /**
     * Get account/user profile info from Gigya
     * If error is 0 store response in userGigyaData
     * If optParam is passed, resolve to Gigya account resp (curent day flow), if not, resolve to gigya is ready status (SSO flow)
     * See {@link https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/417f6b5e70b21014bbc5a10ce4041860.html#ongigyaserviceready | SAP ongigyaserviceready }
     * @param {Record<string, string>} optParam
     * @return {Promise}
     */
    getAccountInfo(optParam?: Record<string, string>): Promise<GigyaAccountResp | boolean> {
        return new Promise(resolve => {
            window?.gigya?.accounts?.getAccountInfo({
                ...optParam,
                callback: (response: TypesGigyaAccountResp) => {
                    const { errorCode } = response
                    const isReady = window.gigya.isReady
                    if (errorCode === status.authorizedUser) {
                        // inherit function from gigya
                        window.userGigyaData = response
                    }
                    optParam ? resolve(response) : resolve(isReady)
                },
            })
        })
    }

    /**
     *
     * Get cds access token
     * @param  {string} gigyaJwt
     * @return {AxiosPromise}
     */
    cdsAccessToken(gigyaJwt: string): AxiosPromise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsAccessToken}`
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJwt}`,
        }
        return httpClient.apiPost(
            url,
            {
                rememberMe: !!(
                    localStorageService.getItem('remember') && localStorageService.getItem('remember') === 'true'
                ),
            },
            headers,
            true,
        )
    }
    /**
     * Load user profile from CDS
     * @param  {string|null} gigyaJwt
     * @return {UserProfileData}
     */
    userProfile(gigyaJwt: string | null): Promise<{ data: UserProfileData }> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsUserProfile}`
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${String(gigyaJwt)}`,
        }
        return httpClient.apiGet(
            url,
            { rememberMe: localStorageService.getItem('remember'), lang: BaseService.language },
            headers,
            true,
        )
    }

    /**
     *
     * Call CDS resend verification API
     * @param {string} UID
     * @return {AxiosPromise}
     */
    resendVerification(UID: string): Promise<any> {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.resendVerification}/${UID}`
        return httpClient.apiPost(url, undefined, undefined, true)
    }
}

export { GigyaService }
export default GigyaService
