import { MutableRefObject, useEffect, useRef, useState } from 'react'

/**
 * It makes a focus on element from reference when it is rendered
 * @param {MutableRefObject} ref
 * @param {boolean} enabled
 * @return {void}
 */
export const useFocusOnce = (ref: MutableRefObject<HTMLElement | undefined | null>, enabled?: boolean): void => {
    const [isRendered, setRendered] = useState<boolean>(false)

    useEffect(() => {
        setRendered(Boolean(ref.current))
    }, [ref])

    useEffect(() => {
        if (enabled && isRendered) {
            ref.current?.focus?.()
        }
    }, [isRendered, ref, enabled])
}

/**
 * It returns a ref what will case to one time focus on element where it will be attached.
 * @param {boolean} enabled
 * @return {MutableRefObject}
 */
export const useFocusOnceRef = (enabled?: boolean): MutableRefObject<HTMLElement | undefined | null> => {
    const ref = useRef<HTMLInputElement>()
    useFocusOnce(ref, enabled)
    return ref
}
