import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { OrderExpiredErrorProps } from './OrderExpiredError.type'
import { errorAnalytics } from '../../../analytics/components/errorAnalytics'
import { ErrorScreen } from '@nl/lib'
import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import { PREFIX } from '../../../config'
import { orderExpiredSelector } from '../../../redux/selectors/orderDetails.selectors'

const orderExpiredErrorClassName = `${PREFIX}-order-expired`

/**
 * returns Loading Skeleton for order header
 * @return {JSX.Element}
 */
const OrderExpiredError: React.FC<OrderExpiredErrorProps> = ({ ...props }): JSX.Element => {
    const { oeImage, oeTitle, oeMessage, oectaLabel } = props
    const orderExpired = useSelector(orderExpiredSelector)

    const continueClick = (): void => {
        window.location.href = encodeURI(window.ODP.globalLinks.homePageLink || '')
    }

    const errorData = {
        errorImage: oeImage,
        errorImgAltText: oeMessage,
        errorRichText: `<div class='${orderExpiredErrorClassName}-error_title'>${oeTitle}</div> ${oeMessage}`,
        tryAgainCTALabelText: oectaLabel,
        onClickTryAgain: continueClick,
    }

    useEffect(() => {
        if (orderExpired) {
            errorAnalytics(analyticsAttributes.eventParameters.labels.orderExpired)
        }
    }, [orderExpired, oeMessage])

    const accordionDisplay = document.getElementsByClassName('accordion-wrapper')[0]
    if (accordionDisplay && orderExpired) {
        accordionDisplay.classList.add(`${PREFIX}-xs-none`)
    }

    return (
        <>
            {orderExpired && (
                <div className={orderExpiredErrorClassName}>
                    <ErrorScreen data={errorData}></ErrorScreen>
                </div>
            )}
        </>
    )
}

OrderExpiredError.propTypes = {
    oeImage: PropTypes.string.isRequired,
    oeTitle: PropTypes.string.isRequired,
    oeMessage: PropTypes.string.isRequired,
    oectaLabel: PropTypes.string.isRequired,
}

export default OrderExpiredError
