import { createAction } from 'typesafe-actions'

import { Category, InitialCategoryPayloadType } from '../models/secondaryNavigation.interface'
import { SET_INITIAL_LINK_MEGANAV, SET_SITEMAP_LINKS } from '../types/secondaryNavigation/secondaryNavigation.constants'

export const setMegaNavLinks = createAction(
    SET_INITIAL_LINK_MEGANAV,
    (payload: InitialCategoryPayloadType[]) => payload,
)<InitialCategoryPayloadType[]>()

export const setSiteMapLinks = createAction(SET_SITEMAP_LINKS, (payload: Category[]) => payload)<
    InitialCategoryPayloadType[]
>()
