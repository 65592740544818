import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import appCacheService from '../../utils/appCacheService'
import {
    getWishListItems,
    getWishListProductCodesItems,
    getWishListDetailsItems,
    mergeWishListItems,
} from '../../redux/actions/wishlist.action'
import {
    wishlistSuccessAction,
    wishlistDetailsSuccessAction,
    wishlistProductCodesSuccessAction,
} from '../../redux/actionCreators'
import { WishlistResponse } from '../../redux/models/wishlist.interface'
import { useAuth } from '../../hooks'
import { areAllParamsValid, isAtleastOneParamValid } from '../../utils/getFilteredCartItems'
import { storageData } from '../../globalConstants'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileDataSelector,
    isUserDataFetchedSelector,
} from '../../redux/selectors/userProfile.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'

const WishlistInit: React.FC = () => {
    const dispatch = useDispatch()
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const signOutSuccess = useSelector(signOutSuccessSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const isUserDataFetched = useSelector(isUserDataFetchedSelector)
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)

    const runAuthenticatedOnce = useRef(0)

    const authConditions = useAuth({
        runOnce: runAuthenticatedOnce,
    })

    const commonContentIsFetched = checkDataLength(commonContentAvailable)

    useEffect(() => {
        const guid = appCacheService.wishlistGuid.get()
        const prefStoreId = appCacheService.preferredStoreId.get()
        const _checkPageForInitWishlist = (guidParam: string): void => {
            const currentPageType = getPageType()
            const { wishlist, cart, pdpPage } = pageTypes
            const wishlistCache = appCacheService.wishlistCache.get()
            const { products } = wishlistCache
                ? (JSON.parse(wishlistCache) as WishlistResponse)
                : ({} as WishlistResponse)

            switch (currentPageType) {
                case wishlist:
                case cart:
                    dispatch(getWishListItems(guidParam))
                    break
                case pdpPage:
                    if (checkDataLength(wishlistCache)) {
                        dispatch(wishlistDetailsSuccessAction({ products: products }))
                    } else {
                        dispatch(getWishListDetailsItems(guidParam))
                    }
                    break
                default:
                    if (checkDataLength(wishlistCache)) {
                        dispatch(wishlistProductCodesSuccessAction({ products: products }))
                    } else {
                        userProfileData && isUserDataFetched
                            ? dispatch(getWishListProductCodesItems(guidParam, selectedPreferredStoreId))
                            : dispatch(getWishListDetailsItems(guidParam))
                    }
            }
        }
        if (
            !!areAllParamsValid(
                isAtleastOneParamValid(selectedPreferredStoreId, prefStoreId),
                isAuthFlowExecuted,
                commonContentIsFetched,
            )
        ) {
            if (checkDataLength(userProfileData)) {
                !!appCacheService.wishlistCache.get() && appCacheService.wishlistCache.delete()

                if (!!guid && !guid.includes('@') && authConditions()) {
                    dispatch(mergeWishListItems(guid))
                    runAuthenticatedOnce.current = 1
                } else {
                    _checkPageForInitWishlist('')
                }
            } else {
                if (!!guid && !appCacheService.gigyaJWTToken.get()) {
                    _checkPageForInitWishlist(guid)
                }
            }
        }
    }, [
        dispatch,
        isAuthFlowExecuted,
        userProfileData,
        selectedPreferredStoreId,
        authConditions,
        isUserDataFetched,
        commonContentIsFetched,
    ])

    useEffect(() => {
        if (signOutSuccess) {
            runAuthenticatedOnce.current = 0
            appCacheService.wishlistGuid.delete(storageData.wishlistGuid)
        }
        if (signOutSuccess && !appCacheService.wishlistGuid.get()) {
            dispatch(
                wishlistSuccessAction({
                    products: [],
                } as WishlistResponse),
            )
        }
    }, [signOutSuccess, dispatch])

    return null
}

export default WishlistInit
export { WishlistInit }
