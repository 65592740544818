import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { pageTypes, PREFIX } from '../config'
import { BannerType, VehicleBannerProps, Cta, CtaType } from './VehicleBanner.type'
import Icon from '../Icon'
import Button from '../Button'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'

export const VehicleBanner: React.FC<VehicleBannerProps> = props => {
    const [titleCtasState, setTitleCtasState] = useState({})
    const { isAdditionalInfo, setDefaultSelectedCard } = props
    const prevDefaultCtasStateRef = useRef<Record<number, boolean>>({})
    const prevIsTiresDataAvailable = useRef(props.isTiresDataAvailable)
    const flyOutRef = useRef()
    const additionalInfoClass = props.isAdditionalInfo
        ? `${PREFIX}-vehicle-banner__additional-info`
        : props.bannerType !== BannerType.PartialFit
        ? `${PREFIX}-vehicle-banner__no-border`
        : ''

    // use Effect to set default props in next render
    useEffect(() => {
        if (
            prevDefaultCtasStateRef.current !== props.defaultCtasState ||
            prevIsTiresDataAvailable.current !== props.isTiresDataAvailable
        ) {
            const prevCtaState = props.isTiresDataAvailable ? { 0: false } : titleCtasState
            setTitleCtasState({ ...prevCtaState, ...props.defaultCtasState })
            prevDefaultCtasStateRef.current = props.defaultCtasState as Record<number, boolean>
            prevIsTiresDataAvailable.current = props.isTiresDataAvailable
        }
    }, [props.defaultCtasState, props.isTiresDataAvailable, titleCtasState])

    // useEffect to reset default selected card
    useEffect(() => {
        if (Object.keys(titleCtasState).length && isAdditionalInfo) {
            setDefaultSelectedCard('')
        }
    }, [titleCtasState, isAdditionalInfo, setDefaultSelectedCard])

    /**
     * function to set selected cta title
     * @param {string} ctaIndex
     * @param {boolean} state
     */
    const titleCtaAction = (ctaIndex: string, state: boolean): void => {
        setTitleCtasState({ [0]: !state, [ctaIndex]: state })
        props?.closeClickHandler?.(true)
    }

    const subTitleCtaAction = () => {
        console.log('Sub Title CTA action')
    }

    /**
     * This function return Icon based on vehicle fit type
     * @return {JSX.Element | null} returns icon
     */
    const getVehicleBannerIcon = (): JSX.Element => {
        return props.bannerType === BannerType.NotFit ? (
            props.isAdditionalInfo ? (
                <Icon type="ct-notification-caution" size="lg"></Icon>
            ) : (
                props.pageType === pageTypes.pdpPage && <Icon type="ct-toast-error" size="lg"></Icon>
            )
        ) : props.bannerType === BannerType.PartialFit ? (
            <Icon type="ct-notification-caution" size="lg"></Icon>
        ) : (
            !props.isNotShowIcon && <Icon type="ct-notification-success" size="lg" />
        )
    }

    // TODO: Need to fix this, its causing to close model when multiple model are present on vehicle banner
    const closeHandler = (): void => {
        setTitleCtasState({})
        props?.closeManageVehiclesDropdown()
    }
    useClickOutsideClose(flyOutRef, closeHandler, !props.showPackageInfoPopup, true)

    const toggleVehicleSelectorBannerVisibility = () => {
        if (!props.hideVehicleSelectorBannerState) {
            return `${PREFIX}-vehicle-banner ${props.pageType === pageTypes.pdpPage && `${PREFIX}-pdp-margin`}`
        } else {
            return `${PREFIX}-xs-none`
        }
    }
    return (
        <>
            <div className={`${PREFIX}-container`}>{props.showVehicleInfo?.()}</div>
            <div
                className={classnames(
                    props.pageType !== pageTypes.pdpPage && additionalInfoClass,
                    `${PREFIX}-full-width-container`,
                    {
                        [`${PREFIX}-vehicle-banner--may-not-fit`]: props.bannerType === BannerType.PartialFit,
                        [`${PREFIX}-vehicle-banner--not-fit`]:
                            props.bannerType === BannerType.NotFit && props.pageType === pageTypes.pdpPage,
                        [`${PREFIX}-vehicle-banner--margin-remove`]:
                            !!props.isPackageFlow || props.pageType === pageTypes.pdpPage,
                    },
                    toggleVehicleSelectorBannerVisibility(),
                )}>
                <div className={`${PREFIX}-container`}>
                    {getVehicleBannerIcon() && (
                        <div className={`${PREFIX}-icon-container`}>{getVehicleBannerIcon()}</div>
                    )}
                    <div className={`${PREFIX}-text-container`}>
                        <div className={`${PREFIX}-text-container-banner`}>
                            {/* Title Section */}
                            <section>
                                <div className={`${PREFIX}-text-container-banner-heading`}>{props.title}</div>
                                <div
                                    className={`${PREFIX}-ctas-container ${
                                        props.isAdditionalInfo ? `${PREFIX}-ctas-container__additional-info` : ''
                                    }`}>
                                    {/* We are not using renderer to improve the readability fo the code. */}
                                    {props?.ctas.map((cta: Cta, index) => {
                                        const { AfterActionUi } = cta
                                        return (
                                            <div className={`${PREFIX}-ctas-container__cta-container`} key={index}>
                                                <div className={`${PREFIX}-ctas-container__button-group`}>
                                                    <Button
                                                        id={cta?.ctaId ? cta?.ctaId : 'title-btn'}
                                                        type="tertiary"
                                                        onClick={
                                                            cta?.ctaAction
                                                                ? cta.ctaAction
                                                                : () => {
                                                                      titleCtaAction(
                                                                          `${index}`,
                                                                          !titleCtasState[`${index}`],
                                                                      )
                                                                  }
                                                        }>
                                                        {cta.text}
                                                        {/* Logic to hid and show chevron */}
                                                        {cta.ctaType === CtaType.dropDown ? (
                                                            <Icon
                                                                type={
                                                                    titleCtasState[`${index}`]
                                                                        ? 'ct-chevron-up'
                                                                        : 'ct-chevron-down'
                                                                }
                                                                size="lg"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Button>
                                                </div>
                                                {titleCtasState[`${index}`] && AfterActionUi ? (
                                                    <div
                                                        className={`${PREFIX}-ctas-container__action-ui-container`}
                                                        ref={flyOutRef}>
                                                        <AfterActionUi index={index} {...cta.uiProps} />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                            {/* Sub Title Section */}
                            <section>
                                {props.showSubtitle ? (
                                    <span className={`${PREFIX}-vehicle-banner__disclaimer-text`}>
                                        {props.subTitle}
                                    </span>
                                ) : null}
                                <div className={`${PREFIX}-ctas-container`}>
                                    {/* We are not using renderer to improve the readability fo the code. */}
                                    {props?.subCtas?.map((cta: Cta, index: number) => {
                                        const { AfterActionUi } = cta
                                        return (
                                            <div key={index} className={`${PREFIX}-ctas-container__view-button`}>
                                                <Button
                                                    id="sub-title-btn"
                                                    type="tertiary"
                                                    onClick={
                                                        cta?.ctaAction
                                                            ? cta?.ctaAction
                                                            : () => {
                                                                  subTitleCtaAction()
                                                              }
                                                    }>
                                                    {cta.text}
                                                </Button>
                                                {AfterActionUi ? (
                                                    <div
                                                        className={`${PREFIX}-ctas-container__action-ui-container-disclaimer`}>
                                                        <AfterActionUi index={index} {...cta.uiProps} />
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        </div>
                        <div className={`${PREFIX}-vehicle-banner__auto-service-button`}>
                            {props.renderAutoAppointmentButton()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

VehicleBanner.propTypes = {
    bannerType: PropTypes.oneOf(Object.values(BannerType)),
    title: PropTypes.string,
    subTitle: PropTypes.string,
    showSubtitle: PropTypes.bool,
    ctas: PropTypes.any,
    subCtas: PropTypes.any,
    defaultCtasState: PropTypes.any,
    showVehicleInfo: PropTypes.func,
    isNotShowIcon: PropTypes.bool,
    isAdditionalInfo: PropTypes.bool,
    closeClickHandler: PropTypes.func,
    pageType: PropTypes.string,
    isPackageFlow: PropTypes.bool,
    hideVehicleSelectorBannerState: PropTypes.bool,
    renderAutoAppointmentButton: PropTypes.func.isRequired,
    setDefaultSelectedCard: PropTypes.func,
    isTiresDataAvailable: PropTypes.bool,
    showPackageInfoPopup: PropTypes.bool,
}

export default VehicleBanner
