import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { PreviewDetailsListType } from './PreviewDetailsList.type'

/**
 * PreviewDetailsList component
 * @param {PreviewDetailsListType} props - label, value
 * @return {JSX.Element} returns PreviewDetailsList component
 */
const PreviewDetailsList: React.FC<PreviewDetailsListType> = ({ ...props }): JSX.Element => {
    const { label, value } = props

    return (
        <div className={`${PREFIX}-preview-details-list__item`} key={label}>
            <label className={`${PREFIX}-preview-details-list__label`} htmlFor="etInitiativeName">
                {label}
            </label>
            :<span className={`${PREFIX}-preview-details-list__value`}>{value}</span>
        </div>
    )
}

PreviewDetailsList.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
}

export default PreviewDetailsList
