import { RootState } from '../reducers'
import { CheckButtonClickState } from '../reducers/checkoutButtonClick.reducer'

export const checkoutButtonClickSelector = (state: RootState): CheckButtonClickState => state.checkoutButtonClick
export const isTNCOpenSelector = (state: RootState): boolean => state.checkoutButtonClick.isTNCOpen
export const isPCOpenSelector = (state: RootState): boolean => state.checkoutButtonClick.isPCOpen
export const isChangeStoreSelector = (state: RootState): boolean => state.checkoutButtonClick.isChangeStore
export const isTncAcceptedSelector = (state: RootState): boolean => state.checkoutButtonClick.isTncAccepted
export const isCheckoutButtonClickSelector = (state: RootState): boolean =>
    state.checkoutButtonClick.isCheckoutButtonClick
export const isClickToPayClickedSelector = (state: RootState): boolean => state.checkoutButtonClick.isClickToPayClicked
export const isPaypalButtonClickSelector = (state: RootState): boolean => state.checkoutButtonClick.isPaypalButtonClick
export const isPaypalButtonClickValidSelector = (state: RootState): boolean =>
    state.checkoutButtonClick.isPaypalButtonClickValid
