import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'

import { PREFIX } from '../config'
import { TransactionColumnDataProps } from './TransactionColumn.type'

const TransactionColumn: React.FC<TransactionColumnDataProps> = props => {
    const { label, value, className, icon, src, alt } = props

    const componentName = `${PREFIX}-triangle-transaction-history`
    return (
        <div className={`${componentName} ${componentName}-${className}`}>
            <div aria-hidden="true" className={`${PREFIX}-transaction-label`}>
                {label}
            </div>
            <div className={`${PREFIX}-transaction-value`}>
                {icon && <Icon type={icon} size="md" />}
                {src && <img className={`${PREFIX}--ct-money-icon`} src={src} alt={alt} />}
                {value}
            </div>
        </div>
    )
}

TransactionColumn.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    icon: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
}

export default TransactionColumn
