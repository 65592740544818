import React from 'react'
import PropTypes from 'prop-types'

import { TmxService } from '../../services/tmxService/tmx.service'
import { threatMetrixSessionInfoProps } from './tmxinit.type'
import { magicNumber } from '@nl/lib'

export const TmxInit: React.FC<threatMetrixSessionInfoProps> = ({ ...props }) => {
    const tmxservice = new TmxService()
    const lifeTimeInMinutes = props.sessionLifeTime
    let tmxScripts: HTMLElement[] = tmxservice.profiling({ ...props })
    let tmxScriptsBody = tmxservice.profilingBody({ ...props })
    setInterval(() => {
        tmxScripts = tmxservice.profiling({ ...props })
        tmxScriptsBody = tmxservice.profilingBody({ ...props })
    }, lifeTimeInMinutes * magicNumber.SIXTY * magicNumber.THOUSAND)

    const tmxComp = document.querySelector('head')
    const appendScript = (): void => {
        tmxScripts.forEach((scriptType: HTMLElement) => {
            tmxComp?.append(scriptType)
        })

        tmxScriptsBody.forEach((scriptBodyType: HTMLElement) => {
            tmxComp?.append(scriptBodyType)
        })
    }
    return <>{props.enabled && appendScript()}</>
}
TmxInit.propTypes = {
    threatMetrixJsUrl: PropTypes.string.isRequired,
    threatMetrixIframeUrl: PropTypes.string.isRequired,
    threatMetrixPageId: PropTypes.string.isRequired,
    threatMetrixOrgId: PropTypes.string.isRequired,
    sessionIdSuffixLength: PropTypes.number.isRequired,
    sessionPrefix: PropTypes.string.isRequired,
    sessionLifeTime: PropTypes.number.isRequired,
    enabled: PropTypes.bool.isRequired,
}
