import React from 'react'
import PropTypes from 'prop-types'

import { TabProps } from './Tabs.type'
import { PREFIX } from '../config'
import { magicNumber } from '../../utils'

/**
 * Tab component
 * @return {JSX.Element} returns Tab component
 */
const Tab: React.FC<TabProps> = ({ ...props }): JSX.Element => {
    let tabClass = `${PREFIX}-tabs__tab`
    const tabClassLabel = `${tabClass}__label`

    if (props.activeTab === props.label) {
        tabClass += ` ${tabClass}--active`
    }

    return (
        <li className={tabClass} role="presentation">
            <div
                data-testid={props.dataTestId}
                className={tabClassLabel}
                role="tab"
                aria-selected={props.currentIndex ? 'false' : 'true'}
                tabIndex={props.currentIndex ? magicNumber.MINUS_ONE : magicNumber.ZERO}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    props.handleKeyDown(event, props.currentIndex, props.label)
                }}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    props.handleClickOrEnter(event, props.currentIndex, props.label)
                }}>
                {props.label}
            </div>
        </li>
    )
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    currentIndex: PropTypes.number.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    handleClickOrEnter: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
}

export default Tab
