import { fetchInterceptorErrorAction } from '../../../actionCreators/fetchInterceptorError.actionCreators'
import interceptorConfig from '../../../../services/ResponseInterceptors/interceptorConfig.service'
import injectStoreInInterceptor from '../injectStoreInInterceptor'

export const httpErrorInterceptor = (
    error: Record<string, unknown>,
    resolve: (value: Record<string, unknown>) => void,
    reject: (value: Record<string, unknown>) => void,
): void => {
    // TODO: This condition may be changed once backend configuration sub task is done
    const store = injectStoreInInterceptor.getStoreValue()
    const axiosRefObject = {
        resolveFunc: resolve,
        rejectFunc: reject,
        config: error?.config,
    }
    interceptorConfig.setInterceptorPartialAuthList(axiosRefObject)
    store.dispatch(fetchInterceptorErrorAction(error))
    reject(error)
}
