import { PREFIX } from '../config'

/**
 * Calculates Header Height(Primary Nav + Secondary Nav + Store Locator Pencil Banner)
 * @example
 * // returns 104
 * calculateHeaderHeight();
 * @example
 * @returns {Number} Returns the height of header.
 */

export const calculateHeaderHeight = (): number => {
    let primaryNavHeight = document.getElementsByClassName(`${PREFIX}-primary-navigation-bar`)[0]?.clientHeight
    const checkoutNavHeight = document.getElementsByClassName(`${PREFIX}-simple-header-checkout`)[0]?.clientHeight
    const secondaryNav = document.getElementsByClassName(`${PREFIX}-secondary-navigation-bar`)
    const notificationBanner = document.getElementsByClassName(`${PREFIX}-notification-banner__body`)
    const notificationBannerHeight =
        notificationBanner && notificationBanner.length ? notificationBanner[0].clientHeight : 0
    let secondaryNavHeight = 0
    let secondaryNavPos = 0
    if (secondaryNav && secondaryNav.length) {
        secondaryNavHeight = secondaryNav[0].clientHeight
        secondaryNavPos = secondaryNav[0].getBoundingClientRect().top
    }

    if (!primaryNavHeight) {
        primaryNavHeight = checkoutNavHeight
    }
    const headerHeight = secondaryNavPos >= primaryNavHeight ? secondaryNavHeight + primaryNavHeight : primaryNavHeight
    return headerHeight + notificationBannerHeight
}

export const calculateHeaderHeightWithStickyStoreLocator = (): number => {
    const headersHeight = Number(document.getElementsByClassName(`${PREFIX}-primary-navigation`)[0]?.clientHeight || 0)
    const infoBannerHeight = isInformationBanner()
        ? Number(document.getElementsByClassName(`${PREFIX}-information-banner`)[0]?.clientHeight || 0)
        : 0
    const storeLocatorHeight = Number(
        document.getElementsByClassName(`store-locator-pencil-banner`)[0]?.clientHeight || 0,
    )
    const stickyStoreLocatorHeight = Number(
        document.getElementsByClassName(`${PREFIX}-primary-navigation__sticky_store-locator`)[0]?.clientHeight || 0,
    )
    const scrolledStoreLocatorHeight = stickyStoreLocatorHeight > 0 ? 0 : stickyStoreLocatorHeight
    return headersHeight + infoBannerHeight + storeLocatorHeight + scrolledStoreLocatorHeight
}

const isInformationBanner = (): boolean => {
    const infoBanner = document.getElementsByClassName(`${PREFIX}-information-banner`)
    if (!!infoBanner && !!infoBanner[0]) {
        const rect = infoBanner[0].getBoundingClientRect()
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    }
    return false
}

export const getStickyBuyBarHeight = (): number => {
    const stickyBuyBar = document.getElementsByClassName(`${PREFIX}-sticky-buy-bar`)
    let stickyBuyBarHeight = 0
    if (stickyBuyBar && stickyBuyBar.length) {
        stickyBuyBarHeight = stickyBuyBar[0]?.clientHeight
    }

    return stickyBuyBarHeight
}
