import { gigyaUrl } from '../config'

/**
 * function to is to check url params and redirect user to site after successful registration
 */
const handleRegistrationRedirection = (): void => {
    // this is called only when url parameter has "emailVerificationLink", which is link from registration email.
    const navigateToUrl = (parameter: string) => {
        const index = 1
        const currentUrl = window.location.href
        const urlParam = new URL(currentUrl.split(parameter + '=')[index])
        if (urlParam.origin.includes(gigyaUrl)) {
            window.location.href = encodeURI(urlParam.href)
        }
    }

    const urlParameter: {
        [key: string]: (param: string) => void
    } = {
        emailVerificationLink: navigateToUrl,
    }

    Object.keys(urlParameter).forEach(key => {
        const currentUrl = window.location.href
        if (currentUrl.includes(key)) {
            const navigateFunction = urlParameter[key]
            navigateFunction(key)
        }
    })
}

export default handleRegistrationRedirection
