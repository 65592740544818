import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { INSTORECLEARANCE, SALE } from './PriceOld.constant'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import { PriceType } from './PriceOld.types'
import { checkInstanceOf } from '../ProductReusableCard/instanceCheckingUtil'
import { replaceStrWithDynamicVal } from '../../utils'
import { addModalAttribute } from '../../utils/modalAccessibility'
/**
 * Price component
 * @deprecated - please use new Price component in future since this will be removed moving forward
 * @param {PriceType} props -  unitPrice, priceStyle, offerPrice, priceType, promotionalPriceLabel, thresholdValue, unitPriceLabel, clearancePriceLabel, discountValue, a11yPriceRangeFrom, a11yPriceRangeTo, hideSaveLabel, a11yStrikeOutPrice
 * @return {JSX.Element} returns Price component
 */
const PriceOld: React.FC<PriceType> = ({
    unitPrice,
    priceStyle,
    offerPrice,
    priceType,
    promotionalPriceLabel,
    thresholdValue,
    unitPriceLabel,
    clearancePriceLabel,
    discountValue,
    a11yPriceRangeFrom,
    a11yPriceRangeTo,
    hideSaveLabel,
    a11yStrikeOutPrice,
    coreChargesLabel,
    coreChargesTooltipTitle,
    coreChargesTooltipParagraph,
    coreCharge,
    a11yTooltipIcon,
    a11yCloseIconLabel,
    feeTitle,
    feeDisclaimerMessage,
    feeDisclaimerTitle,
}): JSX.Element => {
    const roundToFivePercent = (value: number) => {
        const roundOffValue = 5
        /**
         * As per the discussion with BA team-> the round off should be nearest 5%
         *  i.e. 23% -> 20%, 24% -> 20% , 26-27% -> 25%
         */
        return value % roundOffValue >= roundOffValue // value should be 5 to satisfy the above condition
            ? Math.floor(value / roundOffValue) * roundOffValue + roundOffValue
            : Math.floor(value / roundOffValue) * roundOffValue
    }

    const calculateSavings = (original: string, discount: string): string => {
        // The below regex will strip away everything except numbers
        const originalNumber = Number(original.replace(/[^0-9.]+/g, ''))
        const discountNumber = Number(discount.replace(/[^0-9.]+/g, ''))

        // Using the numbers extracted above to get the symbol value
        const currencySymbol = original.replace(originalNumber.toString(), '')

        // Finding the position of the symbol in the unit price i.e original value.
        const currencyPosition = original.indexOf(currencySymbol)

        const difference: number = originalNumber - discountNumber

        const percentageMultiplier = 100

        const percentageDifference = (Math.abs(originalNumber - discountNumber) / originalNumber) * percentageMultiplier

        return difference > thresholdValue
            ? currencyPosition > 0
                ? `${Math.round(difference)}${currencySymbol}`
                : `${currencySymbol}${Math.round(difference)}`
            : `${roundToFivePercent(percentageDifference)}%`
    }

    const checkRange = (str: string) => {
        return !!str && str.includes('-')
    }
    const checkWasPrice = (): string => {
        return offerPrice ? offerPrice : unitPrice
    }

    const screenReaderPrice = (actualPrice: string) => {
        const rangeToIndex = 1
        return checkRange(actualPrice)
            ? a11yPriceRangeFrom &&
                  a11yPriceRangeTo &&
                  `${a11yPriceRangeFrom} ${actualPrice.split('-')[0]} ${a11yPriceRangeTo} ${
                      actualPrice.split('-')[rangeToIndex]
                  }`
            : actualPrice
    }

    const checkDiscountType = (): string => {
        return discountValue ? discountValue : calculateSavings(unitPrice, offerPrice)
    }
    const renderPrice = (): JSX.Element => {
        return (
            <p
                aria-label={screenReaderPrice(checkWasPrice())}
                className={priceStyle ? priceStyle : `${PREFIX}-price__value`}
                data-testid="price">
                {checkWasPrice()}
            </p>
        )
    }

    const [visibility, setVisibility] = useState(false)
    const buttonRef = useRef()
    /**
     * To display tooltip discription
     *
     * @param { string } tooltipDesc - tooltipdescription message
     * @return { JSX.Element } - returns descriptions
     */
    const toolTipText = (tooltipDesc: string): JSX.Element => {
        return <div data-testid="tooltip-text" dangerouslySetInnerHTML={{ __html: tooltipDesc }}></div>
    }

    const toolTipButtonHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        addModalAttribute(e)
        setVisibility(!visibility)
    }
    /**
     * to display core charge if added
     *
     * @return {JSX.Element | null } - if corecharge availabel return JSX element else null
     */
    const coreChargeComponent = (): JSX.Element => {
        return (
            coreChargesLabel && (
                <div className={`${PREFIX}-product__core-charge`}>
                    {replaceStrWithDynamicVal(coreChargesLabel, checkInstanceOf(coreCharge))}
                    <button
                        ref={buttonRef}
                        className={`${PREFIX}-product__core-charge-tooltip-btn`}
                        onClick={toolTipButtonHandler}
                        data-testid="core-charges"
                        aria-label={a11yTooltipIcon}>
                        <span className={visibility ? `${PREFIX}-product__core-charge-tooltip-btn--active` : ''}>
                            <Icon type="ct-information" size="sm" />
                        </span>
                    </button>
                    {!!visibility && (
                        <Tooltip
                            visibility={visibility}
                            setVisibility={setVisibility}
                            iconID="ct-close"
                            headerText={coreChargesTooltipTitle}
                            bodyText={toolTipText(coreChargesTooltipParagraph)}
                            coords={buttonRef.current}
                            a11yCloseIconLabel={a11yCloseIconLabel}
                        />
                    )}
                </div>
            )
        )
    }

    const ecoFeeComponent = (): JSX.Element => {
        return (
            feeTitle && (
                <div className={`${PREFIX}-price__eco-fee`}>
                    {feeTitle}
                    {feeDisclaimerMessage && (
                        <button
                            ref={buttonRef}
                            className={`${PREFIX}-price__eco-fee-tooltip-btn`}
                            onClick={toolTipButtonHandler}
                            data-testid="eco-fee"
                            aria-label={a11yTooltipIcon}>
                            <span className={visibility ? `${PREFIX}-price__eco-fee-tooltip-btn--active` : ''}>
                                <Icon type="ct-information" size="sm" />
                            </span>
                        </button>
                    )}
                    {!!visibility && (
                        <Tooltip
                            visibility={visibility}
                            setVisibility={setVisibility}
                            iconID="ct-close"
                            headerText={feeDisclaimerTitle}
                            bodyText={toolTipText(feeDisclaimerMessage)}
                            coords={buttonRef.current}
                            a11yCloseIconLabel={a11yCloseIconLabel}
                        />
                    )}
                </div>
            )
        )
    }
    return (
        <>
            <div className={`${PREFIX}-price`}>
                {priceType === INSTORECLEARANCE && (
                    <span className={`${PREFIX}-price--was`}>{clearancePriceLabel}</span>
                )}
                {renderPrice()}
                {coreChargeComponent()}
                {ecoFeeComponent()}
                {offerPrice && (
                    <div className={`${PREFIX}-price--was`}>
                        {priceType === INSTORECLEARANCE && <span>{unitPriceLabel}</span>}
                        <s aria-label={unitPrice ? `${a11yStrikeOutPrice} ${screenReaderPrice(unitPrice)}` : ''}>
                            {unitPrice}
                        </s>
                    </div>
                )}
                {!hideSaveLabel && priceType === SALE && (
                    <div className={`${PREFIX}-price--sale`}>
                        {promotionalPriceLabel}

                        {checkDiscountType()}
                    </div>
                )}
            </div>
        </>
    )
}

PriceOld.propTypes = {
    /**
     * Previous Price
     */
    unitPrice: PropTypes.string,
    priceStyle: PropTypes.any,
    offerPrice: PropTypes.string,
    priceType: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    thresholdValue: PropTypes.number,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    discountValue: PropTypes.string,
    a11yPriceRangeFrom: PropTypes.string,
    a11yPriceRangeTo: PropTypes.string,
    hideSaveLabel: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    coreChargesTooltipTitle: PropTypes.string,
    coreChargesTooltipParagraph: PropTypes.string,
    coreCharge: PropTypes.any,
    coreChargesLabel: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    feeTitle: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
}

export default PriceOld
