import { libUtils } from '@nl/lib'
import { getEnvironment } from '../../environments'
import {
    DynamicComponentReduxType,
    DynamicComponentApiResponse,
    SinglePriorityType,
} from '../../redux/models/dynamicComponent.interface'
import BaseService from '../base.service'

const environment = getEnvironment()

/**
 * AEM Fragment service
 * @extends BaseService
 */
export class AemFragmentService extends BaseService {
    /**
     * Function to prepare pdp url
     * @static
     * @method
     * @private
     * @param {string} uri
     * @return {URL}
     */
    static preparePdpUrl(uri: string): URL {
        const language = libUtils.getLanguage()
        const cmsFragmentUrl = environment.AEM_URL.replace('{0}', language)
        return new URL(`${cmsFragmentUrl}${environment.API_ENDPOINTS.pdpFragment}${uri}`)
    }

    /**
     * Function to prepare plp url.
     * @static
     * @private
     * @method
     * @param {string} uri
     * @return {URL}
     */
    static preparePlpUrl(uri: string): URL {
        const language = libUtils.getLanguage()
        const cmsFragmentUrl = environment.AEM_URL.replace('{0}', language)
        return new URL(`${cmsFragmentUrl}${environment.API_ENDPOINTS.plpFragment}${uri}`)
    }

    /**
     * Function to prepare brand url.
     * @static
     * @private
     * @method
     * @param {string} uri
     * @return {URL}
     */
    static prepareBrandUrl(uri: string): URL {
        const language = libUtils.getLanguage()
        const cmsFragmentUrl = environment.AEM_URL.replace('{0}', language)
        return new URL(`${cmsFragmentUrl}${environment.API_ENDPOINTS.brandFragment}${uri}`)
    }

    /**
     * Function to prepare store url.
     * @static
     * @private
     * @method
     * @param {string} uri
     * @return {URL}
     */
    static prepareStoreURL(uri: string): URL {
        const language = libUtils.getLanguage()
        const cmsFragmentUrl = environment.AEM_URL.replace('{0}', language)
        return new URL(`${cmsFragmentUrl}${environment.API_ENDPOINTS.storeDetailsFragment}${uri}`)
    }

    /**
     * Function to hit the service and return promise.
     * @static
     * @method
     * @param {URL} url
     * @return {Promise}
     */
    static aemFragment(url: URL): Promise<unknown> {
        return new Promise((resolve, reject) => {
            AemFragmentService.get(url)
                .then(res => {
                    resolve(AemFragmentService.transformData(res.data as DynamicComponentApiResponse))
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * Function to reframe the response object before saving in the redux.
     * @static
     * @method
     * @param {SinglePriorityType} data
     * @return {DynamicComponentReduxType}
     */
    static transformData(data: DynamicComponentApiResponse) {
        const transformData: DynamicComponentReduxType = {}
        data.priorities.forEach((priority: SinglePriorityType) => {
            transformData[`reference_${priority.id}`] = {
                htmlStr: priority.content.join(''),
            }
        })
        return transformData
    }

    /**
     * Function to get component data for pdp page.
     * @method
     * @public
     * @param {string} pCode
     * @param {string[]} categories
     * @return {Promise}
     */
    pdpFragment(pCode: string, categories: string[]): Promise<unknown> {
        const categoryList = !!categories?.length ? `/${categories.join('/')}` : ''
        const url = AemFragmentService.preparePdpUrl(`${pCode}${categoryList}`)
        return AemFragmentService.aemFragment(url)
    }
    /**
     * Function to get component data for plp page.
     * @method
     * @public
     * @param {string} pCode
     * @return {Promise}
     */
    plpFragment(pCode: string): Promise<unknown> {
        const url = AemFragmentService.preparePlpUrl(`${pCode}`)
        return AemFragmentService.aemFragment(url)
    }

    /**
     * Function to get component data for plp page.
     * @method
     * @public
     * @param {string} storeId
     * @return {Promise}
     */
    storeFragment(storeId: string): Promise<unknown> {
        const url = AemFragmentService.prepareStoreURL(`${storeId}`)
        return AemFragmentService.aemFragment(url)
    }

    /**
     * This function used to get brand info
     * @param {string[]} categories
     * @return {Promise<unknown>} brandFragmentInfo
     */
    brandFragment(categories: string[]): Promise<unknown> {
        const url = AemFragmentService.prepareBrandUrl(`${categories.join('/')}`)
        return AemFragmentService.aemFragment(url)
    }
}

export const aemFragmentService = new AemFragmentService()
