import { useRef, useEffect } from 'react'

/**
 * Custom react hook that returns previous value.
 * @param {T} value
 * @return {T} Previous value (happens before update in useEffect above)
 */
export default function usePrevious<T>(value: T): T {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}
