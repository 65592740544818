import { EnvironmentConfig } from '../../../../app/src/environments/base.environment'
import {
    xWebHost,
    serviceClient,
    serviceVersion,
    ocpApimSubscriptionKey,
    baseSiteId,
    bannerId,
    browseMode,
} from './axiosHttpClientUtils.constant'

/**
 * Function that set request headers with sensitive information for allowed domains from AEM configuration
 * @param {EnvironmentConfig} environment environment
 * @param {Record<string, unknown>} configs page data-configs
 * @param {Record<string, unknown>} domainsConfig config with allowed domains and corresponding headers
 * @param {string} url request url
 * @param {string} jwt jwt token
 * @param {Record<string, unknown>} headers request headers
 */

export const setRequestHeadersToAllowedDomains = (
    environment: EnvironmentConfig,
    configs: Record<string, unknown>,
    domainsConfig: Record<string, unknown>,
    url: string,
    jwt: string,
    headers: Record<string, unknown>,
) => {
    const headersMapping = {
        [xWebHost]: window.location.hostname,
        [serviceClient]: environment.serviceClient,
        [serviceVersion]: environment.serviceVersion,
        [ocpApimSubscriptionKey]: environment.ocpApimSubscriptionKey,
        [baseSiteId]: environment.baseSiteId,
        [bannerId]: configs?.['bannerId'] as string,
        [browseMode]: headers[browseMode],
    }

    Object.keys(domainsConfig).forEach(domain => {
        if (url && url.includes(domain)) {
            ;(domainsConfig[domain] as string).split(',').forEach(header => {
                if (header === 'authorization' && !!jwt) {
                    headers['authorization'] = `Bearer ${jwt}`
                } else if (header !== 'authorization') {
                    headers[header] = headersMapping[header as keyof typeof headersMapping]
                }
            })
        }
    })
}
