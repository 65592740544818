import React from 'react'
import PropTypes from 'prop-types'

import { WishlistProps } from './Wishlist.type'
import Icon from '../Icon'
import { PREFIX } from '../config'

/**
 * Wishlist component
 * @param {WishlistProps} props - wishlistUrl, a11yWishlist, wishlistCount, maximumCount, path
 * @return {JSX.Element} returns Wishlist Icon
 */
const Wishlist: React.FC<WishlistProps> = props => {
    const {
        wishlistUrl,
        a11yWishlist,
        ariaLabelWishlist,
        wishlistCount,
        maximumCount,
        path,
        label,
        featureType,
        isSideNav,
    } = props

    /**
     * Renders wishlist label.
     * @return {JSX.Element}
     */
    const wishlistLabel = (): JSX.Element => !!label && <span className={`${PREFIX}-wishlist__label`}>{label}</span>

    return (
        <div className={`${PREFIX}-wishlist${isSideNav ? '-nav' : ''}`} data-testid="header-wishlist">
            <a
                href={wishlistUrl}
                aria-label={ariaLabelWishlist}
                data-link-value={a11yWishlist}
                data-feature-type={featureType}>
                <Icon type="ct-favorite-inactive" title="a11yWishlist" size="lg" path={path} decorative={true} />
                {wishlistCount > 0 && (
                    <>
                        {wishlistCount > maximumCount ? (
                            <span className={`${PREFIX}-wishlist-badge ${PREFIX}-wishlist-max`}>{maximumCount}+</span>
                        ) : (
                            <span className={`${PREFIX}-wishlist-badge`}>{wishlistCount}</span>
                        )}
                    </>
                )}
                {wishlistLabel()}
            </a>
        </div>
    )
}

Wishlist.defaultProps = {
    label: '',
}

Wishlist.propTypes = {
    wishlistUrl: PropTypes.string,
    a11yWishlist: PropTypes.string,
    wishlistCount: PropTypes.number,
    path: PropTypes.string,
    maximumCount: PropTypes.number,
    label: PropTypes.string,
    featureType: PropTypes.string,
    ariaLabelWishlist: PropTypes.string,
}

export default Wishlist
