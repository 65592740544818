import { roundOfToTwoDecimals } from './roundOfToTwoDecimals'

/**
 * Returns base total amount
 * @param {number} rewardsPercentage
 * @param {number} currentPriceValue
 * @param {number} quantity
 * @param {number} pteOfferValue
 * @param {number} selectRewardsValue
 * @param {number} rewardsBoostValue
 * @return {number}
 */

export const getPTEBaseTotal = (
    rewardsPercentage: number,
    currentPriceValue: number,
    quantity = 1,
    pteOfferValue = 0,
    selectRewardsValue = 0,
    rewardsBoostValue = 0,
): number => {
    return (
        roundOfToTwoDecimals(Number(rewardsPercentage * currentPriceValue * quantity)) +
        roundOfToTwoDecimals(Number(pteOfferValue)) +
        roundOfToTwoDecimals(Number(selectRewardsValue)) +
        roundOfToTwoDecimals(Number(rewardsBoostValue))
    )
}
