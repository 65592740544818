export const MERGE_LOYALTY_CARD_ERROR = 'MERGE_LOYALTY_CARD_ERROR'
export const CREDIT_CARD_ASSOCIATION_SUCCESS = 'CREDIT_CARD_ASSOCIATION_SUCCESS'
export const CREDIT_CARD_ASSOCIATION_ERROR = 'CREDIT_CARD_ASSOCIATION_ERROR'
export const CLEAR_CREDIT_CARD_ASSOCIATION_ERROR = 'CLEAR_CREDIT_CARD_ASSOCIATION_ERROR'
export const VALIDATE_LOYALTY_SUCCESS = 'VALIDATE_LOYALTY_SUCCESS'
export const VALIDATE_LOYALTY_ERROR = 'VALIDATE_LOYALTY_ERROR'
export const CLEAR_VALIDATE_LOYALTY_ERROR = 'CLEAR_VALIDATE_LOYALTY_ERROR'
export const VALIDATE_LOYALTY_EXTENDED_SUCCESS = 'VALIDATE_LOYALTY_EXTENDED_SUCCESS'
export const VALIDATE_LOYALTY_EXTENDED_ERROR = 'VALIDATE_LOYALTY_EXTENDED_ERROR'
export const CLEAR_VALIDATE_LOYALTY_EXTENDED_ERROR = 'CLEAR_VALIDATE_LOYALTY_EXTENDED_ERROR'
export const CLEAR_MERGE_LOYALTY_CARD_ERROR = 'CLEAR_MERGE_LOYALTY_CARD_ERROR'
export const CLEAR_MERGE_LOYALTY_CARD = 'CLEAR_MERGE_LOYALTY_CARD'
export const CLEAR_MERGE_LOYALTY_VALIDATE = 'CLEAR_MERGE_LOYALTY_VALIDATE'
export const MERGE_LOYALTY_CARD_SUCCESS = 'MERGE_LOYALTY_CARD_SUCCESS'
