import { ActionType, getType } from 'typesafe-actions'
import { OfferCarouselWrapperProps } from '../../components/OfferCarousel/OfferCarousel.type'

import * as actions from '../actionCreators'
import {
    offersFetchInProgressAction,
    offersSuccessAction,
    offersFailureAction,
    offersActivateFailureAction,
    offersActivateSuccessAction,
    clearOffersActivateAction,
    clearOfferActivationErrorAction,
    targetedOfferActivateSuccessAction,
    updateFilteredOffersSortingAction,
    filteredOffersSuccessAction,
    updateFilteredOffersAction,
    filteredOffersFailureAction,
    setLoyaltyOffersSuccessAction,
} from '../actionCreators'
import { EmailOfferActivationErrorResponse } from '../models/emailOfferActivation.interface'
import { CustomerOffers, OfferActivation, Offers, TargetedOfferActivation } from '../models/offers.interface'

type Action = ActionType<typeof actions>

export interface OffersState {
    offersData: CustomerOffers | null
    offersActivatedData: OfferActivation | null
    targetedOfferActivatedData: TargetedOfferActivation[] | null
    offersActivatedError: EmailOfferActivationErrorResponse | null
    offersError: boolean | null
    offersFetchInProgress: boolean
    filteredOffersData: FilteredOffers | null
    loyaltyOffersProps: OfferCarouselWrapperProps
}

export interface FilteredOffers {
    filteredOffers?: Offers[]
    filteredOffersLength?: number
    sortByFilter?: string
}

export const initialState: OffersState = {
    offersData: {} as CustomerOffers,
    offersActivatedData: {} as OfferActivation,
    targetedOfferActivatedData: {} as TargetedOfferActivation[],
    offersActivatedError: {} as EmailOfferActivationErrorResponse,
    offersError: false,
    filteredOffersData: {} as FilteredOffers,
    offersFetchInProgress: false,
    loyaltyOffersProps: {} as OfferCarouselWrapperProps,
}

/* eslint-disable complexity */
export const offersReducer = (state: OffersState = initialState, action: Action): OffersState => {
    switch (action.type) {
        case getType(offersFetchInProgressAction): {
            return { ...state, offersFetchInProgress: action.payload, offersError: null }
        }
        case getType(offersSuccessAction): {
            return { ...state, offersData: action.payload, offersError: null, offersFetchInProgress: false }
        }
        case getType(offersFailureAction): {
            return { ...state, offersData: null, offersError: true, offersFetchInProgress: false }
        }
        case getType(offersActivateSuccessAction): {
            return { ...state, offersActivatedData: action.payload, offersActivatedError: null }
        }
        case getType(offersActivateFailureAction): {
            return { ...state, offersActivatedData: null, offersActivatedError: action.payload }
        }
        case getType(clearOffersActivateAction): {
            return { ...state, offersActivatedData: null }
        }
        case getType(clearOfferActivationErrorAction): {
            return { ...state, offersActivatedError: null }
        }
        case getType(targetedOfferActivateSuccessAction): {
            return { ...state, targetedOfferActivatedData: action.payload, offersActivatedError: null }
        }
        case getType(filteredOffersSuccessAction): {
            return { ...state, filteredOffersData: action.payload, offersError: false }
        }
        case getType(updateFilteredOffersSortingAction): {
            return {
                ...state,
                offersError: false,
                filteredOffersData: {
                    ...state.filteredOffersData,
                    sortByFilter: action.payload,
                },
            }
        }
        case getType(updateFilteredOffersAction): {
            return {
                ...state,
                offersError: false,
                filteredOffersData: {
                    ...state.filteredOffersData,
                    filteredOffers: action.payload,
                    filteredOffersLength: action.payload.length,
                },
            }
        }
        case getType(filteredOffersFailureAction): {
            return { ...state, filteredOffersData: null, offersError: true }
        }
        case getType(setLoyaltyOffersSuccessAction): {
            return { ...state, loyaltyOffersProps: action.payload.data }
        }
        default:
            return state
    }
}
