export const generateDOBStr = (day: string | number, month: string | number, year: string | number): string => {
    const singleDigitLength = 1
    const monthToPass = month?.toString().length === singleDigitLength ? `0${month}` : `${month}`
    const dayToPass = day.toString().length === singleDigitLength ? `0${day}` : `${day}`
    return `${year}-${monthToPass}-${dayToPass}`
}

export const generateDayMonthYearFromDOBStr = (dob: string): { day: number; month: number; year: number } => {
    const monthIndex = 1
    const dayIndex = 2
    const valStrings = dob.split('-')
    return {
        year: Number(valStrings[0]),
        month: Number(valStrings[monthIndex]),
        day: Number(valStrings[dayIndex]),
    }
}
