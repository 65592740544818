import PageHelper from '../../helpers/pageHelpers/page.helper'
import getQueryString from '../../utils/getQueryString'

/**
 * function to get store id from url
 * @param {string} queryParams
 * @return {string}
 */
export const getStoreId = (queryParams: string): string => {
    const storeIdFromUrl = new PageHelper().primaryId
    const storeIdFromParam = getQueryString(queryParams, 'storeId')
    try {
        if (parseInt(storeIdFromUrl)) {
            return storeIdFromUrl
        } else {
            return storeIdFromParam
        }
    } catch (_) {
        return storeIdFromParam
    }
}
