import appCacheService from '../../utils/appCacheService'

/**
 * Sets redirect parameter in complete search GA to true and if fireCompleteSearch is on, fire an event complete-search
 * @return {function}
 */
export const setRedirect = () => (): void => {
    const data = appCacheService.completeSearchEventGA.get()
    if (data) {
        data.redirect = 'true'
        appCacheService.completeSearchEventGA.set(data)
    }
}
