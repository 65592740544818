import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Button from '../Button'
import Icon from '../Icon'
import { ModalProps } from './Modal.type'

/**
 * Modal component
 * @param {ModalProps} props
 * @return {JSX.Element} returns Modal component
 */
const Modal: React.FC<ModalProps> = ({ ...props }) => {
    const headerTitle = props.modalHeaderTitle ? props.modalHeaderTitle : ''
    const title = props.modalTitle ? props.modalTitle : headerTitle
    const titleClass = props.modalTitle
        ? `${PREFIX}-modal__header ${PREFIX}-modal__cta-header`
        : `${PREFIX}-modal__header`

    return (
        <div className={`${PREFIX}-modal`}>
            <button className={`${PREFIX}-modal__close`} aria-label="Close Modal">
                <Icon type="ct-close" size="md" path={props.path} />
            </button>
            {title && <h4 className={`${titleClass}`}>{title}</h4>}
            <div className={`${PREFIX}-modal__body`}>
                <p className={`${PREFIX}-modal__sub-header`}>{props.subTitle}</p>
                <p className={`${PREFIX}-modal__description`}>{props.description}</p>
            </div>
            {props.cta && (
                <div className={`${PREFIX}-modal__footer`}>
                    <div className={`${PREFIX}-modal__cta-btn`}>
                        <Button type="primary" size="small">
                            {props.btnText}
                        </Button>
                    </div>
                    <a href="/" className={`${PREFIX}-modal__link`} data-link-value={props.linkText}>
                        {props.linkText}
                    </a>
                </div>
            )}
        </div>
    )
}

Modal.propTypes = {
    modalHeaderTitle: PropTypes.string,
    modalTitle: PropTypes.string,
    path: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    btnText: PropTypes.string,
    cta: PropTypes.string,
    linkText: PropTypes.string,
}

export default Modal
