import { imgAttribute } from '../components/OfferCard/OfferCard.type'
import { magicNumber } from './magicNumber'

/**
 * get image attributes based on data component name passed
 * @param {string} path
 * @param {string} dataComponentName
 * @param {boolean} isLazyRequire
 * @return {imgAttribute}
 */
export const imageAttributes = (path: string, dataComponentName: string, isLazyRequire: boolean): imgAttribute => {
    const attributeObject: imgAttribute = {
        'data-component-name': dataComponentName,
    }
    if (isLazyRequire) {
        attributeObject['data-src'] = path
        attributeObject.className = 'lazyload'
    } else {
        attributeObject.src = path
        attributeObject.srcSet = path
    }
    return attributeObject
}

/**
 * Function to return image url appended with akamai policies
 * @param {string} source
 * @param {string} policyType
 * @param {function} returnPolicy
 * @return {string} image url
 */
export const createUrlWithPolicy = (
    source: string,
    policyType: string,
    returnPolicy: (policyType: string) => string,
): string => {
    return policyType
        ? `${source?.toString()?.split('?', magicNumber.ONE).toString()}${returnPolicy(policyType)}`
        : `${source}`
}

/**
 * Function to apply akamai image policy
 * @param {string} imageUrl
 * @param {string} imageComponentName
 * @param {boolean} _isLazyRequire
 * @param {function} returnPolicy
 * @return {imgAttribute}
 */
export const applyAkamaiPolicy = (
    imageUrl: string,
    imageComponentName: string,
    _isLazyRequire: boolean,
    returnPolicy: (policyType: string) => string,
): imgAttribute => {
    return imageAttributes(
        !!imageUrl && `${createUrlWithPolicy(imageUrl, imageComponentName, returnPolicy)}`,
        imageComponentName,
        _isLazyRequire,
    )
}
