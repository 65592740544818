import { ProductStatus } from '../../redux/models/cart.interface'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { GetWishlistErrorPropType, ItemInlineErrorType, OptionsOnCartItem } from '../ShoppingCart/ShoppingCart.type'
import { ItemAvailability } from '../../redux/models/product.interface'
export const WishlistErrorService = (() => {
    const displayAllItems = [
        OptionsOnCartItem.CHECK_NEAR_BY,
        OptionsOnCartItem.PRICE_AND_QUANTITY,
        OptionsOnCartItem.ADD_TO_CART,
        OptionsOnCartItem.IN_STOCK_TAG,
    ]

    const displayAllWithOutOfStock = [
        OptionsOnCartItem.CHECK_NEAR_BY,
        OptionsOnCartItem.PRICE_AND_QUANTITY,
        OptionsOnCartItem.ADD_TO_CART,
        OptionsOnCartItem.IN_STOCK_TAG,
        OptionsOnCartItem.STOCK_INFO,
        OptionsOnCartItem.OUT_OF_STOCK,
    ]

    /**
     * Frame the error response.
     * @param {boolean} showInlineError - true, if the item has error.
     * @param {string} errorMessage - toast error message.
     * @param {boolean} grayOutImage - if true, will apply opacity of 0.4 on the image.
     * @param {string[]} optionsToBeDisplayed - items to be displayed when the item as errors.
     * @param {string} fulfillmentDeliverError -if deliver mode  not available during checkout but initially was available
     *
     * @return {ItemInlineErrorType} returns error object, which then is passed to the productCartItem.
     */
    const _constructErrorResponse = (
        showInlineError: boolean,
        errorMessage: string,
        grayOutImage: boolean,
        optionsToBeDisplayed: string[],
        fulfillmentDeliverError = '',
    ): ItemInlineErrorType => {
        return {
            showInlineError,
            errorMessage,
            grayOutImage,
            optionsToBeDisplayed,
            fulfillmentDeliverError,
        }
    }

    /**
     * Function to check conditions when the product is discontinued.
     * @param {ProductStatus} productStatus - used to throw different error based on the status.
     * @param {Record<string, string>} message - message object
     * @return {ItemInlineErrorType}
     */
    const __discontinuedOOSConditions = (
        productStatus: ProductStatus,
        message: Record<string, string>,
    ): ItemInlineErrorType => {
        const { discontinuedMsg, tempDiscontinued } = message

        switch (productStatus) {
            case ProductStatus.FULLY_DISCONTINUED:
            case ProductStatus.DISCONTINUED_WHEN_OUT:
                return _constructErrorResponse(true, discontinuedMsg, true, [
                    OptionsOnCartItem.CHECK_NEAR_BY,
                    OptionsOnCartItem.STOCK_INFO,
                    OptionsOnCartItem.IN_STOCK_TAG,
                ])
            case ProductStatus.SEASONALLY_DISCONTINUED:
            case ProductStatus.TEMPORARY_DISCONTINUED:
                return _constructErrorResponse(true, tempDiscontinued, true, [
                    OptionsOnCartItem.CHECK_NEAR_BY,
                    OptionsOnCartItem.STOCK_INFO,
                    OptionsOnCartItem.IN_STOCK_TAG,
                ])
            default:
                return _constructErrorResponse(false, '', false, displayAllItems)
        }
    }

    /**
     * Check in stock in three stores.
     * @param {number} currentStoreQuantity - if > 0, there is some quantity in current store.
     * @param {number} dcQuantity - if > 0, there is some quantity in dc.
     * @param {number} orderableFromDC - is orderable from dc
     * @return {Record<string, unknown>} returns method to check different conditions.
     */
    const _checkIsStockInAllStore = (
        currentStoreQuantity: number,
        dcQuantity: number,
        orderableFromDC: boolean,
    ): Record<string, () => boolean> => {
        /**
         * Check whether current store is empty but not dc.
         * @return {boolean}
         */
        const _discontinuedAndOOSInStore = (): boolean => Boolean(!currentStoreQuantity)

        /**
         * check whether the item is only in distribution center
         * @return {boolean}
         */
        const _isStockOnlyInDC = (): boolean => Boolean(dcQuantity && orderableFromDC && _discontinuedAndOOSInStore())

        /**
         * Case where in stock in DC but not orderableFromDC.
         * @return {boolean}
         */
        const _isStockinDCNotOrderable = (): boolean =>
            Boolean(_discontinuedAndOOSInStore() && dcQuantity && !orderableFromDC)

        /**
         * Case where not in stock in DC.
         * @return {boolean}
         */
        const _isOOSInDC = (): boolean => Boolean(_discontinuedAndOOSInStore() && !dcQuantity)

        /**
         * Case where out of stock in store and out of stock in DC.
         * @return {boolean}
         */
        const _isOutOfStock = (): boolean => Boolean(_isStockinDCNotOrderable() || _isOOSInDC())
        return {
            _discontinuedAndOOSInStore,
            _isStockOnlyInDC,
            _isOutOfStock,
        }
    }

    /**
     * Checks multiple conditions and returns an error object.
     * @param {CartOrderEntries} itemData - Single Product Item Data.
     * @param {boolean} outOfStock - true, if all stores are out of stock.
     * @param {Record<string, string>} messageObject - object which holds all the error messages with respect to the product item.
     * @param {ItemAvailability} itemAvailabilityData - fulfillment data of the item.
     * @return {GetWishlistErrorPropType} - returns the error based on the conditions.
     */
    const _getItemAvailabilityError = ({
        extendedWishlistHandling,
        itemData,
        messageObject,
        itemAvailabilityData,
    }: GetWishlistErrorPropType): ItemInlineErrorType => {
        const { quantity, Corporate } = enableDestructOnUndefinedData(itemAvailabilityData) as ItemAvailability
        const { sellable, isBopisOnly, isSellableStarted, isSellableFinished } = itemData
        const storeQuantity = quantity
        const dcQuantity = Corporate?.Quantity || 0
        const orderableFromDC = Corporate?.orderableFromDC || false
        const checkInStock = _checkIsStockInAllStore(storeQuantity, dcQuantity, orderableFromDC)

        const isComingSoon = (): boolean => {
            return sellable && !isSellableStarted
        }

        const isNotSellableOnline = (): boolean => {
            return !sellable || (sellable && (isSellableFinished as boolean))
        }

        const extendedAvailabilityHandler = () => {
            if (isComingSoon()) {
                return _constructErrorResponse(false, '', false, [
                    OptionsOnCartItem.PRICE_AND_QUANTITY,
                    OptionsOnCartItem.IN_STOCK_TAG,
                    OptionsOnCartItem.STOCK_INFO,
                    OptionsOnCartItem.COMING_SOON,
                ])
            }

            if (checkInStock._isOutOfStock()) {
                return _constructErrorResponse(false, '', false, [
                    OptionsOnCartItem.PRICE_AND_QUANTITY,
                    OptionsOnCartItem.IN_STOCK_TAG,
                    OptionsOnCartItem.STOCK_INFO,
                    OptionsOnCartItem.OUT_OF_STOCK,
                ])
            }

            if (isNotSellableOnline()) {
                return _constructErrorResponse(false, '', false, [
                    OptionsOnCartItem.PRICE_AND_QUANTITY,
                    OptionsOnCartItem.IN_STOCK_TAG,
                    OptionsOnCartItem.STOCK_INFO,
                    OptionsOnCartItem.IN_STOCK_FOR_PURCHASE,
                ])
            }

            if (isBopisOnly) {
                return _constructErrorResponse(false, '', false, [
                    OptionsOnCartItem.PRICE_AND_QUANTITY,
                    OptionsOnCartItem.IN_STOCK_TAG,
                    OptionsOnCartItem.STOCK_INFO,
                    OptionsOnCartItem.ADD_TO_CART,
                    OptionsOnCartItem.IN_STOCK_FOR_PICK_UP,
                ])
            }

            return _constructErrorResponse(false, '', false, [
                OptionsOnCartItem.PRICE_AND_QUANTITY,
                OptionsOnCartItem.IN_STOCK_TAG,
                OptionsOnCartItem.ADD_TO_CART,
            ])
        }

        if (checkInStock._discontinuedAndOOSInStore() && itemData.productStatus !== ProductStatus.ACTIVE) {
            return __discontinuedOOSConditions(itemData.productStatus, messageObject)
        }

        if (extendedWishlistHandling) {
            return extendedAvailabilityHandler()
        } else {
            if (!sellable) {
                return _constructErrorResponse(true, messageObject.noLongerAvailableMsg, true, [
                    OptionsOnCartItem.IN_STOCK_TAG,
                ])
            }
            if (itemData.productStatus === ProductStatus.ACTIVE) {
                if (checkInStock._isStockOnlyInDC()) {
                    return _constructErrorResponse(false, '', false, displayAllWithOutOfStock)
                }
                if (checkInStock._isOutOfStock()) {
                    return _constructErrorResponse(true, messageObject.oosStoreInStockDc, true, [
                        OptionsOnCartItem.CHECK_NEAR_BY,
                        OptionsOnCartItem.IN_STOCK_TAG,
                        OptionsOnCartItem.STOCK_INFO,
                        OptionsOnCartItem.OUT_OF_STOCK,
                    ])
                }
            }
        }

        return _constructErrorResponse(false, '', false, displayAllItems)
    }
    return {
        getError: (props: GetWishlistErrorPropType): ItemInlineErrorType => _getItemAvailabilityError({ ...props }),
    }
})()
