import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { PostalCodeProps } from './PostalCode.type'
import TextInput from '../TextInput'
import { PREFIX, previousElementName } from '../config'
import Button from '../Button'
import ReactModal from '../ReactModal'
import Icon from '../Icon'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { stringKeyCodes } from '../../utils'

/**
 * PostalCodeModal component
 * @param {PostalCodeProps} props
 * @return {JSX.Element} returns ReactModal and TextInput
 */
const PostalCodeModal: React.FC<PostalCodeProps> = ({ ...props }): JSX.Element => {
    const {
        isOpen,
        modalTitle,
        modalInputPlaceholder,
        primaryButtonLabel,
        tertiaryButtonLabel,
        a11ypcmCloseLabel,
        setPCM,
        onChangePCAction,
        selectedPostalCode,
        modalSecondaryTitle,
        pcmErrorMsg,
        emptyPostalCodeMessage,
        postalCodeRegex,
        showDescription,
        validatePostalCode,
        showPCMSpinner,
        setPCMErrorMsg,
        popUpTitleId,
    } = props
    const [postalCode, setPostalCode] = useState(selectedPostalCode)
    const [postalInputCode, setPostalInputCode] = useState('')
    const [errorMsg, setErrorMsg] = useState(pcmErrorMsg)
    const componentClassName = `${PREFIX}-postal-code-modal`

    /**
     * function to be called on postal code change action
     */
    const onPCChangeClick = (): void => {
        if (postalCode) {
            const validate = postalCodeRegex.test(postalCode)
            setErrorMsg(!validate ? emptyPostalCodeMessage : '')
            if (onChangePCAction && validate) {
                setPCMErrorMsg && setPCMErrorMsg('')
                onChangePCAction(postalCode)
            }
        } else {
            setErrorMsg(emptyPostalCodeMessage)
        }
    }

    useEffect(() => {
        if (isOpen && (!selectedPostalCode || pcmErrorMsg)) {
            setPostalCode(postalInputCode || postalCode)
        } else {
            setPostalInputCode('')
        }
        setErrorMsg(pcmErrorMsg ? pcmErrorMsg : '')
    }, [isOpen, pcmErrorMsg, postalInputCode, selectedPostalCode, postalCode])

    useEffect(() => {
        setPostalCode(selectedPostalCode)
        !selectedPostalCode && setErrorMsg('')
    }, [isOpen, selectedPostalCode])

    useEffect(() => {
        setErrorMsg(pcmErrorMsg)
    }, [pcmErrorMsg])

    const closeHandler = () => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName: `${PREFIX}-react-modal` })
        setPCM()
        const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    const modalRef = useRef(null)
    useClickOutsideClose(modalRef, closeHandler, isOpen, true)

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === stringKeyCodes.enter) {
            onPCChangeClick()
        }
    }

    return (
        <ReactModal closeHandler={closeHandler} isOpen={isOpen}>
            <div
                role="dialog"
                aria-labelledby={popUpTitleId}
                aria-modal="true"
                className={componentClassName}
                ref={modalRef}>
                <div className={`${componentClassName}__close-container`}>
                    <button
                        className={`${componentClassName}__close-btn`}
                        onClick={closeHandler}
                        data-testid="postal-code-close-handler"
                        aria-label={a11ypcmCloseLabel}>
                        <Icon type="ct-close" size="lg"></Icon>
                    </button>
                </div>
                <div className={`${componentClassName}__content`}>
                    <h3 id={popUpTitleId} className={`${componentClassName}__title`}>
                        {selectedPostalCode ? modalSecondaryTitle : modalTitle}
                    </h3>
                    {showDescription && (
                        <p
                            className={`${componentClassName}__description`}
                            dangerouslySetInnerHTML={{ __html: modalSecondaryTitle }}></p>
                    )}
                    <TextInput
                        id="postal-code-modal"
                        label={modalInputPlaceholder}
                        value={postalInputCode?.length ? postalInputCode : postalCode}
                        error={errorMsg}
                        maxLength={7}
                        onChange={val => setPostalCode(validatePostalCode(val))}
                        size="default"
                        quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                        onKeyDown={onKeyDownHandler}
                    />
                    <div className={`${componentClassName}__footer-buttons`}>
                        <Button
                            size="medium"
                            onClick={onPCChangeClick}
                            label={primaryButtonLabel}
                            showSpinner={showPCMSpinner}></Button>
                        <Button type="tertiary" onClick={closeHandler}>
                            {tertiaryButtonLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}

PostalCodeModal.defaultProps = {
    showDescription: false,
}

PostalCodeModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalDescription: PropTypes.string,
    modalInputPlaceholder: PropTypes.string.isRequired,
    primaryButtonLabel: PropTypes.string.isRequired,
    tertiaryButtonLabel: PropTypes.string.isRequired,
    pcmErrorMsg: PropTypes.string.isRequired,
    a11ypcmCloseLabel: PropTypes.string.isRequired,
    setPCM: PropTypes.func,
    onChangePostalCode: PropTypes.func,
    onChangePCAction: PropTypes.func,
    selectedPostalCode: PropTypes.string,
    modalSecondaryTitle: PropTypes.string.isRequired,
    emptyPostalCodeMessage: PropTypes.string.isRequired,
    postalCodeRegex: PropTypes.instanceOf(RegExp).isRequired,
    showDescription: PropTypes.bool,
    validatePostalCode: PropTypes.func,
    showPCMSpinner: PropTypes.bool,
    setPCMErrorMsg: PropTypes.func,
}

export default PostalCodeModal
