import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { TransactionColumnLogoProps, BannerImagePath } from './TransactionColumnLogo.type'

const TransactionColumnLogo: React.FC<TransactionColumnLogoProps> = props => {
    const { banner, bannerImageList } = props
    const [bannerDetails, setBannerDetails] = useState({} as BannerImagePath)
    const componentName = `${PREFIX}-triangle-transaction-history`

    useEffect(() => {
        setBannerDetails(
            bannerImageList && bannerImageList.find((imageList: BannerImagePath) => banner === imageList.bannerId),
        )
    }, [bannerImageList, banner])

    return (
        <div className={`${componentName}-banner-logo`}>
            {
                <img
                    data-component-name="transaction-history"
                    src={bannerDetails?.bannerImagePath}
                    alt={bannerDetails?.bannerImageAlt}
                />
            }
        </div>
    )
}

TransactionColumnLogo.propTypes = {
    banner: PropTypes.string.isRequired,
    bannerImageList: PropTypes.arrayOf(
        PropTypes.exact({
            bannerImagePath: PropTypes.string.isRequired,
            bannerId: PropTypes.string.isRequired,
            bannerImageAlt: PropTypes.string.isRequired,
        }),
    ).isRequired,
}

export default TransactionColumnLogo
