/**
 * return sort label and default selection
 * @param {string} label
 * @param {string} brand
 * @param {string} category
 * @return {label: string, selected: boolean}
 */

const getOfferSorting = (label: string, brand: string, category: string) => {
    return brand
        ? [
              {
                  label: label,
                  selected: true,
              },
              {
                  label: brand,
                  selected: false,
              },
              {
                  label: category,
                  selected: false,
              },
          ]
        : [
              {
                  label: label,
                  selected: true,
              },
              {
                  label: category,
                  selected: false,
              },
          ]
}

export default getOfferSorting
