import { Promo, AnalyticsDomSelector, TriggerEventType } from './components'
import { getProvider } from './providers'
import { PromotionRecord } from './providers/provider.type'

/**
 * Analytics initialization for AEM based components
 */
function initAnalytics() {
    const promos = getAllPromos()
    const analyticsProvider = getProvider()
    if (promos?.length) {
        promos.forEach((node: Node) => {
            // check node is type of Element
            if (node.nodeType === Node.ELEMENT_NODE) {
                const analyticsData = getDataAnalytics(node as HTMLElement)
                const promo = new Promo(
                    node as HTMLElement,
                    [TriggerEventType.PREVIEW],
                    analyticsData,
                    analyticsProvider,
                )
                promo.registerListeners()
                promo.checkAndRegisterCtas()
            }
        })
    }
}

/**
 * Get all promos
 * @return {NodeList | null}
 */
function getAllPromos(): NodeList | null {
    return document.querySelectorAll(AnalyticsDomSelector.promo)
}

/**
 * provide analytics data for provided element
 * @param {HTMLElement} element
 * @return {PromotionRecord}
 */
function getDataAnalytics(element: HTMLElement): PromotionRecord | undefined {
    return element.dataset['analytics']
        ? (JSON.parse(element.dataset['analytics'].replace(/'/g, '"')) as PromotionRecord)
        : undefined
}

export { initAnalytics }
