import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/aoaDateTimeStep.actionCreators'

import { saveDateSlotsAction, clearAOADateSlotsAction } from '../actionCreators/aoaDateTimeStep.actionCreators'

import { AOADateTimeState } from '../models/aoaDateTimeStep.interface'

type Action = ActionType<typeof actions>

export const initialState: AOADateTimeState = {
    dateSlots: null,
}

export const aoaDateTimeStepReducer = (state: AOADateTimeState = initialState, action: Action): AOADateTimeState => {
    switch (action.type) {
        case getType(saveDateSlotsAction):
            return { ...state, dateSlots: action.payload }
        case getType(clearAOADateSlotsAction):
            return { ...state, dateSlots: null }
        default:
            return state
    }
}
