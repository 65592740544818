import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { magicNumber } from '../../utils/magicNumber'
import { PREFIX } from '../config'
import Icon from '../Icon'
import { keyCodes } from '../../utils/keyCodes'
import { useClickOrFocusOutsideForCallback } from '../../hooks/useClickOrFocusOutsideForCallback'
import { JumpListProps, JumpListItem } from './JumpList.type'
import { CHANGE_INDEX, OptionListSize } from './JumpList.constant'
import JumpListSearch from './JumpListSearch/JumpListSearch'
import DropdownButton from '../Dropdown/DropdownButton/DropdownButton'
import { filterByRank } from './JumpList.helpers'

const NO_RESULTS_ID = String(magicNumber.MINUS_ONE)
const NVDA_DELAY = magicNumber.ONETHOUSAND

/**
 * This component should be used as searchable dropdown(JumpList).
 * If you need a regular dropdown list (dropdown menu) consider using "Dropdown" component.
 * @param {JumpListProps} props
 * @return {Element} JumpList component
 */
const JumpList: React.FC<JumpListProps> = ({ ...props }) => {
    const {
        dropdownId,
        dropdownTitle,
        dropdownList,
        dropdownIcon,
        size,
        path,
        type,
        isFilter,
        filterTitle,
        selectedItem,
        error,
        isOnlyErrorStyle = false,
        disabled,
        resetDropdown,
        prePopulateFirstItem,
        noResultsMessage,
        singleResultMessage,
        manyResultsMessage,
        searchMaxLength,
        searchPlaceholder,
        modifierClass,
        ariaLabel,
        allyDropDownNotSelected,
        allyClearIcon,
        allyCloseIcon,
        clearLabel,
        openDropdownList = false,
    } = props

    const [opened, setOpened] = useState(false)
    const [transformed, setTransformClass] = useState(false)
    const [currentHighlightedItem, setCurrentHighlightedItem] = useState(magicNumber.MINUS_ONE)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const searchContainerRef = useRef<HTMLDivElement>(null)
    const dropdownButtonRef = useRef<HTMLButtonElement>(null)
    const [shadowClass, setShadowClass] = useState('')
    const [dropIcon, setDropIcon] = useState(dropdownIcon)
    const updatedDropdownList = dropdownList
    const [displayList, setDisplayList] = useState(updatedDropdownList)
    const runOnce = useRef(0)
    const [showClearButton, setShowClearButton] = useState(false)
    const [suggestionLength, setSuggestionLength] = useState<number>(null)

    /**
     * Variable for display list label for screen reader
     */
    const displayListAriaLabel = useMemo(() => {
        switch (suggestionLength) {
            case null:
                return suggestionLength
            case magicNumber.ZERO:
                return noResultsMessage
            case magicNumber.ONE:
                return singleResultMessage
            default:
                return manyResultsMessage?.replace('[number]', String(suggestionLength))
        }
    }, [suggestionLength, noResultsMessage, singleResultMessage, manyResultsMessage])

    /**
     * Function to return the jumplist title. It returns selected value if filter is true. Else dropdownTitle from props
     * @return {string}
     */
    const getTitle = useCallback(() => {
        if (isFilter) {
            let selectedTitle = ''
            const dropdownSelectedItem = updatedDropdownList?.find((item: JumpListItem) => item.selected)
            if (dropdownSelectedItem) {
                selectedTitle = dropdownSelectedItem.label
            }
            return selectedTitle
        }
        return dropdownTitle
    }, [updatedDropdownList, isFilter, dropdownTitle])

    const updatedSelectedTitle = getTitle()
    const [selected, setSelected] = useState(updatedSelectedTitle)

    /**
     * Function used to set dropdown selected element index
     * @return {number | null}
     */
    const selectedIndex = useMemo(
        (): number =>
            Math.max(
                displayList?.findIndex((item: JumpListItem) => item.selected),
                magicNumber.ZERO,
            ),
        [displayList],
    )

    const scrollIndexToSeeFirst = Math.max(selectedIndex - CHANGE_INDEX, magicNumber.ZERO)

    /**
     * close dropdown event
     * set the dropdown to collapsed view
     */
    const closeDropdown = (): void => {
        setCurrentHighlightedItem(magicNumber.MINUS_ONE)
        setOpened(false)
        setShowClearButton(false)
        setSuggestionLength(null)
    }

    const dropdownListDependency = JSON.stringify(dropdownList)

    useClickOrFocusOutsideForCallback([dropdownRef, searchContainerRef], closeDropdown, opened)

    /**
     * Function to return the jumplist title. It returns selected value if filter is on. Else dropdownTitle from props
     * @return {string}
     */
    const itemSelect = useCallback(
        (item: JumpListItem, index) => {
            // if the emptyItem is selected, then replace the id to send an empty value.
            if (item.id !== '') {
                setOpened(false)
                if (item.label !== selected) {
                    selectedItem?.(item)
                }

                const currIndex = index as number
                setCurrentHighlightedItem(currIndex)
                setSelected(item.label)
                updatedDropdownList.forEach(value => (value.selected = value.label === item.label))
            }
        },
        [selectedItem, updatedDropdownList, selected],
    )

    /**
     * lifecyle hook to set the display list on update of list items
     */
    useEffect(() => {
        setDisplayList(updatedDropdownList)
    }, [updatedDropdownList])

    /**
     * lifecyle hook to open dropdown list automatically
     */
    useEffect(() => {
        if (
            openDropdownList &&
            !disabled &&
            updatedDropdownList.length > magicNumber.ONE &&
            !updatedDropdownList.find((item: JumpListItem) => item.selected)
        ) {
            setOpened(true)
        }
    }, [openDropdownList, disabled, updatedDropdownList])

    /**
     * lifecyle hook to reset the dropdownTitle
     */
    useEffect(() => {
        if (!resetDropdown) {
            setSelected(dropdownTitle)
        }
    }, [resetDropdown, dropdownTitle])

    /**
     * focus the dropdown button when not in focus so than its accessible via keyboard
     */
    useEffect(() => {
        !opened && dropdownButtonRef?.current?.focus({ preventScroll: true })
    }, [opened])

    /**
     * lifecyle hook to show the updated title for dropdown
     */
    useEffect(() => {
        if (updatedSelectedTitle && updatedSelectedTitle.length && updatedSelectedTitle !== dropdownTitle) {
            setSelected(updatedSelectedTitle)
        } else {
            setSelected(getTitle())
            setTransformClass(false)
        }
    }, [dropdownListDependency, getTitle, dropdownTitle, updatedSelectedTitle])

    /**
     * lifecyle hook to update the chevron icon based on jumpList opened or collapsed state
     * and apply classes based on jumplist type
     */
    useEffect(() => {
        setDropIcon(opened ? 'ct-chevron-up' : 'ct-chevron-down')
        if (opened && type === 'primary') {
            setShadowClass(`${PREFIX}-shadow--small`)
        } else if (type !== 'primary') {
            setShadowClass(`${PREFIX}-shadow--small`)
        } else {
            setShadowClass('')
        }
        if (isFilter && !opened && selected) {
            setTransformClass(true)
        }
    }, [type, opened, selected, isFilter])

    /**
     * lifecyle hook to pre populated first item is its passed as true
     */
    useEffect(() => {
        if (
            runOnce.current === magicNumber.ZERO &&
            updatedDropdownList?.length === magicNumber.ONE &&
            !updatedDropdownList[0].selected &&
            prePopulateFirstItem
        ) {
            itemSelect(updatedDropdownList[magicNumber.ZERO], magicNumber.ZERO)
            runOnce.current = 1
        }
    }, [itemSelect, updatedDropdownList, prePopulateFirstItem])

    /**
     * lifecycle hook to set delay for display list label for screen reader
     */
    useEffect(() => {
        if (opened) {
            const handler = setTimeout(() => {
                displayList[0]?.id === NO_RESULTS_ID ? setSuggestionLength(0) : setSuggestionLength(displayList.length)
            }, NVDA_DELAY)
            return () => {
                clearTimeout(handler)
            }
        }
    }, [displayList, opened])

    /**
     * dropdownButton click event
     * While clicking the dropdown setting the class to modal-open
     */
    const dropdownButtonClicked = () => {
        setDisplayList(updatedDropdownList)
        setCurrentHighlightedItem(magicNumber.MINUS_ONE)
        setOpened(!opened)
        scrollToSelected()
    }

    /**
     * Callback to scroll selection item container to element before selected one,
     * or it leaves scroll on the first element if selected absent or it is the first element.
     * It uses the memorized calculated index scrollIndexToSeeFirst
     */
    const scrollToSelected = useCallback(() => {
        const item = document.getElementById(`${dropdownId}${scrollIndexToSeeFirst}`)
        if (item) item.parentElement.scrollTop = item.offsetTop
    }, [dropdownId, scrollIndexToSeeFirst])

    useEffect(() => {
        if (opened && displayList.length > magicNumber.FIVE) {
            scrollToSelected()
        }
    }, [displayList, opened, scrollToSelected])

    /**
     * function to handle the DOWN, ESC and TAB key press for DropdownButton
     * @param {React.KeyboardEvent} event
     */
    const dropdownButtonKeyboardEventHandler = (event: React.KeyboardEvent) => {
        if (event.which === keyCodes.downArrow) {
            event.preventDefault()
            setDisplayList(updatedDropdownList)
            setCurrentHighlightedItem(magicNumber.MINUS_ONE)
            setOpened(true)
            scrollToSelected()
        } else if (event.which === keyCodes.esc) {
            event.preventDefault()
            closeDropdown()
        }
    }

    /**
     * function to handled the down arrow key and highlight next item in the list for JumpListItem
     * @param {React.KeyboardEvent} event
     */
    const jumpListItemKeyboardHandler = (event: React.KeyboardEvent) => {
        if (event.which === keyCodes.downArrow) {
            event.preventDefault()
            const nextItem =
                currentHighlightedItem === magicNumber.MINUS_ONE
                    ? selectedIndex
                    : Math.min(currentHighlightedItem + CHANGE_INDEX, updatedDropdownList.length - CHANGE_INDEX)
            setCurrentHighlightedItem(nextItem)
            const item = document.getElementById(`${dropdownId}${nextItem}`)
            item.focus()
        } else if (event.which === keyCodes.upArrow) {
            event.preventDefault()
            const previousItemIndex = currentHighlightedItem - CHANGE_INDEX
            const supposedElementId =
                previousItemIndex >= 0 ? `${dropdownId}${previousItemIndex}` : `search_${dropdownId}`
            const item = document.getElementById(supposedElementId)

            setCurrentHighlightedItem(Math.max(previousItemIndex, magicNumber.MINUS_ONE))

            item.focus()
        } else if (event.which === keyCodes.esc) {
            closeDropdown()
        }
    }

    /**
     * function used to toggle error class
     * @return {string}
     */
    const errorClass = () => (error || isOnlyErrorStyle ? `${PREFIX}-jumplist__button--error` : '')

    /**
     * Method used to show error elements
     * @return {JSX.Element}
     */
    const showError = (): JSX.Element =>
        error && (
            <div className={`${PREFIX}-jumplist ${PREFIX}-jumplist-native__error`} role="alert" aria-label={error}>
                <Icon type="ct-warning" size="sm" path={path} />
                <span
                    className={`${PREFIX}-jumplist-text ${PREFIX}-jumplist-native__error-text`}
                    id={`${dropdownId}__error`}>
                    {error}
                </span>
            </div>
        )

    /**
     * Checks if modifier class is present then will append modifier class
     * else will return empty string
     * @return {string}
     */
    const getModifierClass = (): string => {
        return !!modifierClass ? modifierClass : ''
    }

    /**
     * return css class based on active state of the jumplist item
     * @param {JumpListItem} item
     * @param {number} index
     * @return {string}
     */
    const currentItemClass = (item: JumpListItem): string => {
        if (item.selected) {
            return `${PREFIX}-jumplist__content--active`
        } else if (item.isUnavailable) {
            return `${PREFIX}-jumplist__content--not-selected`
        } else return `${PREFIX}-jumplist__content--default`
    }

    /**
     * Filters the items based on the search string entered
     * @param {string} value
     */
    const filterDisplayList = (value: string) => {
        setShowClearButton(Boolean(value))
        let filteredList: JumpListItem[]
        setCurrentHighlightedItem(magicNumber.MINUS_ONE)
        if (value === '') {
            filteredList = updatedDropdownList || []
        } else {
            filteredList = filterByRank(updatedDropdownList, value)
        }
        if (!filteredList.length) {
            filteredList.push({
                id: NO_RESULTS_ID,
                label: noResultsMessage,
                selected: false,
            })
        }
        setDisplayList(filteredList)
    }

    /**
     * JumpList Item component
     * @param {JumpListItem} item
     * @param {number} index
     * @return {JSX.Element} returns jumplist Item component
     */
    const renderJumpListItem = (item: JumpListItem, index: number): JSX.Element => {
        return (
            <button
                key={index}
                onClick={() => {
                    itemSelect(item, index)
                }}
                onKeyDown={event => {
                    jumpListItemKeyboardHandler(event)
                }}
                id={`${dropdownId}${index}`}
                data-testid={`drpItem${index}`}
                type="button"
                data-qm-allow="true"
                disabled={item?.id === NO_RESULTS_ID}
                className={`${PREFIX}-jumplist__content-button ${currentItemClass(item)}`}
                aria-label={item?.id === NO_RESULTS_ID ? allyDropDownNotSelected : item?.label}
                title={item?.id === NO_RESULTS_ID ? allyDropDownNotSelected : item?.label}>
                {item?.label}
            </button>
        )
    }

    /**
     * JumpList component
     * @return {JSX.Element} returns renderJumpList
     */

    const renderJumpList = (): JSX.Element => {
        return (
            <div
                role="application"
                className={`${PREFIX}-jumplist ${shadowClass} ${getModifierClass()} ${
                    size === 'default' ? `${PREFIX}-jumplist--${size}-desktop` : `${PREFIX}-jumplist--${size}`
                }`}
                id={dropdownId}
                data-testid={`open_jumplist_${dropdownId}`}>
                {opened ? (
                    <JumpListSearch
                        id={`search_${dropdownId}`}
                        searchBoxPlaceholder={searchPlaceholder}
                        dropIcon={dropIcon}
                        path={path}
                        dropIconSize="lg"
                        clearLabel={clearLabel}
                        showClearButton={showClearButton}
                        onChange={filterDisplayList}
                        onClose={closeDropdown}
                        keyDownEvent={jumpListItemKeyboardHandler}
                        searchMaxLength={searchMaxLength}
                        allyClearIconLabel={allyClearIcon}
                        allyCloseIconLabel={allyCloseIcon}
                        searchContainerRef={searchContainerRef}
                    />
                ) : (
                    <DropdownButton
                        title={selected}
                        icon={dropIcon}
                        path={path}
                        disabled={disabled}
                        dropdownClick={() => {
                            dropdownButtonClicked()
                        }}
                        keyDownEvent={event => {
                            dropdownButtonKeyboardEventHandler(event)
                        }}
                        transformLabel={transformed}
                        filterTitle={filterTitle}
                        isFilter={isFilter}
                        opened={opened}
                        errorClass={errorClass()}
                        dropdownButtonref={dropdownButtonRef}
                        ariaLabel={ariaLabel}
                        id={error ? dropdownId : ''}
                    />
                )}
                <div
                    className="sr-only"
                    aria-atomic={true}
                    aria-live={suggestionLength == magicNumber.ONE ? 'polite' : 'assertive'}>
                    {displayListAriaLabel}
                </div>
                <div
                    className={`${PREFIX}-jumplist__content ${PREFIX}-jumplist__content--${props?.optionListSize} ${
                        opened ? 'show' : 'hide'
                    }`}
                    ref={dropdownRef}>
                    {displayList?.map((item: JumpListItem, index: number) => renderJumpListItem(item, index))}
                </div>
                {showError()}
            </div>
        )
    }

    return <>{renderJumpList()}</>
}

JumpList.defaultProps = {
    optionListSize: OptionListSize.SMALL,
}

JumpList.propTypes = {
    dropdownId: PropTypes.string,
    dropdownTitle: PropTypes.string,
    dropdownList: PropTypes.any,
    dropdownIcon: PropTypes.string,
    size: PropTypes.oneOf(['mini', 'xsmall', 'small', 'medium', 'large', 'default']),
    path: PropTypes.string,
    type: PropTypes.string,
    isFilter: PropTypes.bool,
    filterTitle: PropTypes.string,
    selectedItem: PropTypes.func,
    error: PropTypes.string,
    isOnlyErrorStyle: PropTypes.bool,
    disabled: PropTypes.bool,
    resetDropdown: PropTypes.bool,
    prePopulateFirstItem: PropTypes.bool,
    noResultsMessage: PropTypes.string,
    singleResultMessage: PropTypes.string,
    manyResultsMessage: PropTypes.string,
    searchMaxLength: PropTypes.number,
    searchPlaceholder: PropTypes.string,
    modifierClass: PropTypes.string,
    ariaLabel: PropTypes.string,
    allyDropDownNotSelected: PropTypes.string,
    allyClearIcon: PropTypes.string,
    allyCloseIcon: PropTypes.string,
    clearLabel: PropTypes.string,
}

export default JumpList
