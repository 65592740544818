/**
 * Replaces "[0]" in the string with dynamic value provided
 *
 * @param {string} message - message to display
 * @param {string | number} dynamicVal - replacing value with [0]
 * @return {string} - return string with replaced value
 */
export const replaceStrWithDynamicVal = (message: string, dynamicVal: string | number): string =>
    message ? message.replace('[0]', `${dynamicVal}`) : ''

/**
 * Replaces  multiple "[0]" in the string with dynamic value provided using replaceStrWithDynamicVal
 *
 * @param {string} message - message to display
 * @param {string[]} values - array of replacing value with [0]
 * @return {string} - return string with replaced value
 */
export const replaceMultipleStrWithDynamicVal = (message: string, values: string[]): string => {
    let finalString = message
    values.forEach(val => {
        finalString = replaceStrWithDynamicVal(finalString, val)
    })
    return finalString
}

/**
 * Replaces the value provided with "[0]"
 *
 * @param {string} message - message to display
 * @param {string} value - value replacing with [0]
 * @return {string} - return string with replaced value
 */
export const addDynamicToken = (message: string, value: string): string =>
    message ? message.replace(value, '[0]') : ''
