import appCacheService from '../../utils/appCacheService'
import { MagicNumber } from '../../analytics/analytics.type'
import { zonedTimeToUtc } from 'date-fns-tz'

/**
 * countdownBanner component
 * @param {Element} element
 */
const countdownBanner = (element: Element): void => {
    if (element) {
        const countdownbannerData = element.getAttribute('data-props')
        const timerData = countdownbannerData ? (JSON.parse(countdownbannerData) as Record<string, string>) : null
        const startTime = new Date(timerData?.startDateTime as string).getTime()
        const endDateTime = new Date(timerData?.endDateTime as string)

        let storeTimeZone = appCacheService.storeTimeZone.get()

        // If storeTimeZone is not available in local storage, end time is determined based off of user's time zone
        let endTime = storeTimeZone ? zonedTimeToUtc(endDateTime, storeTimeZone).getTime() : endDateTime.getTime()
        let currentTime = new Date().getTime()

        let difference = endTime - currentTime
        let timer: NodeJS.Timeout | string = ''
        window.addEventListener('storage', event => {
            if (event.oldValue !== event.newValue) {
                clearInterval(timer)
                storeTimeZone = appCacheService.storeTimeZone.get()
                endTime = storeTimeZone ? zonedTimeToUtc(endDateTime, storeTimeZone).getTime() : endDateTime.getTime()
                currentTime = new Date().getTime()
                difference = endTime - currentTime
                timer = setInterval(() => {
                    timerCalculation()
                }, MagicNumber.ONETHOUSAND)
                if (difference < 0) {
                    clearInterval(timer)
                }
            }
        })

        /**
         * Function to handle timer calculation
         * @return {void}
         */
        const timerCalculation = (): void => {
            if (currentTime > startTime) {
                if (difference > 0) {
                    // Time calculations for days, hours, minutes and seconds
                    const daysLeft = Math.floor(
                        difference /
                            (MagicNumber.TWENTYFOUR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONETHOUSAND),
                    )
                    const hoursLeft = Math.floor(
                        (difference -
                            daysLeft *
                                (MagicNumber.TWENTYFOUR *
                                    MagicNumber.SIXTY *
                                    MagicNumber.SIXTY *
                                    MagicNumber.ONETHOUSAND)) /
                            (MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONETHOUSAND),
                    )
                    const minutesLeft = Math.floor(
                        (difference -
                            daysLeft *
                                (MagicNumber.TWENTYFOUR *
                                    MagicNumber.SIXTY *
                                    MagicNumber.SIXTY *
                                    MagicNumber.ONETHOUSAND) -
                            hoursLeft * (MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONETHOUSAND)) /
                            (MagicNumber.SIXTY * MagicNumber.ONETHOUSAND),
                    )
                    const secondsLeft = Math.floor(
                        (difference -
                            daysLeft *
                                (MagicNumber.TWENTYFOUR *
                                    MagicNumber.SIXTY *
                                    MagicNumber.SIXTY *
                                    MagicNumber.ONETHOUSAND) -
                            hoursLeft * (MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONETHOUSAND) -
                            minutesLeft * (MagicNumber.SIXTY * MagicNumber.ONETHOUSAND)) /
                            MagicNumber.ONETHOUSAND,
                    )

                    const dateTimeTillMins =
                        `${daysLeft} ` +
                        `${timerData?.days as string} : ` +
                        `${hoursLeft} ` +
                        `${timerData?.hours as string} : ` +
                        `${minutesLeft} ` +
                        `${timerData?.mins as string}`

                    /**
                     * returns countdown timer text
                     * @return {string}
                     */
                    const showtimer = (): string => {
                        // Output the result in an element with id="countdown-timer"
                        return ((
                            document.getElementById('countdown-timer') as HTMLInputElement
                        ).innerHTML = `${dateTimeTillMins} : ${secondsLeft} ${timerData?.secs as string}`)
                    }

                    showtimer()

                    const allyElement = document.getElementById('ally-timer') as HTMLInputElement
                    // Output the timer text till minutes in an element with id="ally-timer"
                    if (allyElement.childNodes.length === 0 || secondsLeft === MagicNumber.FIFTYNINE) {
                        allyElement.innerHTML = `${dateTimeTillMins}`
                    }

                    difference = difference - MagicNumber.ONETHOUSAND
                } else {
                    clearInterval(timer)
                    const bannerEle = document.getElementsByClassName('nl-countdownbanner')[0] as HTMLInputElement
                    bannerEle.style.display = 'none'
                }
            }
        }

        timer = setInterval(() => {
            timerCalculation()
        }, MagicNumber.ONETHOUSAND)
    }
}

export { countdownBanner }
