import { CtaType } from './getCTA.type'

/**
 * function commonly used to display cta details
 * @param {string} label
 * @param {string} link
 * @param {string} target
 * @param {string} a11yLabel
 * @return {CtaType}
 */
export const getCTA = (label: string, link: string, target: string, a11yLabel: string): CtaType => {
    return { label, link, target, a11yLabel }
}
