import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    useGlobalResizeEvent,
    extractKeyValues,
    useGlobalScrollEvent,
    checkNestedProps,
    getCookieValue,
    searchConstants,
} from '@nl/lib'
import { magicNumber } from '@nl/lib/src/utils/magicNumber'
import { BREAKPOINTS, pageTypes, REF_URL_KEY } from '../../config'
import getPageType from '../../utils/getPageType'
import { CertonaInit } from '../CertonaInit'
import { CategoryInit } from '../CategoryInit'
import ProductPageInit from '../ProductPageInit'
import OrderDetailsPageInit from '../OrderDetails/OrderDetailsPageInit'
import OrderConfirmationPageInit from '../OrderConfirmation/ProductCards/OrderConfirmationPageInit'
import { RootState } from '../../redux/reducers'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { netWorkErrorCode, timeoutErrorCode } from '../../globalConstants/cdsErrorCodes'
import interceptorConfig from '../../services/ResponseInterceptors/interceptorConfig.service'
import PartialAuthModal from './PartialAuthModal'
import FullPageSpinnerComponent from '../FullPageSpinnerComponent'
import { showSpinner } from '../../redux/actions/spinner.action'
import { CartInit } from './CartInit'
import { WishlistInit } from './WishlistInit'
import { usePageAnalytics } from '../../analytics/hooks/usePageAnalytics'
import { globalPageLoadAnalyticsHoc } from '../Accounts/OrderHistory/GlobalPageLoadAnalytics.hoc'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { redirectToLoginPage, isRegistrationAuthPage } from './PageInit.helper'
import { dispatchToast } from '../ToastMessage/ToastMessage.helper'
import { ToastComponentNames } from '../../redux/models/toastMessage.interface'
import DisplayToast from '../Toast/DisplayToast'
import sessionStorageService from '../../utils/sessionStorageService'
import appCacheService from '../../utils/appCacheService'
import { updateSemaFoneEnabled } from '../../redux/actions/checkout.action'
import { signOutUser } from '../../redux/actions/user.profile.action'
import GigyaTokenRefresh from './GigyaTokenRefresh'
import { MagicNumber } from '../../analytics/analytics.type'
import StoreDetailPageInit from '../StoreDetailPageInit'
import showEdgeToEdgeBanner from '../ShowEdgeToEdgeBanner'
import GigyaInit from './GigyaInit'
import { lazyLoadImages, useLazyLoadImage } from '../../helpers/lazyLoadImage.helper'
import { docLoaded } from '../../redux/actions/docLoadStatus.action'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { gigyaLoginFormId } from '../GigyaScreen/gigya.constants'
import { categoryApiCompletedSelector, isFitmentRequiredSelector } from '../../redux/selectors/categoryIdData.selectors'
import { tokenStateSelector } from '../../redux/selectors/tokenState.selectors'
import { errorInterceptorDataSelector } from '../../redux/selectors/errorInterceptorData.selectors'
import { setToastAuthStatusAction } from '../../redux/actionCreators'
import { docLoadStatusSelector } from '../../redux/selectors/docLoadStatus.selectors'
import { isGigyaLoadedSelector } from '../../redux/selectors/gigyaScreenSetData.selectors'
import { useFetchInitialStoreListOnLoad, useFetchPreferredStoreDetails } from './hooks'
import PreferredStoreInit from './PreferredStoreInit'
import { storageData, browseOnlyCookieName, flyerPageUrlPattern } from '../../globalConstants'
import { setSelectedPreferredStoreId } from '../../redux/actions'
import { setBrowseOnly } from '../../redux/actionCreators/browseOnly.actionCreators'
import redirectionBasedOnHost from '../GigyaScreen/redirectionBasedOnHost'
import { emailSignUp, infoBanner } from './PageInit.constants'
import { completeSearchAnalytics } from '../../analytics/components/completeSearchAnalytics'
import BrazeInit from './BrazeInit'
import { PageInitType } from '../../global.type'

const PageInit: React.FC<PageInitType> = ({ ...props }): JSX.Element => {
    usePageAnalytics()
    const { categoryPages } = pageTypes
    const currentPageType = getPageType()
    const errorInterceptorData = useSelector(errorInterceptorDataSelector)
    const [isShowTimeoutErrorToast, setShowTimeoutErrorToast] = useState(false)
    const [isShowSuccessToast, setShowSuccessToast] = useState(false)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const isFitmentRequired = useSelector(isFitmentRequiredSelector)
    const categoryApiCompleted = useSelector(categoryApiCompletedSelector)
    const tokenState = useSelector(tokenStateSelector)
    const docLoadStatus = useSelector(docLoadStatusSelector)
    const isGigyaLoaded = useSelector(isGigyaLoadedSelector)
    const preferredStoreIdValue = storageData.preferredStoreId
    const [authentication, setAuthentication] = useState(
        commonContentAvailable?.authentication || ({} as typeof commonContentAvailable.authentication),
    )
    const { showSpinner: isShowspinner } = useSelector((state: RootState) => state.spinner)
    const [cartContent, setCartContent] = useState({} as typeof commonContentAvailable.cart)

    const isBrowseOnlyMod = useSelector((state: RootState) => state.browseOnly.enable)
    const { redirectURL } = useSelector((state: RootState) => state.searchData)
    const loginTimeOutValue = checkNestedProps(
        commonContentAvailable,
        'accountDashboard',
        'loginTimeOutValue',
    ) as string

    const browseOnlyCookie = getCookieValue(browseOnlyCookieName)
    const isBrowseOnlyMode = browseOnlyCookie === 'true'
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setBrowseOnly(isBrowseOnlyMode))
    }, [isBrowseOnlyMode, dispatch])

    const isDesktop = window.innerWidth > BREAKPOINTS.tabletMaxWidth
    const currentPage = window.location.href
    const isFlyerPage = currentPage.includes(flyerPageUrlPattern.en) || currentPage.includes(flyerPageUrlPattern.fr)

    /**
     * useEffect to open pdp in new tab when clicked from digital flyer desktop
     */
    useEffect(() => {
        if (redirectURL) {
            isFlyerPage && isDesktop ? window.open(redirectURL, '_blank') : window.location.assign(redirectURL)
        }
    }, [redirectURL, isDesktop, isFlyerPage])

    useEffect(() => {
        commonContentAvailable?.authentication && setAuthentication(commonContentAvailable.authentication)
        commonContentAvailable?.cart && setCartContent(commonContentAvailable.cart)
    }, [commonContentAvailable])

    const { loginSuccessMessage } = authentication
    const { tabChangeStoreMessage } = cartContent

    const loginSuccessToastProps = {
        success: true,
        options: {
            toastSuccessMessage: loginSuccessMessage,
            toastSuccessIcon: 'ct-notification-success',
        },
        enableTimer: true,
        resetPageVariables: () => {
            sessionStorageService.removeItem('isLoggedInForToast')
            dispatchToast(false, {}, ToastComponentNames.NONE, dispatch)
            dispatch(setToastAuthStatusAction(true))
        },
        toastCloseFunction: () => {
            setShowSuccessToast(false)
        },
        toastTimeOutValue: parseInt(loginTimeOutValue),
    }
    const tabChangeStoreSuccesToastProps = {
        success: true,
        options: {
            toastSuccessMessage: tabChangeStoreMessage,
            toastSuccessIcon: 'ct-notification-success',
        },
        enableTimer: true,
    }

    const docHandler = useCallback(() => {
        dispatch(docLoaded(true))
    }, [dispatch])

    useEffect(() => {
        if (docLoadStatus) {
            document.removeEventListener('load', docHandler)
        } else {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', docHandler)
            } else {
                docHandler()
            }
        }
    }, [docLoadStatus, docHandler])

    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const [[loginPageLink, linkRewardOptionsLink], [errorPopupDefaultSubtitle, errorPopupCloseButtonDefaultText]] =
        extractKeyValues(commonContentAvailable, [
            { globalLinks: ['loginPageLink', 'linkRewardOptionsLink'] },
            { fedErrors: ['errorPopupDefaultSubtitle', 'errorPopupCloseButtonDefaultText'] },
        ])
    const authenticatedUser = userProfileData && checkDataLength(userProfileData)
    const [isStoreChangeToastRequired, setIsStoreChangeToastRequired] = useState(false)
    const [showStoreChangeToast, setShowStoreChangeToast] = useState(false)
    const runOnceRedirection = useRef(MagicNumber.ZERO)

    useEffect(() => {
        if (
            isAuthFlowExecuted &&
            !authenticatedUser &&
            (pageTypes.account === currentPageType || window.location.pathname === linkRewardOptionsLink) &&
            runOnceRedirection.current === MagicNumber.ZERO
        ) {
            runOnceRedirection.current = MagicNumber.ONE
            !!loginPageLink && redirectToLoginPage(loginPageLink)
        }
    }, [isAuthFlowExecuted, loginPageLink, authenticatedUser, currentPageType, linkRewardOptionsLink])

    useEffect(() => {
        // category page
        const isCategoryPage = pageTypes.categoryPages.findIndex(pageType => pageType === currentPageType)
        if (isCategoryPage !== MagicNumber.MINUS_ONE && categoryApiCompleted) {
            // show edge to edge banner after category data api is completed, and if isFitmentRequired is false
            if (isFitmentRequired === false) {
                showEdgeToEdgeBanner()
            } else {
                // Removing edge-to-edge-banner element as they are AEM authored and not required if FitmentRequired is true
                const elements = document.querySelectorAll('.edge-to-edge-banner')
                if (elements.length > 0) {
                    elements[0].remove()
                }
            }
        } else {
            showEdgeToEdgeBanner()
        }
    }, [isFitmentRequired, currentPageType, categoryApiCompleted])

    const handleResize = useCallback(() => {
        const components: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-component-name]`)
        AkamaiImagePolicies.init(components)
    }, [])

    useEffect(() => {
        handleResize()
    }, [handleResize])

    useEffect(() => {
        if (isRegistrationAuthPage() && isAuthFlowExecuted && authenticatedUser) {
            const redirectURLParams = new URLSearchParams(window.location.search)
            const returnParam = redirectURLParams.get('returnURL')
            const returnURL = returnParam ? returnParam : ''
            const newUrl = new URL(returnURL, window.location.href)
            const redirectUrl = window.ODP.globalLinks.homePageLink
            redirectionBasedOnHost(newUrl, returnURL, redirectUrl)
        }
    }, [isAuthFlowExecuted, authenticatedUser, userProfileData])

    /**
     * Callback for Catch All Timeout
     * @param {number} time
     */
    const handleTimeoutError = useCallback(
        (time: number) => {
            setTimeout(() => {
                dispatch(showSpinner(false))
            }, time)
        },
        [dispatch],
    )

    /**
     * Callback for Catch All event listener on click 'submit' input
     */
    const handleErrorPopUp = useCallback(() => {
        document.addEventListener('submit', event => {
            const isLoginErrorPresent = sessionStorageService.getItem('isLoginErrorPresent')
            if (isLoginErrorPresent) {
                dispatch(showSpinner(true))
                handleTimeoutError(magicNumber.THOUSAND)
                sessionStorageService.removeItem('isLoginErrorPresent')
            }
            if (event.target && event.target.id !== gigyaLoginFormId) {
                dispatch(showSpinner(true))
                handleTimeoutError(magicNumber.THOUSAND)
            }
        })
    }, [handleTimeoutError, dispatch])

    useEffect(() => {
        handleErrorPopUp()
    }, [handleErrorPopUp])

    useEffect(() => {
        const completeSearchData = appCacheService.completeSearchEventGA.get()
        if (completeSearchData && document.querySelectorAll('.productlistingpanel').length === 0) {
            completeSearchAnalytics(searchConstants.COMPLETE_SEARCH_EVENT_EVENT, completeSearchData)
            appCacheService.completeSearchEventGA.delete()
        }
    }, [dispatch])

    useEffect(() => {
        if (
            checkDataLength(errorInterceptorData) &&
            ((errorInterceptorData as { data?: { errorCode?: number } })?.data?.errorCode?.toString() ===
                timeoutErrorCode ||
                (errorInterceptorData as { message?: string })?.message?.toLowerCase() ===
                    netWorkErrorCode.toLowerCase())
        ) {
            setShowTimeoutErrorToast(true)
        } else {
            const { interceptorObj } = interceptorConfig.getInterceptorTimeoutList() as {
                interceptorObj: {
                    resolveFunc: (data: Record<string, unknown>) => void
                }
            }
            interceptorObj?.resolveFunc(errorInterceptorData)
        }
        handleTimeoutError(magicNumber.THOUSAND)
    }, [errorInterceptorData, handleTimeoutError, dispatch])

    useEffect(() => {
        if (isAuthFlowExecuted) {
            const hashSearchWithoutSymbol = window.location.hash?.replace('#', '')
            const semafoneEnabledParam = hashSearchWithoutSymbol?.includes('semafoneModeEnable')
            const isSemafoneEnabled = !!semafoneEnabledParam
            const isSemafoneSetAlready = appCacheService.semafoneMode.get()
            const semaphoneVal = isSemafoneEnabled ? 'Y' : isSemafoneSetAlready
            !isSemafoneSetAlready && !!semaphoneVal && appCacheService.semafoneMode.set(semaphoneVal)
            if (!!semaphoneVal) {
                document.body.classList.add('semafone')
                dispatch(updateSemaFoneEnabled(semaphoneVal))
                if (!!userProfileData) dispatch(signOutUser(''))
            }
        }
    }, [dispatch, isAuthFlowExecuted, userProfileData])

    const urlParams = new URLSearchParams(window.location.search)
    const returnURLParam = urlParams.get('returnURL')
    const refUrl = sessionStorageService.getItem(REF_URL_KEY) ?? ''
    const isPrevPageLogin = refUrl.includes(loginPageLink)
    useEffect(() => {
        if (checkDataLength(commonContentAvailable)) {
            setShowSuccessToast(
                !returnURLParam &&
                    sessionStorageService.getItem('isLoggedInForToast') === 'true' &&
                    (tokenState === 'SUCCESS' || isPrevPageLogin),
            )
        }
    }, [commonContentAvailable, tokenState, returnURLParam, isPrevPageLogin])

    useEffect(() => {
        if (!isShowSuccessToast && checkDataLength(commonContentAvailable)) {
            sessionStorageService.removeItem('isLoggedInForToast')
        }
    }, [isShowSuccessToast, commonContentAvailable])

    const preferredStoreId = appCacheService.preferredStoreId.get()

    useFetchInitialStoreListOnLoad(preferredStoreId)
    const setIsPreferredStoreIdChanged = useFetchPreferredStoreDetails()

    /**
     * this useEffect will trigger
     * for showing the toast  when a spinner is not there and a visibility change happened
     */
    useEffect(() => {
        if (!isShowspinner && showStoreChangeToast) {
            dispatchToast(true, tabChangeStoreSuccesToastProps, ToastComponentNames.TAB_CHANGE_STORE_SUCCESS, dispatch)
            setShowStoreChangeToast(false)
        }
    }, [showStoreChangeToast, isShowspinner, dispatch, tabChangeStoreSuccesToastProps])

    /**
     * this callback  will happend
     * when a visibility (tab) change is happened to CART page
     */
    const visibilityChangeHandler = useCallback(() => {
        setShowStoreChangeToast(true)
        setIsStoreChangeToastRequired(false)
    }, [])
    /**
     * this useEffect is for whenever storechangeToast is required,
     * then need to trace the Tab change
     *
     */
    useEffect(() => {
        if (isStoreChangeToastRequired) {
            window.addEventListener('visibilitychange', visibilityChangeHandler)
            return () => window.removeEventListener('visibilitychange', visibilityChangeHandler)
        }
    }, [visibilityChangeHandler, isStoreChangeToastRequired])

    /**
     * this callback is for deciding if prefferestoreID change and the page is cart, Then Toast is required
     *
     */
    const onStorageUpdate = useCallback(
        (evt: StorageEventInit) => {
            const { key, newValue, oldValue } = evt
            const newValueStringified = String(newValue)

            if (key === preferredStoreIdValue && !isNaN(parseInt(newValueStringified)) && newValue !== oldValue) {
                dispatch(setSelectedPreferredStoreId(newValueStringified))
                setIsPreferredStoreIdChanged(true)
                if (currentPageType === pageTypes.cart) {
                    setIsStoreChangeToastRequired(true)
                }
            }
        },
        [preferredStoreIdValue, dispatch, setIsPreferredStoreIdChanged, currentPageType],
    )

    /**
     * Effect to trigger Event when there is a storage Change
     *
     */
    useEffect(() => {
        window.addEventListener('storage', onStorageUpdate)
        return () => window.removeEventListener('storage', onStorageUpdate)
    }, [onStorageUpdate])

    useGlobalResizeEvent(handleResize)
    useGlobalScrollEvent(lazyLoadImages)
    useLazyLoadImage()
    /**
     * Function to check if category component needs to be rendered.
     * @return {boolean}
     */
    const _renderCategoryComponent = (): boolean => categoryPages.includes(currentPageType)

    /**
     * Function to check if pdp page
     * @return {boolean}
     */
    const _renderProductPage = (): boolean => pageTypes.productPage === currentPageType

    /**
     * Function to check if order details page
     * @return {boolean}
     */
    const _renderOrderDetailsPage = (): boolean => pageTypes.orderDetails === currentPageType

    /**
     * Function to check if order confirmation page
     * @return {boolean}
     */

    const _renderOrderConfirmationPage = (): boolean => pageTypes.orderConfirmation === currentPageType

    const _renderStoreDetailPage = (): boolean => pageTypes.store === currentPageType

    /**
     * Render the dummy components based on the page types.
     * @return {JSX.Element}
     */
    const renderInitComponent = (): JSX.Element | null => {
        if (_renderCategoryComponent()) {
            return <CategoryInit />
        } else if (_renderProductPage()) {
            return <ProductPageInit />
        } else if (_renderOrderDetailsPage()) {
            return <OrderDetailsPageInit />
        } else if (_renderOrderConfirmationPage()) {
            return <OrderConfirmationPageInit />
        } else if (_renderStoreDetailPage()) {
            return <StoreDetailPageInit />
        }
        return null
    }

    /**
     *  Show a success login message
     */
    const showLoginSuccessToast = useCallback(() => {
        if (isShowSuccessToast) {
            dispatchToast(isShowSuccessToast, loginSuccessToastProps, ToastComponentNames.LOGIN_SUCCESS, dispatch)
            setShowSuccessToast(false)
        }
    }, [isShowSuccessToast, loginSuccessToastProps, dispatch])

    useEffect(() => {
        showLoginSuccessToast()
    }, [showLoginSuccessToast])

    /**
     * Remove links from footer in browse only mode
     */

    const removeLinksFromFooter = (): void => {
        const excludeLinksArray = document
            .querySelector('.footer-links__container')
            ?.querySelectorAll(`[data-hide-in-browse-only-mode="true"]`)
        excludeLinksArray?.length && excludeLinksArray.forEach(link => link.remove())
    }

    const hideInfoBanner = (): void => {
        const infoBannerElement = document.querySelector<HTMLElement>(infoBanner)
        if (infoBannerElement) {
            infoBannerElement.style.display = 'none'
        }
    }

    const showEmailSignUp = (): void => {
        const emailSignUpElement = document.querySelector<HTMLElement>(emailSignUp)
        if (emailSignUpElement?.parentElement) {
            emailSignUpElement.parentElement.style.display = 'block'
        }
    }

    useEffect(() => {
        if (isBrowseOnlyMod) {
            hideInfoBanner()
            removeLinksFromFooter()
        }
    }, [isBrowseOnlyMod])

    useEffect(() => {
        if (isGigyaLoaded) {
            showEmailSignUp()
        }
    }, [isGigyaLoaded])

    return (
        <>
            {renderInitComponent()}
            <PreferredStoreInit />
            <CertonaInit />
            <PartialAuthModal />
            <GigyaTokenRefresh />
            <GigyaInit isFlyerPage={isFlyerPage} />
            <CartInit />
            <WishlistInit />
            <FullPageSpinnerComponent />
            <BrazeInit {...props} />
            {isShowTimeoutErrorToast && (
                <DisplayToast
                    setShowTimeoutErrorToast={setShowTimeoutErrorToast}
                    errorMessage={errorPopupDefaultSubtitle as string}
                    errorPopupCloseButtonDefaultText={errorPopupCloseButtonDefaultText as string}
                />
            )}
        </>
    )
}
export default globalPageLoadAnalyticsHoc(PageInit)
