import React, { Validator } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { MegaNavigationTileProps, SaleCategoriesType } from './MegaNavigation.type'
import { getAriaPath } from './MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'

/* MegaNavigationTile component
 * @param {MegaNavigationTileProps} props
 * @return {JSX.Element} returns MediaGalleryViewer component
 */
const MegaNavigationTile: React.FC<MegaNavigationTileProps> = ({ ...props }): JSX.Element => {
    const { tileData } = props
    const SaleCategoriesTypeClasses: Record<string, string> = {
        sale: `${PREFIX}-mega-navigation-tile--sale`,
        clearance: `${PREFIX}-mega-navigation-tile--clearance`,
        arrival: `${PREFIX}-mega-navigation-tile--arrival`,
        autoService: `${PREFIX}-mega-navigation-tile--auto-service`,
    }

    return (
        <div className={`${PREFIX}-mega-navigation-tile`}>
            {tileData.map((tile: SaleCategoriesType, index: number) => {
                const { name, url, buttonCtaText } = tile
                return (
                    <div className={`${SaleCategoriesTypeClasses[tile.type]}`} key={`${index}${name}`}>
                        <div className={`${SaleCategoriesTypeClasses[tile.type]}__title`} key={`${index}${name}`}>
                            {name}
                        </div>
                        <a
                            aria-current={getAriaPath(encodeURI(url as string)) ? ariaType : false}
                            className={`${PREFIX}-button`}
                            href={encodeURI(url as string)}
                            data-link-value={buttonCtaText}>
                            {buttonCtaText}
                        </a>
                    </div>
                )
            })}
        </div>
    )
}

MegaNavigationTile.propTypes = {
    tileData: PropTypes.arrayOf<SaleCategoriesType>(
        PropTypes.exact({
            type: PropTypes.string,
        }).isRequired as Validator<SaleCategoriesType>,
    ).isRequired,
}

export default MegaNavigationTile
