import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators'
import { orderCancellationSuccess, orderCancellationFailure } from '../actionCreators/orderCancellation.actionCreators'
import { CartResponseErrorDTO } from '../models/cart.interface'
import { OrderCancellationData } from '../models/orderCancellation.interface'

export interface CancelOrderState {
    orderCancellationData: OrderCancellationData
    orderCancellationError: CartResponseErrorDTO
}

type Action = ActionType<typeof actions>

export const initialState: CancelOrderState = {
    orderCancellationData: {} as OrderCancellationData,
    orderCancellationError: {} as CartResponseErrorDTO,
}

export const cancelOrderReducer = (state: CancelOrderState = initialState, action: Action): CancelOrderState => {
    if (action.type === getType(orderCancellationSuccess)) {
        return { ...state, orderCancellationData: action.payload }
    } else if (action.type === getType(orderCancellationFailure)) {
        return { ...state, orderCancellationError: action.payload }
    }
    return state
}
