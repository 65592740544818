import { ClassListFunction } from './collapseControl.type'

/**
 * @param {string} clickableItem - Class name of the button which is clickable
 * @param {string} displayItem - Class name of the content which needs to be toggled when clicked on the button
 * @param {string} plusIcon - Class name of the Plus Icon which you want to change on click
 * @param {string} minusIcon - Class name of the Minus Icon which you want to change on click
 * @param {string} IconHideClass - Class name which is used to toggle between the plus and minus
 * @param {string} showSingleItem - Class name which is used to toggle between the content of different sections.
 */
export const collapseControl = (
    clickableItem: string,
    displayItem: string,
    plusIcon: string,
    minusIcon: string,
    IconHideClass: string,
    showSingleItem: string,
): void => {
    const accEle = document.getElementsByClassName(clickableItem) as HTMLCollectionOf<HTMLElement>
    const panelEle = document.getElementsByClassName(displayItem) as HTMLCollectionOf<HTMLElement>
    const plusEle = document.getElementsByClassName(plusIcon) as HTMLCollectionOf<HTMLElement>
    const minusEle = document.getElementsByClassName(minusIcon) as HTMLCollectionOf<HTMLElement>
    const ariaExpanded = 'aria-expanded'

    /**
     * handles click functions on button
     * @param {HTMLButtonElement} buttonEle - button element
     */
    const buttonClickHandler = (buttonEle: HTMLButtonElement): void => {
        buttonEle.onclick = function () {
            const firstEle = buttonEle.firstElementChild as HTMLElement
            const siblingEle = firstEle.nextElementSibling as HTMLElement
            const setClasses = !buttonEle.classList.contains('active')
            const nextEle = buttonEle.nextElementSibling as HTMLDivElement

            setClass(accEle, 'active', 'remove')
            setClass(panelEle, showSingleItem, 'remove')
            setClass(plusEle, IconHideClass, 'remove')
            setClass(minusEle, IconHideClass, 'remove')
            if (setClasses) {
                setClass(minusEle, IconHideClass, 'add')
                buttonEle.classList.toggle('active')
                buttonEle.setAttribute(ariaExpanded, 'true')
                firstEle.classList.toggle(IconHideClass)
                nextEle.classList.toggle(showSingleItem)
                siblingEle.classList.remove(IconHideClass)
            } else {
                buttonEle.classList.remove('active')
                buttonEle.setAttribute(ariaExpanded, 'false')
                setClass(panelEle, showSingleItem, 'remove')
                setClass(minusEle, IconHideClass, 'add')
                firstEle.classList.remove(IconHideClass)
                siblingEle.classList.add(IconHideClass)
            }
        }
    }

    for (let i = 0; i < accEle.length; i++) {
        const buttonEle = accEle[i] as HTMLButtonElement
        buttonEle.setAttribute(ariaExpanded, 'false')
        buttonClickHandler(buttonEle)
    }

    /**
     * sets class name for each html element wrt the function name
     * @param {HTMLCollectionOf<HTMLElement>} els - collection of HTML elements for setting classes
     * @param {string} className - Class name that needs to be set for a particular html element
     * @param {string} fnName - function name for which the class needs to be set on particular html element
     */
    const setClass = (els: HTMLCollectionOf<HTMLElement>, className: string, fnName: string) => {
        for (let i = 0; i < els.length; i++) {
            ;(els[i].classList[fnName] as ClassListFunction)(className)
        }
    }
}
