// This file contains ProductCard constants
export const constantValues = {
    currentPageInitialValue: 1,
    incrementValue: 1,
    positionIncrementValue: 1,
    minPrice: 'minPrice',
    pageToFetchValues: 2,
    plpUrl: 'loc=plp',
    productOptionLength: 1,
    initialSkeletonItems: 10,
    minSkuLength: 1,
    minTireWheelSizeOptionsForFilterDisplaying: 2,
    productPerPageForMobileAndTabletViewport: 24,
    productPerPageForDesktopViewport: 40,
}
