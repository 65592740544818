import { createAction } from 'typesafe-actions'

import {
    START_SPINNER_WITH_COUNTER,
    SET_SHOW_SPINNER,
    STOP_SPINNER_WITH_COUNTER,
} from '../types/spinner/spinner.actionTypes.constant'

export const setShowSpinner = createAction(SET_SHOW_SPINNER, (payload: boolean) => payload)<boolean>()
export const startSpinnerWithCounter = createAction(START_SPINNER_WITH_COUNTER)<void>()
export const stopSpinnerWithCounter = createAction(STOP_SPINNER_WITH_COUNTER)<void>()
