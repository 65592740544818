import { useMemo, useEffect, useState } from 'react'
import { BREAKPOINTS } from '../../config'
import { getParameterFromURL, isArrayNotEmpty, queryParameters } from '@nl/lib'
import { constantValues } from './ProductCard.constant'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'
import { useDispatch } from 'react-redux'
import { fillPriceAndAvailability } from '../../redux/actionCreators'
import priceAvailabilityService from '../../services/priceAvailabilityService'

export const useProductPerPage = (): number => {
    return useMemo(() => {
        const isDesktopViewport = window.innerWidth > BREAKPOINTS.tabletMaxWidth
        return isDesktopViewport
            ? constantValues.productPerPageForDesktopViewport
            : constantValues.productPerPageForMobileAndTabletViewport
    }, [])
}

export const useCurrentPage = () => {
    const pageNumberFromURL = Number(getParameterFromURL(queryParameters.page))

    const [currentPage, setCurrentPage] = useState(
        pageNumberFromURL > 0 ? pageNumberFromURL : constantValues.currentPageInitialValue,
    )

    useEffect(() => {
        if (!pageNumberFromURL) {
            setCurrentPage(constantValues.currentPageInitialValue)
        }
    }, [pageNumberFromURL, setCurrentPage])

    return { currentPage, setCurrentPage }
}

/**
 * @description hook to Intersect the product grid and perform priceAvailability call for products that in viewport
 * @param {boolean} featureFlag
 * @param {ProductDataTypeObj[]} products
 * @param {number} page
 * @return {Function}
 */
export const usePriceAvailabilityLazyLoad = (featureFlag: boolean, products: ProductDataTypeObj[], page: number) => {
    const pCodesSet = useMemo(() => new Set<string>(), [])
    const observedPCodes = useMemo(() => new Set<string>(), [])
    const [requestPCodes, setRequestPCodes] = useState<string[]>([])
    const dispatch = useDispatch()

    const productsObserver = useMemo(
        () =>
            featureFlag
                ? new IntersectionObserver(
                      (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                          const [first] = entries
                          const firstEntryTop = first.target.getBoundingClientRect().top

                          const observedProductRowPCodes = entries
                              .filter(productEntree => productEntree.isIntersecting)
                              .map(({ target }) => {
                                  observer?.unobserve(target)
                                  return target.id
                              })

                          const productsPerRow = entries.filter(
                              entry =>
                                  entry.isIntersecting && entry.target.getBoundingClientRect().top === firstEntryTop,
                          ).length

                          const pCodes = Array.from(pCodesSet)
                          const [lastObserved] = observedProductRowPCodes.slice(-1)
                          const cutIndex = pCodes.findIndex(pCode => pCode === lastObserved)

                          let nextRowPCodes: string[] = []
                          if (cutIndex !== -1 && productsPerRow) {
                              nextRowPCodes = pCodes.slice(cutIndex + 1, cutIndex + productsPerRow + 1)
                          }

                          const pCodesToRequest: string[] = []
                          observedProductRowPCodes.concat(nextRowPCodes).forEach(pCode => {
                              if (!observedPCodes.has(pCode)) {
                                  pCodesToRequest.push(pCode)
                                  observedPCodes.add(pCode)
                              }
                          })

                          setRequestPCodes(pCodesToRequest)
                      },
                      { root: null, rootMargin: '0px', threshold: 0.1 },
                  )
                : null,
        [pCodesSet, observedPCodes, featureFlag],
    )

    useEffect(() => {
        pCodesSet.clear()
        observedPCodes.clear()
        setRequestPCodes([])
    }, [observedPCodes, pCodesSet, page])

    useEffect(() => {
        if (isArrayNotEmpty(requestPCodes) && isArrayNotEmpty(products)) {
            const requestProducts = products.filter(({ code }) => requestPCodes.includes(code))
            setRequestPCodes([])

            priceAvailabilityService
                .fetchPriceAvailabilityData(requestProducts)
                .then(priceAvailabilityData => {
                    dispatch(fillPriceAndAvailability(priceAvailabilityData))
                })
                .catch(error => {
                    console.error(error)
                })
        }
    }, [requestPCodes, products, dispatch])

    /**
     * This useEffect will disconnect observer when ProductCart is destoyed
     */
    useEffect(() => {
        return () => {
            productsObserver?.disconnect()
        }
    }, [productsObserver])

    const observeProductByRef = (node: HTMLElement | null) => {
        if (productsObserver && node && !pCodesSet.has(node.id)) {
            productsObserver.observe(node)
            pCodesSet.add(node.id)
        }
    }

    return featureFlag ? observeProductByRef : null
}
