export const PREFIX = 'nl'
export const PATH = '/resources/global-icons.svg'

export const BREAKPOINTS = {
    mobileMaxWidth: 767, // anything above 768 will be treated as tablet
    tabletMaxWidth: 1279, // anything above 1279 will be treated as desktop,
    mobilePortrait: 414,
    mobileLandscape: 667,
    tabletPortrait: 768,
    tabletLandscape: 1025,
    desktopMin: 1200,
    desktopStandard: 1920,
    desktopLarg: 2560,
}

export const minInputVal = 1
export const maxInputVal = 99
export const incrementDecrementVal = 1
// TODO: Page type information should be app package level.
export const pageTypes = {
    searchPage: 'search',
    pdpPage: 'product',
    orderConfirmation: 'purchase',
    storeLocator: 'store locator page',
    brandCategoryPage: 'brandcategorylisting',
    eventListing: 'eventlisting',
    promoListing: 'promolisting',
    wishlist: 'wishlist',
    shoppingCart: 'cart',
}

export enum FeeType {
    ECO_FEE = 'ECO_FEE',
    CORE_CHARGE = 'CORE_CHARGE',
    TIRE_RECYCLE_FEE = 'TIRE_RECYCLE_FEE',
}

export const previousElementName = 'data-previous-focused-ele' // This name is the element which opened the modal

export const MODAL_OPEN_CLASS = 'modal-open'
