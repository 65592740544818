import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { CHECKOUT, CAD, TRACK, COLOR } from './InitTruefitOrderReport.constant'
import { TruefitTrackProductType, TruefitTrackOrderType } from './InitTruefitOrderReport.type'
import {
    enableTruefitSelector,
    truefitSizeDescriptorsSelector,
    userTruefitSelector,
} from '../../../redux/selectors/Truefit.selectors'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { orderSelector } from '../../../redux/selectors/orderItemsDetails.selectors'
import { libUtils } from '@nl/lib'
import { CartItemsData, ProductOption, CartOrderEntries } from '../../../redux/models/cart.interface'

/**
 * InitTruefitOrderReport to invoke truefit tracking for orders on the confirmation page.
 * @return {null}
 */
const InitTruefitOrderReport: React.FC = () => {
    const enableTruefit = useSelector(enableTruefitSelector)
    const truefitSizeDescriptors: string[] = useSelector(truefitSizeDescriptorsSelector)
    const userTruefit = useSelector(userTruefitSelector)
    const [isTracked, setTracked] = useState(false)
    const order = useSelector(orderSelector)

    const getColour = useCallback((options: ProductOption[] | undefined): string => {
        return options?.find(item => COLOR === item.descriptor)?.value || ''
    }, [])

    const getSize = useCallback(
        (options: ProductOption[]): string =>
            options.find(item => truefitSizeDescriptors.find(descriptor => descriptor === item.descriptor))?.value ||
            '',
        [truefitSizeDescriptors],
    )

    const getProducts = useCallback(
        (orderData: CartItemsData | null): TruefitTrackProductType[] =>
            orderData?.entries
                ?.map((entry: CartOrderEntries) => {
                    const size = getSize(entry.options || [])
                    return {
                        productId: entry.baseProduct,
                        colorId: getColour(entry.options),
                        quantity: entry.quantity,
                        price: entry.currentPrice?.value,
                        size,
                        sku: entry.code,
                        currency: CAD,
                    } as TruefitTrackProductType
                })
                .filter(product => checkDataLength(product)) || ([] as TruefitTrackProductType[]),
        [getSize, getColour],
    )

    const getOrderInfo = useCallback(
        (orderData: CartItemsData): TruefitTrackOrderType => {
            return {
                orderId: orderData.code || '',
                locale: libUtils.getLanguageCA(),
                products: getProducts(orderData),
                userId: userTruefit.props['data-userid'] || '',
            } as TruefitTrackOrderType
        },
        [getProducts, userTruefit],
    )

    const [isPending, setIsPending] = useState(userTruefit.isPending)

    useEffect(() => {
        setIsPending(userTruefit.isPending)
    }, [userTruefit])

    useEffect(() => {
        if (enableTruefit && !isTracked && !isPending && checkDataLength(order) && window.tfcapi) {
            const dataToSend = getOrderInfo(order.cart)
            if (dataToSend?.products?.length) {
                window.tfcapi(TRACK, CHECKOUT, dataToSend)
                setTracked(true)
            }
        }
    }, [enableTruefit, order, isTracked, getOrderInfo, isPending])

    return null
}

export default InitTruefitOrderReport
