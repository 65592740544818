import { AxiosPromise } from 'axios'

import BaseService from '../base.service'
import { BASE_SITE, JSON_RESPONSE_FORMAT } from '../../environments/apiConfig'
import { getEnvironment } from '../../environments'
import { Suggest, getCookieValue } from '@nl/lib'
import { getHttpClient } from '../../httpClient'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import { REF_URL_KEY } from '../../config'
import sessionStorageService from '../../utils/sessionStorageService'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Search service
 */
class SearchService extends BaseService {
    /**
     * Function to fetch Search Results.
     * @param {string} searchString
     * @param {string} storeId
     * @param {boolean} enableXRingFence
     * @return {AxiosPromise}
     */
    getSearchResults(searchString: string, storeId: string, enableXRingFence?: boolean): AxiosPromise<unknown> {
        const brUID = getCookieValue('_br_uid_2')
        const brUIDheader = brUID ? { 'X-BR-UID': brUID } : undefined
        const refURL = sessionStorageService.getItem(REF_URL_KEY)
        const referrer = refURL && { 'X-BR-REF-URL': refURL }
        const xBrRef = { 'X-BR-REF': window.location.href }
        const headers: HttpReqHeaders = {
            'x-ringfence': !!enableXRingFence,
            ...brUIDheader,
            ...referrer,
            ...xBrRef,
        }
        return httpClient.apiGet(this.getSearchURL(searchString, storeId).toString(), {}, headers)
    }

    /**
     * Function used to construct the query params for the search url
     * @param {string} searchString - search string.
     * @param {string} storeId - store id.
     * @return {string} return cds or mock url
     */
    getSearchURL(searchString: string, storeId: string): URL {
        const locale = SearchService.language
        const {
            API_ENDPOINTS: { getSearchResults },
        } = environment

        const endPoint = SearchService.switchEndpoints(`/mock${getSearchResults}`, getSearchResults)
        return new URL(
            `${endPoint}?${searchString}&store=${storeId}&lang=${locale}&site=${BASE_SITE}&format=${JSON_RESPONSE_FORMAT}`,
        )
    }

    /**
     * Function to fetch Suggest Results.
     * @param {string} searchString
     * @param {string} storeId
     * @param {boolean | undefined} mobile
     * @return {AxiosPromise}
     */
    getSuggestions(searchString: string, storeId?: string, mobile?: boolean | undefined): Promise<{ data: Suggest }> {
        const brUID = getCookieValue('_br_uid_2')
        const header = brUID ? { 'X-BR-UID': brUID } : undefined
        const refURL = sessionStorageService.getItem(REF_URL_KEY)
        const referrer = refURL && { 'X-BR-REF-URL': refURL }
        const xBrRef = { 'X-BR-REF': window.location.href }
        const newHeader = { ...header, ...referrer, ...xBrRef }
        return httpClient.apiGet(this.getSuggestURL(searchString, storeId, mobile).toString(), undefined, newHeader)
    }

    /**
     * Function used to construct the query params for the suggest url
     * @param {string} searchString - search string.
     * @param {string} storeId
     * @param {boolean | undefined} mobile
     * @return {URL} return cds or mock url
     */
    getSuggestURL(searchString: string, storeId?: string, mobile?: boolean | undefined): URL {
        const locale = SearchService.language
        const mockUrl = SearchService.isMock() ? '/mock' : ''
        const {
            API_BASE_URL,
            API_MOCK_URL,
            API_ENDPOINTS: {
                getSuggestResults,
                // these are mock json files
                shovelSuggestion,
                snowShovelsRakesSuggestion,
                snowShovelsToolsSuggestion,
                gardeShovelsSuggestion,
                jacketSuggestion,
                winterJacketsSuggestion,
                rainJacketsSuggestion,
                mensJacketsSuggestion,
                mostPopularProducts,
                pCodeSuggestion,
            },
        } = environment

        if (mockUrl) {
            const mockedURLMapping = {
                shovel: shovelSuggestion,
                'snow shovels & rakes': snowShovelsRakesSuggestion,
                'snow shovels & tools': snowShovelsToolsSuggestion,
                'garden shovels': gardeShovelsSuggestion,
                jacket: jacketSuggestion,
                'mens jacket': mensJacketsSuggestion,
                'winter jacket': winterJacketsSuggestion,
                'rain jacket': rainJacketsSuggestion,
                '1873091P': pCodeSuggestion,
            }

            // for mocks, default to most popular product mock. if search term mapping exists, use available mocked data
            let mockedURL = mostPopularProducts

            // popular products case
            if (searchString) {
                if (searchString in mockedURLMapping) {
                    mockedURL = mockedURLMapping[searchString as keyof typeof mockedURLMapping]
                } else {
                    // use shovel suggestion as default data
                    mockedURL = shovelSuggestion
                }
            }

            return new URL(`${API_MOCK_URL}${mockedURL}.${JSON_RESPONSE_FORMAT}`)
        } else {
            const storeIdUrlPart = storeId ? `&storeId=${storeId}` : ''
            // Temporarily added workaround with extra keys for suggest request
            const mobileAppUrlPart = mobile ? `&mobileApp=${String(mobile)}&fix=PRS-373` : ''
            const suggestEndpoint = `${getSuggestResults}?q=${encodeURIComponent(
                searchString,
            )}${storeIdUrlPart}&lang=${locale}&site=${BASE_SITE}&format=${JSON_RESPONSE_FORMAT}${mobileAppUrlPart}`
            return new URL(`${API_BASE_URL}${suggestEndpoint}`)
        }
    }
}

const searchService = new SearchService()
export { searchService }
export default searchService
