import { createAction } from 'typesafe-actions'

import { LinkLoyaltyCardErrorResponse, LinkLoyaltyCardType } from '../models/linkLoyaltyCard.interface'
import {
    TRY_AGAIN_SUCCESS,
    TRY_AGAIN_FAILURE,
    TRY_AGAIN_SUCCESS_CLEAR,
    TRY_AGAIN_FAILURE_CLEAR,
} from '../types/linkCard/tryAgain.actionTypes.constant'

export const tryAgainSuccessAction = createAction(
    TRY_AGAIN_SUCCESS,
    (payload: LinkLoyaltyCardType) => payload,
)<LinkLoyaltyCardType>()

export const tryAgainErrorAction = createAction(
    TRY_AGAIN_FAILURE,
    (payload: LinkLoyaltyCardErrorResponse) => payload,
)<LinkLoyaltyCardErrorResponse>()

export const tryAgainSuccessClearAction = createAction(TRY_AGAIN_SUCCESS_CLEAR)()

export const tryAgainErrorClearAction = createAction(TRY_AGAIN_FAILURE_CLEAR)()
