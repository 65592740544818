/**
 *  This function is to add animation effect to gigya input fields
 * @param {HTMLInputElement} item Input field to which animation to be applied
 * @return {void}
 */
const transformLabelClass = 'transform-label'
const tfaTransformLabelClass = 'tfa-transform-label'
export const animateGigyaFields = (item: HTMLInputElement): void => {
    updateForAttributeMapping(item)
    if (item.value !== '') {
        item.previousElementSibling?.classList.add(transformLabelClass)
        item.previousElementSibling?.classList.add(tfaTransformLabelClass)
    }
    item.addEventListener('focus', function () {
        item.previousElementSibling?.classList.add(transformLabelClass)
        item.previousElementSibling?.classList.add(tfaTransformLabelClass)
        const tfaInputLabel = document.querySelector('.gig-tfa-error') as HTMLInputElement

        if (item.classList.contains('gig-tfa-input-error')) {
            tfaInputLabel.innerHTML = ''
            if (item.value === '') {
                item.classList.remove('gig-tfa-input-error')
            }
        }
    })
    item.addEventListener('blur', function () {
        if (item.value === '') {
            item.previousElementSibling?.classList.remove(transformLabelClass)
            item.previousElementSibling?.classList.remove(tfaTransformLabelClass)
        }
    })
}

/**
 * for attribute mapping function for gigya input ids
 *
 * @param { HTMLInputElement } item - input field in gigya screen
 */
const updateForAttributeMapping = (item: HTMLInputElement): void => {
    const elementId = item.getAttribute('id') as string
    item?.previousElementSibling?.setAttribute('for', elementId)
}
