import { magicNumber } from '.'

/**
 * Returns a percent value within the range of 0 to 100
 * @param {number} percent - actual percent value which has to be constrain between 0 and 100
 * @return {number}
 */
export const getSafePercent = (percent: number): number => {
    return Math.min(magicNumber.HUNDRED, Math.max(percent, 0))
}
