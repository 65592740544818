import { Dispatch } from 'redux'
import { RemainingGiftCardInterface } from '@nl/lib/src/components/GiftCard/GiftCard.types'

import {
    AddressRequest,
    AddressAutoSuggestions,
    AddressSuggestions,
    RetrieveAddressRequest,
    AddressSuggestionsError,
} from '../models/checkout.interface'
import {
    getSuggestionsSuccess,
    getSuggestionsFailure,
    getAddressSuggestionsSuccess,
    getAddressSuggestionsFailure,
    getErrorSuggestionsSuccess,
    getAddressSuggestionsCleanup,
    setHppToken,
    placeOrderSuccess,
    placeOrderFailure,
    semaFoneEnabled,
    updateCartDataSuccessAction,
    updateCartDataFailureAction,
    redeemCTMoneyResponseAction,
    setShowSpinner,
    updateStoreInitiatedCart,
    getPaymentDetailsAction,
    getPaymentDetailsFailure,
    editPaymentClickedAction,
    isGiftCardJustDeletedAction,
    setInitPaymentStatusAction,
} from '../actionCreators'
import { getItemId } from '../../services/checkoutService/checkoutService'
import { CheckoutService } from '../../services/checkoutService'
import { AxiosError, AxiosResponse } from 'axios'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { RootState } from '../reducers'
import appCacheService from '../../utils/appCacheService'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { isOneTimeCartForAuthUser } from '../../utils/isOneTimeCartForAuthUser.utils'
import { StoreSharedCart } from '../reducers/sharedCart.reducer'
import { CartItemsData, CartResponseErrorDTO } from '../models/cart.interface'
import { IFeatureFlag } from '../models/commonContent.interface'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { isFullPageError } from '../../components/Checkout/Checkout.helper'
/**
 * action to get the address auto suggestions
 * @param {AddressRequest} requestPayload - request object for getting the values for auto complete suggestions
 * @return {Promise}
 */
export const getAddressAutoSuggestions =
    (requestPayload: AddressRequest) =>
    (dispatch: Dispatch): Promise<void> => {
        return CheckoutService.getCanadaPostAddressSuggestions(requestPayload)
            .then((data: AxiosResponse) => {
                dispatch(getSuggestionsSuccess(data.data as AddressAutoSuggestions))
            })
            .catch((err: AxiosError<AddressSuggestionsError>) => {
                const errorResponse = err.response
                dispatch(getSuggestionsFailure(errorResponse))
            })
    }

/**
 * action to get the address auto suggestions
 * @param {AddressRequest} requestPayload - request object for getting the values for auto complete suggestions
 * @param {boolean} isValid - to check if it is valid suggestion or error
 * @param {string} key - canada post api key
 * @return {Promise}
 */
export const validateSelectedAddress =
    (requestPayload: AddressRequest | string, isValid: boolean, key: string) =>
    async (dispatch: Dispatch): Promise<void> => {
        let id = ''
        if (typeof requestPayload === 'object') {
            const addressData = await CheckoutService.getCanadaPostAddressSuggestions(requestPayload)
            id = getItemId(addressData.data)
        } else {
            id = requestPayload
        }
        const retrieveRequest: RetrieveAddressRequest = {
            Key: key,
            Id: id,
        }
        return CheckoutService.validateSelectedCanadaPostAddress(retrieveRequest)
            .then(data => {
                if (!isValid) dispatch(getErrorSuggestionsSuccess(data.data as AddressSuggestions))
                else dispatch(getAddressSuggestionsSuccess(data.data as AddressSuggestions))
            })
            .catch((err: AxiosError<AddressSuggestionsError>) => {
                const errorResponse = err.response
                dispatch(getAddressSuggestionsFailure(errorResponse))
            })
    }

export const emptyErrorSuggestions = () => (dispatch: Dispatch) => {
    dispatch(getAddressSuggestionsCleanup())
}

export const getHPPToken =
    (
        cartId: string,
        storeNumber: string,
        userId: string,
        isSemaFoneEnabled: boolean,
        initPaymentVersion: number,
        mergePlaceOrderAndReviewOrderFeature?: boolean,
        paymentType?: string,
        redirectSuccessLink?: string,
        redirectErrorLink?: string,
        redirectCancelLink?: string,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        const semaFoneFlag = isSemaFoneEnabled ? 'Y' : ''
        return CheckoutService.hppToken(
            cartId,
            storeNumber,
            initPaymentVersion,
            userId,
            semaFoneFlag,
            isOneTimeCartFlag,
            paymentType,
            redirectSuccessLink,
            redirectErrorLink,
            redirectCancelLink,
        )
            .then((resp: AxiosResponse<Record<string, unknown>>) => {
                dispatch(
                    setHppToken({
                        hppToken: resp.data?.transactionId || resp.data?.hppToken,
                        requestId: resp.data?.requestId,
                        paymentPlanInfo: resp.data?.paymentPlanInfo,
                        iframeUrl: resp.data?.iframeUrl,
                        validUntil: resp.data.validUntil,
                        giftCards: resp.data?.giftCards,
                    }),
                )
                dispatch(setInitPaymentStatusAction(true))
            })
            .catch(err => {
                console.warn(err)
            })
            .finally(() => {
                !mergePlaceOrderAndReviewOrderFeature && dispatch(setShowSpinner(false))
            })
    }

/**
 * action to call the placeorder api
 * @param {string} cartId
 * @param {boolean} isGiftCardsCoverOrderAmount
 * @param {boolean} isPayPalPayment
 * @param {IFeatureFlag} featureFlag
 * @return {Promise}
 */
export const placeOrder =
    (cartId: string, isGiftCardsCoverOrderAmount: boolean, isPayPalPayment = false, featureFlag: IFeatureFlag) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        // TODO: Need to remove mock api when actual api contain error response
        const queryParams = window.location.search
        const isMockError = queryParams.toLowerCase().includes('mockerror')
        const isNewCard =
            isGiftCardsCoverOrderAmount || isPayPalPayment
                ? false
                : getState().checkoutDrawer.checkoutPaymentInfo.newCreditCard || false

        const fraudSessionID = CheckoutService.getFraudSessionID(featureFlag)
        const { isStoreSharedCart } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        ) as StoreSharedCart
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )

        CheckoutService.placeOrder(cartId, isMockError, isNewCard, fraudSessionID, isOneTimeCartFlag)
            .then(response => {
                if (!isStoreSharedCart) {
                    appCacheService.removeCartGuid()
                    appCacheService.removeTmxSession()
                    appCacheService.removeSignifydSession()
                    appCacheService.removeTendersStorageData(cartId)
                }
                appCacheService.cartData.delete()
                appCacheService.cartLastVisitedDate.remove()
                isMockError ? dispatch(placeOrderFailure(response)) : dispatch(placeOrderSuccess(response.data))
            })
            .catch((err: AxiosError<Record<string, unknown>>) => {
                const errorResponse = err.response
                dispatch(placeOrderFailure(errorResponse))
                !isFullPageError(errorResponse?.data?.errCode) && dispatch(setShowSpinner(false))
            })
    }

/**
 * action to set semafoneEnabled from url params to redux state
 * @param {string} isSemaFoneEnabled
 * @return {void} nothing
 */
export const updateSemaFoneEnabled =
    (isSemaFoneEnabled: string) =>
    (dispatch: Dispatch): void => {
        const data = { semaFoneEnabled: isSemaFoneEnabled }
        dispatch(semaFoneEnabled(data))
    }

/**
 * Calls API to update CT Money redemption to cart
 * @param {boolean} redeemCTMoney boolean to redeem CT Money or not
 * @param {number|null} redeemAmount Amount to be redeemed
 * @param {number} storeId the storeId associated with the cart, to be used by PandA (OCCP-15803)
 * @param {boolean} isFromUpdate is API call happening from update click or not
 * @return {Promise<void>}
 */
export const redeemCTMoneyUpdateToCart =
    (redeemCTMoney: boolean, redeemAmount: number | null, storeId: string | number, isFromUpdate?: boolean) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )

        return CheckoutService.updateRedeemCTMoneyToCart(redeemCTMoney, redeemAmount, storeId, isOneTimeCartFlag)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                redeemCTMoneySuccess(dispatch, getState, data, isFromUpdate)
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updateCartDataFailureAction(errorResponse))
                const redeemObject = isFromUpdate
                    ? {
                          isSuccess: false,
                          error: errorResponse?.data,
                      }
                    : null
                dispatch(redeemCTMoneyResponseAction(redeemObject))
            })
    }

export const resetRedeemCTMoneyResponse =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(redeemCTMoneyResponseAction(null))
    }

/**
 * Method to perform redeemCTMoney success callback actions
 * @param { Dispatch } dispatch dispatch
 * @param { RootState } getState state
 * @param {AxiosResponse<any>} data Axios Response for the failed API request
 * @param {boolean | null} isFromUpdate flag for validating if its invoked from update
 */
const redeemCTMoneySuccess = (
    dispatch: Dispatch,
    getState: () => RootState,
    data: AxiosResponse<any>,
    isFromUpdate?: boolean,
) => {
    const { isStoreSharedCart } = enableDestructOnUndefinedData(
        getState().sharedCart?.cartConsuming?.storeSharedCart,
    ) as StoreSharedCart
    const cartFilteredData = getFilteredCartItems(data.data)
    isStoreSharedCart
        ? dispatch(updateStoreInitiatedCart(cartFilteredData))
        : dispatch(updateCartDataSuccessAction(cartFilteredData))
    isFromUpdate && dispatch(redeemCTMoneyResponseAction({ isSuccess: true }))
}

/**
 * Action method to invoke redeemCTMoney success callback
 * @param {AxiosResponse<any>} data Axios Response for the failed API request
 * @param {boolean | null} isFromUpdate flag for validating if its invoked from update
 * @return {void}
 */
export const redeemCTMoneySuccessAction =
    (data: AxiosResponse<any>, isFromUpdate?: boolean) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        redeemCTMoneySuccess(dispatch, getState, data, isFromUpdate)
    }

/**
 * Calls API to addGiftCard
 * @param {number} giftCardAmount
 * @param {string} tenderId
 * @param {string} cartId
 * @param {string} storeId - preferred store id
 * @return {void}
 */
export const applyGiftCardAction =
    (giftCardAmount: number, tenderId: string, cartId: string, storeId: string) =>
    (dispatch: Dispatch): void => {
        CheckoutService.applyGiftCard(giftCardAmount, tenderId, cartId, storeId)
            .then(response => {
                appCacheService.isGiftCardWasApplied.set(cartId, true)
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
            })
            .catch(err => console.error(err))
    }

/**
 * Calls API to deleteGiftCard
 * @param {string} tenderId
 * @param {string} cartId
 * @param {RemainingGiftCardInterface[]} appliedGiftCards remaining Gift Cards in returned from CTFS and formatted
 * @param {string} storeId - preferred store id
 * @return {void}
 */
export const deleteGiftCardAction =
    (tenderId: string, cartId: string, appliedGiftCards: RemainingGiftCardInterface[], storeId: string) =>
    (dispatch: Dispatch): void => {
        CheckoutService.deleteGiftCard(tenderId, cartId, appliedGiftCards, storeId)
            .then(response => {
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                dispatch(isGiftCardJustDeletedAction(true))
            })
            .catch(err => console.error(err))
    }

/**
 * Calls API to obtain payment details
 * @param {string} cartId
 * @return {Promise<void>}
 */
export const getPaymentDetails =
    (cartId: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return CheckoutService.getPaymentDetails(cartId)
            .then(data => {
                dispatch(getPaymentDetailsAction(data))
            })
            .catch((err: AxiosError<Record<string, unknown>>) => {
                console.error(err)
                dispatch(getPaymentDetailsFailure(err.response.data))
            })
    }

/**
 * Update edit payment clicked state
 * @param {boolean} state
 * @return {void}
 */
export const updateEditPaymentState =
    (state: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(editPaymentClickedAction(state))
    }
