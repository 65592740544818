import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/preselectedVehicle.actionCreators'
import { preselectedVehicle } from '../models/preselectedVehicle.interface'

type Action = ActionType<typeof actions>

const initialDropDownsState = {
    attributes: {
        known: [
            {
                id: '',
                value: '',
            },
        ],
        suggested: [
            {
                id: [''],
                values: [''],
            },
        ],
    },
}

export const initialState: preselectedVehicle = {
    IsPendingDictionaryForPreselection: false,
    isAdditionalFetchNeeded: true,
    initialDropDownsForPreselectedMakeOnly: initialDropDownsState,
    preselectedValues: {},
    isPreselectedFromURL: false,
}

export const preselectedVehicleReducer = (state = initialState, action: Action): preselectedVehicle => {
    switch (action.type) {
        case getType(actions.setIsPendingDictionaryForPreselection):
            return {
                ...state,
                IsPendingDictionaryForPreselection: action.payload,
            }

        case getType(actions.setInitialDropDownsForPreselectedMakeOnly):
            return { ...state, initialDropDownsForPreselectedMakeOnly: action.payload }

        case getType(actions.setPreselectedVehicleValues):
            const { preselectedValues } = state

            return {
                ...state,
                preselectedValues: { ...preselectedValues, ...action.payload },
                isPreselectedFromURL: true,
                IsPendingDictionaryForPreselection: false,
            }

        case getType(actions.clearPreselectedVehicleValues):
            return {
                ...state,
                preselectedValues: {
                    make: '',
                    model: '',
                    year: '',
                },
                isPreselectedFromURL: false,
            }

        case getType(actions.setIsAdditionalFetchNeeded):
            return { ...state, isAdditionalFetchNeeded: action.payload }

        default:
            return state
    }
}
