import { RootState } from '../reducers'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import { linkLoyaltyCard, linkLoyaltyCheck, linkLoyaltyValidate, linkLoyaltyProfile } from './linkLoyaltyCard.action'
import { linkMasterCard } from './linkMasterCard.action'
import {
    tryAgainSuccessAction,
    tryAgainErrorAction,
    clearLinkMasterCardResponseAction,
    clearLinkLoyaltyCheckResponseAction,
    clearLinkLoyaltyValidateResponseAction,
    clearLinkLoyaltyProfileResponseAction,
    clearLinkLoyaltyCardResponseAction,
    clearLinkLoyaltyCardErrorAction,
    clearLinkLoyaltyProfileErrorAction,
    clearLinkLoyaltyValidateErrorAction,
    clearLinkLoyaltyCheckErrorAction,
    clearLinkMasterCardErrorAction,
} from '../actionCreators'
import {
    LinkLoyaltyCardType,
    LinkLoyaltyCardErrorResponse,
    LinkLoyaltyCardRequest,
    LinkLoyaltyProfileResponse,
    LinkLoyaltyValidateResponse,
    LinkLoyaltyCheckResponse,
} from '../models/linkLoyaltyCard.interface'
import {
    LinkMasterCardRequest,
    ProductCodeTypes,
    LinkMasterCardSuccessResponse,
} from '../models/linkMasterCard.interface'
import { cardCheckValidStatus, LegacyCardError } from '../../components/LinkRewards/LinkRewards.constants'
import { generateDayMonthYearFromDOBStr } from '../../utils/birthDayService'

/* Calls loyalty card link API for try again from T&C screen */
export const tryAgainLink =
    () =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const formPayload = getState().registerFormData.registerFormValues

        return linkLoyaltyCard(formPayload)(dispatch)
            .then(data => {
                dispatch(tryAgainSuccessAction(data.data as LinkLoyaltyCardType))
            })
            .catch(error => {
                dispatch(tryAgainErrorAction(error as LinkLoyaltyCardErrorResponse))
            })
    }

/* Calls loyalty card link API for loyalty card number. */
export const linkLoyaltyCardTryAgain = (payload: LinkLoyaltyCardRequest, dispatch: Dispatch): Promise<void> => {
    return linkLoyaltyCard(payload)(dispatch)
        .then(linkData => {
            dispatch(clearLinkLoyaltyCardResponseAction())
            dispatch(tryAgainSuccessAction(linkData.data as LinkLoyaltyCardType))
        })
        .catch(linkCardError => {
            dispatch(clearLinkLoyaltyCardErrorAction())
            dispatch(tryAgainErrorAction(linkCardError))
        })
}

/* Calls loyalty card profile API for lmsProfileId.
 *   If successful then call loyalty card link API
 */
export const linkLoyaltyProfileTryAgain = (
    day: number,
    month: number,
    year: number,
    dispatch: Dispatch,
    lmsProfileId: string,
): Promise<void> => {
    return linkLoyaltyProfile(lmsProfileId)(dispatch)
        .then((profileData: AxiosResponse<LinkLoyaltyProfileResponse>) => {
            dispatch(clearLinkLoyaltyProfileResponseAction())
            const {
                loyaltyCardNumber: loyaltyCardNumberProfile,
                gender,
                firstName,
                lastName,
                homeAddress,
            } = profileData.data
            const { address1, city, province, postalCode, phone, country } = homeAddress

            const payload = {
                loyaltyCard: {
                    loyaltyCardNumber: loyaltyCardNumberProfile,
                    birthDay: day,
                    birthMonth: month,
                    birthYear: year,
                    gender,
                    firstName,
                    lastName,
                    addresses: {
                        addressLineOne: address1,
                        city,
                        province: `${country}-${province}`,
                        postalCode,
                        phone,
                    },
                },
            }
            void linkLoyaltyCardTryAgain(payload, dispatch)
        })
        .catch(profileError => {
            dispatch(clearLinkLoyaltyProfileErrorAction())
            dispatch(tryAgainErrorAction(profileError))
        })
}

/* Calls loyalty card validate API for loyalty card number.
 *    If non-valid status then dispatch the error
 *   Else call loyalty card profile API
 */
export const linkLoyaltyValidateTryAgain = (
    loyaltyCardNumber: string,
    dispatch: Dispatch,
    formPayload: LinkMasterCardRequest,
): Promise<void> => {
    const { year, day, month } = generateDayMonthYearFromDOBStr(formPayload.masterCard.dob)
    return linkLoyaltyValidate({
        postalCode: formPayload.masterCard.postalCode.toUpperCase(),
        dateOfBirth: year.toString(),
        loyaltyCardNumber,
    })(dispatch)
        .then((validateData: AxiosResponse<LinkLoyaltyValidateResponse>) => {
            dispatch(clearLinkLoyaltyValidateResponseAction())
            const { validationStatus, lmsProfileId } = validateData.data
            if (validationStatus === cardCheckValidStatus) {
                void linkLoyaltyProfileTryAgain(day, month, year, dispatch, lmsProfileId)
            } else {
                dispatch(
                    tryAgainErrorAction({
                        errCode: validationStatus,
                    } as LinkLoyaltyCardErrorResponse),
                )
            }
        })
        .catch(validateError => {
            dispatch(clearLinkLoyaltyValidateErrorAction())
            dispatch(tryAgainErrorAction(validateError))
        })
}

/* Calls loyalty card check API for loyalty card number.
 *    If non-valid status then dispatch the error
 *   Else call loyalty card validate API
 */
export const linkLoyaltyCheckTryAgain = (
    loyaltyCardNumber: string,
    dispatch: Dispatch,
    formPayload: LinkMasterCardRequest,
): Promise<void> => {
    return linkLoyaltyCheck({
        loyaltyCardNumber,
    })(dispatch)
        .then((checkData: AxiosResponse<LinkLoyaltyCheckResponse>) => {
            dispatch(clearLinkLoyaltyCheckResponseAction())
            const { accountStatus } = checkData.data
            if (accountStatus === cardCheckValidStatus) {
                void linkLoyaltyValidateTryAgain(loyaltyCardNumber, dispatch, formPayload)
            } else {
                dispatch(tryAgainErrorAction({ errCode: accountStatus } as LinkLoyaltyCardErrorResponse))
            }
        })
        .catch(checkError => {
            dispatch(clearLinkLoyaltyCheckErrorAction())
            dispatch(tryAgainErrorAction(checkError))
        })
}

/* Calls loyaltycardlookup API for mastercard number.
 *    If Legacy card then dispatch the error
 *   Else call loyalty card check API
 */
export const tryAgainMasterCardLink =
    () =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const formPayload = getState().linkMasterCard.linkMasterEntry

        return linkMasterCard(formPayload)(dispatch)
            .then((masterCardData: AxiosResponse<LinkMasterCardSuccessResponse>) => {
                dispatch(clearLinkMasterCardResponseAction())
                const { productCode, loyaltyCardNumber } = masterCardData.data
                if (productCode === ProductCodeTypes.OMP || productCode === ProductCodeTypes.OMR) {
                    dispatch(tryAgainErrorAction({ errCode: LegacyCardError } as LinkLoyaltyCardErrorResponse))
                } else {
                    void linkLoyaltyCheckTryAgain(loyaltyCardNumber, dispatch, formPayload)
                }
            })
            .catch(masterCardError => {
                dispatch(clearLinkMasterCardErrorAction())
                dispatch(tryAgainErrorAction(masterCardError))
            })
    }
