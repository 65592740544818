import { invokeGenericLinkClickAnalytics } from './components/genericLinkClickAnalytics'
import { analyticsAttributes, dataComponentConstants } from '../globalConstants'

/**
 * Get closest DOM element based on specified condition
 * @param {Element} element DOM element from where to find the closest element
 * @param {Function} checkFn check function which should satisfy with the closest element
 * @return {Element} return closest DOM element which matches the condition specified in checkFn
 */
const getClosestElement = (element: Element, checkFn: (_: Element) => boolean): Element | null => {
    let currElement: Element | null = element

    while (currElement && currElement !== document.body) {
        if (checkFn(currElement)) {
            return currElement
        } else {
            currElement = currElement.parentElement
        }
    }

    return null
}

/**
 * Register for analytics on anchor tags
 */
const initAnchorClickAnalytics = () => {
    const { external, internal } = analyticsAttributes.linkTypes

    const tagNameChecker =
        (tagName: string) =>
        (element: Element): boolean =>
            tagName ? element?.tagName?.toLowerCase() === tagName : false

    const dataAttributeChecker =
        (attributeName: string) =>
        (element: Element): boolean =>
            attributeName ? element?.hasAttribute(attributeName) : false

    const anchorClickHandler = function (event: Event) {
        if (!event) return

        const target = event.target as Element
        const globalLinkElement = getClosestElement(target, tagNameChecker('a')) as HTMLAnchorElement

        if (globalLinkElement) {
            const isExternalLink = globalLinkElement.hostname !== window.location.hostname
            const linkType = isExternalLink ? external : internal
            const linkValue = globalLinkElement.getAttribute('data-link-value') || ''
            const featureType = globalLinkElement.getAttribute('data-feature-type') || ''
            const closestDataComponent = getClosestElement(globalLinkElement, dataAttributeChecker('data-component'))

            const dataComponent = closestDataComponent?.getAttribute('data-component') || ''
            const location = dataComponentConstants[dataComponent] || dataComponent

            invokeGenericLinkClickAnalytics(linkType, linkValue, location, featureType)
        }
    }

    document.addEventListener('click', anchorClickHandler)
}

export { initAnchorClickAnalytics }
