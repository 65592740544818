import { createAction } from 'typesafe-actions'
import {
    IS_STICKY_BUYBAR_IN_VIEW,
    SET_STICKY_BUYBAR_ATC_SPINNER,
    SET_STICKY_BUYBAR_HEIGHT,
} from '../types/products/stickyBuyBar.actionTypes.constant'

export const setIsStickyBuyBarInViewAction = createAction(
    IS_STICKY_BUYBAR_IN_VIEW,
    (payload: boolean) => payload,
)<boolean>()

export const setStickyBuyBuyBarHeightAction = createAction(
    SET_STICKY_BUYBAR_HEIGHT,
    (payload: number) => payload,
)<number>()

export const setStickyBuyBarATCSpinnerAction = createAction(
    SET_STICKY_BUYBAR_ATC_SPINNER,
    (payload: boolean) => payload,
)<boolean>()
