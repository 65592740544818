import { createAction } from 'typesafe-actions'

import {
    LinkLoyaltyCardType,
    LinkLoyaltyCardErrorResponse,
    LinkLoyaltyCheckResponse,
    LinkLoyaltyValidateResponse,
    LinkLoyaltyProfileResponse,
} from '../models/linkLoyaltyCard.interface'
import {
    LINK_LOYALTY_CARD_SUCCESS,
    LINK_LOYALTY_CARD_FAILURE,
    LINK_LOYALTY_CHECK_SUCCESS,
    LINK_LOYALTY_CHECK_FAILURE,
    CLEAR_LINK_LOYALTY_CHECK_RESPONSE,
    CLEAR_LINK_LOYALTY_CHECK_FAILURE,
    CLEAR_LINK_LOYALTY_VALIDATE_RESPONSE,
    CLEAR_LINK_LOYALTY_VALIDATE_FAILURE,
    LINK_LOYALTY_VALIDATE_FAILURE,
    LINK_LOYALTY_VALIDATE_SUCCESS,
    LINK_LOYALTY_PROFILE_SUCCESS,
    LINK_LOYALTY_PROFILE_FAILURE,
    CLEAR_LINK_LOYALTY_PROFILE_RESPONSE,
    CLEAR_LINK_LOYALTY_PROFILE_FAILURE,
    CLEAR_LINK_LOYALTY_CARD_RESPONSE,
    CLEAR_LINK_LOYALTY_CARD_FAILURE,
    SAVE_LOYALTY_CARD_NUMBER,
    SAVE_LINK_TYPE,
} from '../types/loyaltyCard/linkLoyaltyCard.actionTypes.constant'

export const linkLoyaltyCardSuccessAction = createAction(
    LINK_LOYALTY_CARD_SUCCESS,
    (payload: LinkLoyaltyCardType) => payload,
)<LinkLoyaltyCardType>()

export const linkLoyaltyCardErrorAction = createAction(
    LINK_LOYALTY_CARD_FAILURE,
    (payload: LinkLoyaltyCardErrorResponse) => payload,
)<LinkLoyaltyCardErrorResponse>()

export const linkLoyaltyCheckSuccessAction = createAction(
    LINK_LOYALTY_CHECK_SUCCESS,
    (payload: LinkLoyaltyCheckResponse) => payload,
)<LinkLoyaltyCheckResponse>()

export const linkLoyaltyCheckErrorAction = createAction(
    LINK_LOYALTY_CHECK_FAILURE,
    (payload: LinkLoyaltyCardErrorResponse) => payload,
)<LinkLoyaltyCardErrorResponse>()

export const linkLoyaltyValidateSuccessAction = createAction(
    LINK_LOYALTY_VALIDATE_SUCCESS,
    (payload: LinkLoyaltyValidateResponse) => payload,
)<LinkLoyaltyValidateResponse>()

export const linkLoyaltyValidateErrorAction = createAction(
    LINK_LOYALTY_VALIDATE_FAILURE,
    (payload: LinkLoyaltyCardErrorResponse) => payload,
)<LinkLoyaltyCardErrorResponse>()

export const linkLoyaltyProfileSuccessAction = createAction(
    LINK_LOYALTY_PROFILE_SUCCESS,
    (payload: LinkLoyaltyProfileResponse) => payload,
)<LinkLoyaltyProfileResponse>()

export const linkLoyaltyProfileErrorAction = createAction(
    LINK_LOYALTY_PROFILE_FAILURE,
    (payload: LinkLoyaltyCardErrorResponse) => payload,
)<LinkLoyaltyCardErrorResponse>()

export const saveLoyaltyCardAction = createAction(SAVE_LOYALTY_CARD_NUMBER, (payload: string) => payload)<string>()

export const saveLinkTypeAction = createAction(SAVE_LINK_TYPE, (payload: string) => payload)<string>()

export const clearLinkLoyaltyCardResponseAction = createAction(CLEAR_LINK_LOYALTY_CARD_RESPONSE)()

export const clearLinkLoyaltyCardErrorAction = createAction(CLEAR_LINK_LOYALTY_CARD_FAILURE)()

export const clearLinkLoyaltyProfileResponseAction = createAction(CLEAR_LINK_LOYALTY_PROFILE_RESPONSE)()

export const clearLinkLoyaltyProfileErrorAction = createAction(CLEAR_LINK_LOYALTY_PROFILE_FAILURE)()

export const clearLinkLoyaltyValidateResponseAction = createAction(CLEAR_LINK_LOYALTY_VALIDATE_RESPONSE)()

export const clearLinkLoyaltyValidateErrorAction = createAction(CLEAR_LINK_LOYALTY_VALIDATE_FAILURE)()

export const clearLinkLoyaltyCheckResponseAction = createAction(CLEAR_LINK_LOYALTY_CHECK_RESPONSE)()

export const clearLinkLoyaltyCheckErrorAction = createAction(CLEAR_LINK_LOYALTY_CHECK_FAILURE)()
