import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { balanceCheckerScreen, Button } from '@nl/lib'
import { BalanceCheckerWrapperProps } from './BalanceChecker.type'
import BalanceCheckerModalScreen from './BalanceCheckerModalScreen'
import { PREFIX } from '../../config'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'

const BalanceCheckerWrapper: React.FC<BalanceCheckerWrapperProps> = ({ ...props }) => {
    const { balanceChecker } = props
    const {
        ctMoneyBalanceCheckLabel,
        balanceCheckerImage,
        balanceCheckerImageAltText,
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        checkBalanceCtaLabel,
        checkBalanceCtaAllyLabel,
        cancelCtaLabel,
        cancelCtaAllyLabel,
        ctMoneyLabel,
        linkCardText,
        ctaLabelGuestUser,
        ctaUrlGuestUser,
        ctaLinkTargetGuestUser,
        ctaAllyLabelGuestUser,
        ctaUrlLoggedInUser,
        ctaLinkTargetLoggedInUser,
        ctaAllyLabelLoggedInUser,
        checkBalanceOnAnotherCardCtaLabel,
        checkBalanceOnAnotherCardCtaAllyLabel,
        multipleAttemptFailureTitle,
        multipleAttemptFailureDescription,
        tryAgainCtaLabel,
        tryAgainCtaAllyLabel,
        emptyCardErrorMsg,
        invalidCardErrorMsg,
        partialCardErrorMsg,
        cardLinkedErrorMsg,
        ineligibleForBalanceCheckTitle,
        ineligibleForBalanceCheckDescription,
        cardMergeProcessTitle,
        cardMergeProcessDescription,
    } = balanceChecker

    const modalScreenProps = {
        balanceCheckerImage,
        balanceCheckerImageAltText,
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        checkBalanceCtaLabel,
        checkBalanceCtaAllyLabel,
        cancelCtaLabel,
        cancelCtaAllyLabel,
        ctMoneyLabel,
        linkCardText,
        ctaLabelGuestUser,
        ctaUrlGuestUser,
        ctaLinkTargetGuestUser,
        ctaAllyLabelGuestUser,
        ctaUrlLoggedInUser,
        ctaLinkTargetLoggedInUser,
        ctaAllyLabelLoggedInUser,
        checkBalanceOnAnotherCardCtaLabel,
        checkBalanceOnAnotherCardCtaAllyLabel,
        multipleAttemptFailureTitle,
        multipleAttemptFailureDescription,
        tryAgainCtaLabel,
        tryAgainCtaAllyLabel,
        emptyCardErrorMsg,
        invalidCardErrorMsg,
        partialCardErrorMsg,
        cardLinkedErrorMsg,
        ineligibleForBalanceCheckTitle,
        ineligibleForBalanceCheckDescription,
        cardMergeProcessTitle,
        cardMergeProcessDescription,
    }
    const { balanceScreen } = balanceCheckerScreen as Record<string, string>
    const [openPopup, setOpenPopup] = useState(false)
    const userProfileData = useSelector(userProfileDataSelector)
    const authenticatedUser = userProfileData ? Boolean(Object.keys(userProfileData).length) : false
    const isLoyaltyLinked = userProfileData?.loyalty?.cardNumber

    /**
     * Handler to close modal
     *
     * @return {void}
     */
    const closeHandler = (): void => {
        setOpenPopup(false)
    }

    return (
        <>
            {(!authenticatedUser || (authenticatedUser && !isLoyaltyLinked)) && (
                <div className={`${PREFIX}-balance-checker-footer-link`}>
                    <Button
                        type="tertiary_reverse"
                        ariaLabel={ctMoneyBalanceCheckLabel}
                        onClick={() => setOpenPopup(true)}
                        onKeyDown={() => false}>
                        {ctMoneyBalanceCheckLabel}
                    </Button>
                </div>
            )}
            <BalanceCheckerModalScreen
                screenId={balanceScreen}
                openPopup={openPopup}
                closeHandler={closeHandler}
                data={modalScreenProps}
            />
        </>
    )
}

BalanceCheckerWrapper.propTypes = {
    balanceChecker: PropTypes.shape({
        ctMoneyBalanceCheckLabel: PropTypes.string.isRequired,
        balanceCheckerImage: PropTypes.string.isRequired,
        balanceCheckerImageAltText: PropTypes.string.isRequired,
        balanceCheckerTitle: PropTypes.string.isRequired,
        balanceCheckerDescription: PropTypes.string.isRequired,
        cardNumberLabel: PropTypes.string.isRequired,
        cardImage: PropTypes.string.isRequired,
        cardImageAltText: PropTypes.string.isRequired,
        checkBalanceCtaLabel: PropTypes.string.isRequired,
        checkBalanceCtaAllyLabel: PropTypes.string.isRequired,
        cancelCtaLabel: PropTypes.string.isRequired,
        cancelCtaAllyLabel: PropTypes.string.isRequired,
        ctMoneyLabel: PropTypes.string.isRequired,
        linkCardText: PropTypes.string.isRequired,
        ctaLabelGuestUser: PropTypes.string.isRequired,
        ctaUrlGuestUser: PropTypes.string.isRequired,
        ctaLinkTargetGuestUser: PropTypes.string.isRequired,
        ctaAllyLabelGuestUser: PropTypes.string.isRequired,
        ctaUrlLoggedInUser: PropTypes.string.isRequired,
        ctaLinkTargetLoggedInUser: PropTypes.string.isRequired,
        ctaAllyLabelLoggedInUser: PropTypes.string.isRequired,
        checkBalanceOnAnotherCardCtaLabel: PropTypes.string.isRequired,
        checkBalanceOnAnotherCardCtaAllyLabel: PropTypes.string.isRequired,
        multipleAttemptFailureTitle: PropTypes.string.isRequired,
        multipleAttemptFailureDescription: PropTypes.string.isRequired,
        tryAgainCtaLabel: PropTypes.string.isRequired,
        tryAgainCtaAllyLabel: PropTypes.string.isRequired,
        emptyCardErrorMsg: PropTypes.string.isRequired,
        invalidCardErrorMsg: PropTypes.string.isRequired,
        partialCardErrorMsg: PropTypes.string.isRequired,
        cardLinkedErrorMsg: PropTypes.string.isRequired,
        ineligibleForBalanceCheckTitle: PropTypes.string.isRequired,
        ineligibleForBalanceCheckDescription: PropTypes.string.isRequired,
        cardMergeProcessTitle: PropTypes.string.isRequired,
        cardMergeProcessDescription: PropTypes.string.isRequired,
    }).isRequired,
}

export default BalanceCheckerWrapper
