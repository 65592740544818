import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Icon from '../Icon'
import { ReviewProps } from './Review.types'
import { unityValue } from '../Autocomplete/Autocomplete.constants'
import { magicNumber } from '../../utils'

/**
 * Reviews component story book
 *
 * @param {ReviewProps} props - path, numberOfReviews, averageRating
 * @return {JSX.Element} returns Review component
 */
const Review: React.FC<ReviewProps> = ({ ...props }): JSX.Element => {
    const { path, numberOfReviews, averageRating, displayReviews = true } = props
    const ratings: JSX.Element[] = []
    const stars: string[] = ['1', '2', '3', '4', '5']

    /**
     * looping through 5 stars and display active or inactive star based on average rating
     */
    const decimal = averageRating - Math.floor(averageRating)

    stars.forEach((starCounter: string) => {
        let ratingClass = ''
        const ratingVal = decimal.toFixed(unityValue)

        if (Number(starCounter) <= Math.floor(averageRating)) {
            ratingClass = 'ct-reviews-active'
        } else if (decimal && Number(starCounter) === Math.ceil(averageRating) && Number(ratingVal) > 0) {
            ratingClass = Number(ratingVal) === magicNumber.ONE ? 'ct-reviews-active' : `ct-reviews-${ratingVal}-active`
        } else {
            ratingClass = 'ct-reviews-inactive'
        }
        ratings.push(<Icon size="lg" type={ratingClass} path={path} key={starCounter}></Icon>)
    })

    return (
        <span className={`${PREFIX}-reviews__list`}>
            <span className={`${PREFIX}-reviews__list-stars`}>
                {ratings}
                {displayReviews && (
                    <span aria-hidden="true" className={`${PREFIX}-button ${PREFIX}-button--tertiary`}>
                        ({numberOfReviews})
                    </span>
                )}
            </span>
        </span>
    )
}
Review.propTypes = {
    path: PropTypes.string,
    numberOfReviews: PropTypes.number,
    averageRating: PropTypes.number,
    displayReviews: PropTypes.bool,
}
export default Review
