import { getType, ActionType } from 'typesafe-actions'

import { setDocLoadStatus } from '../actionCreators/docLoadStatus.actionCreator'
import * as actions from '../actionCreators/docLoadStatus.actionCreator'

type Action = ActionType<typeof actions>

export interface docLoadState {
    status: boolean
}

const initialState: docLoadState = {
    status: false,
}

export const docLoadStatusReducer = (state: docLoadState = initialState, action: Action): docLoadState => {
    if (action.type === getType(setDocLoadStatus)) {
        return { ...state, status: action.payload }
    } else {
        return state
    }
}
