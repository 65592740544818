import { stringKeyCodes } from '.'

/**
 * Initialize popup for aem handling
 * @param {string} popupId - popup id
 * @param {string} openedPopupClass - opened popup class
 * @param {string} closeButtonClass - close button class
 * @return {void}
 **/
export const popUpAEMHandler = (popupId: string, openedPopupClass: string, closeButtonClass: string): void => {
    const modalButton = document.querySelector(`[href="#${popupId}"]`)
    const modal = document.getElementById(popupId)

    if (modalButton && modal) {
        const modalCloseButton = modal.querySelector(`.${closeButtonClass}`)

        const closeModalEsc = (e: KeyboardEvent): void => {
            if (e.key === stringKeyCodes.esc) {
                closeModal()
            }
        }

        const openModal = () => {
            modal.classList.add(openedPopupClass)
            document.body.addEventListener('keydown', closeModalEsc)
        }

        const closeModal = () => {
            modal.classList.remove(openedPopupClass)
            document.body.removeEventListener('keydown', closeModalEsc)
        }

        modalButton.addEventListener('click', openModal)
        modalCloseButton?.addEventListener('click', closeModal)
    }
}
