import { createAction } from 'typesafe-actions'
import { DynamicComponentReduxType } from '../models/dynamicComponent.interface'

import {
    FETCH_DYNAMIC_COMPONENT_DATA,
    SET_DYNAMIC_CONTENT_DATA,
    SET_API_XHR_INFO,
} from '../types/dynamicComponent/dynamicComponent.actionTypes.constant'

export const fetchDynamicComponentData = createAction(FETCH_DYNAMIC_COMPONENT_DATA)()

export const setDynamicContentDataOnSuccess = createAction(
    SET_DYNAMIC_CONTENT_DATA,
    (payload: DynamicComponentReduxType) => payload,
)<DynamicComponentReduxType>()

export const setApiXhrInfo = createAction(SET_API_XHR_INFO, (payload: string) => payload)<string>()
