import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { SearchTitleProps } from './SearchTitle.type'
import Button from '../Button/Button'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'

/**
 * Search Title component
 * @return {JSX.Element} returns Search Title component
 */
const SearchTitle: React.FC<SearchTitleProps> = ({ ...props }): JSX.Element => {
    const {
        searchTitle,
        searchString,
        suggestiveMessage,
        didYouMean,
        suggestiveQueryClickCallback,
        lastSelectedCategory,
        similarProductsMessage,
    } = props

    const searchQuery = searchString ? `${searchTitle} "${searchString}" ` : ''

    /*
     * getSuggestiveSearchQueries function returns suggestive search link
     */
    const getSuggestiveSearchQueries = (): JSX.Element[] => {
        return didYouMean.map((suggestion: string, index: number) => {
            return (
                <Button
                    key={index}
                    type="tertiary"
                    onClick={() => {
                        suggestiveQueryClickCallback(suggestion)
                    }}>
                    {suggestion}
                </Button>
            )
        })
    }

    return (
        <div className={`${PREFIX}-srp`} data-testid="search-title">
            <h2 className={`${PREFIX}-srp__title`}>
                {searchQuery && <div>{searchQuery}</div>}
                {lastSelectedCategory && <div className={`${PREFIX}-srp__category`}>{lastSelectedCategory}</div>}
            </h2>
            {!!similarProductsMessage && (
                <div className={`${PREFIX}-srp__similar-products-message`}>{similarProductsMessage}</div>
            )}
            {isArrayNotEmpty(didYouMean) && (
                <div className={`${PREFIX}-srp__suggestive-message`}>
                    {suggestiveMessage}
                    <span className={`${PREFIX}-srp__suggestive-option`}>{getSuggestiveSearchQueries()}</span>
                </div>
            )}
        </div>
    )
}

SearchTitle.propTypes = {
    searchTitle: PropTypes.string,
    searchString: PropTypes.string,
    suggestiveMessage: PropTypes.string,
    didYouMean: PropTypes.array,
    suggestiveQueryClickCallback: PropTypes.func,
    lastSelectedCategory: PropTypes.string,
    similarProductsMessage: PropTypes.string,
}

export default SearchTitle
