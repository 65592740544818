/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { isArrayNotEmpty } from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import { PencilBannerGlobalProps } from '../components/AccountsPencilBanner/PencilBanner/PencilBanner.type'
import {
    NavigationCategory,
    SecondaryNavigationWrapperProps,
} from '../components/SecondaryNavigation/SecondaryNavigationWrapper.type'
import { CategoryResponseById, CategorySessionStorage } from '../redux/models/category.interface'
import { IGlobalProps } from '../redux/models/commonContent.interface'
import { InitialCategoryPayloadType, Category, SaleCategoriesType } from '../redux/models/secondaryNavigation.interface'
import checkNestedProps from '../utils/checkNestedProps'
import sessionStorageService from '../utils/sessionStorageService'
import { salesCategoryTypes } from './headerNavigationConstants'

/**
 * Header Navigation helper
 * @class
 */
class HeaderNavigationHelper {
    /**
     * Function to transform l1 categories into updated format.
     * @param {InitialCategoryPayloadType[]} previousReduxState
     * @param {Category[]} apiResponse
     * @param {IGlobalProps} commonContentState
     * @param {string} shopAllLabel
     * @return {InitialCategoryPayloadType[] | null}
     */
    static transformApiResponse(
        previousReduxState: InitialCategoryPayloadType[],
        apiResponse: Category[],
        commonContentState: IGlobalProps,
        shopAllLabel: string,
    ): InitialCategoryPayloadType[] | null {
        if (!isArrayNotEmpty(previousReduxState) || !isArrayNotEmpty(apiResponse)) {
            return null
        }
        const backToLabel = commonContentState?.backToLabel
        const backToHome = commonContentState?.backToHomeLabel
        const updatedMegaNavData = JSON.parse(JSON.stringify(previousReduxState)) as InitialCategoryPayloadType[]
        ;(apiResponse as InitialCategoryPayloadType[]).forEach(category => {
            const apiCategoryL1 = updatedMegaNavData.find(apiCat => {
                if (apiCat.id === category.id) {
                    category.saleCategories = apiCat?.saleCategories
                    return true
                } else {
                    return false
                }
            })
            if (apiCategoryL1) {
                apiCategoryL1.name = apiCategoryL1.name ?? category.name
                apiCategoryL1.megaNavTitle = `${shopAllLabel} ${category.name}`
                apiCategoryL1.backLinkLabel = `${backToHome}`
                apiCategoryL1.saleCategories = category?.saleCategories
                apiCategoryL1.categories = category.subcategories
                apiCategoryL1.url = category.url

                apiCategoryL1.categories?.forEach((apiCatL2: InitialCategoryPayloadType) => {
                    apiCatL2.megaNavTitle = `${shopAllLabel} ${apiCatL2.name}`
                    apiCatL2.backLinkLabel = `${backToLabel} ${apiCategoryL1.name}`
                })
            }
        })
        // insert hot sales and flyers
        // TODO: logic need to search hot sales and flyers

        return updatedMegaNavData
    }

    /**
     * Transform additional mega nav props which contains categories.
     * @param {SecondaryNavigationWrapperProps} aemProps
     * @param {string} shopAllLabel
     * @param {string} backToLabel
     * @param {string} backToMain
     * @param {string} autoServiceUrl
     * @return {InitialCategoryPayloadType[]}
     */
    static transformAemResponse(
        aemProps: SecondaryNavigationWrapperProps,
        shopAllLabel: string,
        backToLabel: string,
        backToMain: string,
        autoServiceUrl: string,
    ): InitialCategoryPayloadType[] {
        const reduxState: InitialCategoryPayloadType[] = []
        // Prepare CDS based categories
        aemProps.categoriesL1.forEach(category => {
            const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformCategory(
                category,
                shopAllLabel,
                backToLabel,
            )
            categoryState.saleCategories = []
            category?.saleUrl &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.saleLabel,
                        aemProps.saleLabel,
                        category?.saleUrl,
                        `${aemProps.megaNavCtaLabel} ${category.name}`,
                        salesCategoryTypes.sale,
                    ),
                )
            category?.clearanceUrl &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.clearanceLabel,
                        aemProps.clearanceLabel,
                        category?.clearanceUrl,
                        `${aemProps.megaNavCtaLabel} ${category.name}`,
                        salesCategoryTypes.clearance,
                    ),
                )
            category?.newArrivalsUrl &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.newArrivalsLabel,
                        aemProps.newArrivalsLabel,
                        category?.newArrivalsUrl,
                        `${aemProps.megaNavCtaLabel} ${category.name}`,
                        salesCategoryTypes.arrival,
                    ),
                )
            category?.needServiceButton &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.autoServiceLabel,
                        aemProps.autoServiceLabel,
                        autoServiceUrl,
                        aemProps.bookAppointmentLabel,
                        salesCategoryTypes.autoService,
                    ),
                )
            reduxState.push(categoryState)
        })

        // Prepare AEM based categories
        aemProps.additionalLinksMegaNav?.forEach(category => {
            const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformNestedCategories(
                category,
                shopAllLabel,
                backToLabel,
                backToMain,
            )
            reduxState.push(categoryState)
        })

        // Prepare AEM based categories
        aemProps.additionalLinksNoMegaNav.forEach(category => {
            const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformNestedCategories(
                category,
                shopAllLabel,
                backToLabel,
            )
            reduxState.push(categoryState)
        })

        return reduxState
    }

    /**
     * Transform nested props.
     * @param {NavigationCategory} category
     * @param {string} shopAllLabel
     * @param {string} backToLabel
     * @param {string} backToMain
     * @return {InitialCategoryPayloadType}
     */
    static transformNestedCategories(
        category: NavigationCategory,
        shopAllLabel: string,
        backToLabel: string,
        backToMain?: string,
    ): InitialCategoryPayloadType {
        const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformCategory(
            category,
            shopAllLabel,
            backToLabel,
            backToMain,
        )
        if (category.categories) {
            categoryState.categories = []
            category.categories.forEach(cat1 => {
                const categoryState1: InitialCategoryPayloadType = HeaderNavigationHelper.transformCategory(
                    cat1,
                    shopAllLabel,
                    `${backToLabel} ${category?.name}`,
                )
                if (cat1.categories) {
                    categoryState1.categories = []
                    cat1.categories.forEach(cat2 => {
                        categoryState1.categories?.push(
                            HeaderNavigationHelper.transformCategory(
                                cat2,
                                shopAllLabel,
                                `${backToLabel} ${cat1?.name}`,
                            ),
                        )
                    })
                }
                categoryState.categories?.push(categoryState1)
            })
        }
        return categoryState
    }

    /**
     * Modify the category object.
     * @param {NavigationCategory} category
     * @param {string} shopAllLabel
     * @param {string} backToLabel
     * @param {string} backToMain
     * @return {InitialCategoryPayloadType}
     */
    static transformCategory(
        category: NavigationCategory,
        shopAllLabel: string,
        backToLabel: string,
        backToMain?: string,
    ): InitialCategoryPayloadType {
        return {
            name: category.name,
            id: category.categoryId || category.name,
            url: category.url || category.saleUrl,
            style: category.style || 'standard',
            megaNavTitle: `${shopAllLabel} ${category.name}`,
            backLinkLabel: backToMain ? backToMain : `${backToLabel}`,
            needServiceButton: category.needServiceButton ? category.needServiceButton : false,
        }
    }

    /**
     * Accepts no category data and returns transformed data.
     * @param {InitialCategoryPayloadType} category
     * @return {InitialCategoryPayloadType}
     */
    static transformToMegaNavDesktopProps(category: InitialCategoryPayloadType): InitialCategoryPayloadType {
        const props: InitialCategoryPayloadType = JSON.parse(JSON.stringify(category)) as InitialCategoryPayloadType
        const categories: Partial<InitialCategoryPayloadType>[][] = []
        props.categories = isArrayNotEmpty(props.categories)
            ? props.categories.slice(MagicNumber.ZERO, MagicNumber.TEN)
            : props.categories
        for (let i = MagicNumber.ZERO; i < props.categories?.length; i++) {
            const column = i % MagicNumber.FIVE
            if (!categories[column]) {
                categories[column] = []
            }
            props.categories[i].categories = props.categories?.[i].subcategories?.length
                ? HeaderNavigationHelper.limitL3Categories(props.categories?.[i].subcategories)
                : props.categories?.[i].categories
            categories[column].push(props.categories?.[i])
        }
        props.categories = categories
        return props
    }

    /**
     * This function control max number of categories
     * @param {InitialCategoryPayloadType[]} l3Categories
     * @return {InitialCategoryPayloadType[]} l3Categories
     */
    static limitL3Categories(l3Categories: InitialCategoryPayloadType[]) {
        if (l3Categories.length < MagicNumber.FIVE) {
            return l3Categories
        } else {
            return l3Categories.slice(MagicNumber.ZERO, MagicNumber.FIVE)
        }
    }

    /**
     * Prepare sale category object.
     * @param {string} id
     * @param {string} name
     * @param {string} url
     * @param {string} buttonCtaText
     * @param {string} type
     * @return {SaleCategoriesType}
     */
    static prepareSalesCategory(
        id: string,
        name: string,
        url: string,
        buttonCtaText: string,
        type: string,
    ): SaleCategoriesType {
        return {
            id,
            name,
            url,
            buttonCtaText,
            type,
        }
    }

    /**
     * Extract pencil banner props from global props.
     * @param {IGlobalProps} commonContentAvailable
     * @return {PencilBannerGlobalProps}
     */
    static getPencilBannerGlobalProps = (commonContentAvailable: IGlobalProps): PencilBannerGlobalProps => {
        const authenticatedUserGreetingText = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'authenticatedUserGreetingText',
        ) as string
        const signOutLabel = checkNestedProps(commonContentAvailable, 'accountDashboard', 'signOutLabel') as string
        const signInLink = checkNestedProps(commonContentAvailable, 'globalLinks', 'loginPageLink') as string
        const orText = checkNestedProps(commonContentAvailable, 'accountDashboard', 'orText') as string
        const signInLabel = checkNestedProps(commonContentAvailable, 'accountDashboard', 'signInLabel') as string
        const cardNumberLabel = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'cardNumberLabel',
        ) as string
        const registerLink = checkNestedProps(commonContentAvailable, 'globalLinks', 'registrationPageLink') as string
        const registerLabel = checkNestedProps(commonContentAvailable, 'accountDashboard', 'registerLabel') as string
        const registerNowLabel = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'registerNowLabel',
        ) as string
        const registerNowLink = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'registerNowLink',
        ) as string
        const cardNumberLabelIcon = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'cardNumberLabelIcon',
        ) as string
        const rewardsUpsellingMessage = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'rewardsUpsellingMessage',
        ) as string
        const rewardsBalanceLabelIcon = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'rewardsBalanceLabelIcon',
        ) as string
        const triangleRewardsIcon = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'triangleRewardsIcon',
        ) as string
        const triangleRewardsLabel = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'triangleRewardsLabel',
        ) as string
        const rewardsRegisterRichText = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'rewardsRegisterRichText',
        ) as string
        const rewardsBalanceLabel = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'rewardsBalanceLabel',
        ) as string
        const backToMainLabel = checkNestedProps(
            commonContentAvailable,
            'accountDashboard',
            'backToMainLabel',
        ) as string

        return {
            authenticatedUserGreetingText,
            signOutLabel,
            signInLink,
            orText,
            signInLabel,
            registerLink,
            registerLabel,
            registerNowLabel,
            triangleRewardsIcon,
            triangleRewardsLabel,
            rewardsRegisterRichText,
            rewardsBalanceLabel,
            backToMainLabel,
            cardNumberLabel,
            registerNowLink,
            cardNumberLabelIcon,
            rewardsBalanceLabelIcon,
            rewardsUpsellingMessage,
        }
    }

    /**
     * function to crete session storage data for categories
     * @param {CategoryResponseById} data
     * @param {string} expires
     * @param {string} date
     * @param {string} cacheControl
     * @return {CategorySessionStorage}
     */
    static prepareCategorySessionStorage = (
        data: CategoryResponseById,
        expires: string,
        date: string,
        cacheControl: string,
    ): CategorySessionStorage => {
        const expiringTime = new Date(expires).getTime()
        const currentDate = new Date(date).getTime()
        // sample response cache-control: public,max-age=86400 OR cache-control: max-age=0, no-cache, no-store
        const maxAgeInSeconds = cacheControl?.split('=')[MagicNumber.ONE]?.split(',')[0]
        const maxAgeInMiliSecond = Number(maxAgeInSeconds) * MagicNumber.ONETHOUSAND
        return {
            categoryData: data,
            expiryTimestamp: expiringTime,
            maxAge: maxAgeInMiliSecond + currentDate,
        }
    }

    /**
     * function to get category data from session storage
     * @param {string} storageKey
     * @return {CategorySessionStorage}
     */
    static getCategoryDataFromSessionStorage = (storageKey: string): CategorySessionStorage => {
        let expiryTimestamp
        let maxAge
        const categoryData = sessionStorageService.getItem(storageKey)
        if (categoryData) {
            const categoryDataParsed = JSON.parse(categoryData) as CategorySessionStorage
            expiryTimestamp = categoryDataParsed.expiryTimestamp
            maxAge = categoryDataParsed.maxAge
        }
        return { categoryData, expiryTimestamp, maxAge }
    }
}

export { HeaderNavigationHelper }

export default HeaderNavigationHelper
