import { PaymentUnitType } from '../utils/CreditCardExpired/isCreditCardExpired.type'

/**
 * render valid year and month details
 * @param { string } date
 * @param { string } year
 * @return { PaymentUnitType }
 */
export const getDateDetails = (date: string, year: string): PaymentUnitType => {
    return {
        validToYear: year,
        validToMonth: date,
    }
}
