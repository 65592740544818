import React from 'react'
import { SummaryCardProps } from './SummaryCard.type'
import { PREFIX } from '../config'
import CommonCardWrapper from '../CommonCardWrapper'
import CommonCard from '../CommonCard'
import Icon from '../Icon'

/**
 * Summary Card component
 * @return {JSX.Element} returns Summary card component
 */
const SummaryCard: React.FC<SummaryCardProps> = ({ ...props }) => {
    const { image, title, subTitle, children, ctaButtonInfo, componentClass } = props
    const className = componentClass ? `${PREFIX}-${componentClass}` : `${PREFIX}-summary-card`
    return (
        <CommonCardWrapper noPadding={true}>
            <CommonCard noPadding>
                <>
                    {image && (
                        <div className={`${className}__image-wrapper ${PREFIX}-row`}>
                            <img src={image.url} alt={image.altText} />
                        </div>
                    )}
                    {title && (
                        <div className={`${className}__heading`}>
                            <h4 className={`${className}__title ${PREFIX}-sm-block`}>{title}</h4>
                            {subTitle && <p className={`${className}__subtitle`}>{subTitle}</p>}
                        </div>
                    )}
                    {children}
                    {ctaButtonInfo && (
                        <a
                            className={`${className}__link ${PREFIX}-row`}
                            aria-label={ctaButtonInfo.label}
                            href={ctaButtonInfo.url}
                            target={ctaButtonInfo.target}
                            title={ctaButtonInfo.label}>
                            {ctaButtonInfo.label}
                            {ctaButtonInfo.ctaIcon && (
                                <Icon type={ctaButtonInfo.ctaIcon.type} size={ctaButtonInfo.ctaIcon.size} />
                            )}
                        </a>
                    )}
                </>
            </CommonCard>
        </CommonCardWrapper>
    )
}

export default SummaryCard
