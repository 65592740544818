export enum ActionType {
    SET = 'SET',
    REMOVE = 'REMOVE',
    NEXT = 'NEXT',
    PREVIOUS = 'PREV',
    DOWN_ARROW = 'DOWN',
    UP_ARROW = 'UP',
}

export interface MenuAccessibilityInterface {
    menuId: string | HTMLElement
}

export interface MenuBarAccessibilityRequestParams {
    init: (menuBarId: string) => void
    destroy: () => void
}
export type TextHTMLPropsType = {
    firstItem: string
    secondItem: string
    thirdItem: string
    lastItem: string
    wrapperId: string
}
