import { getCapitalizeString } from './getCapitalizeString'
import { magicNumber } from './magicNumber'
import { replaceMultipleStrWithDynamicVal } from './replaceStrWithDynamicVal'

/**
 * Replaces dynamic string with card name and number
 *
 * @param {PaymentUnitType} propText - AEM prop value to replace with dynamic string(s)
 * @param {PaymentUnitType} card - credit card info
 * @return {string} - returns the string with card name and number
 */

export const getCardNameAndNumber = (propText: string, card: Record<string, string>): string => {
    return `${replaceMultipleStrWithDynamicVal(propText, [
        getCapitalizeString(card.type),
        card.maskedCardNumber.slice(magicNumber.MINUS_FOUR),
    ])}`
}

/**
 * function to get date from credit card in format MM/YY
 * @param {number} validToMonth
 * @param {number} validToYear
 * @return {string}
 */
export const getDateFromCreditCard = (validToMonth: number, validToYear: number): string => {
    const month = String(validToMonth).padStart(magicNumber.TWO, `${magicNumber.ZERO}`)
    const year = String(validToYear).substr(magicNumber.MINUS_TWO)
    return `${month}/${year}`
}

/**
 * returns object {logo: '', type: ''} from array [{logo: '', type: ''},...] based on cardType
 * @param {Record<string, string>[]} cardsData
 * @param {string} cardType
 * @return {Record<string, string> | undefined}
 */
export const getCardLogo = (cardsData: Record<string, string>[], cardType = ''): Record<string, string> | undefined =>
    cardsData?.find(item => ~item.type.trim().toLowerCase().split(',')?.indexOf(cardType.trim().toLowerCase()))
