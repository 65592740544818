import React, { Validator } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../components/Icon'
import { PREFIX } from '../../components/config'
import classnames from 'classnames'
import { getAriaCurrentValue } from '../MegaNavigation/MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'
import {
    InteractionAnalyticsType,
    KpNavigationCatalog,
    MegaNavigationDesktopType,
    NavigationCatalog,
} from './MegaNavigationDesktop.type'
import SubCategories from './SubCategories'
import NavExpansionPanel from '../NavExpansionPanel'

export const MegaNavigationDesktop: React.FC<MegaNavigationDesktopType> = ({
    catalog,
    InteractionAnalytics,
    eventName,
    actionName,
    isKp,
}) => {
    const getNavExpandedPanel = () => {
        if (isKp) {
            return <NavExpansionPanel catalog={catalog as KpNavigationCatalog} />
        } else {
            return (
                <SubCategories
                    catalog={catalog}
                    InteractionAnalytics={InteractionAnalytics}
                    eventName={eventName}
                    actionName={actionName}
                />
            )
        }
    }

    const getRenderContent = () => {
        if (isKp) {
            return (
                <>
                    <a
                        data-link-value={catalog?.megaNavTitle}
                        href={encodeURI(typeof catalog?.url === 'string' ? catalog?.url : catalog?.url?.url)}>
                        {catalog?.megaNavTitle}
                    </a>{' '}
                    <Icon type="ct-chevron-right" size="md" />
                </>
            )
        } else {
            return (
                <InteractionAnalytics
                    componentName="MegaNavigation"
                    label={catalog?.megaNavTitle}
                    eventName={eventName}
                    actionName={actionName}>
                    <a
                        aria-current={getAriaCurrentValue(catalog) ? ariaType : false}
                        data-link-value={catalog?.megaNavTitle}
                        href={encodeURI(typeof catalog?.url === 'string' ? catalog?.url : catalog?.url?.url)}>
                        {catalog?.megaNavTitle}
                    </a>{' '}
                    <Icon type="ct-chevron-right" size="md" />
                </InteractionAnalytics>
            )
        }
    }

    return (
        <div className={classnames(`${PREFIX}-mega-navigation`, `${PREFIX}-row `)}>
            <div className={classnames(`${PREFIX}-col-sm-12`, `${PREFIX}-mega-navigation__padding`)}>
                <h3>{getRenderContent()}</h3>
            </div>
            <hr />
            {/* L2 links container */}
            <>{getNavExpandedPanel()}</>
        </div>
    )
}

MegaNavigationDesktop.propTypes = {
    InteractionAnalytics: PropTypes.node as unknown as Validator<React.FC<InteractionAnalyticsType>>,
    catalog: PropTypes.object as Validator<NavigationCatalog>,
}
