/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { replaceStrWithDynamicVal } from '@nl/lib'
import { MagicNumber } from '../../analytics/analytics.type'
import {
    AvailableDeliveryModesType,
    CartItemFulfillmentSource,
    CartItemFulFillmentType,
    CartOrderDeliveryModes,
    CartOrderEntries,
    ExpressRejectReasons,
    FilteredCartData,
    ProductSourceTypes,
    ProductStatus,
    STHDeliveryModes,
    StockItemAvailability,
} from '../../redux/models/cart.interface'
import appCacheService from '../../utils/appCacheService'
import { areAllParamsValid } from '../../utils/getFilteredCartItems'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import {
    hasCartItemsBecomeUnavailableByDCQty,
    hasCartItemsBecomeUnavailableByStoreQty,
    hasCartItemsBecomeUnavailableForBOPIS,
    hasCartItemsBecomeUnavailableForSTH,
    checkShipToHomeError,
} from './CartItem.util'
import {
    FulfillmentMethods,
    FulfillmentErrorType,
    GetErrorFunctionPropType,
    ItemInlineErrorType,
    OptionsOnCartItem,
} from './ShoppingCart.type'

export const CartErrorService = (() => {
    const previousCartItems: FilteredCartData = appCacheService.cartData.get()
    const cartLastVisitedDate: string = appCacheService.cartLastVisitedDate.get()

    /**
     * check whether there is any change  in DC Min and Max Eta .
     * @param {CartItemFulFillmentType} oldFulfillment
     * @param {CartItemFulFillmentType} newFulfillment
     * @return {boolean}
     */
    const __checkDCChangeHelper = (
        oldFulfillment: CartItemFulFillmentType,
        newFulfillment: CartItemFulFillmentType,
    ): boolean => {
        const { sourceTypes } = oldFulfillment
        const productDCSource = sourceTypes.find(source => source.productSourceType === ProductSourceTypes.DC)
        const productStoreSource = sourceTypes.find(source => source.productSourceType === ProductSourceTypes.INSTORE)
        const minEta = productDCSource?.etaEarliest
        const maxEta = productDCSource?.etaLatest
        const oldEtaEarliest = productStoreSource?.etaEarliest
        const oldEtaLatest = productStoreSource?.etaLatest

        const { sourceTypes: sourceTypesNew } = newFulfillment
        const productDCSourceNew = sourceTypesNew.find(source => source.productSourceType === ProductSourceTypes.DC)
        const productStoreSourceNew = sourceTypesNew.find(
            source => source.productSourceType === ProductSourceTypes.INSTORE,
        )
        const newMinEta = productDCSourceNew?.etaEarliest
        const newMaxEta = productDCSourceNew?.etaLatest
        const etaEarliest = productStoreSourceNew?.etaEarliest
        const etaLatest = productStoreSourceNew?.etaLatest

        return !(
            minEta === newMinEta &&
            maxEta === newMaxEta &&
            oldEtaEarliest === etaEarliest &&
            oldEtaLatest === etaLatest
        )
    }

    /**
     * Frame the error response.
     * @param {boolean} showInlineError - true, if the item has error.
     * @param {string} errorMessage - toast error message.
     * @param {boolean} grayOutImage - if true, will apply opacity of 0.4 on the image.
     * @param {string[]} optionsToBeDisplayed - items to be displayed when the item as errors.
     * @param {string} fulfillmentDeliverError -if deliver mode  not available during checkout but initially was available
     * @param {boolean} hardStopError - if true, user should not be allowed to proceed to checkout.
     * @param {boolean} isShowETAToast - if true, User is able to get cart level toast when ETA changed.
     * @return {ItemInlineErrorType} returns error object, which then is passed to the productCartItem.
     */
    const _constructErrorResponse = (
        showInlineError: boolean,
        errorMessage: string,
        grayOutImage: boolean,
        optionsToBeDisplayed: string[],
        fulfillmentDeliverError = '',
        hardStopError = false,
        isShowETAToast = false,
    ): ItemInlineErrorType => {
        return {
            showInlineError,
            errorMessage,
            grayOutImage,
            optionsToBeDisplayed,
            fulfillmentDeliverError,
            hardStopError,
            isShowETAToast,
        }
    }

    /**
     * Frames the error options based on the product status and the info attached to that particular error.
     * @param {Record<string, string>} message - array of messages used when the item is out of stock in all stores and have a product status.
     * @param {FulfillmentMethods} itemType - BOPIS or STH.
     *
     * @return {ItemInlineErrorType}
     */
    const _outOfStockConditions = (
        message: Record<string, string>,
        itemType: FulfillmentMethods,
    ): ItemInlineErrorType => {
        const { outOfStockInStorePickUpMsg, outOfStockInStoreShipFromMsg } = message

        const errorMessage =
            itemType === FulfillmentMethods.BOPIS ? outOfStockInStorePickUpMsg : outOfStockInStoreShipFromMsg
        return _constructErrorResponse(
            true,
            errorMessage,
            false,
            [OptionsOnCartItem.CHECK_NEAR_BY, OptionsOnCartItem.WISHLIST],
            '',
            true,
        )
    }

    /**
     * Function to check conditions when the product is discontinued.
     * @param {ProductStatus} productStatus - used to throw different error based on the status.
     * @param {Record<string, string>} message - message object
     * @param {FulfillmentMethods} itemType - BOPIS or STH
     *
     * @return {ItemInlineErrorType}
     */
    const __discontinuedOOSConditions = (
        productStatus: ProductStatus,
        message: Record<string, string>,
        itemType: FulfillmentMethods,
    ): ItemInlineErrorType => {
        const {
            discontinuedInStorePickUpMsg,
            discontinuedInStoreShipFromMsg,
            tempDiscontinuedInStorePickUpMsg,
            tempDiscontinuedInStoreShipFromMsg,
        } = message
        const DwoAndFDMessage =
            itemType === FulfillmentMethods.BOPIS ? discontinuedInStorePickUpMsg : discontinuedInStoreShipFromMsg
        const SDIAndTDIMessage =
            itemType === FulfillmentMethods.BOPIS
                ? tempDiscontinuedInStorePickUpMsg
                : tempDiscontinuedInStoreShipFromMsg

        return _discontinuedOOSErrorResponse(true, DwoAndFDMessage, SDIAndTDIMessage, productStatus)
    }
    /**
     * Function to Get Error Response for OOS and DISCONTINUED
     * @param {Boolean} isRequiredOptionToBeDisplayed - to Decide Option to Display or not
     * @param {string} DwoAndFDMessage
     * @param {string} SDIAndTDIMessage
     *  @param {ProductStatus} productStatus - used to throw different error based on the status.
     *
     * @return {ItemInlineErrorType}
     */
    const _discontinuedOOSErrorResponse = (
        isRequiredOptionToBeDisplayed: boolean,
        DwoAndFDMessage: string,
        SDIAndTDIMessage: string,
        productStatus: ProductStatus,
    ): ItemInlineErrorType => {
        if (
            productStatus === ProductStatus.FULLY_DISCONTINUED ||
            productStatus === ProductStatus.DISCONTINUED_WHEN_OUT
        ) {
            return _constructErrorResponse(
                true,
                DwoAndFDMessage,
                false,
                isRequiredOptionToBeDisplayed ? [OptionsOnCartItem.CHECK_NEAR_BY, OptionsOnCartItem.WISHLIST] : [],
                '',
                true,
            )
        }
        return _constructErrorResponse(
            true,
            SDIAndTDIMessage,
            false,
            isRequiredOptionToBeDisplayed ? [OptionsOnCartItem.CHECK_NEAR_BY, OptionsOnCartItem.WISHLIST] : [],
            '',
            true,
        )
    }
    /**
     * Check in stock in three stores.
     * @param {number} currentStoreQuantity - if > 0, there is some quantity in current store.
     * @param {number} dcQuantity - if > 0, there is some quantity in dc.
     * @param {number} selectedQuantity - number of items selected.
     * @param {number} orderableFromDC - If true, then only we can look for dc.
     *
     * @return {Record<string, unknown>} returns method to check different conditions.
     */
    const _checkIsStockInAllStore = (
        currentStoreQuantity: number,
        dcQuantity: number,
        selectedQuantity: number,
        orderableFromDC: boolean,
    ): Record<string, () => boolean> => {
        /**
         * check whether the item is in distribution center
         * @return {boolean}
         */
        const _isStockInDC = (): boolean => Boolean(orderableFromDC && !!dcQuantity)

        /**
         * Stock only in dc.
         * @return {boolean}
         */
        const _isStockOnlyInDC = (): boolean => Boolean(!currentStoreQuantity) && _isStockInDC()

        /**
         * check whether there is any limited stock in the current store and the dc and no near by stores.
         * @return {boolean}
         */
        const _splitQuantity = (): boolean => Boolean(currentStoreQuantity < selectedQuantity) && _isStockInDC()

        /**
         * Check whether current store is empty but not dc.
         * @return {boolean}
         */
        const _discontinuedAndOOS = (): boolean => Boolean(!currentStoreQuantity)

        /**
         * Case where we need to split but not enough stock.
         * Also check for orderFromDc. if true then consider dcQuantity otherwise 0
         * @return {boolean}
         */
        const _isStockNotEnoughForSplit = (): boolean =>
            Boolean(currentStoreQuantity + (_isStockInDC() ? dcQuantity : 0) < selectedQuantity)

        return {
            _isStockInDC,
            _splitQuantity,
            _isStockNotEnoughForSplit,
            _discontinuedAndOOS,
            _isStockOnlyInDC,
        }
    }

    /**
     * Checks for Eta change for a product.
     * @param {string} sku - product unique key.
     * @param {string} productType - BOPIS or STH.
     * @param {string} sourceType -Instore or Dc
     * @param {CartItemFulFillmentType} fulfillmentData - data that contains info about delivery stocks etc
     * @return {boolean} - returns the error based on the conditions.
     */
    const isEtaChanged = (
        sku: string,
        productType: string,
        sourceType: string,
        fulfillmentData: CartItemFulFillmentType,
    ): boolean => {
        let isEtaChange = false
        if (checkDataLength(previousCartItems as unknown as Record<string, unknown>)) {
            const {
                cart: { orderEntries },
            } = previousCartItems
            const productValue = orderEntries?.find(value => value.code === sku)
            const { fulfillment } = enableDestructOnUndefinedData(productValue) as CartOrderEntries
            const { sourceTypes } = enableDestructOnUndefinedData(fulfillment) as CartItemFulFillmentType
            const { etaEarliest, etaLatest } = enableDestructOnUndefinedData(
                sourceTypes?.find(source => source.productSourceType === sourceType),
            ) as CartItemFulfillmentSource
            const { sourceTypes: sourceTypesNew } = enableDestructOnUndefinedData(
                fulfillmentData,
            ) as CartItemFulFillmentType
            const { etaEarliest: newEtaEarliest, etaLatest: newEtaLatest } = enableDestructOnUndefinedData(
                sourceTypesNew?.find(source => source.productSourceType === sourceType),
            ) as CartItemFulfillmentSource
            if (productValue && productType === FulfillmentMethods.BOPIS) {
                isEtaChange =
                    sourceType === ProductSourceTypes.DC
                        ? `${etaEarliest}${etaLatest}` !== `${newEtaEarliest}${newEtaLatest}`
                        : etaEarliest !== newEtaEarliest
            } else if (productValue && productType === FulfillmentMethods.STH) {
                isEtaChange = `${etaEarliest}${etaLatest}` !== `${newEtaEarliest}${newEtaLatest}`
            }
        }
        return isEtaChange
    }

    /**
     * Calls isEtaChanged for different sourceTypes  i.e INStore || DC.
     * @param {string} sku - product unique key.
     * @param {string} productType - BOPIS or STH.
     * @param {CartItemFulFillmentType} fulfillmentData - data that contains info about delivery stocks etc
     * @return {boolean} if anyone of isStoreEtaChanged or isDcEtaChanged is true
     */
    const isFulfillmentEtaChanged = (
        sku: string,
        productType: string,
        fulfillmentData: CartItemFulFillmentType,
    ): boolean => {
        const isStoreEtaChanged = isEtaChanged(sku, productType, ProductSourceTypes.INSTORE, fulfillmentData)
        const isDcEtaChanged = isEtaChanged(sku, productType, ProductSourceTypes.DC, fulfillmentData)
        return isStoreEtaChanged || isDcEtaChanged
    }

    const createNoErrorObject = (): ItemInlineErrorType => {
        return _constructErrorResponse(false, '', false, [])
    }

    /**
     * Checks multiple conditions and returns an error object.
     * @param {CartOrderEntries} itemData - Single Product Item Data.
     * @param {FulfillmentMethods} itemType - BOPIS or STH
     *
     * @return {GetErrorFunctionPropType} - returns the error based on the conditions.
     */

    const _checkDeliveryCartErrors = (
        itemData: Record<string, unknown>,
        itemType: FulfillmentMethods,
    ): ItemInlineErrorType => {
        const { code: sku, fulfillment } = itemData
        // replaced isEtachanged with isFulfillmentEtaChanged to check for both INSTORE and DC
        if (isFulfillmentEtaChanged(sku, itemType, fulfillment)) {
            return _constructErrorResponse(
                false,
                '',
                false,
                [
                    OptionsOnCartItem.WISHLIST,
                    OptionsOnCartItem.UPDATED_TEXT,
                    OptionsOnCartItem.PRICE_AND_QUANTITY,
                    OptionsOnCartItem.AVAILABILITY_INFO,
                ],
                '',
                false,
                cartLastVisitedDate === new Date().toLocaleDateString(),
            )
        }
        return createNoErrorObject()
    }

    const checkAvailableQuantity = (fulfillment: CartItemFulFillmentType): number => {
        const { deliveryMode } = fulfillment
        const { dcQuantity, storeQuantity } = fulfillment?.stockItemAvailability || {}

        return deliveryMode === CartOrderDeliveryModes.BOPIS ? storeQuantity : dcQuantity
    }

    const _defineItemErrorSTH = (
        itemData: any,
        messageObject: Record<string, string>,
        initialItemData: CartOrderEntries,
        deliveryMode: string,
    ): ItemInlineErrorType => {
        const { fulfillment } = itemData
        const {
            isEligibleToShipHome,
            stockItemAvailability: { dcQuantity },
            isEligibleForExpressDelivery,
        } = fulfillment

        const { itemUnavailableForSTH, itemNoLongerAvailableForSTH, itemNotEligibleForExpressDeliveryMsg } =
            messageObject

        const availableOption = [OptionsOnCartItem.AVAILABILITY_INFO, OptionsOnCartItem.WISHLIST]

        if (deliveryMode === FulfillmentMethods.EXPRESS) {
            if (!isEligibleForExpressDelivery) {
                return _constructErrorResponse(
                    true,
                    itemNotEligibleForExpressDeliveryMsg,
                    false,
                    availableOption,
                    '',
                    true,
                )
            }

            return createNoErrorObject()
        }

        const isBecameUnavailable = !dcQuantity && initialItemData?.fulfillment?.stockItemAvailability?.dcQuantity
        const isBecameNotEligibleForSTH = !isEligibleToShipHome && initialItemData?.fulfillment?.isEligibleToShipHome

        if (isBecameUnavailable || isBecameNotEligibleForSTH) {
            return _constructErrorResponse(true, itemNoLongerAvailableForSTH, false, availableOption, '', true)
        } else if (!isEligibleToShipHome || !dcQuantity) {
            return _constructErrorResponse(true, itemUnavailableForSTH, false, availableOption, '', true)
        }
        return createNoErrorObject()
    }

    const _defineItemErrorBOPIS = (
        itemData: any,
        messageObject: Record<string, string>,
        initialItemData: CartOrderEntries,
    ): ItemInlineErrorType => {
        const { fulfillment } = itemData
        const {
            isEligibleToPickupFromStore,
            isEligibleToShipHome,
            stockItemAvailability: { storeQuantity },
        } = fulfillment
        const { itemUnavailableForBOPIS, itemUnavailableAtStoreForBOPIS, itemNoLongerAvailableForBOPIS } = messageObject
        const checkNearByOption = [
            OptionsOnCartItem.AVAILABILITY_INFO,
            OptionsOnCartItem.CHECK_NEAR_BY,
            OptionsOnCartItem.WISHLIST,
        ]
        const unavailableOption = [OptionsOnCartItem.AVAILABILITY_INFO, OptionsOnCartItem.WISHLIST]

        const isBecameUnavailableAtStore =
            !storeQuantity && initialItemData?.fulfillment?.stockItemAvailability?.storeQuantity
        const isBecameNotEligibleForBOPIS =
            !isEligibleToPickupFromStore && initialItemData?.fulfillment?.isEligibleToPickupFromStore
        const isAvailableForBOPISNearby = isEligibleToPickupFromStore || !isEligibleToShipHome

        if (isBecameUnavailableAtStore && isAvailableForBOPISNearby) {
            return _constructErrorResponse(true, itemNoLongerAvailableForBOPIS, false, checkNearByOption, '', true)
        } else if (isBecameNotEligibleForBOPIS) {
            return _constructErrorResponse(true, itemNoLongerAvailableForBOPIS, false, unavailableOption, '', true)
        } else if (!storeQuantity && isAvailableForBOPISNearby) {
            return _constructErrorResponse(true, itemUnavailableAtStoreForBOPIS, false, checkNearByOption, '', true)
        } else if (!isEligibleToPickupFromStore) {
            return _constructErrorResponse(true, itemUnavailableForBOPIS, false, unavailableOption, '', true)
        }
        return createNoErrorObject()
    }

    const _defineSingleShippingMethodError = (
        itemData: any,
        messageObject: Record<string, string>,
        itemType: FulfillmentMethods,
        initialItemData: CartOrderEntries,
        deliveryMode = '',
    ): ItemInlineErrorType => {
        const { exceededMsgBar } = messageObject
        const optionsToBeDisplayed = [
            OptionsOnCartItem.AVAILABILITY_INFO,
            OptionsOnCartItem.PRICE_AND_QUANTITY,
            OptionsOnCartItem.WISHLIST,
        ]
        const { sellable, fulfillment, quantity, maxPurchaseQty } = itemData

        if (!sellable) {
            return _constructErrorResponse(
                true,
                messageObject.productCannotBePurchasedOnline,
                true,
                [OptionsOnCartItem.WISHLIST, OptionsOnCartItem.AVAILABILITY_INFO],
                '',
                true,
            )
        }

        if (maxPurchaseQty && quantity > maxPurchaseQty) {
            const errorMessage = replaceStrWithDynamicVal(exceededMsgBar, maxPurchaseQty)
            return _constructErrorResponse(true, errorMessage, false, optionsToBeDisplayed, '', true)
        }

        const availableQuantity = checkAvailableQuantity(fulfillment)
        if (availableQuantity && quantity > availableQuantity && availableQuantity < MagicNumber.NINETY_NINE) {
            const errorMessage = replaceStrWithDynamicVal(exceededMsgBar, availableQuantity)
            return _constructErrorResponse(true, errorMessage, false, optionsToBeDisplayed, '', true)
        }

        return itemType === FulfillmentMethods.STH
            ? _defineItemErrorSTH(itemData, messageObject, initialItemData, deliveryMode)
            : _defineItemErrorBOPIS(itemData, messageObject, initialItemData)
    }

    const _getCartLevelErrorSTH = (
        initialCartItems: FilteredCartData,
        currentCartItems: FilteredCartData,
        messages: Record<string, string>,
        isExpressDeliveryEnabled: boolean,
    ): string => {
        const {
            itemsForceSetForSTH,
            becameUnavailableForSTHCartError,
            unavailableForSTHCartError,
            itemsNotEligibleForShippingMethodGeneralMsg,
        } = messages
        const { orderEntries } = currentCartItems.cart

        if (appCacheService.isDeliveryModeConflict.get()) {
            return itemsForceSetForSTH
        }

        const hasBecomeUnavailableForSTH = hasCartItemsBecomeUnavailableForSTH(
            initialCartItems?.cart.orderEntries,
            orderEntries,
        )

        const hasBecomeUnavailableByQty = hasCartItemsBecomeUnavailableByDCQty(
            initialCartItems?.cart.orderEntries,
            orderEntries,
        )

        const hasNotSellable = orderEntries?.some(item => !item.sellable)

        if (hasBecomeUnavailableForSTH || hasBecomeUnavailableByQty || hasNotSellable) {
            return becameUnavailableForSTHCartError
        }

        const isBOPISOnlyOrUnavailableForSTH = orderEntries?.some(item => {
            const {
                fulfillment: { isEligibleToShipHome, stockItemAvailability },
            } = item
            return !isEligibleToShipHome || !stockItemAvailability.dcQuantity
        })
        if (isBOPISOnlyOrUnavailableForSTH) {
            return isExpressDeliveryEnabled ? itemsNotEligibleForShippingMethodGeneralMsg : unavailableForSTHCartError
        }

        return ''
    }

    const _getCartLevelErrorExpress = (
        messages: Record<string, string>,
        expressDeliveryMode: AvailableDeliveryModesType,
        isQuantityExceedThanStoreQty: boolean,
    ): string => {
        const {
            postalCodeIsNotEligibleForExpressDeliveryMsg,
            cartExceedsWeightLimitMsg,
            cartExceedsSizeLimitMsg,
            itemsNotEligibleForShippingMethodGeneralMsg,
            insufficientQuantityOfProductAtSelectedStoreMsg,
            cartExceedsExpressThresholdLimitMsg,
        } = messages

        let errMsg = ''

        switch (expressDeliveryMode?.rejectionReason) {
            case ExpressRejectReasons.WEIGHT_EXCEEDED:
                errMsg = cartExceedsWeightLimitMsg
                break
            case ExpressRejectReasons.SIZE_EXCEEDED:
                errMsg = cartExceedsSizeLimitMsg
                break
            case ExpressRejectReasons.NOT_ELIGIBLE:
                errMsg = itemsNotEligibleForShippingMethodGeneralMsg
                break
            case ExpressRejectReasons.DISTANCE_EXCEEDED:
                errMsg = postalCodeIsNotEligibleForExpressDeliveryMsg
                break
            case ExpressRejectReasons.TOTAL_EXCEEDED:
                errMsg = cartExceedsExpressThresholdLimitMsg
                break
            default:
                errMsg = ''
        }

        if (isQuantityExceedThanStoreQty) {
            errMsg = insufficientQuantityOfProductAtSelectedStoreMsg
        }

        return errMsg
    }

    const _getCartLevelErrorBOPIS = (
        initialCartItems: FilteredCartData,
        currentCartItems: FilteredCartData,
        messages: Record<string, string>,
    ): string => {
        const { itemsForceSetForBOPIS, unavailableForBOPISCartError, becameUnavailableForBOPISCartError } = messages
        const { orderEntries, store } = currentCartItems.cart
        const messageWithStore = (message: string): string => {
            const preparedMsg = message.replace('[0]', '<b>[0]</b>')
            return replaceStrWithDynamicVal(preparedMsg, store.name)
        }

        if (appCacheService.cartStoreChanged.get()) {
            return messageWithStore(itemsForceSetForBOPIS)
        }

        if (orderEntries?.some(item => !item.sellable)) {
            return messageWithStore(unavailableForBOPISCartError)
        }

        const hasBecomeUnavailableForBOPIS = hasCartItemsBecomeUnavailableForBOPIS(
            initialCartItems?.cart.orderEntries,
            orderEntries,
        )

        const hasBecomeUnavailableByQty = hasCartItemsBecomeUnavailableByStoreQty(
            initialCartItems?.cart.orderEntries,
            orderEntries,
        )

        if (hasBecomeUnavailableForBOPIS || hasBecomeUnavailableByQty) {
            return messageWithStore(becameUnavailableForBOPISCartError)
        }

        const isSTHOnlyOrUnavailableForBOPIS = orderEntries?.some(item => {
            const {
                fulfillment: { isEligibleToPickupFromStore, stockItemAvailability },
            } = item
            return !isEligibleToPickupFromStore || !stockItemAvailability.storeQuantity
        })
        if (isSTHOnlyOrUnavailableForBOPIS) {
            return messageWithStore(unavailableForBOPISCartError)
        }

        return ''
    }

    const _getCartLevelError = (
        initialCartItems: FilteredCartData,
        currentCartItems: FilteredCartData,
        messages: Record<string, string>,
        isExpressDeliveryEnabled: boolean,
    ) => {
        if (currentCartItems.cart.deliveryMode === FulfillmentMethods.EXPRESS) {
            const expressDeliveryMode = currentCartItems.cart.availableDeliveryModes?.filter(
                data => data?.code === STHDeliveryModes.EXPRESS,
            )[0]

            const isQuantityExceedThanStoreQty = currentCartItems?.sth?.some(product => {
                const storeMaxQuantity = Math.min(
                    product.fulfillment.stockItemAvailability.storeQuantity,
                    product.maxPurchaseQty || product.fulfillment.stockItemAvailability.storeQuantity,
                )
                const qtySelectorInput = product.quantity

                return qtySelectorInput > storeMaxQuantity
            })

            return _getCartLevelErrorExpress(messages, expressDeliveryMode, isQuantityExceedThanStoreQty)
        }

        return currentCartItems.cart.deliveryMode === FulfillmentMethods.STH
            ? _getCartLevelErrorSTH(initialCartItems, currentCartItems, messages, isExpressDeliveryEnabled)
            : _getCartLevelErrorBOPIS(initialCartItems, currentCartItems, messages)
    }

    /**
     * Checks multiple conditions and returns an error object.
     * @param {CartOrderEntries} itemData - Single Product Item Data.
     * @param {boolean} singleShippingMethodOption - choose shipping method feature
     * @param {string} deliveryMode - delivery mode of the Cart
     * @param {Record<string, string>} messageObject - object which holds all the error messages with respect to the product item.
     * @param {FulfillmentMethods} itemType - BOPIS or STH
     * @param {StorePickUp | ShipToHome} itemAvailabilityData - fulfillment data of the item.
     * @param {CartOrderEntries} initialItemData - item after Cart page open
     * @param {SelectedDeliveryMode} selectedDeliveryMode - option for delivery that was selected by user - STH or Express
     *
     * @return {GetErrorFunctionPropType} - returns the error based on the conditions.
     */
    const _getItemAvailabilityError = (
        {
            itemData,
            messageObject,
            itemType,
            itemAvailabilityData,
            initialItemData,
            selectedDeliveryMode,
        }: GetErrorFunctionPropType,
        singleShippingMethodOption?: boolean,
        deliveryMode?: string,
    ): ItemInlineErrorType => {
        const { dcQuantity, storeQuantity } = enableDestructOnUndefinedData(
            itemAvailabilityData,
        ) as StockItemAvailability
        const { quantity: selectedQuantity, sellable, fulfillment, productStatus } = itemData
        const checkInStock = _checkIsStockInAllStore(
            storeQuantity,
            dcQuantity,
            selectedQuantity,
            fulfillment?.orderableFromDC,
        )
        const isShipToHomeError = checkShipToHomeError(selectedDeliveryMode, itemType, fulfillment)
        const inActiveStatus = [
            ProductStatus.DISCONTINUED_WHEN_OUT,
            ProductStatus.FULLY_DISCONTINUED,
            ProductStatus.SEASONALLY_DISCONTINUED,
            ProductStatus.TEMPORARY_DISCONTINUED,
        ]
        if (singleShippingMethodOption) {
            return _defineSingleShippingMethodError(itemData, messageObject, itemType, initialItemData, deliveryMode)
        }
        if (!sellable) {
            return _constructErrorResponse(
                true,
                messageObject.productCannotBePurchasedOnline,
                true,
                [OptionsOnCartItem.WISHLIST],
                '',
                true,
            )
        } else if (isShipToHomeError) {
            return _constructErrorResponse(
                true,
                messageObject.deliverySTHError,
                true,
                [OptionsOnCartItem.WISHLIST],
                FulfillmentErrorType.FULFILLMENT_STH_ERROR,
                true,
            )
        } else if (
            areAllParamsValid(itemType === FulfillmentMethods.BOPIS, !fulfillment?.isEligibleToPickupFromStore)
        ) {
            return _constructErrorResponse(
                true,
                messageObject.deliveryBOPISError,
                true,
                [OptionsOnCartItem.WISHLIST],
                FulfillmentErrorType.FULFILLMENT_BOPIS_ERROR,
                true,
            )
        } else if (checkInStock._discontinuedAndOOS() && inActiveStatus.includes(productStatus)) {
            return __discontinuedOOSConditions(itemData.productStatus, messageObject, itemType)
        } else if (productStatus === ProductStatus.ACTIVE && !storeQuantity && !checkInStock._isStockInDC()) {
            return _outOfStockConditions(messageObject, itemType)
        } else {
            return _checkDeliveryCartErrors(itemData, itemType)
        }
    }

    /**
     * Frames the error for value bags if no value bags option have been chosen on the cart page.
     * @param {boolean} showErrorMsg - toggle to show error message.
     * @param {string} valueBagsOptionNotChosenMsg - error message for value bags.
     *
     * @return {ItemInlineErrorType}
     */
    const _valueBagsOptionNotChosen = (
        showErrorMsg: boolean,
        valueBagsOptionNotChosenMsg: string,
    ): ItemInlineErrorType => {
        return _constructErrorResponse(showErrorMsg, valueBagsOptionNotChosenMsg, false, [], '', true)
    }

    return {
        getError: _getItemAvailabilityError,
        getCartLevelError: _getCartLevelError,
        checkDCChangeHelper: __checkDCChangeHelper,
        constructErrorResponse: _constructErrorResponse,
        checkIsStockInAllStore: _checkIsStockInAllStore,
        discontinuedOOSErrorResponse: _discontinuedOOSErrorResponse,
        outOfStockConditions: _outOfStockConditions,
        valueBagsOptionNotChosen: _valueBagsOptionNotChosen,
    }
})()
