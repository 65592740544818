import {
    thumbnailNavWidthMobile,
    thumbnailNavWidthTablet,
} from '../components/MediaGalleryViewer/MediaGalleryViewer.constant'
import { BREAKPOINTS } from '../components/config'

const isMobile = window.innerWidth <= BREAKPOINTS.tabletMaxWidth
const thumbnailNavWidth = isMobile ? thumbnailNavWidthMobile : thumbnailNavWidthTablet
/**
 * function to calculate and set the thumbnail height only for tablet and mobile view
 * @param {Element} thumbnailDiv
 */
export const setThumbnailHeightMobile = (thumbnailDiv: Element): void => {
    const navInner = thumbnailDiv && thumbnailDiv.querySelector('.snapper_nav_inner')
    navInner && navInner.setAttribute('style', `width:${thumbnailNavWidth}px`) // thumbnail navigation width is sum of width & margin of thumbnailImageCount -1 and last image width & margin
}
