import { Dispatch } from 'redux'

import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import {
    userProfileSuccess,
    userProfileFailure,
    signOutSuccessAction,
    signOutErrorAction,
    resetUserProfileAction,
    profileUpdateSuccessAction,
    profileUpdateErrorAction,
    resetProfileUpdateAction,
    setVehicleIdAction,
    resetBaseVehicleIdAction,
    showNoVehicleAction,
    setSoftRecallCTAClickedAction,
    rehydrateUserProfileAction,
    setSelectedVehicleAction,
    setSelectedVehicleIndexAction,
} from '../actionCreators/user.profile.actionCreators'
import { UserProfileData, SignOutResponseError, SoftRecallCTARequestPayload } from '../models/user.profile.interface'
import GigyaService from '../../services/gigyaService/gigya.service'
import { GigyaJWTResp } from '../../utils/gigya.type'
import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import appCacheService from '../../utils/appCacheService'
import { setIsSsoSessionPending } from '../../redux/actionCreators/gigyaScreenSet.actionCreators'
import { Vehicle, isArrayNotEmpty, checkDataLength, addClass, removeClass, isTriangleSelectMember } from '@nl/lib'
import { AxiosError } from 'axios'
import { authenticationClasses } from '../../globalConstants/global.constant'
import { handleOffersCacheOnSignOut } from '../../utils/offersCache.helper'
import { logNewRelic } from '../../components/NewRelic/newRelic.helper'
import {
    parseGigyaJwtToken,
    getGigyaJwtTokenExpirationStatus,
} from '../../components/PageInit/GigyaTokenRefresh.helper'
import { fiveSecondsToTokenExpiration } from '../../components/PageInit/GigyaTokenRefresh.constant'
import { enableSingleSignOn } from '../../helpers/ciam.helper'

const gigyaService = new GigyaService()
const environment = getEnvironment()
const httpClient = getHttpClient()
const bodyEl = document.body

/**
 * Function to add authentication classes to body html element
 * @param {UserProfileData} profileData
 */
const addAuthenticationClasses = (profileData: { data: UserProfileData }) => {
    const isLoading = checkDataLength(profileData.data) === isArrayNotEmpty(profileData.data)
    if (isLoading) {
        addClass(bodyEl, authenticationClasses.showSkeleton)
    } else if (profileData.data.loyalty?.cardNumber) {
        addClass(bodyEl, authenticationClasses.authenticatedRewards)
    } else {
        addClass(bodyEl, authenticationClasses.authenticated)
    }

    if (isTriangleSelectMember(profileData?.data?.loyalty?.tsSubscriptionStatus)) {
        addClass(bodyEl, authenticationClasses.authenticatedTSSubscription)
    }
}

/**
 * Function for handling login failure
 * @param {Dispatch} dispatch,
 * @param {any} error,
 * @param {string} resource,
 */
const handleLoginFailure = (dispatch: Dispatch, error: Error, resource: string) => {
    appCacheService.gigyaJWTToken.delete()
    dispatch(userProfileFailure())
    logNewRelic({ error, resource })
}

/**
 * Function to initiate SSO log-in sequence calls
 * @param {Dispatch} dispatch
 */
const loginWithSSO = (dispatch: Dispatch): void => {
    gigyaService
        .getAccountInfo()
        .then((isReady: boolean) => {
            return isReady && gigyaService.hasSession()
        })
        .then((sessionExist: boolean) => {
            if (sessionExist) return gigyaService.jwtToken()
        })
        .then((resp: GigyaJWTResp) => {
            appCacheService.gigyaJWTToken.set(resp.id_token)
        })
        .then(() => {
            return gigyaService.cdsAccessToken(appCacheService.gigyaJWTToken.get())
        })
        .then(() => {
            return gigyaService.userProfile(appCacheService.gigyaJWTToken.get())
        })
        .then((profileData: { data: UserProfileData }) => {
            dispatch(userProfileSuccess(profileData.data))
            addAuthenticationClasses(profileData)
        })
        .catch(error => handleLoginFailure(dispatch, error, 'loginWithSSO'))
}

/**
 * Function without checkCookie call for loginWithOutSSO
 * @param {Dispatch} dispatch
 */
const loginWithOutSSO = (dispatch: Dispatch): void => {
    gigyaService
        .jwtToken()
        .then((resp: GigyaJWTResp) => {
            appCacheService.gigyaJWTToken.set(resp.id_token)
            return gigyaService.userProfile(appCacheService.gigyaJWTToken.get())
        })
        .then((profileData: { data: UserProfileData }) => {
            dispatch(userProfileSuccess(profileData.data))
            addAuthenticationClasses(profileData)
        })
        .catch(error => handleLoginFailure(dispatch, error, 'loginWithOutSSO'))
}

export const fetchUserProfile =
    (rehydrateUserProfile?: boolean) =>
    (dispatch: Dispatch): void => {
        const gigyaJWTToken = appCacheService.gigyaJWTToken.get()

        if (!!gigyaJWTToken) {
            const parsedToken = parseGigyaJwtToken(gigyaJWTToken)
            const isTokenAboutToExpireOrExpired = getGigyaJwtTokenExpirationStatus(
                parsedToken.exp as number,
                fiveSecondsToTokenExpiration,
            )

            const promise = isTokenAboutToExpireOrExpired
                ? gigyaService
                      .jwtToken()
                      .then((resp: GigyaJWTResp) => {
                          appCacheService.gigyaJWTToken.set(resp.id_token)
                          return resp.id_token
                      })
                      .catch(err => {
                          console.error(err)
                          return gigyaJWTToken
                      })
                : Promise.resolve(gigyaJWTToken)

            promise
                .then(token => {
                    gigyaService
                        .userProfile(token)
                        .then((profileData: { data: UserProfileData }) => {
                            // save the first name and email for welcome back screen
                            const { firstName, email } = profileData.data
                            refreshSflGuid(email)
                            !!firstName && localStorageService.setItem('firstName', firstName)
                            !!email && localStorageService.setItem('userId', email)
                            dispatch(userProfileSuccess(profileData.data))
                            rehydrateUserProfile && dispatch(rehydrateUserProfileAction(true))
                            addAuthenticationClasses(profileData)
                        })
                        .catch(error => handleLoginFailure(dispatch, error, 'fetchUserProfile'))
                })
                .catch(console.error)
        } else {
            if (window.gigya) {
                addAuthenticationClasses({ data: {} })
                handleOffersCacheOnSignOut()
                if (enableSingleSignOn()) {
                    loginWithSSO(dispatch)
                } else {
                    loginWithOutSSO(dispatch)
                }
            } else dispatch(setIsSsoSessionPending(true))
        }
    }

/**
 * Function to update the profile
 * @param {UserProfileData} requestPayload
 * @param {boolean} shouldUpdateProfileState
 * @return {void}
 */
export const updateProfile =
    (requestPayload: UserProfileData, shouldUpdateProfileState = true) =>
    (dispatch: Dispatch): Promise<void> => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsUserProfile}`

        return httpClient
            .apiPut(url, { ...requestPayload }, headers, true)
            .then(data => {
                const successStatus = 200
                const profileUpdated = data.status === successStatus
                shouldUpdateProfileState &&
                    dispatch(profileUpdateSuccessAction({ profileUpdated, profileData: requestPayload }))
            })
            .catch((err: Record<string, SignOutResponseError>) => {
                dispatch(profileUpdateErrorAction(err.response))
            })
    }

// SignOut currently logged in User
export const signOutUser =
    (location: string) =>
    (dispatch: Dispatch): Promise<void> => {
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.cdsSignOut}`
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        return httpClient
            .apiGet(url, {}, headers, true)
            .then(() => {
                handleOffersCacheOnSignOut()
                dispatch(signOutSuccessAction(location))
                addAuthenticationClasses({ data: {} })
                removeClass(bodyEl, [
                    authenticationClasses.authenticated,
                    authenticationClasses.authenticatedRewards,
                    authenticationClasses.authenticatedTSSubscription,
                ])
            })
            .catch((error: AxiosError<SignOutResponseError>) => {
                const errorResponse = error.response ? error.response.data : error
                dispatch(signOutErrorAction(errorResponse as SignOutResponseError))
            })
    }

export const resetProfileUpdate =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetProfileUpdateAction())
    }

export const resetUserProfileData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetUserProfileAction())
    }

export const showNoVehicleData =
    (vehicleList: Vehicle[]) =>
    (dispatch: Dispatch): void => {
        dispatch(showNoVehicleAction(vehicleList))
    }

export const setVehicleId =
    (vehicle: Record<string, string>) =>
    (dispatch: Dispatch): void => {
        dispatch(setVehicleIdAction(vehicle))
    }

export const resetBaseVehicleId =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetBaseVehicleIdAction())
    }

export const setSoftRecallCTAClicked =
    (requestPayload: SoftRecallCTARequestPayload) =>
    (dispatch: Dispatch): void => {
        dispatch(setSoftRecallCTAClickedAction(requestPayload))
    }

/**
 * Function to remove sfl when diffrent user is logged in Partial auth
 * @param {string} email
 */
export const refreshSflGuid = (email: string) => {
    const previousUserEmail = localStorageService.getItem('userId')
    if (previousUserEmail && previousUserEmail !== email) appCacheService.removeSflGuid()
}

export const setSelectedVehicle =
    (requestPayload: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedVehicleAction(requestPayload))
    }

export const setSelectedVehicleIndex =
    (requestPayload: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedVehicleIndexAction(requestPayload))
    }
