import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { PREFIX } from './../config'
import { ConfirmationScreenPropType } from './ContactUsByEmail.type'

/**
 * ConfirmationScreen component
 * @param {ConfirmationScreenPropType} props
 * @return {JSX.Element}
 */
const ConfirmationScreen: React.FC<ConfirmationScreenPropType> = (props: ConfirmationScreenPropType) => {
    const {
        title,
        description,
        imagePath,
        imageAlt,
        buttonLabel,
        buttonLink,
        success,
        successLinkTarget,
        formDetails,
        errorCallback,
    } = props

    const componentName = `${PREFIX}-confirmation-screen`

    /**
     * This Button will be used in two ways.
     * 1. for success - it will open the given link in new tab.
     * 2. for error - will dispatch an event.
     */
    const confirmationButtonHandler = (): void => {
        if (success) {
            window.open(buttonLink, successLinkTarget, 'noopener')
        } else {
            errorCallback(formDetails)
        }
    }

    return (
        <div className={componentName}>
            <div className={`${componentName}-container`}>
                <img src={imagePath} alt={imageAlt} className={`${componentName}-image`} />
                <div className={`${componentName}-title`}>{title}</div>
                <div className={`${componentName}-description`} dangerouslySetInnerHTML={{ __html: description }}></div>
                <Button type="primary" size="medium" onClick={confirmationButtonHandler}>
                    {buttonLabel}
                </Button>
            </div>
        </div>
    )
}

ConfirmationScreen.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    imagePath: PropTypes.string,
    imageAlt: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonLink: PropTypes.string,
    success: PropTypes.bool,
    successLinkTarget: PropTypes.string,
    formDetails: PropTypes.exact({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        emailSubject: PropTypes.string,
        subject: PropTypes.string,
        subjectOptions: PropTypes.string,
        subjectOptionDetails: PropTypes.string,
        orderNumber: PropTypes.string,
        comment: PropTypes.string,
    }),
    errorCallback: PropTypes.func,
}

export default ConfirmationScreen
