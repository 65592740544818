import { ActionType, getType } from 'typesafe-actions'

import { setToastAuthStatusAction, setToastDetailsAction, setToastMessageDataAction } from '../actionCreators'
import * as actions from '../actionCreators/toastMessage.actionCreators'
import { ToastComponentNames, ToastDetails, ToastMessage } from '../models/toastMessage.interface'

type Action = ActionType<typeof actions>
export interface ToastMessageDataState {
    toastMessageData: ToastMessage
    toastDetails: ToastDetails
    toastAuthStatus: boolean
}

export const initialState: ToastMessageDataState = {
    toastMessageData: {},
    toastDetails: {
        showToast: false,
        toastProps: {},
        componentName: ToastComponentNames.NONE,
    },
    toastAuthStatus: false,
}

export const toastMessageDataReducer = (
    state: ToastMessageDataState = initialState,
    action: Action,
): ToastMessageDataState => {
    switch (action.type) {
        case getType(setToastMessageDataAction):
            return { ...state, toastMessageData: action.payload }
        case getType(setToastDetailsAction):
            return { ...state, toastDetails: action.payload }
        case getType(setToastAuthStatusAction):
            return { ...state, toastAuthStatus: action.payload }
        default:
            return state
    }
}
