import { createAction } from 'typesafe-actions'

import {
    CHECKOUT_BUTTON_CLICKED,
    RESET_CHECKOUT_VARIABLES,
    SET_PC_MODAL_OPEN,
    SET_TNC_MODAL_OPEN,
    CHANGE_BOPIS_STORE,
    SET_TNC_ACCEPTED,
    PAYPAL_BUTTON_CLICKED,
    PAYPAL_BUTTON_CLICKED_VALID,
    CLICK_TO_PAY_BUTTON_CLICKED,
} from '../types/checkout/checkoutButtonClick.actionTypes.constant'

export const checkoutButtonClickAction = createAction(CHECKOUT_BUTTON_CLICKED, (payload: boolean) => payload)<boolean>()

export const setPostalCodeOpenAction = createAction(SET_PC_MODAL_OPEN, (payload: boolean) => payload)<boolean>()

export const resetCheckOutVariablesAction = createAction(RESET_CHECKOUT_VARIABLES)()

export const setTNCOpenAction = createAction(SET_TNC_MODAL_OPEN, (payload: boolean) => payload)<boolean>()

export const changeBopisStoreAction = createAction(CHANGE_BOPIS_STORE, (payload: boolean) => payload)<boolean>()

export const setTncAccepted = createAction(SET_TNC_ACCEPTED, (payload: boolean) => payload)<boolean>()

export const paypalButtonClickAction = createAction(PAYPAL_BUTTON_CLICKED, (payload: boolean) => payload)<boolean>()

export const paypalButtonClickValidationAction = createAction(
    PAYPAL_BUTTON_CLICKED_VALID,
    (payload: boolean) => payload,
)<boolean>()

export const clickToPayButtonClickAction = createAction(
    CLICK_TO_PAY_BUTTON_CLICKED,
    (payload: boolean) => payload,
)<boolean>()
