import React from 'react'
import PropTypes from 'prop-types'

import { ServiceInstallationMessageProps } from '../../components/CartFlyout/CartFlyout.type'
import { PREFIX } from '../config'

/**
 * Service Installation message component
 * @return {JSX.Element} returns Service Installation message component
 */
const ServiceInstallationMessage: React.FC<ServiceInstallationMessageProps> = ({ ...props }): JSX.Element => {
    const { installLabel, serviceAvailabiity, children } = props

    const availabilityClass = serviceAvailabiity
        ? `${PREFIX}-installation-msg--available`
        : `${PREFIX}-installation-msg--not-available`

    return (
        <div
            className={`${PREFIX}-installation-msg ${availabilityClass} ${
                !props.isCartFlyout ? `${PREFIX}-col-sm-6` : ''
            }`}>
            <div className={`${PREFIX}-installation-msg__detail`}>
                <div className={`${PREFIX}-installation-msg__header`}>
                    <h4 className={`${PREFIX}-installation-msg__title`}>{installLabel}</h4>
                </div>
                {children}
            </div>
        </div>
    )
}

ServiceInstallationMessage.propTypes = {
    installLabel: PropTypes.string,
    serviceAvailabiity: PropTypes.bool,
    isCartFlyout: PropTypes.bool,
    children: PropTypes.element,
}

export default ServiceInstallationMessage
