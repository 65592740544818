import { magicNumber, checkDataLength } from '@nl/lib'
/* eslint-disable require-jsdoc */
import { getEnvironment } from '../../environments'
import localStorageService from '../../utils/localStorageService'
import cryptoRandomString from 'crypto-random-string'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import { getHttpClient } from '../../httpClient'
import { AxiosError, AxiosResponse } from 'axios'
import { RbaTMXResponse, TMXSessionId } from './tmx.type'
import { storageData } from '../../globalConstants'

const environment = getEnvironment()

const httpClient = getHttpClient()

/**
 * TmxServiceInit
 *
 */
class TmxService {
    profiling(data: {
        threatMetrixJsUrl: string
        threatMetrixOrgId: string
        threatMetrixPageId: string
        threatMetrixIframeUrl: string
        sessionIdSuffixLength: number
        sessionPrefix: string
        sessionLifeTime: number
        enabled: boolean
    }): HTMLElement[] {
        this.checkTmxSessionId(data)
        const script = document.createElement('script')
        script.setAttribute(
            'src',
            `${data.threatMetrixJsUrl}?org_id=${data.threatMetrixOrgId}&session_id=${this.getSessionId(data)}&pageid=${
                data.threatMetrixPageId
            }`,
        )

        return [script]
    }
    profilingBody(data: {
        threatMetrixJsUrl: string
        threatMetrixOrgId: string
        threatMetrixPageId: string
        threatMetrixIframeUrl: string
        sessionIdSuffixLength: number
        sessionPrefix: string
        sessionLifeTime: number
        enabled: boolean
    }): HTMLElement[] {
        const noscript = document.createElement('noscript')
        const iframe = document.createElement('iframe')
        iframe.setAttribute(
            'src',
            `${data.threatMetrixJsUrl}?org_id=${data.threatMetrixOrgId}&session_id=${this.getSessionId(data)}`,
        )
        noscript.appendChild(iframe)

        return [noscript]
    }
    checkTmxSessionId(
        data:
            | { sessionIdSuffixLength: number; sessionPrefix: string; sessionLifeTime: number; enabled: boolean }
            | undefined,
    ): void {
        const sessionId = this.getSessionId(data)
        if (!sessionId) {
            this.provideTmxSessionId(data)
        }
    }

    clearExpiredTmxSessionId(data: { sessionLifeTime: number; enabled: boolean }): void {
        if (!data.enabled) return
        try {
            const sessionvalue = localStorageService.getItem(environment.TMX_SESSION_VALUE)
            if (sessionvalue) {
                const tmxSessionIdInfo = JSON.parse(sessionvalue) as TMXSessionId
                const lifeTimeinMinutes = data.sessionLifeTime || magicNumber.SIXTY
                const isTmxSessionIdExpired =
                    tmxSessionIdInfo &&
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    tmxSessionIdInfo.timeStamp + lifeTimeinMinutes * magicNumber.SIXTY * magicNumber.THOUSAND <
                        Date.now()

                if (isTmxSessionIdExpired) {
                    localStorageService.removeItem(environment.TMX_SESSION_VALUE)
                }
            }
        } catch (error) {
            console.warn(error)
        }
    }

    getSessionId(
        data:
            | { sessionIdSuffixLength: number; sessionPrefix: string; sessionLifeTime: number; enabled: boolean }
            | undefined,
    ): string {
        this.clearExpiredTmxSessionId(data)
        try {
            const sessionvalue = localStorageService.getItem(environment.TMX_SESSION_VALUE)
            if (sessionvalue) {
                const tmxSessionIdInfo = JSON.parse(sessionvalue) as TMXSessionId
                return tmxSessionIdInfo.sessionId
            }
        } catch (error) {
            console.warn(error)
        }
        return ''
    }
    getSessionIdFromLocalstorage(): string {
        try {
            const sessionvalue = localStorageService.getItem(environment.TMX_SESSION_VALUE)
            if (sessionvalue) {
                const tmxSessionIdInfo = JSON.parse(sessionvalue) as TMXSessionId
                return tmxSessionIdInfo.sessionId
            }
        } catch (error) {
            console.warn(error)
        }
        return ''
    }

    provideTmxSessionId(data: { sessionPrefix: string; sessionIdSuffixLength: number; enabled: boolean }): string {
        if (!data.enabled) return ''
        const tmxSessionId = `${data.sessionPrefix}${cryptoRandomString({ length: data.sessionIdSuffixLength })}`
        const tmxWebSessionId = `${data.sessionPrefix}${cryptoRandomString({ length: data.sessionIdSuffixLength })}`
        const sessionCheck = JSON.parse(localStorageService.getItem(environment.TMX_SESSION_VALUE)) as Record<
            string,
            string
        >

        if (checkDataLength(sessionCheck) && sessionCheck.hasOwnProperty(storageData.webSessionId)) {
            const updatedSession = { ...sessionCheck, sessionId: tmxSessionId, timeStamp: new Date().getTime() }
            localStorageService.setItem(environment.TMX_SESSION_VALUE, JSON.stringify(updatedSession))
        } else {
            localStorageService.setItem(
                environment.TMX_SESSION_VALUE,
                JSON.stringify({
                    webSessionId: tmxWebSessionId,
                    sessionId: tmxSessionId,
                    timeStamp: new Date().getTime(),
                }),
            )
        }
        return tmxSessionId
    }

    tmxAuthorization(data: Record<string, string | boolean>): Promise<AxiosResponse<any>> {
        const baseEndPoint = environment.API_ENDPOINTS.rbaTmxAuth
        let sessionId = null
        try {
            const sessionvalue = localStorageService.getItem(environment.TMX_SESSION_VALUE)
            if (sessionvalue) {
                sessionId = (JSON.parse(sessionvalue) as TMXSessionId).sessionId
            }
        } catch (error) {
            console.warn(error)
        }
        const headers: HttpReqHeaders = {
            'X-TMX-Session-ID': sessionId,
        }

        const url = new URL(`${environment.API_BASE_URL}${baseEndPoint}`)

        return new Promise((resolve, reject) => {
            httpClient
                .apiPost<RbaTMXResponse>(url, data, headers, true)
                .then(tmxResponse => {
                    if ((tmxResponse.data || {}).errorCode) {
                        reject(tmxResponse.data)
                    }
                    window.gigya.socialize.refreshUI()
                    resolve(tmxResponse)
                })
                .catch((error: AxiosError) => {
                    reject(error.response!.data)
                })
        })
    }
}
export { TmxService }
export default TmxService
