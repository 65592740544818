import { CacheValue, UnpackReturnedPromise } from './memoizeAsync.type'

/**
 * Memoizes an asynchronous function by caching its results based on the function arguments.
 * The cached results are returned when the same arguments are provided again, avoiding redundant calculations.
 * @template T - The type of the asynchronous function being memoized.
 * @param {number} cachingDuration - The duration in milliseconds for which the results should be cached.
 * @param {T} asyncFunction - The asynchronous function to be memoized. It should take some arguments and return a Promise.
 * @return {Promise<UnpackReturnedPromise<T>>} - The memoized asynchronous function.
 */
const memoizeAsync = <T extends (...args: any[]) => Promise<any>>(cachingDuration: number, asyncFunction: T) => {
    const cache = new Map<string, CacheValue<Promise<UnpackReturnedPromise<T>>>>()
    return (...args: unknown[]): Promise<UnpackReturnedPromise<T>> => {
        const keys = JSON.stringify(args)
        if (cache.has(keys) && cache.get(keys)!.expirationTimestamp - Date.now() > 0) {
            return Promise.resolve(cache.get(keys)!.cachedResult)
        }
        const result = asyncFunction(...args) as Promise<UnpackReturnedPromise<T>>
        cache.set(keys, { expirationTimestamp: Date.now() + cachingDuration, cachedResult: result })
        return Promise.resolve(result)
    }
}

export default memoizeAsync
