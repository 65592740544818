import { RootState } from '../reducers'
import { OrderDetailsState } from '../reducers/orderDetails.reducer'
import { CartResponseErrorDTO, FilteredCartData } from '../models/cart.interface'

export const orderDetailsSelector = (state: RootState): OrderDetailsState => state.orderDetails

export const orderExpiredSelector = (state: RootState): boolean => state.orderDetails.orderExpired

export const orderDetailsDataSelector = (state: RootState): FilteredCartData => state.orderDetails.orderDetailsData

export const orderDetailsErrorSelector = (state: RootState): CartResponseErrorDTO =>
    state.orderDetails.getOrderDetailsError
