import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { ServiceInstallationProps } from './ServiceInstallation.type'
import { PREFIX } from '../config'
import Icon from '../Icon'
import Button from '../Button'
import Tooltip from '../Tooltip'
import { getFormattedPhoneNumber } from '../../utils'

/**
 * Service Installation component
 * @return {JSX.Element} returns Service Installation component
 */
const ServiceInstallation: React.FC<ServiceInstallationProps> = ({ ...props }): JSX.Element => {
    const {
        availabilityLabel,
        installLabel,
        installIcon,
        toolTipIcon,
        toolTipTitle,
        toolTipDesc,
        path,
        a11yCloseIconLabel,
        phone,
        checkNearbyStoreCTAHandler,
    } = props

    const [showViewMore, setShowViewMore] = useState(true)

    const [visibility, setVisibility] = useState(false)
    const buttonRef = useRef()
    const phoneNumber = getFormattedPhoneNumber(phone)

    /**
     * function to render view content
     * @return {JSX.Element}
     */
    const renderViewMoreContents = (): JSX.Element | null => {
        return (
            <div className={`${PREFIX}-installation__shopping-store`}>
                <div dangerouslySetInnerHTML={{ __html: props.autoshoppingAtText }} />
                <a className={`${PREFIX}-md-none`} href={`tel:${phone}`} data-link-value={phone}>
                    {phoneNumber}
                </a>
                <div className={`${PREFIX}-xs-none ${PREFIX}-md-block`}>{phoneNumber}</div>
            </div>
        )
    }

    /**
     * function to render showView More and less
     * @return {JSX.Element}
     */
    const showViewMoreLess = (): JSX.Element | null => {
        return (
            <>
                {!showViewMore && renderViewMoreContents()}
                <div className={`${PREFIX}-installation__check-nearby`}>
                    <Button type="tertiary" size="mini" onClick={() => setShowViewMore(!showViewMore)}>
                        {showViewMore ? props.viewMoreLabel : props.viewLessLabel}{' '}
                    </Button>
                </div>
            </>
        )
    }

    /**
     * function to render get check nearbystore
     * @return {JSX.Element}
     */
    const renderCheckNearByCTA = (): JSX.Element | null => {
        return (
            <div className={`${PREFIX}-installation__check-nearby`}>
                <Button id="store-cta-btn" type="tertiary" onClick={checkNearbyStoreCTAHandler}>
                    {props.checkNearByCTALabel}
                </Button>
            </div>
        )
    }

    return (
        <div className={`${PREFIX}-installation`}>
            <div className={`${PREFIX}-installation__container`}>
                <div className={`${PREFIX}-installation__icon`}>
                    <Icon type={installIcon} size="lg" path={path} />
                </div>

                <div className={`${PREFIX}-installation__detail`}>
                    <div className={`${PREFIX}-installation__header`}>
                        <h4 className={`${PREFIX}-installation-msg__title`}>{installLabel}</h4>
                        <button
                            ref={buttonRef}
                            className={`${PREFIX}-installation__tooltip-btn`}
                            onClick={() => {
                                setVisibility(!visibility)
                            }}
                            aria-label="information">
                            <span className={visibility ? `${PREFIX}-installation__tooltip-btn--active` : ''}>
                                <Icon type={toolTipIcon} size="sm" path={path} />
                            </span>
                        </button>
                        {!!visibility && (
                            <Tooltip
                                visibility={visibility}
                                setVisibility={setVisibility}
                                iconID="ct-close"
                                headerText={toolTipTitle}
                                bodyText={toolTipDesc}
                                path={path}
                                coords={buttonRef.current}
                                a11yCloseIconLabel={a11yCloseIconLabel}
                            />
                        )}
                    </div>
                    {availabilityLabel}
                </div>
            </div>
            {props.isAutoPartOrTire
                ? phone
                    ? !props.isAOAUpdationRequire && showViewMoreLess()
                    : renderCheckNearByCTA()
                : null}
        </div>
    )
}

ServiceInstallation.propTypes = {
    availabilityLabel: PropTypes.element,
    installLabel: PropTypes.string,
    installIcon: PropTypes.string,
    toolTipIcon: PropTypes.string,
    toolTipTitle: PropTypes.string,
    toolTipDesc: PropTypes.string,
    closeIcon: PropTypes.string,
    path: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    phone: PropTypes.string,
    autoshoppingAtText: PropTypes.string,
    checkNearByCTALabel: PropTypes.string,
    viewMoreLabel: PropTypes.string,
    viewLessLabel: PropTypes.string,
    checkNearbyStoreCTAHandler: PropTypes.func,
    isAutoPartOrTire: PropTypes.bool,
    isAOAUpdationRequire: PropTypes.bool,
}
export default ServiceInstallation
