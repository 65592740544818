import { magicNumber } from './magicNumber'

/**
 * Returns false , if the object passed is empty , otherwise returns true
 * @param {unknown} obj - max limit of the text.
 * @return {boolean}
 */
export const checkDataLength = (obj: any): boolean => {
    return obj && Object.values(obj).length > 0
}

/**
 * function to check if atleast one property of the object has non null value and not empty string in case of string value for the key
 * @param {unknown}obj
 * @return {boolean}
 */
export const checkValidData = (obj: any): boolean => {
    return obj && Object.values(obj).filter(item => !!item).length > 0
}

/**
 * function to check passed index is last item of given array
 * @param {Array<any>}array
 * @param {number}index
 * @return {boolean}
 */
export const checkLastItemArray = (array: Array<any>, index: number): boolean => {
    return array && index !== array.length - magicNumber.ONE
}
