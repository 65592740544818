import { FilteredCartData, CartOrderEntries } from '../../redux/models/cart.interface'

import { AutoPackageModalData } from './ProductCard.types'
import { productWheelTypes } from '../../globalConstants/global.constant'
import { isTirePDP, isWheelPDP } from '../Vehicles/Vehicle.helper'
import { removeBlankSpaceFromString } from '../../utils/removeBlankSpaceFromString'
import { MagicNumber } from '../../analytics/analytics.type'
import { isArrayNotEmpty, Vehicle } from '@nl/lib'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
/**
 * function to check if current product wheel type is same plp page tire type
 * @param {string | undefined} currentWheelType
 * @param {string} productWheelType
 *
 * @return {boolean}
 */
const checkCurrentWheelType = (currentWheelType: string | undefined, productWheelType: string): boolean => {
    if (productWheelType === productWheelTypes.Wheel) {
        return currentWheelType === productWheelType
    } else {
        return currentWheelType === productWheelType || currentWheelType === productWheelTypes.WinterTire
    }
}

/**
 * function to add tires for wheel plp and vice versa
 * @param {AutoPackageModalData[]} autopackageUpdatedList
 * @param {string} productWheelTypeValue
 *
 * @return {AutoPackageModalData[]}
 */
const checkWheelType = (
    autopackageUpdatedList: AutoPackageModalData[],
    productWheelTypeValue: string,
): AutoPackageModalData[] => {
    return autopackageUpdatedList?.filter((categoryType: AutoPackageModalData) =>
        checkCurrentWheelType(categoryType.productWheelType?.toUpperCase(), productWheelTypeValue),
    )
}
/**
 * function to return productWheelTypes string
 * @param {string | undefined} productWheelType
 * @return {string | undefined} returns TIRE or WHEEL
 */
const wheelTypeValue = (productWheelType: string | undefined): string | undefined => {
    if (isTirePDP(productWheelType)) return productWheelTypes.Tire
    if (isWheelPDP(productWheelType)) return productWheelTypes.Wheel
}

/**
 * function to add product in an array which are tire for wheel plp and vice versa
 * @param {AutoPackageModalData[]} autopackageUpdatedList
 * @param {string | undefined} productWheelType
 *
 * @return {AutoPackageModalData[]}
 */
const wheelOrTireModalData = (
    autopackageUpdatedList: AutoPackageModalData[],
    productWheelType: string | undefined,
): AutoPackageModalData[] => {
    let wheelOrTireList: AutoPackageModalData[] = []
    if (wheelTypeValue(productWheelType)) {
        wheelOrTireList = checkCurrentWheelType(wheelTypeValue(productWheelType), productWheelTypes.Wheel)
            ? checkWheelType(autopackageUpdatedList, productWheelTypes.Tire)
            : checkWheelType(autopackageUpdatedList, productWheelTypes.Wheel)
    }
    return wheelOrTireList
}

/**
 * function to add isSelected flag. Flag will te true foe selected product and by default first product will be selected
 * @param {AutoPackageModalData[]} autoPackageData
 * @param {number} currentAutoPackIndex
 * @param {string | undefined} productWheelType
 *
 * @return {AutoPackageModalData[]}
 */
const updatedWheelOrTireList = (
    autoPackageData: AutoPackageModalData[],
    currentAutoPackIndex: number,
    productWheelType: string | undefined,
): AutoPackageModalData[] => {
    // TODO: func wheelOrTireModalData is redundant as it was used to create autoPackageData
    return wheelOrTireModalData(autoPackageData, productWheelType)?.map(
        (wheelOrTireData: AutoPackageModalData, index: number) => ({
            ...wheelOrTireData,
            isSelected: index === currentAutoPackIndex,
        }),
    )
}

/**
 * function to add product which are part of both complete and incomplete auto-package.
 * @param {AutoPackageModalData[]} sameVehicleProduct
 *
 * @return {AutoPackageModalData[]}
 */
export const getAutoPackageList = (sameVehicleProduct: AutoPackageModalData[]): AutoPackageModalData[] => {
    return sameVehicleProduct?.filter((autopackage: AutoPackageModalData) => !!autopackage.packageId)
}

/**
 * function returns auto-package modal data with same vehicle name as selected vehicle
 * @param {CartOrderEntries[]} orderEntries
 * @param {string} vehicleInfo
 *
 * @return {AutoPackageModalData[]}
 */
export const getSameVehicleProduct = (
    orderEntries: CartOrderEntries[] = [],
    vehicleInfo: string,
): AutoPackageModalData[] => {
    const sameVehicleProduct: AutoPackageModalData[] = []
    orderEntries?.forEach((currentCartData: CartOrderEntries) => {
        if (currentCartData.vehicleInformation) {
            const vehicleNameList = [
                currentCartData?.vehicleInformation?.year,
                currentCartData.vehicleInformation.make,
                currentCartData.vehicleInformation.model,
                currentCartData.vehicleInformation.body,
                currentCartData.vehicleInformation.options,
            ]
            const currentVehicleName = vehicleNameList.join(' ')

            if (
                removeBlankSpaceFromString(vehicleInfo).toLowerCase() ===
                removeBlankSpaceFromString(currentVehicleName).toLowerCase()
            ) {
                sameVehicleProduct.push(currentCartData as AutoPackageModalData)
            }
        }
    })
    return sameVehicleProduct
}

/**
 * function returns auto-package modal data
 * @param {FilteredCartData} cartItemsData
 * @param {string} vehicleInfo
 * @param {number} currentAutoPackIndex
 * @param {string | undefined} productWheelType
 *
 * @return {AutoPackageModalData[]}
 */
export const openAutoPackageModal = (
    cartItemsData: FilteredCartData,
    vehicleInfo: string,
    currentAutoPackIndex: number,
    productWheelType: string | undefined,
): AutoPackageModalData[] => {
    const sameVehicleProduct = getSameVehicleProduct(cartItemsData.cart?.orderEntries, vehicleInfo)
    const autopackageUpdatedList = sameVehicleProduct?.filter(
        // TODO: looks like getAutoPackageList function is redundant and this part can be simplified
        (item: AutoPackageModalData) => !getAutoPackageList(sameVehicleProduct).includes(item),
    )
    return updatedWheelOrTireList(
        wheelOrTireModalData(autopackageUpdatedList, productWheelType),
        currentAutoPackIndex,
        productWheelType,
    )
}

/**
 * function returns incomplete auto-package list from same vehicle cart array.
 * @param {AutoPackageModalData[]} sameVehicleProduct
 *
 * @return {AutoPackageModalData[]}
 */
export const incompletePackageList = (sameVehicleProduct: AutoPackageModalData[]): AutoPackageModalData[] => {
    const autoPackageIncompleteList: AutoPackageModalData[] = []
    const packageIdOccurance: AutoPackageModalData = {}

    sameVehicleProduct?.forEach((vehicleProduct: AutoPackageModalData): void => {
        // packageIdString constant is created to maintain the order of package id in object
        const packageIdString = `a-${String(vehicleProduct.packageId)}`
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        packageIdOccurance[packageIdString] = (packageIdOccurance[packageIdString] + MagicNumber.ONE ||
            MagicNumber.ONE) as AutoPackageModalData
    })

    for (const packageIdValue in packageIdOccurance) {
        if (packageIdOccurance[packageIdValue] === MagicNumber.ONE) {
            autoPackageIncompleteList.push(
                sameVehicleProduct.find(
                    (product: AutoPackageModalData) =>
                        product.packageId === packageIdValue.split('a-')[MagicNumber.ONE],
                ),
            )
        }
    }
    return autoPackageIncompleteList
}

/**
 * function returns incomplete auto-package list from same vehicle cart array.
 * @param {boolean} initialLoad
 * @param {number} pageNoFromURL
 * @param {number} initialProductCount
 * @param {number} showMoreClickCount
 * @return {number}
 */
export const productFetchCount = (
    initialLoad: boolean,
    pageNoFromURL: number,
    initialProductCount: number,
    showMoreClickCount: number,
): number => {
    return initialLoad && pageNoFromURL > 0
        ? Number(initialProductCount) + showMoreClickCount * (pageNoFromURL - MagicNumber.ONE)
        : Number(initialProductCount) + Number(showMoreClickCount)
}

/**
 * fuction to check if additional vehicle info present
 * @param {Vehicle[]} list
 * @param { boolean }isSuggestiveDropdownState
 * @return {bollean}
 */
export const checkIfCompleteVehicleState = (list: Vehicle[], isSuggestiveDropdownState: boolean): boolean => {
    return isArrayNotEmpty(list) && !Array.isArray(list[0]) && !isSuggestiveDropdownState
}

/**
 * Function returns true if page will be PLP or SRP
 * because need to set min height for price components element.
 * @return {boolean}
 */

export const overridePriceHeight = (): boolean => {
    const isSRPPage = getPageType() === pageTypes.searchPage
    const isPLPPage = getPageType() === pageTypes.plp
    return isSRPPage || isPLPPage
}
