export const scrollToTarget = (event: React.MouseEvent<Element, MouseEvent>): void => {
    event.stopPropagation()
    event.preventDefault()
    window.scroll({
        top: 0,
        behavior: 'smooth',
    })
}

export const scrollToTop = scrollToTarget
