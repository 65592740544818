export const GET_ADDRESS_SUGGESTIONS_SUCCESS = 'GET_ADDRESS_SUGGESTIONS_SUCCESS'
export const GET_ADDRESS_SUGGESTIONS_ERROR = 'GET_ADDRESS_SUGGESTIONS_ERROR'
export const GET_SHIPPING_ADDRESS_SUCCESS = 'GET_SHIPPING_ADDRESS_SUCCESS'
export const GET_SHIPPING_ADDRESS_FAILURE = 'GET_SHIPPING_ADDRESS_FAILURE'
export const GET_ERROR_SUGGESTIONS = 'GET_ERROR_SUGGESTIONS'
export const ERROR_SUGGESTIONS_CLEANUP = 'ERROR_SUGGESTIONS_CLEANUP'
export const SET_HPP_TOKEN = 'SET_HPP_TOKEN'
export const SET_CHECKOUT_CONTACT = 'SET_CHECKOUT_CONTACT'
export const SET_CHECKOUT_SHIPPING = 'SET_CHECKOUT_SHIPPING'
export const SET_CHECKOUT_PICKUP = 'SET_CHECKOUT_PICKUP'
export const SET_CHECKOUT_PAYMENT = 'SET_CHECKOUT_PAYMENT'
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS'
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE'
export const CLEAR_PLACE_ORDER_FAILURE = 'CLEAR_PLACE_ORDER_FAILURE'
export const SEMAFONE_ENABLED = 'SEMAFONE_ENABLED'
export const REDEEM_CT_MONEY_RESPONSE = 'REDEEM_CT_MONEY_RESPONSE'
export const SAVED_PAYMENT_TO_CART = 'SAVED_PAYMENT_TO_CART'
export const SET_PAYMENTFAILURE_ERROR = ' SET_PAYMENTFAILURE_ERROR'
export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS'
export const SET_CHECKOUT_CONTACT_STATUS = 'SET_CHECKOUT_CONTACT_STATUS'
export const RESET_CHECKOUT_VALIDATIONS = 'RESET_CHECKOUT_VALIDATIONS'
export const SET_CHECKOUT_SHIPPING_STATUS = 'SET_CHECKOUT_SHIPPING_STATUS'
export const GET_PAYMENT_DETAILS_FAILURE = 'GET_PAYMENT_DETAILS_FAILURE'
export const EDIT_PAYMENT_CLICKED = 'EDIT_PAYMENT_CLICKED'
export const IS_GIFT_CARD_JUST_DELETED_ACTION = 'IS_GIFT_CARD_JUST_DELETED_ACTION'
export const SET_PAYMENT_SUCCESS = 'SET_PAYMENT_SUCCESS'
export const SET_INIT_PAYMENT_STATUS_ACTION = 'SET_INIT_PAYMENT_STATUS_ACTION'

export enum tostErrorTypeConst {
    retrieve = 'retrieve',
    find = 'Find',
}
