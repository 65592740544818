import { PREFIX } from '../config'

export const minimumBreadcrumbLength = 1

export const breadcrumbClassName = `${PREFIX}-breadcrumbs`
export const listClassName = `${breadcrumbClassName}__list`
export const listItemClassName = `${breadcrumbClassName}__list-item`
export const pipeItemClassName = `${breadcrumbClassName}__list-item--pipe`

export const listPipeItemClassName = `${listItemClassName} ${pipeItemClassName}`
export const textClassName = `${breadcrumbClassName}__text`
export const linkClassName = `${breadcrumbClassName}__link`
