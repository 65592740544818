import { magicNumber } from '../../utils'
import { JumpListItem } from './JumpList.type'

/** filter helper that returns the search result in the order in which the word of the label matches searchTerm
 * @param {JumpListItem[]} list
 * @param {string} searchTerm
 * @return {JumpListItem[]}
 */
const filterByRank = (list: JumpListItem[], searchTerm: string): JumpListItem[] => {
    const includesKey = 'includes'

    const searchResultByRankMap = new Map<number | typeof includesKey, JumpListItem[]>()
    const ranks = new Set<number>()
    list.forEach(item => {
        const { label } = item
        const words = label.toLowerCase().split(' ')

        for (let wordNumber = 0; wordNumber < words.length; wordNumber++) {
            const word = words[wordNumber]
            if (word.startsWith(searchTerm.toLowerCase())) {
                ranks.add(wordNumber)
                const listByNRank = searchResultByRankMap.get(wordNumber) ?? []
                searchResultByRankMap.set(wordNumber, [...listByNRank, item])
                break
            }
            const isLastWord = wordNumber === words.length - magicNumber.ONE
            if (isLastWord && label.toLowerCase().includes(searchTerm.toLowerCase())) {
                const includesRankList = searchResultByRankMap.get(includesKey) ?? []
                searchResultByRankMap.set(includesKey, [...includesRankList, item])
                break
            }
        }
    })

    const searchResultByRank: JumpListItem[] = Array.from(ranks)
        .sort()
        .reduce((prev, rank) => {
            const listByNRank = searchResultByRankMap.get(rank) ?? []

            return [...prev, ...listByNRank]
        }, [] as JumpListItem[])

    const includesRankList = searchResultByRankMap.get(includesKey) ?? []

    return [...searchResultByRank, ...includesRankList]
}
export { filterByRank }
