import { createAction } from 'typesafe-actions'
import { ErrorResponse } from '../models/mergeLoyaltyCard.interface'
import { FilteredOffers } from '../reducers/offers.reducer'
import {
    OfferActivation,
    CustomerOffers,
    TargetedOfferActivation,
    LoyaltyOffersProps,
    Offers,
} from '../models/offers.interface'
import {
    FETCH_CUSTOMER_OFFERS_IN_PROGRESS,
    FETCH_CUSTOMER_OFFERS_SUCCESS,
    FETCH_CUSTOMER_OFFERS_FAILURE,
    ACTIVATE_CUSTOMER_OFFERS_SUCCESS,
    ACTIVATE_CUSTOMER_OFFERS_FAILURE,
    CLEAR_ACTIVATION_SUCCESS,
    CLEAR_ACTIVATION_ERROR,
    ACTIVATE_TARGETED_OFFER_SUCCESS,
    FETCH_FILTERED_OFFERS_FAILURE,
    FETCH_FILTERED_OFFERS_SUCCESS,
    UPDATE_FILTERED_OFFERS_SORTING,
    UPDATE_FILTERED_OFFERS,
    SET_LOYALTY_OFFERS_FETCH_SUCCESS,
} from '../types/profile/offers.actionTypes.constant'

export const offersSuccessAction = createAction(
    FETCH_CUSTOMER_OFFERS_SUCCESS,
    (payload: CustomerOffers) => payload,
)<CustomerOffers>()

export const offersFailureAction = createAction(FETCH_CUSTOMER_OFFERS_FAILURE)()
export const offersFetchInProgressAction = createAction(
    FETCH_CUSTOMER_OFFERS_IN_PROGRESS,
    (payload: boolean) => payload,
)<boolean>()

export const offersActivateSuccessAction = createAction(
    ACTIVATE_CUSTOMER_OFFERS_SUCCESS,
    (payload: OfferActivation) => payload,
)<OfferActivation>()

export const offersActivateFailureAction = createAction(ACTIVATE_CUSTOMER_OFFERS_FAILURE)<ErrorResponse>()
export const clearOffersActivateAction = createAction(CLEAR_ACTIVATION_SUCCESS)()
export const clearOfferActivationErrorAction = createAction(CLEAR_ACTIVATION_ERROR)()
export const targetedOfferActivateSuccessAction = createAction(
    ACTIVATE_TARGETED_OFFER_SUCCESS,
    (payload: TargetedOfferActivation[]) => payload,
)<TargetedOfferActivation[]>()
export const filteredOffersSuccessAction = createAction(
    FETCH_FILTERED_OFFERS_SUCCESS,
    (payload: FilteredOffers) => payload,
)<FilteredOffers>()
export const filteredOffersFailureAction = createAction(FETCH_FILTERED_OFFERS_FAILURE)()
export const updateFilteredOffersSortingAction = createAction(UPDATE_FILTERED_OFFERS_SORTING)<string>()
export const updateFilteredOffersAction = createAction(UPDATE_FILTERED_OFFERS, (payload: Offers[]) => payload)<
    Offers[]
>()

export const setLoyaltyOffersSuccessAction = createAction(SET_LOYALTY_OFFERS_FETCH_SUCCESS)<LoyaltyOffersProps>()
