import { createAction } from 'typesafe-actions'

import {
    HIDE_VEHICLE_SELECTOR_BANNER,
    IS_PARTIAL_VEHICLE_ENTERED,
    IS_PARTIAL_VEHICLE_API_FETCHED,
    SUGGESTIVE_ATTRIBUTE,
} from '../types/products/hideVehicleSelectorBanner.actionTypes.constant'

export const hideVehicleSelectorBannerAction = createAction(
    HIDE_VEHICLE_SELECTOR_BANNER,
    (payload: boolean) => payload,
)<boolean>()

export const isSuggestiveDropdownAction = createAction(
    IS_PARTIAL_VEHICLE_ENTERED,
    (payload: boolean) => payload,
)<boolean>()

export const isSuggestiveDropdownAPIFetchedAction = createAction(
    IS_PARTIAL_VEHICLE_API_FETCHED,
    (payload: boolean) => payload,
)<boolean>()

export const setSuggestedAttributeAction = createAction(SUGGESTIVE_ATTRIBUTE, (payload: string) => payload)<string>()
