import { createAction } from 'typesafe-actions'
import {
    SET_STICKY_NAVIGATION_POPUP_TOGGLE_STATE,
    SET_STORE_LOCATOR_POPUP_TOGGLE_STATE,
} from '../types/popupsToggleState/popupsToggleState.actionTypes.constant'

export const setSideNavigationPopupToggleState = createAction(
    SET_STICKY_NAVIGATION_POPUP_TOGGLE_STATE,
    (payload: boolean) => payload,
)<boolean>()

export const setStoreLocatorPopupToggleState = createAction(
    SET_STORE_LOCATOR_POPUP_TOGGLE_STATE,
    (payload: boolean) => payload,
)<boolean>()
