import {
    setGigyaScreenSetData,
    setIsGigyaLoaded,
    setIsSsoSessionPending,
} from './../actionCreators/gigyaScreenSet.actionCreators'
import { ScreenSetResponse } from './../../components/GigyaScreen/gigyaScreen.type'
import { ActionType, getType } from 'typesafe-actions'

type Action = ActionType<typeof setGigyaScreenSetData>

export const initialState: ScreenSetResponse = {
    screenSets: [],
    isGigyaLoaded: false,
    isSsoSessionPending: false,
}

export const setGigyaScreenDataReducer = (
    state: ScreenSetResponse = initialState,
    action: Action,
): ScreenSetResponse => {
    if (action.type === getType(setGigyaScreenSetData)) {
        return { ...state, screenSets: action.payload.screenSets }
    }
    if (action.type === getType(setIsGigyaLoaded)) {
        return { ...state, isGigyaLoaded: true }
    }
    if (action.type === getType(setIsSsoSessionPending)) {
        return { ...state, isSsoSessionPending: true }
    } else {
        return state
    }
}
