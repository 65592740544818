import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { RadioProps } from './Radio.types'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

/**
 * Radio button component
 * @param {RadioProps} props
 * @return {JSX.Element} returns Radio button input element
 */
const Radio: React.FC<RadioProps> = ({ ...props }): JSX.Element => {
    const { quantumMetricAttribute, error } = props
    const componentClassName = `${PREFIX}-radio`
    const id = props.id ? `${componentClassName}__${props.id}` : ''
    const name = props.name ? `${componentClassName}__${props.name}` : ''
    const [visibility, setVisibility] = useState(false)
    const buttonRef = useRef(null)

    const returnErrorClass = () => {
        return error ? `${componentClassName}__check--error` : ''
    }
    const erroClass = returnErrorClass()

    const labelClasses = (() => {
        return `${componentClassName}__label-container ${props.disabled ? 'disabled' : ''} ${
            props.isBaselineAlignment ? `${componentClassName}__label-baseline` : ''
        }`
    })()

    const getQmDataAttribute = () =>
        quantumMetricAttribute?.type
            ? { [`data-qm-${quantumMetricAttribute?.type}`]: quantumMetricAttribute?.value }
            : {}
    const quantumMetricDataAttribute = getQmDataAttribute()
    return (
        <div className={`${componentClassName}`}>
            <input
                id={`${id}`}
                data-testid={id}
                type="radio"
                name={`${name}`}
                className={`${componentClassName}__input`}
                onChange={props.onChange}
                disabled={props.disabled}
                checked={props.checked}
                value={props.value}
                aria-label={props.ariaLabel}
                defaultChecked={props.defaultChecked}
            />
            <label htmlFor={`${id}`} className={labelClasses}>
                <span className={`${componentClassName}__check ${erroClass} ${props.disabled ? 'disabled' : ''}`}>
                    {(props.checked || props.defaultChecked) && <Icon size="xs" type="ct-radio-selected" />}
                </span>
                <span
                    {...quantumMetricDataAttribute}
                    className={`${componentClassName}__text ${props.disabled ? 'disabled' : ''}`}>
                    {props.label}
                    {props.hasTooltip && (
                        <button
                            type="button"
                            data-qm-allow="true"
                            data-testid={`info-icon`}
                            className={`${componentClassName}__tooltip-icon`}
                            ref={buttonRef}
                            onClick={() => setVisibility(!visibility)}
                            aria-label={props.information}>
                            <Icon size="md" type="ct-information-details"></Icon>
                        </button>
                    )}
                    {props.caption}
                </span>
            </label>
            {!!visibility && (
                <Tooltip
                    visibility={visibility}
                    setVisibility={setVisibility}
                    iconID="ct-close"
                    headerText={props.option.bulkTooltipTitle}
                    bodyText={props.option.bulkTooltipDesc}
                    a11yCloseIconLabel={props.a11yCloseIconLabel}
                    coords={buttonRef.current as unknown as HTMLElement}
                    tooltipModifiedStyles={props.tooltipModifiedStyles}
                />
            )}
        </div>
    )
}

Radio.defaultProps = {
    label: 'Radio Label',
    id: 'radio_label',
}

Radio.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    hasTooltip: PropTypes.bool,
    information: PropTypes.string,
    option: PropTypes.shape({
        bulkTooltipTitle: PropTypes.string,
        bulkTooltipDesc: PropTypes.string,
    }),
    a11yCloseIconLabel: PropTypes.string,
    ariaLabel: PropTypes.string,
    isBaselineAlignment: PropTypes.bool,
}

export default Radio
