import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BannerImagePath, EmailActivationType } from './EmailActivationCard.type'
import OfferActivationMessage from './ActivationMessage'
import OfferBanner from '../OfferBanner'
import { magicNumber } from '../../utils/magicNumber'
import { getButtonStatus } from '../../utils/ButtonStatus/getButtonStatus'
import { PREFIX } from '../config'
import { Offers } from '../OfferCard/OfferCard.type'
import OfferCard from '../OfferCard'
import OfferFlyout from '../OfferFlyout'
import { checkDataLength } from '../../utils/checkDataLength'
import { offerConstants } from '../OfferCard/OfferCard.constant'
import { applyAkamaiPolicy } from '../../utils/getAkamaiPolicy'

const EmailActivationCard: React.FC<EmailActivationType> = (props: EmailActivationType): JSX.Element => {
    const {
        ClassName,
        selectedCard,
        isCardVisible,
        bannerImageList,
        failedActivationImage,
        failedActivationImageAltText,
        title,
        multiOffer,
        selectedOfferArray,
        activatedLabel,
        offerDetailsLabel,
        multipleOfferTilesSuccessMessage,
        offerEndsMessage,
        offerEndsTodayMessage,
        offerEndsDateMessage,
        akamaiPolicy,
        activateLabel,
        redeemedLabel,
        moreDetailsLabel,
    } = props
    const {
        returnPolicy,
        fallbackOfferImageUrl,
        offersCardImgComponentName,
        fallbackImageForBrandLogo,
        fallbackImageForBrandLogoAltText,
        bannerLogoImgComponentName,
    } = akamaiPolicy || {}
    /**
     * If isCardVisible and selectedCard is available
     * @return {JSX.Element[]}
     */
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedOffer, setSelectedOffer] = useState<Offers>({} as Offers)

    const openOfferModal = (offerInModal: Offers): void => {
        setIsModalOpen(true)
        setSelectedOffer(offerInModal)
    }

    const columns = {
        md: magicNumber.TWELVE / magicNumber.FOUR,
        sm: magicNumber.TWELVE / magicNumber.THREE,
        xs: magicNumber.THREE,
    }

    const labels = {
        activatedLabel,
        activateLabel,
        redeemedLabel,
    }
    const offerDetailsCardElement = (): JSX.Element => {
        if (isCardVisible) {
            const { imageUrl, badgeImageUrl, displayBanner } = selectedCard

            const setBannerLogo = (bannerName: string): BannerImagePath | undefined => {
                const getBannerName = bannerName?.toString()
                return (
                    bannerImageList &&
                    bannerImageList.find((imageList: BannerImagePath) => getBannerName === imageList.bannerId)
                )
            }
            const { bannerImagePath, bannerImageAlt } = setBannerLogo(displayBanner) || {}
            const mutipleBanner = displayBanner?.includes(',')

            const brandImage = mutipleBanner ? fallbackImageForBrandLogo : bannerImagePath
            const brandImageAlt = mutipleBanner ? fallbackImageForBrandLogoAltText : bannerImageAlt
            return (
                <div className={`${ClassName}_offer-card`}>
                    <OfferBanner
                        bannerImagePath={brandImage}
                        bannerImageAlt={brandImageAlt}
                        badgeImageAlt="Banner Image"
                        badgeImageUrl={badgeImageUrl}
                        returnPolicy={returnPolicy}
                        bannerLogoImgComponentName={bannerLogoImgComponentName}
                    />
                    <picture>
                        <source
                            type="image/png"
                            {...applyAkamaiPolicy(imageUrl, offersCardImgComponentName, false, returnPolicy)}
                        />
                        <img
                            className={`${ClassName}_offer-card_image`}
                            alt=""
                            {...applyAkamaiPolicy(
                                fallbackOfferImageUrl,
                                offersCardImgComponentName,
                                false,
                                returnPolicy,
                            )}
                        />
                    </picture>
                </div>
            )
        } else if (title) {
            return (
                <div className={`${ClassName}_offer-card ${ClassName}_offer-card_error`}>
                    <img
                        className={`${ClassName}_offer-card_image`}
                        src={failedActivationImage}
                        alt={failedActivationImageAltText}
                    />
                </div>
            )
        } else return
    }

    const renderOfferCard = (): JSX.Element => {
        return (
            <div className={`${ClassName}_offer-container`}>
                <div
                    data-testid="rendermultipleoffers"
                    dangerouslySetInnerHTML={{ __html: multipleOfferTilesSuccessMessage }}
                />
                {selectedOfferArray?.map((offer: Offers, index: number) => (
                    <OfferCard
                        key={`offer_${index}`}
                        activatedLabel={activatedLabel}
                        bannerImageList={bannerImageList}
                        offerEndsLabel={offerEndsMessage}
                        offerEndsTodayLabel={offerEndsTodayMessage}
                        offerEndsDateLabel={offerEndsDateMessage}
                        offer={offer}
                        openModal={openOfferModal}
                        activateOffer={() => {
                            return null
                        }}
                        modalLabel={offerDetailsLabel}
                        moreDetailsLabel={moreDetailsLabel}
                        activateLabel={''}
                        redeemedLabel={''}
                        countLabel={''}
                        countWithTotalLabel={''}
                        offerStatusLabel={''}
                        brandsLabel={''}
                        categoriesLabel={''}
                        activateAllOffersLabel={''}
                        showMoreLabel={''}
                        unactivatedLabel={''}
                        offerExpiresLabel={''}
                        bannerIdToNameList={[]}
                        offerTypeIdToNameList={[]}
                        status={getButtonStatus(offer?.offerStatus, offerConstants, labels)}
                        columns={columns}
                        akamaiPolicy={akamaiPolicy}
                    />
                ))}
            </div>
        )
    }

    /**
     * function to render OfferFlyout
     * @return {JSX.Element}
     */
    const offerModalWrapper = () => {
        return (
            <>
                {checkDataLength(selectedOffer) && (
                    <div className={`${PREFIX}__offers_details`}>
                        <OfferFlyout
                            modalLabel={offerDetailsLabel}
                            moreDetailsLabel={moreDetailsLabel}
                            countLabel={''}
                            countWithTotalLabel={''}
                            offerStatusLabel={''}
                            brandsLabel={''}
                            categoriesLabel={''}
                            activateAllOffersLabel={''}
                            showMoreLabel={''}
                            activatedLabel={activatedLabel}
                            activateLabel={''}
                            unactivatedLabel={''}
                            offerExpiresLabel={''}
                            offerEndsTodayLabel={''}
                            offerEndsLabel={''}
                            bannerIdToNameList={[]}
                            offerTypeIdToNameList={[]}
                            redeemedLabel={''}
                            {...props}
                            isOpen={isModalOpen}
                            setIsFlyoutOpen={value => setIsModalOpen(value)}
                            offer={selectedOffer}
                            activateOffer={() => {
                                return null
                            }}
                            status={getButtonStatus(selectedOffer?.offerStatus, offerConstants, labels)}
                            columns={columns}
                            akamaiPolicy={akamaiPolicy}
                        />
                    </div>
                )}
            </>
        )
    }
    if (!isCardVisible && multiOffer && selectedOfferArray) {
        return (
            <div className={`${ClassName}_multiple`}>
                {renderOfferCard()}
                {offerModalWrapper()}
            </div>
        )
    } else {
        return (
            <div className={ClassName}>
                {offerDetailsCardElement()}
                <OfferActivationMessage {...props} />
            </div>
        )
    }
}

EmailActivationCard.propTypes = {
    ClassName: PropTypes.string,
    messages: PropTypes.object,
    isCardVisible: PropTypes.bool,
    selectedCard: PropTypes.object,
    selectedOfferArray: PropTypes.array,
    returnPolicy: PropTypes.func,
}

export default EmailActivationCard
