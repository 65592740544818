import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonToggle } from '@nl/lib'
import { propsType } from './buttonToggle.types'
import { fetchToggleData } from '../../redux/actions/buttonToggle.action'
import { isObjectNotEmpty } from '../../utils/isObjectNotEmpty'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { analyticsInteraction } from '../../redux/actions/pageAnalytics.action'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'

/**
 * ButtonToggleWrapper component
 * @param {propsType} props
 * @return {JSX.Element} returns ButtonToggleWrapper component
 */

const ButtonToggleWrapper: React.FC<propsType> = ({ ...props }) => {
    const dispatch = useDispatch()
    const { commonContentAvailable } = useSelector(commonContentSelector)

    const [selectedLabel, setSelectedLabel] = useState('')
    const [unselectedLabel, setUnselectedLabel] = useState('')
    const [a11yViewTypeLabel, setA11yViewTypeLabel] = useState('')

    /**
     * This function handles the layout button click
     * @param {toggleClick} toggleClick
     */
    const handleDispatchClick = (toggleClick: string): void => {
        dispatch(fetchToggleData(toggleClick))
        dispatch(analyticsInteraction(toggleClick, '', search, changeResultsLayout))
    }

    useEffect(() => {
        const accessibilityObj = commonContentAvailable.accessibility
        if (isObjectNotEmpty(accessibilityObj)) {
            setSelectedLabel(accessibilityObj.a11ySelectedLabel as string)
            setUnselectedLabel(accessibilityObj.allyUnselectedLabel as string)
            setA11yViewTypeLabel(accessibilityObj.a11yViewTypeLabel as string)
        }
    }, [commonContentAvailable])

    const {
        event: { search },
        eventParameters: {
            action: { changeResultsLayout },
        },
    } = analyticsAttributes

    return (
        <ButtonToggle
            productCardListView={props.productCardListView}
            handleDispatchClick={handleDispatchClick}
            listViewLabel={props.listViewLabel}
            gridViewLabel={props.gridViewLabel}
            a11ySelectedLabel={selectedLabel}
            allyUnselectedLabel={unselectedLabel}
            isFitmentRequired={props.isFitmentRequired}
            defaultView={props.defaultView}
            a11yViewTypeLabel={a11yViewTypeLabel}
        />
    )
}
ButtonToggleWrapper.propTypes = {
    productCardListView: PropTypes.bool.isRequired,
    listViewLabel: PropTypes.string.isRequired,
    gridViewLabel: PropTypes.string.isRequired,
    isFitmentRequired: PropTypes.bool,
    defaultView: PropTypes.string,
}
export default ButtonToggleWrapper
