import React, { FC, FormEventHandler, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import TextInput from '../TextInput'
import { NewCardFormProps } from './GiftCard.types'
import { PREFIX } from '../config'
import {
    CARD_NUMBER_MIN_DIGITS,
    CARD_NUMBER_MAX_DIGITS,
    CARD_NUMBER_GROUP_SIZE,
    PIN_MIN_DIGITS,
    PIN_MAX_DIGITS,
    PIN_GROUP_SIZE,
} from './GiftCard.constants'
import { addDividers, getLengthWithDividers, removeDividers, validateLength } from './GiftCard.helpers'
import { TextInputModes } from '../../globalConstants/global.constant'
import { checkoutRegexPatterns } from '../../globalConstants/regexPatterns.constant'

/**
 * New Gift Card Form component
 * @param {NewCardFormProps} NewCardFormProps
 *
 * @return {JSX.Element}
 */
const NewCardForm: FC<NewCardFormProps> = ({
    gcApplyCTALabel,
    gcCardNumberInputLabel,
    gcPinInputLabel,
    gcCardNumberErrorMsg,
    gcPinErrorMsg,
    applyGiftCard,
}) => {
    const [number, setNumber] = useState('')
    const [pin, setPin] = useState('')
    const [cardNumberError, setCardNumberError] = useState(false)
    const [pinError, setPinError] = useState(false)

    const componentClassName = `${PREFIX}-giftcard`

    const handleNumberChange = (value: string) => {
        setNumber(addDividers(value, CARD_NUMBER_GROUP_SIZE))
    }

    const handlePinChange = (value: string) => {
        setPin(addDividers(value, PIN_GROUP_SIZE))
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        const isNumberValid = validateLength(number, CARD_NUMBER_MIN_DIGITS, CARD_NUMBER_MAX_DIGITS)
        const isPinValid = validateLength(pin, PIN_MIN_DIGITS, PIN_MAX_DIGITS)
        if (isNumberValid && isPinValid) {
            applyGiftCard({ giftCardNumber: removeDividers(number), giftCardPin: removeDividers(pin) })
        }
        setCardNumberError(!isNumberValid)
        setPinError(!isPinValid)
    }

    return (
        <form className={`${componentClassName}__form`} onSubmit={handleSubmit}>
            <div className={`${componentClassName}__form-fields`}>
                <TextInput
                    quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    label={gcCardNumberInputLabel}
                    id="card-number"
                    value={number}
                    error={cardNumberError ? gcCardNumberErrorMsg : ''}
                    maxLength={getLengthWithDividers(CARD_NUMBER_MAX_DIGITS, CARD_NUMBER_GROUP_SIZE)}
                    onChange={handleNumberChange}
                    size="default"
                    inputMode={TextInputModes.NUMERIC}
                    pattern={checkoutRegexPatterns.giftCardNumberAndPin}
                />
                <TextInput
                    quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    label={gcPinInputLabel}
                    id="pin"
                    value={pin}
                    error={pinError ? gcPinErrorMsg : ''}
                    maxLength={getLengthWithDividers(PIN_MAX_DIGITS, PIN_GROUP_SIZE)}
                    onChange={handlePinChange}
                    size="default"
                    inputMode={TextInputModes.NUMERIC}
                    pattern={checkoutRegexPatterns.giftCardNumberAndPin}
                />
            </div>
            <Button
                type="secondary"
                buttonType="submit"
                quantumMetricAttribute={{ type: 'allow', value: 'true' }}
                size="small"
                label={gcApplyCTALabel}
                id="gift-card-apply"
            />
        </form>
    )
}
NewCardForm.propTypes = {
    gcApplyCTALabel: PropTypes.string.isRequired,
    gcCardNumberInputLabel: PropTypes.string.isRequired,
    gcPinInputLabel: PropTypes.string.isRequired,
    gcCardNumberErrorMsg: PropTypes.string.isRequired,
    gcPinErrorMsg: PropTypes.string.isRequired,
    applyGiftCard: PropTypes.func.isRequired,
}

export default NewCardForm
