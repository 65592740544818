import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CascadingDropdownProps } from './CascadingDropdown.type'
import Icon from '../Icon'
import Button from '../Button'
import SubtitleItem from './SubtitleItem'
import { externalNavigation } from '../../utils/getExternalLinks'
import { PREFIX } from '../config'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { stringKeyCodes } from '../../utils/stringKeyCodes'
import { magicNumber } from '../../utils'

/**
 * CascadingDropdown component
 *
 * @param {DropdownProps} props - link,title,index,isItemCascading is default to false,subMenu,subtitle,subtitle,
 * @return {JSX.Element} returns CascadingDropdown component
 */
const CascadingDropdown: React.FC<CascadingDropdownProps> = ({ ...props }) => {
    const { link, title, index, isItemCascading = false, subMenu, subtitle, ClassName, isSubItem } = props
    const [showDropdown, setShowDropdown] = useState(false)
    const contentRef = useRef(null)
    useClickOutsideClose(contentRef, () => onDropdownButtonClickHandler(), true, true)

    /**
     * Function to show dropdown if item is cascading
     */
    const onDropdownButtonClickHandler = () => {
        setShowDropdown(!showDropdown)
    }

    /**
     * Close the dropdown when user press esc button.
     * @param {KeyboardEvent} e - use to capture the esc keycode.
     */
    const menuEscapeHandler = useCallback((e: KeyboardEvent): void => {
        if (e.key === stringKeyCodes['esc']) {
            setShowDropdown(false) // close the dropdown
        }
    }, [])

    /**
     * Close the dropdown when user press tab key / shift+Tab when on last or first item respectively
     * @param {Element} firstItem - first item in menu.
     * @param {Element} lastItem - last item in menu.
     * @param {KeyboardEvent} e - use to capture the keycode.
     */

    const addKeyBoardAccessibility = useCallback((firstItem: Element, lastItem: Element, e: KeyboardEvent): void => {
        const elem = document.activeElement
        if (
            (e.key === stringKeyCodes['tab'] && elem === lastItem && !e.shiftKey) ||
            (elem === firstItem && e.shiftKey && e.key === stringKeyCodes['tab'])
        ) {
            e.stopPropagation()
            e.preventDefault()
            setShowDropdown(false)
        }
    }, [])

    /**
     * useEffect for adding event listeners
     */
    useEffect(() => {
        document.addEventListener('keyup', menuEscapeHandler)
        return () => {
            document.removeEventListener('keyup', menuEscapeHandler)
        }
    }, [menuEscapeHandler])

    /**
     * useEffect for adding event listeners and creating menuAccessibility
     */
    useEffect(() => {
        let firstItem = null
        let lastItem = null
        if (showDropdown) {
            const menuDropDownParent = document.getElementsByClassName(
                `${PREFIX}-primary-navigation__subitems`,
            )[0] as HTMLElement
            const interactiveElements: NodeListOf<Element> = menuDropDownParent?.querySelectorAll('a,button')
            const menuItems = interactiveElements && Array.from(interactiveElements)
            if (menuItems && menuItems.length > 0) {
                firstItem = menuItems[0] // Set the first item in the menu
                lastItem = menuItems[menuItems.length - magicNumber.ONE] // Get the last item in the menu
            }
            menuDropDownParent &&
                menuDropDownParent.addEventListener('keydown', addKeyBoardAccessibility.bind(null, firstItem, lastItem))
        }
        return () => {
            document.removeEventListener('keydown', addKeyBoardAccessibility.bind(null, firstItem, lastItem))
        }
    }, [showDropdown, addKeyBoardAccessibility])

    /**
     * @return {JSX.Element} returns Sub-Menu Items of Dropdown Buttons
     */
    const subMenuItemsRender = (): JSX.Element => {
        return subMenu && showDropdown ? (
            <nav className={`${ClassName}__subitems`} ref={contentRef}>
                {subMenu.map((item: CascadingDropdownProps, i: number) => (
                    <CascadingDropdown
                        key={`${index}-${i}`}
                        index={i}
                        title={item.title}
                        link={item.link}
                        subtitle={item.subtitle}
                        ClassName={`${ClassName}`}
                        isSubItem={true}
                        isItemCascading={!!(item.subLevelItems && item.subLevelItems.length)}
                        subMenu={item.subLevelItems}
                    />
                ))}
            </nav>
        ) : null
    }

    /**
     * @return {JSX.Element} returns external link items of Dropdown Buttons
     */
    const externalLinkItemRender = (): JSX.Element => {
        return (
            <div className={`${ClassName}--item__external-link-value ${PREFIX}-display-2`}>
                {title}
                <Icon type="ct-north-east" />
            </div>
        )
    }

    const isItemActive = () => {
        const currentUrl = window.location.href

        if (!isItemCascading && currentUrl && currentUrl.includes(decodeURIComponent(link))) {
            return `${ClassName}--item__active`
        }
        return ``
    }

    const cascadingDropdownRender = (): JSX.Element => {
        return isItemCascading ? (
            <>
                <Button
                    data-index={index}
                    onClick={onDropdownButtonClickHandler}
                    type="link"
                    ariaExpanded={showDropdown}>
                    {title}
                    <Icon type={`ct-chevron-${showDropdown ? 'up' : 'down'}`} />
                </Button>
                {subMenuItemsRender()}
            </>
        ) : (
            <a href={link} data-index={index} target={`${externalNavigation(link) ? '_blank' : '_self'}`}>
                {isSubItem ? (
                    externalNavigation(link) ? (
                        externalLinkItemRender()
                    ) : (
                        <SubtitleItem title={title} subtitle={subtitle} ClassName={`${ClassName}`} />
                    )
                ) : (
                    title
                )}
            </a>
        )
    }

    return (
        <div className={`${ClassName}--item${externalNavigation(link) ? `__external-link` : ''} ${isItemActive()}`}>
            {cascadingDropdownRender()}
        </div>
    )
}

CascadingDropdown.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    index: PropTypes.number,
    isItemCascading: PropTypes.bool,
    subMenu: PropTypes.array,
    ClassName: PropTypes.string,
    isSubItem: PropTypes.bool,
    subLevelItems: PropTypes.array,
}

export default CascadingDropdown
