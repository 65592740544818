export const rewardAccountCard = 'rewardAccountCard'
export const loyaltyCardRegisterForm = 'loyaltyCardRegisterForm'
export const linkExistingCardForm = 'linkExistingCardForm'
export const successLinkCard = 'successLinkCard'
export const rewardTermsConditions = 'rewardTermsConditions'
export const loyaltyCardActiveOwnership = 'loyaltyCardActiveOwnership'
export const masterCardRegisterForm = 'masterCardRegisterForm'
export const errorLinkCard = 'errorLinkCard'
export const loyaltyCardPrefix = '6365'
export const loyaltyCardMaxLength = 14
export const nullLoyaltyCard = '0000000000000000'
export const defaultDOB = { month: 1, day: 1 }

export const firstNameRegex = /^[^.\s]+[a-zA-ZÀ-ÿ\-'’"\s]+$/
export const lastNameRegex = /^[^.\s]+[a-zA-ZÀ-ÿ\-'’"\s]+$/
export const loyaltyCardRegex = /(\d{1,4})(\d{1,4})(\d{1,4})/
export const lastFourDigitsRegex = /(\d{4})/g
export const errorRichTextRegex = /(\.)\s+/g

export const addressRegex = /^[a-zA-ZÀ-ÿ0-9,.'’/\\\-\s]+$/

export const cityRegex = /^[a-zA-ZÀ-ÿ,.'’/\\\-\s]+$/
export const postalCodeRegex = /^ *(?=[^dfioquwz])[a-z]\d(?=[^dfioqu])[a-z] ?\d(?=[^dfioqu])[a-z]\d *$/i
export const telephoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/

export const cardNumberRegex = /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/
export const mastercardNumberRegex = /^[0-9]{4}/

export const LegacyCardError = 'LEGACY_MASTER_CARD_ERROR'
export const cardCheckValidStatus = 'VALID'

export const loyaltyCardStatus = {
    alreadyLinkedCard: 'ALREADY_LINKED_CARD',
    validCard: 'VALID',
    cancelledCard: 'CANCELED_CARD',
    pendingCard: 'PENDING_CARD',
    nonExistingCard: 'NON_EXISTING',
    involvedInMergeCard: 'INVOLVED_IN_MERGE',
    banaceExceededCard: 'BALANCE_EXCEEDING_THRESHOLD',
}

export const excludedErrCodeForTryAgain = ['40400']

export const analyticsScreenName = {
    loyaltyNumber: 'Loyalty number',
    customerInfo: 'Customer info',
    ActiveOwnership: 'Active ownership',
    registerExistingCard: 'Register existing loyalty card',
    completeCustProfile: 'Complete customer profile',
    registerNewCard: 'Register new loyalty card',
    masterCard: 'Mastercard information',
    registerMasterCard: 'Mastercard information',
    tnc: 'Rewards Terms and Condition',
}

export const analyticsLinkType: Record<string, Record<string, string>> = {
    LINK_EXISTING_CARD: {
        type: 'link existing card',
    },
    LINK_MASTER_CARD: {
        type: 'link mastercard',
    },
    LINK_NEW_CARD: {
        type: 'link new card',
    },
}
export const screenName = 'Loyalty card options'
export const existingCardName = 'LINK_EXISTING_CARD'
export const newCardName = 'LINK_NEW_CARD'
export const masterCardName = 'LINK_MASTER_CARD'
export const skipLinkCard = 'SKIP_LINK_CARD'

export const dataType: Record<string, string> = {
    string: 'string',
}
