export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE'
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR'
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_CLEAR = 'UPDATE_PROFILE_CLEAR'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const USER_PROFILE_PENDING = 'USER_PROFILE_PENDING'
export const ADD_VEHICLE_LIST = 'ADD_VEHICLE_LIST'
export const SET_IS_VEHICLE_SYNCED_WITH_LS_ON_PAGE_LOAD = 'SET_IS_VEHICLE_SYNCED_WITH_LS_ON_PAGE_LOAD'
export const ADD_VEHICLE = 'ADD_VEHICLE'
export const SET_DEFAULT_VEHICLE = 'SET_DEFAULT_VEHICLE'
export const REMOVE_VEHICLE_LIST_FROM_GARAGE = 'REMOVE_VEHICLE_LIST_FROM_GARAGE'
export const SET_URL_DEFAULT_VEHICLE = 'SET_URL_DEFAULT_VEHICLE'
export const CLEAR_URL_DEFAULT_VEHICLE = 'CLEAR_URL_DEFAULT_VEHICLE'
export const UPDATE_VEHICLE_FIT_TYPE = 'SET_VEHICLE_FIT'
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE'
export const SET_BASE_VEHICLE_ID = 'SET_BASE_VEHICLE_ID'
export const RESET_BASE_VEHICLE_ID = 'RESET_BASE_VEHICLE_ID'
export const SHOW_NO_VEHICLE = 'SHOW_NO_VEHICLE'
export const SET_CRITICAL_FITMENT_DETAILS = 'SET_CRITICAL_FITMENT_DETAILS'
export const CRITICAL_FITMENT_API_TRIGGERED = 'CRITICAL_FITMENT_API_TRIGGERED'
export const CRITICAL_FITMENT_API_DONE = 'CRITICAL_FITMENT_API_DONE'
export const SET_CRITICAL_FITMENT_ERROR = 'SET_CRITICAL_FITMENT_ERROR'
export const SET_SOFT_RECALL_CTA_CLICKED = 'SET_SOFT_RECALL_CTA_CLICKED'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const RESET_CRITICAL_FITMENT_DETAILS = 'RESET_CRITICAL_FITMENT_DETAILS'
export const NEW_VEHICLE_FORM_PROPS = 'NEW_VEHICLE_FORM_PROPS'
export const REHYDRATE_USER_PROFILE = 'REHYDRATE_USER_PROFILE'
export const SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE'
export const SET_SELECTED_VEHICLE_INDEX = 'SET_SELECTED_VEHICLE_INDEX'
