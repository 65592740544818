import { Dispatch } from 'redux'

import { GetProductDataOptionalValues, ProductDataTypeObj, SortOption } from '../models/productData.interface'
import {
    fetchProductCardDataRequestAction,
    fetchProductCardDataSuccessAction,
    fetchProductCardDataErrorAction,
    fetchLoadMoreProductDataSuccessAction,
    updateFacetClickAction,
    updateSelectedSortAction,
    clearProductListAction,
    fetchWeatherTechProductCardDataSuccessAction,
    fetchWeatherTechProductCardDataRequestAction,
} from '../actionCreators/productData.actionCreators'
import getNextSetOfResults from '../../utils/getNextSetOfResults'
import { PlpService } from '../../services/plpService'
import { setCreatePackageClick } from './automotiveVehicle.action'
import { ProductResponseErrorDTO } from '../models/product.interface'
import { IFeatureFlag, IGlobalProps, ISearchConfiguration } from '../models/commonContent.interface'
import { Vehicle, checkDataLength, isArrayNotEmpty, updateUrlHistory } from '@nl/lib'
import { AxiosError } from 'axios'
import { RootState } from '../reducers'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import {
    setIsWeatherTechProductsPresent,
    setShowErrorToast,
    setWeatherTechVehicleVendorResults,
} from '../actionCreators/weatherTech.actionCreators'
import { WeatherTechVehicle } from '../models/weatherTech.interface'
import { getCategoriesList } from '../../components/Vehicles/Vehicle.helper'
import { CategoryBreadCrumb } from '../models/category.interface'
import { checkIsPriceAvailabilityLazyLoadNeeded } from '../../utils/PLP/checkIsPriceAvailabilityLazyLoadNeeded'
import { suppressFacetEntry } from '../../components/ProductGridView/ProductCard.types'

/**
 * function to fetch list of products
 * @param {string} requestPayload
 * @param {string} selectedPreferredStoreId
 * @param {boolean | undefined} isFitmentRequired
 * @param {number} productToFetch
 * @param {number} pageNumber
 * @param {string} pathwayId
 * @param {CategoryBreadCrumb[]} breadcrumbList
 * @param {isPDP} isPDP
 * @param {Vehicle} defaultVehicle
 * @param {string} currentPackageId
 * @param {boolean} isCompleteVehicleState
 * @param {string[]} searchSuppressFacets
 * @param {string} searchWidgetType
 * @param {string} searchCategoryLevel
 * @param {string} searchCategoryId
 * @param {boolean | undefined} searchPassQParameter
 * @param {string} searchQParameter
 * @param {string} searchExperience
 * @param {string} tireWheelSpecification
 * @param {boolean} filterUniversalProducts
 * @param {boolean} withCaching
 * @return {Promise}
 */
export const fetchProductCardData =
    (
        requestPayload: string,
        selectedPreferredStoreId: string,
        isFitmentRequired?: boolean,
        productToFetch?: number,
        pageNumber?: number,
        pathwayId?: string,
        breadcrumbList?: CategoryBreadCrumb[],
        isPDP?: boolean,
        defaultVehicle?: Vehicle,
        currentPackageId?: string,
        isCompleteVehicleState?: boolean,
        searchSuppressFacets?: suppressFacetEntry[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchCategoryId?: string,
        searchPassQParameter?: boolean,
        searchQParameter?: string,
        searchExperience?: string,
        tireWheelSpecification?: string,
        filterUniversalProducts?: boolean,
        withCaching?: boolean,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { commonContentAvailable } = enableDestructOnUndefinedData(getState().commonContent) as IGlobalProps
        const { enableXRingFence, enablePriceAvailabilityLazyLoadForSearchResults } = enableDestructOnUndefinedData(
            commonContentAvailable?.featureFlag,
        ) as IFeatureFlag
        const { categoriesForPriceAvailabilityLazyLoad } = enableDestructOnUndefinedData(
            commonContentAvailable?.searchConfiguration,
        ) as ISearchConfiguration
        const priceAvailabilityLazyLoadNeeded = checkIsPriceAvailabilityLazyLoadNeeded(
            enablePriceAvailabilityLazyLoadForSearchResults || false,
            breadcrumbList,
            categoriesForPriceAvailabilityLazyLoad,
        )
        const categoriesList = getCategoriesList(breadcrumbList, isPDP)

        dispatch(fetchProductCardDataRequestAction())
        const optionalValues = {
            productToFetch: productToFetch,
            pathwayId: pathwayId,
            breadcrumbList: categoriesList,
            defaultVehicle: defaultVehicle,
            categoryId: searchCategoryId,
            currentPackageId: currentPackageId,
            isCompleteVehicleState: isCompleteVehicleState,
            searchSuppressFacets: searchSuppressFacets,
            searchWidgetType: searchWidgetType,
            searchCategoryLevel: searchCategoryLevel,
            searchPassQParameter: searchPassQParameter,
            searchQParameter: searchQParameter,
            searchExperience: searchExperience,
            tireOrWheelSpecification: tireWheelSpecification,
            enableXRingFence: enableXRingFence,
            filterUniversalProducts: filterUniversalProducts,
            priceAvailabilityLazyLoadNeeded: priceAvailabilityLazyLoadNeeded,
            withCaching: withCaching,
        } as GetProductDataOptionalValues

        return PlpService.getProductData(
            requestPayload,
            selectedPreferredStoreId,
            isFitmentRequired,
            pageNumber,
            optionalValues,
        )
            .then(data => {
                replaceEmptyImagesWithDefault(data?.data?.products, 'images')
                dispatch(fetchProductCardDataSuccessAction(data.data))
                dispatch(setCreatePackageClick(false))
            })
            .catch((error: AxiosError<ProductResponseErrorDTO>) => {
                const { data, status } = error.response as unknown as ProductResponseErrorDTO
                dispatch(fetchProductCardDataErrorAction({ data, status }))
            })
    }

/**
 * function to fetch list of products
 * @param {string} requestPayload
 * @param {WeatherTechVehicle} weatherTechVehicle
 * @param {string} selectedPreferredStoreId
 * @param {number} productToFetch
 * @param {number} pageNumber
 * @param {string} pathwayId
 * @param {string} searchSuppressFacets
 * @param {string} searchWidgetType
 * @param {boolean} searchCategoryLevel
 * @param {string} searchCategoryId
 * @param {string} searchExperience
 * @return {Promise}
 */
export const fetchWeatherTechProductData =
    (
        requestPayload: string,
        weatherTechVehicle: WeatherTechVehicle,
        selectedPreferredStoreId: string,
        productToFetch?: number,
        pageNumber?: number,
        pathwayId?: string,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchCategoryId?: string,
        searchExperience?: string,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { commonContentAvailable } = enableDestructOnUndefinedData(getState().commonContent) as IGlobalProps
        const { enableXRingFence } = enableDestructOnUndefinedData(commonContentAvailable?.featureFlag) as IFeatureFlag
        const optionalValues = {
            productToFetch: productToFetch,
            pathwayId: pathwayId,
            searchSuppressFacets: searchSuppressFacets,
            searchWidgetType: searchWidgetType,
            searchCategoryLevel: searchCategoryLevel,
            searchExperience: searchExperience,
            categoryId: searchCategoryId,
            enableXRingFence: enableXRingFence,
            isWeatherTech: true,
            weatherTechVehicle: weatherTechVehicle,
        } as GetProductDataOptionalValues

        dispatch(fetchWeatherTechProductCardDataRequestAction())

        return PlpService.getWeatherTechProductData(
            requestPayload,
            selectedPreferredStoreId,
            pageNumber,
            optionalValues,
        )
            .then(data => {
                replaceEmptyImagesWithDefault(data?.data?.products, 'images')
                if (!checkDataLength(weatherTechVehicle.vendorResults) && checkDataLength(data?.data?.vendorResults)) {
                    dispatch(setWeatherTechVehicleVendorResults(data?.data?.vendorResults))
                    if (isArrayNotEmpty(data?.data?.products)) {
                        updateUrlHistory()
                    }
                }
                dispatch(fetchWeatherTechProductCardDataSuccessAction(data.data))
                dispatch(setIsWeatherTechProductsPresent(isArrayNotEmpty(data?.data?.products)))
                dispatch(setShowErrorToast(!isArrayNotEmpty(data?.data?.products)))
            })
            .catch((error: AxiosError<ProductResponseErrorDTO>) => {
                const { data, status } = error.response as unknown as ProductResponseErrorDTO
                dispatch(fetchProductCardDataErrorAction({ data, status }))
            })
    }

/**
 * function to fetch next set of products based on current page
 * @param {ProductDataTypeObj[]} products
 * @param {number} currentPage
 * @param {number} loadCount
 * @param {number} initialCount
 * @param {number} productsDisplayCount
 * @return {void} // Void needs to be returned for action
 */
export const fetchLoadMoreProductData =
    (
        products: ProductDataTypeObj[],
        currentPage: number,
        loadCount: number,
        initialCount: number,
        productsDisplayCount?: number,
    ) =>
    (dispatch: Dispatch): void => {
        const loadProducts = getNextSetOfResults(products, currentPage, loadCount, initialCount, productsDisplayCount)
        dispatch(fetchLoadMoreProductDataSuccessAction(loadProducts as unknown as ProductDataTypeObj[]))
    }

/**
 * function to update facet click action
 * @param {string} requestPayload
 * @return {void} // Void needs to be returned for action
 */
export const updateFacetClick =
    (requestPayload: string) =>
    (dispatch: Dispatch): void => {
        dispatch(updateFacetClickAction(requestPayload))
    }

/**
 * function to update selected sort option
 * @param {string} requestPayload
 * @return {void} // Void needs to be returned for action
 */
export const updateSelectedSort =
    (requestPayload: SortOption) =>
    (dispatch: Dispatch): void => {
        dispatch(updateSelectedSortAction(requestPayload))
    }

/**
 * function to clear the product list
 * @return {void} // Void needs to be returned for action
 */
export const clearProductList =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearProductListAction())
    }
