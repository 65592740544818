/**
 * function to check whether object is empty or not
 * @param {Record<string, unknown>} obj
 * @param {string} attr
 * @return {boolean}
 */
export const isObjectNotEmpty = (obj: Record<string, unknown>, attr?: string): boolean => {
    return attr
        ? !(obj[attr] === undefined || obj[attr] === null)
        : !(obj === undefined || obj === null || Object.keys(obj).length === 0)
}
