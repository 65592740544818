import React from 'react'
import { PREFIX } from '../config'
import PropTypes from 'prop-types'
import { MessageCardProps } from './MessageCard.type'
import CommonCardWrapper from '../CommonCardWrapper'
import CommonCard from '../CommonCard'
import Icon from '../Icon'

/**
 * This component displays an message card that renders icon and primary text.
 * It can also render complementary text and a description if provided.
 * @param {MessageCardProps} MessageCardProps
 * @return {JSX.Element}
 */
export const MessageCard: React.FC<MessageCardProps> = ({
    icon,
    primaryText,
    renderComplementaryText,
    renderDescription,
}): JSX.Element => (
    <CommonCardWrapper>
        <CommonCard>
            <article className={`${PREFIX}-message-card`}>
                <Icon type={icon} size="lg" />
                {primaryText && <h1 className={`${PREFIX}-message-card__title ${PREFIX}-mt-sm`}>{primaryText}</h1>}
                {renderComplementaryText && renderComplementaryText()}
                {renderDescription && renderDescription()}
            </article>
        </CommonCard>
    </CommonCardWrapper>
)

export default MessageCard

MessageCard.propTypes = {
    primaryText: PropTypes.string,
    icon: PropTypes.string.isRequired,
    renderComplementaryText: PropTypes.func,
    renderDescription: PropTypes.func,
}
