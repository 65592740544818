/**
 ** Interface for PillBar
 * @interface
 */
export interface PillBarTypes {
    clearAllFilterLabel: string
    toggleDispatch: () => void
    a11yRemoveFilter: string
    a11yClearFiltersLabel?: string
    a11yAppliedFiltersLabel?: string
}

/**
 ** Interface for Facet
 * @interface
 */

export enum FacetTypes {
    BREADCRUMB = 'breadcrumb',
    RADIO = 'radio',
    MULTISELECT = 'multiselect',
    RANGE = 'range',
    CUSTOM_PRICE = 'custom_price',
    CATEGORY_ID = 'category_ast',
}
