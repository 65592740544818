import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/stickyToc.actionCreators'
type Action = ActionType<typeof actions>
export interface StickyState {
    stickyData: string[]
    isStickyTOCInView: boolean
}
const initialState: StickyState = {
    stickyData: [],
    isStickyTOCInView: false,
}

export const stickyReducer = (state: StickyState = initialState, action: Action): StickyState => {
    switch (action.type) {
        case getType(actions.fetchStickyAction):
            const mappedData = [...state.stickyData, action.payload]
            return state.stickyData.find(obj => obj === action.payload) ? state : { ...state, stickyData: mappedData }
        case getType(actions.setIsStickyTOCInViewAction):
            return { ...state, isStickyTOCInView: action.payload }
        default:
            return state
    }
}
