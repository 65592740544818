import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip'
import { PREFIX } from '../config'
import { BannerDisclaimerProps } from './BannerDisclaimer.type'

const BannerDisclaimer: React.FC<BannerDisclaimerProps> = ({ ...props }): JSX.Element => {
    const buttonRef = useRef()
    const [visibility, setVisibility] = useState(false)
    const { disclaimerFormat, disclaimerText, disclaimerTitle, tooltipField } = props

    const toolTipButtonHandler = (): void => {
        setVisibility(!visibility)
    }

    /**
     * Render Disclaimer text
     * @return {{JSX.Element}} Render Disclaimer text
     */
    const renderDisclaimerText = (): JSX.Element => {
        return (
            <div
                className={`${PREFIX}-banner__disclaimer-content`}
                dangerouslySetInnerHTML={{ __html: disclaimerText || '' }}></div>
        )
    }

    /**
     * Get Disclaimer tooltip
     * @param {string | undefined} tooltipText
     * @return {{JSX.Element}} Get Disclaimer tooltip
     */
    const getDisclaimerTooltip = (tooltipText: string | undefined): JSX.Element => {
        return (
            <>
                {tooltipText && (
                    <button
                        className={`${PREFIX}-banner__links`}
                        type="button"
                        ref={buttonRef}
                        data-testid="banner-tooltip-field"
                        onClick={toolTipButtonHandler}>
                        {tooltipText}
                    </button>
                )}
                {tooltipText && (
                    <Tooltip
                        visibility={visibility}
                        setVisibility={setVisibility}
                        iconID="ct-close"
                        headerText={disclaimerTitle}
                        bodyText={
                            <div
                                data-testid="tooltip-text"
                                className={`${PREFIX}-banner__disclaimer__content`}
                                dangerouslySetInnerHTML={{ __html: disclaimerText || '' }}
                            />
                        }
                        coords={buttonRef.current}
                    />
                )}
            </>
        )
    }

    /**
     * Render Disclaimer tooltip
     * @return {{JSX.Element}} Disclaimer tooltip
     */
    const renderDisclaimerTooltip = (): JSX.Element => {
        const tooltipText = tooltipField
        return getDisclaimerTooltip(tooltipText)
    }

    return <>{disclaimerFormat === 'Tooltip' ? renderDisclaimerTooltip() : renderDisclaimerText()}</>
}

BannerDisclaimer.propTypes = {
    disclaimerFormat: PropTypes.string,
    disclaimerText: PropTypes.string,
    disclaimerTitle: PropTypes.string,
    tooltipField: PropTypes.string.isRequired,
}
export default BannerDisclaimer
