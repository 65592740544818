import { countryCode } from '../globalConstants/global.constant'

/**
 * checks if country is Canada
 * @param {string} country (CA, US etc.)
 * @return {boolean}
 */
export const isCanada = (country: string): boolean => {
    return country?.toLowerCase() === countryCode.ca
}

/**
 * checks if country is USA
 * @param {string} country (CA, US etc.)
 * @return {boolean}
 */
export const isUSA = (country: string): boolean => {
    return country?.toLowerCase() === countryCode.us
}

/**
 * checks if country is not CA and not USA
 * @param {string} country (CA, US etc.)
 * @return {boolean}
 */
export const isCountryWithoutRegions = (country: string): boolean => {
    return !(isCanada(country) || isUSA(country))
}
