/**
 * Analytics default module
 */
import { Promo } from './promo'
import { PromoCta } from './promoCta'
import { TriggerEventType } from './component.type'
import { AnalyticsDomSelector } from '../../globalConstants/analyticsParams.constant'
import { CartAnalytics } from './cartAnalytics'
import { Providers } from '../providers/provider.type'
import { getProvider } from '../providers'

/**
 * Default application analytics provider
 */
const defaultProvider = getProvider()

/**
 * Get analytics class instance with provided provider or default provider
 * @param {string} eventName
 * @param  {Providers} provider?
 * @return {CartAnalytics}
 */
function getCartAnalytics(eventName: string, provider?: Providers): CartAnalytics {
    if (provider) {
        return new CartAnalytics(getProvider(provider), eventName)
    }
    return new CartAnalytics(defaultProvider, eventName)
}

export { Promo, PromoCta, TriggerEventType, AnalyticsDomSelector, getCartAnalytics }
