export const GetEventAnalytics = {
    interaction: 'interaction',
    account: 'account',
}

export const GetRewardsLabel = {
    error: 'error',
    rewardLinked: 'rewards linked',
    completed: 'completed',
    step: 'step',
}
