import { arraysElementsAreEqual } from '@nl/lib'
import { DropdownOptionsState, DropdownType } from '../models/vehicleDropdowns.types'
import { vehiclesDataInterface } from '../models/vehiclesData.interface'

/**
 * Method to handle action about basic dropdown reorder.
 * It recreate dropdowns.
 * So reordering lead to loosing previous state
 * Array will be base attribute and show only in the provided order.
 * @param {vehiclesDataInterface} state
 * @param {string[]} newBaseDropdownsKeys - list on new base attributes. The order is also important
 * @return {Partial<vehiclesDataInterface>}
 */
export const initDropdownByOrders = (
    state: vehiclesDataInterface,
    newBaseDropdownsKeys: string[],
): Partial<vehiclesDataInterface> => {
    if (!arraysElementsAreEqual(state.baseDropdownsKeys, newBaseDropdownsKeys)) {
        const dropdowns = newBaseDropdownsKeys.map(id => {
            return {
                optionsState: DropdownOptionsState.NOT_LOADED,
                label: '',
                id,
                isDisabled: true,
                value: '',
                options: [],
            } as DropdownType
        })
        return { dropdowns, baseDropdownsKeys: newBaseDropdownsKeys }
    } else {
        return state
    }
}

export default initDropdownByOrders
