/**
 * Check if given nested props are available in the object
 *
 * @param {*} obj on which to apply check
 * @param {string} args keys in form of strings
 * @return {undefined} if prop does not exists otherwise return value in i.e string
 */
export const checkNestedProps = (obj: Record<string, unknown>, ...args: string[]): unknown => {
    return args.reduce((innerObj, level) => innerObj && innerObj[level], obj)
}
