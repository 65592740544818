import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators'
import { orderCancellationReasonsFailure, orderCancellationReasonsSuccess } from '../actionCreators'
import { iOrderCancellationReason } from '../models/getOrderCancellationReasons.interface'
import { CartResponseErrorDTO } from '../models/cart.interface'

export interface CancelOrderReasonsState {
    cancellationReasons: iOrderCancellationReason[]
    cancellationReasonsFailure: CartResponseErrorDTO
}

type Action = ActionType<typeof actions>

export const initialState: CancelOrderReasonsState = {
    cancellationReasons: {} as iOrderCancellationReason[],
    cancellationReasonsFailure: {} as CartResponseErrorDTO,
}

export const orderCancellationReasonsReducer = (
    state: CancelOrderReasonsState = initialState,
    action: Action,
): CancelOrderReasonsState => {
    if (action.type === getType(orderCancellationReasonsSuccess)) {
        return { ...state, cancellationReasons: action.payload }
    } else if (action.type === getType(orderCancellationReasonsFailure)) {
        return { ...state, cancellationReasonsFailure: action.payload }
    }

    return state
}
