import { createAction } from 'typesafe-actions'

import {
    SET_SELECTED_TAB,
    UPDATE_TIRE_DATA,
    UPDATE_VEHICLE_DATA,
    UPDATE_IS_PACKAGE_FLOW,
    UPDATE_AUTO_PACKAGES,
    FETCH_TIRES_DATA_SUCCESS,
    FETCH_WHEELS_DATA_SUCCESS,
    VEHICLE_SELECTION_MODE,
    UPDATE_CURRENT_PACKAGE_ID,
    RESET_TIRE_DATA,
    SET_VEHICLE_API_STATUS,
    RESET_AUTO_PACKAGES,
    SET_IS_CREATE_PACKAGE_MODAL_OPEN,
    SET_IS_CREATE_PACKAGE_CLICK,
    SET_IS_TIRES_DATA_SYNCED_WITH_LS_ON_PAGE_LOAD,
} from '../types/automotive/automotiveVehicle.actionTypes.constant'
import { TireType } from '../models/tireVehicle.interface'
import { Vehicle } from '@nl/lib/'
import { AutoPackages } from '../../components/AutomotivePackage/PackageLanding/PackageLanding.type'
import { ProductDataType } from '../models/productData.interface'

export const updateTiresDataAction = createAction(UPDATE_TIRE_DATA, (payload: TireType) => payload)<TireType>()
export const setIsTiresDataSyncedWithLSOnPageLoadAction = createAction(
    SET_IS_TIRES_DATA_SYNCED_WITH_LS_ON_PAGE_LOAD,
    (payload: boolean) => payload,
)<boolean>()
export const updateVehicleDataAction = createAction(UPDATE_VEHICLE_DATA, (payload: Vehicle) => payload)<Vehicle>()
export const setTabSelectorAction = createAction(SET_SELECTED_TAB, (payload: string) => payload)<string>()
export const updateUserInitializedPackageFlowAction = createAction(
    UPDATE_IS_PACKAGE_FLOW,
    (payload: boolean) => payload,
)<boolean>()
export const updateCurrentPackageIdAction = createAction(
    UPDATE_CURRENT_PACKAGE_ID,
    (payload: string) => payload,
)<string>()
export const updateAutoPackageAction = createAction(UPDATE_AUTO_PACKAGES, (payload: AutoPackages[]) => payload)<
    AutoPackages[]
>()

export const fetchTiresDataSuccessAction = createAction(
    FETCH_TIRES_DATA_SUCCESS,
    (payload: ProductDataType) => payload,
)<ProductDataType>()

export const fetchWheelsDataSuccessAction = createAction(
    FETCH_WHEELS_DATA_SUCCESS,
    (payload: ProductDataType) => payload,
)<ProductDataType>()

export const setVehicleSelectionModeAction = createAction(
    VEHICLE_SELECTION_MODE,
    (payload: string) => payload,
)<string>()

export const resetTireDataAction = createAction(RESET_TIRE_DATA)()

export const setVehicleApiStatusAction = createAction(SET_VEHICLE_API_STATUS, (payload: boolean) => payload)<boolean>()

export const resetAutoPackagesDataAction = createAction(RESET_AUTO_PACKAGES)()

export const setCreatePackageModalOpenAction = createAction(
    SET_IS_CREATE_PACKAGE_MODAL_OPEN,
    (payload: boolean) => payload,
)<boolean>()

export const setCreatePackageClickAction = createAction(
    SET_IS_CREATE_PACKAGE_CLICK,
    (payload: boolean) => payload,
)<boolean>()
