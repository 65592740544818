import { MagicNumber } from '../analytics/analytics.type'
import {
    CartOrderDeliveryModes,
    CartOrderEntries,
    CartOrderEntriesWithFilterVal,
    CartOrderEntriesWithGroupingVal,
} from '../redux/models/cart.interface'
/**
 * @method getStatusGrouping: Groups the products by unique tracking id, status, ETA date and time combination
 * @param list passes the List of products to be grouped
 * @param type is the type of list (bopis,sth,services)
 * @return {Object} with unique keys and each value as array of Products List.
 **/

export const getStatusGrouping = (
    list: CartOrderEntriesWithGroupingVal[],
): Record<string, CartOrderEntriesWithFilterVal[]> => {
    const tempList: CartOrderEntriesWithFilterVal[] = []
    list.forEach(elem => {
        const { trackingID, isBulk, minETA, maxETA, fulfillment } = elem
        const strStatus = String(elem.status)

        const filterVal =
            fulfillment?.deliveryMode === CartOrderDeliveryModes.STH
                ? `${strStatus}_${trackingID}${String(isBulk)}${minETA}${maxETA}`
                : `${strStatus}${minETA}${maxETA}`
        tempList.push({
            filterVal: filterVal,
            ...elem,
        })
    })
    /**
     * @method groupBy: Groups the products by unique filterVal
     * @param productList passes the List of products to be grouped
     * @param key unique key to filter
     * @return {result} filtered grouped Object
     **/

    const groupBy = (
        productList: CartOrderEntriesWithFilterVal[],
        key: string,
    ): Record<string, CartOrderEntriesWithFilterVal[]> => {
        const initialValue: Record<string, CartOrderEntriesWithFilterVal[]> = {}
        return productList.reduce((result, currentValue) => {
            const resultKey = currentValue[key] as string
            if (!result[resultKey]) {
                result[resultKey] = []
            }
            result[resultKey].push(currentValue)
            return result
        }, initialValue)
    }
    return groupBy(tempList, 'filterVal')
}
export const getMinMaxEta = (orderEntry: CartOrderEntries): Record<string, unknown> | null => {
    const fulfillment = orderEntry?.fulfillment
    const sourceTypes = fulfillment?.sourceTypes
    if (sourceTypes) {
        let minETA = sourceTypes[MagicNumber.ZERO]?.etaEarliest
            ? new Date(sourceTypes[MagicNumber.ZERO]?.etaEarliest)
            : undefined
        let maxETA = sourceTypes[MagicNumber.ZERO]?.etaLatest
            ? new Date(sourceTypes[MagicNumber.ZERO]?.etaLatest)
            : undefined
        if (fulfillment?.sourceTypes.length > MagicNumber.ONE) {
            const dcMinETA = sourceTypes[MagicNumber.ONE]?.etaEarliest
                ? new Date(sourceTypes[MagicNumber.ONE]?.etaEarliest)
                : undefined
            const dcMaxETA = sourceTypes[MagicNumber.ONE]?.etaLatest
                ? new Date(sourceTypes[MagicNumber.ONE]?.etaLatest)
                : undefined
            minETA = minETA?.getTime() < dcMinETA?.getTime() ? minETA : sourceTypes[MagicNumber.ONE]?.etaEarliest
            maxETA = maxETA?.getTime() > dcMaxETA?.getTime() ? maxETA : sourceTypes[MagicNumber.ONE]?.etaLatest
        }
        return {
            minETA: minETA,
            maxETA: maxETA,
        }
    }
    return null
}
