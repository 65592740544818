export const falsyMockIndex = -1

export enum apiMethods {
    'GET' = 'GET',
    'POST' = 'POST',
    'PATCH' = 'PATCH',
    'PUT' = 'PUT',
    'DELETE' = 'DELETE',
}

// brand pages query param x1 vales
export const searchPageTypes = {
    brandlisting: { x1: 'brand' },
    promolisting: { x1: 'promo' },
    eventlisting: { x1: 'eventList' },
    eventlanding: { x1: 'eventLand' },
}

export const bannersProdOriginMap: Record<string, string> = {
    CTR: 'https://www.canadiantire.ca',
    PTY: 'https://www.partycity.ca',
}

export const REQUEST_CASHING_DURATION = 10000
