import { createAction } from 'typesafe-actions'

import {
    LinkMasterCardSuccessResponse,
    LinkMasterCardErrorResponse,
    LinkMasterCardRequest,
} from '../models/linkMasterCard.interface'
import {
    LINK_MASTER_CARD_SUCCESS,
    LINK_MASTER_CARD_FAILURE,
    SAVE_MASTER_CARD_ENTRY,
    CLEAR_LINK_MASTER_CARD_RESPONSE,
    CLEAR_LINK_MASTER_CARD_ERROR,
} from '../types/linkCard/linkMasterCard.actionTypes.constant'

export const linkMasterCardSuccessAction = createAction(
    LINK_MASTER_CARD_SUCCESS,
    (payload: LinkMasterCardSuccessResponse) => payload,
)<LinkMasterCardSuccessResponse>()

export const linkMasterCardErrorAction = createAction(
    LINK_MASTER_CARD_FAILURE,
    (payload: LinkMasterCardErrorResponse) => payload,
)<LinkMasterCardErrorResponse>()

export const saveMasterCardEntryAction = createAction(
    SAVE_MASTER_CARD_ENTRY,
    (payload: LinkMasterCardRequest) => payload,
)<LinkMasterCardRequest>()

export const clearLinkMasterCardResponseAction = createAction(CLEAR_LINK_MASTER_CARD_RESPONSE)()
export const clearLinkMasterCardErrorAction = createAction(CLEAR_LINK_MASTER_CARD_ERROR)()
