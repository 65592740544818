import { createAction } from 'typesafe-actions'

import {
    FETCH_TRIANGLE_TRANSACTION_HISTORY_SUCCESS,
    FETCH_TRIANGLE_TRANSACTION_HISTORY_ERROR,
} from '../types/profile/triangleTransactionHistory.actionTypes.constant'
import { TriangleTransactionResponse, ErrorResponse } from '../models/triangleTransactionHistory.interface'

export const fetchTriangleTransactionSuccessAction = createAction(
    FETCH_TRIANGLE_TRANSACTION_HISTORY_SUCCESS,
)<TriangleTransactionResponse>()

export const fetchTriangleTransactionErrorAction = createAction(
    FETCH_TRIANGLE_TRANSACTION_HISTORY_ERROR,
)<ErrorResponse>()
