import React from 'react'

import { PREFIX } from '../config'
import Icon from '../Icon'
import { ProductImageComponentProps } from './ProductItemInfo.type'

const ProductImageComponent: React.FC<ProductImageComponentProps> = ({ ...props }) => {
    const {
        itemAvailabilityInfo,
        isService,
        a11yServiceInstallation,
        servicesIcon,
        images,
        changeBrowserBackUrl,
        hideQuantitySelector,
        url,
        isUnfinishedPackage,
        name,
    } = props
    const primaryImage = Array.isArray(images) ? images[0] : images
    const { grayOutImage } = itemAvailabilityInfo || {}
    const cartClass = `${PREFIX}-shopping-cart__item`
    const availabilityInfoClass = grayOutImage ? `${cartClass}__image-container--gray-out` : ''
    const unfinishedPackage = isUnfinishedPackage ? `${cartClass}__image-container--package` : ''

    /**
     * function to render service icon component
     * @return {JSX.Element}
     */
    const rendersServiceIconComp = (): JSX.Element => {
        return isService ? (
            <div className={`${cartClass}__service-icon-container`}>
                <div className={`${cartClass}__service-icon`} aria-label={a11yServiceInstallation}>
                    <Icon type={servicesIcon} size="lg" />
                </div>
            </div>
        ) : url ? (
            <a
                data-testid="render-service-btn"
                href={url}
                onClick={() => {
                    hideQuantitySelector && changeBrowserBackUrl()
                }}
                aria-hidden="true"
                tabIndex={-1}>
                <img className={`${PREFIX}-product-img`} src={primaryImage?.url} alt={name || primaryImage?.altText} />
            </a>
        ) : (
            <img className={`${PREFIX}-product-img`} src={primaryImage?.url} alt={name || primaryImage?.altText} />
        )
    }

    return (
        <div
            className={`${cartClass}__image-container ${PREFIX}-col-md-3 ${PREFIX}-col-sm-3 ${availabilityInfoClass} ${unfinishedPackage}`}>
            {rendersServiceIconComp()}
        </div>
    )
}

export default ProductImageComponent
