import PageHelper from './page.helper'

/**
 * @class {BrandPageHelper}
 */
export class BrandPageHelper extends PageHelper {
    /**
     * constructor which get and save categories
     */
    constructor() {
        super()
        this.categories = this.churnCategories()
    }

    /**
     * This function split link on categories
     * @return {string[]} categories
     */
    churnCategories(): string[] {
        const pathNameArr = window.location.pathname.split('/all-brands/') // OCCP-13088 brand path from /brands/ to /all-brands/
        const lastPath = pathNameArr.pop()
        if (lastPath?.includes('.html')) {
            return lastPath.split('.html')[0].split('/')
        } else {
            return []
        }
    }
}

export default BrandPageHelper
