import { StatusType, LabelType, ButtonStatusType } from './getButtonStatus.type'
import { statusLabel } from '../../globalConstants/global.constant'

/**
 * @param {string} status - callback function
 * @param {StatusType} vals - time interval
 * @param {LabelType} labels - getter function
 * @return {ButtonStatusType}
 */
export const getButtonStatus = (status: string, vals: StatusType, labels: LabelType): ButtonStatusType => {
    const { activated, activate, redeemed, allActivated } = vals
    const { activatedLabel, activateLabel, redeemedLabel, allActivatedLabel } = labels
    const lowerCase = (item: string) => item?.toLocaleLowerCase()
    const { activateStatus, activatedStatus, redeemedStatus } = statusLabel
    const confirmIcon = 'ct-confirm'

    switch (lowerCase(status)) {
        case lowerCase(activatedStatus):
            return {
                class: lowerCase(activated),
                label: activatedLabel,
                icon: confirmIcon,
            }

        case lowerCase(activateStatus):
            return {
                class: lowerCase(activate),
                label: activateLabel,
                icon: 'ct-plus',
            }

        case lowerCase(redeemedStatus):
            return {
                class: lowerCase(redeemed),
                label: redeemedLabel,
                icon: 'ct-gift',
            }

        case lowerCase(allActivatedLabel):
            return {
                class: lowerCase(allActivated),
                label: allActivatedLabel,
                icon: confirmIcon,
            }
    }
}
