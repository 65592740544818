import { BaseProvider, AnalyticsRecordInterface } from '../providers/provider.type'
import { ReactComponentInterface } from './component.type'

/**
 *  Abstract class for all react components that require analytics implementation
 */
export abstract class ReactComponentAnalytics implements ReactComponentInterface {
    /**
     * @param  {BaseProvider} public analyticsProvider
     */
    constructor(public analyticsProvider: BaseProvider) {}

    /**
     * @param  {AnalyticsRecordInterface} data
     */
    push(data: AnalyticsRecordInterface): void {
        this.analyticsProvider.push(data)
    }

    /**
     * Function to prepare base analytics state for a page
     * TODO: any need to removed
     * @param  {any} reduxState
     * @returns void
     */
    abstract prepareBaseAnalyticsState(reduxState: any): void
}
