import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon/Icon'
import { PREFIX } from '../config'
import { switchProps } from './Switch.type'

/**
 * Switch component
 * @param {switchProps} props
 * @return {JSX.Element} returns Label,Icon and checkbox
 */
const Switch: React.FC<switchProps> = props => {
    const { checked, label, icon, path, handleInputChange, mainClass } = props
    /* Have added empty span for focus reason is we are using before for ellipse
     cant add focus on before and wont to change border of ellipse.*/
    return (
        <>
            <div className={mainClass}>
                <label className={`${PREFIX}-switch`}>
                    {label}
                    <input
                        className={`${PREFIX}-switch-${checked ? 'on' : 'off'}`}
                        type="checkbox"
                        role="switch"
                        data-testid="toggle-switch"
                        checked={checked}
                        onClick={handleInputChange}
                    />
                    <span className={`${PREFIX}-switch__slider`}>
                        <span className={`${PREFIX}-switch__slider-focus`}></span>
                        {checked && <Icon size="xs" type="ct-checkmark" path={path} />}
                    </span>
                </label>
            </div>
            {icon && <Icon type={icon} size="sm" path={path} />}
        </>
    )
}

Switch.defaultProps = {
    label: '',
    icon: '',
    mainClass: '',
}
Switch.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
    handleInputChange: PropTypes.func,
    mainClass: PropTypes.string,
}

export default Switch
