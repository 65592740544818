import { fetchInterceptorErrorAction } from '../../../actionCreators/fetchInterceptorError.actionCreators'
import interceptorConfig from '../../../../services/ResponseInterceptors/interceptorConfig.service'
import injectStoreInInterceptor from '../injectStoreInInterceptor'
import { excludeHttpStatusCodes } from './../../../../globalConstants/global.constant'

export const httpResponseInterceptor = (
    error: Record<string, unknown>,
    resolve: (value: Record<string, unknown>) => void,
): void => {
    const store = injectStoreInInterceptor.getStoreValue()
    // TODO: This condition may be changed once backend configuration sub task is done
    if (!excludeHttpStatusCodes.includes(error?.status) && error?.data?.errorCode) {
        store.dispatch(fetchInterceptorErrorAction(error))
        interceptorConfig.setInterceptorTimeoutList({ resolveFunc: resolve })
    } else resolve(error)
}
