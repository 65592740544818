import React, { useCallback, useState } from 'react'

import Button from '../Button'
import { AutoProductCartItemProps, iService } from '../ProductCartItem/ProductItemInfo.type'
import AutomotiveAddonCard from './AutomotiveAddonCard'

const AutomotiveAddonsList = (props: AutoProductCartItemProps): JSX.Element => {
    const [selectedProducts, setSelectedProducts] = useState(props.addons)

    /**
     * function to set the selected hardware addons list
     */
    const selectService = useCallback(
        (selectedSku: string) => {
            setSelectedProducts(prevstate => [...prevstate, props.addons.find(product => product?.sku === selectedSku)])
        },
        [props.addons],
    )
    /**
     * function to remove hardware addons after unchecking
     */
    const removeService = useCallback(
        (selectedSku: string) => {
            const updatedArray = selectedProducts.filter(item => {
                return item?.sku !== selectedSku
            })
            setSelectedProducts([...updatedArray])
        },
        [selectedProducts],
    )
    const addServiceToCartHandler = (): void => {
        props.serviceAddToCartHandler(selectedProducts, props.productData)
        props.hideAccordion(true)
    }

    return (
        <>
            {props.addons.map((addon: iService, index) => (
                <AutomotiveAddonCard
                    hideQuantitySelector={props.hideQuantitySelector}
                    key={index}
                    brand={addon.brand}
                    url={addon.url}
                    images={addon.images}
                    sku={addon.sku}
                    price={addon.price}
                    name={addon.name}
                    PDPLink={props.PDPLink}
                    language={props.language}
                    selectService={selectService}
                    removeService={removeService}
                    quantity={addon.maxQuantity}
                    dpBuyAgainLabel={props.dpBuyAgainLabel}
                    isDetailPage={props.isDetailPage}
                    qtyAbbrLabel={props.qtyAbbrLabel}
                />
            ))}
            {!props.hideQuantitySelector && selectedProducts.length ? (
                <Button type="primary_reverse" size="medium" onClick={addServiceToCartHandler}>
                    {props.hardwareAddToCartCTALabel}
                </Button>
            ) : null}
        </>
    )
}

export default AutomotiveAddonsList
