import { Dispatch } from 'redux'
import { AxiosError } from 'axios'

import { getEnvironment } from '../../environments'
import getHttpClient from '../../httpClient'
import {
    earnCtMoneyAction,
    resetEarnCreditCardAction,
    earnCtMoneyErrorAction,
} from '../actionCreators/triangleRewardsUpsell.actionCreators'
import localStorageService from '../../utils/localStorageService'
import { HttpReqHeaders } from '../utils/httpClient.type'
import { EaryCtMoneyType, ErrorResponse } from '../models/upsellingRewards.interface'
import { setShowSpinner } from '../actionCreators'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * earn ctmoney from an triangle credit card
 * @param {EaryCtMoneyType} payload
 * @return {Promise<void>}
 */
export const earnPotentialCTM =
    (payload: EaryCtMoneyType) =>
    (dispatch: Dispatch): Promise<void> => {
        const gigyaJWTToken = localStorageService.getItem('gigya.JWT') as string
        const headers: HttpReqHeaders = {
            authorization: `Bearer ${gigyaJWTToken}`,
        }
        const url = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.earnCtMoney}`

        return httpClient
            .apiPost(url, { ...payload }, headers, true)
            .then(resp => {
                dispatch(earnCtMoneyAction(resp?.status))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                const errorResponse = error?.response ? error.response.data : error
                dispatch(earnCtMoneyErrorAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * reset credit card
 * @return {Dispatch}
 */
export const resetVerifyCreditCard =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetEarnCreditCardAction())
    }
