import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { CommonWarningToastProps } from '../CommonCard/CommonCard.type'
import Icon from '../Icon'
import Button from '../Button'
import { addModalAttribute } from '../../utils/modalAccessibility'

/**
 * CommonWarningToast component
 * @param {CommonWarningToastProps} props
 * @return {JSX.Element} returns CommonWarningToast component
 */
const CommonWarningToast: React.FC<CommonWarningToastProps> = ({ ...props }) => {
    /**
     * Function to open the store modal and add focus to the triggered element.
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - button click event.
     */
    const toastCTAClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        addModalAttribute(event)
        props.toastCTAHandler && props.toastCTAHandler(true)
        props.actionCTAHandler && props.actionCTAHandler()
    }

    const serviceAddOnClass = props.isAddOn ? `${PREFIX}-warning-toast--add-on` : ''

    return (
        <div
            className={`${PREFIX}-warning-toast ${PREFIX}-warning-toast__${props.bgType} ${serviceAddOnClass}`}
            role="alert"
            aria-atomic="true">
            <div className={`${PREFIX}-warning-toast__text-container`}>
                <span className={`${PREFIX}-warning-toast__icon`}>
                    <Icon type={props.icon} size="lg" />
                </span>
                {props.label !== '' ? (
                    <p className={`${PREFIX}-warning-toast__label`}>{props.label}</p>
                ) : (
                    props.children
                )}
            </div>
            {!!props.checkNearByText && (
                <Button type="tertiary" size="mini" onClick={toastCTAClickHandler} buttonType="button">
                    {props.checkNearByText}
                </Button>
            )}
        </div>
    )
}

CommonWarningToast.propTypes = {
    icon: PropTypes.string,
    bgType: PropTypes.string,
    label: PropTypes.string,
    checkNearByText: PropTypes.string.isRequired,
    toastCTAHandler: PropTypes.func.isRequired,
    isAddOn: PropTypes.bool,
    actionCTAHandler: PropTypes.func,
    children: PropTypes.element,
}

export default CommonWarningToast
