import { magicNumber } from '@nl/lib'
import { DropdownOptions, DropdownOptionsState } from '../models/vehicleDropdowns.types'

/**
 * Returns correct state for current dropdown base on previous state and API data.
 * @param {DropdownOptions[]} newOptions
 * @param {DropdownOptions[]} oldOptions
 * @param {boolean} isSuggestedAttribute
 * @param {string} value
 * @param {DropdownOptionsState} previousOptionsState
 * @return {DropdownOptionsState}
 */
export const calculateOptionState = (
    newOptions: DropdownOptions[] | undefined,
    oldOptions: DropdownOptions[] | undefined,
    isSuggestedAttribute: boolean,
    value: string,
    previousOptionsState?: DropdownOptionsState,
) => {
    let optionsState: DropdownOptionsState = previousOptionsState || DropdownOptionsState.NOT_LOADED

    switch (true) {
        // To don't change state for previous preselected attribute without suggestions
        case optionsState === DropdownOptionsState.PRESELECTED:
            break
        // To don't change state for previous skipped attribute
        case optionsState === DropdownOptionsState.TO_SKIP:
            break
        // To mark like preselected after the load for new values
        case optionsState === DropdownOptionsState.PRESELECTED_AND_UPDATED:
            optionsState = DropdownOptionsState.PRESELECTED
            break
        // New version API case - if option has one value, that API gives it in known and suggested and suggestions for the next attribute. This single option dropdown should be skipped
        case isAttributePrePopulatedByAPIDueToSingleOption(value, isSuggestedAttribute, newOptions):
            optionsState = DropdownOptionsState.TO_SKIP
            break
        // If dropdown option was load in previos call
        // Don't replace order - if old options was loaded before it is more hight priority to mark load then skip.
        case isOldLoadedOptions(oldOptions):
            optionsState = DropdownOptionsState.LOADED
            break
        // Cover not rendering dropdown for attributes like driveTypeId, subModel, etc. what will appear for selected vehicle on PDP
        case isAttributePopulatedIntoKnownsWithOutSuggestionByAPI(value, isSuggestedAttribute, newOptions):
            optionsState = DropdownOptionsState.TO_SKIP
            break
        // If a attribute gets options and it is not skipped - it is marked like loaded
        case newOptions?.length > 0:
            optionsState = DropdownOptionsState.LOADED
            break
        case optionsState === DropdownOptionsState.DELETED:
            optionsState = DropdownOptionsState.NOT_LOADED
            break
    }

    return optionsState
}

const isAttributePrePopulatedByAPIDueToSingleOption = (
    value: string,
    isSuggestedAttribute: boolean,
    newOptions: DropdownOptions[] | undefined,
) => Boolean(value && isSuggestedAttribute && newOptions?.length === magicNumber.ONE)

const isAttributePopulatedIntoKnownsWithOutSuggestionByAPI = (
    value: string,
    isSuggestedAttribute: boolean,
    newOptions: DropdownOptions[] | undefined,
) => Boolean(value && isSuggestedAttribute && !newOptions)

const isOldLoadedOptions = (oldOptions: DropdownOptions[] | undefined) => Boolean(oldOptions && oldOptions.length > 0)
