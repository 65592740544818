import { createAction } from 'typesafe-actions'

import { OrderHistory, ErrorResponse } from '../models/orderHistory.interface'
import {
    GET_ORDER_HISTORY,
    HIDE_RECOMMENDATION_COMPONENT,
    ORDER_HISTORY_FAILURE,
} from '../types/orderHistory/orderHistory.actionTypes.constant'

export const fetchOrderHistoryAction = createAction(GET_ORDER_HISTORY)<OrderHistory>()

export const hideRecommendation = createAction(HIDE_RECOMMENDATION_COMPONENT, (payload: boolean) => payload)<boolean>()

export const orderHistoryFailure = createAction(ORDER_HISTORY_FAILURE)<ErrorResponse>()
