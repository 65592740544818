import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/linkLoyaltyCard.actionCreators'
import {
    creditCardAssociationSuccessAction,
    creditCardAssociationErrorAction,
    mergeLoyaltySuccessAction,
    mergeLoyaltyErrorAction,
    clearCreditCardAssociationError,
    clearMergeLoyaltyError,
    clearMergeLoyaltyCardAction,
} from '../actionCreators/mergeLoyaltyCard.actionCreators'
import { MergeData, ErrorResponse } from '../models/mergeLoyaltyCard.interface'

type Action = ActionType<typeof actions>

export interface MergeLoyaltyCardState {
    creditCardAssociation: MergeData | null
    creditCardAssociationError: ErrorResponse | null
    mergeLoyaltyCard: number | null
    mergeLoyaltyCardError: ErrorResponse | null
}

export const initialState: MergeLoyaltyCardState = {
    creditCardAssociation: {} as MergeData,
    creditCardAssociationError: {} as ErrorResponse,
    mergeLoyaltyCard: null,
    mergeLoyaltyCardError: {} as ErrorResponse,
}

export const mergeLoyaltyCardReducer = (
    state: MergeLoyaltyCardState = initialState,
    action: Action,
): MergeLoyaltyCardState => {
    switch (action.type) {
        case getType(creditCardAssociationSuccessAction): {
            return { ...state, creditCardAssociation: action.payload as MergeData }
        }
        case getType(creditCardAssociationErrorAction): {
            return { ...state, creditCardAssociationError: action.payload as ErrorResponse }
        }
        case getType(clearCreditCardAssociationError): {
            return { ...state, creditCardAssociation: null, creditCardAssociationError: null }
        }
        case getType(mergeLoyaltySuccessAction): {
            return { ...state, mergeLoyaltyCard: action.payload as number }
        }
        case getType(mergeLoyaltyErrorAction): {
            return { ...state, mergeLoyaltyCard: null, mergeLoyaltyCardError: action.payload as ErrorResponse }
        }
        case getType(clearMergeLoyaltyError): {
            return { ...state, mergeLoyaltyCard: null, mergeLoyaltyCardError: null }
        }
        case getType(clearMergeLoyaltyCardAction): {
            return { ...state, creditCardAssociation: null, mergeLoyaltyCard: null }
        }
        default:
            return state
    }
}
