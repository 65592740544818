import { createAction } from 'typesafe-actions'

import { ToastDetails, ToastMessage } from '../models/toastMessage.interface'
import {
    SET_TOAST_AUTH_STATUS,
    SET_TOAST_DETAILS,
    SET_TOAST_MESSAGE_DATA,
} from '../types/products/toastMessage.actionTypes.constant'

export const setToastMessageDataAction = createAction(SET_TOAST_MESSAGE_DATA)<ToastMessage>()

export const setToastDetailsAction = createAction(SET_TOAST_DETAILS)<ToastDetails>()

export const setToastAuthStatusAction = createAction(SET_TOAST_AUTH_STATUS)<boolean>()
