import { libUtils } from '@nl/lib'
import { IGlobalProps } from '../redux/models/commonContent.interface'
import sessionStorageService from './sessionStorageService'
const language = libUtils.getLanguage()
/**
 * function to get enableSingleSignOn value from featureFlag in global_props
 * @return {boolean}
 */
export const getEnableSingleSignOn = (): boolean => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    let hasContent
    if (commonContent) {
        try {
            hasContent = JSON.parse(commonContent) as IGlobalProps
        } catch (error) {
            console.log(error)
        }
    }
    return hasContent?.featureFlag?.enableSingleSignOn as boolean
}
