import { createAction } from 'typesafe-actions'
import { CartResponseErrorDTO } from '../models/cart.interface'
import { iSaveForLater, iSaveForLaterEntry } from '../models/SaveForLater.interface'
import {
    GET_SAVE_FOR_LATER_LIST_SUCCESS,
    GET_SAVE_FOR_LATER_LIST_FAILURE,
    REMOVE_SAVE_FOR_LATER_FAILURE,
    GET_SAVE_FOR_LATER_UPDATED_WISHLIST,
} from '../types/saveForLater/saveForLater.actionTypes.constants'

export const saveForLaterListSuccess = createAction(
    GET_SAVE_FOR_LATER_LIST_SUCCESS,
    (payload: iSaveForLater) => payload,
)<iSaveForLater>()

export const saveForLaterListFailure = createAction(GET_SAVE_FOR_LATER_LIST_FAILURE)<CartResponseErrorDTO>()

export const removeSaveForLaterFailure = createAction(REMOVE_SAVE_FOR_LATER_FAILURE)<CartResponseErrorDTO>()

export const getNewWishListState = createAction(GET_SAVE_FOR_LATER_UPDATED_WISHLIST)<iSaveForLaterEntry[]>()
