import { magicNumber } from './../../utils/magicNumber'
import { productDataRefType } from './ProductCard.type'

/**
 * function to get secondary image of a product
 * @param {productDataRefType} item
 * @return {productDataRefType} returns secondary image
 */
export const getSecondaryImage = (item: productDataRefType): productDataRefType => {
    const { images } = item
    const secondaryImage = Array.isArray(images) && images.length > magicNumber.ONE ? images[magicNumber.ONE].url : ''
    return { ...item, secondaryImage: secondaryImage }
}
