import React from 'react'
import PropTypes from 'prop-types'
import { UrgencyMessageType } from './UrgencyMessage.type'
import localization from '../../helpers'
import { getETATimeOptions } from '../../utils/getETATimeOptions'
import { getLanguage } from '../../utils'

/* UrgencyMessage component
 * @param {UrgencyMessageType} props
 * @return {JSX.Element} returns UrgencyMessage component
 */
const UrgencyMessage: React.FC<UrgencyMessageType> = ({ ...props }): JSX.Element => {
    const { saleEndDaySoonMessage, salePriceEndDate, saleEndDisableShift = false } = props

    const baseOptions = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    }
    const dateOptions = saleEndDisableShift
        ? baseOptions
        : {
              ...baseOptions,
              timeZone: 'UTC',
          }

    return (
        <>
            <strong>{saleEndDaySoonMessage}</strong>{' '}
            {localization.formattedDate(getETATimeOptions(dateOptions), salePriceEndDate, getLanguage())}
        </>
    )
}

UrgencyMessage.propTypes = {
    saleEndDaySoonMessage: PropTypes.string,
    salePriceEndDate: PropTypes.string,
    saleEndDisableShift: PropTypes.bool,
}

export default UrgencyMessage
