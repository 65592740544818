import React, { useState, useRef } from 'react'

import Icon from '../Icon'
import Tooltip from '../Tooltip'
import { PREFIX } from '../config'
import { TooltipType } from '../LineSummary/LineSummary.type'

const SummaryTooltip: React.FC<TooltipType> = ({ ...props }): JSX.Element => {
    const { title, desc, a11yTooltipIcon, ariaDescribedbyId, a11yCloseIconLabel, tooltipIcon, isTooltipPreventEvent } =
        props

    const [visibility, setVisibility] = useState(false)

    const buttonRef = useRef(null)

    const toolTipButtonHandler = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        visibilitySetter: (value: React.SetStateAction<boolean>) => void,
        currentVisibility: boolean,
    ) => {
        if (isTooltipPreventEvent) {
            e.preventDefault()
        } else {
            e.stopPropagation()
        }
        visibilitySetter(!currentVisibility)
    }

    const toolTipText = (tooltipDesc: string): JSX.Element => {
        return <div data-testid="tooltip-text" dangerouslySetInnerHTML={{ __html: tooltipDesc }}></div>
    }

    /**
     * this function renders when clicking tooltip button visibility gets true then this tooltip view shows to user
     * component created as resuable, this component is used in lineSummary component in lib.
     * @return { JSX.Element }
     */
    const renderTooltipView = (): JSX.Element => {
        return (
            !!visibility && (
                <Tooltip
                    visibility={visibility}
                    setVisibility={setVisibility}
                    iconID="ct-close"
                    headerText={title}
                    bodyText={toolTipText(desc)}
                    coords={buttonRef.current as unknown as HTMLElement}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                />
            )
        )
    }

    return (
        <>
            <button
                ref={buttonRef}
                className={`${PREFIX}-summary__tooltip-btn`}
                onClick={event => toolTipButtonHandler(event, setVisibility, visibility)}
                data-testid="summary-fee"
                aria-label={a11yTooltipIcon}
                aria-describedby={ariaDescribedbyId}>
                <span className={visibility ? `${PREFIX}-summary__tooltip-btn--active` : ''}>
                    <Icon type={tooltipIcon ? tooltipIcon : 'ct-information-grey'} size="md" />
                </span>
            </button>
            {renderTooltipView()}
        </>
    )
}

export default SummaryTooltip
