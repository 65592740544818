import Shiitake from 'shiitake'

import React from 'react'
import PropTypes from 'prop-types'
import { ClampLinesEllipsisProps } from './ClampLinesEllipsis.types'

/**
 * ClampLinesEllipsis component
 * @param {ClampLinesEllipsisProps} props
 * @return {JSX.Element} returns ClampLinesEllipsis component
 */
const ClampLinesEllipsis: React.FC<ClampLinesEllipsisProps> = ({
    text,
    lines,
    className,
    innerElement,
}): JSX.Element => {
    const props = {
        lines,
        throttleRate: 200,
        className,
        tagName: `${innerElement}`,
    }
    return React.createElement(Shiitake, props, text)
}
ClampLinesEllipsis.propTypes = {
    text: PropTypes.string.isRequired,
    lines: PropTypes.number.isRequired,
    className: PropTypes.string,
    innerElement: PropTypes.string,
}
export default ClampLinesEllipsis
