import React from 'react'

import { BreadcrumbProps } from './Breadcrumb.type'
import { breadcrumbClassName, listClassName } from './Breadcrumb.constant'
import { BreadcrumbLink } from './BreadcrumbLink'
import { isLastLinkAndNotInteractive } from './Breadcrumb.helpers'

/**
 * Breadcrumb component
 * @param {BreadcrumbProps} props - links
 * @return {JSX.Element[]} returns Breadcrumb Links
 */
const Breadcrumb: React.FC<BreadcrumbProps> = ({ links, showPipeIcon, isLastElementInteractive, breadCrumbLabel }) => (
    <nav className={breadcrumbClassName} aria-label={breadCrumbLabel}>
        <ol className={listClassName}>
            {links.map(({ link, name, pName }, index) => {
                const isLastAndNotInteractive = isLastLinkAndNotInteractive(
                    links.length,
                    index,
                    isLastElementInteractive,
                )

                return (
                    <BreadcrumbLink
                        key={link}
                        link={link}
                        name={name}
                        isLastLinkAndNotInteractive={isLastAndNotInteractive}
                        pName={pName}
                        showPipeIcon={showPipeIcon}
                    />
                )
            })}
        </ol>
    </nav>
)

export default Breadcrumb
