import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Icon from '../Icon'
import { InlineToastProps, InlineToastType } from './InlineToast.type'

/**
 * InlineToast component
 * @param {InlineToastProps} props
 * @return {JSX.Element} returns InlineToast component
 */
const InlineToast: React.FC<InlineToastProps> = ({ ...props }) => {
    const cssClass = `${PREFIX}-inline-toast`
    return (
        <div className={`${cssClass} ${cssClass}--${props.type}`} aria-atomic="true">
            <div className={`${PREFIX}-row ${PREFIX}-xs-align-items-center ${cssClass}__text-container`}>
                <span className={`${cssClass}__icon`}>
                    <Icon type={props.icon} size="lg" />
                </span>
                <p className={`${cssClass}__label`} dangerouslySetInnerHTML={{ __html: props.label }} />
            </div>
        </div>
    )
}

InlineToast.propTypes = {
    icon: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        InlineToastType.SUCCESS,
        InlineToastType.ERROR,
        InlineToastType.WARNING,
        InlineToastType.INFO,
    ]).isRequired,
    label: PropTypes.string.isRequired,
}

export default InlineToast
