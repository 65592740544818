import { PREFIX } from '../../components/config'
import { MenuAccessibility } from './MenuAccessibility'
import { menuConstantsObject } from './MenuAccessibility.constant'
import { ActionType, MenuBarAccessibilityRequestParams } from './MenuAccessibility.type'

/**
 * Util function to add menu accessibility to the nav bar.
 * Implemented as per the guidelines mentioned in https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
 * @function
 * @author Yashwanth Korla
 * @return {MenuBarAccessibilityRequestParams} init, destroy methods.
 */
export const menuBarAccessibility = (): MenuBarAccessibilityRequestParams => {
    /**
     * Entire Menu Bar Element
     * @type {HTMLElement}
     * @private
     * @property
     */
    let menuBarElement: HTMLElement
    /**
     * Contains the a tags of the menu bar
     * @type {HTMLAnchorElement[]}
     * @private
     * @property
     */
    let menuItems: HTMLAnchorElement[]

    /**
     * Class name
     * @constant {string}
     */
    const megaNavClassName = `.${PREFIX}-mega-navigation-container`
    /**
     * @constant {string}
     */
    const ariaExpandedName = 'aria-expanded'

    /**
     * Extract all the anchors from the menuBar.
     * @method
     * @private
     * @return {HTMLElement[]}
     */
    const _extractMenuBarItems = (): HTMLElement[] => {
        return Array.from(menuBarElement.children).reduce((acc, curr) => {
            acc.push(curr.querySelector('a'))
            return acc as HTMLElement[]
        }, []) as HTMLElement[]
    }

    /**
     * Function which listens to few keyboard events.
     * * If right, move focus to next item i.e. 1-2
     * * If left, move focus to previous item i.e. 2-1
     * * if down arrow, open the menu list and set the focus to first item in the menu list.
     * * if up arrow, open the menu list and set the focus to last item in the menu list.
     * * if escape is pressed in the menu list closed the menu list and set the focus to menu bar item.
     * * if escape is pressed on the menu bar item don't do any thing.
     * @method
     * @private
     * @param {KeyboardEvent} event
     */
    const _addKeyBoardFunctionality = (event: KeyboardEvent): void => {
        const targetElement = event.target
        switch (event.key) {
            case 'ArrowRight':
                _setFocusToNextItem(targetElement as HTMLAnchorElement, ActionType.NEXT)
                return
            case 'ArrowLeft':
                _setFocusToNextItem(targetElement as HTMLAnchorElement, ActionType.PREVIOUS)
                return
            case 'ArrowDown':
                _openMenuList(targetElement as HTMLElement, ActionType.DOWN_ARROW)
                return
            case 'ArrowUp':
                _openMenuList(targetElement as HTMLElement, ActionType.UP_ARROW)
                return
            case 'Escape':
                const element = targetElement as HTMLElement
                if (!element.hasAttribute(ariaExpandedName) && element.getAttribute(ariaExpandedName) !== 'false') {
                    const megaNav = (event.target as HTMLElement).offsetParent as HTMLElement
                    megaNav.removeAttribute('style') // doing this to remove added styles.
                    const anchorTag = megaNav.parentElement.previousSibling as HTMLElement
                    anchorTag.focus()
                    _setAriaExpanded(anchorTag, false)
                    new MenuAccessibility(megaNav).destroy()
                }
                return
            default:
                return
        }
    }

    /**
     * Function to open menu list on a menu item.
     * @method
     * @private
     * @param {HTMLElement} currentElement
     * @param {ActionType} actionType
     */
    const _openMenuList = (currentElement: HTMLElement, actionType: ActionType): void => {
        if (_doesElementHasMenuList(currentElement)) {
            _setAriaExpanded(currentElement, true)
            _enableMenuAccessibility(actionType === ActionType.UP_ARROW, currentElement)
        }
    }

    /**
     * Function to add menu Accessibility as per https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html.
     * @method
     * @private
     * @param {boolean} selectLastItem - if true, sets the tab focus on last item and false, sets the focus on first item.
     * @param {HTMLElement} currentElement - current focused element.
     */
    const _enableMenuAccessibility = (selectLastItem: boolean, currentElement: HTMLElement): void => {
        new MenuAccessibility(
            currentElement.parentElement.querySelector(megaNavClassName) as unknown as HTMLElement,
        ).init(selectLastItem)
    }

    /**
     * Function to set aria-expanded attribute value.
     * @method
     * @private
     * @param {HTMLElement} currentElement - current focused element.
     * @param {boolean} isExpanded
     */
    const _setAriaExpanded = (currentElement: HTMLElement, isExpanded: boolean): void => {
        currentElement.setAttribute(ariaExpandedName, `${String(isExpanded)}`)
    }

    /**
     * Function to check if mega nav is attached to the menu item or not
     * @method
     * @private
     * @param {HTMLElement} currentElement
     * @return {boolean}
     */
    const _doesElementHasMenuList = (currentElement: HTMLElement): boolean => {
        return currentElement.getAttribute('aria-haspopup') === 'true'
    }

    /**
     * Function to set focus to next item in the menu bar items.
     *
     * * If pressed next will go the next item i.e. from 1 - 2.
     * * If pressed left arrow will go the previous item i.e from 2 - 1
     * * If pressed right on the last item it will go to first item i.e. from 4-1
     * * If pressed left on the first item it will go to the last item i.e. from 1-4
     *
     * @method
     * @private
     * @param {HTMLAnchorElement} currentEle
     * @param {ActionType} actionType
     */
    const _setFocusToNextItem = (currentEle: HTMLAnchorElement, actionType: ActionType): void => {
        const currentElementIndex = menuItems.indexOf(currentEle)
        if (_doesElementHasMenuList(currentEle)) {
            _setAriaExpanded(currentEle, false)
        }
        const menuItemsLength = menuItems.length
        if (actionType === ActionType.NEXT) {
            if (currentElementIndex === menuItemsLength - menuConstantsObject.unit_one) {
                menuItems[0].focus()
                return
            }
            menuItems[currentElementIndex + menuConstantsObject.unit_one].focus()
        } else {
            if (currentElementIndex === 0) {
                menuItems[menuItemsLength - menuConstantsObject.unit_one].focus()
                return
            }
            menuItems[currentElementIndex + menuConstantsObject.negative_unit_one].focus()
        }
    }

    /**
     * Function used to add accessibility to the menu bar.
     * @method
     * @public
     * @param {string} menuBarId
     */
    const init = (menuBarId: string): void => {
        menuBarElement = document.getElementById(menuBarId)
        menuItems = _extractMenuBarItems() as HTMLAnchorElement[]
        menuBarElement.addEventListener('keydown', _addKeyBoardFunctionality)
    }

    /**
     * Function to remove added event listeners.
     * @method
     * @public
     */
    const destroy = (): void => {
        menuBarElement.removeEventListener('keydown', _addKeyBoardFunctionality)
    }

    return {
        init,
        destroy,
    }
}
