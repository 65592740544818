import { YesNoNotApplicable } from '../analytics/providers/provider.type'
import { VehicleInformation } from '../redux/models/cart.interface'
import { FitmentTypeEnum } from '../globalConstants'

type FitmentData = {
    vehicleInformation?: VehicleInformation | null
    fitmentTypeCode?: FitmentTypeEnum
}

/**
 * This function is used to determine the value for guaranteedFitment key
 * It checks for vehicleInformation and fitmentTypeCode and determines if a product is guaranteed to fit or not
 * @template T - the type of the Fitment Data value
 * @param {T} entry - represents added cart entries
 * @return {string} returns value for guaranteedFitment key
 */

export const checkIsGuaranteedFitment = <T extends FitmentData>(entry: T): string => {
    const { vehicleInformation, fitmentTypeCode } = entry
    if (vehicleInformation && fitmentTypeCode !== null) {
        return 'true'
    } else if (vehicleInformation === null && fitmentTypeCode !== null) {
        return 'false'
    } else {
        return YesNoNotApplicable.notApplicable
    }
}
