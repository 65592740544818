import { Dispatch } from 'redux'

import {
    setShowSpinner,
    startSpinnerWithCounter as startSpinner,
    stopSpinnerWithCounter as stopSpinner,
} from '../actionCreators/spinner.actionCreators'
import {
    START_SPINNER_WITH_COUNTER,
    STOP_SPINNER_WITH_COUNTER,
    SET_SHOW_SPINNER,
} from '../types/spinner/spinner.actionTypes.constant'

export const showSpinner =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof SET_SHOW_SPINNER; payload: boolean }>): void => {
        dispatch(setShowSpinner(requestPayload))
    }

export const startSpinnerWithCounter =
    () =>
    (dispatch: Dispatch<{ type: typeof START_SPINNER_WITH_COUNTER }>): void => {
        dispatch(startSpinner())
    }

export const stopSpinnerWithCounter =
    () =>
    (dispatch: Dispatch<{ type: typeof STOP_SPINNER_WITH_COUNTER }>): void => {
        dispatch(stopSpinner())
    }
