/**
 * @param {string} svgClass - class name of the svg which u want to change the attribute of the use tag
 * @param {string} useTagAttribute - attribute name of the use tag
 */
export const preProcessSvgUseTag = (svgClass: string, useTagAttribute: string): void => {
    const jsIcon = document.getElementsByClassName(svgClass)
    Array.from(jsIcon).forEach(function (el) {
        const useTagElement = el.children[0]
        const dataIconValue = useTagElement.getAttribute(useTagAttribute) || ''
        useTagElement.setAttribute('href', dataIconValue)
    })
}
