import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    extractKeyValues,
    checkDataLength,
    EmailActivationCard,
    getCookieValue,
    parseQuery,
    SkeletonComponent,
    magicNumber,
    localeCookieName,
    getAkamaiPolicy,
} from '@nl/lib'
import { PREFIX } from '../../config'
import { ActivateTargetedOfferPayload, Offers } from '../../redux/models/offers.interface'
import { activateTargetedOffer } from '../../redux/actions'
import { lmsProfileIdParameterName, offerCodeParameterName, p1ErrorCodes } from './EmailOfferActivation.constant'
import { EmailOfferActivationType, TargetedOffer } from './EmailOfferActivation.type'
import { commonContentAvailableSelector, commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { clearOffersCacheCookie } from '../../utils/offersCache.helper'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { offersSelector } from '../../redux/selectors/offers.selectors'

const EmailOfferActivation = (props: EmailOfferActivationType): JSX.Element => {
    const dispatch = useDispatch()
    const ClassName = `${PREFIX}-offer-activation`
    const { targetedOfferActivatedData, offersActivatedError, offersData } = useSelector(offersSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const [paramSelectedOffer, setParamSelectedOffer] = useState<TargetedOffer[]>()
    const [selectedOfferArray, setSelectedOfferArray] = useState<Offers[]>([])

    const {
        badParametersErrorMessageHeader,
        badParametersErrorMessageText,
        bannerImageList,
        failedActivationImage,
        failedActivationImageAltText,
        offerEndsMessage,
        offerEndsTodayMessage,
        offerEndsDateMessage,
        shopNowCtaA11yLabel,
        shopNowCtaLabel,
        shopNowCtaLink,
        shopNowCtaLinkTarget,
        successMessageHeader,
        successMessageText,
        weeklyOffersCtaA11yLabel,
        weeklyOffersCtaLabel,
        weeklyOffersCtaLink,
        weeklyOffersCtaLinkTarget,
        multipleOffersSuccessImage,
        multipleOffersSuccessImageAltText,
        activatedLabel,
        offerImageAltText,
        offerDetailsLabel,
        multipleOfferTilesSuccessMessage,
    } = props
    const urlParams = new URLSearchParams(window.location.search)
    const offerCodeParam = urlParams.get(offerCodeParameterName)?.split(/[.!?;,:]/, 1)[0]
    const lmsProfileIdParam = urlParams.get(lmsProfileIdParameterName)
    const [[rewardsPageLink]] = extractKeyValues(commonContentAvailable, [
        {
            globalLinks: ['rewardsPageLink'],
        },
    ])

    const { loyalty } = useSelector(commonContentAvailableSelector)
    const { fallbackOfferImageUrl, moreDetailsLabel, fallbackImageForBrandLogo, fallbackImageForBrandLogoAltText } =
        loyalty || ({} as typeof commonContentAvailable.loyalty)

    const isOnManageRewardsPage = window.location.pathname === rewardsPageLink

    /**
     * Dispatch activate offer from param provided and use it as a payload
     * @param {string} offerCode - p2
     * @param {string | null} lmsProfileId - p1 (can be null or string)
     */
    const activateOffer = useCallback(
        (offerCode: string, lmsProfileId: string | null) => {
            const hasDelimeter = offerCode.includes(' ')

            const payload: ActivateTargetedOfferPayload = {
                p2: parseQuery(offerCode, hasDelimeter),
            } as ActivateTargetedOfferPayload

            const langValue = getCookieValue(localeCookieName)

            if (lmsProfileId) {
                payload.p1 = lmsProfileId
            }

            if (langValue) {
                payload.lang = langValue
            }

            dispatch(activateTargetedOffer(payload, hasDelimeter))
        },
        [dispatch],
    )

    /**
     * If user doesnt have both parameters, redirect to home page
     * Call activateOffer with the ff:
     * When a user is redirected to the Manage Rewards Page(user is authenticated) with the parameters, OR
     * When a user has both params & not in the Manage Rewards Page
     */
    useEffect(() => {
        if (isOnManageRewardsPage) {
            offerCodeParam && activateOffer(offerCodeParam, null)
        } else {
            lmsProfileIdParam && offerCodeParam && activateOffer(offerCodeParam, lmsProfileIdParam)
        }
    }, [activateOffer, offerCodeParam, lmsProfileIdParam, isOnManageRewardsPage])

    /**
     * Effect to build offer card
     */
    useEffect(() => {
        if (checkDataLength(targetedOfferActivatedData)) {
            const offerList: TargetedOffer[] = []
            targetedOfferActivatedData?.map(targetedOffer => {
                if (!targetedOffer?.aemDetails?.error || !targetedOffer?.epsilonDetails?.error) {
                    const offer = {
                        badgeImageUrl: targetedOffer?.aemDetails.badgeImageUrl,
                        code: targetedOffer?.offerCode,
                        daysLeft: targetedOffer?.epsilonDetails.daysLeft,
                        imageUrl: targetedOffer?.aemDetails.imageUrl,
                        shortDesc: targetedOffer?.aemDetails.offerShortDesc,
                        banner: targetedOffer?.epsilonDetails.banner,
                        offerEndDate: targetedOffer?.epsilonDetails.offerEndDate,
                        displayBanner: targetedOffer?.epsilonDetails.displayBanner,
                    } as TargetedOffer
                    offerList.push(offer)
                }
            })
            setParamSelectedOffer(offerList)
        }
    }, [targetedOfferActivatedData])

    useEffect(() => {
        if (checkDataLength(offersData?.offers)) {
            const offerArray = offersData?.offers.filter(selectedOffer => {
                return paramSelectedOffer?.find(offers => {
                    return offers.code === selectedOffer.offerCode
                })
            })
            setSelectedOfferArray(offerArray)
        }
    }, [paramSelectedOffer, offersData])

    useEffect(() => {
        setTimeout(() => {
            clearOffersCacheCookie()
        }, magicNumber.TWOTHOUSAND)
    })

    // checking multiple Offer by checking param length
    const multiOfferActivated = paramSelectedOffer && paramSelectedOffer?.length > 1 ? true : false

    /**
     * Display of title an subtitle of card
     * @param {boolean} isTitle - checks if info is title -- default is false
     * @return {string}
     */

    const isOfferArrayEmpty = paramSelectedOffer && paramSelectedOffer?.length === 0 ? true : false
    const renderCardInformation = (isTitle = false): string | undefined => {
        if (checkDataLength(paramSelectedOffer)) {
            return isTitle ? successMessageHeader : successMessageText
        } else if (
            checkDataLength(offersActivatedError) ||
            !offerCodeParam ||
            !lmsProfileIdParam ||
            isOfferArrayEmpty
        ) {
            return isTitle ? badParametersErrorMessageHeader : badParametersErrorMessageText
        }
    }

    const isLoyaltyIdInvalid = (): boolean => {
        if (p1ErrorCodes.includes(offersActivatedError?.errCode) || !lmsProfileIdParam) return true
        return false
    }
    // Props for email activation card for lib
    const activationProps = {
        ClassName,
        title: renderCardInformation(true),
        details: renderCardInformation(),
        isCardVisible: multiOfferActivated ? false : checkDataLength(paramSelectedOffer),
        messages: {
            dynamicMessage: offerEndsMessage,
            staticMessage: offerEndsTodayMessage,
            dynamicMessageWithDate: offerEndsDateMessage,
        },
        shopNowCtaA11yLabel,
        shopNowCtaLinkTarget,
        shopNowCtaLabel,
        shopNowCtaLink,
        weeklyOffersCtaA11yLabel,
        weeklyOffersCtaLabel,
        weeklyOffersCtaLink,
        weeklyOffersCtaLinkTarget,
        isLoyaltyIdInvalid: isLoyaltyIdInvalid(),
        failedActivationImage,
        failedActivationImageAltText,
        bannerImageList,
        multipleOffersSuccessImage,
        multipleOffersSuccessImageAltText,
        activatedLabel,
        offerImageAltText,
        offerDetailsLabel,
        multipleOfferTilesSuccessMessage,
        offerEndsMessage,
        offerEndsTodayMessage,
        offerEndsDateMessage,
        moreDetailsLabel,
    }

    const renderSkeleton = (): JSX.Element | null => {
        return (
            <div data-testid="offers-skeleton">
                <SkeletonComponent skeletonClass={`${PREFIX}-offers__skeleton`} height={'20rem'} />
            </div>
        )
    }

    const hideRenderSkeleton = (): boolean => {
        if (
            checkDataLength(paramSelectedOffer) ||
            checkDataLength(offersActivatedError) ||
            isOfferArrayEmpty ||
            !offerCodeParam ||
            !lmsProfileIdParam
        )
            return true
        return false
    }

    return (
        <>
            {hideRenderSkeleton() ? (
                <EmailActivationCard
                    {...activationProps}
                    selectedCard={paramSelectedOffer && paramSelectedOffer[0]}
                    selectedOfferArray={selectedOfferArray}
                    multiOffer={multiOfferActivated}
                    akamaiPolicy={getAkamaiPolicy(
                        AkamaiImagePolicies.returnPolicy,
                        fallbackOfferImageUrl,
                        fallbackImageForBrandLogo,
                        fallbackImageForBrandLogoAltText,
                    )}
                />
            ) : (
                renderSkeleton()
            )}
        </>
    )
}

export default EmailOfferActivation
