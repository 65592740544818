import { magicNumber } from './magicNumber'
import getLanguage from './getLanguage'
import { format } from 'date-fns-tz'
import { dateFormatter, defaultLocale } from '../globalConstants/global.constant'

/**
 * Returns Date Format with or without time
 * @param {boolean} dateOnly - should be true to fetch date without time
 * @param {number} previousDate - previous date number for which date format is required
 * @return {string} - returns Date or DateTime
 */
const getDateTime = (dateOnly = false, previousDate = 0): string => {
    const currentMonth = 1
    const numOfHours = 10
    const newDate = new Date()
    const hours = newDate.getHours()
    const minutes = newDate.getMinutes()
    const seconds = newDate.getSeconds()

    /**
     * function to calculate date 90 days before current date
     * @return {number}
     */
    const getModifiedDate = () => {
        const startDate = newDate.setDate(newDate.getDate() - previousDate)
        const formattedStartDate = new Date(startDate)
        return formattedStartDate.getDate()
    }

    /**
     * Returns Date
     * @param {number} previous - should be greater than 0
     * @return {string} Previous Date or Current Date
     */
    const date = previousDate > 0 ? getModifiedDate() : newDate.getDate()
    const month = newDate.getMonth() + currentMonth
    const year = newDate.getFullYear()

    /**
     * New date needs to be set when previousDate > 0
     * If previousDate <= 0, there will be no change as it calls the same on the ternary operator on the date const
     * @param {number} setDate
     * @return {number} New Formatted Date String.
     */
    const setDate = newDate.getDate()

    /**
     * Formatting Year, Month & Date to required format(double digits).
     * @param {string} year
     * @param {string} month
     * @param {string} date
     *
     * @return {string} Formatted Date String.
     */

    const formatedDate = `${month < numOfHours ? `0${month}` : `${month}`}${
        date < numOfHours ? `0${setDate}` : `${setDate}`
    }${year}`

    /**
     * Formatting Hours, Minutes & Seconds to required format(double digits).
     * @param {string} hours
     * @param {string} minutes
     * @param {string} seconds
     *
     * @return {string} Formatted Time String.
     */
    const formatedTime = `${hours < numOfHours ? `0${hours}` : `${hours}`}:${
        minutes < numOfHours ? `0${minutes}` : `${minutes}`
    }:${seconds < numOfHours ? `0${seconds}` : `${seconds}`}`

    return dateOnly ? formatedDate : formatedDate + formatedTime
}

export default getDateTime

/**
 * Returns Date Format DDMMYY
 * @param {string} extendedFormatDate - ISO 8601 Extended format YYYY-MM-DDTHH:mm:ss.sssZ
 * @return {string} - returns Date or DateTime
 */
export const getDateFormatDDMMYY = (extendedFormatDate: string): string => {
    const date = new Date(extendedFormatDate)
    const year = date.getFullYear().toString()

    let month = (magicNumber.ONE + date.getMonth()).toString()
    month = month.length > magicNumber.ONE ? month : `0${month}`

    let day = date.getDate().toString()
    day = day.length > magicNumber.ONE ? day : `0${day}`

    return day + month + year
}

/**
 * Returns Date Format DD/MM/YYYY
 * @param {string} value
 * @return {string} - returns Date Format
 */
export const getDateFormatter = (value: string): string => {
    const date = value.slice(0, magicNumber.TWO)
    const month = value.slice(magicNumber.TWO, magicNumber.FOUR)
    const year = value.slice(magicNumber.FOUR)
    return date + '/' + month + '/' + year
}

/**
 * returns difference in days between two dates
 * @param {Date} date1
 * @param {Date} date2
 * @return {number}
 */
export const getDiffBetweenDates = (date1: Date, date2: Date): number => {
    return Math.floor(
        Math.abs(date2.getTime() - date1.getTime()) /
            (magicNumber.THOUSAND * magicNumber.SIXTY * magicNumber.SIXTY * magicNumber.TWENTYFOUR),
    )
}

/**
 * sometimes CDS returns year as two-digit number e.g. 35
 * we need to transform it to full year, like 2023
 * @param {number} year
 * @return {number}
 */
export const getFullYear = (year: number): number => {
    return year > magicNumber.HUNDRED ? year : magicNumber.TWOTHOUSAND + year
}

/**
 * function param input date format is mmddyyyy. and return valur format is 12th January 2023
 * @param {string} value
 * @return {string}
 */
export const getDateFormatNumericYear = (value: string): string => {
    const language = getLanguage()
    const dateFormat = getDateFormatter(value)
    const objDate = new Date(dateFormat)
    const getMonthYear = objDate.toLocaleDateString(language || defaultLocale, {
        month: 'long',
        year: 'numeric',
    })
    const day = value.slice(magicNumber.ZERO, magicNumber.TWO)

    const getDayValue = value && format(new Date(day), dateFormatter)
    return `${getDayValue} ${getMonthYear}`
}

/**
 * function to return date and time for gigya subscription, format is yyyy-mm-dd hh:mm:ss. and return valur format is 2023-07-14 14:18:11
 * @return {string}
 */
export const getSubscriptionDateTime = (): string => {
    return new Date().toLocaleString('en-ca', { hour12: false }).replace(',', '').replace('24:', '00:')
}
