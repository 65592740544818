import { magicNumber } from './magicNumber'

/**
 * Prepares the time format by un-padding leading zero(0)
 * @param { string } time
 * @return {string}
 */
const getUnpaddedTimeFormat = (time: string): string => {
    return time.charAt(0) === '0' ? time.slice(magicNumber.ONE) : time
}

export default getUnpaddedTimeFormat
export { getUnpaddedTimeFormat }
