import { currencyFormat, currencyShortForm } from '../../globalConstants/global.constant'
import localization from '../../helpers'
import { getLanguage, magicNumber } from '../../utils'

/**
 * Function to format number as currency
 * @param {number} value
 * @return {string}
 */
export const formatCurrency = (value: number): string =>
    localization.formattedPrice(getLanguage(), value / magicNumber.HUNDRED, currencyShortForm, currencyFormat)

/**
 * Function to get string divided by spaces
 * @param {string} value
 * @param {number} groupLength
 * @return {string}
 */
export const addDividers = (value: string, groupLength: number): string => {
    const digits = value.split('').filter(char => /\d/.exec(char))
    const groups = []
    for (let i = 0; i < digits.length; i += groupLength) {
        groups.push(digits.slice(i, i + groupLength).join(''))
    }
    return groups.join(' ')
}

/**
 * Function to remove spaces from card number and pin values
 * @param {string} value
 * @return {string}
 */
export const removeDividers = (value: string): string => value.split(' ').join('')

/**
 * Function to get field length including dividers
 * @param {number} digits
 * @param {number} groupLength
 * @return {string}
 */
export const getLengthWithDividers = (digits: number, groupLength: number): number =>
    digits + Math.floor(digits / groupLength)

/**
 * Function to vaidate field length
 * @param {string} input
 * @param {number} minLength
 * @param {number} maxLength
 * @return {boolean}
 */
export const validateLength = (input: string, minLength: number, maxLength: number): boolean => {
    const digits = removeDividers(input)
    return digits.length >= minLength && digits.length <= maxLength
}
