import { DropdownType, DropdownOptionsState } from '../models/vehicleDropdowns.types'

/**
 * Reset option, value and set status NOT_LOADED
 * @param {DropdownType} drop - dropdown to clear
 */
export const deleteDropdown = (drop: DropdownType): void => {
    drop.options = []
    drop.isDisabled = true
    drop.optionsState = DropdownOptionsState.DELETED
    drop.value = ''
}
