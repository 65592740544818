import { Url } from './MegaNavigation.type'
/**
 * Aria-Current path for current page
 * @method
 * @param {string} url url to be validated
 * @return {boolean} returns boolean
 */

export const getAriaPath = (url: string): boolean => {
    return Boolean(RegExp(url).exec(window.location.pathname))
}

/**
 * Aria-Current value for current page
 * @method
 * @param {CategoryDataProps} categoryData categoryData has url to validate
 * @return {boolean} returns boolean
 */

export const getAriaCurrentValue = (categoryData: { url: Url | string }): boolean => {
    return getAriaPath(
        typeof categoryData.url === 'string' ? encodeURI(categoryData.url) : encodeURI(categoryData.url?.url),
    )
}
