import { createAction } from 'typesafe-actions'

import {
    FETCH_DATA_USING_CATEGORY_ID,
    FETCH_DATA_USING_CATEGORY_ID_ERROR,
    UPDATE_VEHICLE_DESCRIPTIONS,
} from '../types/category/category.actionTypes.constant'
import { CategoryResponseById, CategoryResponseErrorDTO, VehicleDescriptionsDto } from '../models/category.interface'

export const fetchCategoryIdAction = createAction(
    FETCH_DATA_USING_CATEGORY_ID,
    (payload: CategoryResponseById) => payload,
)<CategoryResponseById>()

export const fetchCategoryIdErrorAction = createAction(
    FETCH_DATA_USING_CATEGORY_ID_ERROR,
    (payload: CategoryResponseErrorDTO) => payload,
)<CategoryResponseErrorDTO>()

export const updateVehicleDescriptions = createAction(
    UPDATE_VEHICLE_DESCRIPTIONS,
    (payload: VehicleDescriptionsDto) => payload,
)<VehicleDescriptionsDto>()
