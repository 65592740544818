import { createAction } from 'typesafe-actions'

import { ErrorResponse } from '../models/loyaltyRegisterForm.interface'
import { InitPayment, AddPaymentCardType } from '../models/profile.payment.interface'
import {
    SET_HPP_TOKEN,
    ADD_CREDIT_CARD,
    CLEAR_ADD_CREDIT_STATES,
    ADD_CREDIT_CARD_ERROR,
} from '../types/profile/profile.payment.actionTypes.constant'

export const addHppToken = createAction(SET_HPP_TOKEN)<InitPayment>()
export const addCreditCardAction = createAction(ADD_CREDIT_CARD)<AddPaymentCardType>()
export const addCreditCardErrorAction = createAction(ADD_CREDIT_CARD_ERROR)<ErrorResponse>()
export const resetAddCreditCardAction = createAction(CLEAR_ADD_CREDIT_STATES)()
