import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/deliveryEligibility.actionCreators'
import { SuccessResponse, ErrorResponse } from '../models/deliveryOptions.interface'
import {
    fetchDeliveryOptionsAction,
    deliveryOptionsFailureAction,
    resetDeliveryOptionsAction,
} from '../actionCreators/deliveryEligibility.actionCreators'

type Action = ActionType<typeof actions>

export interface deliveryOptionsState {
    deliveryOptions: SuccessResponse
    deliveryOptionsErrorResponse?: ErrorResponse
}

export const initialState: deliveryOptionsState = {
    deliveryOptions: {} as SuccessResponse,
    deliveryOptionsErrorResponse: null,
}

export const deliveryOptionsReducer = (
    state: deliveryOptionsState = initialState,
    action: Action,
): deliveryOptionsState => {
    switch (action.type) {
        case getType(fetchDeliveryOptionsAction): {
            return {
                ...state,
                deliveryOptions: action.payload,
                deliveryOptionsErrorResponse: null,
            }
        }
        case getType(deliveryOptionsFailureAction): {
            return {
                ...state,
                deliveryOptions: {},
                deliveryOptionsErrorResponse: action.payload,
            }
        }
        case getType(resetDeliveryOptionsAction): {
            return {
                deliveryOptions: {} as SuccessResponse,
                deliveryOptionsErrorResponse: null,
            }
        }
        default:
            return state
    }
}
