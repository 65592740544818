/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'
import { vehiclesDataInterface } from '../models/vehiclesData.interface'
import * as actions from '../actionCreators/vehiclesData.actionCreators'
import { setInitialDropDownsForPreselectedMakeOnly } from '../actionCreators/preselectedVehicle.actionCreators'
import { adaptVehicleResponseToDropdowns } from '../utils/adaptVehicleResponseToDropdowns'
import { resetDropdown } from '../utils/resetDropdown'
import updateDropDownValues from '../utils/updateDropDownValues'
import { initDropdownByOrders } from '../utils/initDropdownByOrders'
import { generateLocalizedDropdowns } from '../utils/generateLocalizedDropdowns'
import { magicNumber } from '@nl/lib'
import { deleteDropdown } from '../utils/deleteDropdown'

export const initialState: vehiclesDataInterface = {
    baseDropdownsKeys: ['vehicleType', 'year', 'make', 'model'],
    dropdowns: [],
    vehicleDropdowns: [],
    initialDropDownsFetched: false,
    isSavedVehicleChecked: false,
    isFetchInProgress: false,
    removedVehicle: '',
}
type Action = ActionType<typeof actions | typeof setInitialDropDownsForPreselectedMakeOnly>

export const vehiclesDataReducer = (state = initialState, action: Action): vehiclesDataInterface => {
    switch (action.type) {
        case getType(actions.setIsFetchInProgress):
            return { ...state, isFetchInProgress: action.payload }
        case getType(actions.setVehicleChooseResponse):
            const dropdowns = adaptVehicleResponseToDropdowns(
                action.payload.response,
                state.dropdowns,
                state.baseDropdownsKeys,
                action.payload.labels,
            )
            return {
                ...state,
                dropdowns,
                isFetchInProgress: false,
                initialDropDownsFetched: true,
                forceFetchDropDowns: false,
            }

        case getType(actions.setVehicleBaseAttributesOrder):
            return { ...state, ...initDropdownByOrders(state, action.payload) }
        case getType(actions.setVehicleKnownValues):
            return {
                ...state,
                dropdowns: updateDropDownValues(state.dropdowns, state.baseDropdownsKeys, action.payload),
            }
        case getType(actions.setVehicleKnownValuesAndClearOther):
            const keys = Object.keys(action.payload)
            const updatedAndClearedDropdown = updateDropDownValues(
                state.dropdowns,
                state.baseDropdownsKeys,
                action.payload,
            )
            updatedAndClearedDropdown.filter(item => !keys.includes(item.id)).forEach(deleteDropdown)
            return { ...state, dropdowns: updatedAndClearedDropdown }
        case getType(actions.setSavedVehicleChecked):
            return { ...state, isSavedVehicleChecked: true }
        case getType(actions.setClearToVehicleAttribute):
            const firstIndexToClear = magicNumber.ONE + state.dropdowns.findIndex(drop => drop.id === action.payload)
            const cutIndex = Math.max(firstIndexToClear, state.baseDropdownsKeys.length)
            const clearedDropdown = state.dropdowns.slice(0, cutIndex)
            clearedDropdown.slice(firstIndexToClear).forEach(resetDropdown)
            return { ...state, dropdowns: clearedDropdown, forceFetchDropDowns: true }
        case getType(actions.setDropdownAttributeLocalization):
            const localizedDropdowns = generateLocalizedDropdowns(state.dropdowns, action.payload)

            return { ...state, dropdowns: localizedDropdowns }

        case getType(actions.setRemovedVehicle):
            return { ...state, removedVehicle: action.payload }

        case getType(actions.recallVehicleAPI):
            return { ...state, forceFetchDropDowns: true, isFetchInProgress: false }

        case getType(actions.setAPIErrorStatus):
            return {
                ...state,
                dropdowns: state.dropdowns.map(val =>
                    val.id === action.payload.fieldName ? { ...val, optionsState: action.payload.status } : val,
                ),
            }

        default:
            return vehiclesDataReducerForSavedVehicle(state, action)
    }
}

const vehiclesDataReducerForSavedVehicle = (state = initialState, action: Action): vehiclesDataInterface => {
    switch (action.type) {
        case getType(actions.setVehicleChooseResponseForSavedVehicle):
            const vehicleDropdowns = adaptVehicleResponseToDropdowns(
                action.payload.response,
                state.vehicleDropdowns,
                state.baseDropdownsKeys,
                action.payload.labels,
            )
            return {
                ...state,
                vehicleDropdowns,
                isFetchInProgress: false,
                initialDropDownsFetched: true,
                forceFetchDropDowns: false,
            }

        case getType(actions.setVehicleKnownValuesForSavedVehicle):
            return {
                ...state,
                vehicleDropdowns: updateDropDownValues(state.vehicleDropdowns, state.baseDropdownsKeys, action.payload),
            }
        case getType(actions.setVehicleKnownValuesAndClearOtherForSavedVehicle):
            const keys = Object.keys(action.payload)
            const updatedAndClearedDropdown = updateDropDownValues(
                state.vehicleDropdowns,
                state.baseDropdownsKeys,
                action.payload,
            )
            updatedAndClearedDropdown.filter(item => !keys.includes(item.id)).forEach(deleteDropdown)
            return { ...state, vehicleDropdowns: updatedAndClearedDropdown }
        case getType(actions.setClearToVehicleAttributeForSavedVehicle):
            const firstIndexToClear =
                magicNumber.ONE + state.vehicleDropdowns.findIndex(drop => drop.id === action.payload)
            const cutIndex = Math.max(firstIndexToClear, state.baseDropdownsKeys.length)
            const clearedDropdown = state.vehicleDropdowns.slice(0, cutIndex)
            clearedDropdown.slice(firstIndexToClear).forEach(resetDropdown)
            return { ...state, vehicleDropdowns: clearedDropdown }
        case getType(actions.setDropdownAttributeLocalizationForSavedVehicle):
            const localizedDropdowns = generateLocalizedDropdowns(state.vehicleDropdowns, action.payload)

            return { ...state, vehicleDropdowns: localizedDropdowns }
        default:
            return state
    }
}
