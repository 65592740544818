import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

import { ToastWrapperProps } from './ToastWrapper.type'
import { Toast } from '@nl/lib'

const ToastWrapper: React.FC<ToastWrapperProps> = props => {
    useLayoutEffect(() => {
        const toastContainer = document.getElementById('toastContainer')
        if (!!toastContainer) {
            toastContainer.remove()
        }
    }, [])

    return <Toast {...props.toastProps} />
}

ToastWrapper.propTypes = {
    toastProps: PropTypes.any,
}

export default ToastWrapper
