import React from 'react'
import PropTypes from 'prop-types'
import { LanguageToggleProps } from './LanguageToggle.type'
import { PREFIX } from '../config'
import { getLanguage } from '../../utils'
import { checkEmptyValue } from '../../utils/checkNotNullAndUndefined'

const LanguageToggle: React.FC<LanguageToggleProps> = ({ ...props }): JSX.Element => {
    const { languageLabel, alternateLanguageA11y, getAlternateUrl, featureType, fontStyle } = props
    const font = fontStyle ? `${fontStyle}` : ''

    const onClickHandler = (event: React.MouseEvent): void => {
        event.preventDefault()
        document.cookie = `preferred-language=${languageLabel.toLowerCase()}`
        window.location.href = encodeURI(getAlternateUrl())
    }
    return !checkEmptyValue(languageLabel) ? (
        <a
            className={`${PREFIX}-pencil-banner__language ${font}`}
            href={getAlternateUrl()}
            onClick={onClickHandler}
            aria-label={alternateLanguageA11y}
            data-link-value={languageLabel}
            data-feature-type={featureType}
            lang={getLanguage()}>
            {languageLabel}
        </a>
    ) : null
}

LanguageToggle.propTypes = {
    languageLabel: PropTypes.string,
    alternateLanguageA11y: PropTypes.string,
    getAlternateUrl: PropTypes.func,
    featureType: PropTypes.string,
    fontStyle: PropTypes.string,
}

export default LanguageToggle
