import { Dispatch } from 'redux'

import {
    fetchExtraHardwareAction,
    extraHardwareFailureAction,
    setExtraHardwarePriceAction,
} from '../actionCreators/extraHardware.actionCreators'
import { extraHardwareService } from '../../services/extraHardwareService'
import { SuccessResponse, ErrorResponse, HardwareParams, HardwareSkuData } from '../models/extraHardware.interface'
import { AxiosError } from 'axios'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { getEnvironment } from '../../environments'

export const getExtraHardware =
    (params: HardwareParams) =>
    (dispatch: Dispatch): void => {
        const config = getEnvironment()
        extraHardwareService
            .fetchExtraHardware(params)
            .then((extraHardwareData: { data: SuccessResponse }) => {
                replaceEmptyImagesWithDefault(
                    extraHardwareData?.data?.results?.map(result => result?.field),
                    'thumb-img-url',
                    config.defaultProductImage,
                )
                const response = extraHardwareService.transformData(
                    extraHardwareData.data,
                    params.sku,
                    params.vehicleInformation,
                )
                dispatch(fetchExtraHardwareAction(response))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(extraHardwareFailureAction(error.response))
            })
    }

export const setExtraHardwarePrice =
    (hardwareSkuData: HardwareSkuData) =>
    (dispatch: Dispatch): void => {
        dispatch(setExtraHardwarePriceAction(hardwareSkuData))
    }
