import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { fetchPreferredStoreDetails, setSelectedPreferredStoreId } from '../../redux/actions'

import getQueryString from '../../utils/getQueryString'
/*
import { signOutSuccessSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import { checkStoreHashInURL, checkStoreParamInURL } from '@nl/lib'
import { checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import { getEnvironment } from '../../environments'
import { StoreChangeSource, userLoginStatus } from '../../globalConstants/global.constant'
import {
    storeChangeSourceSelector,
    selectedPreferredStoreIdSelector,
} from '../../redux/selectors/storeDetails.selectors'
import { modifyStringValues } from '../Checkout/ShippingAddress/utils/ShippingAddressHelper'
*/
const PreferredStoreInit: React.FC = () => {
    const dispatch = useDispatch()
    const pageLoaded = useRef(0)

    /*
    const userProfileData = useSelector(userProfileDataSelector)    
    const signOutSuccess = useSelector(signOutSuccessSelector)
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const storeChangeSource = useSelector(storeChangeSourceSelector)
    const isLoggedIn = !checkNotNullAndUndefined(userProfileData) ? checkDataLength(userProfileData) : false
    
    const profileRef = useRef(0)
    const config = getEnvironment()
    const bannerType = config.banner
    
    const ecommerceData =
        !checkNotNullAndUndefined(userProfileData?.ecommerce) && checkDataLength(userProfileData?.ecommerce)
            ? userProfileData?.ecommerce
            : {}
    */
    /**
     * Updates preferred store with the storeid passed from url
     */
    useEffect(() => {
        if (pageLoaded.current === 0) {
            const withoutHashSymbol = window.location.hash?.replace('#', '')
            const storeIdFromHash = getQueryString(withoutHashSymbol, 'store')
            const storeIdFromQueryParams = getQueryString(window.location.search, 'store')
            const storeId = storeIdFromQueryParams || storeIdFromHash || null
            if (storeId) {
                const storeIdFromUrl = Number(storeId)
                if (!isNaN(storeIdFromUrl)) {
                    dispatch(setSelectedPreferredStoreId(String(storeIdFromUrl)))
                    dispatch(fetchPreferredStoreDetails(String(storeIdFromUrl)))
                    pageLoaded.current = 1
                }
            }
        }
    }, [dispatch])

    /**
     * function to get preferredStoreId from  profile ecommerce
     * @return {string | undefined}
     */
    /*
    const getUserPreferredStoreId = useCallback((): string | undefined => {
        // loop is to check preferredStoreId under same bannuserer
        for (const [key, value] of Object.entries(ecommerceData)) {
            if (key === bannerType) {
                return value?.preferredStore as string
            }
        }
    }, [bannerType, ecommerceData])

    
    const userPreferredStore = getUserPreferredStoreId()
    const pageType = getPageType()
    const isCartOrCheckout = pageType === pageTypes.cart || pageType === pageTypes.checkout
    const setPreferedSToreDetails = !isCartOrCheckout && selectedPreferredStoreId !== userPreferredStore
    */
    /**
     * Updates preferred store for auth user, to preferred store saved in their profile
     */
    /*
    useEffect(() => {
        const isValidStoreId =
            !!userPreferredStore && !checkNotNullAndUndefined(userPreferredStore) && !isNaN(Number(userPreferredStore))
        const payload = {
            ecommerce: {
                [bannerType]: {
                    preferredStore: String(selectedPreferredStoreId),
                },
            },
        }

        if (
            isLoggedIn &&
            isValidStoreId &&
            !checkStoreHashInURL() &&
            !checkStoreParamInURL() &&
            profileRef.current === 0
        ) {
            if (setPreferedSToreDetails) {
                dispatch(setSelectedPreferredStoreId(String(userPreferredStore)))
            } else if (userProfileData?.role === userLoginStatus.fullyAuthenticatedUser) {
                dispatch(updateProfile(payload, false))
            }
            profileRef.current = 1
        }
    }, [
        bannerType,
        dispatch,
        setPreferedSToreDetails,
        isCartOrCheckout,
        isLoggedIn,
        selectedPreferredStoreId,
        userPreferredStore,
        userProfileData,
    ])

    useEffect(() => {
        if (signOutSuccess) {
            profileRef.current = 0
        }
    }, [signOutSuccess])

    useEffect(() => {
        const payload = {
            ecommerce: {
                [bannerType]: {
                    preferredStore: String(selectedPreferredStoreId),
                },
            },
        }

        let isStoreChanged
        Object.values(StoreChangeSource).forEach(source => {
            if (modifyStringValues(source) === modifyStringValues(storeChangeSource)) {
                isStoreChanged = true
            }
        })

        if (
            !!selectedPreferredStoreId &&
            checkDataLength(userProfileData) &&
            userProfileData?.role === userLoginStatus.fullyAuthenticatedUser &&
            // store change source is not availalbe from store details page since it is not dispatching fetchPreferredStoreDetails, handling it with pageype
            // storeChangeSource being defined means that user changed the preferred store, we have to update the profile
            (pageType === pageTypes.store || pageType === pageTypes.pdpPage || isStoreChanged)
        ) {
            dispatch(updateProfile(payload, false))
        }
    }, [bannerType, dispatch, pageType, selectedPreferredStoreId, storeChangeSource, userProfileData])
    */

    return null
}
export default PreferredStoreInit
