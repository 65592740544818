import { ActionType, getType } from 'typesafe-actions'

import {
    startSpinnerWithCounter,
    setShowSpinner,
    stopSpinnerWithCounter,
} from '../actionCreators/spinner.actionCreators'
import * as actions from '../actionCreators/spinner.actionCreators'

type Action = ActionType<typeof actions>

export interface SpinnerState {
    showSpinner: boolean
    spinnerCounter: number
}
const initialState: SpinnerState = {
    showSpinner: false,
    spinnerCounter: 0,
}

export const spinnerReducer = (state: SpinnerState = initialState, action: Action): SpinnerState => {
    switch (action.type) {
        case getType(startSpinnerWithCounter):
            return { ...state, spinnerCounter: state.spinnerCounter + 1 }
        case getType(stopSpinnerWithCounter):
            return { ...state, spinnerCounter: state.spinnerCounter - 1 }
        case getType(setShowSpinner):
            return { ...state, showSpinner: action.payload }
        default:
            return state
    }
}
