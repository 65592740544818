import { createAction } from 'typesafe-actions'

import { HardwareList, ErrorResponse, HardwareSkuData } from '../models/extraHardware.interface'
import {
    GET_BALLOON_ADDONS_DATA_SUCCESS,
    GET_BALLOON_ADDONS_DATA_ERROR,
    SET_BALLOON_ADDONS_PRICE_SUCCESS,
} from '../types/balloonAddons/balloonAddons.actionTypes.constant'

export const fetchBalloonAddonsAction = createAction(GET_BALLOON_ADDONS_DATA_SUCCESS)<HardwareList[]>()

export const balloonAddonsFailureAction = createAction(GET_BALLOON_ADDONS_DATA_ERROR)<ErrorResponse>()

export const setBalloonAddonsPriceAction = createAction(SET_BALLOON_ADDONS_PRICE_SUCCESS)<HardwareSkuData>()
