// This is wrapper util function for localStorage
const localStorageService = (localStorage => {
    const _getItem = (key: string) => {
        return localStorage.getItem(key)
    }

    const _setItem = (key: string, value: string) => {
        localStorage.setItem(key, value)
    }

    const _removeItem = (key: string) => {
        localStorage.removeItem(key)
    }

    const _clear = () => {
        localStorage.clear()
    }

    return {
        getItem: (key: string) => {
            return _getItem(key)
        },
        setItem: (key: string, value: string) => {
            _setItem(key, value)
        },
        removeItem: (key: string) => {
            _removeItem(key)
        },
        clear: () => {
            _clear()
        },
    }
})(localStorage)

export default localStorageService
