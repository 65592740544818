/**
 * @class {GlobalInterceptorService}
 */
class GlobalInterceptorService {
    interceptorTimeoutList: Record<string, unknown>
    interceptorPartialAuthList: Record<string, unknown>[]

    /**
     * constructor for GlobalInterceptorService
     * @param {Record<string, unknown>} timeoutList
     * @param {Record<string, unknown>} partialAuthList
     */
    constructor(timeoutList: Record<string, unknown>, partialAuthList: Record<string, unknown>[]) {
        this.interceptorTimeoutList = timeoutList // array of interceptor functions }
        this.interceptorPartialAuthList = partialAuthList
    }

    /**
     * setter for interceptorTimeoutList
     * @param {Record<string, unknown>} interceptorObj
     */
    setInterceptorTimeoutList(interceptorObj: Record<string, unknown>) {
        this.interceptorTimeoutList = { ...this.interceptorTimeoutList, interceptorObj }
    }

    /**
     * getter for interceptorTimeoutList
     * @return {Record<string, unknown>}
     */
    getInterceptorTimeoutList() {
        return this.interceptorTimeoutList
    }

    /**
     * setter for interceptorPartialAuthList
     * @param {Record<string, unknown>} InterceptorObj
     */
    setInterceptorPartialAuthList(InterceptorObj: Record<string, unknown>): void {
        this.interceptorPartialAuthList.push(InterceptorObj)
    }

    /**
     * This function return interceptor partial auth list
     * @return {Record<string, unknown>} interceptorPartialAuthList
     */
    getInterceptorPartialAuthList(): Record<string, unknown>[] {
        return this.interceptorPartialAuthList.length ? this.interceptorPartialAuthList : []
    }

    /**
     * function to clear interceptorTimeoutList and interceptorPartialAuthList
     */
    clearAll() {
        this.interceptorTimeoutList = {}
        this.interceptorPartialAuthList = []
    }
}
const interceptorConfig = new GlobalInterceptorService({}, [])

export default interceptorConfig
