import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { checkDataLength } from '../components/Accounts/Addresses/checkDataLength'
import { userProfileDataSelector } from '../redux/selectors/userProfile.selectors'

import { useAuthProps } from './hook.type'

// Custom hook for check the auth conditions and running only once.
const useAuth = ({ runOnce }: useAuthProps): (() => boolean) => {
    const userProfileData = useSelector(userProfileDataSelector)
    return useCallback(() => {
        return checkDataLength(userProfileData) && runOnce.current === 0
    }, [userProfileData, runOnce])
}

export { useAuth }
export default useAuth
