import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { CommonWarningToastProps } from '../CommonCard/CommonCard.type'
import Icon from '../Icon'
import Button from '../Button'
import { addModalAttribute } from '../../utils/modalAccessibility'

/**
 * CommonWarningToast component
 * @param {CommonWarningToastProps} props
 * @return {JSX.Element} returns CommonWarningToast component
 */
const CommonStatusToast: React.FC<CommonWarningToastProps> = ({ ...props }) => {
    /**
     * Function to open the store modal and add focus to the triggered element.
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - button click event.
     */
    const toastCTAClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        addModalAttribute(event)
        props.toastCTAHandler()
    }

    return (
        <div
            className={`${PREFIX}-warning-toast ${PREFIX}-warning-toast__${props.bgType}`}
            role="alert"
            aria-atomic="true">
            <div className={`${PREFIX}-warning-toast__text-container`}>
                <span className={`${PREFIX}-warning-toast__icon`}>
                    <Icon type={props.icon} size="lg" />
                </span>
                <p className={`${PREFIX}-warning-toast__label`} dangerouslySetInnerHTML={{ __html: props.label }}></p>
            </div>
            {!!props.urlLabel && (
                <a href={props.url} title={props.urlLabel} aria-label={props.urlA11yLabel}>
                    {props.urlLabel}
                </a>
            )}
            {!!props.checkNearByText && (
                <Button type="primary" size="mini" onClick={toastCTAClickHandler} buttonType="button">
                    {props.checkNearByText}
                </Button>
            )}
            {!!props.linkCTAText && (
                <button
                    className={`${PREFIX}__item-status-cta-link`}
                    type="button"
                    data-qm-allow="true"
                    onClick={props.linkCTAHandler}>
                    {props.linkCTAText}
                </button>
            )}
        </div>
    )
}

CommonStatusToast.propTypes = {
    icon: PropTypes.string.isRequired,
    bgType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checkNearByText: PropTypes.string,
    toastCTAHandler: PropTypes.func,
    linkCTAText: PropTypes.string,
    linkCTAHandler: PropTypes.func,
    isAddOn: PropTypes.bool,
    actionCTAHandler: PropTypes.func,
}

export default CommonStatusToast
