import { GlobalPropsHelper } from '../analytics/helpers/globalProps'

/**
 * get the current banner id which user is currently browsing
 * @return {string} returns current banner id
 */
export const getCurrentBannerId = (): string => {
    const globalProps = new GlobalPropsHelper()
    const configs = globalProps.init()
    return (configs['offerBannerId'] as string) || (configs['bannerId'] as string)
}
