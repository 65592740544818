/**
 * Add a class(s) to an element
 * @param {HTMLElement|string} selector  An dom element or a CSS selector string
 * @param {string} className The class name
 */

export const addClass = (selector: HTMLElement | string, className: string) => {
    if (selector instanceof Element) {
        selector.classList.add(className)
    } else if (typeof selector === 'string') {
        document.querySelector(selector).classList.add(className)
    }
}
