import { PREFIX } from '../config'

export const componentClassName = `${PREFIX}-giftcard`

export const CARD_NUMBER_GROUP_SIZE = 4
export const PIN_GROUP_SIZE = 3

// Gift card number from 8 to 21 digits
export const CARD_NUMBER_MIN_DIGITS = 8
export const CARD_NUMBER_MAX_DIGITS = 21

// Gift card pin from 1 to 10 digits
export const PIN_MIN_DIGITS = 1
export const PIN_MAX_DIGITS = 10

export const CARD_MASKING = '****'
export const CARD_NUMBER_DIGIT_SLICE = -4

export enum TooltipInteractionKeys {
    'Enter',
    ' ',
}

export const defaultLogo = 'ct-card'
