/**
 * Braze Init service
 */
class BrazeService {
    /**
     * Initialize Braze Web SDK
     * @param {string} apiKey
     * @param {string} sdkEndpointUrl
     */
    init = (apiKey: string, sdkEndpointUrl: string): void => {
        if (window?.braze) {
            window.braze.initialize(apiKey, {
                baseUrl: sdkEndpointUrl,
            })
        }
    }
}

export { BrazeService }
export default BrazeService
