import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { CategoryCardProps } from './CategoryCard.type'

/**
 * CategoryCard component
 * @param {CategoryCardProps} props
 * @return {JSX.Element} returns CategoryCard Component
 */
const CategoryCard: React.FC<CategoryCardProps> = props => {
    const { href, linkTarget, image, label, cardClicked, linkClass, imageClass, labelClass } = props
    const tileRef = useRef<HTMLImageElement>()

    useEffect(() => {
        if (tileRef && props.imagePolicies) {
            props.imagePolicies(tileRef.current)
        }
    }, [tileRef, props])

    return (
        <a
            target={linkTarget}
            onClick={() => cardClicked(label)}
            className={linkClass}
            data-testid="category-link"
            href={href}>
            <img
                ref={tileRef}
                data-component-name="dynamic-category-featured-list"
                src={image}
                alt={label}
                className={imageClass}
            />
            <span className={labelClass}>{label}</span>
        </a>
    )
}

CategoryCard.propTypes = {
    href: PropTypes.string,
    linkTarget: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
    cardClicked: PropTypes.func,
    linkClass: PropTypes.string,
    imageClass: PropTypes.string,
    labelClass: PropTypes.string,
    imagePolicies: PropTypes.func,
}

export default CategoryCard
