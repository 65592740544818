import { iPrice, iPriceRange } from '../PriceOld/PriceOld.types'
import { Price } from '../Price/Price.types'
import localization from '../../helpers'
import { currencyFormat, currencyShortForm } from '../../globalConstants/global.constant'
import { getFormattedPrice } from '../../utils/getPriceRangePill'

/**
 * Returns the price value to be shown based on unit price or price range
 * @param {iPrice | iPriceRange} value - max limit of the text.
 * @return {string}
 */
export const checkInstanceOf = (value: iPrice | iPriceRange): string => {
    let updatedPriceStruct = ''
    if (value && !Object.keys(value).includes('minPrice')) {
        updatedPriceStruct = (value as unknown as iPrice).formattedValue
    } else if (value && Object.keys(value).includes('minPrice')) {
        value = value as unknown as iPriceRange
        updatedPriceStruct = `${value.minPrice.formattedValue} - ${value.maxPrice.formattedValue}`
    } else {
        updatedPriceStruct = ''
    }
    return updatedPriceStruct
}

/**
 * Returns the price value to be shown based on original/current price or price range
 * @param {Price} priceValue - price object passed to check value or minimum and maximum price value
 * @param {string} currentLanguage - passing the current web page language
 * @param {number} quantity - passing the quantity
 * @param {boolean} isZeroPriceShown - is zero price shown or not
 * @return {string}
 */
export const checkInstanceOfPrice = (
    priceValue: Price,
    currentLanguage: string,
    quantity?: number,
    isZeroPriceShown = false,
): string => {
    let updatePriceStructure = ''
    const initialPriceVal = {
        value: 0,
        minPrice: 0,
        maxPrice: 0,
    }
    const { value, minPrice } = !!priceValue ? priceValue : initialPriceVal
    if (isZeroPriceShown ? value >= 0 : value) {
        updatePriceStructure = getFormattedPriceValue(currentLanguage, quantity ? quantity * value : value)
    } else if (isZeroPriceShown ? minPrice >= 0 : minPrice) {
        updatePriceStructure = `${getFormattedPriceValue(currentLanguage, quantity ? quantity * minPrice : minPrice)}`
    } else {
        updatePriceStructure = ''
    }
    return updatePriceStructure
}

/**
 * Helper function to return the price value
 * @param {string} currentLanguage - current document language
 * @param {string} price - value, min price or max price to be passed
 * @return {string}
 */
export const getFormattedPriceValue = (currentLanguage, price): string => {
    return localization.formattedPrice(currentLanguage, price, currencyShortForm, currencyFormat)
}

/**
 * Returns the price value to be shown based on discount price or price range
 * @param {Price} priceValue - price object passed to check value or minimum and maximum price value
 * @param {string} currentLanguage - current language
 * @return {string}
 */
export const checkInstanceOfDiscountPrice = (priceValue: Price, currentLanguage: string): string => {
    let updatePriceStructure = ''
    const initialPriceVal = {
        value: 0,
        minPrice: 0,
        maxPrice: 0,
    }
    const { value, minPrice, maxPrice } = !!priceValue ? priceValue : initialPriceVal
    if (value) {
        updatePriceStructure = getFormattedPrice(Math.floor(value), currentLanguage)
    } else if (minPrice && maxPrice) {
        updatePriceStructure = `${getFormattedPrice(Math.floor(minPrice), currentLanguage)} - ${getFormattedPrice(
            Math.floor(maxPrice),
            currentLanguage,
        )}`
    } else {
        updatePriceStructure = ''
    }
    return updatePriceStructure
}
